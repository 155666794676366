body {
    background-color: #7D7768;
    font-family: 'Asap', Sans-serif;
    font-size: 0.95em;
}

.table {
    font-family: 'Asap', Sans-serif !important;
}

$containerWidth:900px;
.content {
    max-width: $containerWidth; // padding: 55px 0;
    padding: 0;
}

h1,
h2,
h3,
h4,
h5 {
    text-align: center;
    font-family: 'Asap', sans-serif !important;
}

.anchor {
    display: block;
    position: relative;
    top: -55px;
    visibility: hidden;
}

.column-2 {
    width: 50%;
    min-width: 275px;
}

.column-3 {
    width: 33%;
    min-width: 275px;
}

.column-4 {
    width: 25%;
    min-width: 275px;
}

.page-header {
    height: 600px;
    padding: 60px 20px;
    background: url(./img/header/moogwein.jpg);
    background-position: top center;
    background-size: cover;
    color: white;
    h1,
    h2,
    h3 {
        margin: 0;
        text-align: left;
        text-shadow: 2px 2px 4px black;
    }
}

/* .page-header img {
    object-position: top;
} */

.logo {
    padding: 10px 0px 0px 10px;
    img {
        width: 94px;
    }
}

.float-left {
    float: left;
}

.menu {
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: initial;
    padding: 0;
    background-color: rgba(45, 97, 85, 0.85);
    h2,
    .nav__item>a {
        color: white;
    }
    .menu-container {
        padding: 0 10px;
        justify-content: space-between;
        display: flex;
        max-width: $containerWidth;
        width: 100%;
    }
}

.toolbar {
    width: 100%;
    background-color: rgba(45, 97, 85, 0.8);
    h2,
    .nav__item>a {
        color: white;
    }
}

.content {
    background-color: white;
}

.footer {
    background-color: rgba(45, 97, 85, 0.8);
    width: 100%;
    padding: 10px;
    text-align: center;
    color: white;
}