@charset "UTF-8";
:root {
  box-sizing: border-box;
  cursor: default;
  font-size: 16px;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow-y: scroll;
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
  vertical-align: top; }

input[type='number'] {
  max-width: 100%;
  width: auto; }

input[type='search'] {
  -webkit-appearance: textfield; }
  input[type='search']::-webkit-search-cancel-button, input[type='search']::-webkit-search-decoration {
    -webkit-appearance: none; }

[hidden] {
  display: none; }

[unselectable] {
  user-select: none; }

*,
::after,
::before {
  box-sizing: border-box;
  /* color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  text-decoration: inherit;
  vertical-align: inherit; */ }

* {
  box-sizing: border-box;
  outline: none; }

html,
body {
  height: 100%;
  margin: 0;
  padding: 0; }

h1,
h2,
h3,
h4,
h5,
h6,
.is-h1,
.is-h2,
.is-h3,
.is-h4,
.is-h5,
.is-h6,
.is-giga,
.is-mega,
.is-kilo {
  font-family: sans-serif;
  font-weight: bolder;
  line-height: 1.4; }
  h1 small,
  h2 small,
  h3 small,
  h4 small,
  h5 small,
  h6 small,
  .is-h1 small,
  .is-h2 small,
  .is-h3 small,
  .is-h4 small,
  .is-h5 small,
  .is-h6 small,
  .is-giga small,
  .is-mega small,
  .is-kilo small {
    font-size: 0.5em;
    vertical-align: baseline; }
  h1 a,
  h2 a,
  h3 a,
  h4 a,
  h5 a,
  h6 a,
  .is-h1 a,
  .is-h2 a,
  .is-h3 a,
  .is-h4 a,
  .is-h5 a,
  .is-h6 a,
  .is-giga a,
  .is-mega a,
  .is-kilo a {
    font-family: inherit;
    font-weight: inherit;
    line-height: inherit; }

.is-giga {
  margin: 0; }
  @media (min-width: 0px) {
    .is-giga {
      font-size: 1.75em; } }
  @media (min-width: 720px) {
    .is-giga {
      font-size: 2em; } }
  @media (min-width: 1920px) {
    .is-giga {
      font-size: 2.5em; } }

.is-mega {
  margin: 0; }
  @media (min-width: 0px) {
    .is-mega {
      font-size: 1.3125em; } }
  @media (min-width: 720px) {
    .is-mega {
      font-size: 1.5em; } }
  @media (min-width: 1920px) {
    .is-mega {
      font-size: 1.875em; } }

.is-kilo {
  margin: 0; }
  @media (min-width: 0px) {
    .is-kilo {
      font-size: 0.875em; } }
  @media (min-width: 720px) {
    .is-kilo {
      font-size: 1em; } }
  @media (min-width: 1920px) {
    .is-kilo {
      font-size: 1.25em; } }

h1,
.is-h1 {
  margin: 0 0 2.75rem 0; }
  @media (min-width: 0px) {
    h1,
    .is-h1 {
      font-size: 2.6705em; } }
  @media (min-width: 720px) {
    h1,
    .is-h1 {
      font-size: 3.052em; } }
  @media (min-width: 1920px) {
    h1,
    .is-h1 {
      font-size: 3.815em; } }

h2,
.is-h2 {
  margin: 0 0 1.375rem 0; }
  @media (min-width: 0px) {
    h2,
    .is-h2 {
      font-size: 2.13587em; } }
  @media (min-width: 720px) {
    h2,
    .is-h2 {
      font-size: 2.441em; } }
  @media (min-width: 1920px) {
    h2,
    .is-h2 {
      font-size: 3.05125em; } }

h3,
.is-h3 {
  margin: 0 0 1.375rem 0; }
  @media (min-width: 0px) {
    h3,
    .is-h3 {
      font-size: 1.70888em; } }
  @media (min-width: 720px) {
    h3,
    .is-h3 {
      font-size: 1.953em; } }
  @media (min-width: 1920px) {
    h3,
    .is-h3 {
      font-size: 2.44125em; } }

h4,
.is-h4 {
  margin: 0 0 1.375rem 0; }
  @media (min-width: 0px) {
    h4,
    .is-h4 {
      font-size: 1.36762em; } }
  @media (min-width: 720px) {
    h4,
    .is-h4 {
      font-size: 1.563em; } }
  @media (min-width: 1920px) {
    h4,
    .is-h4 {
      font-size: 1.95375em; } }

h5,
.is-h5 {
  margin: 0 0 0.6875rem 0; }
  @media (min-width: 0px) {
    h5,
    .is-h5 {
      font-size: 1.09375em; } }
  @media (min-width: 720px) {
    h5,
    .is-h5 {
      font-size: 1.25em; } }
  @media (min-width: 1920px) {
    h5,
    .is-h5 {
      font-size: 1.5625em; } }

h6,
.is-h6 {
  margin: 0 0 0.6875rem 0; }
  @media (min-width: 0px) {
    h6,
    .is-h6 {
      font-size: 0.875em; } }
  @media (min-width: 720px) {
    h6,
    .is-h6 {
      font-size: 1em; } }
  @media (min-width: 1920px) {
    h6,
    .is-h6 {
      font-size: 1.25em; } }

h1 .tag,
h2 .tag,
h3 .tag,
h4 .tag,
h5 .tag,
h6 .tag {
  font-size: 0.875rem;
  vertical-align: middle; }
  h1 .tag.tag_small,
  h2 .tag.tag_small,
  h3 .tag.tag_small,
  h4 .tag.tag_small,
  h5 .tag.tag_small,
  h6 .tag.tag_small {
    font-size: 0.76562rem; }
  h1 .tag.tag_big,
  h2 .tag.tag_big,
  h3 .tag.tag_big,
  h4 .tag.tag_big,
  h5 .tag.tag_big,
  h6 .tag.tag_big {
    font-size: 0.98438rem; }

h1 .bubble,
h2 .bubble,
h3 .bubble,
h4 .bubble,
h5 .bubble,
h6 .bubble {
  font-size: 0.875rem;
  vertical-align: middle; }
  h1 .bubble.bubble_small,
  h2 .bubble.bubble_small,
  h3 .bubble.bubble_small,
  h4 .bubble.bubble_small,
  h5 .bubble.bubble_small,
  h6 .bubble.bubble_small {
    font-size: 0.625rem; }
  h1 .bubble.bubble_big,
  h2 .bubble.bubble_big,
  h3 .bubble.bubble_big,
  h4 .bubble.bubble_big,
  h5 .bubble.bubble_big,
  h6 .bubble.bubble_big {
    font-size: 1rem; }

body {
  color: #2a3d54;
  background: #fff;
  font-family: sans-serif;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.4; }

a {
  cursor: pointer;
  outline: none;
  transition: color 0.2s;
  color: #2fce6f;
  font-weight: normal;
  text-decoration: none; }
  a * {
    cursor: pointer; }
  a:hover {
    color: #28c064;
    text-decoration: none; }

p {
  line-height: 1.4;
  font-weight: normal;
  margin: 0 0 1.375rem 0; }
  @media (min-width: 0px) {
    p {
      font-size: 0.875em; } }
  @media (min-width: 720px) {
    p {
      font-size: 1em; } }
  @media (min-width: 1920px) {
    p {
      font-size: 1.25em; } }
  p strong,
  p small {
    vertical-align: baseline; }

b,
strong {
  font-weight: bolder; }

small {
  vertical-align: baseline;
  font-size: 0.875em; }

code {
  font-family: monospace; }

.is-lead {
  font-weight: lighter;
  line-height: 1.4;
  margin: 0 0 1.375rem 0; }
  @media (min-width: 0px) {
    .is-lead {
      font-size: 1.09375em; } }
  @media (min-width: 720px) {
    .is-lead {
      font-size: 1.25em; } }
  @media (min-width: 1920px) {
    .is-lead {
      font-size: 1.5625em; } }

.is-unstyled {
  list-style: none;
  margin: 0;
  padding: 0; }

.is-inline {
  list-style: none;
  margin: 0;
  padding: 0; }
  .is-inline > li {
    display: inline-block; }

mark {
  background: #fcff66;
  color: #080c10; }

::selection {
  background: #fcff66;
  color: #080c10; }

::-moz-selection {
  background: #fcff66;
  color: #080c10; }

.img_responsive {
  height: auto;
  max-width: 100%; }

.img_full {
  height: auto;
  width: 100%; }

.avatar {
  display: inline-block;
  overflow: hidden;
  border: 0;
  border-radius: 50%;
  height: 2.5rem;
  width: 2.5rem; }
  @supports (display: inline-flex) {
    .avatar {
      display: inline-flex;
      flex-shrink: 0; } }
  @media (min-width: 0px) {
    .avatar-xs {
      height: 2.5rem;
      width: 2.5rem; } }
  @media (min-width: 480px) {
    .avatar-sm {
      height: 2.5rem;
      width: 2.5rem; } }
  @media (min-width: 720px) {
    .avatar-md {
      height: 2.5rem;
      width: 2.5rem; } }
  @media (min-width: 1024px) {
    .avatar-nav {
      height: 2.5rem;
      width: 2.5rem; } }
  @media (min-width: 1024px) {
    .avatar-lg {
      height: 2.5rem;
      width: 2.5rem; } }
  @media (min-width: 1280px) {
    .avatar-xl {
      height: 2.5rem;
      width: 2.5rem; } }
  @media (min-width: 1920px) {
    .avatar-xxl {
      height: 2.5rem;
      width: 2.5rem; } }
  .avatar img {
    height: 100%;
    object-fit: cover;
    object-position: center;
    width: 100%; }
  .avatar_square {
    border-radius: 0.25rem; }
  .avatar_round {
    border-radius: 50%; }
  .avatar_small {
    height: 2rem;
    width: 2rem; }
    @media (min-width: 0px) {
      .avatar_small-xs {
        height: 2rem;
        width: 2rem; } }
    @media (min-width: 480px) {
      .avatar_small-sm {
        height: 2rem;
        width: 2rem; } }
    @media (min-width: 720px) {
      .avatar_small-md {
        height: 2rem;
        width: 2rem; } }
    @media (min-width: 1024px) {
      .avatar_small-nav {
        height: 2rem;
        width: 2rem; } }
    @media (min-width: 1024px) {
      .avatar_small-lg {
        height: 2rem;
        width: 2rem; } }
    @media (min-width: 1280px) {
      .avatar_small-xl {
        height: 2rem;
        width: 2rem; } }
    @media (min-width: 1920px) {
      .avatar_small-xxl {
        height: 2rem;
        width: 2rem; } }
  .avatar_big {
    height: 3rem;
    width: 3rem; }
    @media (min-width: 0px) {
      .avatar_big-xs {
        height: 3rem;
        width: 3rem; } }
    @media (min-width: 480px) {
      .avatar_big-sm {
        height: 3rem;
        width: 3rem; } }
    @media (min-width: 720px) {
      .avatar_big-md {
        height: 3rem;
        width: 3rem; } }
    @media (min-width: 1024px) {
      .avatar_big-nav {
        height: 3rem;
        width: 3rem; } }
    @media (min-width: 1024px) {
      .avatar_big-lg {
        height: 3rem;
        width: 3rem; } }
    @media (min-width: 1280px) {
      .avatar_big-xl {
        height: 3rem;
        width: 3rem; } }
    @media (min-width: 1920px) {
      .avatar_big-xxl {
        height: 3rem;
        width: 3rem; } }

.img-crop-1-1 {
  padding-top: 100%;
  position: relative;
  width: 100%; }
  .img-crop-1-1 img {
    height: 100%;
    left: 0;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    width: 100%;
    vertical-align: top; }

.img-crop-3-4 {
  padding-top: 133.33333%;
  position: relative;
  width: 100%; }
  .img-crop-3-4 img {
    height: 100%;
    left: 0;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    width: 100%;
    vertical-align: top; }

.img-crop-4-3 {
  padding-top: 75%;
  position: relative;
  width: 100%; }
  .img-crop-4-3 img {
    height: 100%;
    left: 0;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    width: 100%;
    vertical-align: top; }

.img-crop-16-9 {
  padding-top: 56.25%;
  position: relative;
  width: 100%; }
  .img-crop-16-9 img {
    height: 100%;
    left: 0;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    width: 100%;
    vertical-align: top; }

.img-crop-golden {
  padding-top: 61.8047%;
  position: relative;
  width: 100%; }
  .img-crop-golden img {
    height: 100%;
    left: 0;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    width: 100%;
    vertical-align: top; }

.img-crop-silver {
  padding-top: 41.42502%;
  position: relative;
  width: 100%; }
  .img-crop-silver img {
    height: 100%;
    left: 0;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    width: 100%;
    vertical-align: top; }

.img-crop-ultrawide {
  padding-top: 27.77778%;
  position: relative;
  width: 100%; }
  .img-crop-ultrawide img {
    height: 100%;
    left: 0;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    width: 100%;
    vertical-align: top; }

fieldset {
  padding: 0;
  margin: 0;
  background: #fff;
  border: 0; }

.input {
  display: block;
  flex-grow: 1;
  outline: none;
  text-align: left;
  width: 100%;
  padding: 0.2375rem 0.475rem;
  font-size: 1rem;
  line-height: 1.4rem;
  min-height: 2rem;
  min-width: 2rem;
  border-style: solid;
  border-width: 0.0625rem;
  background: #fff;
  border-radius: 0.25rem;
  border-color: #cacfd4;
  border-style: solid;
  margin: 0;
  color: #2a3d54; }
  .input .bubble:first-child,
  .input .tag:first-child {
    margin-left: -0.2375rem;
    margin-right: 0.2375rem; }
  .input .bubble:last-child,
  .input .tag:last-child {
    margin-left: 0.2375rem;
    margin-right: -0.2375rem; }
  .input .bubble:only-child,
  .input .tag:only-child {
    margin-left: -0.475rem;
    margin-right: -0.475rem; }
  .input .ec-icon {
    padding-right: 0.2375rem; }
    @supports (display: inline-flex) {
      .input .ec-icon {
        display: inline-flex;
        align-items: center;
        justify-content: center; } }
    .input .ec-icon::before {
      font-size: 1em;
      line-height: inherit;
      vertical-align: top; }
    .input .ec-icon_right {
      padding-left: 0.2375rem;
      padding-right: 0; }
    .input .ec-icon:empty {
      padding-left: 0;
      padding-right: 0; }
      .input .ec-icon:empty:first-child {
        margin-left: -0.11875rem;
        margin-right: 0.2375rem; }
      .input .ec-icon:empty:last-child {
        margin-left: 0.2375rem;
        margin-right: -0.11875rem; }
      .input .ec-icon:empty:only-child {
        margin-left: -0.475rem;
        margin-right: -0.475rem; }
      .input .ec-icon:empty::before {
        margin-left: 0;
        margin-right: 0; }
  .input::-webkit-input-placeholder {
    color: rgba(42, 61, 84, 0.6); }
  .input::-moz-placeholder {
    color: rgba(42, 61, 84, 0.6); }
  .input::-moz-placeholder {
    color: rgba(42, 61, 84, 0.6); }
  .input:-ms-input-placeholder {
    color: rgba(42, 61, 84, 0.6); }

.input:hover {
  box-shadow: inset 0 0 0 2px rgba(47, 206, 111, 0.1); }
  .input:hover::-webkit-input-placeholder {
    color: #2a3d54; }
  .input:hover::-moz-placeholder {
    color: #2a3d54; }
  .input:hover::-moz-placeholder {
    color: #2a3d54; }
  .input:hover:-ms-input-placeholder {
    color: #2a3d54; }

.input:focus {
  outline: none;
  box-shadow: inset 0 0 0 2px rgba(47, 206, 111, 0.1); }
  .input:focus::-webkit-input-placeholder {
    color: #2a3d54; }
  .input:focus::-moz-placeholder {
    color: #2a3d54; }
  .input:focus::-moz-placeholder {
    color: #2a3d54; }
  .input:focus:-ms-input-placeholder {
    color: #2a3d54; }

.input.is-disabled, .input[disabled] {
  cursor: not-allowed;
  opacity: 0.4; }

select.input {
  height: 2em; }
  select.input[multiple] {
    height: auto; }
  select.input option {
    padding: 0 0.5rem; }

textarea.input {
  height: auto;
  resize: vertical; }

.input_clear {
  padding: 0.3rem 0.6rem;
  font-size: 1rem;
  line-height: 1.4rem;
  min-height: 2rem;
  min-width: 2rem;
  border: 0; }
  .input_clear .bubble:first-child,
  .input_clear .tag:first-child {
    margin-left: -0.3rem;
    margin-right: 0.3rem; }
  .input_clear .bubble:last-child,
  .input_clear .tag:last-child {
    margin-left: 0.3rem;
    margin-right: -0.3rem; }
  .input_clear .bubble:only-child,
  .input_clear .tag:only-child {
    margin-left: -0.6rem;
    margin-right: -0.6rem; }
  .input_clear .ec-icon {
    padding-right: 0.3rem; }
    @supports (display: inline-flex) {
      .input_clear .ec-icon {
        display: inline-flex;
        align-items: center;
        justify-content: center; } }
    .input_clear .ec-icon::before {
      font-size: 1em;
      line-height: inherit;
      vertical-align: top; }
    .input_clear .ec-icon_right {
      padding-left: 0.3rem;
      padding-right: 0; }
    .input_clear .ec-icon:empty {
      padding-left: 0;
      padding-right: 0; }
      .input_clear .ec-icon:empty:first-child {
        margin-left: -0.15rem;
        margin-right: 0.3rem; }
      .input_clear .ec-icon:empty:last-child {
        margin-left: 0.3rem;
        margin-right: -0.15rem; }
      .input_clear .ec-icon:empty:only-child {
        margin-left: -0.6rem;
        margin-right: -0.6rem; }
      .input_clear .ec-icon:empty::before {
        margin-left: 0;
        margin-right: 0; }
  .input_clear:focus, .input_clear:hover {
    box-shadow: none; }

.input:-webkit-autofill, .input:-webkit-autofill:hover, .input:-webkit-autofill:focus, .input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  color: #2a3d54;
  -webkit-text-fill-color: #2a3d54; }

.input_small {
  padding: 0.2rem 0.4rem;
  font-size: 0.875rem;
  line-height: 1.225rem;
  min-height: 1.75rem;
  min-width: 1.75rem;
  border-style: solid;
  border-width: 0.0625rem;
  border-radius: 0.25rem; }
  .input_small .bubble:first-child,
  .input_small .tag:first-child {
    margin-left: -0.2rem;
    margin-right: 0.2rem; }
  .input_small .bubble:last-child,
  .input_small .tag:last-child {
    margin-left: 0.2rem;
    margin-right: -0.2rem; }
  .input_small .bubble:only-child,
  .input_small .tag:only-child {
    margin-left: -0.4rem;
    margin-right: -0.4rem; }
  .input_small .ec-icon {
    padding-right: 0.2rem; }
    @supports (display: inline-flex) {
      .input_small .ec-icon {
        display: inline-flex;
        align-items: center;
        justify-content: center; } }
    .input_small .ec-icon::before {
      font-size: 1.14286em;
      line-height: inherit;
      vertical-align: top; }
    .input_small .ec-icon_right {
      padding-left: 0.2rem;
      padding-right: 0; }
    .input_small .ec-icon:empty {
      padding-left: 0;
      padding-right: 0; }
      .input_small .ec-icon:empty:first-child {
        margin-left: -0.1rem;
        margin-right: 0.2rem; }
      .input_small .ec-icon:empty:last-child {
        margin-left: 0.2rem;
        margin-right: -0.1rem; }
      .input_small .ec-icon:empty:only-child {
        margin-left: -0.4rem;
        margin-right: -0.4rem; }
      .input_small .ec-icon:empty::before {
        margin-left: 0;
        margin-right: 0; }
  select.input_small {
    height: 2em; }
  .input_small.input_clear {
    padding: 0.2625rem 0.525rem;
    font-size: 0.875rem;
    line-height: 1.225rem;
    min-height: 1.75rem;
    min-width: 1.75rem;
    border: 0; }
    .input_small.input_clear .bubble:first-child,
    .input_small.input_clear .tag:first-child {
      margin-left: -0.2625rem;
      margin-right: 0.2625rem; }
    .input_small.input_clear .bubble:last-child,
    .input_small.input_clear .tag:last-child {
      margin-left: 0.2625rem;
      margin-right: -0.2625rem; }
    .input_small.input_clear .bubble:only-child,
    .input_small.input_clear .tag:only-child {
      margin-left: -0.525rem;
      margin-right: -0.525rem; }
    .input_small.input_clear .ec-icon {
      padding-right: 0.2625rem; }
      @supports (display: inline-flex) {
        .input_small.input_clear .ec-icon {
          display: inline-flex;
          align-items: center;
          justify-content: center; } }
      .input_small.input_clear .ec-icon::before {
        font-size: 1.14286em;
        line-height: inherit;
        vertical-align: top; }
      .input_small.input_clear .ec-icon_right {
        padding-left: 0.2625rem;
        padding-right: 0; }
      .input_small.input_clear .ec-icon:empty {
        padding-left: 0;
        padding-right: 0; }
        .input_small.input_clear .ec-icon:empty:first-child {
          margin-left: -0.13125rem;
          margin-right: 0.2625rem; }
        .input_small.input_clear .ec-icon:empty:last-child {
          margin-left: 0.2625rem;
          margin-right: -0.13125rem; }
        .input_small.input_clear .ec-icon:empty:only-child {
          margin-left: -0.525rem;
          margin-right: -0.525rem; }
        .input_small.input_clear .ec-icon:empty::before {
          margin-left: 0;
          margin-right: 0; }

.input_big {
  padding: 0.4rem 0.8rem;
  font-size: 1.125rem;
  line-height: 1.575rem;
  min-height: 2.5rem;
  min-width: 2.5rem;
  border-style: solid;
  border-width: 0.0625rem;
  border-radius: 0.25rem; }
  .input_big .bubble:first-child,
  .input_big .tag:first-child {
    margin-left: -0.4rem;
    margin-right: 0.4rem; }
  .input_big .bubble:last-child,
  .input_big .tag:last-child {
    margin-left: 0.4rem;
    margin-right: -0.4rem; }
  .input_big .bubble:only-child,
  .input_big .tag:only-child {
    margin-left: -0.8rem;
    margin-right: -0.8rem; }
  .input_big .ec-icon {
    padding-right: 0.4rem; }
    @supports (display: inline-flex) {
      .input_big .ec-icon {
        display: inline-flex;
        align-items: center;
        justify-content: center; } }
    .input_big .ec-icon::before {
      font-size: 0.88889em;
      line-height: inherit;
      vertical-align: top; }
    .input_big .ec-icon_right {
      padding-left: 0.4rem;
      padding-right: 0; }
    .input_big .ec-icon:empty {
      padding-left: 0;
      padding-right: 0; }
      .input_big .ec-icon:empty:first-child {
        margin-left: -0.2rem;
        margin-right: 0.4rem; }
      .input_big .ec-icon:empty:last-child {
        margin-left: 0.4rem;
        margin-right: -0.2rem; }
      .input_big .ec-icon:empty:only-child {
        margin-left: -0.8rem;
        margin-right: -0.8rem; }
      .input_big .ec-icon:empty::before {
        margin-left: 0;
        margin-right: 0; }
  select.input_small {
    height: 2.22222em; }
  .input_big.input_clear {
    padding: 0.4625rem 0.925rem;
    font-size: 1.125rem;
    line-height: 1.575rem;
    min-height: 2.5rem;
    min-width: 2.5rem;
    border: 0; }
    .input_big.input_clear .bubble:first-child,
    .input_big.input_clear .tag:first-child {
      margin-left: -0.4625rem;
      margin-right: 0.4625rem; }
    .input_big.input_clear .bubble:last-child,
    .input_big.input_clear .tag:last-child {
      margin-left: 0.4625rem;
      margin-right: -0.4625rem; }
    .input_big.input_clear .bubble:only-child,
    .input_big.input_clear .tag:only-child {
      margin-left: -0.925rem;
      margin-right: -0.925rem; }
    .input_big.input_clear .ec-icon {
      padding-right: 0.4625rem; }
      @supports (display: inline-flex) {
        .input_big.input_clear .ec-icon {
          display: inline-flex;
          align-items: center;
          justify-content: center; } }
      .input_big.input_clear .ec-icon::before {
        font-size: 0.88889em;
        line-height: inherit;
        vertical-align: top; }
      .input_big.input_clear .ec-icon_right {
        padding-left: 0.4625rem;
        padding-right: 0; }
      .input_big.input_clear .ec-icon:empty {
        padding-left: 0;
        padding-right: 0; }
        .input_big.input_clear .ec-icon:empty:first-child {
          margin-left: -0.23125rem;
          margin-right: 0.4625rem; }
        .input_big.input_clear .ec-icon:empty:last-child {
          margin-left: 0.4625rem;
          margin-right: -0.23125rem; }
        .input_big.input_clear .ec-icon:empty:only-child {
          margin-left: -0.925rem;
          margin-right: -0.925rem; }
        .input_big.input_clear .ec-icon:empty::before {
          margin-left: 0;
          margin-right: 0; }

[type='checkbox'],
[type='file'],
[type='image'],
[type='radio'] {
  display: inline-block;
  height: auto;
  width: auto; }

[type='checkbox'],
[type='radio'] {
  line-height: normal;
  padding: 0;
  vertical-align: middle; }
  [type='checkbox'] + small,
  [type='radio'] + small {
    cursor: pointer;
    display: inline-block; }

[type='range'].slider {
  appearance: none;
  width: 100%;
  outline: none;
  margin: 0;
  height: 0.5rem;
  background: rgba(0, 0, 0, 0.04);
  border: 0;
  border-radius: 0.25rem; }

[type='range'].slider::-webkit-slider-runnable-track {
  appearance: none;
  width: 100%;
  outline: none;
  margin: 0;
  height: 0.5rem;
  background: rgba(0, 0, 0, 0.04);
  border: 0;
  border-radius: 0.25rem; }

[type='range'].slider::-moz-range-track {
  appearance: none;
  width: 100%;
  outline: none;
  margin: 0;
  height: 0.5rem;
  background: rgba(0, 0, 0, 0.04);
  border: 0;
  border-radius: 0.25rem; }

[type='range'].slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  border: 0;
  box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.2);
  width: 1rem;
  height: 1rem;
  background: #2fce6f;
  border-radius: 0.5rem;
  margin-top: -0.25rem; }
  [type='range'].slider::-webkit-slider-thumb:hover {
    box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.2); }

[type='range'].slider::-moz-range-thumb {
  cursor: pointer;
  border: 0;
  box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.2);
  width: 1rem;
  height: 1rem;
  background: #2fce6f;
  border-radius: 0.5rem; }
  [type='range'].slider::-moz-range-thumb:hover {
    box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.2); }

.file-upload {
  cursor: pointer;
  display: block;
  position: relative;
  font-size: 1rem;
  text-align: center;
  background: none;
  padding: 1rem;
  margin: 0 0 1rem;
  border-radius: 0.25rem;
  border-width: 0.125rem;
  border-color: rgba(47, 206, 111, 0.2);
  border-style: dashed; }
  @supports (display: flex) {
    .file-upload {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; } }
  .file-upload [type='file'] {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    opacity: 0; }

.input-group {
  align-items: stretch;
  display: flex;
  position: relative;
  margin: 0; }
  .input-group .input-group__addon {
    display: flex;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: center;
    width: auto;
    color: rgba(42, 61, 84, 0.6);
    background: #f7f7f7;
    border-width: 0.0625rem;
    border-color: #cacfd4;
    border-style: solid;
    border-radius: 0.25rem;
    padding: 0 0.5rem;
    min-width: 2rem; }
  .input-group .input-group__addon:not(:only-child):first-child,
  .input-group > .btn:not(:only-child):first-child,
  .input-group > .input:not(:only-child):first-child {
    border-bottom-right-radius: 0;
    border-right: 0;
    border-top-right-radius: 0; }
  .input-group .input-group__addon:not(:only-child):last-child,
  .input-group > .btn:not(:only-child):last-child,
  .input-group > .input:not(:only-child):last-child {
    border-bottom-left-radius: 0;
    border-left: 0;
    border-top-left-radius: 0; }
  .input-group .input-group__addon:not(:last-child):not(:first-child),
  .input-group > .btn:not(:last-child):not(:first-child),
  .input-group > .input:not(:last-child):not(:first-child) {
    border-left: 0;
    border-radius: 0;
    border-right: 0; }
  .input-group > .btn {
    align-items: center;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: center; }
    .input-group > .btn:active {
      transform: initial; }
    .input-group > .btn:first-child.btn_outlined {
      border-right-style: solid;
      border-right-width: 0.125rem; }
    .input-group > .btn.btn_clear + .input,
    .input-group > .btn.btn_clear + .input-group__addon {
      border-bottom-left-radius: 0.25rem;
      border-left: 0.0625rem solid #cacfd4;
      border-top-left-radius: 0.25rem;
      margin-left: 0.25rem; }
  .input-group .input-group__addon + .btn.btn_clear::before {
    border-width: 0.0625rem;
    border-style: solid;
    border-color: #cacfd4;
    background: #f7f7f7; }
  .input-group .input {
    margin: 0; }
    .input-group .input + .btn.btn_clear::before {
      border-width: 0.0625rem;
      border-style: solid;
      border-color: #cacfd4;
      background: #fff; }
  .input-group .input + .btn.btn_clear,
  .input-group .input-group__addon + .btn.btn_clear {
    margin-left: 0.5rem; }
    .input-group .input + .btn.btn_clear::before,
    .input-group .input-group__addon + .btn.btn_clear::before {
      content: '';
      position: absolute;
      top: 0;
      border-left: 0;
      height: 100%;
      border-radius: 0 0.25rem 0.25rem 0;
      left: -0.5rem;
      width: 0.25rem; }
  .input-group .input + .btn.btn_outlined,
  .input-group .input-group__addon + .btn.btn_outlined {
    border-left-style: solid;
    border-left-width: 0.125rem; }

.input_round {
  border-radius: 1rem; }
  .input_round.input_small {
    border-radius: 0.875rem; }
  .input_round.input_big {
    border-radius: 1.25rem; }

.input.ng-touched.ng-valid {
  border-color: #22e722;
  color: #15c115; }

.input.ng-touched.ng-invalid {
  border-color: #e72222;
  color: #c11515; }

.xui-select {
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-style: solid;
  flex-grow: 1;
  outline: none;
  text-align: left;
  position: relative;
  padding: 0.2375rem 0.475rem;
  font-size: 1rem;
  line-height: 1.4rem;
  min-height: 2rem;
  min-width: 2rem;
  border-style: solid;
  border-width: 0.0625rem;
  background: #fff;
  border-color: #cacfd4;
  color: #2a3d54;
  margin: 0;
  border-radius: 0.25rem; }
  .xui-select .bubble:first-child,
  .xui-select .tag:first-child {
    margin-left: -0.2375rem;
    margin-right: 0.2375rem; }
  .xui-select .bubble:last-child,
  .xui-select .tag:last-child {
    margin-left: 0.2375rem;
    margin-right: -0.2375rem; }
  .xui-select .bubble:only-child,
  .xui-select .tag:only-child {
    margin-left: -0.475rem;
    margin-right: -0.475rem; }
  .xui-select .ec-icon {
    padding-right: 0.2375rem; }
    @supports (display: inline-flex) {
      .xui-select .ec-icon {
        display: inline-flex;
        align-items: center;
        justify-content: center; } }
    .xui-select .ec-icon::before {
      font-size: 1em;
      line-height: inherit;
      vertical-align: top; }
    .xui-select .ec-icon_right {
      padding-left: 0.2375rem;
      padding-right: 0; }
    .xui-select .ec-icon:empty {
      padding-left: 0;
      padding-right: 0; }
      .xui-select .ec-icon:empty:first-child {
        margin-left: -0.11875rem;
        margin-right: 0.2375rem; }
      .xui-select .ec-icon:empty:last-child {
        margin-left: 0.2375rem;
        margin-right: -0.11875rem; }
      .xui-select .ec-icon:empty:only-child {
        margin-left: -0.475rem;
        margin-right: -0.475rem; }
      .xui-select .ec-icon:empty::before {
        margin-left: 0;
        margin-right: 0; }
  .xui-select::after {
    content: '';
    border: 4px solid transparent;
    border-top-color: rgba(42, 61, 84, 0.2);
    flex-grow: 0;
    flex-shrink: 0;
    margin-top: 0.25rem;
    transition: border-top-color 0.2s;
    z-index: 1; }
  .xui-select:hover {
    box-shadow: 0 0 0 2px rgba(47, 206, 111, 0.1); }
    .xui-select:hover::after {
      border-top-color: rgba(42, 61, 84, 0.4); }
    .xui-select:hover .xui-select__placeholder {
      color: #2a3d54; }
  .xui-select:focus::after {
    margin-bottom: 0.25rem;
    margin-top: 0;
    transform: rotate(180deg);
    z-index: 11; }
  .xui-select:focus .xui-select-options {
    clip-path: initial;
    opacity: 1;
    visibility: visible; }
  .xui-select .xui-select__placeholder {
    flex-grow: 1;
    flex-shrink: 0;
    user-select: none;
    color: rgba(42, 61, 84, 0.6); }
  .xui-select.xui-select_top .xui-select-options {
    bottom: 100%;
    top: auto; }

.xui-select-options {
  width: 100%;
  opacity: 0;
  list-style: none;
  position: absolute;
  left: 0;
  top: 100%;
  margin: 0;
  transition: visibility 0.5s, opacity 0.2s;
  visibility: hidden;
  z-index: 10;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  background: #fff;
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem -0.25rem rgba(0, 0, 0, 0.16) , 0 0.25rem 1.5rem 0 rgba(0, 0, 0, 0.1);
  max-height: 15rem;
  padding: 0.25rem; }

.xui-select-option {
  outline: none;
  padding: 0.125rem 0; }
  .xui-select-option a:not(.btn) {
    display: block;
    outline: none;
    transition: background-color 0.4s, color 0.4s;
    background: none;
    color: #2a3d54;
    border-radius: 0.25rem;
    padding: 0.25rem;
    font-size: 1em; }
    .xui-select-option a:not(.btn):hover {
      background: rgba(47, 206, 111, 0.1);
      color: inherit; }
  .xui-select-option.is-selected a:not(.btn) {
    background: rgba(47, 206, 111, 0.1);
    color: inherit; }

.xui-checkbox label {
  cursor: pointer;
  display: flex;
  align-items: flex-start; }

.xui-checkbox [type='checkbox'] + label::before {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
  text-align: center;
  overflow: hidden;
  transition: all 0.2s;
  box-shadow: inset 0 1px 2px 1px rgba(0, 0, 0, 0.2);
  content: "✔︎";
  font-family: sans-serif;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  height: 1.25rem;
  line-height: 2.75rem;
  margin: 0 0.5rem 0 0;
  width: 1.25rem;
  background-color: #fff;
  color: transparent; }

@media (hover: hover) {
  .xui-checkbox [type='checkbox'] + label:hover::before {
    background-color: inherit;
    color: rgba(42, 61, 84, 0.2);
    line-height: 1.375rem; } }

.xui-checkbox [type='checkbox']:checked + label::before {
  background-color: inherit;
  color: #2fce6f;
  line-height: 1.375rem; }

.xui-radio label {
  cursor: pointer;
  display: flex;
  align-items: flex-start; }

.xui-radio [type='radio'] + label::before {
  display: inline-flex;
  flex-grow: 0;
  flex-shrink: 0;
  font-family: initial;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: all 0.2s;
  box-shadow: inset 0 1px 2px 1px rgba(0, 0, 0, 0.2);
  content: "⬤";
  font-family: sans-serif;
  border-radius: 0.625rem;
  background: #fff;
  color: transparent;
  font-size: 0.15625rem;
  line-height: 1.25rem;
  height: 1.25rem;
  width: 1.25rem;
  margin: 0 0.5rem 0 0; }

@media (hover: hover) {
  .xui-radio [type='radio'] + label:hover::before {
    background-color: inherit;
    color: rgba(42, 61, 84, 0.2);
    font-size: 0.625rem; } }

.xui-radio [type='radio']:checked + label::before {
  background-color: inherit;
  color: #2fce6f;
  font-size: 0.625rem; }

.xui-toggle .xui-toggle__switch {
  cursor: pointer;
  direction: ltr;
  display: inline-block;
  background: #d5f5e2;
  border-radius: 0.5rem;
  height: 1rem;
  width: 1.75rem;
  transition: background-color 0.2s; }
  .xui-toggle .xui-toggle__switch::after {
    content: '';
    display: block;
    box-shadow: 0 2px 8px 1px rgba(0, 0, 0, 0.2);
    background: #fff;
    border-radius: 0.5rem;
    height: 1rem;
    width: 1rem;
    margin: 0 -0.0625rem; }

.xui-toggle [type='radio']:checked + label .xui-toggle__switch,
.xui-toggle [type='checkbox']:checked + label .xui-toggle__switch, .xui-toggle [type='radio']:checked + label.xui-toggle__switch,
.xui-toggle [type='checkbox']:checked + label.xui-toggle__switch {
  direction: rtl;
  background: #2fce6f; }

.xui-toggle {
  min-height: 2rem;
  margin: 0 0 0.5rem; }
  @supports (display: flex) {
    .xui-toggle {
      display: flex;
      align-items: center; } }
  .xui-toggle:hover .xui-toggle__switch {
    background: #c4f2d6; }
  .xui-toggle .xui-toggle__switch {
    margin-top: 0.2rem; }
  .xui-toggle label {
    cursor: pointer;
    display: flex;
    user-select: none;
    margin: 0;
    font-size: 1em;
    line-height: 1.4em; }
    .xui-toggle label .xui-toggle__switch {
      margin-right: 0.5rem; }

.btn, .hamburger-btn, .dialog-dismiss, .deck-dismiss, .toast-dismiss, .snackbar-dismiss, .xui-overlay__dismiss, .overlay-dismiss {
  cursor: pointer;
  appearance: none;
  user-select: none;
  outline: none;
  touch-action: manipulation;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
  position: relative;
  height: auto;
  border: 0;
  display: inline-block;
  font-family: sans-serif;
  margin: 0;
  padding: 0.55rem 0.825rem;
  font-size: 1rem;
  line-height: 1.4rem;
  min-height: 2.5rem;
  min-width: 2.5rem;
  border-radius: 0.25rem;
  font-weight: bolder; }
  @supports (display: inline-flex) {
    .btn, .hamburger-btn, .dialog-dismiss, .deck-dismiss, .toast-dismiss, .snackbar-dismiss, .xui-overlay__dismiss, .overlay-dismiss {
      display: inline-flex;
      align-items: center;
      justify-content: center; } }
  .btn .bubble:first-child, .hamburger-btn .bubble:first-child, .dialog-dismiss .bubble:first-child, .deck-dismiss .bubble:first-child, .toast-dismiss .bubble:first-child, .snackbar-dismiss .bubble:first-child, .xui-overlay__dismiss .bubble:first-child, .overlay-dismiss .bubble:first-child,
  .btn .tag:first-child,
  .hamburger-btn .tag:first-child,
  .dialog-dismiss .tag:first-child,
  .deck-dismiss .tag:first-child,
  .toast-dismiss .tag:first-child,
  .snackbar-dismiss .tag:first-child,
  .xui-overlay__dismiss .tag:first-child,
  .overlay-dismiss .tag:first-child {
    margin-left: -0.55rem;
    margin-right: 0.55rem; }
  .btn .bubble:last-child, .hamburger-btn .bubble:last-child, .dialog-dismiss .bubble:last-child, .deck-dismiss .bubble:last-child, .toast-dismiss .bubble:last-child, .snackbar-dismiss .bubble:last-child, .xui-overlay__dismiss .bubble:last-child, .overlay-dismiss .bubble:last-child,
  .btn .tag:last-child,
  .hamburger-btn .tag:last-child,
  .dialog-dismiss .tag:last-child,
  .deck-dismiss .tag:last-child,
  .toast-dismiss .tag:last-child,
  .snackbar-dismiss .tag:last-child,
  .xui-overlay__dismiss .tag:last-child,
  .overlay-dismiss .tag:last-child {
    margin-left: 0.55rem;
    margin-right: -0.55rem; }
  .btn .bubble:only-child, .hamburger-btn .bubble:only-child, .dialog-dismiss .bubble:only-child, .deck-dismiss .bubble:only-child, .toast-dismiss .bubble:only-child, .snackbar-dismiss .bubble:only-child, .xui-overlay__dismiss .bubble:only-child, .overlay-dismiss .bubble:only-child,
  .btn .tag:only-child,
  .hamburger-btn .tag:only-child,
  .dialog-dismiss .tag:only-child,
  .deck-dismiss .tag:only-child,
  .toast-dismiss .tag:only-child,
  .snackbar-dismiss .tag:only-child,
  .xui-overlay__dismiss .tag:only-child,
  .overlay-dismiss .tag:only-child {
    margin-left: -0.825rem;
    margin-right: -0.825rem; }
  .btn .ec-icon, .hamburger-btn .ec-icon, .dialog-dismiss .ec-icon, .deck-dismiss .ec-icon, .toast-dismiss .ec-icon, .snackbar-dismiss .ec-icon, .xui-overlay__dismiss .ec-icon, .overlay-dismiss .ec-icon {
    padding-right: 0.55rem; }
    @supports (display: inline-flex) {
      .btn .ec-icon, .hamburger-btn .ec-icon, .dialog-dismiss .ec-icon, .deck-dismiss .ec-icon, .toast-dismiss .ec-icon, .snackbar-dismiss .ec-icon, .xui-overlay__dismiss .ec-icon, .overlay-dismiss .ec-icon {
        display: inline-flex;
        align-items: center;
        justify-content: center; } }
    .btn .ec-icon::before, .hamburger-btn .ec-icon::before, .dialog-dismiss .ec-icon::before, .deck-dismiss .ec-icon::before, .toast-dismiss .ec-icon::before, .snackbar-dismiss .ec-icon::before, .xui-overlay__dismiss .ec-icon::before, .overlay-dismiss .ec-icon::before {
      font-size: 1em;
      line-height: inherit;
      vertical-align: top; }
    .btn .ec-icon_right, .hamburger-btn .ec-icon_right, .dialog-dismiss .ec-icon_right, .deck-dismiss .ec-icon_right, .toast-dismiss .ec-icon_right, .snackbar-dismiss .ec-icon_right, .xui-overlay__dismiss .ec-icon_right, .overlay-dismiss .ec-icon_right {
      padding-left: 0.55rem;
      padding-right: 0; }
    .btn .ec-icon:empty, .hamburger-btn .ec-icon:empty, .dialog-dismiss .ec-icon:empty, .deck-dismiss .ec-icon:empty, .toast-dismiss .ec-icon:empty, .snackbar-dismiss .ec-icon:empty, .xui-overlay__dismiss .ec-icon:empty, .overlay-dismiss .ec-icon:empty {
      padding-left: 0;
      padding-right: 0; }
      .btn .ec-icon:empty:first-child, .hamburger-btn .ec-icon:empty:first-child, .dialog-dismiss .ec-icon:empty:first-child, .deck-dismiss .ec-icon:empty:first-child, .toast-dismiss .ec-icon:empty:first-child, .snackbar-dismiss .ec-icon:empty:first-child, .xui-overlay__dismiss .ec-icon:empty:first-child, .overlay-dismiss .ec-icon:empty:first-child {
        margin-left: -0.275rem;
        margin-right: 0.55rem; }
      .btn .ec-icon:empty:last-child, .hamburger-btn .ec-icon:empty:last-child, .dialog-dismiss .ec-icon:empty:last-child, .deck-dismiss .ec-icon:empty:last-child, .toast-dismiss .ec-icon:empty:last-child, .snackbar-dismiss .ec-icon:empty:last-child, .xui-overlay__dismiss .ec-icon:empty:last-child, .overlay-dismiss .ec-icon:empty:last-child {
        margin-left: 0.55rem;
        margin-right: -0.275rem; }
      .btn .ec-icon:empty:only-child, .hamburger-btn .ec-icon:empty:only-child, .dialog-dismiss .ec-icon:empty:only-child, .deck-dismiss .ec-icon:empty:only-child, .toast-dismiss .ec-icon:empty:only-child, .snackbar-dismiss .ec-icon:empty:only-child, .xui-overlay__dismiss .ec-icon:empty:only-child, .overlay-dismiss .ec-icon:empty:only-child {
        margin-left: -0.825rem;
        margin-right: -0.825rem; }
      .btn .ec-icon:empty::before, .hamburger-btn .ec-icon:empty::before, .dialog-dismiss .ec-icon:empty::before, .deck-dismiss .ec-icon:empty::before, .toast-dismiss .ec-icon:empty::before, .snackbar-dismiss .ec-icon:empty::before, .xui-overlay__dismiss .ec-icon:empty::before, .overlay-dismiss .ec-icon:empty::before {
        margin-left: 0;
        margin-right: 0; }

.btn.btn_small {
  cursor: pointer;
  appearance: none;
  user-select: none;
  outline: none;
  touch-action: manipulation;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
  position: relative;
  height: auto;
  border: 0;
  display: inline-block;
  font-family: sans-serif;
  margin: 0;
  padding: 0.3875rem 0.58125rem;
  font-size: 0.875rem;
  line-height: 1.225rem;
  min-height: 2rem;
  min-width: 2rem;
  border-radius: 0.25rem;
  font-weight: bolder; }
  @supports (display: inline-flex) {
    .btn.btn_small {
      display: inline-flex;
      align-items: center;
      justify-content: center; } }
  .btn.btn_small .bubble:first-child,
  .btn.btn_small .tag:first-child {
    margin-left: -0.3875rem;
    margin-right: 0.3875rem; }
  .btn.btn_small .bubble:last-child,
  .btn.btn_small .tag:last-child {
    margin-left: 0.3875rem;
    margin-right: -0.3875rem; }
  .btn.btn_small .bubble:only-child,
  .btn.btn_small .tag:only-child {
    margin-left: -0.58125rem;
    margin-right: -0.58125rem; }
  .btn.btn_small .ec-icon {
    padding-right: 0.3875rem; }
    @supports (display: inline-flex) {
      .btn.btn_small .ec-icon {
        display: inline-flex;
        align-items: center;
        justify-content: center; } }
    .btn.btn_small .ec-icon::before {
      font-size: 1.14286em;
      line-height: inherit;
      vertical-align: top; }
    .btn.btn_small .ec-icon_right {
      padding-left: 0.3875rem;
      padding-right: 0; }
    .btn.btn_small .ec-icon:empty {
      padding-left: 0;
      padding-right: 0; }
      .btn.btn_small .ec-icon:empty:first-child {
        margin-left: -0.19375rem;
        margin-right: 0.3875rem; }
      .btn.btn_small .ec-icon:empty:last-child {
        margin-left: 0.3875rem;
        margin-right: -0.19375rem; }
      .btn.btn_small .ec-icon:empty:only-child {
        margin-left: -0.58125rem;
        margin-right: -0.58125rem; }
      .btn.btn_small .ec-icon:empty::before {
        margin-left: 0;
        margin-right: 0; }

.btn.btn_big {
  cursor: pointer;
  appearance: none;
  user-select: none;
  outline: none;
  touch-action: manipulation;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
  position: relative;
  height: auto;
  border: 0;
  display: inline-block;
  font-family: sans-serif;
  margin: 0;
  padding: 0.7125rem 1.06875rem;
  font-size: 1.125rem;
  line-height: 1.575rem;
  min-height: 3rem;
  min-width: 3rem;
  border-radius: 0.25rem;
  font-weight: bolder; }
  @supports (display: inline-flex) {
    .btn.btn_big {
      display: inline-flex;
      align-items: center;
      justify-content: center; } }
  .btn.btn_big .bubble:first-child,
  .btn.btn_big .tag:first-child {
    margin-left: -0.7125rem;
    margin-right: 0.7125rem; }
  .btn.btn_big .bubble:last-child,
  .btn.btn_big .tag:last-child {
    margin-left: 0.7125rem;
    margin-right: -0.7125rem; }
  .btn.btn_big .bubble:only-child,
  .btn.btn_big .tag:only-child {
    margin-left: -1.06875rem;
    margin-right: -1.06875rem; }
  .btn.btn_big .ec-icon {
    padding-right: 0.7125rem; }
    @supports (display: inline-flex) {
      .btn.btn_big .ec-icon {
        display: inline-flex;
        align-items: center;
        justify-content: center; } }
    .btn.btn_big .ec-icon::before {
      font-size: 0.88889em;
      line-height: inherit;
      vertical-align: top; }
    .btn.btn_big .ec-icon_right {
      padding-left: 0.7125rem;
      padding-right: 0; }
    .btn.btn_big .ec-icon:empty {
      padding-left: 0;
      padding-right: 0; }
      .btn.btn_big .ec-icon:empty:first-child {
        margin-left: -0.35625rem;
        margin-right: 0.7125rem; }
      .btn.btn_big .ec-icon:empty:last-child {
        margin-left: 0.7125rem;
        margin-right: -0.35625rem; }
      .btn.btn_big .ec-icon:empty:only-child {
        margin-left: -1.06875rem;
        margin-right: -1.06875rem; }
      .btn.btn_big .ec-icon:empty::before {
        margin-left: 0;
        margin-right: 0; }

.btn {
  background: #2fce6f;
  color: white;
  transition: background-color 0.2s, box-shadow 0.1s; }
  .btn * {
    cursor: pointer; }
  .btn strong {
    vertical-align: baseline; }
  .btn.is-active, .btn:hover, .btn:focus {
    outline: none;
    background: #28c064;
    color: white; }
  .btn.is-disabled, .btn[disabled] {
    cursor: not-allowed;
    box-shadow: none;
    opacity: 0.6; }
  .btn:active {
    transform: translateY(2px); }
  .btn.btn_wide {
    min-width: 15rem; }
    .btn.btn_wide.btn_small {
      min-width: 10rem; }
    .btn.btn_wide.btn_big {
      min-width: 15rem; }

.btn_block {
  display: block;
  width: 100%; }
  @supports (display: flex) {
    .btn_block {
      display: flex;
      width: initial; } }

.btn_minor {
  background: rgba(47, 206, 111, 0.2);
  color: #192432; }
  .btn_minor.is-active, .btn_minor:hover, .btn_minor:focus {
    background: rgba(47, 206, 111, 0.2);
    color: #192432; }

.btn_super {
  background: #14a656;
  color: white; }
  .btn_super.is-active, .btn_super:hover, .btn_super:focus {
    background: #19b561;
    color: white; }

.btn_invert {
  background: #fff;
  color: #2fce6f; }
  .btn_invert.is-active, .btn_invert:hover, .btn_invert:focus {
    background: whitesmoke;
    color: #28c064; }

.btn_clear {
  background: transparent;
  color: #2fce6f; }
  .btn_clear.is-active, .btn_clear:hover, .btn_clear:focus {
    background: transparent;
    color: #28c064; }

.btn .tag,
.btn .bubble {
  margin-bottom: 0;
  margin-top: 0; }

.btn .tag {
  margin-top: -0.175rem;
  margin-bottom: -0.175rem; }
  .btn .tag.tag_small {
    margin-top: -0.05rem;
    margin-bottom: -0.05rem; }
  .btn .tag.tag_big {
    margin-top: -0.3rem;
    margin-bottom: -0.3rem; }

.btn .bubble {
  margin-top: -0.175rem;
  margin-bottom: -0.175rem; }
  .btn .bubble.bubble_small {
    margin-top: -0.05rem;
    margin-bottom: -0.05rem; }
  .btn .bubble.bubble_big {
    margin-top: -0.3rem;
    margin-bottom: -0.3rem; }

.btn_small .tag {
  margin-top: -0.2625rem;
  margin-bottom: -0.2625rem; }
  .btn_small .tag.tag_small {
    margin-top: -0.1375rem;
    margin-bottom: -0.1375rem; }
  .btn_small .tag.tag_big {
    margin-top: -0.3875rem;
    margin-bottom: -0.3875rem; }

.btn_small .bubble {
  margin-top: -0.2625rem;
  margin-bottom: -0.2625rem; }
  .btn_small .bubble.bubble_small {
    margin-top: -0.1375rem;
    margin-bottom: -0.1375rem; }
  .btn_small .bubble.bubble_big {
    margin-top: -0.3875rem;
    margin-bottom: -0.3875rem; }

.btn_big .tag {
  margin-top: -0.0875rem;
  margin-bottom: -0.0875rem; }
  .btn_big .tag.tag_small {
    margin-top: 0.0375rem;
    margin-bottom: 0.0375rem; }
  .btn_big .tag.tag_big {
    margin-top: -0.2125rem;
    margin-bottom: -0.2125rem; }

.btn_big .bubble {
  margin-top: -0.0875rem;
  margin-bottom: -0.0875rem; }
  .btn_big .bubble.bubble_small {
    margin-top: 0.0375rem;
    margin-bottom: 0.0375rem; }
  .btn_big .bubble.bubble_big {
    margin-top: -0.2125rem;
    margin-bottom: -0.2125rem; }

.btn_outlined {
  padding: 0.425rem 0.6375rem;
  font-size: 1rem;
  line-height: 1.4rem;
  min-height: 2.5rem;
  min-width: 2.5rem;
  border-style: solid;
  border-width: 0.125rem;
  border-style: solid;
  border-color: #2fce6f;
  color: #2fce6f;
  background: none; }
  .btn_outlined .bubble:first-child,
  .btn_outlined .tag:first-child {
    margin-left: -0.425rem;
    margin-right: 0.425rem; }
  .btn_outlined .bubble:last-child,
  .btn_outlined .tag:last-child {
    margin-left: 0.425rem;
    margin-right: -0.425rem; }
  .btn_outlined .bubble:only-child,
  .btn_outlined .tag:only-child {
    margin-left: -0.6375rem;
    margin-right: -0.6375rem; }
  .btn_outlined .ec-icon {
    padding-right: 0.425rem; }
    @supports (display: inline-flex) {
      .btn_outlined .ec-icon {
        display: inline-flex;
        align-items: center;
        justify-content: center; } }
    .btn_outlined .ec-icon::before {
      font-size: 1em;
      line-height: inherit;
      vertical-align: top; }
    .btn_outlined .ec-icon_right {
      padding-left: 0.425rem;
      padding-right: 0; }
    .btn_outlined .ec-icon:empty {
      padding-left: 0;
      padding-right: 0; }
      .btn_outlined .ec-icon:empty:first-child {
        margin-left: -0.2125rem;
        margin-right: 0.425rem; }
      .btn_outlined .ec-icon:empty:last-child {
        margin-left: 0.425rem;
        margin-right: -0.2125rem; }
      .btn_outlined .ec-icon:empty:only-child {
        margin-left: -0.6375rem;
        margin-right: -0.6375rem; }
      .btn_outlined .ec-icon:empty::before {
        margin-left: 0;
        margin-right: 0; }
  .btn_outlined.btn_small {
    padding: 0.2625rem 0.39375rem;
    font-size: 0.875rem;
    line-height: 1.225rem;
    min-height: 2rem;
    min-width: 2rem;
    border-style: solid;
    border-width: 0.125rem; }
    .btn_outlined.btn_small .bubble:first-child,
    .btn_outlined.btn_small .tag:first-child {
      margin-left: -0.2625rem;
      margin-right: 0.2625rem; }
    .btn_outlined.btn_small .bubble:last-child,
    .btn_outlined.btn_small .tag:last-child {
      margin-left: 0.2625rem;
      margin-right: -0.2625rem; }
    .btn_outlined.btn_small .bubble:only-child,
    .btn_outlined.btn_small .tag:only-child {
      margin-left: -0.39375rem;
      margin-right: -0.39375rem; }
    .btn_outlined.btn_small .ec-icon {
      padding-right: 0.2625rem; }
      @supports (display: inline-flex) {
        .btn_outlined.btn_small .ec-icon {
          display: inline-flex;
          align-items: center;
          justify-content: center; } }
      .btn_outlined.btn_small .ec-icon::before {
        font-size: 1.14286em;
        line-height: inherit;
        vertical-align: top; }
      .btn_outlined.btn_small .ec-icon_right {
        padding-left: 0.2625rem;
        padding-right: 0; }
      .btn_outlined.btn_small .ec-icon:empty {
        padding-left: 0;
        padding-right: 0; }
        .btn_outlined.btn_small .ec-icon:empty:first-child {
          margin-left: -0.13125rem;
          margin-right: 0.2625rem; }
        .btn_outlined.btn_small .ec-icon:empty:last-child {
          margin-left: 0.2625rem;
          margin-right: -0.13125rem; }
        .btn_outlined.btn_small .ec-icon:empty:only-child {
          margin-left: -0.39375rem;
          margin-right: -0.39375rem; }
        .btn_outlined.btn_small .ec-icon:empty::before {
          margin-left: 0;
          margin-right: 0; }
  .btn_outlined.btn_big {
    padding: 0.5875rem 0.88125rem;
    font-size: 1.125rem;
    line-height: 1.575rem;
    min-height: 3rem;
    min-width: 3rem;
    border-style: solid;
    border-width: 0.125rem; }
    .btn_outlined.btn_big .bubble:first-child,
    .btn_outlined.btn_big .tag:first-child {
      margin-left: -0.5875rem;
      margin-right: 0.5875rem; }
    .btn_outlined.btn_big .bubble:last-child,
    .btn_outlined.btn_big .tag:last-child {
      margin-left: 0.5875rem;
      margin-right: -0.5875rem; }
    .btn_outlined.btn_big .bubble:only-child,
    .btn_outlined.btn_big .tag:only-child {
      margin-left: -0.88125rem;
      margin-right: -0.88125rem; }
    .btn_outlined.btn_big .ec-icon {
      padding-right: 0.5875rem; }
      @supports (display: inline-flex) {
        .btn_outlined.btn_big .ec-icon {
          display: inline-flex;
          align-items: center;
          justify-content: center; } }
      .btn_outlined.btn_big .ec-icon::before {
        font-size: 0.88889em;
        line-height: inherit;
        vertical-align: top; }
      .btn_outlined.btn_big .ec-icon_right {
        padding-left: 0.5875rem;
        padding-right: 0; }
      .btn_outlined.btn_big .ec-icon:empty {
        padding-left: 0;
        padding-right: 0; }
        .btn_outlined.btn_big .ec-icon:empty:first-child {
          margin-left: -0.29375rem;
          margin-right: 0.5875rem; }
        .btn_outlined.btn_big .ec-icon:empty:last-child {
          margin-left: 0.5875rem;
          margin-right: -0.29375rem; }
        .btn_outlined.btn_big .ec-icon:empty:only-child {
          margin-left: -0.88125rem;
          margin-right: -0.88125rem; }
        .btn_outlined.btn_big .ec-icon:empty::before {
          margin-left: 0;
          margin-right: 0; }
  .btn_outlined.is-active, .btn_outlined:hover, .btn_outlined:focus {
    border-color: #28c064;
    color: #28c064;
    background: none; }
  .btn_outlined.btn_minor {
    border-color: rgba(47, 206, 111, 0.2);
    color: #212f3e;
    background: none; }
    .btn_outlined.btn_minor.is-active, .btn_outlined.btn_minor:hover, .btn_outlined.btn_minor:focus {
      border-color: #c4f2d6;
      color: #212f3e;
      background: none; }
  .btn_outlined.btn_super {
    border-color: #14a656;
    color: whitesmoke;
    background: none; }
    .btn_outlined.btn_super.is-active, .btn_outlined.btn_super:hover, .btn_outlined.btn_super:focus {
      border-color: #19b561;
      color: whitesmoke;
      background: none; }
  .btn_outlined.btn_invert {
    border-color: #fff;
    color: whitesmoke;
    background: none; }
    .btn_outlined.btn_invert.is-active, .btn_outlined.btn_invert:hover, .btn_outlined.btn_invert:focus {
      border-color: whitesmoke;
      color: whitesmoke;
      background: none; }

.btn.btn_round {
  border-radius: 1.25rem; }
  .btn.btn_round.btn_small {
    border-radius: 1rem; }
  .btn.btn_round.btn_big {
    border-radius: 1.5rem; }

.btn_glow.btn {
  box-shadow: 0 12px 24px -8px rgba(47, 206, 111, 0.6); }
  .btn_glow.btn:hover {
    box-shadow: 0 8px 16px -8px rgba(47, 206, 111, 0.6); }
  .btn_glow.btn.is-disabled, .btn_glow.btn:disabled {
    box-shadow: none; }
  .btn_glow.btn.btn_super {
    box-shadow: 0 12px 24px -8px rgba(20, 166, 86, 0.6); }
    .btn_glow.btn.btn_super:hover {
      box-shadow: 0 8px 16px -8px rgba(20, 166, 86, 0.6); }
    .btn_glow.btn.btn_super.is-disabled, .btn_glow.btn.btn_super:disabled {
      box-shadow: none; }
  .btn_glow.btn.btn_minor {
    box-shadow: 0 12px 24px -8px rgba(47, 206, 111, 0.6); }
    .btn_glow.btn.btn_minor:hover {
      box-shadow: 0 8px 16px -8px rgba(47, 206, 111, 0.6); }
    .btn_glow.btn.btn_minor.is-disabled, .btn_glow.btn.btn_minor:disabled {
      box-shadow: none; }

.hamburger-btn {
  position: relative;
  background-color: transparent;
  margin: 0; }
  .hamburger-btn::after {
    content: '';
    position: absolute;
    top: 50%;
    transition: all 0.4s;
    background-color: #2fce6f;
    border-radius: 0.0625rem;
    box-shadow: 0 0.375rem 0 0 #2fce6f, 0 -0.375rem 0 0 #2fce6f;
    height: 0.125rem;
    left: 0.75rem;
    margin-top: -0.0625rem;
    width: 1rem; }
  .hamburger-btn::before {
    content: '';
    position: absolute;
    top: 50%;
    transition: all 0.4s;
    background-color: #2fce6f;
    border-radius: 0.0625rem;
    height: 0.125rem;
    left: 0.75rem;
    margin-top: -0.0625rem;
    width: 1rem; }
  .hamburger-btn.is-active::after {
    content: '';
    position: absolute;
    top: 50%;
    box-shadow: 0 0 0 0 transparent, 0 0 0 0 transparent;
    background-color: #2fce6f;
    border-radius: 0.0625rem;
    height: 0.125rem;
    left: 0.75rem;
    margin-top: -0.0625rem;
    transform: rotate(45deg);
    width: 1rem; }
  .hamburger-btn.is-active::before {
    transform: rotate(-45deg); }

.tag {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: text-bottom;
  padding: 0.2625rem 0.525rem;
  font-size: 0.875rem;
  line-height: 1.225rem;
  min-height: 1.75rem;
  min-width: 1.75rem;
  background-color: rgba(47, 206, 111, 0.2);
  color: #192432;
  border-radius: 0.125rem;
  font-weight: bolder; }
  .tag .bubble:first-child,
  .tag .tag:first-child {
    margin-left: -0.2625rem;
    margin-right: 0.2625rem; }
  .tag .bubble:last-child,
  .tag .tag:last-child {
    margin-left: 0.2625rem;
    margin-right: -0.2625rem; }
  .tag .bubble:only-child,
  .tag .tag:only-child {
    margin-left: -0.525rem;
    margin-right: -0.525rem; }
  .tag .ec-icon {
    padding-right: 0.2625rem; }
    @supports (display: inline-flex) {
      .tag .ec-icon {
        display: inline-flex;
        align-items: center;
        justify-content: center; } }
    .tag .ec-icon::before {
      font-size: 1.14286em;
      line-height: inherit;
      vertical-align: top; }
    .tag .ec-icon_right {
      padding-left: 0.2625rem;
      padding-right: 0; }
    .tag .ec-icon:empty {
      padding-left: 0;
      padding-right: 0; }
      .tag .ec-icon:empty:first-child {
        margin-left: -0.13125rem;
        margin-right: 0.2625rem; }
      .tag .ec-icon:empty:last-child {
        margin-left: 0.2625rem;
        margin-right: -0.13125rem; }
      .tag .ec-icon:empty:only-child {
        margin-left: -0.525rem;
        margin-right: -0.525rem; }
      .tag .ec-icon:empty::before {
        margin-left: 0;
        margin-right: 0; }
  .tag a {
    color: #192432; }
  .tag.tag_small {
    padding: 0.21406rem 0.42813rem;
    font-size: 0.76562rem;
    line-height: 1.07187rem;
    min-height: 1.5rem;
    min-width: 1.5rem;
    border-radius: 0.125rem; }
    .tag.tag_small .bubble:first-child,
    .tag.tag_small .tag:first-child {
      margin-left: -0.21406rem;
      margin-right: 0.21406rem; }
    .tag.tag_small .bubble:last-child,
    .tag.tag_small .tag:last-child {
      margin-left: 0.21406rem;
      margin-right: -0.21406rem; }
    .tag.tag_small .bubble:only-child,
    .tag.tag_small .tag:only-child {
      margin-left: -0.42813rem;
      margin-right: -0.42813rem; }
    .tag.tag_small .ec-icon {
      padding-right: 0.21406rem; }
      @supports (display: inline-flex) {
        .tag.tag_small .ec-icon {
          display: inline-flex;
          align-items: center;
          justify-content: center; } }
      .tag.tag_small .ec-icon::before {
        font-size: 1.30612em;
        line-height: inherit;
        vertical-align: top; }
      .tag.tag_small .ec-icon_right {
        padding-left: 0.21406rem;
        padding-right: 0; }
      .tag.tag_small .ec-icon:empty {
        padding-left: 0;
        padding-right: 0; }
        .tag.tag_small .ec-icon:empty:first-child {
          margin-left: -0.10703rem;
          margin-right: 0.21406rem; }
        .tag.tag_small .ec-icon:empty:last-child {
          margin-left: 0.21406rem;
          margin-right: -0.10703rem; }
        .tag.tag_small .ec-icon:empty:only-child {
          margin-left: -0.42813rem;
          margin-right: -0.42813rem; }
        .tag.tag_small .ec-icon:empty::before {
          margin-left: 0;
          margin-right: 0; }
  .tag.tag_big {
    padding: 0.31094rem 0.62188rem;
    font-size: 0.98438rem;
    line-height: 1.37812rem;
    min-height: 2rem;
    min-width: 2rem;
    border-radius: 0.125rem; }
    .tag.tag_big .bubble:first-child,
    .tag.tag_big .tag:first-child {
      margin-left: -0.31094rem;
      margin-right: 0.31094rem; }
    .tag.tag_big .bubble:last-child,
    .tag.tag_big .tag:last-child {
      margin-left: 0.31094rem;
      margin-right: -0.31094rem; }
    .tag.tag_big .bubble:only-child,
    .tag.tag_big .tag:only-child {
      margin-left: -0.62188rem;
      margin-right: -0.62188rem; }
    .tag.tag_big .ec-icon {
      padding-right: 0.31094rem; }
      @supports (display: inline-flex) {
        .tag.tag_big .ec-icon {
          display: inline-flex;
          align-items: center;
          justify-content: center; } }
      .tag.tag_big .ec-icon::before {
        font-size: 1.01587em;
        line-height: inherit;
        vertical-align: top; }
      .tag.tag_big .ec-icon_right {
        padding-left: 0.31094rem;
        padding-right: 0; }
      .tag.tag_big .ec-icon:empty {
        padding-left: 0;
        padding-right: 0; }
        .tag.tag_big .ec-icon:empty:first-child {
          margin-left: -0.15547rem;
          margin-right: 0.31094rem; }
        .tag.tag_big .ec-icon:empty:last-child {
          margin-left: 0.31094rem;
          margin-right: -0.15547rem; }
        .tag.tag_big .ec-icon:empty:only-child {
          margin-left: -0.62188rem;
          margin-right: -0.62188rem; }
        .tag.tag_big .ec-icon:empty::before {
          margin-left: 0;
          margin-right: 0; }
  .tag + .tag {
    margin-left: 0.25rem; }

.tag_minor {
  background-color: rgba(47, 206, 111, 0.2);
  color: #192432; }
  .tag_minor a {
    color: #192432; }
  a.tag_minor:hover {
    background: #c4f2d6;
    color: #192432; }

.tag_link {
  background-color: #2fce6f;
  color: white; }
  .tag_link a {
    color: white; }
  a.tag_link:hover {
    background: #28c064;
    color: white; }

.tag_super {
  background-color: #14a656;
  color: white; }
  .tag_super a {
    color: white; }
  a.tag_super:hover {
    background: #19b561;
    color: white; }

.tag_highlight {
  background-color: #fcff66;
  color: #080c10; }
  .tag_highlight a {
    color: #080c10; }
  a.tag_highlight:hover {
    background: #faff52;
    color: #080c10; }

.tag_invert {
  background-color: #fff;
  color: #2a3d54; }
  .tag_invert a {
    color: #2a3d54; }
  a.tag_invert:hover {
    background: whitesmoke;
    color: #2a3d54; }

.tag_info {
  background-color: #22e7e7;
  color: black; }
  .tag_info a {
    color: black; }
  a.tag_info:hover {
    background: #15dee0;
    color: black; }

.tag_error {
  background-color: #e72222;
  color: white; }
  .tag_error a {
    color: white; }
  a.tag_error:hover {
    background: #e63938;
    color: white; }

.tag_success {
  background-color: #22e722;
  color: white; }
  .tag_success a {
    color: white; }
  a.tag_success:hover {
    background: #38e639;
    color: white; }

.tag_warning {
  background-color: #e7c822;
  color: black; }
  .tag_warning a {
    color: black; }
  a.tag_warning:hover {
    background: #e0be15;
    color: black; }

.tag_outlined {
  padding: 0.1375rem 0.275rem;
  font-size: 0.875rem;
  line-height: 1.225rem;
  min-height: 1.75rem;
  min-width: 1.75rem;
  border-style: solid;
  border-width: 0.125rem;
  background: none;
  border-color: rgba(47, 206, 111, 0.2);
  border-style: solid; }
  .tag_outlined .bubble:first-child,
  .tag_outlined .tag:first-child {
    margin-left: -0.1375rem;
    margin-right: 0.1375rem; }
  .tag_outlined .bubble:last-child,
  .tag_outlined .tag:last-child {
    margin-left: 0.1375rem;
    margin-right: -0.1375rem; }
  .tag_outlined .bubble:only-child,
  .tag_outlined .tag:only-child {
    margin-left: -0.275rem;
    margin-right: -0.275rem; }
  .tag_outlined .ec-icon {
    padding-right: 0.1375rem; }
    @supports (display: inline-flex) {
      .tag_outlined .ec-icon {
        display: inline-flex;
        align-items: center;
        justify-content: center; } }
    .tag_outlined .ec-icon::before {
      font-size: 1.14286em;
      line-height: inherit;
      vertical-align: top; }
    .tag_outlined .ec-icon_right {
      padding-left: 0.1375rem;
      padding-right: 0; }
    .tag_outlined .ec-icon:empty {
      padding-left: 0;
      padding-right: 0; }
      .tag_outlined .ec-icon:empty:first-child {
        margin-left: -0.06875rem;
        margin-right: 0.1375rem; }
      .tag_outlined .ec-icon:empty:last-child {
        margin-left: 0.1375rem;
        margin-right: -0.06875rem; }
      .tag_outlined .ec-icon:empty:only-child {
        margin-left: -0.275rem;
        margin-right: -0.275rem; }
      .tag_outlined .ec-icon:empty::before {
        margin-left: 0;
        margin-right: 0; }
  a:hover .tag_outlined {
    border-color: #c4f2d6;
    color: #c4f2d6; }
  .tag_outlined.tag_small {
    padding: 0.08906rem 0.17813rem;
    font-size: 0.76562rem;
    line-height: 1.07187rem;
    min-height: 1.5rem;
    min-width: 1.5rem;
    border-style: solid;
    border-width: 0.125rem; }
    .tag_outlined.tag_small .bubble:first-child,
    .tag_outlined.tag_small .tag:first-child {
      margin-left: -0.08906rem;
      margin-right: 0.08906rem; }
    .tag_outlined.tag_small .bubble:last-child,
    .tag_outlined.tag_small .tag:last-child {
      margin-left: 0.08906rem;
      margin-right: -0.08906rem; }
    .tag_outlined.tag_small .bubble:only-child,
    .tag_outlined.tag_small .tag:only-child {
      margin-left: -0.17813rem;
      margin-right: -0.17813rem; }
    .tag_outlined.tag_small .ec-icon {
      padding-right: 0.08906rem; }
      @supports (display: inline-flex) {
        .tag_outlined.tag_small .ec-icon {
          display: inline-flex;
          align-items: center;
          justify-content: center; } }
      .tag_outlined.tag_small .ec-icon::before {
        font-size: 1.30612em;
        line-height: inherit;
        vertical-align: top; }
      .tag_outlined.tag_small .ec-icon_right {
        padding-left: 0.08906rem;
        padding-right: 0; }
      .tag_outlined.tag_small .ec-icon:empty {
        padding-left: 0;
        padding-right: 0; }
        .tag_outlined.tag_small .ec-icon:empty:first-child {
          margin-left: -0.04453rem;
          margin-right: 0.08906rem; }
        .tag_outlined.tag_small .ec-icon:empty:last-child {
          margin-left: 0.08906rem;
          margin-right: -0.04453rem; }
        .tag_outlined.tag_small .ec-icon:empty:only-child {
          margin-left: -0.17813rem;
          margin-right: -0.17813rem; }
        .tag_outlined.tag_small .ec-icon:empty::before {
          margin-left: 0;
          margin-right: 0; }
  .tag_outlined.tag_big {
    padding: 0.18594rem 0.37188rem;
    font-size: 0.98438rem;
    line-height: 1.37812rem;
    min-height: 2rem;
    min-width: 2rem;
    border-style: solid;
    border-width: 0.125rem; }
    .tag_outlined.tag_big .bubble:first-child,
    .tag_outlined.tag_big .tag:first-child {
      margin-left: -0.18594rem;
      margin-right: 0.18594rem; }
    .tag_outlined.tag_big .bubble:last-child,
    .tag_outlined.tag_big .tag:last-child {
      margin-left: 0.18594rem;
      margin-right: -0.18594rem; }
    .tag_outlined.tag_big .bubble:only-child,
    .tag_outlined.tag_big .tag:only-child {
      margin-left: -0.37188rem;
      margin-right: -0.37188rem; }
    .tag_outlined.tag_big .ec-icon {
      padding-right: 0.18594rem; }
      @supports (display: inline-flex) {
        .tag_outlined.tag_big .ec-icon {
          display: inline-flex;
          align-items: center;
          justify-content: center; } }
      .tag_outlined.tag_big .ec-icon::before {
        font-size: 1.01587em;
        line-height: inherit;
        vertical-align: top; }
      .tag_outlined.tag_big .ec-icon_right {
        padding-left: 0.18594rem;
        padding-right: 0; }
      .tag_outlined.tag_big .ec-icon:empty {
        padding-left: 0;
        padding-right: 0; }
        .tag_outlined.tag_big .ec-icon:empty:first-child {
          margin-left: -0.09297rem;
          margin-right: 0.18594rem; }
        .tag_outlined.tag_big .ec-icon:empty:last-child {
          margin-left: 0.18594rem;
          margin-right: -0.09297rem; }
        .tag_outlined.tag_big .ec-icon:empty:only-child {
          margin-left: -0.37188rem;
          margin-right: -0.37188rem; }
        .tag_outlined.tag_big .ec-icon:empty::before {
          margin-left: 0;
          margin-right: 0; }

.tag_outlined.tag_minor {
  border-color: rgba(47, 206, 111, 0.2);
  color: rgba(47, 206, 111, 0.2); }
  a.tag_outlined.tag_minor:hover {
    background: none;
    border-color: #c4f2d6;
    color: rgba(47, 206, 111, 0.2); }

.tag_outlined.tag_link {
  border-color: #2fce6f;
  color: #2fce6f; }
  a.tag_outlined.tag_link:hover {
    background: none;
    border-color: #28c064;
    color: #2fce6f; }

.tag_outlined.tag_super {
  border-color: #14a656;
  color: #14a656; }
  a.tag_outlined.tag_super:hover {
    background: none;
    border-color: #19b561;
    color: #14a656; }

.tag_outlined.tag_highlight {
  border-color: #fcff66;
  color: #fcff66; }
  a.tag_outlined.tag_highlight:hover {
    background: none;
    border-color: #faff52;
    color: #fcff66; }

.tag_outlined.tag_invert {
  border-color: #fff;
  color: #fff; }
  a.tag_outlined.tag_invert:hover {
    background: none;
    border-color: whitesmoke;
    color: #fff; }

.tag_outlined.tag_info {
  border-color: #22e7e7;
  color: #22e7e7; }
  a.tag_outlined.tag_info:hover {
    background: none;
    border-color: #15dee0;
    color: #22e7e7; }

.tag_outlined.tag_error {
  border-color: #e72222;
  color: #e72222; }
  a.tag_outlined.tag_error:hover {
    background: none;
    border-color: #e63938;
    color: #e72222; }

.tag_outlined.tag_success {
  border-color: #22e722;
  color: #22e722; }
  a.tag_outlined.tag_success:hover {
    background: none;
    border-color: #38e639;
    color: #22e722; }

.tag_outlined.tag_warning {
  border-color: #e7c822;
  color: #e7c822; }
  a.tag_outlined.tag_warning:hover {
    background: none;
    border-color: #e0be15;
    color: #e7c822; }

.tag.tag_round {
  border-radius: 0.875rem; }
  .tag.tag_round.tag_small {
    border-radius: 0.75rem; }
  .tag.tag_round.tag_big {
    border-radius: 1rem; }

.bubble {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  background-color: rgba(47, 206, 111, 0.2);
  color: #192432;
  padding: 0.375rem 0.375rem;
  font-size: 0.875rem;
  line-height: 1rem;
  min-height: 1.75rem;
  min-width: 1.75rem;
  border-radius: 0.25rem;
  font-weight: bolder; }
  .bubble.bubble_small {
    padding: 0.375rem 0.375rem;
    font-size: 0.625rem;
    line-height: 0.75rem;
    min-height: 1.5rem;
    min-width: 1.5rem;
    border-radius: 0.25rem; }
  .bubble.bubble_big {
    padding: 0.375rem 0.375rem;
    font-size: 1rem;
    line-height: 1.25rem;
    min-height: 2rem;
    min-width: 2rem;
    border-radius: 0.25rem; }

.bubble_minor {
  background-color: rgba(47, 206, 111, 0.2);
  color: #192432; }

.bubble_link {
  background-color: #2fce6f;
  color: white; }

.bubble_super {
  background-color: #14a656;
  color: white; }

.bubble_highlight {
  background-color: #fcff66;
  color: #080c10; }

.bubble_invert {
  background-color: #fff;
  color: #2a3d54; }

.bubble_info {
  background-color: #22e7e7;
  color: black; }

.bubble_error {
  background-color: #e72222;
  color: white; }

.bubble_success {
  background-color: #22e722;
  color: white; }

.bubble_warning {
  background-color: #e7c822;
  color: black; }

.bubble {
  border-radius: 0.875rem; }
  .bubble.bubble_small {
    border-radius: 0.75rem; }
  .bubble.bubble_big {
    border-radius: 1rem; }

@keyframes loaderDefault {
  0% {
    transform: rotate(0);
    border-right-color: #2fce6f; }
  50% {
    border-right-color: #d03190; }
  100% {
    border-right-color: #2fce6f;
    transform: rotate(360deg); } }

.loader {
  display: none;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin: 0 auto;
  display: flex; }
  .loader::after {
    content: '';
    display: block;
    border-radius: 50%;
    height: 1.5em;
    border: 0.25em solid rgba(42, 61, 84, 0.1);
    border-right-color: #2fce6f;
    animation: loaderDefault 1s infinite linear;
    width: 1.5em;
    flex-grow: 0; }
  .loader.is-local {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .loader.is-global {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.2); }

.btn .loader:not(.is-local) {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem; }

.btn_small .loader:not(.is-local) {
  margin-top: -0.25rem;
  margin-bottom: -0.25rem; }

.btn_big .loader:not(.is-local) {
  margin-top: -0.75rem;
  margin-bottom: -0.75rem; }

.bumper {
  flex-shrink: 0;
  width: 100%;
  position: relative;
  text-align: center;
  height: 0.25rem;
  margin: 2rem 0; }
  @media (min-width: 0px) {
    .bumper-xs {
      margin: 2rem 0; } }
  @media (min-width: 480px) {
    .bumper-sm {
      margin: 2rem 0; } }
  @media (min-width: 720px) {
    .bumper-md {
      margin: 2rem 0; } }
  @media (min-width: 1024px) {
    .bumper-nav {
      margin: 2rem 0; } }
  @media (min-width: 1024px) {
    .bumper-lg {
      margin: 2rem 0; } }
  @media (min-width: 1280px) {
    .bumper-xl {
      margin: 2rem 0; } }
  @media (min-width: 1920px) {
    .bumper-xxl {
      margin: 2rem 0; } }
  .bumper::before {
    content: '';
    border-radius: 50%;
    position: absolute;
    background-color: rgba(42, 61, 84, 0.1);
    box-shadow: 0.5rem 0 0 rgba(42, 61, 84, 0.1), 1rem 0 0 rgba(42, 61, 84, 0.1);
    height: 0.25rem;
    margin-left: -0.625rem;
    width: 0.25rem; }
  .bumper.bumper_invert::before {
    background-color: rgba(255, 255, 255, 0.2);
    box-shadow: 0.5rem 0 0 rgba(255, 255, 255, 0.2), 1rem 0 0 rgba(255, 255, 255, 0.2); }

.bumper_small {
  margin: 1rem 0; }
  @media (min-width: 0px) {
    .bumper_small-xs {
      margin: 1rem 0; } }
  @media (min-width: 480px) {
    .bumper_small-sm {
      margin: 1rem 0; } }
  @media (min-width: 720px) {
    .bumper_small-md {
      margin: 1rem 0; } }
  @media (min-width: 1024px) {
    .bumper_small-nav {
      margin: 1rem 0; } }
  @media (min-width: 1024px) {
    .bumper_small-lg {
      margin: 1rem 0; } }
  @media (min-width: 1280px) {
    .bumper_small-xl {
      margin: 1rem 0; } }
  @media (min-width: 1920px) {
    .bumper_small-xxl {
      margin: 1rem 0; } }

.bumper_big {
  margin: 3rem 0; }
  @media (min-width: 0px) {
    .bumper_big-xs {
      margin: 3rem 0; } }
  @media (min-width: 480px) {
    .bumper_big-sm {
      margin: 3rem 0; } }
  @media (min-width: 720px) {
    .bumper_big-md {
      margin: 3rem 0; } }
  @media (min-width: 1024px) {
    .bumper_big-nav {
      margin: 3rem 0; } }
  @media (min-width: 1024px) {
    .bumper_big-lg {
      margin: 3rem 0; } }
  @media (min-width: 1280px) {
    .bumper_big-xl {
      margin: 3rem 0; } }
  @media (min-width: 1920px) {
    .bumper_big-xxl {
      margin: 3rem 0; } }

.bumper-small {
  position: relative;
  height: 0.25rem;
  text-align: center;
  flex-shrink: 0;
  margin: 1rem 0;
  width: 100%; }
  .bumper-small::before {
    background-color: rgba(42, 61, 84, 0.1);
    border-radius: 50%;
    box-shadow: 0.5rem 0 0 rgba(42, 61, 84, 0.1), 1rem 0 0 rgba(42, 61, 84, 0.1);
    content: '';
    height: 0.25rem;
    margin-left: -0.625rem;
    position: absolute;
    width: 0.25rem; }
  .bumper-small.bumper_invert::before {
    background-color: rgba(255, 255, 255, 0.2);
    box-shadow: 0.5rem 0 0 rgba(255, 255, 255, 0.2), 1rem 0 0 rgba(255, 255, 255, 0.2); }
  @media (min-width: 0px) {
    .bumper-small-xs {
      position: relative;
      height: 0.25rem;
      text-align: center;
      flex-shrink: 0;
      margin: 1rem 0;
      width: 100%; }
      .bumper-small-xs::before {
        background-color: rgba(42, 61, 84, 0.1);
        border-radius: 50%;
        box-shadow: 0.5rem 0 0 rgba(42, 61, 84, 0.1), 1rem 0 0 rgba(42, 61, 84, 0.1);
        content: '';
        height: 0.25rem;
        margin-left: -0.625rem;
        position: absolute;
        width: 0.25rem; }
      .bumper-small-xs.bumper_invert::before {
        background-color: rgba(255, 255, 255, 0.2);
        box-shadow: 0.5rem 0 0 rgba(255, 255, 255, 0.2), 1rem 0 0 rgba(255, 255, 255, 0.2); } }
  @media (min-width: 480px) {
    .bumper-small-sm {
      position: relative;
      height: 0.25rem;
      text-align: center;
      flex-shrink: 0;
      margin: 1rem 0;
      width: 100%; }
      .bumper-small-sm::before {
        background-color: rgba(42, 61, 84, 0.1);
        border-radius: 50%;
        box-shadow: 0.5rem 0 0 rgba(42, 61, 84, 0.1), 1rem 0 0 rgba(42, 61, 84, 0.1);
        content: '';
        height: 0.25rem;
        margin-left: -0.625rem;
        position: absolute;
        width: 0.25rem; }
      .bumper-small-sm.bumper_invert::before {
        background-color: rgba(255, 255, 255, 0.2);
        box-shadow: 0.5rem 0 0 rgba(255, 255, 255, 0.2), 1rem 0 0 rgba(255, 255, 255, 0.2); } }
  @media (min-width: 720px) {
    .bumper-small-md {
      position: relative;
      height: 0.25rem;
      text-align: center;
      flex-shrink: 0;
      margin: 1rem 0;
      width: 100%; }
      .bumper-small-md::before {
        background-color: rgba(42, 61, 84, 0.1);
        border-radius: 50%;
        box-shadow: 0.5rem 0 0 rgba(42, 61, 84, 0.1), 1rem 0 0 rgba(42, 61, 84, 0.1);
        content: '';
        height: 0.25rem;
        margin-left: -0.625rem;
        position: absolute;
        width: 0.25rem; }
      .bumper-small-md.bumper_invert::before {
        background-color: rgba(255, 255, 255, 0.2);
        box-shadow: 0.5rem 0 0 rgba(255, 255, 255, 0.2), 1rem 0 0 rgba(255, 255, 255, 0.2); } }
  @media (min-width: 1024px) {
    .bumper-small-nav {
      position: relative;
      height: 0.25rem;
      text-align: center;
      flex-shrink: 0;
      margin: 1rem 0;
      width: 100%; }
      .bumper-small-nav::before {
        background-color: rgba(42, 61, 84, 0.1);
        border-radius: 50%;
        box-shadow: 0.5rem 0 0 rgba(42, 61, 84, 0.1), 1rem 0 0 rgba(42, 61, 84, 0.1);
        content: '';
        height: 0.25rem;
        margin-left: -0.625rem;
        position: absolute;
        width: 0.25rem; }
      .bumper-small-nav.bumper_invert::before {
        background-color: rgba(255, 255, 255, 0.2);
        box-shadow: 0.5rem 0 0 rgba(255, 255, 255, 0.2), 1rem 0 0 rgba(255, 255, 255, 0.2); } }
  @media (min-width: 1024px) {
    .bumper-small-lg {
      position: relative;
      height: 0.25rem;
      text-align: center;
      flex-shrink: 0;
      margin: 1rem 0;
      width: 100%; }
      .bumper-small-lg::before {
        background-color: rgba(42, 61, 84, 0.1);
        border-radius: 50%;
        box-shadow: 0.5rem 0 0 rgba(42, 61, 84, 0.1), 1rem 0 0 rgba(42, 61, 84, 0.1);
        content: '';
        height: 0.25rem;
        margin-left: -0.625rem;
        position: absolute;
        width: 0.25rem; }
      .bumper-small-lg.bumper_invert::before {
        background-color: rgba(255, 255, 255, 0.2);
        box-shadow: 0.5rem 0 0 rgba(255, 255, 255, 0.2), 1rem 0 0 rgba(255, 255, 255, 0.2); } }
  @media (min-width: 1280px) {
    .bumper-small-xl {
      position: relative;
      height: 0.25rem;
      text-align: center;
      flex-shrink: 0;
      margin: 1rem 0;
      width: 100%; }
      .bumper-small-xl::before {
        background-color: rgba(42, 61, 84, 0.1);
        border-radius: 50%;
        box-shadow: 0.5rem 0 0 rgba(42, 61, 84, 0.1), 1rem 0 0 rgba(42, 61, 84, 0.1);
        content: '';
        height: 0.25rem;
        margin-left: -0.625rem;
        position: absolute;
        width: 0.25rem; }
      .bumper-small-xl.bumper_invert::before {
        background-color: rgba(255, 255, 255, 0.2);
        box-shadow: 0.5rem 0 0 rgba(255, 255, 255, 0.2), 1rem 0 0 rgba(255, 255, 255, 0.2); } }
  @media (min-width: 1920px) {
    .bumper-small-xxl {
      position: relative;
      height: 0.25rem;
      text-align: center;
      flex-shrink: 0;
      margin: 1rem 0;
      width: 100%; }
      .bumper-small-xxl::before {
        background-color: rgba(42, 61, 84, 0.1);
        border-radius: 50%;
        box-shadow: 0.5rem 0 0 rgba(42, 61, 84, 0.1), 1rem 0 0 rgba(42, 61, 84, 0.1);
        content: '';
        height: 0.25rem;
        margin-left: -0.625rem;
        position: absolute;
        width: 0.25rem; }
      .bumper-small-xxl.bumper_invert::before {
        background-color: rgba(255, 255, 255, 0.2);
        box-shadow: 0.5rem 0 0 rgba(255, 255, 255, 0.2), 1rem 0 0 rgba(255, 255, 255, 0.2); } }

.bumper-big {
  position: relative;
  height: 0.25rem;
  text-align: center;
  flex-shrink: 0;
  margin: 3rem 0;
  width: 100%; }
  .bumper-big::before {
    background-color: rgba(42, 61, 84, 0.1);
    border-radius: 50%;
    box-shadow: 0.5rem 0 0 rgba(42, 61, 84, 0.1), 1rem 0 0 rgba(42, 61, 84, 0.1);
    content: '';
    height: 0.25rem;
    margin-left: -0.625rem;
    position: absolute;
    width: 0.25rem; }
  .bumper-big.bumper_invert::before {
    background-color: rgba(255, 255, 255, 0.2);
    box-shadow: 0.5rem 0 0 rgba(255, 255, 255, 0.2), 1rem 0 0 rgba(255, 255, 255, 0.2); }
  @media (min-width: 0px) {
    .bumper-big-xs {
      position: relative;
      height: 0.25rem;
      text-align: center;
      flex-shrink: 0;
      margin: 3rem 0;
      width: 100%; }
      .bumper-big-xs::before {
        background-color: rgba(42, 61, 84, 0.1);
        border-radius: 50%;
        box-shadow: 0.5rem 0 0 rgba(42, 61, 84, 0.1), 1rem 0 0 rgba(42, 61, 84, 0.1);
        content: '';
        height: 0.25rem;
        margin-left: -0.625rem;
        position: absolute;
        width: 0.25rem; }
      .bumper-big-xs.bumper_invert::before {
        background-color: rgba(255, 255, 255, 0.2);
        box-shadow: 0.5rem 0 0 rgba(255, 255, 255, 0.2), 1rem 0 0 rgba(255, 255, 255, 0.2); } }
  @media (min-width: 480px) {
    .bumper-big-sm {
      position: relative;
      height: 0.25rem;
      text-align: center;
      flex-shrink: 0;
      margin: 3rem 0;
      width: 100%; }
      .bumper-big-sm::before {
        background-color: rgba(42, 61, 84, 0.1);
        border-radius: 50%;
        box-shadow: 0.5rem 0 0 rgba(42, 61, 84, 0.1), 1rem 0 0 rgba(42, 61, 84, 0.1);
        content: '';
        height: 0.25rem;
        margin-left: -0.625rem;
        position: absolute;
        width: 0.25rem; }
      .bumper-big-sm.bumper_invert::before {
        background-color: rgba(255, 255, 255, 0.2);
        box-shadow: 0.5rem 0 0 rgba(255, 255, 255, 0.2), 1rem 0 0 rgba(255, 255, 255, 0.2); } }
  @media (min-width: 720px) {
    .bumper-big-md {
      position: relative;
      height: 0.25rem;
      text-align: center;
      flex-shrink: 0;
      margin: 3rem 0;
      width: 100%; }
      .bumper-big-md::before {
        background-color: rgba(42, 61, 84, 0.1);
        border-radius: 50%;
        box-shadow: 0.5rem 0 0 rgba(42, 61, 84, 0.1), 1rem 0 0 rgba(42, 61, 84, 0.1);
        content: '';
        height: 0.25rem;
        margin-left: -0.625rem;
        position: absolute;
        width: 0.25rem; }
      .bumper-big-md.bumper_invert::before {
        background-color: rgba(255, 255, 255, 0.2);
        box-shadow: 0.5rem 0 0 rgba(255, 255, 255, 0.2), 1rem 0 0 rgba(255, 255, 255, 0.2); } }
  @media (min-width: 1024px) {
    .bumper-big-nav {
      position: relative;
      height: 0.25rem;
      text-align: center;
      flex-shrink: 0;
      margin: 3rem 0;
      width: 100%; }
      .bumper-big-nav::before {
        background-color: rgba(42, 61, 84, 0.1);
        border-radius: 50%;
        box-shadow: 0.5rem 0 0 rgba(42, 61, 84, 0.1), 1rem 0 0 rgba(42, 61, 84, 0.1);
        content: '';
        height: 0.25rem;
        margin-left: -0.625rem;
        position: absolute;
        width: 0.25rem; }
      .bumper-big-nav.bumper_invert::before {
        background-color: rgba(255, 255, 255, 0.2);
        box-shadow: 0.5rem 0 0 rgba(255, 255, 255, 0.2), 1rem 0 0 rgba(255, 255, 255, 0.2); } }
  @media (min-width: 1024px) {
    .bumper-big-lg {
      position: relative;
      height: 0.25rem;
      text-align: center;
      flex-shrink: 0;
      margin: 3rem 0;
      width: 100%; }
      .bumper-big-lg::before {
        background-color: rgba(42, 61, 84, 0.1);
        border-radius: 50%;
        box-shadow: 0.5rem 0 0 rgba(42, 61, 84, 0.1), 1rem 0 0 rgba(42, 61, 84, 0.1);
        content: '';
        height: 0.25rem;
        margin-left: -0.625rem;
        position: absolute;
        width: 0.25rem; }
      .bumper-big-lg.bumper_invert::before {
        background-color: rgba(255, 255, 255, 0.2);
        box-shadow: 0.5rem 0 0 rgba(255, 255, 255, 0.2), 1rem 0 0 rgba(255, 255, 255, 0.2); } }
  @media (min-width: 1280px) {
    .bumper-big-xl {
      position: relative;
      height: 0.25rem;
      text-align: center;
      flex-shrink: 0;
      margin: 3rem 0;
      width: 100%; }
      .bumper-big-xl::before {
        background-color: rgba(42, 61, 84, 0.1);
        border-radius: 50%;
        box-shadow: 0.5rem 0 0 rgba(42, 61, 84, 0.1), 1rem 0 0 rgba(42, 61, 84, 0.1);
        content: '';
        height: 0.25rem;
        margin-left: -0.625rem;
        position: absolute;
        width: 0.25rem; }
      .bumper-big-xl.bumper_invert::before {
        background-color: rgba(255, 255, 255, 0.2);
        box-shadow: 0.5rem 0 0 rgba(255, 255, 255, 0.2), 1rem 0 0 rgba(255, 255, 255, 0.2); } }
  @media (min-width: 1920px) {
    .bumper-big-xxl {
      position: relative;
      height: 0.25rem;
      text-align: center;
      flex-shrink: 0;
      margin: 3rem 0;
      width: 100%; }
      .bumper-big-xxl::before {
        background-color: rgba(42, 61, 84, 0.1);
        border-radius: 50%;
        box-shadow: 0.5rem 0 0 rgba(42, 61, 84, 0.1), 1rem 0 0 rgba(42, 61, 84, 0.1);
        content: '';
        height: 0.25rem;
        margin-left: -0.625rem;
        position: absolute;
        width: 0.25rem; }
      .bumper-big-xxl.bumper_invert::before {
        background-color: rgba(255, 255, 255, 0.2);
        box-shadow: 0.5rem 0 0 rgba(255, 255, 255, 0.2), 1rem 0 0 rgba(255, 255, 255, 0.2); } }

.divider {
  border: 0;
  width: 100%;
  height: 0.0625rem;
  background: rgba(42, 61, 84, 0.1);
  margin: 2rem 0; }
  @media (min-width: 0px) {
    .divider-xs {
      margin: 2rem 0; } }
  @media (min-width: 480px) {
    .divider-sm {
      margin: 2rem 0; } }
  @media (min-width: 720px) {
    .divider-md {
      margin: 2rem 0; } }
  @media (min-width: 1024px) {
    .divider-nav {
      margin: 2rem 0; } }
  @media (min-width: 1024px) {
    .divider-lg {
      margin: 2rem 0; } }
  @media (min-width: 1280px) {
    .divider-xl {
      margin: 2rem 0; } }
  @media (min-width: 1920px) {
    .divider-xxl {
      margin: 2rem 0; } }
  .divider.divider_invert {
    background: rgba(255, 255, 255, 0.2); }

.divider_small {
  margin: 1rem 0; }
  @media (min-width: 0px) {
    .divider_small-xs {
      margin: 1rem 0; } }
  @media (min-width: 480px) {
    .divider_small-sm {
      margin: 1rem 0; } }
  @media (min-width: 720px) {
    .divider_small-md {
      margin: 1rem 0; } }
  @media (min-width: 1024px) {
    .divider_small-nav {
      margin: 1rem 0; } }
  @media (min-width: 1024px) {
    .divider_small-lg {
      margin: 1rem 0; } }
  @media (min-width: 1280px) {
    .divider_small-xl {
      margin: 1rem 0; } }
  @media (min-width: 1920px) {
    .divider_small-xxl {
      margin: 1rem 0; } }

.divider_big {
  margin: 3rem 0; }
  @media (min-width: 0px) {
    .divider_big-xs {
      margin: 3rem 0; } }
  @media (min-width: 480px) {
    .divider_big-sm {
      margin: 3rem 0; } }
  @media (min-width: 720px) {
    .divider_big-md {
      margin: 3rem 0; } }
  @media (min-width: 1024px) {
    .divider_big-nav {
      margin: 3rem 0; } }
  @media (min-width: 1024px) {
    .divider_big-lg {
      margin: 3rem 0; } }
  @media (min-width: 1280px) {
    .divider_big-xl {
      margin: 3rem 0; } }
  @media (min-width: 1920px) {
    .divider_big-xxl {
      margin: 3rem 0; } }

.spacer {
  align-self: center;
  flex-shrink: 0;
  width: 100%;
  height: 2rem; }
  @media (min-width: 0px) {
    .spacer-xs {
      height: 2rem; } }
  @media (min-width: 480px) {
    .spacer-sm {
      height: 2rem; } }
  @media (min-width: 720px) {
    .spacer-md {
      height: 2rem; } }
  @media (min-width: 1024px) {
    .spacer-nav {
      height: 2rem; } }
  @media (min-width: 1024px) {
    .spacer-lg {
      height: 2rem; } }
  @media (min-width: 1280px) {
    .spacer-xl {
      height: 2rem; } }
  @media (min-width: 1920px) {
    .spacer-xxl {
      height: 2rem; } }
  .spacer-small, .spacer_small {
    height: 1rem; }
    @media (min-width: 0px) {
      .spacer-small-xs, .spacer_small-xs {
        height: 1rem; } }
    @media (min-width: 480px) {
      .spacer-small-sm, .spacer_small-sm {
        height: 1rem; } }
    @media (min-width: 720px) {
      .spacer-small-md, .spacer_small-md {
        height: 1rem; } }
    @media (min-width: 1024px) {
      .spacer-small-nav, .spacer_small-nav {
        height: 1rem; } }
    @media (min-width: 1024px) {
      .spacer-small-lg, .spacer_small-lg {
        height: 1rem; } }
    @media (min-width: 1280px) {
      .spacer-small-xl, .spacer_small-xl {
        height: 1rem; } }
    @media (min-width: 1920px) {
      .spacer-small-xxl, .spacer_small-xxl {
        height: 1rem; } }
  .spacer-big, .spacer_big {
    height: 3rem; }
    @media (min-width: 0px) {
      .spacer-big-xs, .spacer_big-xs {
        height: 3rem; } }
    @media (min-width: 480px) {
      .spacer-big-sm, .spacer_big-sm {
        height: 3rem; } }
    @media (min-width: 720px) {
      .spacer-big-md, .spacer_big-md {
        height: 3rem; } }
    @media (min-width: 1024px) {
      .spacer-big-nav, .spacer_big-nav {
        height: 3rem; } }
    @media (min-width: 1024px) {
      .spacer-big-lg, .spacer_big-lg {
        height: 3rem; } }
    @media (min-width: 1280px) {
      .spacer-big-xl, .spacer_big-xl {
        height: 3rem; } }
    @media (min-width: 1920px) {
      .spacer-big-xxl, .spacer_big-xxl {
        height: 3rem; } }

.flex-spacer,
.flex-space {
  flex-grow: 1; }

.y-space-0 {
  width: auto;
  height: 0;
  flex-shrink: 0; }
  @media (min-width: 0px) {
    .y-space-0-xs {
      width: auto;
      height: 0;
      flex-shrink: 0; } }
  @media (min-width: 480px) {
    .y-space-0-sm {
      width: auto;
      height: 0;
      flex-shrink: 0; } }
  @media (min-width: 720px) {
    .y-space-0-md {
      width: auto;
      height: 0;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .y-space-0-nav {
      width: auto;
      height: 0;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .y-space-0-lg {
      width: auto;
      height: 0;
      flex-shrink: 0; } }
  @media (min-width: 1280px) {
    .y-space-0-xl {
      width: auto;
      height: 0;
      flex-shrink: 0; } }
  @media (min-width: 1920px) {
    .y-space-0-xxl {
      width: auto;
      height: 0;
      flex-shrink: 0; } }

.y-space-1 {
  width: auto;
  height: 0.25rem;
  flex-shrink: 0; }
  @media (min-width: 0px) {
    .y-space-1-xs {
      width: auto;
      height: 0.25rem;
      flex-shrink: 0; } }
  @media (min-width: 480px) {
    .y-space-1-sm {
      width: auto;
      height: 0.25rem;
      flex-shrink: 0; } }
  @media (min-width: 720px) {
    .y-space-1-md {
      width: auto;
      height: 0.25rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .y-space-1-nav {
      width: auto;
      height: 0.25rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .y-space-1-lg {
      width: auto;
      height: 0.25rem;
      flex-shrink: 0; } }
  @media (min-width: 1280px) {
    .y-space-1-xl {
      width: auto;
      height: 0.25rem;
      flex-shrink: 0; } }
  @media (min-width: 1920px) {
    .y-space-1-xxl {
      width: auto;
      height: 0.25rem;
      flex-shrink: 0; } }

.y-space-2 {
  width: auto;
  height: 0.5rem;
  flex-shrink: 0; }
  @media (min-width: 0px) {
    .y-space-2-xs {
      width: auto;
      height: 0.5rem;
      flex-shrink: 0; } }
  @media (min-width: 480px) {
    .y-space-2-sm {
      width: auto;
      height: 0.5rem;
      flex-shrink: 0; } }
  @media (min-width: 720px) {
    .y-space-2-md {
      width: auto;
      height: 0.5rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .y-space-2-nav {
      width: auto;
      height: 0.5rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .y-space-2-lg {
      width: auto;
      height: 0.5rem;
      flex-shrink: 0; } }
  @media (min-width: 1280px) {
    .y-space-2-xl {
      width: auto;
      height: 0.5rem;
      flex-shrink: 0; } }
  @media (min-width: 1920px) {
    .y-space-2-xxl {
      width: auto;
      height: 0.5rem;
      flex-shrink: 0; } }

.y-space-3 {
  width: auto;
  height: 1rem;
  flex-shrink: 0; }
  @media (min-width: 0px) {
    .y-space-3-xs {
      width: auto;
      height: 1rem;
      flex-shrink: 0; } }
  @media (min-width: 480px) {
    .y-space-3-sm {
      width: auto;
      height: 1rem;
      flex-shrink: 0; } }
  @media (min-width: 720px) {
    .y-space-3-md {
      width: auto;
      height: 1rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .y-space-3-nav {
      width: auto;
      height: 1rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .y-space-3-lg {
      width: auto;
      height: 1rem;
      flex-shrink: 0; } }
  @media (min-width: 1280px) {
    .y-space-3-xl {
      width: auto;
      height: 1rem;
      flex-shrink: 0; } }
  @media (min-width: 1920px) {
    .y-space-3-xxl {
      width: auto;
      height: 1rem;
      flex-shrink: 0; } }

.y-space-4 {
  width: auto;
  height: 1.5rem;
  flex-shrink: 0; }
  @media (min-width: 0px) {
    .y-space-4-xs {
      width: auto;
      height: 1.5rem;
      flex-shrink: 0; } }
  @media (min-width: 480px) {
    .y-space-4-sm {
      width: auto;
      height: 1.5rem;
      flex-shrink: 0; } }
  @media (min-width: 720px) {
    .y-space-4-md {
      width: auto;
      height: 1.5rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .y-space-4-nav {
      width: auto;
      height: 1.5rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .y-space-4-lg {
      width: auto;
      height: 1.5rem;
      flex-shrink: 0; } }
  @media (min-width: 1280px) {
    .y-space-4-xl {
      width: auto;
      height: 1.5rem;
      flex-shrink: 0; } }
  @media (min-width: 1920px) {
    .y-space-4-xxl {
      width: auto;
      height: 1.5rem;
      flex-shrink: 0; } }

.y-space-5 {
  width: auto;
  height: 2rem;
  flex-shrink: 0; }
  @media (min-width: 0px) {
    .y-space-5-xs {
      width: auto;
      height: 2rem;
      flex-shrink: 0; } }
  @media (min-width: 480px) {
    .y-space-5-sm {
      width: auto;
      height: 2rem;
      flex-shrink: 0; } }
  @media (min-width: 720px) {
    .y-space-5-md {
      width: auto;
      height: 2rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .y-space-5-nav {
      width: auto;
      height: 2rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .y-space-5-lg {
      width: auto;
      height: 2rem;
      flex-shrink: 0; } }
  @media (min-width: 1280px) {
    .y-space-5-xl {
      width: auto;
      height: 2rem;
      flex-shrink: 0; } }
  @media (min-width: 1920px) {
    .y-space-5-xxl {
      width: auto;
      height: 2rem;
      flex-shrink: 0; } }

.y-space-6 {
  width: auto;
  height: 3rem;
  flex-shrink: 0; }
  @media (min-width: 0px) {
    .y-space-6-xs {
      width: auto;
      height: 3rem;
      flex-shrink: 0; } }
  @media (min-width: 480px) {
    .y-space-6-sm {
      width: auto;
      height: 3rem;
      flex-shrink: 0; } }
  @media (min-width: 720px) {
    .y-space-6-md {
      width: auto;
      height: 3rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .y-space-6-nav {
      width: auto;
      height: 3rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .y-space-6-lg {
      width: auto;
      height: 3rem;
      flex-shrink: 0; } }
  @media (min-width: 1280px) {
    .y-space-6-xl {
      width: auto;
      height: 3rem;
      flex-shrink: 0; } }
  @media (min-width: 1920px) {
    .y-space-6-xxl {
      width: auto;
      height: 3rem;
      flex-shrink: 0; } }

.y-space-7 {
  width: auto;
  height: 4rem;
  flex-shrink: 0; }
  @media (min-width: 0px) {
    .y-space-7-xs {
      width: auto;
      height: 4rem;
      flex-shrink: 0; } }
  @media (min-width: 480px) {
    .y-space-7-sm {
      width: auto;
      height: 4rem;
      flex-shrink: 0; } }
  @media (min-width: 720px) {
    .y-space-7-md {
      width: auto;
      height: 4rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .y-space-7-nav {
      width: auto;
      height: 4rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .y-space-7-lg {
      width: auto;
      height: 4rem;
      flex-shrink: 0; } }
  @media (min-width: 1280px) {
    .y-space-7-xl {
      width: auto;
      height: 4rem;
      flex-shrink: 0; } }
  @media (min-width: 1920px) {
    .y-space-7-xxl {
      width: auto;
      height: 4rem;
      flex-shrink: 0; } }

.y-space-8 {
  width: auto;
  height: 5rem;
  flex-shrink: 0; }
  @media (min-width: 0px) {
    .y-space-8-xs {
      width: auto;
      height: 5rem;
      flex-shrink: 0; } }
  @media (min-width: 480px) {
    .y-space-8-sm {
      width: auto;
      height: 5rem;
      flex-shrink: 0; } }
  @media (min-width: 720px) {
    .y-space-8-md {
      width: auto;
      height: 5rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .y-space-8-nav {
      width: auto;
      height: 5rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .y-space-8-lg {
      width: auto;
      height: 5rem;
      flex-shrink: 0; } }
  @media (min-width: 1280px) {
    .y-space-8-xl {
      width: auto;
      height: 5rem;
      flex-shrink: 0; } }
  @media (min-width: 1920px) {
    .y-space-8-xxl {
      width: auto;
      height: 5rem;
      flex-shrink: 0; } }

.x-space-0 {
  height: auto;
  width: 0;
  flex-shrink: 0; }
  @media (min-width: 0px) {
    .x-space-0-xs {
      height: auto;
      width: 0;
      flex-shrink: 0; } }
  @media (min-width: 480px) {
    .x-space-0-sm {
      height: auto;
      width: 0;
      flex-shrink: 0; } }
  @media (min-width: 720px) {
    .x-space-0-md {
      height: auto;
      width: 0;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .x-space-0-nav {
      height: auto;
      width: 0;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .x-space-0-lg {
      height: auto;
      width: 0;
      flex-shrink: 0; } }
  @media (min-width: 1280px) {
    .x-space-0-xl {
      height: auto;
      width: 0;
      flex-shrink: 0; } }
  @media (min-width: 1920px) {
    .x-space-0-xxl {
      height: auto;
      width: 0;
      flex-shrink: 0; } }

.x-space-1 {
  height: auto;
  width: 0.25rem;
  flex-shrink: 0; }
  @media (min-width: 0px) {
    .x-space-1-xs {
      height: auto;
      width: 0.25rem;
      flex-shrink: 0; } }
  @media (min-width: 480px) {
    .x-space-1-sm {
      height: auto;
      width: 0.25rem;
      flex-shrink: 0; } }
  @media (min-width: 720px) {
    .x-space-1-md {
      height: auto;
      width: 0.25rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .x-space-1-nav {
      height: auto;
      width: 0.25rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .x-space-1-lg {
      height: auto;
      width: 0.25rem;
      flex-shrink: 0; } }
  @media (min-width: 1280px) {
    .x-space-1-xl {
      height: auto;
      width: 0.25rem;
      flex-shrink: 0; } }
  @media (min-width: 1920px) {
    .x-space-1-xxl {
      height: auto;
      width: 0.25rem;
      flex-shrink: 0; } }

.x-space-2 {
  height: auto;
  width: 0.5rem;
  flex-shrink: 0; }
  @media (min-width: 0px) {
    .x-space-2-xs {
      height: auto;
      width: 0.5rem;
      flex-shrink: 0; } }
  @media (min-width: 480px) {
    .x-space-2-sm {
      height: auto;
      width: 0.5rem;
      flex-shrink: 0; } }
  @media (min-width: 720px) {
    .x-space-2-md {
      height: auto;
      width: 0.5rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .x-space-2-nav {
      height: auto;
      width: 0.5rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .x-space-2-lg {
      height: auto;
      width: 0.5rem;
      flex-shrink: 0; } }
  @media (min-width: 1280px) {
    .x-space-2-xl {
      height: auto;
      width: 0.5rem;
      flex-shrink: 0; } }
  @media (min-width: 1920px) {
    .x-space-2-xxl {
      height: auto;
      width: 0.5rem;
      flex-shrink: 0; } }

.x-space-3 {
  height: auto;
  width: 1rem;
  flex-shrink: 0; }
  @media (min-width: 0px) {
    .x-space-3-xs {
      height: auto;
      width: 1rem;
      flex-shrink: 0; } }
  @media (min-width: 480px) {
    .x-space-3-sm {
      height: auto;
      width: 1rem;
      flex-shrink: 0; } }
  @media (min-width: 720px) {
    .x-space-3-md {
      height: auto;
      width: 1rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .x-space-3-nav {
      height: auto;
      width: 1rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .x-space-3-lg {
      height: auto;
      width: 1rem;
      flex-shrink: 0; } }
  @media (min-width: 1280px) {
    .x-space-3-xl {
      height: auto;
      width: 1rem;
      flex-shrink: 0; } }
  @media (min-width: 1920px) {
    .x-space-3-xxl {
      height: auto;
      width: 1rem;
      flex-shrink: 0; } }

.x-space-4 {
  height: auto;
  width: 1.5rem;
  flex-shrink: 0; }
  @media (min-width: 0px) {
    .x-space-4-xs {
      height: auto;
      width: 1.5rem;
      flex-shrink: 0; } }
  @media (min-width: 480px) {
    .x-space-4-sm {
      height: auto;
      width: 1.5rem;
      flex-shrink: 0; } }
  @media (min-width: 720px) {
    .x-space-4-md {
      height: auto;
      width: 1.5rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .x-space-4-nav {
      height: auto;
      width: 1.5rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .x-space-4-lg {
      height: auto;
      width: 1.5rem;
      flex-shrink: 0; } }
  @media (min-width: 1280px) {
    .x-space-4-xl {
      height: auto;
      width: 1.5rem;
      flex-shrink: 0; } }
  @media (min-width: 1920px) {
    .x-space-4-xxl {
      height: auto;
      width: 1.5rem;
      flex-shrink: 0; } }

.x-space-5 {
  height: auto;
  width: 2rem;
  flex-shrink: 0; }
  @media (min-width: 0px) {
    .x-space-5-xs {
      height: auto;
      width: 2rem;
      flex-shrink: 0; } }
  @media (min-width: 480px) {
    .x-space-5-sm {
      height: auto;
      width: 2rem;
      flex-shrink: 0; } }
  @media (min-width: 720px) {
    .x-space-5-md {
      height: auto;
      width: 2rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .x-space-5-nav {
      height: auto;
      width: 2rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .x-space-5-lg {
      height: auto;
      width: 2rem;
      flex-shrink: 0; } }
  @media (min-width: 1280px) {
    .x-space-5-xl {
      height: auto;
      width: 2rem;
      flex-shrink: 0; } }
  @media (min-width: 1920px) {
    .x-space-5-xxl {
      height: auto;
      width: 2rem;
      flex-shrink: 0; } }

.x-space-6 {
  height: auto;
  width: 3rem;
  flex-shrink: 0; }
  @media (min-width: 0px) {
    .x-space-6-xs {
      height: auto;
      width: 3rem;
      flex-shrink: 0; } }
  @media (min-width: 480px) {
    .x-space-6-sm {
      height: auto;
      width: 3rem;
      flex-shrink: 0; } }
  @media (min-width: 720px) {
    .x-space-6-md {
      height: auto;
      width: 3rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .x-space-6-nav {
      height: auto;
      width: 3rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .x-space-6-lg {
      height: auto;
      width: 3rem;
      flex-shrink: 0; } }
  @media (min-width: 1280px) {
    .x-space-6-xl {
      height: auto;
      width: 3rem;
      flex-shrink: 0; } }
  @media (min-width: 1920px) {
    .x-space-6-xxl {
      height: auto;
      width: 3rem;
      flex-shrink: 0; } }

.x-space-7 {
  height: auto;
  width: 4rem;
  flex-shrink: 0; }
  @media (min-width: 0px) {
    .x-space-7-xs {
      height: auto;
      width: 4rem;
      flex-shrink: 0; } }
  @media (min-width: 480px) {
    .x-space-7-sm {
      height: auto;
      width: 4rem;
      flex-shrink: 0; } }
  @media (min-width: 720px) {
    .x-space-7-md {
      height: auto;
      width: 4rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .x-space-7-nav {
      height: auto;
      width: 4rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .x-space-7-lg {
      height: auto;
      width: 4rem;
      flex-shrink: 0; } }
  @media (min-width: 1280px) {
    .x-space-7-xl {
      height: auto;
      width: 4rem;
      flex-shrink: 0; } }
  @media (min-width: 1920px) {
    .x-space-7-xxl {
      height: auto;
      width: 4rem;
      flex-shrink: 0; } }

.x-space-8 {
  height: auto;
  width: 5rem;
  flex-shrink: 0; }
  @media (min-width: 0px) {
    .x-space-8-xs {
      height: auto;
      width: 5rem;
      flex-shrink: 0; } }
  @media (min-width: 480px) {
    .x-space-8-sm {
      height: auto;
      width: 5rem;
      flex-shrink: 0; } }
  @media (min-width: 720px) {
    .x-space-8-md {
      height: auto;
      width: 5rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .x-space-8-nav {
      height: auto;
      width: 5rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .x-space-8-lg {
      height: auto;
      width: 5rem;
      flex-shrink: 0; } }
  @media (min-width: 1280px) {
    .x-space-8-xl {
      height: auto;
      width: 5rem;
      flex-shrink: 0; } }
  @media (min-width: 1920px) {
    .x-space-8-xxl {
      height: auto;
      width: 5rem;
      flex-shrink: 0; } }

.form-group {
  background: none;
  margin: 0 0 0.5rem;
  padding: 0; }
  .form-group > label {
    display: block;
    font-size: 1em;
    margin: 0 0 0.25rem 0; }
  .form-group.is-disabled, .form-group[disabled] {
    cursor: not-allowed; }
    .form-group.is-disabled:not(.is-active), .form-group[disabled]:not(.is-active) {
      opacity: 0.4; }
      .form-group.is-disabled:not(.is-active):hover, .form-group[disabled]:not(.is-active):hover {
        opacity: 0.2; }
  .form-group[data-grid] > label {
    align-self: center;
    margin: 0; }

.form-boolean {
  background: none;
  margin: 0 0 0.5rem;
  padding: 0; }
  @supports (display: flex) {
    .form-boolean {
      display: flex;
      flex-direction: row; }
      .form-boolean label {
        order: 1; } }
  .form-boolean label {
    font-size: 1em;
    margin: 0 0 0.25rem 0; }
  .form-boolean [type='radio'],
  .form-boolean [type='checkbox'] {
    flex-shrink: 0;
    margin-right: 0.5rem; }
  .form-boolean.is-disabled, .form-boolean[disabled] {
    cursor: not-allowed; }
    .form-boolean.is-disabled:not(.is-active), .form-boolean[disabled]:not(.is-active) {
      opacity: 0.4; }
      .form-boolean.is-disabled:not(.is-active):hover, .form-boolean[disabled]:not(.is-active):hover {
        opacity: 0.2; }

.alert, .alert-small, .alert-big {
  background-color: #f7f7f7;
  color: #2a3d54;
  border-radius: 0.25rem;
  font-weight: normal;
  text-align: center; }

.alert {
  font-size: 1em;
  margin: 0 0 1rem 0;
  padding: 1rem 1.5rem; }

.alert-small {
  font-size: 0.875em;
  margin: 0 0 1rem 0;
  padding: 0.5rem 0.75rem; }

.alert-big {
  font-size: 1.125em;
  margin: 0 0 1rem 0;
  padding: 1.5rem 1.75rem; }

.alert_minor {
  background-color: rgba(47, 206, 111, 0.2);
  color: #192432; }

.alert_link {
  background-color: #2fce6f;
  color: white; }

.alert_super {
  background-color: #14a656;
  color: white; }

.alert_highlight {
  background-color: #fcff66;
  color: #080c10; }

.alert_invert {
  background-color: #fff;
  color: #2a3d54; }

.alert_info {
  background-color: #22e7e7;
  color: black; }

.alert_error {
  background-color: #e72222;
  color: white; }

.alert_success {
  background-color: #22e722;
  color: white; }

.alert_warning {
  background-color: #e7c822;
  color: black; }

.alert h1:last-child, .alert h1:only-child,
.alert h2:last-child,
.alert h2:only-child,
.alert h3:last-child,
.alert h3:only-child,
.alert h4:last-child,
.alert h4:only-child,
.alert h5:last-child,
.alert h5:only-child,
.alert h6:last-child,
.alert h6:only-child {
  margin: 0; }

.alert p:last-child, .alert p:only-child {
  margin: 0; }

.alert.alert_outlined {
  background: none;
  border-style: solid;
  border-color: #f7f7f7;
  border-width: 0.125rem; }
  .alert.alert_outlined.alert_minor {
    background: none;
    border-color: rgba(47, 206, 111, 0.2);
    color: #1d7c43; }
  .alert.alert_outlined.alert_link {
    background: none;
    border-color: #2fce6f;
    color: #1c7b42; }
  .alert.alert_outlined.alert_super {
    background: none;
    border-color: #14a656;
    color: #0f783e; }
  .alert.alert_outlined.alert_highlight {
    background: none;
    border-color: #fcff66;
    color: #646600; }
  .alert.alert_outlined.alert_invert {
    background: none;
    border-color: #fff;
    color: #2a3d54; }
  .alert.alert_outlined.alert_info {
    background: none;
    border-color: #22e7e7;
    color: #0b6565; }
  .alert.alert_outlined.alert_error {
    background: none;
    border-color: #e72222;
    color: #c11515; }
  .alert.alert_outlined.alert_success {
    background: none;
    border-color: #22e722;
    color: #15c115; }
  .alert.alert_outlined.alert_warning {
    background: none;
    border-color: #e7c822;
    color: #65570b; }

.btn-group > .btn {
  border-radius: 0;
  margin: 0;
  position: relative; }
  @supports not (display: flex) {
    .btn-group > .btn {
      float: left; } }
  .btn-group > .btn:first-child {
    border-radius: 0.25rem 0 0 0.25rem; }
    .btn-group > .btn:first-child.btn_round {
      border-radius: 1.25rem 0 0 1.25rem; }
  .btn-group > .btn:last-child {
    border-radius: 0 0.25rem 0.25rem 0; }
    .btn-group > .btn:last-child.btn_round {
      border-radius: 0 1.25rem 1.25rem 0; }
  .btn-group > .btn.btn_small {
    border-radius: 0; }
    .btn-group > .btn.btn_small:first-child {
      border-radius: 0.25rem 0 0 0.25rem; }
      .btn-group > .btn.btn_small:first-child.btn_round {
        border-radius: 1rem 0 0 1rem; }
    .btn-group > .btn.btn_small:last-child {
      border-radius: 0 0.25rem 0.25rem 0; }
      .btn-group > .btn.btn_small:last-child.btn_round {
        border-radius: 0 1rem 1rem 0; }
  .btn-group > .btn.btn_big {
    border-radius: 0; }
    .btn-group > .btn.btn_big:first-child {
      border-radius: 0.25rem 0 0 0.25rem; }
      .btn-group > .btn.btn_big:first-child.btn_round {
        border-radius: 1.5rem 0 0 1.5rem; }
    .btn-group > .btn.btn_big:last-child {
      border-radius: 0 0.25rem 0.25rem 0; }
      .btn-group > .btn.btn_big:last-child.btn_round {
        border-radius: 0 1.5rem 1.5rem 0; }
  .btn-group > .btn.btn_outlined {
    border-left-width: 0; }
    .btn-group > .btn.btn_outlined:first-child {
      border-left-width: 0.125rem; }

@supports not (display: flex) {
  .btn-group > .dropdown {
    float: left; } }

.btn-group > .dropdown > .btn {
  border-radius: 0;
  margin: 0;
  position: relative; }
  .btn-group > .dropdown > .btn.btn_outlined {
    border-left-width: 0; }

.btn-group > .dropdown:first-child > .btn {
  border-radius: 0.25rem 0 0 0.25rem; }
  .btn-group > .dropdown:first-child > .btn.btn_round {
    border-radius: 1.25rem 0 0 1.25rem; }
  .btn-group > .dropdown:first-child > .btn.btn_small {
    border-radius: 0.25rem 0 0 0.25rem; }
    .btn-group > .dropdown:first-child > .btn.btn_small.btn_round {
      border-radius: 1rem 0 0 1rem; }
  .btn-group > .dropdown:first-child > .btn.btn_big {
    border-radius: 0.25rem 0 0 0.25rem; }
    .btn-group > .dropdown:first-child > .btn.btn_big.btn_round {
      border-radius: 1.5rem 0 0 1.5rem; }
  .btn-group > .dropdown:first-child > .btn.btn_outlined {
    border-left-width: 0.125rem; }

.btn-group > .dropdown:last-child > .btn {
  border-radius: 0 0.25rem 0.25rem 0; }
  .btn-group > .dropdown:last-child > .btn.btn_round {
    border-radius: 0 1.25rem 1.25rem 0; }
  .btn-group > .dropdown:last-child > .btn.btn_small {
    border-radius: 0 0.25rem 0.25rem 0; }
    .btn-group > .dropdown:last-child > .btn.btn_small.btn_round {
      border-radius: 0 1rem 1rem 0; }
  .btn-group > .dropdown:last-child > .btn.btn_big {
    border-radius: 0 0.25rem 0.25rem 0; }
    .btn-group > .dropdown:last-child > .btn.btn_big.btn_round {
      border-radius: 0 1.5rem 1.5rem 0; }

@supports (display: flex) {
  .btn-group {
    display: flex; } }

.btn-group__divider {
  background: rgba(42, 61, 84, 0.1);
  width: 0.0625rem;
  margin-left: -0.0625rem;
  z-index: 1; }

.card {
  position: relative;
  z-index: 1;
  background: #fff;
  color: inherit;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem; }

.card {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.1), 0px 4px 5px 0px rgba(0, 0, 0, 0.08), 0px 1px 10px 0px rgba(0, 0, 0, 0.06); }

.dialog-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: none;
  overflow: hidden;
  overscroll-behavior: contain;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.2);
  z-index: 100; }
  .dialog-wrapper.is-active {
    display: flex; }
    .dialog-wrapper.is-active > .dialog {
      display: flex; }

.dialog {
  display: none;
  position: relative;
  max-height: 100%;
  flex-direction: column;
  background: #fff;
  color: #2a3d54;
  padding: 0;
  border-radius: 0.5rem;
  box-shadow: 0 0.25rem 1rem -0.25rem rgba(0, 0, 0, 0.16) , 0 0.5rem 1.5rem 0 rgba(0, 0, 0, 0.1); }
  .dialog:not([data-col]) {
    min-width: 15rem; }
    @media (min-width: 640px) {
      .dialog:not([data-col]) {
        max-width: 50%; }
        .dialog:not([data-col]) .dialog-header,
        .dialog:not([data-col]) .dialog-body,
        .dialog:not([data-col]) .dialog-footer {
          max-width: 40rem; } }
  .dialog.is-active {
    display: flex;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 100; }
  .dialog > .dialog-dismiss + .dialog-body {
    margin-top: 2.5rem; }

.dialog-dismiss {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  opacity: 0.4;
  transition: opacity 0.2s; }
  .dialog-dismiss:only-child {
    margin-left: auto; }
  .dialog-dismiss:hover {
    opacity: 1; }

.dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0;
  padding: 0.5rem; }
  .dialog-header .dialog-dismiss {
    position: relative;
    top: auto;
    right: auto; }

.dialog-body {
  flex-grow: 1;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  margin: 0;
  padding: 0.5rem; }

.dialog-footer {
  flex-shrink: 0;
  flex-grow: 0;
  margin: 0;
  padding: 0.5rem; }

.deck-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  flex-direction: column;
  overflow: hidden;
  overscroll-behavior: contain;
  background: rgba(0, 0, 0, 0.2);
  z-index: 100; }
  .deck-wrapper.is-active {
    display: flex; }
    .deck-wrapper.is-active > .deck {
      display: flex;
      align-self: flex-start; }
    .deck-wrapper.is-active > .opens-left {
      align-self: flex-start; }
    .deck-wrapper.is-active > .opens-right {
      align-self: flex-end; }

.deck {
  display: none;
  position: relative;
  height: 100%;
  flex-direction: column;
  background: #fff;
  color: #2a3d54;
  padding: 0;
  box-shadow: 0.25rem 0 1rem -0.25rem rgba(0, 0, 0, 0.16) , 0.5rem 0 1.5rem 0 rgba(0, 0, 0, 0.1); }
  .deck:not([data-col]) {
    min-width: 15rem; }
    @media (min-width: 640px) {
      .deck:not([data-col]) {
        max-width: 50%; }
        .deck:not([data-col]) .deck-header,
        .deck:not([data-col]) .deck-body,
        .deck:not([data-col]) .deck-footer {
          max-width: 40rem; } }
  .deck.is-active {
    left: 0; }
  .deck.opens-left {
    box-shadow: 0.25rem 0 1rem -0.25rem rgba(0, 0, 0, 0.16) , 0.5rem 0 1.5rem 0 rgba(0, 0, 0, 0.1); }
    .deck.opens-left.is-active {
      left: 0; }
  .deck.opens-right {
    box-shadow: -0.25rem 0 1rem -0.25rem rgba(0, 0, 0, 0.16) , -0.5rem 0 1.5rem 0 rgba(0, 0, 0, 0.1); }
    .deck.opens-right.is-active {
      left: auto;
      right: 0; }
  .deck.is-active {
    display: flex;
    position: fixed;
    top: 0;
    z-index: 100; }
  .deck > .deck-dismiss + .deck-body {
    margin-top: 2.5rem; }

.deck-dismiss {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  opacity: 0.4;
  transition: opacity 0.2s;
  color: #2a3d54; }
  .deck-dismiss:only-child {
    margin-left: auto; }
  .deck-dismiss:hover {
    opacity: 1; }

.deck-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0;
  padding: 0.5rem; }
  .deck-header .deck-dismiss {
    position: relative;
    top: auto;
    right: auto; }

.deck-body {
  flex-grow: 1;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  margin: 0;
  padding: 0.5rem; }

.deck-footer {
  flex-shrink: 0;
  flex-grow: 0;
  margin: 0;
  padding: 0.5rem; }

.toast-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  overflow: hidden;
  overscroll-behavior: contain;
  background: rgba(0, 0, 0, 0.2);
  z-index: 100; }
  .toast-wrapper.is-active {
    display: flex; }
    .toast-wrapper.is-active > .toast {
      display: flex;
      align-self: flex-start; }
    .toast-wrapper.is-active > .opens-top {
      align-self: flex-start; }
    .toast-wrapper.is-active > .opens-bottom {
      align-self: flex-end; }

.toast {
  display: none;
  position: relative;
  width: 100%;
  max-height: 100%;
  flex-direction: column;
  background: #fff;
  color: #2a3d54;
  padding: 0;
  box-shadow: 0 0.25rem 1rem -0.25rem rgba(0, 0, 0, 0.16) , 0 0.5rem 1.5rem 0 rgba(0, 0, 0, 0.1); }
  .toast.is-active {
    top: 0; }
  .toast.opens-top {
    box-shadow: 0 0.25rem 1rem -0.25rem rgba(0, 0, 0, 0.16) , 0 0.5rem 1.5rem 0 rgba(0, 0, 0, 0.1); }
    .toast.opens-top.is-active {
      top: 0; }
  .toast.opens-bottom {
    box-shadow: 0 -0.25rem 1rem -0.25rem rgba(0, 0, 0, 0.16) , 0 -0.5rem 1.5rem 0 rgba(0, 0, 0, 0.1); }
    .toast.opens-bottom.is-active {
      top: auto;
      bottom: 0; }
  .toast.is-active {
    display: flex;
    position: fixed;
    left: 0;
    z-index: 100; }
  .toast[data-col~='1'] {
    margin-left: 45.83333%; }
  .toast[data-col~='2'] {
    margin-left: 41.66667%; }
  .toast[data-col~='3'] {
    margin-left: 37.5%; }
  .toast[data-col~='4'] {
    margin-left: 33.33333%; }
  .toast[data-col~='5'] {
    margin-left: 29.16667%; }
  .toast[data-col~='6'] {
    margin-left: 25%; }
  .toast[data-col~='7'] {
    margin-left: 20.83333%; }
  .toast[data-col~='8'] {
    margin-left: 16.66667%; }
  .toast[data-col~='9'] {
    margin-left: 12.5%; }
  .toast[data-col~='10'] {
    margin-left: 8.33333%; }
  .toast[data-col~='11'] {
    margin-left: 4.16667%; }
  .toast[data-col~='12'] {
    margin-left: 0%; }

.toast-dismiss {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  opacity: 0.4;
  transition: opacity 0.2s;
  color: #2a3d54; }
  .toast-dismiss:only-child {
    margin-left: auto; }
  .toast-dismiss:hover {
    opacity: 1; }

.toast-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0;
  padding: 0.5rem; }
  .toast-header .toast-dismiss {
    position: relative;
    top: auto;
    right: auto; }

.toast-body {
  flex-grow: 1;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  margin: 0;
  padding: 0.5rem; }

.toast-footer {
  flex-shrink: 0;
  flex-grow: 0;
  margin: 0;
  padding: 0.5rem; }

.snackbar-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  overflow: hidden;
  overscroll-behavior: contain;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.2);
  z-index: 100; }
  .snackbar-wrapper.is-active {
    display: flex; }
    .snackbar-wrapper.is-active > .snackbar {
      display: flex; }
    .snackbar-wrapper.is-active > .opens-bottom {
      margin-top: auto; }

.snackbar {
  display: none;
  position: relative;
  width: 100%;
  max-height: 100%;
  flex-direction: column;
  background: rgba(42, 61, 84, 0.8);
  color: #fff;
  padding: 0;
  border-radius: 0.25rem; }
  .snackbar:not([data-col]) {
    min-width: 15rem; }
    @media (min-width: 640px) {
      .snackbar:not([data-col]) {
        max-width: 50%; }
        .snackbar:not([data-col]) .dialog-header,
        .snackbar:not([data-col]) .dialog-body,
        .snackbar:not([data-col]) .dialog-footer {
          max-width: 40rem; } }
  .snackbar.is-active {
    top: 0; }
  .snackbar.opens-top {
    box-shadow: none; }
    .snackbar.opens-top.is-active {
      top: 1rem; }
  .snackbar.opens-bottom {
    box-shadow: none; }
    .snackbar.opens-bottom.is-active {
      top: auto;
      bottom: 1rem; }
  .snackbar.is-active {
    display: flex;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100; }

.snackbar-dismiss {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  opacity: 0.4;
  transition: opacity 0.2s;
  color: #fff; }
  .snackbar-dismiss:only-child {
    margin-left: auto; }
  .snackbar-dismiss:hover {
    opacity: 1; }

.snackbar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0;
  padding: 0.5rem; }
  .snackbar-header .snackbar-dismiss {
    position: relative;
    top: auto;
    right: auto; }

.snackbar-body {
  flex-grow: 1;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  margin: 0;
  padding: 0.5rem; }

.snackbar-footer {
  flex-shrink: 0;
  flex-grow: 0;
  margin: 0;
  padding: 0.5rem; }

.xui-overlay {
  position: relative;
  background-color: #fff;
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem -0.25rem rgba(0, 0, 0, 0.16) , 0 0.25rem 1.5rem 0 rgba(0, 0, 0, 0.1);
  color: #2a3d54;
  padding: 0.25rem; }
  .xui-overlay__dismiss {
    padding-left: 0;
    padding-right: 0;
    position: absolute;
    right: 0;
    top: 0; }

@keyframes showOverlayWrapper {
  0% {
    clip-path: circle(0% at 50% 50%);
    opacity: 0; }
  100% {
    clip-path: circle(100% at 50% 50%);
    opacity: 1; } }

@keyframes showOverlay {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.xui-overlay-wrapper {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  z-index: 100; }
  .xui-overlay-wrapper.is-targeted .xui-overlay {
    animation: showOverlay 0.4s forwards ease-out;
    animation-delay: 0.4s;
    display: none;
    opacity: 0; }
  .xui-overlay-wrapper.is-targeted:target {
    animation: showOverlayWrapper 0.4s forwards ease-in-out;
    display: initial;
    opacity: 1; }
    .xui-overlay-wrapper.is-targeted:target .xui-overlay {
      display: initial; }
  .xui-overlay-wrapper.has-backdrop {
    background: rgba(0, 0, 0, 0.2); }

.overlay-wrapper.is-active .overlay {
  display: flex; }

.overlay {
  display: none;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #fff;
  color: #2a3d54;
  padding: 0;
  z-index: 100; }
  .overlay.is-active {
    display: flex; }

.overlay-dismiss {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  opacity: .4;
  transition: opacity .2s;
  color: #2a3d54; }
  .overlay-dismiss:only-child {
    margin-left: auto; }
  .overlay-dismiss:hover {
    opacity: 1; }

.overlay-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0;
  padding: 0.5rem; }
  .overlay-header .overlay-dismiss {
    position: relative;
    top: auto;
    right: auto; }

.overlay-body {
  flex-grow: 1;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  margin: 0;
  padding: 0.5rem; }

.overlay-footer {
  flex-shrink: 0;
  flex-grow: 0;
  margin: 0;
  padding: 0.5rem; }

.dropdown {
  cursor: pointer;
  outline: none;
  position: relative; }
  .dropdown.dropdown_center .dropdown-options {
    left: 50%;
    transform: translateX(-50%); }
  .dropdown.dropdown_right .dropdown-options {
    right: 0; }
  .dropdown.dropdown_left .dropdown-options {
    left: 0; }
  .dropdown.dropdown_full .dropdown-options {
    left: 0;
    width: 100%; }
  .dropdown.dropdown_above .dropdown-options {
    bottom: 100%;
    top: initial; }
  .dropdown:focus > .dropdown-options {
    opacity: 1;
    visibility: visible; }
  .dropdown.on-hover:hover .dropdown-options {
    opacity: 1;
    visibility: visible; }

.dropdown-options {
  list-style: none;
  margin: 0;
  opacity: 0;
  position: absolute;
  top: 100%;
  transition: visibility 0.5s, opacity 0.2s;
  visibility: hidden;
  z-index: 10;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  background: #fff;
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem -0.25rem rgba(0, 0, 0, 0.16) , 0 0.25rem 1.5rem 0 rgba(0, 0, 0, 0.1);
  color: #2a3d54;
  max-height: 20rem;
  padding: 0.25rem; }
  .dropdown-options .dropdown_divider {
    background-color: rgba(42, 61, 84, 0.1);
    height: 0.0625rem;
    margin: 0.25rem 0;
    padding: 0; }

.dropdown-option {
  display: block; }
  @supports (display: flex) {
    .dropdown-option {
      display: flex; } }
  .dropdown-option > a:not([class]) {
    cursor: pointer;
    border: 0;
    display: block;
    height: auto;
    outline: none;
    position: relative;
    text-decoration: none;
    touch-action: manipulation;
    user-select: none;
    width: 100%;
    padding: 0.55rem 0.825rem;
    font-size: 1rem;
    line-height: 1.4rem;
    min-height: 2.5rem;
    min-width: 2.5rem;
    color: #2a3d54;
    background: none;
    font-weight: bolder;
    margin: 0;
    text-align: center; }
    .dropdown-option > a:not([class]) .bubble:first-child,
    .dropdown-option > a:not([class]) .tag:first-child {
      margin-left: -0.55rem;
      margin-right: 0.55rem; }
    .dropdown-option > a:not([class]) .bubble:last-child,
    .dropdown-option > a:not([class]) .tag:last-child {
      margin-left: 0.55rem;
      margin-right: -0.55rem; }
    .dropdown-option > a:not([class]) .bubble:only-child,
    .dropdown-option > a:not([class]) .tag:only-child {
      margin-left: -0.825rem;
      margin-right: -0.825rem; }
    .dropdown-option > a:not([class]) .ec-icon {
      padding-right: 0.55rem; }
      @supports (display: inline-flex) {
        .dropdown-option > a:not([class]) .ec-icon {
          display: inline-flex;
          align-items: center;
          justify-content: center; } }
      .dropdown-option > a:not([class]) .ec-icon::before {
        font-size: 1em;
        line-height: inherit;
        vertical-align: top; }
      .dropdown-option > a:not([class]) .ec-icon_right {
        padding-left: 0.55rem;
        padding-right: 0; }
      .dropdown-option > a:not([class]) .ec-icon:empty {
        padding-left: 0;
        padding-right: 0; }
        .dropdown-option > a:not([class]) .ec-icon:empty:first-child {
          margin-left: -0.275rem;
          margin-right: 0.55rem; }
        .dropdown-option > a:not([class]) .ec-icon:empty:last-child {
          margin-left: 0.55rem;
          margin-right: -0.275rem; }
        .dropdown-option > a:not([class]) .ec-icon:empty:only-child {
          margin-left: -0.825rem;
          margin-right: -0.825rem; }
        .dropdown-option > a:not([class]) .ec-icon:empty::before {
          margin-left: 0;
          margin-right: 0; }
    .dropdown-option > a:not([class]):hover {
      color: #2fce6f;
      background: rgba(47, 206, 111, 0.1); }
  .dropdown-option > .btn {
    display: block;
    width: 100%; }

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%; }

.table {
  margin: 0 0 1rem 0; }
  .table thead {
    border-top: 0.0625rem solid rgba(42, 61, 84, 0.1); }
  .table td {
    border-bottom: 0.0625rem solid rgba(42, 61, 84, 0.1); }
  .table td,
  .table th {
    padding: 0.5rem; }
  .table tbody tr:first-child td {
    border-top: 0.0625rem solid rgba(42, 61, 84, 0.1); }

.table_clear thead {
  border-top: 0; }

.table_clear td {
  border-bottom: 0; }

.table_clear tbody tr:first-child td {
  border-top: 0; }

.table_bordered td,
.table_bordered th {
  border-right: 0.0625rem solid rgba(42, 61, 84, 0.1); }
  .table_bordered td:first-child,
  .table_bordered th:first-child {
    border-left: 0.0625rem solid rgba(42, 61, 84, 0.1); }

.table_bordered tbody tr:first-child td {
  border-top: 0.0625rem solid rgba(42, 61, 84, 0.1); }

.nav {
  margin: 0;
  padding: 0; }

.nav__items {
  list-style: none;
  margin: 0;
  padding: 0; }
  @supports (display: flex) {
    .nav__items {
      display: flex;
      align-items: center; } }

.nav__item {
  display: inline-block;
  padding: 0.25rem; }
  .nav__item .btn {
    margin: 0; }

.nav__divider {
  display: block;
  height: 100%;
  background-color: rgba(42, 61, 84, 0.1);
  margin: 0 0.25rem;
  width: 0.0625rem; }

@supports (display: flex) {
  .nav_stacked .nav__items {
    display: flex;
    flex-direction: column;
    align-items: stretch; } }

.nav_stacked .nav__divider {
  width: 100%;
  height: 0.0625rem;
  margin: 0.25rem 0; }

.nav_stacked .nav__item {
  display: block; }
  .nav_stacked .nav__item .btn {
    display: block;
    width: 100%; }

.well, .well-small, .well-big {
  border-radius: 0.25rem;
  font-weight: normal;
  background: #f7f7f7;
  color: inherit; }

.well {
  margin: 0 0 1rem 0;
  padding: 1rem 1.5rem; }
  .well_small {
    margin: 0 0 1rem 0;
    padding: 0.5rem 0.75rem; }
  .well_big {
    margin: 0 0 1rem 0;
    padding: 1.5rem 1.75rem; }

.well-small {
  margin: 0 0 1rem 0;
  padding: 0.5rem 0.75rem; }

.well-big {
  margin: 0 0 1rem 0;
  padding: 1.5rem 1.75rem; }

.well > p:last-child, .well > p:only-child {
  margin: 0; }

.xui-tabs {
  background: none;
  margin: 0;
  padding: 0; }
  .xui-tabs [type='radio']:nth-of-type(1):checked ~ .xui-tab:nth-of-type(1) {
    display: block; }
  .xui-tabs [type='radio']:nth-of-type(2):checked ~ .xui-tab:nth-of-type(2) {
    display: block; }
  .xui-tabs [type='radio']:nth-of-type(3):checked ~ .xui-tab:nth-of-type(3) {
    display: block; }
  .xui-tabs [type='radio']:nth-of-type(4):checked ~ .xui-tab:nth-of-type(4) {
    display: block; }
  .xui-tabs [type='radio']:nth-of-type(5):checked ~ .xui-tab:nth-of-type(5) {
    display: block; }
  .xui-tabs [type='radio']:nth-of-type(6):checked ~ .xui-tab:nth-of-type(6) {
    display: block; }
  .xui-tabs [type='radio']:nth-of-type(7):checked ~ .xui-tab:nth-of-type(7) {
    display: block; }
  .xui-tabs [type='radio']:nth-of-type(8):checked ~ .xui-tab:nth-of-type(8) {
    display: block; }
  .xui-tabs [type='radio']:nth-of-type(9):checked ~ .xui-tab:nth-of-type(9) {
    display: block; }
  .xui-tabs [type='radio']:nth-of-type(10):checked ~ .xui-tab:nth-of-type(10) {
    display: block; }

.xui-tabs-nav {
  background: none;
  margin: 0;
  padding: 0; }
  .xui-tabs-nav > ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    @supports (display: flex) {
      .xui-tabs-nav > ul {
        align-items: center;
        display: flex;
        justify-content: center; } }

.xui-tabs-nav-item {
  position: relative;
  text-align: center;
  padding: 0; }
  @supports not (display: flex) {
    .xui-tabs-nav-item {
      display: inline-block; } }
  .xui-tabs-nav-item label {
    cursor: pointer;
    display: block;
    text-align: center;
    border-radius: 0;
    background: none;
    color: #2a3d54;
    font-weight: bolder;
    padding: 0.25rem 0.5rem; }
    .xui-tabs-nav-item label:target {
      background: none;
      color: #2fce6f; }
    .xui-tabs-nav-item label:hover {
      background: none;
      color: #2fce6f; }

.xui-tab {
  display: none;
  background: none;
  margin: 0;
  padding: 0; }

.xui-accordion {
  list-style: none;
  margin: 0 -0.5rem;
  padding: 0; }

.xui-accordion-item {
  padding: 0.25rem 0;
  position: relative; }
  .xui-accordion-item__summary {
    align-items: center;
    border-radius: 0.25rem;
    color: #2fce6f;
    cursor: pointer;
    display: flex;
    font-weight: bolder;
    justify-content: space-between;
    padding: 0.5rem;
    transition: all 0.4s; }
    .xui-accordion-item__summary h1,
    .xui-accordion-item__summary h2,
    .xui-accordion-item__summary h3,
    .xui-accordion-item__summary h4,
    .xui-accordion-item__summary h5,
    .xui-accordion-item__summary h6 {
      margin: 0; }
    .xui-accordion-item__summary:hover {
      background-color: rgba(47, 206, 111, 0.1);
      color: #28c064; }
      .xui-accordion-item__summary:hover::after {
        color: #28c064; }
    .xui-accordion-item__summary::after {
      color: rgba(42, 61, 84, 0.6);
      margin: 0 0.5rem;
      transition: all 0.4s;
      content: '\e01b'; }
  .xui-accordion-item__detail {
    display: none;
    height: auto;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    padding: 0.5rem;
    transition: all 0.8s; }
  .xui-accordion-item > [type='radio']:checked + .xui-accordion-item__summary,
  .xui-accordion-item > [type='checkbox']:checked + .xui-accordion-item__summary {
    color: #28c064; }
    .xui-accordion-item > [type='radio']:checked + .xui-accordion-item__summary::after,
    .xui-accordion-item > [type='checkbox']:checked + .xui-accordion-item__summary::after {
      color: #28c064;
      content: '\e098'; }
    .xui-accordion-item > [type='radio']:checked + .xui-accordion-item__summary + .xui-accordion-item__detail,
    .xui-accordion-item > [type='checkbox']:checked + .xui-accordion-item__summary + .xui-accordion-item__detail {
      display: block;
      max-height: 800px;
      opacity: 1; }

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0; }
  .title.title_stacked {
    align-items: flex-start;
    flex-direction: column;
    margin: 0;
    padding: 0; }
  .title > .nav,
  .title > .btn {
    align-self: flex-start; }

.toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  min-height: 4rem;
  padding: 0.5rem 1rem;
  margin: 0; }
  .toolbar > .hamburger-btn {
    margin: 0; }
    .toolbar > .hamburger-btn:first-child {
      margin-right: 1rem; }
    .toolbar > .hamburger-btn:last-child {
      margin-left: 1rem; }
    .toolbar > .hamburger-btn:only-child {
      margin: 0; }
  .toolbar .nav {
    flex-grow: 0;
    margin: 0;
    padding: 0; }
    .toolbar .nav:first-child {
      margin-right: 1rem; }
    .toolbar .nav:last-child {
      margin-left: 1rem; }
    .toolbar .nav .btn {
      margin: 0; }
    .toolbar .nav:only-child {
      display: flex;
      justify-content: flex-end;
      margin: 0;
      width: 100%; }

.toolbar__title {
  flex-grow: 1; }
  .toolbar__title h1,
  .toolbar__title h2,
  .toolbar__title h3,
  .toolbar__title h4,
  .toolbar__title h5,
  .toolbar__title h6 {
    line-height: 1em;
    margin: 0;
    font-size: 1.25em; }
  .toolbar__title img {
    height: 2rem;
    width: auto; }
  .toolbar__title.toolbar__title_center h1,
  .toolbar__title.toolbar__title_center h2,
  .toolbar__title.toolbar__title_center h3,
  .toolbar__title.toolbar__title_center h4,
  .toolbar__title.toolbar__title_center h5,
  .toolbar__title.toolbar__title_center h6 {
    -webkit-font-smoothing: antialiased;
    left: 50%;
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) translateX(-50%); }
  .toolbar__title.toolbar__title_center img {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) translateX(-50%); }

.xui-list-group {
  width: 100%;
  border-radius: 0;
  padding: 0;
  margin: 0 0 1rem;
  background: none; }
  .xui-list-group__header {
    border-radius: 0;
    padding: 0;
    margin: 0 0 1rem;
    background: none;
    color: inherit; }

.xui-list {
  list-style: none;
  border-radius: 0;
  padding: 0;
  margin: 0 0 1rem;
  background: none;
  color: inherit; }

.xui-list-item {
  align-items: center;
  display: flex;
  transition: background-color 0.4s;
  border-radius: 0.25rem;
  padding: 0.5rem;
  margin: 0;
  background: none;
  color: inherit; }
  .xui-list-item h1,
  .xui-list-item h2,
  .xui-list-item h3,
  .xui-list-item h4,
  .xui-list-item h5,
  .xui-list-item h6 {
    margin: 0; }
  .xui-list-item:hover {
    background: none;
    color: inherit; }
  .xui-list-item.is-active {
    background: none;
    color: inherit; }
  .xui-list-item__start {
    margin-right: 1rem; }
  .xui-list-item__label {
    margin-right: 1rem; }
  .xui-list-item__end {
    margin-left: auto;
    margin-right: 0; }

.xui-accordion-item__summary::after {
  direction: ltr;
  display: inline-block;
  font-family: "ec-icon" !important;
  font-size: inherit;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal !important;
  font-weight: normal !important;
  letter-spacing: normal;
  line-height: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none !important;
  vertical-align: text-bottom;
  white-space: nowrap;
  word-wrap: normal; }

[data-row] {
  margin-left: -16px;
  margin-right: -16px; }
  [data-row] > [data-col] {
    float: left;
    padding-left: 16px !important;
    padding-right: 16px !important;
    position: relative; }
  [data-row]::before, [data-row]::after {
    content: '';
    display: table; }
  [data-row]::after {
    clear: both; }

[data-row~="no-gutter"] {
  margin-left: 0 !important;
  margin-right: 0 !important; }
  [data-row~="no-gutter"] > [data-col] {
    padding-left: 0 !important;
    padding-right: 0 !important; }

[data-row~="small-grid"] {
  margin-left: -8px !important;
  margin-right: -8px !important; }
  [data-row~="small-grid"] > [data-col] {
    padding-left: 8px !important;
    padding-right: 8px !important; }

[data-col~="pull-0"] {
  left: initial;
  right: initial; }

[data-col~="push-0"] {
  right: initial;
  left: initial; }

[data-col~="pull-1"] {
  left: initial;
  right: 8.33333%; }

[data-col~="push-1"] {
  right: initial;
  left: 8.33333%; }

[data-col~="pull-2"] {
  left: initial;
  right: 16.66667%; }

[data-col~="push-2"] {
  right: initial;
  left: 16.66667%; }

[data-col~="pull-3"] {
  left: initial;
  right: 25%; }

[data-col~="push-3"] {
  right: initial;
  left: 25%; }

[data-col~="pull-4"] {
  left: initial;
  right: 33.33333%; }

[data-col~="push-4"] {
  right: initial;
  left: 33.33333%; }

[data-col~="pull-5"] {
  left: initial;
  right: 41.66667%; }

[data-col~="push-5"] {
  right: initial;
  left: 41.66667%; }

[data-col~="pull-6"] {
  left: initial;
  right: 50%; }

[data-col~="push-6"] {
  right: initial;
  left: 50%; }

[data-col~="pull-7"] {
  left: initial;
  right: 58.33333%; }

[data-col~="push-7"] {
  right: initial;
  left: 58.33333%; }

[data-col~="pull-8"] {
  left: initial;
  right: 66.66667%; }

[data-col~="push-8"] {
  right: initial;
  left: 66.66667%; }

[data-col~="pull-9"] {
  left: initial;
  right: 75%; }

[data-col~="push-9"] {
  right: initial;
  left: 75%; }

[data-col~="pull-10"] {
  left: initial;
  right: 83.33333%; }

[data-col~="push-10"] {
  right: initial;
  left: 83.33333%; }

[data-col~="pull-11"] {
  left: initial;
  right: 91.66667%; }

[data-col~="push-11"] {
  right: initial;
  left: 91.66667%; }

@media (min-width: 0px) {
  [data-col~="pull-0-xs"] {
    left: initial;
    right: initial; } }

@media (min-width: 0px) {
  [data-col~="push-0-xs"] {
    right: initial;
    left: initial; } }

@media (min-width: 0px) {
  [data-col~="pull-1-xs"] {
    left: initial;
    right: 8.33333%; } }

@media (min-width: 0px) {
  [data-col~="push-1-xs"] {
    right: initial;
    left: 8.33333%; } }

@media (min-width: 0px) {
  [data-col~="pull-2-xs"] {
    left: initial;
    right: 16.66667%; } }

@media (min-width: 0px) {
  [data-col~="push-2-xs"] {
    right: initial;
    left: 16.66667%; } }

@media (min-width: 0px) {
  [data-col~="pull-3-xs"] {
    left: initial;
    right: 25%; } }

@media (min-width: 0px) {
  [data-col~="push-3-xs"] {
    right: initial;
    left: 25%; } }

@media (min-width: 0px) {
  [data-col~="pull-4-xs"] {
    left: initial;
    right: 33.33333%; } }

@media (min-width: 0px) {
  [data-col~="push-4-xs"] {
    right: initial;
    left: 33.33333%; } }

@media (min-width: 0px) {
  [data-col~="pull-5-xs"] {
    left: initial;
    right: 41.66667%; } }

@media (min-width: 0px) {
  [data-col~="push-5-xs"] {
    right: initial;
    left: 41.66667%; } }

@media (min-width: 0px) {
  [data-col~="pull-6-xs"] {
    left: initial;
    right: 50%; } }

@media (min-width: 0px) {
  [data-col~="push-6-xs"] {
    right: initial;
    left: 50%; } }

@media (min-width: 0px) {
  [data-col~="pull-7-xs"] {
    left: initial;
    right: 58.33333%; } }

@media (min-width: 0px) {
  [data-col~="push-7-xs"] {
    right: initial;
    left: 58.33333%; } }

@media (min-width: 0px) {
  [data-col~="pull-8-xs"] {
    left: initial;
    right: 66.66667%; } }

@media (min-width: 0px) {
  [data-col~="push-8-xs"] {
    right: initial;
    left: 66.66667%; } }

@media (min-width: 0px) {
  [data-col~="pull-9-xs"] {
    left: initial;
    right: 75%; } }

@media (min-width: 0px) {
  [data-col~="push-9-xs"] {
    right: initial;
    left: 75%; } }

@media (min-width: 0px) {
  [data-col~="pull-10-xs"] {
    left: initial;
    right: 83.33333%; } }

@media (min-width: 0px) {
  [data-col~="push-10-xs"] {
    right: initial;
    left: 83.33333%; } }

@media (min-width: 0px) {
  [data-col~="pull-11-xs"] {
    left: initial;
    right: 91.66667%; } }

@media (min-width: 0px) {
  [data-col~="push-11-xs"] {
    right: initial;
    left: 91.66667%; } }

@media (min-width: 480px) {
  [data-col~="pull-0-sm"] {
    left: initial;
    right: initial; } }

@media (min-width: 480px) {
  [data-col~="push-0-sm"] {
    right: initial;
    left: initial; } }

@media (min-width: 480px) {
  [data-col~="pull-1-sm"] {
    left: initial;
    right: 8.33333%; } }

@media (min-width: 480px) {
  [data-col~="push-1-sm"] {
    right: initial;
    left: 8.33333%; } }

@media (min-width: 480px) {
  [data-col~="pull-2-sm"] {
    left: initial;
    right: 16.66667%; } }

@media (min-width: 480px) {
  [data-col~="push-2-sm"] {
    right: initial;
    left: 16.66667%; } }

@media (min-width: 480px) {
  [data-col~="pull-3-sm"] {
    left: initial;
    right: 25%; } }

@media (min-width: 480px) {
  [data-col~="push-3-sm"] {
    right: initial;
    left: 25%; } }

@media (min-width: 480px) {
  [data-col~="pull-4-sm"] {
    left: initial;
    right: 33.33333%; } }

@media (min-width: 480px) {
  [data-col~="push-4-sm"] {
    right: initial;
    left: 33.33333%; } }

@media (min-width: 480px) {
  [data-col~="pull-5-sm"] {
    left: initial;
    right: 41.66667%; } }

@media (min-width: 480px) {
  [data-col~="push-5-sm"] {
    right: initial;
    left: 41.66667%; } }

@media (min-width: 480px) {
  [data-col~="pull-6-sm"] {
    left: initial;
    right: 50%; } }

@media (min-width: 480px) {
  [data-col~="push-6-sm"] {
    right: initial;
    left: 50%; } }

@media (min-width: 480px) {
  [data-col~="pull-7-sm"] {
    left: initial;
    right: 58.33333%; } }

@media (min-width: 480px) {
  [data-col~="push-7-sm"] {
    right: initial;
    left: 58.33333%; } }

@media (min-width: 480px) {
  [data-col~="pull-8-sm"] {
    left: initial;
    right: 66.66667%; } }

@media (min-width: 480px) {
  [data-col~="push-8-sm"] {
    right: initial;
    left: 66.66667%; } }

@media (min-width: 480px) {
  [data-col~="pull-9-sm"] {
    left: initial;
    right: 75%; } }

@media (min-width: 480px) {
  [data-col~="push-9-sm"] {
    right: initial;
    left: 75%; } }

@media (min-width: 480px) {
  [data-col~="pull-10-sm"] {
    left: initial;
    right: 83.33333%; } }

@media (min-width: 480px) {
  [data-col~="push-10-sm"] {
    right: initial;
    left: 83.33333%; } }

@media (min-width: 480px) {
  [data-col~="pull-11-sm"] {
    left: initial;
    right: 91.66667%; } }

@media (min-width: 480px) {
  [data-col~="push-11-sm"] {
    right: initial;
    left: 91.66667%; } }

@media (min-width: 720px) {
  [data-col~="pull-0-md"] {
    left: initial;
    right: initial; } }

@media (min-width: 720px) {
  [data-col~="push-0-md"] {
    right: initial;
    left: initial; } }

@media (min-width: 720px) {
  [data-col~="pull-1-md"] {
    left: initial;
    right: 8.33333%; } }

@media (min-width: 720px) {
  [data-col~="push-1-md"] {
    right: initial;
    left: 8.33333%; } }

@media (min-width: 720px) {
  [data-col~="pull-2-md"] {
    left: initial;
    right: 16.66667%; } }

@media (min-width: 720px) {
  [data-col~="push-2-md"] {
    right: initial;
    left: 16.66667%; } }

@media (min-width: 720px) {
  [data-col~="pull-3-md"] {
    left: initial;
    right: 25%; } }

@media (min-width: 720px) {
  [data-col~="push-3-md"] {
    right: initial;
    left: 25%; } }

@media (min-width: 720px) {
  [data-col~="pull-4-md"] {
    left: initial;
    right: 33.33333%; } }

@media (min-width: 720px) {
  [data-col~="push-4-md"] {
    right: initial;
    left: 33.33333%; } }

@media (min-width: 720px) {
  [data-col~="pull-5-md"] {
    left: initial;
    right: 41.66667%; } }

@media (min-width: 720px) {
  [data-col~="push-5-md"] {
    right: initial;
    left: 41.66667%; } }

@media (min-width: 720px) {
  [data-col~="pull-6-md"] {
    left: initial;
    right: 50%; } }

@media (min-width: 720px) {
  [data-col~="push-6-md"] {
    right: initial;
    left: 50%; } }

@media (min-width: 720px) {
  [data-col~="pull-7-md"] {
    left: initial;
    right: 58.33333%; } }

@media (min-width: 720px) {
  [data-col~="push-7-md"] {
    right: initial;
    left: 58.33333%; } }

@media (min-width: 720px) {
  [data-col~="pull-8-md"] {
    left: initial;
    right: 66.66667%; } }

@media (min-width: 720px) {
  [data-col~="push-8-md"] {
    right: initial;
    left: 66.66667%; } }

@media (min-width: 720px) {
  [data-col~="pull-9-md"] {
    left: initial;
    right: 75%; } }

@media (min-width: 720px) {
  [data-col~="push-9-md"] {
    right: initial;
    left: 75%; } }

@media (min-width: 720px) {
  [data-col~="pull-10-md"] {
    left: initial;
    right: 83.33333%; } }

@media (min-width: 720px) {
  [data-col~="push-10-md"] {
    right: initial;
    left: 83.33333%; } }

@media (min-width: 720px) {
  [data-col~="pull-11-md"] {
    left: initial;
    right: 91.66667%; } }

@media (min-width: 720px) {
  [data-col~="push-11-md"] {
    right: initial;
    left: 91.66667%; } }

@media (min-width: 1024px) {
  [data-col~="pull-0-nav"] {
    left: initial;
    right: initial; } }

@media (min-width: 1024px) {
  [data-col~="push-0-nav"] {
    right: initial;
    left: initial; } }

@media (min-width: 1024px) {
  [data-col~="pull-1-nav"] {
    left: initial;
    right: 8.33333%; } }

@media (min-width: 1024px) {
  [data-col~="push-1-nav"] {
    right: initial;
    left: 8.33333%; } }

@media (min-width: 1024px) {
  [data-col~="pull-2-nav"] {
    left: initial;
    right: 16.66667%; } }

@media (min-width: 1024px) {
  [data-col~="push-2-nav"] {
    right: initial;
    left: 16.66667%; } }

@media (min-width: 1024px) {
  [data-col~="pull-3-nav"] {
    left: initial;
    right: 25%; } }

@media (min-width: 1024px) {
  [data-col~="push-3-nav"] {
    right: initial;
    left: 25%; } }

@media (min-width: 1024px) {
  [data-col~="pull-4-nav"] {
    left: initial;
    right: 33.33333%; } }

@media (min-width: 1024px) {
  [data-col~="push-4-nav"] {
    right: initial;
    left: 33.33333%; } }

@media (min-width: 1024px) {
  [data-col~="pull-5-nav"] {
    left: initial;
    right: 41.66667%; } }

@media (min-width: 1024px) {
  [data-col~="push-5-nav"] {
    right: initial;
    left: 41.66667%; } }

@media (min-width: 1024px) {
  [data-col~="pull-6-nav"] {
    left: initial;
    right: 50%; } }

@media (min-width: 1024px) {
  [data-col~="push-6-nav"] {
    right: initial;
    left: 50%; } }

@media (min-width: 1024px) {
  [data-col~="pull-7-nav"] {
    left: initial;
    right: 58.33333%; } }

@media (min-width: 1024px) {
  [data-col~="push-7-nav"] {
    right: initial;
    left: 58.33333%; } }

@media (min-width: 1024px) {
  [data-col~="pull-8-nav"] {
    left: initial;
    right: 66.66667%; } }

@media (min-width: 1024px) {
  [data-col~="push-8-nav"] {
    right: initial;
    left: 66.66667%; } }

@media (min-width: 1024px) {
  [data-col~="pull-9-nav"] {
    left: initial;
    right: 75%; } }

@media (min-width: 1024px) {
  [data-col~="push-9-nav"] {
    right: initial;
    left: 75%; } }

@media (min-width: 1024px) {
  [data-col~="pull-10-nav"] {
    left: initial;
    right: 83.33333%; } }

@media (min-width: 1024px) {
  [data-col~="push-10-nav"] {
    right: initial;
    left: 83.33333%; } }

@media (min-width: 1024px) {
  [data-col~="pull-11-nav"] {
    left: initial;
    right: 91.66667%; } }

@media (min-width: 1024px) {
  [data-col~="push-11-nav"] {
    right: initial;
    left: 91.66667%; } }

@media (min-width: 1024px) {
  [data-col~="pull-0-lg"] {
    left: initial;
    right: initial; } }

@media (min-width: 1024px) {
  [data-col~="push-0-lg"] {
    right: initial;
    left: initial; } }

@media (min-width: 1024px) {
  [data-col~="pull-1-lg"] {
    left: initial;
    right: 8.33333%; } }

@media (min-width: 1024px) {
  [data-col~="push-1-lg"] {
    right: initial;
    left: 8.33333%; } }

@media (min-width: 1024px) {
  [data-col~="pull-2-lg"] {
    left: initial;
    right: 16.66667%; } }

@media (min-width: 1024px) {
  [data-col~="push-2-lg"] {
    right: initial;
    left: 16.66667%; } }

@media (min-width: 1024px) {
  [data-col~="pull-3-lg"] {
    left: initial;
    right: 25%; } }

@media (min-width: 1024px) {
  [data-col~="push-3-lg"] {
    right: initial;
    left: 25%; } }

@media (min-width: 1024px) {
  [data-col~="pull-4-lg"] {
    left: initial;
    right: 33.33333%; } }

@media (min-width: 1024px) {
  [data-col~="push-4-lg"] {
    right: initial;
    left: 33.33333%; } }

@media (min-width: 1024px) {
  [data-col~="pull-5-lg"] {
    left: initial;
    right: 41.66667%; } }

@media (min-width: 1024px) {
  [data-col~="push-5-lg"] {
    right: initial;
    left: 41.66667%; } }

@media (min-width: 1024px) {
  [data-col~="pull-6-lg"] {
    left: initial;
    right: 50%; } }

@media (min-width: 1024px) {
  [data-col~="push-6-lg"] {
    right: initial;
    left: 50%; } }

@media (min-width: 1024px) {
  [data-col~="pull-7-lg"] {
    left: initial;
    right: 58.33333%; } }

@media (min-width: 1024px) {
  [data-col~="push-7-lg"] {
    right: initial;
    left: 58.33333%; } }

@media (min-width: 1024px) {
  [data-col~="pull-8-lg"] {
    left: initial;
    right: 66.66667%; } }

@media (min-width: 1024px) {
  [data-col~="push-8-lg"] {
    right: initial;
    left: 66.66667%; } }

@media (min-width: 1024px) {
  [data-col~="pull-9-lg"] {
    left: initial;
    right: 75%; } }

@media (min-width: 1024px) {
  [data-col~="push-9-lg"] {
    right: initial;
    left: 75%; } }

@media (min-width: 1024px) {
  [data-col~="pull-10-lg"] {
    left: initial;
    right: 83.33333%; } }

@media (min-width: 1024px) {
  [data-col~="push-10-lg"] {
    right: initial;
    left: 83.33333%; } }

@media (min-width: 1024px) {
  [data-col~="pull-11-lg"] {
    left: initial;
    right: 91.66667%; } }

@media (min-width: 1024px) {
  [data-col~="push-11-lg"] {
    right: initial;
    left: 91.66667%; } }

@media (min-width: 1280px) {
  [data-col~="pull-0-xl"] {
    left: initial;
    right: initial; } }

@media (min-width: 1280px) {
  [data-col~="push-0-xl"] {
    right: initial;
    left: initial; } }

@media (min-width: 1280px) {
  [data-col~="pull-1-xl"] {
    left: initial;
    right: 8.33333%; } }

@media (min-width: 1280px) {
  [data-col~="push-1-xl"] {
    right: initial;
    left: 8.33333%; } }

@media (min-width: 1280px) {
  [data-col~="pull-2-xl"] {
    left: initial;
    right: 16.66667%; } }

@media (min-width: 1280px) {
  [data-col~="push-2-xl"] {
    right: initial;
    left: 16.66667%; } }

@media (min-width: 1280px) {
  [data-col~="pull-3-xl"] {
    left: initial;
    right: 25%; } }

@media (min-width: 1280px) {
  [data-col~="push-3-xl"] {
    right: initial;
    left: 25%; } }

@media (min-width: 1280px) {
  [data-col~="pull-4-xl"] {
    left: initial;
    right: 33.33333%; } }

@media (min-width: 1280px) {
  [data-col~="push-4-xl"] {
    right: initial;
    left: 33.33333%; } }

@media (min-width: 1280px) {
  [data-col~="pull-5-xl"] {
    left: initial;
    right: 41.66667%; } }

@media (min-width: 1280px) {
  [data-col~="push-5-xl"] {
    right: initial;
    left: 41.66667%; } }

@media (min-width: 1280px) {
  [data-col~="pull-6-xl"] {
    left: initial;
    right: 50%; } }

@media (min-width: 1280px) {
  [data-col~="push-6-xl"] {
    right: initial;
    left: 50%; } }

@media (min-width: 1280px) {
  [data-col~="pull-7-xl"] {
    left: initial;
    right: 58.33333%; } }

@media (min-width: 1280px) {
  [data-col~="push-7-xl"] {
    right: initial;
    left: 58.33333%; } }

@media (min-width: 1280px) {
  [data-col~="pull-8-xl"] {
    left: initial;
    right: 66.66667%; } }

@media (min-width: 1280px) {
  [data-col~="push-8-xl"] {
    right: initial;
    left: 66.66667%; } }

@media (min-width: 1280px) {
  [data-col~="pull-9-xl"] {
    left: initial;
    right: 75%; } }

@media (min-width: 1280px) {
  [data-col~="push-9-xl"] {
    right: initial;
    left: 75%; } }

@media (min-width: 1280px) {
  [data-col~="pull-10-xl"] {
    left: initial;
    right: 83.33333%; } }

@media (min-width: 1280px) {
  [data-col~="push-10-xl"] {
    right: initial;
    left: 83.33333%; } }

@media (min-width: 1280px) {
  [data-col~="pull-11-xl"] {
    left: initial;
    right: 91.66667%; } }

@media (min-width: 1280px) {
  [data-col~="push-11-xl"] {
    right: initial;
    left: 91.66667%; } }

@media (min-width: 1920px) {
  [data-col~="pull-0-xxl"] {
    left: initial;
    right: initial; } }

@media (min-width: 1920px) {
  [data-col~="push-0-xxl"] {
    right: initial;
    left: initial; } }

@media (min-width: 1920px) {
  [data-col~="pull-1-xxl"] {
    left: initial;
    right: 8.33333%; } }

@media (min-width: 1920px) {
  [data-col~="push-1-xxl"] {
    right: initial;
    left: 8.33333%; } }

@media (min-width: 1920px) {
  [data-col~="pull-2-xxl"] {
    left: initial;
    right: 16.66667%; } }

@media (min-width: 1920px) {
  [data-col~="push-2-xxl"] {
    right: initial;
    left: 16.66667%; } }

@media (min-width: 1920px) {
  [data-col~="pull-3-xxl"] {
    left: initial;
    right: 25%; } }

@media (min-width: 1920px) {
  [data-col~="push-3-xxl"] {
    right: initial;
    left: 25%; } }

@media (min-width: 1920px) {
  [data-col~="pull-4-xxl"] {
    left: initial;
    right: 33.33333%; } }

@media (min-width: 1920px) {
  [data-col~="push-4-xxl"] {
    right: initial;
    left: 33.33333%; } }

@media (min-width: 1920px) {
  [data-col~="pull-5-xxl"] {
    left: initial;
    right: 41.66667%; } }

@media (min-width: 1920px) {
  [data-col~="push-5-xxl"] {
    right: initial;
    left: 41.66667%; } }

@media (min-width: 1920px) {
  [data-col~="pull-6-xxl"] {
    left: initial;
    right: 50%; } }

@media (min-width: 1920px) {
  [data-col~="push-6-xxl"] {
    right: initial;
    left: 50%; } }

@media (min-width: 1920px) {
  [data-col~="pull-7-xxl"] {
    left: initial;
    right: 58.33333%; } }

@media (min-width: 1920px) {
  [data-col~="push-7-xxl"] {
    right: initial;
    left: 58.33333%; } }

@media (min-width: 1920px) {
  [data-col~="pull-8-xxl"] {
    left: initial;
    right: 66.66667%; } }

@media (min-width: 1920px) {
  [data-col~="push-8-xxl"] {
    right: initial;
    left: 66.66667%; } }

@media (min-width: 1920px) {
  [data-col~="pull-9-xxl"] {
    left: initial;
    right: 75%; } }

@media (min-width: 1920px) {
  [data-col~="push-9-xxl"] {
    right: initial;
    left: 75%; } }

@media (min-width: 1920px) {
  [data-col~="pull-10-xxl"] {
    left: initial;
    right: 83.33333%; } }

@media (min-width: 1920px) {
  [data-col~="push-10-xxl"] {
    right: initial;
    left: 83.33333%; } }

@media (min-width: 1920px) {
  [data-col~="pull-11-xxl"] {
    left: initial;
    right: 91.66667%; } }

@media (min-width: 1920px) {
  [data-col~="push-11-xxl"] {
    right: initial;
    left: 91.66667%; } }

[data-container] {
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1200px;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%; }

[data-container~='is-left'] {
  margin: 0 auto 0 0; }

@media (min-width: 0px) {
  [data-container~='is-left-xs'] {
    margin: 0 auto 0 0; } }

@media (min-width: 480px) {
  [data-container~='is-left-sm'] {
    margin: 0 auto 0 0; } }

@media (min-width: 720px) {
  [data-container~='is-left-md'] {
    margin: 0 auto 0 0; } }

@media (min-width: 1024px) {
  [data-container~='is-left-nav'] {
    margin: 0 auto 0 0; } }

@media (min-width: 1024px) {
  [data-container~='is-left-lg'] {
    margin: 0 auto 0 0; } }

@media (min-width: 1280px) {
  [data-container~='is-left-xl'] {
    margin: 0 auto 0 0; } }

@media (min-width: 1920px) {
  [data-container~='is-left-xxl'] {
    margin: 0 auto 0 0; } }

[data-container~='is-right'] {
  margin: 0 0 0 auto; }

@media (min-width: 0px) {
  [data-container~='is-right-xs'] {
    margin: 0 0 0 auto; } }

@media (min-width: 480px) {
  [data-container~='is-right-sm'] {
    margin: 0 0 0 auto; } }

@media (min-width: 720px) {
  [data-container~='is-right-md'] {
    margin: 0 0 0 auto; } }

@media (min-width: 1024px) {
  [data-container~='is-right-nav'] {
    margin: 0 0 0 auto; } }

@media (min-width: 1024px) {
  [data-container~='is-right-lg'] {
    margin: 0 0 0 auto; } }

@media (min-width: 1280px) {
  [data-container~='is-right-xl'] {
    margin: 0 0 0 auto; } }

@media (min-width: 1920px) {
  [data-container~='is-right-xxl'] {
    margin: 0 0 0 auto; } }

[data-grid] {
  display: flex;
  flex-wrap: wrap;
  margin-left: -16px !important;
  margin-right: -16px !important; }
  ul[data-grid] {
    list-style: none;
    margin: 0;
    padding: 0; }
  [data-grid] > [data-col] {
    float: initial;
    padding-left: 16px !important;
    padding-right: 16px !important; }

[data-grid~='is-default'] {
  margin-left: -16px !important;
  margin-right: -16px !important; }
  [data-grid~='is-default'] > [data-col] {
    padding-left: 16px !important;
    padding-right: 16px !important; }

@media (min-width: 0px) {
  [data-grid~='is-default-xs'] {
    margin-left: -16px !important;
    margin-right: -16px !important; }
    [data-grid~='is-default-xs'] > [data-col] {
      padding-left: 16px !important;
      padding-right: 16px !important; } }

@media (min-width: 480px) {
  [data-grid~='is-default-sm'] {
    margin-left: -16px !important;
    margin-right: -16px !important; }
    [data-grid~='is-default-sm'] > [data-col] {
      padding-left: 16px !important;
      padding-right: 16px !important; } }

@media (min-width: 720px) {
  [data-grid~='is-default-md'] {
    margin-left: -16px !important;
    margin-right: -16px !important; }
    [data-grid~='is-default-md'] > [data-col] {
      padding-left: 16px !important;
      padding-right: 16px !important; } }

@media (min-width: 1024px) {
  [data-grid~='is-default-nav'] {
    margin-left: -16px !important;
    margin-right: -16px !important; }
    [data-grid~='is-default-nav'] > [data-col] {
      padding-left: 16px !important;
      padding-right: 16px !important; } }

@media (min-width: 1024px) {
  [data-grid~='is-default-lg'] {
    margin-left: -16px !important;
    margin-right: -16px !important; }
    [data-grid~='is-default-lg'] > [data-col] {
      padding-left: 16px !important;
      padding-right: 16px !important; } }

@media (min-width: 1280px) {
  [data-grid~='is-default-xl'] {
    margin-left: -16px !important;
    margin-right: -16px !important; }
    [data-grid~='is-default-xl'] > [data-col] {
      padding-left: 16px !important;
      padding-right: 16px !important; } }

@media (min-width: 1920px) {
  [data-grid~='is-default-xxl'] {
    margin-left: -16px !important;
    margin-right: -16px !important; }
    [data-grid~='is-default-xxl'] > [data-col] {
      padding-left: 16px !important;
      padding-right: 16px !important; } }

[data-grid~='is-small'] {
  margin-left: -8px !important;
  margin-right: -8px !important; }
  [data-grid~='is-small'] > [data-col] {
    padding-left: 8px !important;
    padding-right: 8px !important; }

@media (min-width: 0px) {
  [data-grid~='is-small-xs'] {
    margin-left: -8px !important;
    margin-right: -8px !important; }
    [data-grid~='is-small-xs'] > [data-col] {
      padding-left: 8px !important;
      padding-right: 8px !important; } }

@media (min-width: 480px) {
  [data-grid~='is-small-sm'] {
    margin-left: -8px !important;
    margin-right: -8px !important; }
    [data-grid~='is-small-sm'] > [data-col] {
      padding-left: 8px !important;
      padding-right: 8px !important; } }

@media (min-width: 720px) {
  [data-grid~='is-small-md'] {
    margin-left: -8px !important;
    margin-right: -8px !important; }
    [data-grid~='is-small-md'] > [data-col] {
      padding-left: 8px !important;
      padding-right: 8px !important; } }

@media (min-width: 1024px) {
  [data-grid~='is-small-nav'] {
    margin-left: -8px !important;
    margin-right: -8px !important; }
    [data-grid~='is-small-nav'] > [data-col] {
      padding-left: 8px !important;
      padding-right: 8px !important; } }

@media (min-width: 1024px) {
  [data-grid~='is-small-lg'] {
    margin-left: -8px !important;
    margin-right: -8px !important; }
    [data-grid~='is-small-lg'] > [data-col] {
      padding-left: 8px !important;
      padding-right: 8px !important; } }

@media (min-width: 1280px) {
  [data-grid~='is-small-xl'] {
    margin-left: -8px !important;
    margin-right: -8px !important; }
    [data-grid~='is-small-xl'] > [data-col] {
      padding-left: 8px !important;
      padding-right: 8px !important; } }

@media (min-width: 1920px) {
  [data-grid~='is-small-xxl'] {
    margin-left: -8px !important;
    margin-right: -8px !important; }
    [data-grid~='is-small-xxl'] > [data-col] {
      padding-left: 8px !important;
      padding-right: 8px !important; } }

[data-grid~='no-gutter'] {
  margin-left: 0 !important;
  margin-right: 0 !important; }
  [data-grid~='no-gutter'] > [data-col] {
    padding-left: 0 !important;
    padding-right: 0 !important; }

@media (min-width: 0px) {
  [data-grid~='no-gutter-xs'] {
    margin-left: 0 !important;
    margin-right: 0 !important; }
    [data-grid~='no-gutter-xs'] > [data-col] {
      padding-left: 0 !important;
      padding-right: 0 !important; } }

@media (min-width: 480px) {
  [data-grid~='no-gutter-sm'] {
    margin-left: 0 !important;
    margin-right: 0 !important; }
    [data-grid~='no-gutter-sm'] > [data-col] {
      padding-left: 0 !important;
      padding-right: 0 !important; } }

@media (min-width: 720px) {
  [data-grid~='no-gutter-md'] {
    margin-left: 0 !important;
    margin-right: 0 !important; }
    [data-grid~='no-gutter-md'] > [data-col] {
      padding-left: 0 !important;
      padding-right: 0 !important; } }

@media (min-width: 1024px) {
  [data-grid~='no-gutter-nav'] {
    margin-left: 0 !important;
    margin-right: 0 !important; }
    [data-grid~='no-gutter-nav'] > [data-col] {
      padding-left: 0 !important;
      padding-right: 0 !important; } }

@media (min-width: 1024px) {
  [data-grid~='no-gutter-lg'] {
    margin-left: 0 !important;
    margin-right: 0 !important; }
    [data-grid~='no-gutter-lg'] > [data-col] {
      padding-left: 0 !important;
      padding-right: 0 !important; } }

@media (min-width: 1280px) {
  [data-grid~='no-gutter-xl'] {
    margin-left: 0 !important;
    margin-right: 0 !important; }
    [data-grid~='no-gutter-xl'] > [data-col] {
      padding-left: 0 !important;
      padding-right: 0 !important; } }

@media (min-width: 1920px) {
  [data-grid~='no-gutter-xxl'] {
    margin-left: 0 !important;
    margin-right: 0 !important; }
    [data-grid~='no-gutter-xxl'] > [data-col] {
      padding-left: 0 !important;
      padding-right: 0 !important; } }

[data-grid~='is-center'] {
  justify-content: center; }

@media (min-width: 0px) {
  [data-grid~='is-center-xs'] {
    justify-content: center; } }

@media (min-width: 480px) {
  [data-grid~='is-center-sm'] {
    justify-content: center; } }

@media (min-width: 720px) {
  [data-grid~='is-center-md'] {
    justify-content: center; } }

@media (min-width: 1024px) {
  [data-grid~='is-center-nav'] {
    justify-content: center; } }

@media (min-width: 1024px) {
  [data-grid~='is-center-lg'] {
    justify-content: center; } }

@media (min-width: 1280px) {
  [data-grid~='is-center-xl'] {
    justify-content: center; } }

@media (min-width: 1920px) {
  [data-grid~='is-center-xxl'] {
    justify-content: center; } }

[data-grid~='is-start'] {
  justify-content: flex-start; }

@media (min-width: 0px) {
  [data-grid~='is-start-xs'] {
    justify-content: flex-start; } }

@media (min-width: 480px) {
  [data-grid~='is-start-sm'] {
    justify-content: flex-start; } }

@media (min-width: 720px) {
  [data-grid~='is-start-md'] {
    justify-content: flex-start; } }

@media (min-width: 1024px) {
  [data-grid~='is-start-nav'] {
    justify-content: flex-start; } }

@media (min-width: 1024px) {
  [data-grid~='is-start-lg'] {
    justify-content: flex-start; } }

@media (min-width: 1280px) {
  [data-grid~='is-start-xl'] {
    justify-content: flex-start; } }

@media (min-width: 1920px) {
  [data-grid~='is-start-xxl'] {
    justify-content: flex-start; } }

[data-grid~='is-end'] {
  justify-content: flex-end; }

@media (min-width: 0px) {
  [data-grid~='is-end-xs'] {
    justify-content: flex-end; } }

@media (min-width: 480px) {
  [data-grid~='is-end-sm'] {
    justify-content: flex-end; } }

@media (min-width: 720px) {
  [data-grid~='is-end-md'] {
    justify-content: flex-end; } }

@media (min-width: 1024px) {
  [data-grid~='is-end-nav'] {
    justify-content: flex-end; } }

@media (min-width: 1024px) {
  [data-grid~='is-end-lg'] {
    justify-content: flex-end; } }

@media (min-width: 1280px) {
  [data-grid~='is-end-xl'] {
    justify-content: flex-end; } }

@media (min-width: 1920px) {
  [data-grid~='is-end-xxl'] {
    justify-content: flex-end; } }

[data-col] {
  min-height: 1px;
  width: 100%; }

[data-col~='auto'] {
  width: auto;
  flex-grow: 1; }

[data-col~='1'] {
  max-width: 8.33333%; }

[data-col~='2'] {
  max-width: 16.66667%; }

[data-col~='3'] {
  max-width: 25%; }

[data-col~='4'] {
  max-width: 33.33333%; }

[data-col~='5'] {
  max-width: 41.66667%; }

[data-col~='6'] {
  max-width: 50%; }

[data-col~='7'] {
  max-width: 58.33333%; }

[data-col~='8'] {
  max-width: 66.66667%; }

[data-col~='9'] {
  max-width: 75%; }

[data-col~='10'] {
  max-width: 83.33333%; }

[data-col~='11'] {
  max-width: 91.66667%; }

[data-col~='12'] {
  max-width: 100%; }

@media (min-width: 0px) {
  [data-col~='1-xs'] {
    max-width: 8.33333%; } }

@media (min-width: 0px) {
  [data-col~='2-xs'] {
    max-width: 16.66667%; } }

@media (min-width: 0px) {
  [data-col~='3-xs'] {
    max-width: 25%; } }

@media (min-width: 0px) {
  [data-col~='4-xs'] {
    max-width: 33.33333%; } }

@media (min-width: 0px) {
  [data-col~='5-xs'] {
    max-width: 41.66667%; } }

@media (min-width: 0px) {
  [data-col~='6-xs'] {
    max-width: 50%; } }

@media (min-width: 0px) {
  [data-col~='7-xs'] {
    max-width: 58.33333%; } }

@media (min-width: 0px) {
  [data-col~='8-xs'] {
    max-width: 66.66667%; } }

@media (min-width: 0px) {
  [data-col~='9-xs'] {
    max-width: 75%; } }

@media (min-width: 0px) {
  [data-col~='10-xs'] {
    max-width: 83.33333%; } }

@media (min-width: 0px) {
  [data-col~='11-xs'] {
    max-width: 91.66667%; } }

@media (min-width: 0px) {
  [data-col~='12-xs'] {
    max-width: 100%; } }

@media (min-width: 480px) {
  [data-col~='1-sm'] {
    max-width: 8.33333%; } }

@media (min-width: 480px) {
  [data-col~='2-sm'] {
    max-width: 16.66667%; } }

@media (min-width: 480px) {
  [data-col~='3-sm'] {
    max-width: 25%; } }

@media (min-width: 480px) {
  [data-col~='4-sm'] {
    max-width: 33.33333%; } }

@media (min-width: 480px) {
  [data-col~='5-sm'] {
    max-width: 41.66667%; } }

@media (min-width: 480px) {
  [data-col~='6-sm'] {
    max-width: 50%; } }

@media (min-width: 480px) {
  [data-col~='7-sm'] {
    max-width: 58.33333%; } }

@media (min-width: 480px) {
  [data-col~='8-sm'] {
    max-width: 66.66667%; } }

@media (min-width: 480px) {
  [data-col~='9-sm'] {
    max-width: 75%; } }

@media (min-width: 480px) {
  [data-col~='10-sm'] {
    max-width: 83.33333%; } }

@media (min-width: 480px) {
  [data-col~='11-sm'] {
    max-width: 91.66667%; } }

@media (min-width: 480px) {
  [data-col~='12-sm'] {
    max-width: 100%; } }

@media (min-width: 720px) {
  [data-col~='1-md'] {
    max-width: 8.33333%; } }

@media (min-width: 720px) {
  [data-col~='2-md'] {
    max-width: 16.66667%; } }

@media (min-width: 720px) {
  [data-col~='3-md'] {
    max-width: 25%; } }

@media (min-width: 720px) {
  [data-col~='4-md'] {
    max-width: 33.33333%; } }

@media (min-width: 720px) {
  [data-col~='5-md'] {
    max-width: 41.66667%; } }

@media (min-width: 720px) {
  [data-col~='6-md'] {
    max-width: 50%; } }

@media (min-width: 720px) {
  [data-col~='7-md'] {
    max-width: 58.33333%; } }

@media (min-width: 720px) {
  [data-col~='8-md'] {
    max-width: 66.66667%; } }

@media (min-width: 720px) {
  [data-col~='9-md'] {
    max-width: 75%; } }

@media (min-width: 720px) {
  [data-col~='10-md'] {
    max-width: 83.33333%; } }

@media (min-width: 720px) {
  [data-col~='11-md'] {
    max-width: 91.66667%; } }

@media (min-width: 720px) {
  [data-col~='12-md'] {
    max-width: 100%; } }

@media (min-width: 1024px) {
  [data-col~='1-nav'] {
    max-width: 8.33333%; } }

@media (min-width: 1024px) {
  [data-col~='2-nav'] {
    max-width: 16.66667%; } }

@media (min-width: 1024px) {
  [data-col~='3-nav'] {
    max-width: 25%; } }

@media (min-width: 1024px) {
  [data-col~='4-nav'] {
    max-width: 33.33333%; } }

@media (min-width: 1024px) {
  [data-col~='5-nav'] {
    max-width: 41.66667%; } }

@media (min-width: 1024px) {
  [data-col~='6-nav'] {
    max-width: 50%; } }

@media (min-width: 1024px) {
  [data-col~='7-nav'] {
    max-width: 58.33333%; } }

@media (min-width: 1024px) {
  [data-col~='8-nav'] {
    max-width: 66.66667%; } }

@media (min-width: 1024px) {
  [data-col~='9-nav'] {
    max-width: 75%; } }

@media (min-width: 1024px) {
  [data-col~='10-nav'] {
    max-width: 83.33333%; } }

@media (min-width: 1024px) {
  [data-col~='11-nav'] {
    max-width: 91.66667%; } }

@media (min-width: 1024px) {
  [data-col~='12-nav'] {
    max-width: 100%; } }

@media (min-width: 1024px) {
  [data-col~='1-lg'] {
    max-width: 8.33333%; } }

@media (min-width: 1024px) {
  [data-col~='2-lg'] {
    max-width: 16.66667%; } }

@media (min-width: 1024px) {
  [data-col~='3-lg'] {
    max-width: 25%; } }

@media (min-width: 1024px) {
  [data-col~='4-lg'] {
    max-width: 33.33333%; } }

@media (min-width: 1024px) {
  [data-col~='5-lg'] {
    max-width: 41.66667%; } }

@media (min-width: 1024px) {
  [data-col~='6-lg'] {
    max-width: 50%; } }

@media (min-width: 1024px) {
  [data-col~='7-lg'] {
    max-width: 58.33333%; } }

@media (min-width: 1024px) {
  [data-col~='8-lg'] {
    max-width: 66.66667%; } }

@media (min-width: 1024px) {
  [data-col~='9-lg'] {
    max-width: 75%; } }

@media (min-width: 1024px) {
  [data-col~='10-lg'] {
    max-width: 83.33333%; } }

@media (min-width: 1024px) {
  [data-col~='11-lg'] {
    max-width: 91.66667%; } }

@media (min-width: 1024px) {
  [data-col~='12-lg'] {
    max-width: 100%; } }

@media (min-width: 1280px) {
  [data-col~='1-xl'] {
    max-width: 8.33333%; } }

@media (min-width: 1280px) {
  [data-col~='2-xl'] {
    max-width: 16.66667%; } }

@media (min-width: 1280px) {
  [data-col~='3-xl'] {
    max-width: 25%; } }

@media (min-width: 1280px) {
  [data-col~='4-xl'] {
    max-width: 33.33333%; } }

@media (min-width: 1280px) {
  [data-col~='5-xl'] {
    max-width: 41.66667%; } }

@media (min-width: 1280px) {
  [data-col~='6-xl'] {
    max-width: 50%; } }

@media (min-width: 1280px) {
  [data-col~='7-xl'] {
    max-width: 58.33333%; } }

@media (min-width: 1280px) {
  [data-col~='8-xl'] {
    max-width: 66.66667%; } }

@media (min-width: 1280px) {
  [data-col~='9-xl'] {
    max-width: 75%; } }

@media (min-width: 1280px) {
  [data-col~='10-xl'] {
    max-width: 83.33333%; } }

@media (min-width: 1280px) {
  [data-col~='11-xl'] {
    max-width: 91.66667%; } }

@media (min-width: 1280px) {
  [data-col~='12-xl'] {
    max-width: 100%; } }

@media (min-width: 1920px) {
  [data-col~='1-xxl'] {
    max-width: 8.33333%; } }

@media (min-width: 1920px) {
  [data-col~='2-xxl'] {
    max-width: 16.66667%; } }

@media (min-width: 1920px) {
  [data-col~='3-xxl'] {
    max-width: 25%; } }

@media (min-width: 1920px) {
  [data-col~='4-xxl'] {
    max-width: 33.33333%; } }

@media (min-width: 1920px) {
  [data-col~='5-xxl'] {
    max-width: 41.66667%; } }

@media (min-width: 1920px) {
  [data-col~='6-xxl'] {
    max-width: 50%; } }

@media (min-width: 1920px) {
  [data-col~='7-xxl'] {
    max-width: 58.33333%; } }

@media (min-width: 1920px) {
  [data-col~='8-xxl'] {
    max-width: 66.66667%; } }

@media (min-width: 1920px) {
  [data-col~='9-xxl'] {
    max-width: 75%; } }

@media (min-width: 1920px) {
  [data-col~='10-xxl'] {
    max-width: 83.33333%; } }

@media (min-width: 1920px) {
  [data-col~='11-xxl'] {
    max-width: 91.66667%; } }

@media (min-width: 1920px) {
  [data-col~='12-xxl'] {
    max-width: 100%; } }

@media (min-width: 0px) {
  [data-col~='auto-xs'] {
    width: auto;
    flex-grow: 1; } }

@media (min-width: 480px) {
  [data-col~='auto-sm'] {
    width: auto;
    flex-grow: 1; } }

@media (min-width: 720px) {
  [data-col~='auto-md'] {
    width: auto;
    flex-grow: 1; } }

@media (min-width: 1024px) {
  [data-col~='auto-nav'] {
    width: auto;
    flex-grow: 1; } }

@media (min-width: 1024px) {
  [data-col~='auto-lg'] {
    width: auto;
    flex-grow: 1; } }

@media (min-width: 1280px) {
  [data-col~='auto-xl'] {
    width: auto;
    flex-grow: 1; } }

@media (min-width: 1920px) {
  [data-col~='auto-xxl'] {
    width: auto;
    flex-grow: 1; } }

[data-col~='no-gutter'] {
  padding-left: 0 !important;
  padding-right: 0 !important; }

[data-col~='+0'] {
  margin-left: 0%; }

[data-col~='+1'] {
  margin-left: 8.33333%; }

[data-col~='+2'] {
  margin-left: 16.66667%; }

[data-col~='+3'] {
  margin-left: 25%; }

[data-col~='+4'] {
  margin-left: 33.33333%; }

[data-col~='+5'] {
  margin-left: 41.66667%; }

[data-col~='+6'] {
  margin-left: 50%; }

[data-col~='+7'] {
  margin-left: 58.33333%; }

[data-col~='+8'] {
  margin-left: 66.66667%; }

[data-col~='+9'] {
  margin-left: 75%; }

[data-col~='+10'] {
  margin-left: 83.33333%; }

[data-col~='+11'] {
  margin-left: 91.66667%; }

@media (min-width: 0px) {
  [data-col~='+0-xs'] {
    margin-left: 0%; } }

@media (min-width: 0px) {
  [data-col~='+1-xs'] {
    margin-left: 8.33333%; } }

@media (min-width: 0px) {
  [data-col~='+2-xs'] {
    margin-left: 16.66667%; } }

@media (min-width: 0px) {
  [data-col~='+3-xs'] {
    margin-left: 25%; } }

@media (min-width: 0px) {
  [data-col~='+4-xs'] {
    margin-left: 33.33333%; } }

@media (min-width: 0px) {
  [data-col~='+5-xs'] {
    margin-left: 41.66667%; } }

@media (min-width: 0px) {
  [data-col~='+6-xs'] {
    margin-left: 50%; } }

@media (min-width: 0px) {
  [data-col~='+7-xs'] {
    margin-left: 58.33333%; } }

@media (min-width: 0px) {
  [data-col~='+8-xs'] {
    margin-left: 66.66667%; } }

@media (min-width: 0px) {
  [data-col~='+9-xs'] {
    margin-left: 75%; } }

@media (min-width: 0px) {
  [data-col~='+10-xs'] {
    margin-left: 83.33333%; } }

@media (min-width: 0px) {
  [data-col~='+11-xs'] {
    margin-left: 91.66667%; } }

@media (min-width: 480px) {
  [data-col~='+0-sm'] {
    margin-left: 0%; } }

@media (min-width: 480px) {
  [data-col~='+1-sm'] {
    margin-left: 8.33333%; } }

@media (min-width: 480px) {
  [data-col~='+2-sm'] {
    margin-left: 16.66667%; } }

@media (min-width: 480px) {
  [data-col~='+3-sm'] {
    margin-left: 25%; } }

@media (min-width: 480px) {
  [data-col~='+4-sm'] {
    margin-left: 33.33333%; } }

@media (min-width: 480px) {
  [data-col~='+5-sm'] {
    margin-left: 41.66667%; } }

@media (min-width: 480px) {
  [data-col~='+6-sm'] {
    margin-left: 50%; } }

@media (min-width: 480px) {
  [data-col~='+7-sm'] {
    margin-left: 58.33333%; } }

@media (min-width: 480px) {
  [data-col~='+8-sm'] {
    margin-left: 66.66667%; } }

@media (min-width: 480px) {
  [data-col~='+9-sm'] {
    margin-left: 75%; } }

@media (min-width: 480px) {
  [data-col~='+10-sm'] {
    margin-left: 83.33333%; } }

@media (min-width: 480px) {
  [data-col~='+11-sm'] {
    margin-left: 91.66667%; } }

@media (min-width: 720px) {
  [data-col~='+0-md'] {
    margin-left: 0%; } }

@media (min-width: 720px) {
  [data-col~='+1-md'] {
    margin-left: 8.33333%; } }

@media (min-width: 720px) {
  [data-col~='+2-md'] {
    margin-left: 16.66667%; } }

@media (min-width: 720px) {
  [data-col~='+3-md'] {
    margin-left: 25%; } }

@media (min-width: 720px) {
  [data-col~='+4-md'] {
    margin-left: 33.33333%; } }

@media (min-width: 720px) {
  [data-col~='+5-md'] {
    margin-left: 41.66667%; } }

@media (min-width: 720px) {
  [data-col~='+6-md'] {
    margin-left: 50%; } }

@media (min-width: 720px) {
  [data-col~='+7-md'] {
    margin-left: 58.33333%; } }

@media (min-width: 720px) {
  [data-col~='+8-md'] {
    margin-left: 66.66667%; } }

@media (min-width: 720px) {
  [data-col~='+9-md'] {
    margin-left: 75%; } }

@media (min-width: 720px) {
  [data-col~='+10-md'] {
    margin-left: 83.33333%; } }

@media (min-width: 720px) {
  [data-col~='+11-md'] {
    margin-left: 91.66667%; } }

@media (min-width: 1024px) {
  [data-col~='+0-nav'] {
    margin-left: 0%; } }

@media (min-width: 1024px) {
  [data-col~='+1-nav'] {
    margin-left: 8.33333%; } }

@media (min-width: 1024px) {
  [data-col~='+2-nav'] {
    margin-left: 16.66667%; } }

@media (min-width: 1024px) {
  [data-col~='+3-nav'] {
    margin-left: 25%; } }

@media (min-width: 1024px) {
  [data-col~='+4-nav'] {
    margin-left: 33.33333%; } }

@media (min-width: 1024px) {
  [data-col~='+5-nav'] {
    margin-left: 41.66667%; } }

@media (min-width: 1024px) {
  [data-col~='+6-nav'] {
    margin-left: 50%; } }

@media (min-width: 1024px) {
  [data-col~='+7-nav'] {
    margin-left: 58.33333%; } }

@media (min-width: 1024px) {
  [data-col~='+8-nav'] {
    margin-left: 66.66667%; } }

@media (min-width: 1024px) {
  [data-col~='+9-nav'] {
    margin-left: 75%; } }

@media (min-width: 1024px) {
  [data-col~='+10-nav'] {
    margin-left: 83.33333%; } }

@media (min-width: 1024px) {
  [data-col~='+11-nav'] {
    margin-left: 91.66667%; } }

@media (min-width: 1024px) {
  [data-col~='+0-lg'] {
    margin-left: 0%; } }

@media (min-width: 1024px) {
  [data-col~='+1-lg'] {
    margin-left: 8.33333%; } }

@media (min-width: 1024px) {
  [data-col~='+2-lg'] {
    margin-left: 16.66667%; } }

@media (min-width: 1024px) {
  [data-col~='+3-lg'] {
    margin-left: 25%; } }

@media (min-width: 1024px) {
  [data-col~='+4-lg'] {
    margin-left: 33.33333%; } }

@media (min-width: 1024px) {
  [data-col~='+5-lg'] {
    margin-left: 41.66667%; } }

@media (min-width: 1024px) {
  [data-col~='+6-lg'] {
    margin-left: 50%; } }

@media (min-width: 1024px) {
  [data-col~='+7-lg'] {
    margin-left: 58.33333%; } }

@media (min-width: 1024px) {
  [data-col~='+8-lg'] {
    margin-left: 66.66667%; } }

@media (min-width: 1024px) {
  [data-col~='+9-lg'] {
    margin-left: 75%; } }

@media (min-width: 1024px) {
  [data-col~='+10-lg'] {
    margin-left: 83.33333%; } }

@media (min-width: 1024px) {
  [data-col~='+11-lg'] {
    margin-left: 91.66667%; } }

@media (min-width: 1280px) {
  [data-col~='+0-xl'] {
    margin-left: 0%; } }

@media (min-width: 1280px) {
  [data-col~='+1-xl'] {
    margin-left: 8.33333%; } }

@media (min-width: 1280px) {
  [data-col~='+2-xl'] {
    margin-left: 16.66667%; } }

@media (min-width: 1280px) {
  [data-col~='+3-xl'] {
    margin-left: 25%; } }

@media (min-width: 1280px) {
  [data-col~='+4-xl'] {
    margin-left: 33.33333%; } }

@media (min-width: 1280px) {
  [data-col~='+5-xl'] {
    margin-left: 41.66667%; } }

@media (min-width: 1280px) {
  [data-col~='+6-xl'] {
    margin-left: 50%; } }

@media (min-width: 1280px) {
  [data-col~='+7-xl'] {
    margin-left: 58.33333%; } }

@media (min-width: 1280px) {
  [data-col~='+8-xl'] {
    margin-left: 66.66667%; } }

@media (min-width: 1280px) {
  [data-col~='+9-xl'] {
    margin-left: 75%; } }

@media (min-width: 1280px) {
  [data-col~='+10-xl'] {
    margin-left: 83.33333%; } }

@media (min-width: 1280px) {
  [data-col~='+11-xl'] {
    margin-left: 91.66667%; } }

@media (min-width: 1920px) {
  [data-col~='+0-xxl'] {
    margin-left: 0%; } }

@media (min-width: 1920px) {
  [data-col~='+1-xxl'] {
    margin-left: 8.33333%; } }

@media (min-width: 1920px) {
  [data-col~='+2-xxl'] {
    margin-left: 16.66667%; } }

@media (min-width: 1920px) {
  [data-col~='+3-xxl'] {
    margin-left: 25%; } }

@media (min-width: 1920px) {
  [data-col~='+4-xxl'] {
    margin-left: 33.33333%; } }

@media (min-width: 1920px) {
  [data-col~='+5-xxl'] {
    margin-left: 41.66667%; } }

@media (min-width: 1920px) {
  [data-col~='+6-xxl'] {
    margin-left: 50%; } }

@media (min-width: 1920px) {
  [data-col~='+7-xxl'] {
    margin-left: 58.33333%; } }

@media (min-width: 1920px) {
  [data-col~='+8-xxl'] {
    margin-left: 66.66667%; } }

@media (min-width: 1920px) {
  [data-col~='+9-xxl'] {
    margin-left: 75%; } }

@media (min-width: 1920px) {
  [data-col~='+10-xxl'] {
    margin-left: 83.33333%; } }

@media (min-width: 1920px) {
  [data-col~='+11-xxl'] {
    margin-left: 91.66667%; } }

[data-flex] {
  display: flex; }
  ul[data-flex] {
    list-style: none;
    margin: 0;
    padding: 0; }

[data-flex~='is-column'] {
  flex-direction: column; }

[data-flex~='is-column-reverse'] {
  flex-direction: column-reverse; }

[data-flex~='is-row'] {
  flex-direction: row; }

[data-flex~='is-row-reverse'] {
  flex-direction: row-reverse; }

@media (min-width: 0px) {
  [data-flex~='is-column'] {
    flex-direction: column; } }

@media (min-width: 0px) {
  [data-flex~='is-column-reverse'] {
    flex-direction: column-reverse; } }

@media (min-width: 0px) {
  [data-flex~='is-row'] {
    flex-direction: row; } }

@media (min-width: 0px) {
  [data-flex~='is-row-reverse'] {
    flex-direction: row-reverse; } }

@media (min-width: 480px) {
  [data-flex~='is-column'] {
    flex-direction: column; } }

@media (min-width: 480px) {
  [data-flex~='is-column-reverse'] {
    flex-direction: column-reverse; } }

@media (min-width: 480px) {
  [data-flex~='is-row'] {
    flex-direction: row; } }

@media (min-width: 480px) {
  [data-flex~='is-row-reverse'] {
    flex-direction: row-reverse; } }

@media (min-width: 720px) {
  [data-flex~='is-column'] {
    flex-direction: column; } }

@media (min-width: 720px) {
  [data-flex~='is-column-reverse'] {
    flex-direction: column-reverse; } }

@media (min-width: 720px) {
  [data-flex~='is-row'] {
    flex-direction: row; } }

@media (min-width: 720px) {
  [data-flex~='is-row-reverse'] {
    flex-direction: row-reverse; } }

@media (min-width: 1024px) {
  [data-flex~='is-column'] {
    flex-direction: column; } }

@media (min-width: 1024px) {
  [data-flex~='is-column-reverse'] {
    flex-direction: column-reverse; } }

@media (min-width: 1024px) {
  [data-flex~='is-row'] {
    flex-direction: row; } }

@media (min-width: 1024px) {
  [data-flex~='is-row-reverse'] {
    flex-direction: row-reverse; } }

@media (min-width: 1024px) {
  [data-flex~='is-column'] {
    flex-direction: column; } }

@media (min-width: 1024px) {
  [data-flex~='is-column-reverse'] {
    flex-direction: column-reverse; } }

@media (min-width: 1024px) {
  [data-flex~='is-row'] {
    flex-direction: row; } }

@media (min-width: 1024px) {
  [data-flex~='is-row-reverse'] {
    flex-direction: row-reverse; } }

@media (min-width: 1280px) {
  [data-flex~='is-column'] {
    flex-direction: column; } }

@media (min-width: 1280px) {
  [data-flex~='is-column-reverse'] {
    flex-direction: column-reverse; } }

@media (min-width: 1280px) {
  [data-flex~='is-row'] {
    flex-direction: row; } }

@media (min-width: 1280px) {
  [data-flex~='is-row-reverse'] {
    flex-direction: row-reverse; } }

@media (min-width: 1920px) {
  [data-flex~='is-column'] {
    flex-direction: column; } }

@media (min-width: 1920px) {
  [data-flex~='is-column-reverse'] {
    flex-direction: column-reverse; } }

@media (min-width: 1920px) {
  [data-flex~='is-row'] {
    flex-direction: row; } }

@media (min-width: 1920px) {
  [data-flex~='is-row-reverse'] {
    flex-direction: row-reverse; } }

[data-flex~='is-around'] {
  justify-content: space-around; }

[data-flex~='is-between'] {
  justify-content: space-between; }

[data-flex~='is-center'] {
  justify-content: center; }

[data-flex~='is-start'] {
  justify-content: flex-start; }

[data-flex~='is-end'] {
  justify-content: flex-end; }

@media (min-width: 0px) {
  [data-flex~='is-around'] {
    justify-content: space-around; } }

@media (min-width: 0px) {
  [data-flex~='is-between'] {
    justify-content: space-between; } }

@media (min-width: 0px) {
  [data-flex~='is-center'] {
    justify-content: center; } }

@media (min-width: 0px) {
  [data-flex~='is-start'] {
    justify-content: flex-start; } }

@media (min-width: 0px) {
  [data-flex~='is-end'] {
    justify-content: flex-end; } }

@media (min-width: 480px) {
  [data-flex~='is-around'] {
    justify-content: space-around; } }

@media (min-width: 480px) {
  [data-flex~='is-between'] {
    justify-content: space-between; } }

@media (min-width: 480px) {
  [data-flex~='is-center'] {
    justify-content: center; } }

@media (min-width: 480px) {
  [data-flex~='is-start'] {
    justify-content: flex-start; } }

@media (min-width: 480px) {
  [data-flex~='is-end'] {
    justify-content: flex-end; } }

@media (min-width: 720px) {
  [data-flex~='is-around'] {
    justify-content: space-around; } }

@media (min-width: 720px) {
  [data-flex~='is-between'] {
    justify-content: space-between; } }

@media (min-width: 720px) {
  [data-flex~='is-center'] {
    justify-content: center; } }

@media (min-width: 720px) {
  [data-flex~='is-start'] {
    justify-content: flex-start; } }

@media (min-width: 720px) {
  [data-flex~='is-end'] {
    justify-content: flex-end; } }

@media (min-width: 1024px) {
  [data-flex~='is-around'] {
    justify-content: space-around; } }

@media (min-width: 1024px) {
  [data-flex~='is-between'] {
    justify-content: space-between; } }

@media (min-width: 1024px) {
  [data-flex~='is-center'] {
    justify-content: center; } }

@media (min-width: 1024px) {
  [data-flex~='is-start'] {
    justify-content: flex-start; } }

@media (min-width: 1024px) {
  [data-flex~='is-end'] {
    justify-content: flex-end; } }

@media (min-width: 1024px) {
  [data-flex~='is-around'] {
    justify-content: space-around; } }

@media (min-width: 1024px) {
  [data-flex~='is-between'] {
    justify-content: space-between; } }

@media (min-width: 1024px) {
  [data-flex~='is-center'] {
    justify-content: center; } }

@media (min-width: 1024px) {
  [data-flex~='is-start'] {
    justify-content: flex-start; } }

@media (min-width: 1024px) {
  [data-flex~='is-end'] {
    justify-content: flex-end; } }

@media (min-width: 1280px) {
  [data-flex~='is-around'] {
    justify-content: space-around; } }

@media (min-width: 1280px) {
  [data-flex~='is-between'] {
    justify-content: space-between; } }

@media (min-width: 1280px) {
  [data-flex~='is-center'] {
    justify-content: center; } }

@media (min-width: 1280px) {
  [data-flex~='is-start'] {
    justify-content: flex-start; } }

@media (min-width: 1280px) {
  [data-flex~='is-end'] {
    justify-content: flex-end; } }

@media (min-width: 1920px) {
  [data-flex~='is-around'] {
    justify-content: space-around; } }

@media (min-width: 1920px) {
  [data-flex~='is-between'] {
    justify-content: space-between; } }

@media (min-width: 1920px) {
  [data-flex~='is-center'] {
    justify-content: center; } }

@media (min-width: 1920px) {
  [data-flex~='is-start'] {
    justify-content: flex-start; } }

@media (min-width: 1920px) {
  [data-flex~='is-end'] {
    justify-content: flex-end; } }

[data-flex~='is-end-items'] {
  align-items: flex-end; }

[data-flex~='is-start-items'] {
  align-items: flex-start; }

[data-flex~='is-center-items'] {
  align-items: center; }

[data-flex~='is-baseline-items'] {
  align-items: baseline; }

[data-flex~='is-stretch-items'] {
  align-items: stretch; }

@media (min-width: 0px) {
  [data-flex~='is-end-items'] {
    align-items: flex-end; } }

@media (min-width: 0px) {
  [data-flex~='is-start-items'] {
    align-items: flex-start; } }

@media (min-width: 0px) {
  [data-flex~='is-center-items'] {
    align-items: center; } }

@media (min-width: 0px) {
  [data-flex~='is-baseline-items'] {
    align-items: baseline; } }

@media (min-width: 0px) {
  [data-flex~='is-stretch-items'] {
    align-items: stretch; } }

@media (min-width: 480px) {
  [data-flex~='is-end-items'] {
    align-items: flex-end; } }

@media (min-width: 480px) {
  [data-flex~='is-start-items'] {
    align-items: flex-start; } }

@media (min-width: 480px) {
  [data-flex~='is-center-items'] {
    align-items: center; } }

@media (min-width: 480px) {
  [data-flex~='is-baseline-items'] {
    align-items: baseline; } }

@media (min-width: 480px) {
  [data-flex~='is-stretch-items'] {
    align-items: stretch; } }

@media (min-width: 720px) {
  [data-flex~='is-end-items'] {
    align-items: flex-end; } }

@media (min-width: 720px) {
  [data-flex~='is-start-items'] {
    align-items: flex-start; } }

@media (min-width: 720px) {
  [data-flex~='is-center-items'] {
    align-items: center; } }

@media (min-width: 720px) {
  [data-flex~='is-baseline-items'] {
    align-items: baseline; } }

@media (min-width: 720px) {
  [data-flex~='is-stretch-items'] {
    align-items: stretch; } }

@media (min-width: 1024px) {
  [data-flex~='is-end-items'] {
    align-items: flex-end; } }

@media (min-width: 1024px) {
  [data-flex~='is-start-items'] {
    align-items: flex-start; } }

@media (min-width: 1024px) {
  [data-flex~='is-center-items'] {
    align-items: center; } }

@media (min-width: 1024px) {
  [data-flex~='is-baseline-items'] {
    align-items: baseline; } }

@media (min-width: 1024px) {
  [data-flex~='is-stretch-items'] {
    align-items: stretch; } }

@media (min-width: 1024px) {
  [data-flex~='is-end-items'] {
    align-items: flex-end; } }

@media (min-width: 1024px) {
  [data-flex~='is-start-items'] {
    align-items: flex-start; } }

@media (min-width: 1024px) {
  [data-flex~='is-center-items'] {
    align-items: center; } }

@media (min-width: 1024px) {
  [data-flex~='is-baseline-items'] {
    align-items: baseline; } }

@media (min-width: 1024px) {
  [data-flex~='is-stretch-items'] {
    align-items: stretch; } }

@media (min-width: 1280px) {
  [data-flex~='is-end-items'] {
    align-items: flex-end; } }

@media (min-width: 1280px) {
  [data-flex~='is-start-items'] {
    align-items: flex-start; } }

@media (min-width: 1280px) {
  [data-flex~='is-center-items'] {
    align-items: center; } }

@media (min-width: 1280px) {
  [data-flex~='is-baseline-items'] {
    align-items: baseline; } }

@media (min-width: 1280px) {
  [data-flex~='is-stretch-items'] {
    align-items: stretch; } }

@media (min-width: 1920px) {
  [data-flex~='is-end-items'] {
    align-items: flex-end; } }

@media (min-width: 1920px) {
  [data-flex~='is-start-items'] {
    align-items: flex-start; } }

@media (min-width: 1920px) {
  [data-flex~='is-center-items'] {
    align-items: center; } }

@media (min-width: 1920px) {
  [data-flex~='is-baseline-items'] {
    align-items: baseline; } }

@media (min-width: 1920px) {
  [data-flex~='is-stretch-items'] {
    align-items: stretch; } }

[data-flex~='is-end-content'] {
  align-content: flex-end; }

[data-flex~='is-start-content'] {
  align-content: flex-start; }

[data-flex~='is-center-content'] {
  align-content: center; }

[data-flex~='is-stretch-content'] {
  align-content: stretch; }

[data-flex~='is-between-content'] {
  align-content: space-between; }

[data-flex~='is-around-content'] {
  align-content: space-around; }

@media (min-width: 0px) {
  [data-flex~='is-end-content'] {
    align-content: flex-end; } }

@media (min-width: 0px) {
  [data-flex~='is-start-content'] {
    align-content: flex-start; } }

@media (min-width: 0px) {
  [data-flex~='is-center-content'] {
    align-content: center; } }

@media (min-width: 0px) {
  [data-flex~='is-stretch-content'] {
    align-content: stretch; } }

@media (min-width: 0px) {
  [data-flex~='is-between-content'] {
    align-content: space-between; } }

@media (min-width: 0px) {
  [data-flex~='is-around-content'] {
    align-content: space-around; } }

@media (min-width: 480px) {
  [data-flex~='is-end-content'] {
    align-content: flex-end; } }

@media (min-width: 480px) {
  [data-flex~='is-start-content'] {
    align-content: flex-start; } }

@media (min-width: 480px) {
  [data-flex~='is-center-content'] {
    align-content: center; } }

@media (min-width: 480px) {
  [data-flex~='is-stretch-content'] {
    align-content: stretch; } }

@media (min-width: 480px) {
  [data-flex~='is-between-content'] {
    align-content: space-between; } }

@media (min-width: 480px) {
  [data-flex~='is-around-content'] {
    align-content: space-around; } }

@media (min-width: 720px) {
  [data-flex~='is-end-content'] {
    align-content: flex-end; } }

@media (min-width: 720px) {
  [data-flex~='is-start-content'] {
    align-content: flex-start; } }

@media (min-width: 720px) {
  [data-flex~='is-center-content'] {
    align-content: center; } }

@media (min-width: 720px) {
  [data-flex~='is-stretch-content'] {
    align-content: stretch; } }

@media (min-width: 720px) {
  [data-flex~='is-between-content'] {
    align-content: space-between; } }

@media (min-width: 720px) {
  [data-flex~='is-around-content'] {
    align-content: space-around; } }

@media (min-width: 1024px) {
  [data-flex~='is-end-content'] {
    align-content: flex-end; } }

@media (min-width: 1024px) {
  [data-flex~='is-start-content'] {
    align-content: flex-start; } }

@media (min-width: 1024px) {
  [data-flex~='is-center-content'] {
    align-content: center; } }

@media (min-width: 1024px) {
  [data-flex~='is-stretch-content'] {
    align-content: stretch; } }

@media (min-width: 1024px) {
  [data-flex~='is-between-content'] {
    align-content: space-between; } }

@media (min-width: 1024px) {
  [data-flex~='is-around-content'] {
    align-content: space-around; } }

@media (min-width: 1024px) {
  [data-flex~='is-end-content'] {
    align-content: flex-end; } }

@media (min-width: 1024px) {
  [data-flex~='is-start-content'] {
    align-content: flex-start; } }

@media (min-width: 1024px) {
  [data-flex~='is-center-content'] {
    align-content: center; } }

@media (min-width: 1024px) {
  [data-flex~='is-stretch-content'] {
    align-content: stretch; } }

@media (min-width: 1024px) {
  [data-flex~='is-between-content'] {
    align-content: space-between; } }

@media (min-width: 1024px) {
  [data-flex~='is-around-content'] {
    align-content: space-around; } }

@media (min-width: 1280px) {
  [data-flex~='is-end-content'] {
    align-content: flex-end; } }

@media (min-width: 1280px) {
  [data-flex~='is-start-content'] {
    align-content: flex-start; } }

@media (min-width: 1280px) {
  [data-flex~='is-center-content'] {
    align-content: center; } }

@media (min-width: 1280px) {
  [data-flex~='is-stretch-content'] {
    align-content: stretch; } }

@media (min-width: 1280px) {
  [data-flex~='is-between-content'] {
    align-content: space-between; } }

@media (min-width: 1280px) {
  [data-flex~='is-around-content'] {
    align-content: space-around; } }

@media (min-width: 1920px) {
  [data-flex~='is-end-content'] {
    align-content: flex-end; } }

@media (min-width: 1920px) {
  [data-flex~='is-start-content'] {
    align-content: flex-start; } }

@media (min-width: 1920px) {
  [data-flex~='is-center-content'] {
    align-content: center; } }

@media (min-width: 1920px) {
  [data-flex~='is-stretch-content'] {
    align-content: stretch; } }

@media (min-width: 1920px) {
  [data-flex~='is-between-content'] {
    align-content: space-between; } }

@media (min-width: 1920px) {
  [data-flex~='is-around-content'] {
    align-content: space-around; } }

[data-flex~='is-wrap'] {
  flex-wrap: wrap; }

[data-flex~='is-nowrap'] {
  flex-wrap: nowrap; }

[data-flex~='is-wrap-reverse'] {
  flex-wrap: wrap-reverse; }

@media (min-width: 0px) {
  [data-flex~='is-wrap'] {
    flex-wrap: wrap; } }

@media (min-width: 0px) {
  [data-flex~='is-nowrap'] {
    flex-wrap: nowrap; } }

@media (min-width: 0px) {
  [data-flex~='is-wrap-reverse'] {
    flex-wrap: wrap-reverse; } }

@media (min-width: 480px) {
  [data-flex~='is-wrap'] {
    flex-wrap: wrap; } }

@media (min-width: 480px) {
  [data-flex~='is-nowrap'] {
    flex-wrap: nowrap; } }

@media (min-width: 480px) {
  [data-flex~='is-wrap-reverse'] {
    flex-wrap: wrap-reverse; } }

@media (min-width: 720px) {
  [data-flex~='is-wrap'] {
    flex-wrap: wrap; } }

@media (min-width: 720px) {
  [data-flex~='is-nowrap'] {
    flex-wrap: nowrap; } }

@media (min-width: 720px) {
  [data-flex~='is-wrap-reverse'] {
    flex-wrap: wrap-reverse; } }

@media (min-width: 1024px) {
  [data-flex~='is-wrap'] {
    flex-wrap: wrap; } }

@media (min-width: 1024px) {
  [data-flex~='is-nowrap'] {
    flex-wrap: nowrap; } }

@media (min-width: 1024px) {
  [data-flex~='is-wrap-reverse'] {
    flex-wrap: wrap-reverse; } }

@media (min-width: 1024px) {
  [data-flex~='is-wrap'] {
    flex-wrap: wrap; } }

@media (min-width: 1024px) {
  [data-flex~='is-nowrap'] {
    flex-wrap: nowrap; } }

@media (min-width: 1024px) {
  [data-flex~='is-wrap-reverse'] {
    flex-wrap: wrap-reverse; } }

@media (min-width: 1280px) {
  [data-flex~='is-wrap'] {
    flex-wrap: wrap; } }

@media (min-width: 1280px) {
  [data-flex~='is-nowrap'] {
    flex-wrap: nowrap; } }

@media (min-width: 1280px) {
  [data-flex~='is-wrap-reverse'] {
    flex-wrap: wrap-reverse; } }

@media (min-width: 1920px) {
  [data-flex~='is-wrap'] {
    flex-wrap: wrap; } }

@media (min-width: 1920px) {
  [data-flex~='is-nowrap'] {
    flex-wrap: nowrap; } }

@media (min-width: 1920px) {
  [data-flex~='is-wrap-reverse'] {
    flex-wrap: wrap-reverse; } }

[data-flex-order~='is-first'] {
  order: 1; }

[data-flex-order~='is-last'] {
  order: -1; }

@media (min-width: 0px) {
  [data-flex-order~='is-first'] {
    order: 1; } }

@media (min-width: 0px) {
  [data-flex-order~='is-last'] {
    order: -1; } }

@media (min-width: 480px) {
  [data-flex-order~='is-first'] {
    order: 1; } }

@media (min-width: 480px) {
  [data-flex-order~='is-last'] {
    order: -1; } }

@media (min-width: 720px) {
  [data-flex-order~='is-first'] {
    order: 1; } }

@media (min-width: 720px) {
  [data-flex-order~='is-last'] {
    order: -1; } }

@media (min-width: 1024px) {
  [data-flex-order~='is-first'] {
    order: 1; } }

@media (min-width: 1024px) {
  [data-flex-order~='is-last'] {
    order: -1; } }

@media (min-width: 1024px) {
  [data-flex-order~='is-first'] {
    order: 1; } }

@media (min-width: 1024px) {
  [data-flex-order~='is-last'] {
    order: -1; } }

@media (min-width: 1280px) {
  [data-flex-order~='is-first'] {
    order: 1; } }

@media (min-width: 1280px) {
  [data-flex-order~='is-last'] {
    order: -1; } }

@media (min-width: 1920px) {
  [data-flex-order~='is-first'] {
    order: 1; } }

@media (min-width: 1920px) {
  [data-flex-order~='is-last'] {
    order: -1; } }

[data-flex-order~='1'] {
  order: 1; }

@media (min-width: 0px) {
  [data-flex-order~='1'] {
    order: 1; } }

@media (min-width: 480px) {
  [data-flex-order~='1'] {
    order: 1; } }

@media (min-width: 720px) {
  [data-flex-order~='1'] {
    order: 1; } }

@media (min-width: 1024px) {
  [data-flex-order~='1'] {
    order: 1; } }

@media (min-width: 1024px) {
  [data-flex-order~='1'] {
    order: 1; } }

@media (min-width: 1280px) {
  [data-flex-order~='1'] {
    order: 1; } }

@media (min-width: 1920px) {
  [data-flex-order~='1'] {
    order: 1; } }

[data-flex-order~='2'] {
  order: 2; }

@media (min-width: 0px) {
  [data-flex-order~='2'] {
    order: 2; } }

@media (min-width: 480px) {
  [data-flex-order~='2'] {
    order: 2; } }

@media (min-width: 720px) {
  [data-flex-order~='2'] {
    order: 2; } }

@media (min-width: 1024px) {
  [data-flex-order~='2'] {
    order: 2; } }

@media (min-width: 1024px) {
  [data-flex-order~='2'] {
    order: 2; } }

@media (min-width: 1280px) {
  [data-flex-order~='2'] {
    order: 2; } }

@media (min-width: 1920px) {
  [data-flex-order~='2'] {
    order: 2; } }

[data-flex-order~='3'] {
  order: 3; }

@media (min-width: 0px) {
  [data-flex-order~='3'] {
    order: 3; } }

@media (min-width: 480px) {
  [data-flex-order~='3'] {
    order: 3; } }

@media (min-width: 720px) {
  [data-flex-order~='3'] {
    order: 3; } }

@media (min-width: 1024px) {
  [data-flex-order~='3'] {
    order: 3; } }

@media (min-width: 1024px) {
  [data-flex-order~='3'] {
    order: 3; } }

@media (min-width: 1280px) {
  [data-flex-order~='3'] {
    order: 3; } }

@media (min-width: 1920px) {
  [data-flex-order~='3'] {
    order: 3; } }

[data-flex-order~='4'] {
  order: 4; }

@media (min-width: 0px) {
  [data-flex-order~='4'] {
    order: 4; } }

@media (min-width: 480px) {
  [data-flex-order~='4'] {
    order: 4; } }

@media (min-width: 720px) {
  [data-flex-order~='4'] {
    order: 4; } }

@media (min-width: 1024px) {
  [data-flex-order~='4'] {
    order: 4; } }

@media (min-width: 1024px) {
  [data-flex-order~='4'] {
    order: 4; } }

@media (min-width: 1280px) {
  [data-flex-order~='4'] {
    order: 4; } }

@media (min-width: 1920px) {
  [data-flex-order~='4'] {
    order: 4; } }

[data-flex-order~='5'] {
  order: 5; }

@media (min-width: 0px) {
  [data-flex-order~='5'] {
    order: 5; } }

@media (min-width: 480px) {
  [data-flex-order~='5'] {
    order: 5; } }

@media (min-width: 720px) {
  [data-flex-order~='5'] {
    order: 5; } }

@media (min-width: 1024px) {
  [data-flex-order~='5'] {
    order: 5; } }

@media (min-width: 1024px) {
  [data-flex-order~='5'] {
    order: 5; } }

@media (min-width: 1280px) {
  [data-flex-order~='5'] {
    order: 5; } }

@media (min-width: 1920px) {
  [data-flex-order~='5'] {
    order: 5; } }

[data-flex-order~='6'] {
  order: 6; }

@media (min-width: 0px) {
  [data-flex-order~='6'] {
    order: 6; } }

@media (min-width: 480px) {
  [data-flex-order~='6'] {
    order: 6; } }

@media (min-width: 720px) {
  [data-flex-order~='6'] {
    order: 6; } }

@media (min-width: 1024px) {
  [data-flex-order~='6'] {
    order: 6; } }

@media (min-width: 1024px) {
  [data-flex-order~='6'] {
    order: 6; } }

@media (min-width: 1280px) {
  [data-flex-order~='6'] {
    order: 6; } }

@media (min-width: 1920px) {
  [data-flex-order~='6'] {
    order: 6; } }

[data-flex-order~='7'] {
  order: 7; }

@media (min-width: 0px) {
  [data-flex-order~='7'] {
    order: 7; } }

@media (min-width: 480px) {
  [data-flex-order~='7'] {
    order: 7; } }

@media (min-width: 720px) {
  [data-flex-order~='7'] {
    order: 7; } }

@media (min-width: 1024px) {
  [data-flex-order~='7'] {
    order: 7; } }

@media (min-width: 1024px) {
  [data-flex-order~='7'] {
    order: 7; } }

@media (min-width: 1280px) {
  [data-flex-order~='7'] {
    order: 7; } }

@media (min-width: 1920px) {
  [data-flex-order~='7'] {
    order: 7; } }

[data-flex-order~='8'] {
  order: 8; }

@media (min-width: 0px) {
  [data-flex-order~='8'] {
    order: 8; } }

@media (min-width: 480px) {
  [data-flex-order~='8'] {
    order: 8; } }

@media (min-width: 720px) {
  [data-flex-order~='8'] {
    order: 8; } }

@media (min-width: 1024px) {
  [data-flex-order~='8'] {
    order: 8; } }

@media (min-width: 1024px) {
  [data-flex-order~='8'] {
    order: 8; } }

@media (min-width: 1280px) {
  [data-flex-order~='8'] {
    order: 8; } }

@media (min-width: 1920px) {
  [data-flex-order~='8'] {
    order: 8; } }

[data-flex-order~='9'] {
  order: 9; }

@media (min-width: 0px) {
  [data-flex-order~='9'] {
    order: 9; } }

@media (min-width: 480px) {
  [data-flex-order~='9'] {
    order: 9; } }

@media (min-width: 720px) {
  [data-flex-order~='9'] {
    order: 9; } }

@media (min-width: 1024px) {
  [data-flex-order~='9'] {
    order: 9; } }

@media (min-width: 1024px) {
  [data-flex-order~='9'] {
    order: 9; } }

@media (min-width: 1280px) {
  [data-flex-order~='9'] {
    order: 9; } }

@media (min-width: 1920px) {
  [data-flex-order~='9'] {
    order: 9; } }

[data-flex-order~='10'] {
  order: 10; }

@media (min-width: 0px) {
  [data-flex-order~='10'] {
    order: 10; } }

@media (min-width: 480px) {
  [data-flex-order~='10'] {
    order: 10; } }

@media (min-width: 720px) {
  [data-flex-order~='10'] {
    order: 10; } }

@media (min-width: 1024px) {
  [data-flex-order~='10'] {
    order: 10; } }

@media (min-width: 1024px) {
  [data-flex-order~='10'] {
    order: 10; } }

@media (min-width: 1280px) {
  [data-flex-order~='10'] {
    order: 10; } }

@media (min-width: 1920px) {
  [data-flex-order~='10'] {
    order: 10; } }

[data-flex-order~='11'] {
  order: 11; }

@media (min-width: 0px) {
  [data-flex-order~='11'] {
    order: 11; } }

@media (min-width: 480px) {
  [data-flex-order~='11'] {
    order: 11; } }

@media (min-width: 720px) {
  [data-flex-order~='11'] {
    order: 11; } }

@media (min-width: 1024px) {
  [data-flex-order~='11'] {
    order: 11; } }

@media (min-width: 1024px) {
  [data-flex-order~='11'] {
    order: 11; } }

@media (min-width: 1280px) {
  [data-flex-order~='11'] {
    order: 11; } }

@media (min-width: 1920px) {
  [data-flex-order~='11'] {
    order: 11; } }

[data-flex-order~='12'] {
  order: 12; }

@media (min-width: 0px) {
  [data-flex-order~='12'] {
    order: 12; } }

@media (min-width: 480px) {
  [data-flex-order~='12'] {
    order: 12; } }

@media (min-width: 720px) {
  [data-flex-order~='12'] {
    order: 12; } }

@media (min-width: 1024px) {
  [data-flex-order~='12'] {
    order: 12; } }

@media (min-width: 1024px) {
  [data-flex-order~='12'] {
    order: 12; } }

@media (min-width: 1280px) {
  [data-flex-order~='12'] {
    order: 12; } }

@media (min-width: 1920px) {
  [data-flex-order~='12'] {
    order: 12; } }

[data-flex-item~='grow'] {
  flex: 1 1 auto; }

[data-flex-item~='grow-even'] {
  flex: 1; }

[data-flex-item~='fit-self'] {
  flex: 0 0 auto; }

@media (min-width: 0px) {
  [data-flex-item~='grow'] {
    flex: 1 1 auto; } }

@media (min-width: 0px) {
  [data-flex-item~='grow-even'] {
    flex: 1; } }

@media (min-width: 0px) {
  [data-flex-item~='fit-self'] {
    flex: 0 0 auto; } }

@media (min-width: 480px) {
  [data-flex-item~='grow'] {
    flex: 1 1 auto; } }

@media (min-width: 480px) {
  [data-flex-item~='grow-even'] {
    flex: 1; } }

@media (min-width: 480px) {
  [data-flex-item~='fit-self'] {
    flex: 0 0 auto; } }

@media (min-width: 720px) {
  [data-flex-item~='grow'] {
    flex: 1 1 auto; } }

@media (min-width: 720px) {
  [data-flex-item~='grow-even'] {
    flex: 1; } }

@media (min-width: 720px) {
  [data-flex-item~='fit-self'] {
    flex: 0 0 auto; } }

@media (min-width: 1024px) {
  [data-flex-item~='grow'] {
    flex: 1 1 auto; } }

@media (min-width: 1024px) {
  [data-flex-item~='grow-even'] {
    flex: 1; } }

@media (min-width: 1024px) {
  [data-flex-item~='fit-self'] {
    flex: 0 0 auto; } }

@media (min-width: 1024px) {
  [data-flex-item~='grow'] {
    flex: 1 1 auto; } }

@media (min-width: 1024px) {
  [data-flex-item~='grow-even'] {
    flex: 1; } }

@media (min-width: 1024px) {
  [data-flex-item~='fit-self'] {
    flex: 0 0 auto; } }

@media (min-width: 1280px) {
  [data-flex-item~='grow'] {
    flex: 1 1 auto; } }

@media (min-width: 1280px) {
  [data-flex-item~='grow-even'] {
    flex: 1; } }

@media (min-width: 1280px) {
  [data-flex-item~='fit-self'] {
    flex: 0 0 auto; } }

@media (min-width: 1920px) {
  [data-flex-item~='grow'] {
    flex: 1 1 auto; } }

@media (min-width: 1920px) {
  [data-flex-item~='grow-even'] {
    flex: 1; } }

@media (min-width: 1920px) {
  [data-flex-item~='fit-self'] {
    flex: 0 0 auto; } }

[data-flex-item~='is-auto'] {
  align-self: auto; }

[data-flex-item~='is-baseline'] {
  align-self: baseline; }

[data-flex-item~='is-stretch'] {
  align-self: stretch; }

[data-flex-item~='is-center'] {
  align-self: center; }

[data-flex-item~='is-start'] {
  align-self: flex-start; }

[data-flex-item~='is-end'] {
  align-self: flex-end; }

@media (min-width: 0px) {
  [data-flex-item~='is-auto'] {
    align-self: auto; } }

@media (min-width: 0px) {
  [data-flex-item~='is-baseline'] {
    align-self: baseline; } }

@media (min-width: 0px) {
  [data-flex-item~='is-stretch'] {
    align-self: stretch; } }

@media (min-width: 0px) {
  [data-flex-item~='is-center'] {
    align-self: center; } }

@media (min-width: 0px) {
  [data-flex-item~='is-start'] {
    align-self: flex-start; } }

@media (min-width: 0px) {
  [data-flex-item~='is-end'] {
    align-self: flex-end; } }

@media (min-width: 480px) {
  [data-flex-item~='is-auto'] {
    align-self: auto; } }

@media (min-width: 480px) {
  [data-flex-item~='is-baseline'] {
    align-self: baseline; } }

@media (min-width: 480px) {
  [data-flex-item~='is-stretch'] {
    align-self: stretch; } }

@media (min-width: 480px) {
  [data-flex-item~='is-center'] {
    align-self: center; } }

@media (min-width: 480px) {
  [data-flex-item~='is-start'] {
    align-self: flex-start; } }

@media (min-width: 480px) {
  [data-flex-item~='is-end'] {
    align-self: flex-end; } }

@media (min-width: 720px) {
  [data-flex-item~='is-auto'] {
    align-self: auto; } }

@media (min-width: 720px) {
  [data-flex-item~='is-baseline'] {
    align-self: baseline; } }

@media (min-width: 720px) {
  [data-flex-item~='is-stretch'] {
    align-self: stretch; } }

@media (min-width: 720px) {
  [data-flex-item~='is-center'] {
    align-self: center; } }

@media (min-width: 720px) {
  [data-flex-item~='is-start'] {
    align-self: flex-start; } }

@media (min-width: 720px) {
  [data-flex-item~='is-end'] {
    align-self: flex-end; } }

@media (min-width: 1024px) {
  [data-flex-item~='is-auto'] {
    align-self: auto; } }

@media (min-width: 1024px) {
  [data-flex-item~='is-baseline'] {
    align-self: baseline; } }

@media (min-width: 1024px) {
  [data-flex-item~='is-stretch'] {
    align-self: stretch; } }

@media (min-width: 1024px) {
  [data-flex-item~='is-center'] {
    align-self: center; } }

@media (min-width: 1024px) {
  [data-flex-item~='is-start'] {
    align-self: flex-start; } }

@media (min-width: 1024px) {
  [data-flex-item~='is-end'] {
    align-self: flex-end; } }

@media (min-width: 1024px) {
  [data-flex-item~='is-auto'] {
    align-self: auto; } }

@media (min-width: 1024px) {
  [data-flex-item~='is-baseline'] {
    align-self: baseline; } }

@media (min-width: 1024px) {
  [data-flex-item~='is-stretch'] {
    align-self: stretch; } }

@media (min-width: 1024px) {
  [data-flex-item~='is-center'] {
    align-self: center; } }

@media (min-width: 1024px) {
  [data-flex-item~='is-start'] {
    align-self: flex-start; } }

@media (min-width: 1024px) {
  [data-flex-item~='is-end'] {
    align-self: flex-end; } }

@media (min-width: 1280px) {
  [data-flex-item~='is-auto'] {
    align-self: auto; } }

@media (min-width: 1280px) {
  [data-flex-item~='is-baseline'] {
    align-self: baseline; } }

@media (min-width: 1280px) {
  [data-flex-item~='is-stretch'] {
    align-self: stretch; } }

@media (min-width: 1280px) {
  [data-flex-item~='is-center'] {
    align-self: center; } }

@media (min-width: 1280px) {
  [data-flex-item~='is-start'] {
    align-self: flex-start; } }

@media (min-width: 1280px) {
  [data-flex-item~='is-end'] {
    align-self: flex-end; } }

@media (min-width: 1920px) {
  [data-flex-item~='is-auto'] {
    align-self: auto; } }

@media (min-width: 1920px) {
  [data-flex-item~='is-baseline'] {
    align-self: baseline; } }

@media (min-width: 1920px) {
  [data-flex-item~='is-stretch'] {
    align-self: stretch; } }

@media (min-width: 1920px) {
  [data-flex-item~='is-center'] {
    align-self: center; } }

@media (min-width: 1920px) {
  [data-flex-item~='is-start'] {
    align-self: flex-start; } }

@media (min-width: 1920px) {
  [data-flex-item~='is-end'] {
    align-self: flex-end; } }

@keyframes tooltipBottom {
  0% {
    opacity: 0;
    transform: translateX(-50%) translateY(-0.25rem); }
  100% {
    opacity: 1;
    transform: translateX(-50%) translateY(0.25rem); } }

@keyframes tooltipTop {
  0% {
    opacity: 0;
    transform: translateX(-50%) translateY(0.25rem); }
  100% {
    opacity: 1;
    transform: translateX(-50%) translateY(-0.25rem); } }

@keyframes tooltipLeft {
  0% {
    opacity: 0;
    transform: translateX(0.25rem) translateY(-50%); }
  100% {
    opacity: 1;
    transform: translateX(-0.25rem) translateY(-50%); } }

@keyframes tooltipRight {
  0% {
    opacity: 0;
    transform: translateX(-0.25rem) translateY(-50%); }
  100% {
    opacity: 1;
    transform: translateX(0.25rem) translateY(-50%); } }

[data-tooltip-bottom], [data-tooltip-left], [data-tooltip-right], [data-tooltip-top] {
  overflow: visible;
  position: relative; }
  [data-tooltip-bottom]::after, [data-tooltip-left]::after, [data-tooltip-right]::after, [data-tooltip-top]::after {
    display: none;
    letter-spacing: 0;
    pointer-events: none;
    position: absolute;
    text-align: center;
    text-decoration: none;
    text-transform: initial;
    white-space: nowrap;
    z-index: 100;
    padding: 0.1875rem 0.375rem;
    font-size: 0.75rem;
    line-height: 0.875rem;
    min-height: 1.25rem;
    min-width: 1.25rem;
    background: #2a3d54;
    color: #fff;
    font-weight: normal;
    border-radius: 0.125rem;
    border-radius: 0.125rem; }
    [data-tooltip-bottom]::after .bubble:first-child, [data-tooltip-left]::after .bubble:first-child, [data-tooltip-right]::after .bubble:first-child, [data-tooltip-top]::after .bubble:first-child,
    [data-tooltip-bottom]::after .tag:first-child,
    [data-tooltip-left]::after .tag:first-child,
    [data-tooltip-right]::after .tag:first-child,
    [data-tooltip-top]::after .tag:first-child {
      margin-left: -0.1875rem;
      margin-right: 0.1875rem; }
    [data-tooltip-bottom]::after .bubble:last-child, [data-tooltip-left]::after .bubble:last-child, [data-tooltip-right]::after .bubble:last-child, [data-tooltip-top]::after .bubble:last-child,
    [data-tooltip-bottom]::after .tag:last-child,
    [data-tooltip-left]::after .tag:last-child,
    [data-tooltip-right]::after .tag:last-child,
    [data-tooltip-top]::after .tag:last-child {
      margin-left: 0.1875rem;
      margin-right: -0.1875rem; }
    [data-tooltip-bottom]::after .bubble:only-child, [data-tooltip-left]::after .bubble:only-child, [data-tooltip-right]::after .bubble:only-child, [data-tooltip-top]::after .bubble:only-child,
    [data-tooltip-bottom]::after .tag:only-child,
    [data-tooltip-left]::after .tag:only-child,
    [data-tooltip-right]::after .tag:only-child,
    [data-tooltip-top]::after .tag:only-child {
      margin-left: -0.375rem;
      margin-right: -0.375rem; }
    [data-tooltip-bottom]::after .ec-icon, [data-tooltip-left]::after .ec-icon, [data-tooltip-right]::after .ec-icon, [data-tooltip-top]::after .ec-icon {
      padding-right: 0.1875rem; }
      @supports (display: inline-flex) {
        [data-tooltip-bottom]::after .ec-icon, [data-tooltip-left]::after .ec-icon, [data-tooltip-right]::after .ec-icon, [data-tooltip-top]::after .ec-icon {
          display: inline-flex;
          align-items: center;
          justify-content: center; } }
      [data-tooltip-bottom]::after .ec-icon::before, [data-tooltip-left]::after .ec-icon::before, [data-tooltip-right]::after .ec-icon::before, [data-tooltip-top]::after .ec-icon::before {
        font-size: 1.33333em;
        line-height: inherit;
        vertical-align: top; }
      [data-tooltip-bottom]::after .ec-icon_right, [data-tooltip-left]::after .ec-icon_right, [data-tooltip-right]::after .ec-icon_right, [data-tooltip-top]::after .ec-icon_right {
        padding-left: 0.1875rem;
        padding-right: 0; }
      [data-tooltip-bottom]::after .ec-icon:empty, [data-tooltip-left]::after .ec-icon:empty, [data-tooltip-right]::after .ec-icon:empty, [data-tooltip-top]::after .ec-icon:empty {
        padding-left: 0;
        padding-right: 0; }
        [data-tooltip-bottom]::after .ec-icon:empty:first-child, [data-tooltip-left]::after .ec-icon:empty:first-child, [data-tooltip-right]::after .ec-icon:empty:first-child, [data-tooltip-top]::after .ec-icon:empty:first-child {
          margin-left: -0.09375rem;
          margin-right: 0.1875rem; }
        [data-tooltip-bottom]::after .ec-icon:empty:last-child, [data-tooltip-left]::after .ec-icon:empty:last-child, [data-tooltip-right]::after .ec-icon:empty:last-child, [data-tooltip-top]::after .ec-icon:empty:last-child {
          margin-left: 0.1875rem;
          margin-right: -0.09375rem; }
        [data-tooltip-bottom]::after .ec-icon:empty:only-child, [data-tooltip-left]::after .ec-icon:empty:only-child, [data-tooltip-right]::after .ec-icon:empty:only-child, [data-tooltip-top]::after .ec-icon:empty:only-child {
          margin-left: -0.375rem;
          margin-right: -0.375rem; }
        [data-tooltip-bottom]::after .ec-icon:empty::before, [data-tooltip-left]::after .ec-icon:empty::before, [data-tooltip-right]::after .ec-icon:empty::before, [data-tooltip-top]::after .ec-icon:empty::before {
          margin-left: 0;
          margin-right: 0; }
  [data-tooltip-bottom]:hover::after, [data-tooltip-left]:hover::after, [data-tooltip-right]:hover::after, [data-tooltip-top]:hover::after {
    display: block;
    opacity: 1; }

[data-tooltip-bottom]::after {
  content: attr(data-tooltip-bottom);
  animation: tooltipBottom 0.2s forwards;
  left: 50%;
  opacity: 0;
  top: 100%; }

[data-tooltip-bottom]:hover::after {
  margin-top: 0.25rem; }

[data-tooltip-left]::after {
  content: attr(data-tooltip-left);
  animation: tooltipLeft 0.2s forwards;
  opacity: 0;
  right: 100%;
  top: 50%; }

[data-tooltip-left]:hover::after {
  margin-right: 0.25rem; }

[data-tooltip-right]::after {
  content: attr(data-tooltip-right);
  animation: tooltipRight 0.2s forwards;
  left: 100%;
  opacity: 0;
  top: 50%; }

[data-tooltip-right]:hover::after {
  margin-right: 0.25rem; }

[data-tooltip-top]::after {
  content: attr(data-tooltip-top);
  animation: tooltipTop 0.2s forwards;
  bottom: 100%;
  left: 50%;
  opacity: 0; }

[data-tooltip-top]:hover::after {
  margin-top: 0.25rem; }

.is-raised {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) , 0 2px 8px rgba(0, 0, 0, 0.1); }
  @media (min-width: 0px) {
    .is-raised-xs {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) , 0 2px 8px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 480px) {
    .is-raised-sm {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) , 0 2px 8px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 720px) {
    .is-raised-md {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) , 0 2px 8px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 1024px) {
    .is-raised-nav {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) , 0 2px 8px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 1024px) {
    .is-raised-lg {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) , 0 2px 8px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 1280px) {
    .is-raised-xl {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) , 0 2px 8px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 1920px) {
    .is-raised-xxl {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) , 0 2px 8px rgba(0, 0, 0, 0.1); } }

.is-raised-small {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) , 0 1px 4px rgba(0, 0, 0, 0.1); }
  @media (min-width: 0px) {
    .is-raised-small-xs {
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) , 0 1px 4px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 480px) {
    .is-raised-small-sm {
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) , 0 1px 4px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 720px) {
    .is-raised-small-md {
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) , 0 1px 4px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 1024px) {
    .is-raised-small-nav {
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) , 0 1px 4px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 1024px) {
    .is-raised-small-lg {
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) , 0 1px 4px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 1280px) {
    .is-raised-small-xl {
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) , 0 1px 4px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 1920px) {
    .is-raised-small-xxl {
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) , 0 1px 4px rgba(0, 0, 0, 0.1); } }

.is-raised-big {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) , 0 4px 16px rgba(0, 0, 0, 0.1); }
  @media (min-width: 0px) {
    .is-raised-big-xs {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) , 0 4px 16px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 480px) {
    .is-raised-big-sm {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) , 0 4px 16px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 720px) {
    .is-raised-big-md {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) , 0 4px 16px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 1024px) {
    .is-raised-big-nav {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) , 0 4px 16px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 1024px) {
    .is-raised-big-lg {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) , 0 4px 16px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 1280px) {
    .is-raised-big-xl {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) , 0 4px 16px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 1920px) {
    .is-raised-big-xxl {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) , 0 4px 16px rgba(0, 0, 0, 0.1); } }

a.is-raised:focus, a.is-raised:hover,
.input.is-raised:focus,
.input.is-raised:hover,
.input_clear.is-raised:focus,
.input_clear.is-raised:hover,
.btn.is-raised:focus,
.btn.is-raised:hover {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1); }

@media (min-width: 0px) {
  a.is-raised-xs:focus, a.is-raised-xs:hover,
  .input.is-raised-xs:focus,
  .input.is-raised-xs:hover,
  .input_clear.is-raised-xs:focus,
  .input_clear.is-raised-xs:hover,
  .btn.is-raised-xs:focus,
  .btn.is-raised-xs:hover {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1); } }

@media (min-width: 480px) {
  a.is-raised-sm:focus, a.is-raised-sm:hover,
  .input.is-raised-sm:focus,
  .input.is-raised-sm:hover,
  .input_clear.is-raised-sm:focus,
  .input_clear.is-raised-sm:hover,
  .btn.is-raised-sm:focus,
  .btn.is-raised-sm:hover {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1); } }

@media (min-width: 720px) {
  a.is-raised-md:focus, a.is-raised-md:hover,
  .input.is-raised-md:focus,
  .input.is-raised-md:hover,
  .input_clear.is-raised-md:focus,
  .input_clear.is-raised-md:hover,
  .btn.is-raised-md:focus,
  .btn.is-raised-md:hover {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1); } }

@media (min-width: 1024px) {
  a.is-raised-nav:focus, a.is-raised-nav:hover,
  .input.is-raised-nav:focus,
  .input.is-raised-nav:hover,
  .input_clear.is-raised-nav:focus,
  .input_clear.is-raised-nav:hover,
  .btn.is-raised-nav:focus,
  .btn.is-raised-nav:hover {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1); } }

@media (min-width: 1024px) {
  a.is-raised-lg:focus, a.is-raised-lg:hover,
  .input.is-raised-lg:focus,
  .input.is-raised-lg:hover,
  .input_clear.is-raised-lg:focus,
  .input_clear.is-raised-lg:hover,
  .btn.is-raised-lg:focus,
  .btn.is-raised-lg:hover {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1); } }

@media (min-width: 1280px) {
  a.is-raised-xl:focus, a.is-raised-xl:hover,
  .input.is-raised-xl:focus,
  .input.is-raised-xl:hover,
  .input_clear.is-raised-xl:focus,
  .input_clear.is-raised-xl:hover,
  .btn.is-raised-xl:focus,
  .btn.is-raised-xl:hover {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1); } }

@media (min-width: 1920px) {
  a.is-raised-xxl:focus, a.is-raised-xxl:hover,
  .input.is-raised-xxl:focus,
  .input.is-raised-xxl:hover,
  .input_clear.is-raised-xxl:focus,
  .input_clear.is-raised-xxl:hover,
  .btn.is-raised-xxl:focus,
  .btn.is-raised-xxl:hover {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1); } }

a.is-raised-small:focus, a.is-raised-small:hover,
.input.is-raised-small:focus,
.input.is-raised-small:hover,
.input_clear.is-raised-small:focus,
.input_clear.is-raised-small:hover,
.btn.is-raised-small:focus,
.btn.is-raised-small:hover {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) , 0 1px 2px rgba(0, 0, 0, 0.1); }

@media (min-width: 0px) {
  a.is-raised-small-xs:focus, a.is-raised-small-xs:hover,
  .input.is-raised-small-xs:focus,
  .input.is-raised-small-xs:hover,
  .input_clear.is-raised-small-xs:focus,
  .input_clear.is-raised-small-xs:hover,
  .btn.is-raised-small-xs:focus,
  .btn.is-raised-small-xs:hover {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) , 0 1px 2px rgba(0, 0, 0, 0.1); } }

@media (min-width: 480px) {
  a.is-raised-small-sm:focus, a.is-raised-small-sm:hover,
  .input.is-raised-small-sm:focus,
  .input.is-raised-small-sm:hover,
  .input_clear.is-raised-small-sm:focus,
  .input_clear.is-raised-small-sm:hover,
  .btn.is-raised-small-sm:focus,
  .btn.is-raised-small-sm:hover {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) , 0 1px 2px rgba(0, 0, 0, 0.1); } }

@media (min-width: 720px) {
  a.is-raised-small-md:focus, a.is-raised-small-md:hover,
  .input.is-raised-small-md:focus,
  .input.is-raised-small-md:hover,
  .input_clear.is-raised-small-md:focus,
  .input_clear.is-raised-small-md:hover,
  .btn.is-raised-small-md:focus,
  .btn.is-raised-small-md:hover {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) , 0 1px 2px rgba(0, 0, 0, 0.1); } }

@media (min-width: 1024px) {
  a.is-raised-small-nav:focus, a.is-raised-small-nav:hover,
  .input.is-raised-small-nav:focus,
  .input.is-raised-small-nav:hover,
  .input_clear.is-raised-small-nav:focus,
  .input_clear.is-raised-small-nav:hover,
  .btn.is-raised-small-nav:focus,
  .btn.is-raised-small-nav:hover {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) , 0 1px 2px rgba(0, 0, 0, 0.1); } }

@media (min-width: 1024px) {
  a.is-raised-small-lg:focus, a.is-raised-small-lg:hover,
  .input.is-raised-small-lg:focus,
  .input.is-raised-small-lg:hover,
  .input_clear.is-raised-small-lg:focus,
  .input_clear.is-raised-small-lg:hover,
  .btn.is-raised-small-lg:focus,
  .btn.is-raised-small-lg:hover {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) , 0 1px 2px rgba(0, 0, 0, 0.1); } }

@media (min-width: 1280px) {
  a.is-raised-small-xl:focus, a.is-raised-small-xl:hover,
  .input.is-raised-small-xl:focus,
  .input.is-raised-small-xl:hover,
  .input_clear.is-raised-small-xl:focus,
  .input_clear.is-raised-small-xl:hover,
  .btn.is-raised-small-xl:focus,
  .btn.is-raised-small-xl:hover {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) , 0 1px 2px rgba(0, 0, 0, 0.1); } }

@media (min-width: 1920px) {
  a.is-raised-small-xxl:focus, a.is-raised-small-xxl:hover,
  .input.is-raised-small-xxl:focus,
  .input.is-raised-small-xxl:hover,
  .input_clear.is-raised-small-xxl:focus,
  .input_clear.is-raised-small-xxl:hover,
  .btn.is-raised-small-xxl:focus,
  .btn.is-raised-small-xxl:hover {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) , 0 1px 2px rgba(0, 0, 0, 0.1); } }

a.is-raised-big:focus, a.is-raised-big:hover,
.input.is-raised-big:focus,
.input.is-raised-big:hover,
.input_clear.is-raised-big:focus,
.input_clear.is-raised-big:hover,
.btn.is-raised-big:focus,
.btn.is-raised-big:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) , 0 2px 8px rgba(0, 0, 0, 0.1); }

@media (min-width: 0px) {
  a.is-raised-big-xs:focus, a.is-raised-big-xs:hover,
  .input.is-raised-big-xs:focus,
  .input.is-raised-big-xs:hover,
  .input_clear.is-raised-big-xs:focus,
  .input_clear.is-raised-big-xs:hover,
  .btn.is-raised-big-xs:focus,
  .btn.is-raised-big-xs:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) , 0 2px 8px rgba(0, 0, 0, 0.1); } }

@media (min-width: 480px) {
  a.is-raised-big-sm:focus, a.is-raised-big-sm:hover,
  .input.is-raised-big-sm:focus,
  .input.is-raised-big-sm:hover,
  .input_clear.is-raised-big-sm:focus,
  .input_clear.is-raised-big-sm:hover,
  .btn.is-raised-big-sm:focus,
  .btn.is-raised-big-sm:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) , 0 2px 8px rgba(0, 0, 0, 0.1); } }

@media (min-width: 720px) {
  a.is-raised-big-md:focus, a.is-raised-big-md:hover,
  .input.is-raised-big-md:focus,
  .input.is-raised-big-md:hover,
  .input_clear.is-raised-big-md:focus,
  .input_clear.is-raised-big-md:hover,
  .btn.is-raised-big-md:focus,
  .btn.is-raised-big-md:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) , 0 2px 8px rgba(0, 0, 0, 0.1); } }

@media (min-width: 1024px) {
  a.is-raised-big-nav:focus, a.is-raised-big-nav:hover,
  .input.is-raised-big-nav:focus,
  .input.is-raised-big-nav:hover,
  .input_clear.is-raised-big-nav:focus,
  .input_clear.is-raised-big-nav:hover,
  .btn.is-raised-big-nav:focus,
  .btn.is-raised-big-nav:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) , 0 2px 8px rgba(0, 0, 0, 0.1); } }

@media (min-width: 1024px) {
  a.is-raised-big-lg:focus, a.is-raised-big-lg:hover,
  .input.is-raised-big-lg:focus,
  .input.is-raised-big-lg:hover,
  .input_clear.is-raised-big-lg:focus,
  .input_clear.is-raised-big-lg:hover,
  .btn.is-raised-big-lg:focus,
  .btn.is-raised-big-lg:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) , 0 2px 8px rgba(0, 0, 0, 0.1); } }

@media (min-width: 1280px) {
  a.is-raised-big-xl:focus, a.is-raised-big-xl:hover,
  .input.is-raised-big-xl:focus,
  .input.is-raised-big-xl:hover,
  .input_clear.is-raised-big-xl:focus,
  .input_clear.is-raised-big-xl:hover,
  .btn.is-raised-big-xl:focus,
  .btn.is-raised-big-xl:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) , 0 2px 8px rgba(0, 0, 0, 0.1); } }

@media (min-width: 1920px) {
  a.is-raised-big-xxl:focus, a.is-raised-big-xxl:hover,
  .input.is-raised-big-xxl:focus,
  .input.is-raised-big-xxl:hover,
  .input_clear.is-raised-big-xxl:focus,
  .input_clear.is-raised-big-xxl:hover,
  .btn.is-raised-big-xxl:focus,
  .btn.is-raised-big-xxl:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) , 0 2px 8px rgba(0, 0, 0, 0.1); } }

.is-lifted {
  box-shadow: 0 16px 16px -4px rgba(0, 0, 0, 0.2) , 0 16px 24px rgba(0, 0, 0, 0.1); }
  @media (min-width: 0px) {
    .is-lifted-xs {
      box-shadow: 0 16px 16px -4px rgba(0, 0, 0, 0.2) , 0 16px 24px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 480px) {
    .is-lifted-sm {
      box-shadow: 0 16px 16px -4px rgba(0, 0, 0, 0.2) , 0 16px 24px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 720px) {
    .is-lifted-md {
      box-shadow: 0 16px 16px -4px rgba(0, 0, 0, 0.2) , 0 16px 24px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 1024px) {
    .is-lifted-nav {
      box-shadow: 0 16px 16px -4px rgba(0, 0, 0, 0.2) , 0 16px 24px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 1024px) {
    .is-lifted-lg {
      box-shadow: 0 16px 16px -4px rgba(0, 0, 0, 0.2) , 0 16px 24px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 1280px) {
    .is-lifted-xl {
      box-shadow: 0 16px 16px -4px rgba(0, 0, 0, 0.2) , 0 16px 24px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 1920px) {
    .is-lifted-xxl {
      box-shadow: 0 16px 16px -4px rgba(0, 0, 0, 0.2) , 0 16px 24px rgba(0, 0, 0, 0.1); } }

.is-lifted-small {
  box-shadow: 0 12px 8px -8px rgba(0, 0, 0, 0.1); }
  @media (min-width: 0px) {
    .is-lifted-small-xs {
      box-shadow: 0 12px 8px -8px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 480px) {
    .is-lifted-small-sm {
      box-shadow: 0 12px 8px -8px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 720px) {
    .is-lifted-small-md {
      box-shadow: 0 12px 8px -8px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 1024px) {
    .is-lifted-small-nav {
      box-shadow: 0 12px 8px -8px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 1024px) {
    .is-lifted-small-lg {
      box-shadow: 0 12px 8px -8px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 1280px) {
    .is-lifted-small-xl {
      box-shadow: 0 12px 8px -8px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 1920px) {
    .is-lifted-small-xxl {
      box-shadow: 0 12px 8px -8px rgba(0, 0, 0, 0.1); } }

.is-lifted-big {
  box-shadow: 0 24px 28px 0 rgba(0, 0, 0, 0.2) , 0 24px 40px rgba(0, 0, 0, 0.1); }
  @media (min-width: 0px) {
    .is-lifted-big-xs {
      box-shadow: 0 24px 28px 0 rgba(0, 0, 0, 0.2) , 0 24px 40px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 480px) {
    .is-lifted-big-sm {
      box-shadow: 0 24px 28px 0 rgba(0, 0, 0, 0.2) , 0 24px 40px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 720px) {
    .is-lifted-big-md {
      box-shadow: 0 24px 28px 0 rgba(0, 0, 0, 0.2) , 0 24px 40px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 1024px) {
    .is-lifted-big-nav {
      box-shadow: 0 24px 28px 0 rgba(0, 0, 0, 0.2) , 0 24px 40px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 1024px) {
    .is-lifted-big-lg {
      box-shadow: 0 24px 28px 0 rgba(0, 0, 0, 0.2) , 0 24px 40px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 1280px) {
    .is-lifted-big-xl {
      box-shadow: 0 24px 28px 0 rgba(0, 0, 0, 0.2) , 0 24px 40px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 1920px) {
    .is-lifted-big-xxl {
      box-shadow: 0 24px 28px 0 rgba(0, 0, 0, 0.2) , 0 24px 40px rgba(0, 0, 0, 0.1); } }

a.is-lifted:hover, a.is-lifted:focus,
.input.is-lifted:hover,
.input.is-lifted:focus,
.btn.is-lifted:hover,
.btn.is-lifted:focus {
  box-shadow: 0 12px 8px -8px rgba(0, 0, 0, 0.1); }

@media (min-width: 0px) {
  a.is-lifted-xs:hover, a.is-lifted-xs:focus,
  .input.is-lifted-xs:hover,
  .input.is-lifted-xs:focus,
  .btn.is-lifted-xs:hover,
  .btn.is-lifted-xs:focus {
    box-shadow: 0 12px 8px -8px rgba(0, 0, 0, 0.1); } }

@media (min-width: 480px) {
  a.is-lifted-sm:hover, a.is-lifted-sm:focus,
  .input.is-lifted-sm:hover,
  .input.is-lifted-sm:focus,
  .btn.is-lifted-sm:hover,
  .btn.is-lifted-sm:focus {
    box-shadow: 0 12px 8px -8px rgba(0, 0, 0, 0.1); } }

@media (min-width: 720px) {
  a.is-lifted-md:hover, a.is-lifted-md:focus,
  .input.is-lifted-md:hover,
  .input.is-lifted-md:focus,
  .btn.is-lifted-md:hover,
  .btn.is-lifted-md:focus {
    box-shadow: 0 12px 8px -8px rgba(0, 0, 0, 0.1); } }

@media (min-width: 1024px) {
  a.is-lifted-nav:hover, a.is-lifted-nav:focus,
  .input.is-lifted-nav:hover,
  .input.is-lifted-nav:focus,
  .btn.is-lifted-nav:hover,
  .btn.is-lifted-nav:focus {
    box-shadow: 0 12px 8px -8px rgba(0, 0, 0, 0.1); } }

@media (min-width: 1024px) {
  a.is-lifted-lg:hover, a.is-lifted-lg:focus,
  .input.is-lifted-lg:hover,
  .input.is-lifted-lg:focus,
  .btn.is-lifted-lg:hover,
  .btn.is-lifted-lg:focus {
    box-shadow: 0 12px 8px -8px rgba(0, 0, 0, 0.1); } }

@media (min-width: 1280px) {
  a.is-lifted-xl:hover, a.is-lifted-xl:focus,
  .input.is-lifted-xl:hover,
  .input.is-lifted-xl:focus,
  .btn.is-lifted-xl:hover,
  .btn.is-lifted-xl:focus {
    box-shadow: 0 12px 8px -8px rgba(0, 0, 0, 0.1); } }

@media (min-width: 1920px) {
  a.is-lifted-xxl:hover, a.is-lifted-xxl:focus,
  .input.is-lifted-xxl:hover,
  .input.is-lifted-xxl:focus,
  .btn.is-lifted-xxl:hover,
  .btn.is-lifted-xxl:focus {
    box-shadow: 0 12px 8px -8px rgba(0, 0, 0, 0.1); } }

a.is-lifted-small:hover, a.is-lifted-small:focus,
.input.is-lifted-small:hover,
.input.is-lifted-small:focus,
.btn.is-lifted-small:hover,
.btn.is-lifted-small:focus {
  box-shadow: 0 6px 4px -2px rgba(0, 0, 0, 0.1); }

@media (min-width: 0px) {
  a.is-lifted-small-xs:hover, a.is-lifted-small-xs:focus,
  .input.is-lifted-small-xs:hover,
  .input.is-lifted-small-xs:focus,
  .btn.is-lifted-small-xs:hover,
  .btn.is-lifted-small-xs:focus {
    box-shadow: 0 6px 4px -2px rgba(0, 0, 0, 0.1); } }

@media (min-width: 480px) {
  a.is-lifted-small-sm:hover, a.is-lifted-small-sm:focus,
  .input.is-lifted-small-sm:hover,
  .input.is-lifted-small-sm:focus,
  .btn.is-lifted-small-sm:hover,
  .btn.is-lifted-small-sm:focus {
    box-shadow: 0 6px 4px -2px rgba(0, 0, 0, 0.1); } }

@media (min-width: 720px) {
  a.is-lifted-small-md:hover, a.is-lifted-small-md:focus,
  .input.is-lifted-small-md:hover,
  .input.is-lifted-small-md:focus,
  .btn.is-lifted-small-md:hover,
  .btn.is-lifted-small-md:focus {
    box-shadow: 0 6px 4px -2px rgba(0, 0, 0, 0.1); } }

@media (min-width: 1024px) {
  a.is-lifted-small-nav:hover, a.is-lifted-small-nav:focus,
  .input.is-lifted-small-nav:hover,
  .input.is-lifted-small-nav:focus,
  .btn.is-lifted-small-nav:hover,
  .btn.is-lifted-small-nav:focus {
    box-shadow: 0 6px 4px -2px rgba(0, 0, 0, 0.1); } }

@media (min-width: 1024px) {
  a.is-lifted-small-lg:hover, a.is-lifted-small-lg:focus,
  .input.is-lifted-small-lg:hover,
  .input.is-lifted-small-lg:focus,
  .btn.is-lifted-small-lg:hover,
  .btn.is-lifted-small-lg:focus {
    box-shadow: 0 6px 4px -2px rgba(0, 0, 0, 0.1); } }

@media (min-width: 1280px) {
  a.is-lifted-small-xl:hover, a.is-lifted-small-xl:focus,
  .input.is-lifted-small-xl:hover,
  .input.is-lifted-small-xl:focus,
  .btn.is-lifted-small-xl:hover,
  .btn.is-lifted-small-xl:focus {
    box-shadow: 0 6px 4px -2px rgba(0, 0, 0, 0.1); } }

@media (min-width: 1920px) {
  a.is-lifted-small-xxl:hover, a.is-lifted-small-xxl:focus,
  .input.is-lifted-small-xxl:hover,
  .input.is-lifted-small-xxl:focus,
  .btn.is-lifted-small-xxl:hover,
  .btn.is-lifted-small-xxl:focus {
    box-shadow: 0 6px 4px -2px rgba(0, 0, 0, 0.1); } }

a-big:hover, a-big:focus,
.input-big:hover,
.input-big:focus,
.btn-big:hover,
.btn-big:focus {
  box-shadow: 0 12px 8px -8px rgba(0, 0, 0, 0.1); }

@media (min-width: 0px) {
  a-big-xs:hover, a-big-xs:focus,
  .input-big-xs:hover,
  .input-big-xs:focus,
  .btn-big-xs:hover,
  .btn-big-xs:focus {
    box-shadow: 0 12px 8px -8px rgba(0, 0, 0, 0.1); } }

@media (min-width: 480px) {
  a-big-sm:hover, a-big-sm:focus,
  .input-big-sm:hover,
  .input-big-sm:focus,
  .btn-big-sm:hover,
  .btn-big-sm:focus {
    box-shadow: 0 12px 8px -8px rgba(0, 0, 0, 0.1); } }

@media (min-width: 720px) {
  a-big-md:hover, a-big-md:focus,
  .input-big-md:hover,
  .input-big-md:focus,
  .btn-big-md:hover,
  .btn-big-md:focus {
    box-shadow: 0 12px 8px -8px rgba(0, 0, 0, 0.1); } }

@media (min-width: 1024px) {
  a-big-nav:hover, a-big-nav:focus,
  .input-big-nav:hover,
  .input-big-nav:focus,
  .btn-big-nav:hover,
  .btn-big-nav:focus {
    box-shadow: 0 12px 8px -8px rgba(0, 0, 0, 0.1); } }

@media (min-width: 1024px) {
  a-big-lg:hover, a-big-lg:focus,
  .input-big-lg:hover,
  .input-big-lg:focus,
  .btn-big-lg:hover,
  .btn-big-lg:focus {
    box-shadow: 0 12px 8px -8px rgba(0, 0, 0, 0.1); } }

@media (min-width: 1280px) {
  a-big-xl:hover, a-big-xl:focus,
  .input-big-xl:hover,
  .input-big-xl:focus,
  .btn-big-xl:hover,
  .btn-big-xl:focus {
    box-shadow: 0 12px 8px -8px rgba(0, 0, 0, 0.1); } }

@media (min-width: 1920px) {
  a-big-xxl:hover, a-big-xxl:focus,
  .input-big-xxl:hover,
  .input-big-xxl:focus,
  .btn-big-xxl:hover,
  .btn-big-xxl:focus {
    box-shadow: 0 12px 8px -8px rgba(0, 0, 0, 0.1); } }

.is-elevated-1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.1), 0px 1px 1px 0px rgba(0, 0, 0, 0.08), 0px 1px 3px 0px rgba(0, 0, 0, 0.06); }

.is-elevated-2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.1), 0px 2px 2px 0px rgba(0, 0, 0, 0.08), 0px 1px 5px 0px rgba(0, 0, 0, 0.06); }

.is-elevated-3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.1), 0px 3px 4px 0px rgba(0, 0, 0, 0.08), 0px 1px 8px 0px rgba(0, 0, 0, 0.06); }

.is-elevated-4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.1), 0px 4px 5px 0px rgba(0, 0, 0, 0.08), 0px 1px 10px 0px rgba(0, 0, 0, 0.06); }

.is-elevated-5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.1), 0px 5px 8px 0px rgba(0, 0, 0, 0.08), 0px 1px 14px 0px rgba(0, 0, 0, 0.06); }

.is-elevated-6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.1), 0px 6px 10px 0px rgba(0, 0, 0, 0.08), 0px 1px 18px 0px rgba(0, 0, 0, 0.06); }

.is-elevated-7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.1), 0px 7px 10px 1px rgba(0, 0, 0, 0.08), 0px 2px 16px 1px rgba(0, 0, 0, 0.06); }

.is-elevated-8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.1), 0px 8px 10px 1px rgba(0, 0, 0, 0.08), 0px 3px 14px 2px rgba(0, 0, 0, 0.06); }

.is-elevated-9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.1), 0px 9px 12px 1px rgba(0, 0, 0, 0.08), 0px 3px 16px 2px rgba(0, 0, 0, 0.06); }

.is-elevated-10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.1), 0px 10px 14px 1px rgba(0, 0, 0, 0.08), 0px 4px 18px 3px rgba(0, 0, 0, 0.06); }

.is-elevated-11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.1), 0px 11px 15px 1px rgba(0, 0, 0, 0.08), 0px 4px 20px 3px rgba(0, 0, 0, 0.06); }

.is-elevated-12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.1), 0px 12px 17px 2px rgba(0, 0, 0, 0.08), 0px 5px 22px 4px rgba(0, 0, 0, 0.06); }

.is-elevated-13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.1), 0px 13px 19px 2px rgba(0, 0, 0, 0.08), 0px 5px 24px 4px rgba(0, 0, 0, 0.06); }

.is-elevated-14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.1), 0px 14px 21px 2px rgba(0, 0, 0, 0.08), 0px 5px 26px 4px rgba(0, 0, 0, 0.06); }

.is-elevated-15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.1), 0px 15px 22px 2px rgba(0, 0, 0, 0.08), 0px 6px 28px 5px rgba(0, 0, 0, 0.06); }

.is-elevated-16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.1), 0px 16px 24px 2px rgba(0, 0, 0, 0.08), 0px 6px 30px 5px rgba(0, 0, 0, 0.06); }

.is-elevated-17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.1), 0px 17px 26px 2px rgba(0, 0, 0, 0.08), 0px 6px 32px 5px rgba(0, 0, 0, 0.06); }

.is-elevated-18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.1), 0px 18px 28px 2px rgba(0, 0, 0, 0.08), 0px 7px 34px 6px rgba(0, 0, 0, 0.06); }

.is-elevated-19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.1), 0px 19px 29px 2px rgba(0, 0, 0, 0.08), 0px 7px 36px 6px rgba(0, 0, 0, 0.06); }

.is-elevated-20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.1), 0px 20px 31px 3px rgba(0, 0, 0, 0.08), 0px 8px 38px 7px rgba(0, 0, 0, 0.06); }

.is-elevated-21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.1), 0px 21px 33px 3px rgba(0, 0, 0, 0.08), 0px 8px 40px 7px rgba(0, 0, 0, 0.06); }

.is-elevated-22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.1), 0px 22px 35px 3px rgba(0, 0, 0, 0.08), 0px 8px 42px 7px rgba(0, 0, 0, 0.06); }

.is-elevated-23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.1), 0px 23px 36px 3px rgba(0, 0, 0, 0.08), 0px 9px 44px 8px rgba(0, 0, 0, 0.06); }

.is-elevated-24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.1), 0px 24px 38px 3px rgba(0, 0, 0, 0.08), 0px 9px 46px 8px rgba(0, 0, 0, 0.06); }

.is-rounded {
  border-radius: 0.25rem; }
  @media (min-width: 0px) {
    .is-rounded-xs {
      border-radius: 0.25rem; } }
  @media (min-width: 480px) {
    .is-rounded-sm {
      border-radius: 0.25rem; } }
  @media (min-width: 720px) {
    .is-rounded-md {
      border-radius: 0.25rem; } }
  @media (min-width: 1024px) {
    .is-rounded-nav {
      border-radius: 0.25rem; } }
  @media (min-width: 1024px) {
    .is-rounded-lg {
      border-radius: 0.25rem; } }
  @media (min-width: 1280px) {
    .is-rounded-xl {
      border-radius: 0.25rem; } }
  @media (min-width: 1920px) {
    .is-rounded-xxl {
      border-radius: 0.25rem; } }
  .is-rounded-0 {
    border-radius: 0; }
    @media (min-width: 0px) {
      .is-rounded-0-xs {
        border-radius: 0; } }
    @media (min-width: 480px) {
      .is-rounded-0-sm {
        border-radius: 0; } }
    @media (min-width: 720px) {
      .is-rounded-0-md {
        border-radius: 0; } }
    @media (min-width: 1024px) {
      .is-rounded-0-nav {
        border-radius: 0; } }
    @media (min-width: 1024px) {
      .is-rounded-0-lg {
        border-radius: 0; } }
    @media (min-width: 1280px) {
      .is-rounded-0-xl {
        border-radius: 0; } }
    @media (min-width: 1920px) {
      .is-rounded-0-xxl {
        border-radius: 0; } }
  .is-rounded-top {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    @media (min-width: 0px) {
      .is-rounded-top-xs {
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem; } }
    @media (min-width: 480px) {
      .is-rounded-top-sm {
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem; } }
    @media (min-width: 720px) {
      .is-rounded-top-md {
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem; } }
    @media (min-width: 1024px) {
      .is-rounded-top-nav {
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem; } }
    @media (min-width: 1024px) {
      .is-rounded-top-lg {
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem; } }
    @media (min-width: 1280px) {
      .is-rounded-top-xl {
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem; } }
    @media (min-width: 1920px) {
      .is-rounded-top-xxl {
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem; } }
    .is-rounded-top-0 {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
      @media (min-width: 0px) {
        .is-rounded-top-0-xs {
          border-top-left-radius: 0;
          border-top-right-radius: 0; } }
      @media (min-width: 480px) {
        .is-rounded-top-0-sm {
          border-top-left-radius: 0;
          border-top-right-radius: 0; } }
      @media (min-width: 720px) {
        .is-rounded-top-0-md {
          border-top-left-radius: 0;
          border-top-right-radius: 0; } }
      @media (min-width: 1024px) {
        .is-rounded-top-0-nav {
          border-top-left-radius: 0;
          border-top-right-radius: 0; } }
      @media (min-width: 1024px) {
        .is-rounded-top-0-lg {
          border-top-left-radius: 0;
          border-top-right-radius: 0; } }
      @media (min-width: 1280px) {
        .is-rounded-top-0-xl {
          border-top-left-radius: 0;
          border-top-right-radius: 0; } }
      @media (min-width: 1920px) {
        .is-rounded-top-0-xxl {
          border-top-left-radius: 0;
          border-top-right-radius: 0; } }
  .is-rounded-right {
    border-bottom-right-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    @media (min-width: 0px) {
      .is-rounded-right-xs {
        border-bottom-right-radius: 0.25rem;
        border-top-right-radius: 0.25rem; } }
    @media (min-width: 480px) {
      .is-rounded-right-sm {
        border-bottom-right-radius: 0.25rem;
        border-top-right-radius: 0.25rem; } }
    @media (min-width: 720px) {
      .is-rounded-right-md {
        border-bottom-right-radius: 0.25rem;
        border-top-right-radius: 0.25rem; } }
    @media (min-width: 1024px) {
      .is-rounded-right-nav {
        border-bottom-right-radius: 0.25rem;
        border-top-right-radius: 0.25rem; } }
    @media (min-width: 1024px) {
      .is-rounded-right-lg {
        border-bottom-right-radius: 0.25rem;
        border-top-right-radius: 0.25rem; } }
    @media (min-width: 1280px) {
      .is-rounded-right-xl {
        border-bottom-right-radius: 0.25rem;
        border-top-right-radius: 0.25rem; } }
    @media (min-width: 1920px) {
      .is-rounded-right-xxl {
        border-bottom-right-radius: 0.25rem;
        border-top-right-radius: 0.25rem; } }
    .is-rounded-right-0 {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0; }
      @media (min-width: 0px) {
        .is-rounded-right-0-xs {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0; } }
      @media (min-width: 480px) {
        .is-rounded-right-0-sm {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0; } }
      @media (min-width: 720px) {
        .is-rounded-right-0-md {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0; } }
      @media (min-width: 1024px) {
        .is-rounded-right-0-nav {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0; } }
      @media (min-width: 1024px) {
        .is-rounded-right-0-lg {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0; } }
      @media (min-width: 1280px) {
        .is-rounded-right-0-xl {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0; } }
      @media (min-width: 1920px) {
        .is-rounded-right-0-xxl {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0; } }
  .is-rounded-bottom {
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem; }
    @media (min-width: 0px) {
      .is-rounded-bottom-xs {
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem; } }
    @media (min-width: 480px) {
      .is-rounded-bottom-sm {
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem; } }
    @media (min-width: 720px) {
      .is-rounded-bottom-md {
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem; } }
    @media (min-width: 1024px) {
      .is-rounded-bottom-nav {
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem; } }
    @media (min-width: 1024px) {
      .is-rounded-bottom-lg {
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem; } }
    @media (min-width: 1280px) {
      .is-rounded-bottom-xl {
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem; } }
    @media (min-width: 1920px) {
      .is-rounded-bottom-xxl {
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem; } }
    .is-rounded-bottom-0 {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0; }
      @media (min-width: 0px) {
        .is-rounded-bottom-0-xs {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0; } }
      @media (min-width: 480px) {
        .is-rounded-bottom-0-sm {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0; } }
      @media (min-width: 720px) {
        .is-rounded-bottom-0-md {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0; } }
      @media (min-width: 1024px) {
        .is-rounded-bottom-0-nav {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0; } }
      @media (min-width: 1024px) {
        .is-rounded-bottom-0-lg {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0; } }
      @media (min-width: 1280px) {
        .is-rounded-bottom-0-xl {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0; } }
      @media (min-width: 1920px) {
        .is-rounded-bottom-0-xxl {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0; } }
  .is-rounded-left {
    border-bottom-left-radius: 0.25rem;
    border-top-left-radius: 0.25rem; }
    @media (min-width: 0px) {
      .is-rounded-left-xs {
        border-bottom-left-radius: 0.25rem;
        border-top-left-radius: 0.25rem; } }
    @media (min-width: 480px) {
      .is-rounded-left-sm {
        border-bottom-left-radius: 0.25rem;
        border-top-left-radius: 0.25rem; } }
    @media (min-width: 720px) {
      .is-rounded-left-md {
        border-bottom-left-radius: 0.25rem;
        border-top-left-radius: 0.25rem; } }
    @media (min-width: 1024px) {
      .is-rounded-left-nav {
        border-bottom-left-radius: 0.25rem;
        border-top-left-radius: 0.25rem; } }
    @media (min-width: 1024px) {
      .is-rounded-left-lg {
        border-bottom-left-radius: 0.25rem;
        border-top-left-radius: 0.25rem; } }
    @media (min-width: 1280px) {
      .is-rounded-left-xl {
        border-bottom-left-radius: 0.25rem;
        border-top-left-radius: 0.25rem; } }
    @media (min-width: 1920px) {
      .is-rounded-left-xxl {
        border-bottom-left-radius: 0.25rem;
        border-top-left-radius: 0.25rem; } }
    .is-rounded-left-0 {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0; }
      @media (min-width: 0px) {
        .is-rounded-left-0-xs {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0; } }
      @media (min-width: 480px) {
        .is-rounded-left-0-sm {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0; } }
      @media (min-width: 720px) {
        .is-rounded-left-0-md {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0; } }
      @media (min-width: 1024px) {
        .is-rounded-left-0-nav {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0; } }
      @media (min-width: 1024px) {
        .is-rounded-left-0-lg {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0; } }
      @media (min-width: 1280px) {
        .is-rounded-left-0-xl {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0; } }
      @media (min-width: 1920px) {
        .is-rounded-left-0-xxl {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0; } }
  .is-rounded-small {
    border-radius: 0.125rem; }
    @media (min-width: 0px) {
      .is-rounded-small-xs {
        border-radius: 0.125rem; } }
    @media (min-width: 480px) {
      .is-rounded-small-sm {
        border-radius: 0.125rem; } }
    @media (min-width: 720px) {
      .is-rounded-small-md {
        border-radius: 0.125rem; } }
    @media (min-width: 1024px) {
      .is-rounded-small-nav {
        border-radius: 0.125rem; } }
    @media (min-width: 1024px) {
      .is-rounded-small-lg {
        border-radius: 0.125rem; } }
    @media (min-width: 1280px) {
      .is-rounded-small-xl {
        border-radius: 0.125rem; } }
    @media (min-width: 1920px) {
      .is-rounded-small-xxl {
        border-radius: 0.125rem; } }
    .is-rounded-small-0 {
      border-radius: 0; }
      @media (min-width: 0px) {
        .is-rounded-small-0-xs {
          border-radius: 0; } }
      @media (min-width: 480px) {
        .is-rounded-small-0-sm {
          border-radius: 0; } }
      @media (min-width: 720px) {
        .is-rounded-small-0-md {
          border-radius: 0; } }
      @media (min-width: 1024px) {
        .is-rounded-small-0-nav {
          border-radius: 0; } }
      @media (min-width: 1024px) {
        .is-rounded-small-0-lg {
          border-radius: 0; } }
      @media (min-width: 1280px) {
        .is-rounded-small-0-xl {
          border-radius: 0; } }
      @media (min-width: 1920px) {
        .is-rounded-small-0-xxl {
          border-radius: 0; } }
    .is-rounded-small-top {
      border-top-left-radius: 0.125rem;
      border-top-right-radius: 0.125rem; }
      @media (min-width: 0px) {
        .is-rounded-small-top-xs {
          border-top-left-radius: 0.125rem;
          border-top-right-radius: 0.125rem; } }
      @media (min-width: 480px) {
        .is-rounded-small-top-sm {
          border-top-left-radius: 0.125rem;
          border-top-right-radius: 0.125rem; } }
      @media (min-width: 720px) {
        .is-rounded-small-top-md {
          border-top-left-radius: 0.125rem;
          border-top-right-radius: 0.125rem; } }
      @media (min-width: 1024px) {
        .is-rounded-small-top-nav {
          border-top-left-radius: 0.125rem;
          border-top-right-radius: 0.125rem; } }
      @media (min-width: 1024px) {
        .is-rounded-small-top-lg {
          border-top-left-radius: 0.125rem;
          border-top-right-radius: 0.125rem; } }
      @media (min-width: 1280px) {
        .is-rounded-small-top-xl {
          border-top-left-radius: 0.125rem;
          border-top-right-radius: 0.125rem; } }
      @media (min-width: 1920px) {
        .is-rounded-small-top-xxl {
          border-top-left-radius: 0.125rem;
          border-top-right-radius: 0.125rem; } }
      .is-rounded-small-top-0 {
        border-top-left-radius: 0;
        border-top-right-radius: 0; }
        @media (min-width: 0px) {
          .is-rounded-small-top-0-xs {
            border-top-left-radius: 0;
            border-top-right-radius: 0; } }
        @media (min-width: 480px) {
          .is-rounded-small-top-0-sm {
            border-top-left-radius: 0;
            border-top-right-radius: 0; } }
        @media (min-width: 720px) {
          .is-rounded-small-top-0-md {
            border-top-left-radius: 0;
            border-top-right-radius: 0; } }
        @media (min-width: 1024px) {
          .is-rounded-small-top-0-nav {
            border-top-left-radius: 0;
            border-top-right-radius: 0; } }
        @media (min-width: 1024px) {
          .is-rounded-small-top-0-lg {
            border-top-left-radius: 0;
            border-top-right-radius: 0; } }
        @media (min-width: 1280px) {
          .is-rounded-small-top-0-xl {
            border-top-left-radius: 0;
            border-top-right-radius: 0; } }
        @media (min-width: 1920px) {
          .is-rounded-small-top-0-xxl {
            border-top-left-radius: 0;
            border-top-right-radius: 0; } }
    .is-rounded-small-right {
      border-bottom-right-radius: 0.125rem;
      border-top-right-radius: 0.125rem; }
      @media (min-width: 0px) {
        .is-rounded-small-right-xs {
          border-bottom-right-radius: 0.125rem;
          border-top-right-radius: 0.125rem; } }
      @media (min-width: 480px) {
        .is-rounded-small-right-sm {
          border-bottom-right-radius: 0.125rem;
          border-top-right-radius: 0.125rem; } }
      @media (min-width: 720px) {
        .is-rounded-small-right-md {
          border-bottom-right-radius: 0.125rem;
          border-top-right-radius: 0.125rem; } }
      @media (min-width: 1024px) {
        .is-rounded-small-right-nav {
          border-bottom-right-radius: 0.125rem;
          border-top-right-radius: 0.125rem; } }
      @media (min-width: 1024px) {
        .is-rounded-small-right-lg {
          border-bottom-right-radius: 0.125rem;
          border-top-right-radius: 0.125rem; } }
      @media (min-width: 1280px) {
        .is-rounded-small-right-xl {
          border-bottom-right-radius: 0.125rem;
          border-top-right-radius: 0.125rem; } }
      @media (min-width: 1920px) {
        .is-rounded-small-right-xxl {
          border-bottom-right-radius: 0.125rem;
          border-top-right-radius: 0.125rem; } }
      .is-rounded-small-right-0 {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0; }
        @media (min-width: 0px) {
          .is-rounded-small-right-0-xs {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0; } }
        @media (min-width: 480px) {
          .is-rounded-small-right-0-sm {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0; } }
        @media (min-width: 720px) {
          .is-rounded-small-right-0-md {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0; } }
        @media (min-width: 1024px) {
          .is-rounded-small-right-0-nav {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0; } }
        @media (min-width: 1024px) {
          .is-rounded-small-right-0-lg {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0; } }
        @media (min-width: 1280px) {
          .is-rounded-small-right-0-xl {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0; } }
        @media (min-width: 1920px) {
          .is-rounded-small-right-0-xxl {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0; } }
    .is-rounded-small-bottom {
      border-bottom-left-radius: 0.125rem;
      border-bottom-right-radius: 0.125rem; }
      @media (min-width: 0px) {
        .is-rounded-small-bottom-xs {
          border-bottom-left-radius: 0.125rem;
          border-bottom-right-radius: 0.125rem; } }
      @media (min-width: 480px) {
        .is-rounded-small-bottom-sm {
          border-bottom-left-radius: 0.125rem;
          border-bottom-right-radius: 0.125rem; } }
      @media (min-width: 720px) {
        .is-rounded-small-bottom-md {
          border-bottom-left-radius: 0.125rem;
          border-bottom-right-radius: 0.125rem; } }
      @media (min-width: 1024px) {
        .is-rounded-small-bottom-nav {
          border-bottom-left-radius: 0.125rem;
          border-bottom-right-radius: 0.125rem; } }
      @media (min-width: 1024px) {
        .is-rounded-small-bottom-lg {
          border-bottom-left-radius: 0.125rem;
          border-bottom-right-radius: 0.125rem; } }
      @media (min-width: 1280px) {
        .is-rounded-small-bottom-xl {
          border-bottom-left-radius: 0.125rem;
          border-bottom-right-radius: 0.125rem; } }
      @media (min-width: 1920px) {
        .is-rounded-small-bottom-xxl {
          border-bottom-left-radius: 0.125rem;
          border-bottom-right-radius: 0.125rem; } }
      .is-rounded-small-bottom-0 {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0; }
        @media (min-width: 0px) {
          .is-rounded-small-bottom-0-xs {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0; } }
        @media (min-width: 480px) {
          .is-rounded-small-bottom-0-sm {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0; } }
        @media (min-width: 720px) {
          .is-rounded-small-bottom-0-md {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0; } }
        @media (min-width: 1024px) {
          .is-rounded-small-bottom-0-nav {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0; } }
        @media (min-width: 1024px) {
          .is-rounded-small-bottom-0-lg {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0; } }
        @media (min-width: 1280px) {
          .is-rounded-small-bottom-0-xl {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0; } }
        @media (min-width: 1920px) {
          .is-rounded-small-bottom-0-xxl {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0; } }
    .is-rounded-small-left {
      border-bottom-left-radius: 0.125rem;
      border-top-left-radius: 0.125rem; }
      @media (min-width: 0px) {
        .is-rounded-small-left-xs {
          border-bottom-left-radius: 0.125rem;
          border-top-left-radius: 0.125rem; } }
      @media (min-width: 480px) {
        .is-rounded-small-left-sm {
          border-bottom-left-radius: 0.125rem;
          border-top-left-radius: 0.125rem; } }
      @media (min-width: 720px) {
        .is-rounded-small-left-md {
          border-bottom-left-radius: 0.125rem;
          border-top-left-radius: 0.125rem; } }
      @media (min-width: 1024px) {
        .is-rounded-small-left-nav {
          border-bottom-left-radius: 0.125rem;
          border-top-left-radius: 0.125rem; } }
      @media (min-width: 1024px) {
        .is-rounded-small-left-lg {
          border-bottom-left-radius: 0.125rem;
          border-top-left-radius: 0.125rem; } }
      @media (min-width: 1280px) {
        .is-rounded-small-left-xl {
          border-bottom-left-radius: 0.125rem;
          border-top-left-radius: 0.125rem; } }
      @media (min-width: 1920px) {
        .is-rounded-small-left-xxl {
          border-bottom-left-radius: 0.125rem;
          border-top-left-radius: 0.125rem; } }
      .is-rounded-small-left-0 {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0; }
        @media (min-width: 0px) {
          .is-rounded-small-left-0-xs {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0; } }
        @media (min-width: 480px) {
          .is-rounded-small-left-0-sm {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0; } }
        @media (min-width: 720px) {
          .is-rounded-small-left-0-md {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0; } }
        @media (min-width: 1024px) {
          .is-rounded-small-left-0-nav {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0; } }
        @media (min-width: 1024px) {
          .is-rounded-small-left-0-lg {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0; } }
        @media (min-width: 1280px) {
          .is-rounded-small-left-0-xl {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0; } }
        @media (min-width: 1920px) {
          .is-rounded-small-left-0-xxl {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0; } }
  .is-rounded-big {
    border-radius: 0.5rem; }
    @media (min-width: 0px) {
      .is-rounded-big-xs {
        border-radius: 0.5rem; } }
    @media (min-width: 480px) {
      .is-rounded-big-sm {
        border-radius: 0.5rem; } }
    @media (min-width: 720px) {
      .is-rounded-big-md {
        border-radius: 0.5rem; } }
    @media (min-width: 1024px) {
      .is-rounded-big-nav {
        border-radius: 0.5rem; } }
    @media (min-width: 1024px) {
      .is-rounded-big-lg {
        border-radius: 0.5rem; } }
    @media (min-width: 1280px) {
      .is-rounded-big-xl {
        border-radius: 0.5rem; } }
    @media (min-width: 1920px) {
      .is-rounded-big-xxl {
        border-radius: 0.5rem; } }
    .is-rounded-big-0 {
      border-radius: 0; }
      @media (min-width: 0px) {
        .is-rounded-big-0-xs {
          border-radius: 0; } }
      @media (min-width: 480px) {
        .is-rounded-big-0-sm {
          border-radius: 0; } }
      @media (min-width: 720px) {
        .is-rounded-big-0-md {
          border-radius: 0; } }
      @media (min-width: 1024px) {
        .is-rounded-big-0-nav {
          border-radius: 0; } }
      @media (min-width: 1024px) {
        .is-rounded-big-0-lg {
          border-radius: 0; } }
      @media (min-width: 1280px) {
        .is-rounded-big-0-xl {
          border-radius: 0; } }
      @media (min-width: 1920px) {
        .is-rounded-big-0-xxl {
          border-radius: 0; } }
    .is-rounded-big-top {
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem; }
      @media (min-width: 0px) {
        .is-rounded-big-top-xs {
          border-top-left-radius: 0.5rem;
          border-top-right-radius: 0.5rem; } }
      @media (min-width: 480px) {
        .is-rounded-big-top-sm {
          border-top-left-radius: 0.5rem;
          border-top-right-radius: 0.5rem; } }
      @media (min-width: 720px) {
        .is-rounded-big-top-md {
          border-top-left-radius: 0.5rem;
          border-top-right-radius: 0.5rem; } }
      @media (min-width: 1024px) {
        .is-rounded-big-top-nav {
          border-top-left-radius: 0.5rem;
          border-top-right-radius: 0.5rem; } }
      @media (min-width: 1024px) {
        .is-rounded-big-top-lg {
          border-top-left-radius: 0.5rem;
          border-top-right-radius: 0.5rem; } }
      @media (min-width: 1280px) {
        .is-rounded-big-top-xl {
          border-top-left-radius: 0.5rem;
          border-top-right-radius: 0.5rem; } }
      @media (min-width: 1920px) {
        .is-rounded-big-top-xxl {
          border-top-left-radius: 0.5rem;
          border-top-right-radius: 0.5rem; } }
      .is-rounded-big-top-0 {
        border-top-left-radius: 0;
        border-top-right-radius: 0; }
        @media (min-width: 0px) {
          .is-rounded-big-top-0-xs {
            border-top-left-radius: 0;
            border-top-right-radius: 0; } }
        @media (min-width: 480px) {
          .is-rounded-big-top-0-sm {
            border-top-left-radius: 0;
            border-top-right-radius: 0; } }
        @media (min-width: 720px) {
          .is-rounded-big-top-0-md {
            border-top-left-radius: 0;
            border-top-right-radius: 0; } }
        @media (min-width: 1024px) {
          .is-rounded-big-top-0-nav {
            border-top-left-radius: 0;
            border-top-right-radius: 0; } }
        @media (min-width: 1024px) {
          .is-rounded-big-top-0-lg {
            border-top-left-radius: 0;
            border-top-right-radius: 0; } }
        @media (min-width: 1280px) {
          .is-rounded-big-top-0-xl {
            border-top-left-radius: 0;
            border-top-right-radius: 0; } }
        @media (min-width: 1920px) {
          .is-rounded-big-top-0-xxl {
            border-top-left-radius: 0;
            border-top-right-radius: 0; } }
    .is-rounded-big-right {
      border-bottom-right-radius: 0.5rem;
      border-top-right-radius: 0.5rem; }
      @media (min-width: 0px) {
        .is-rounded-big-right-xs {
          border-bottom-right-radius: 0.5rem;
          border-top-right-radius: 0.5rem; } }
      @media (min-width: 480px) {
        .is-rounded-big-right-sm {
          border-bottom-right-radius: 0.5rem;
          border-top-right-radius: 0.5rem; } }
      @media (min-width: 720px) {
        .is-rounded-big-right-md {
          border-bottom-right-radius: 0.5rem;
          border-top-right-radius: 0.5rem; } }
      @media (min-width: 1024px) {
        .is-rounded-big-right-nav {
          border-bottom-right-radius: 0.5rem;
          border-top-right-radius: 0.5rem; } }
      @media (min-width: 1024px) {
        .is-rounded-big-right-lg {
          border-bottom-right-radius: 0.5rem;
          border-top-right-radius: 0.5rem; } }
      @media (min-width: 1280px) {
        .is-rounded-big-right-xl {
          border-bottom-right-radius: 0.5rem;
          border-top-right-radius: 0.5rem; } }
      @media (min-width: 1920px) {
        .is-rounded-big-right-xxl {
          border-bottom-right-radius: 0.5rem;
          border-top-right-radius: 0.5rem; } }
      .is-rounded-big-right-0 {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0; }
        @media (min-width: 0px) {
          .is-rounded-big-right-0-xs {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0; } }
        @media (min-width: 480px) {
          .is-rounded-big-right-0-sm {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0; } }
        @media (min-width: 720px) {
          .is-rounded-big-right-0-md {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0; } }
        @media (min-width: 1024px) {
          .is-rounded-big-right-0-nav {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0; } }
        @media (min-width: 1024px) {
          .is-rounded-big-right-0-lg {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0; } }
        @media (min-width: 1280px) {
          .is-rounded-big-right-0-xl {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0; } }
        @media (min-width: 1920px) {
          .is-rounded-big-right-0-xxl {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0; } }
    .is-rounded-big-bottom {
      border-bottom-left-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem; }
      @media (min-width: 0px) {
        .is-rounded-big-bottom-xs {
          border-bottom-left-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem; } }
      @media (min-width: 480px) {
        .is-rounded-big-bottom-sm {
          border-bottom-left-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem; } }
      @media (min-width: 720px) {
        .is-rounded-big-bottom-md {
          border-bottom-left-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem; } }
      @media (min-width: 1024px) {
        .is-rounded-big-bottom-nav {
          border-bottom-left-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem; } }
      @media (min-width: 1024px) {
        .is-rounded-big-bottom-lg {
          border-bottom-left-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem; } }
      @media (min-width: 1280px) {
        .is-rounded-big-bottom-xl {
          border-bottom-left-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem; } }
      @media (min-width: 1920px) {
        .is-rounded-big-bottom-xxl {
          border-bottom-left-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem; } }
      .is-rounded-big-bottom-0 {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0; }
        @media (min-width: 0px) {
          .is-rounded-big-bottom-0-xs {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0; } }
        @media (min-width: 480px) {
          .is-rounded-big-bottom-0-sm {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0; } }
        @media (min-width: 720px) {
          .is-rounded-big-bottom-0-md {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0; } }
        @media (min-width: 1024px) {
          .is-rounded-big-bottom-0-nav {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0; } }
        @media (min-width: 1024px) {
          .is-rounded-big-bottom-0-lg {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0; } }
        @media (min-width: 1280px) {
          .is-rounded-big-bottom-0-xl {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0; } }
        @media (min-width: 1920px) {
          .is-rounded-big-bottom-0-xxl {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0; } }
    .is-rounded-big-left {
      border-bottom-left-radius: 0.5rem;
      border-top-left-radius: 0.5rem; }
      @media (min-width: 0px) {
        .is-rounded-big-left-xs {
          border-bottom-left-radius: 0.5rem;
          border-top-left-radius: 0.5rem; } }
      @media (min-width: 480px) {
        .is-rounded-big-left-sm {
          border-bottom-left-radius: 0.5rem;
          border-top-left-radius: 0.5rem; } }
      @media (min-width: 720px) {
        .is-rounded-big-left-md {
          border-bottom-left-radius: 0.5rem;
          border-top-left-radius: 0.5rem; } }
      @media (min-width: 1024px) {
        .is-rounded-big-left-nav {
          border-bottom-left-radius: 0.5rem;
          border-top-left-radius: 0.5rem; } }
      @media (min-width: 1024px) {
        .is-rounded-big-left-lg {
          border-bottom-left-radius: 0.5rem;
          border-top-left-radius: 0.5rem; } }
      @media (min-width: 1280px) {
        .is-rounded-big-left-xl {
          border-bottom-left-radius: 0.5rem;
          border-top-left-radius: 0.5rem; } }
      @media (min-width: 1920px) {
        .is-rounded-big-left-xxl {
          border-bottom-left-radius: 0.5rem;
          border-top-left-radius: 0.5rem; } }
      .is-rounded-big-left-0 {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0; }
        @media (min-width: 0px) {
          .is-rounded-big-left-0-xs {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0; } }
        @media (min-width: 480px) {
          .is-rounded-big-left-0-sm {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0; } }
        @media (min-width: 720px) {
          .is-rounded-big-left-0-md {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0; } }
        @media (min-width: 1024px) {
          .is-rounded-big-left-0-nav {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0; } }
        @media (min-width: 1024px) {
          .is-rounded-big-left-0-lg {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0; } }
        @media (min-width: 1280px) {
          .is-rounded-big-left-0-xl {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0; } }
        @media (min-width: 1920px) {
          .is-rounded-big-left-0-xxl {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0; } }

.is-bordered {
  border: 0.0625rem solid rgba(42, 61, 84, 0.1); }
  @media (min-width: 0px) {
    .is-bordered-xs {
      border: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
  @media (min-width: 480px) {
    .is-bordered-sm {
      border: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
  @media (min-width: 720px) {
    .is-bordered-md {
      border: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
  @media (min-width: 1024px) {
    .is-bordered-nav {
      border: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
  @media (min-width: 1024px) {
    .is-bordered-lg {
      border: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
  @media (min-width: 1280px) {
    .is-bordered-xl {
      border: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
  @media (min-width: 1920px) {
    .is-bordered-xxl {
      border: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
  .is-bordered-top {
    border-top: 0.0625rem solid rgba(42, 61, 84, 0.1); }
    @media (min-width: 0px) {
      .is-bordered-top-xs {
        border-top: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 480px) {
      .is-bordered-top-sm {
        border-top: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 720px) {
      .is-bordered-top-md {
        border-top: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 1024px) {
      .is-bordered-top-nav {
        border-top: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 1024px) {
      .is-bordered-top-lg {
        border-top: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 1280px) {
      .is-bordered-top-xl {
        border-top: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 1920px) {
      .is-bordered-top-xxl {
        border-top: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
  .is-bordered-right {
    border-right: 0.0625rem solid rgba(42, 61, 84, 0.1); }
    @media (min-width: 0px) {
      .is-bordered-right-xs {
        border-right: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 480px) {
      .is-bordered-right-sm {
        border-right: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 720px) {
      .is-bordered-right-md {
        border-right: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 1024px) {
      .is-bordered-right-nav {
        border-right: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 1024px) {
      .is-bordered-right-lg {
        border-right: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 1280px) {
      .is-bordered-right-xl {
        border-right: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 1920px) {
      .is-bordered-right-xxl {
        border-right: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
  .is-bordered-bottom {
    border-bottom: 0.0625rem solid rgba(42, 61, 84, 0.1); }
    @media (min-width: 0px) {
      .is-bordered-bottom-xs {
        border-bottom: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 480px) {
      .is-bordered-bottom-sm {
        border-bottom: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 720px) {
      .is-bordered-bottom-md {
        border-bottom: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 1024px) {
      .is-bordered-bottom-nav {
        border-bottom: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 1024px) {
      .is-bordered-bottom-lg {
        border-bottom: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 1280px) {
      .is-bordered-bottom-xl {
        border-bottom: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 1920px) {
      .is-bordered-bottom-xxl {
        border-bottom: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
  .is-bordered-left {
    border-left: 0.0625rem solid rgba(42, 61, 84, 0.1); }
    @media (min-width: 0px) {
      .is-bordered-left-xs {
        border-left: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 480px) {
      .is-bordered-left-sm {
        border-left: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 720px) {
      .is-bordered-left-md {
        border-left: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 1024px) {
      .is-bordered-left-nav {
        border-left: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 1024px) {
      .is-bordered-left-lg {
        border-left: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 1280px) {
      .is-bordered-left-xl {
        border-left: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 1920px) {
      .is-bordered-left-xxl {
        border-left: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
  .is-bordered-middle {
    border-bottom: 0.0625rem solid rgba(42, 61, 84, 0.1);
    border-top: 0.0625rem solid rgba(42, 61, 84, 0.1); }
    @media (min-width: 0px) {
      .is-bordered-middle-xs {
        border-bottom: 0.0625rem solid rgba(42, 61, 84, 0.1);
        border-top: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 480px) {
      .is-bordered-middle-sm {
        border-bottom: 0.0625rem solid rgba(42, 61, 84, 0.1);
        border-top: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 720px) {
      .is-bordered-middle-md {
        border-bottom: 0.0625rem solid rgba(42, 61, 84, 0.1);
        border-top: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 1024px) {
      .is-bordered-middle-nav {
        border-bottom: 0.0625rem solid rgba(42, 61, 84, 0.1);
        border-top: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 1024px) {
      .is-bordered-middle-lg {
        border-bottom: 0.0625rem solid rgba(42, 61, 84, 0.1);
        border-top: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 1280px) {
      .is-bordered-middle-xl {
        border-bottom: 0.0625rem solid rgba(42, 61, 84, 0.1);
        border-top: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 1920px) {
      .is-bordered-middle-xxl {
        border-bottom: 0.0625rem solid rgba(42, 61, 84, 0.1);
        border-top: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
  .is-bordered-center {
    border-left: 0.0625rem solid rgba(42, 61, 84, 0.1);
    border-right: 0.0625rem solid rgba(42, 61, 84, 0.1); }
    @media (min-width: 0px) {
      .is-bordered-center-xs {
        border-left: 0.0625rem solid rgba(42, 61, 84, 0.1);
        border-right: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 480px) {
      .is-bordered-center-sm {
        border-left: 0.0625rem solid rgba(42, 61, 84, 0.1);
        border-right: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 720px) {
      .is-bordered-center-md {
        border-left: 0.0625rem solid rgba(42, 61, 84, 0.1);
        border-right: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 1024px) {
      .is-bordered-center-nav {
        border-left: 0.0625rem solid rgba(42, 61, 84, 0.1);
        border-right: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 1024px) {
      .is-bordered-center-lg {
        border-left: 0.0625rem solid rgba(42, 61, 84, 0.1);
        border-right: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 1280px) {
      .is-bordered-center-xl {
        border-left: 0.0625rem solid rgba(42, 61, 84, 0.1);
        border-right: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 1920px) {
      .is-bordered-center-xxl {
        border-left: 0.0625rem solid rgba(42, 61, 84, 0.1);
        border-right: 0.0625rem solid rgba(42, 61, 84, 0.1); } }

.is-fab-n {
  left: 50%;
  top: 1rem;
  transform: translateX(-50%); }
  @media (min-width: 0px) {
    .is-fab-n-xs {
      left: 50%;
      top: 1rem;
      transform: translateX(-50%); } }
  @media (min-width: 480px) {
    .is-fab-n-sm {
      left: 50%;
      top: 1rem;
      transform: translateX(-50%); } }
  @media (min-width: 720px) {
    .is-fab-n-md {
      left: 50%;
      top: 1rem;
      transform: translateX(-50%); } }
  @media (min-width: 1024px) {
    .is-fab-n-nav {
      left: 50%;
      top: 1rem;
      transform: translateX(-50%); } }
  @media (min-width: 1024px) {
    .is-fab-n-lg {
      left: 50%;
      top: 1rem;
      transform: translateX(-50%); } }
  @media (min-width: 1280px) {
    .is-fab-n-xl {
      left: 50%;
      top: 1rem;
      transform: translateX(-50%); } }
  @media (min-width: 1920px) {
    .is-fab-n-xxl {
      left: 50%;
      top: 1rem;
      transform: translateX(-50%); } }

.is-fab-ne {
  right: 1rem;
  top: 1rem; }
  @media (min-width: 0px) {
    .is-fab-ne-xs {
      right: 1rem;
      top: 1rem; } }
  @media (min-width: 480px) {
    .is-fab-ne-sm {
      right: 1rem;
      top: 1rem; } }
  @media (min-width: 720px) {
    .is-fab-ne-md {
      right: 1rem;
      top: 1rem; } }
  @media (min-width: 1024px) {
    .is-fab-ne-nav {
      right: 1rem;
      top: 1rem; } }
  @media (min-width: 1024px) {
    .is-fab-ne-lg {
      right: 1rem;
      top: 1rem; } }
  @media (min-width: 1280px) {
    .is-fab-ne-xl {
      right: 1rem;
      top: 1rem; } }
  @media (min-width: 1920px) {
    .is-fab-ne-xxl {
      right: 1rem;
      top: 1rem; } }

.is-fab-e {
  right: 1rem;
  top: 50%;
  transform: translateY(-50%); }
  @media (min-width: 0px) {
    .is-fab-e-xs {
      right: 1rem;
      top: 50%;
      transform: translateY(-50%); } }
  @media (min-width: 480px) {
    .is-fab-e-sm {
      right: 1rem;
      top: 50%;
      transform: translateY(-50%); } }
  @media (min-width: 720px) {
    .is-fab-e-md {
      right: 1rem;
      top: 50%;
      transform: translateY(-50%); } }
  @media (min-width: 1024px) {
    .is-fab-e-nav {
      right: 1rem;
      top: 50%;
      transform: translateY(-50%); } }
  @media (min-width: 1024px) {
    .is-fab-e-lg {
      right: 1rem;
      top: 50%;
      transform: translateY(-50%); } }
  @media (min-width: 1280px) {
    .is-fab-e-xl {
      right: 1rem;
      top: 50%;
      transform: translateY(-50%); } }
  @media (min-width: 1920px) {
    .is-fab-e-xxl {
      right: 1rem;
      top: 50%;
      transform: translateY(-50%); } }

.is-fab-se {
  bottom: 1rem;
  right: 1rem; }
  @media (min-width: 0px) {
    .is-fab-se-xs {
      bottom: 1rem;
      right: 1rem; } }
  @media (min-width: 480px) {
    .is-fab-se-sm {
      bottom: 1rem;
      right: 1rem; } }
  @media (min-width: 720px) {
    .is-fab-se-md {
      bottom: 1rem;
      right: 1rem; } }
  @media (min-width: 1024px) {
    .is-fab-se-nav {
      bottom: 1rem;
      right: 1rem; } }
  @media (min-width: 1024px) {
    .is-fab-se-lg {
      bottom: 1rem;
      right: 1rem; } }
  @media (min-width: 1280px) {
    .is-fab-se-xl {
      bottom: 1rem;
      right: 1rem; } }
  @media (min-width: 1920px) {
    .is-fab-se-xxl {
      bottom: 1rem;
      right: 1rem; } }

.is-fab-s {
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%); }
  @media (min-width: 0px) {
    .is-fab-s-xs {
      bottom: 1rem;
      left: 50%;
      transform: translateX(-50%); } }
  @media (min-width: 480px) {
    .is-fab-s-sm {
      bottom: 1rem;
      left: 50%;
      transform: translateX(-50%); } }
  @media (min-width: 720px) {
    .is-fab-s-md {
      bottom: 1rem;
      left: 50%;
      transform: translateX(-50%); } }
  @media (min-width: 1024px) {
    .is-fab-s-nav {
      bottom: 1rem;
      left: 50%;
      transform: translateX(-50%); } }
  @media (min-width: 1024px) {
    .is-fab-s-lg {
      bottom: 1rem;
      left: 50%;
      transform: translateX(-50%); } }
  @media (min-width: 1280px) {
    .is-fab-s-xl {
      bottom: 1rem;
      left: 50%;
      transform: translateX(-50%); } }
  @media (min-width: 1920px) {
    .is-fab-s-xxl {
      bottom: 1rem;
      left: 50%;
      transform: translateX(-50%); } }

.is-fab-sw {
  bottom: 1rem;
  left: 1rem; }
  @media (min-width: 0px) {
    .is-fab-sw-xs {
      bottom: 1rem;
      left: 1rem; } }
  @media (min-width: 480px) {
    .is-fab-sw-sm {
      bottom: 1rem;
      left: 1rem; } }
  @media (min-width: 720px) {
    .is-fab-sw-md {
      bottom: 1rem;
      left: 1rem; } }
  @media (min-width: 1024px) {
    .is-fab-sw-nav {
      bottom: 1rem;
      left: 1rem; } }
  @media (min-width: 1024px) {
    .is-fab-sw-lg {
      bottom: 1rem;
      left: 1rem; } }
  @media (min-width: 1280px) {
    .is-fab-sw-xl {
      bottom: 1rem;
      left: 1rem; } }
  @media (min-width: 1920px) {
    .is-fab-sw-xxl {
      bottom: 1rem;
      left: 1rem; } }

.is-fab-w {
  left: 1rem;
  top: 50%;
  transform: translateY(-50%); }
  @media (min-width: 0px) {
    .is-fab-w-xs {
      left: 1rem;
      top: 50%;
      transform: translateY(-50%); } }
  @media (min-width: 480px) {
    .is-fab-w-sm {
      left: 1rem;
      top: 50%;
      transform: translateY(-50%); } }
  @media (min-width: 720px) {
    .is-fab-w-md {
      left: 1rem;
      top: 50%;
      transform: translateY(-50%); } }
  @media (min-width: 1024px) {
    .is-fab-w-nav {
      left: 1rem;
      top: 50%;
      transform: translateY(-50%); } }
  @media (min-width: 1024px) {
    .is-fab-w-lg {
      left: 1rem;
      top: 50%;
      transform: translateY(-50%); } }
  @media (min-width: 1280px) {
    .is-fab-w-xl {
      left: 1rem;
      top: 50%;
      transform: translateY(-50%); } }
  @media (min-width: 1920px) {
    .is-fab-w-xxl {
      left: 1rem;
      top: 50%;
      transform: translateY(-50%); } }

.is-fab-nw {
  left: 1rem;
  top: 1rem; }
  @media (min-width: 0px) {
    .is-fab-nw-xs {
      left: 1rem;
      top: 1rem; } }
  @media (min-width: 480px) {
    .is-fab-nw-sm {
      left: 1rem;
      top: 1rem; } }
  @media (min-width: 720px) {
    .is-fab-nw-md {
      left: 1rem;
      top: 1rem; } }
  @media (min-width: 1024px) {
    .is-fab-nw-nav {
      left: 1rem;
      top: 1rem; } }
  @media (min-width: 1024px) {
    .is-fab-nw-lg {
      left: 1rem;
      top: 1rem; } }
  @media (min-width: 1280px) {
    .is-fab-nw-xl {
      left: 1rem;
      top: 1rem; } }
  @media (min-width: 1920px) {
    .is-fab-nw-xxl {
      left: 1rem;
      top: 1rem; } }

.is-fab-0 {
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%); }
  @media (min-width: 0px) {
    .is-fab-0-xs {
      left: 50%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%); } }
  @media (min-width: 480px) {
    .is-fab-0-sm {
      left: 50%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%); } }
  @media (min-width: 720px) {
    .is-fab-0-md {
      left: 50%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%); } }
  @media (min-width: 1024px) {
    .is-fab-0-nav {
      left: 50%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%); } }
  @media (min-width: 1024px) {
    .is-fab-0-lg {
      left: 50%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%); } }
  @media (min-width: 1280px) {
    .is-fab-0-xl {
      left: 50%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%); } }
  @media (min-width: 1920px) {
    .is-fab-0-xxl {
      left: 50%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%); } }

[class*="is-fab-"] {
  position: fixed;
  z-index: 10; }

.has-scroll-shadow {
  background: linear-gradient(#fff 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), #fff 70%) 0 100%, radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
  background-attachment: local, local, scroll, scroll;
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: 100% 40px, 100% 40px, 100% 8px, 100% 8px; }

.is-outset {
  width: 100%;
  padding: 1rem 0;
  margin: 0; }
  .is-outset img {
    width: 100%; }
  .is-outset:first-child {
    padding-top: 0; }
  @media (min-width: 75rem) {
    .is-outset_left:not([data-col]), .is-outset_right:not([data-col]) {
      max-width: 50%; } }
  @media (min-width: 75rem) {
    .is-outset_left {
      margin-left: -3rem;
      float: left;
      margin-right: 2rem; } }
  @media (min-width: 75rem) {
    .is-outset_right {
      margin-right: -3rem;
      float: right;
      margin-left: 2rem; } }
  @media (min-width: 75rem) {
    .is-outset_full {
      width: initial;
      margin-right: -3rem;
      margin-left: -3rem; } }

.has-margin {
  margin:  1rem; }
  @media (min-width: 0px) {
    .has-margin-xs {
      margin:  1rem; } }
  @media (min-width: 480px) {
    .has-margin-sm {
      margin:  1rem; } }
  @media (min-width: 720px) {
    .has-margin-md {
      margin:  1rem; } }
  @media (min-width: 1024px) {
    .has-margin-nav {
      margin:  1rem; } }
  @media (min-width: 1024px) {
    .has-margin-lg {
      margin:  1rem; } }
  @media (min-width: 1280px) {
    .has-margin-xl {
      margin:  1rem; } }
  @media (min-width: 1920px) {
    .has-margin-xxl {
      margin:  1rem; } }
  .has-margin-smallest {
    margin:  0.25rem; }
    @media (min-width: 0px) {
      .has-margin-smallest-xs {
        margin:  0.25rem; } }
    @media (min-width: 480px) {
      .has-margin-smallest-sm {
        margin:  0.25rem; } }
    @media (min-width: 720px) {
      .has-margin-smallest-md {
        margin:  0.25rem; } }
    @media (min-width: 1024px) {
      .has-margin-smallest-nav {
        margin:  0.25rem; } }
    @media (min-width: 1024px) {
      .has-margin-smallest-lg {
        margin:  0.25rem; } }
    @media (min-width: 1280px) {
      .has-margin-smallest-xl {
        margin:  0.25rem; } }
    @media (min-width: 1920px) {
      .has-margin-smallest-xxl {
        margin:  0.25rem; } }
  .has-margin-small {
    margin:  0.5rem; }
    @media (min-width: 0px) {
      .has-margin-small-xs {
        margin:  0.5rem; } }
    @media (min-width: 480px) {
      .has-margin-small-sm {
        margin:  0.5rem; } }
    @media (min-width: 720px) {
      .has-margin-small-md {
        margin:  0.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-small-nav {
        margin:  0.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-small-lg {
        margin:  0.5rem; } }
    @media (min-width: 1280px) {
      .has-margin-small-xl {
        margin:  0.5rem; } }
    @media (min-width: 1920px) {
      .has-margin-small-xxl {
        margin:  0.5rem; } }
  .has-margin-big {
    margin:  2rem; }
    @media (min-width: 0px) {
      .has-margin-big-xs {
        margin:  2rem; } }
    @media (min-width: 480px) {
      .has-margin-big-sm {
        margin:  2rem; } }
    @media (min-width: 720px) {
      .has-margin-big-md {
        margin:  2rem; } }
    @media (min-width: 1024px) {
      .has-margin-big-nav {
        margin:  2rem; } }
    @media (min-width: 1024px) {
      .has-margin-big-lg {
        margin:  2rem; } }
    @media (min-width: 1280px) {
      .has-margin-big-xl {
        margin:  2rem; } }
    @media (min-width: 1920px) {
      .has-margin-big-xxl {
        margin:  2rem; } }
  .has-margin-0 {
    margin: 0; }
    @media (min-width: 0px) {
      .has-margin-0-xs {
        margin: 0; } }
    @media (min-width: 480px) {
      .has-margin-0-sm {
        margin: 0; } }
    @media (min-width: 720px) {
      .has-margin-0-md {
        margin: 0; } }
    @media (min-width: 1024px) {
      .has-margin-0-nav {
        margin: 0; } }
    @media (min-width: 1024px) {
      .has-margin-0-lg {
        margin: 0; } }
    @media (min-width: 1280px) {
      .has-margin-0-xl {
        margin: 0; } }
    @media (min-width: 1920px) {
      .has-margin-0-xxl {
        margin: 0; } }
  .has-margin-1 {
    margin: 0.25rem; }
    @media (min-width: 0px) {
      .has-margin-1-xs {
        margin: 0.25rem; } }
    @media (min-width: 480px) {
      .has-margin-1-sm {
        margin: 0.25rem; } }
    @media (min-width: 720px) {
      .has-margin-1-md {
        margin: 0.25rem; } }
    @media (min-width: 1024px) {
      .has-margin-1-nav {
        margin: 0.25rem; } }
    @media (min-width: 1024px) {
      .has-margin-1-lg {
        margin: 0.25rem; } }
    @media (min-width: 1280px) {
      .has-margin-1-xl {
        margin: 0.25rem; } }
    @media (min-width: 1920px) {
      .has-margin-1-xxl {
        margin: 0.25rem; } }
  .has-margin-2 {
    margin: 0.5rem; }
    @media (min-width: 0px) {
      .has-margin-2-xs {
        margin: 0.5rem; } }
    @media (min-width: 480px) {
      .has-margin-2-sm {
        margin: 0.5rem; } }
    @media (min-width: 720px) {
      .has-margin-2-md {
        margin: 0.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-2-nav {
        margin: 0.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-2-lg {
        margin: 0.5rem; } }
    @media (min-width: 1280px) {
      .has-margin-2-xl {
        margin: 0.5rem; } }
    @media (min-width: 1920px) {
      .has-margin-2-xxl {
        margin: 0.5rem; } }
  .has-margin-3 {
    margin: 1rem; }
    @media (min-width: 0px) {
      .has-margin-3-xs {
        margin: 1rem; } }
    @media (min-width: 480px) {
      .has-margin-3-sm {
        margin: 1rem; } }
    @media (min-width: 720px) {
      .has-margin-3-md {
        margin: 1rem; } }
    @media (min-width: 1024px) {
      .has-margin-3-nav {
        margin: 1rem; } }
    @media (min-width: 1024px) {
      .has-margin-3-lg {
        margin: 1rem; } }
    @media (min-width: 1280px) {
      .has-margin-3-xl {
        margin: 1rem; } }
    @media (min-width: 1920px) {
      .has-margin-3-xxl {
        margin: 1rem; } }
  .has-margin-4 {
    margin: 1.5rem; }
    @media (min-width: 0px) {
      .has-margin-4-xs {
        margin: 1.5rem; } }
    @media (min-width: 480px) {
      .has-margin-4-sm {
        margin: 1.5rem; } }
    @media (min-width: 720px) {
      .has-margin-4-md {
        margin: 1.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-4-nav {
        margin: 1.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-4-lg {
        margin: 1.5rem; } }
    @media (min-width: 1280px) {
      .has-margin-4-xl {
        margin: 1.5rem; } }
    @media (min-width: 1920px) {
      .has-margin-4-xxl {
        margin: 1.5rem; } }
  .has-margin-5 {
    margin: 2rem; }
    @media (min-width: 0px) {
      .has-margin-5-xs {
        margin: 2rem; } }
    @media (min-width: 480px) {
      .has-margin-5-sm {
        margin: 2rem; } }
    @media (min-width: 720px) {
      .has-margin-5-md {
        margin: 2rem; } }
    @media (min-width: 1024px) {
      .has-margin-5-nav {
        margin: 2rem; } }
    @media (min-width: 1024px) {
      .has-margin-5-lg {
        margin: 2rem; } }
    @media (min-width: 1280px) {
      .has-margin-5-xl {
        margin: 2rem; } }
    @media (min-width: 1920px) {
      .has-margin-5-xxl {
        margin: 2rem; } }
  .has-margin-6 {
    margin: 3rem; }
    @media (min-width: 0px) {
      .has-margin-6-xs {
        margin: 3rem; } }
    @media (min-width: 480px) {
      .has-margin-6-sm {
        margin: 3rem; } }
    @media (min-width: 720px) {
      .has-margin-6-md {
        margin: 3rem; } }
    @media (min-width: 1024px) {
      .has-margin-6-nav {
        margin: 3rem; } }
    @media (min-width: 1024px) {
      .has-margin-6-lg {
        margin: 3rem; } }
    @media (min-width: 1280px) {
      .has-margin-6-xl {
        margin: 3rem; } }
    @media (min-width: 1920px) {
      .has-margin-6-xxl {
        margin: 3rem; } }
  .has-margin-7 {
    margin: 4rem; }
    @media (min-width: 0px) {
      .has-margin-7-xs {
        margin: 4rem; } }
    @media (min-width: 480px) {
      .has-margin-7-sm {
        margin: 4rem; } }
    @media (min-width: 720px) {
      .has-margin-7-md {
        margin: 4rem; } }
    @media (min-width: 1024px) {
      .has-margin-7-nav {
        margin: 4rem; } }
    @media (min-width: 1024px) {
      .has-margin-7-lg {
        margin: 4rem; } }
    @media (min-width: 1280px) {
      .has-margin-7-xl {
        margin: 4rem; } }
    @media (min-width: 1920px) {
      .has-margin-7-xxl {
        margin: 4rem; } }
  .has-margin-8 {
    margin: 5rem; }
    @media (min-width: 0px) {
      .has-margin-8-xs {
        margin: 5rem; } }
    @media (min-width: 480px) {
      .has-margin-8-sm {
        margin: 5rem; } }
    @media (min-width: 720px) {
      .has-margin-8-md {
        margin: 5rem; } }
    @media (min-width: 1024px) {
      .has-margin-8-nav {
        margin: 5rem; } }
    @media (min-width: 1024px) {
      .has-margin-8-lg {
        margin: 5rem; } }
    @media (min-width: 1280px) {
      .has-margin-8-xl {
        margin: 5rem; } }
    @media (min-width: 1920px) {
      .has-margin-8-xxl {
        margin: 5rem; } }

.has-margin-middle {
  margin-bottom:  1rem;
  margin-top:  1rem; }
  @media (min-width: 0px) {
    .has-margin-middle-xs {
      margin-bottom:  1rem;
      margin-top:  1rem; } }
  @media (min-width: 480px) {
    .has-margin-middle-sm {
      margin-bottom:  1rem;
      margin-top:  1rem; } }
  @media (min-width: 720px) {
    .has-margin-middle-md {
      margin-bottom:  1rem;
      margin-top:  1rem; } }
  @media (min-width: 1024px) {
    .has-margin-middle-nav {
      margin-bottom:  1rem;
      margin-top:  1rem; } }
  @media (min-width: 1024px) {
    .has-margin-middle-lg {
      margin-bottom:  1rem;
      margin-top:  1rem; } }
  @media (min-width: 1280px) {
    .has-margin-middle-xl {
      margin-bottom:  1rem;
      margin-top:  1rem; } }
  @media (min-width: 1920px) {
    .has-margin-middle-xxl {
      margin-bottom:  1rem;
      margin-top:  1rem; } }
  .has-margin-middle-smallest {
    margin-bottom:  0.25rem;
    margin-top:  0.25rem; }
    @media (min-width: 0px) {
      .has-margin-middle-smallest-xs {
        margin-bottom:  0.25rem;
        margin-top:  0.25rem; } }
    @media (min-width: 480px) {
      .has-margin-middle-smallest-sm {
        margin-bottom:  0.25rem;
        margin-top:  0.25rem; } }
    @media (min-width: 720px) {
      .has-margin-middle-smallest-md {
        margin-bottom:  0.25rem;
        margin-top:  0.25rem; } }
    @media (min-width: 1024px) {
      .has-margin-middle-smallest-nav {
        margin-bottom:  0.25rem;
        margin-top:  0.25rem; } }
    @media (min-width: 1024px) {
      .has-margin-middle-smallest-lg {
        margin-bottom:  0.25rem;
        margin-top:  0.25rem; } }
    @media (min-width: 1280px) {
      .has-margin-middle-smallest-xl {
        margin-bottom:  0.25rem;
        margin-top:  0.25rem; } }
    @media (min-width: 1920px) {
      .has-margin-middle-smallest-xxl {
        margin-bottom:  0.25rem;
        margin-top:  0.25rem; } }
  .has-margin-middle-small {
    margin-bottom:  0.5rem;
    margin-top:  0.5rem; }
    @media (min-width: 0px) {
      .has-margin-middle-small-xs {
        margin-bottom:  0.5rem;
        margin-top:  0.5rem; } }
    @media (min-width: 480px) {
      .has-margin-middle-small-sm {
        margin-bottom:  0.5rem;
        margin-top:  0.5rem; } }
    @media (min-width: 720px) {
      .has-margin-middle-small-md {
        margin-bottom:  0.5rem;
        margin-top:  0.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-middle-small-nav {
        margin-bottom:  0.5rem;
        margin-top:  0.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-middle-small-lg {
        margin-bottom:  0.5rem;
        margin-top:  0.5rem; } }
    @media (min-width: 1280px) {
      .has-margin-middle-small-xl {
        margin-bottom:  0.5rem;
        margin-top:  0.5rem; } }
    @media (min-width: 1920px) {
      .has-margin-middle-small-xxl {
        margin-bottom:  0.5rem;
        margin-top:  0.5rem; } }
  .has-margin-middle-big {
    margin-bottom:  2rem;
    margin-top:  2rem; }
    @media (min-width: 0px) {
      .has-margin-middle-big-xs {
        margin-bottom:  2rem;
        margin-top:  2rem; } }
    @media (min-width: 480px) {
      .has-margin-middle-big-sm {
        margin-bottom:  2rem;
        margin-top:  2rem; } }
    @media (min-width: 720px) {
      .has-margin-middle-big-md {
        margin-bottom:  2rem;
        margin-top:  2rem; } }
    @media (min-width: 1024px) {
      .has-margin-middle-big-nav {
        margin-bottom:  2rem;
        margin-top:  2rem; } }
    @media (min-width: 1024px) {
      .has-margin-middle-big-lg {
        margin-bottom:  2rem;
        margin-top:  2rem; } }
    @media (min-width: 1280px) {
      .has-margin-middle-big-xl {
        margin-bottom:  2rem;
        margin-top:  2rem; } }
    @media (min-width: 1920px) {
      .has-margin-middle-big-xxl {
        margin-bottom:  2rem;
        margin-top:  2rem; } }
  .has-margin-middle-0 {
    margin-bottom: 0;
    margin-top: 0; }
    @media (min-width: 0px) {
      .has-margin-middle-0-xs {
        margin-bottom: 0;
        margin-top: 0; } }
    @media (min-width: 480px) {
      .has-margin-middle-0-sm {
        margin-bottom: 0;
        margin-top: 0; } }
    @media (min-width: 720px) {
      .has-margin-middle-0-md {
        margin-bottom: 0;
        margin-top: 0; } }
    @media (min-width: 1024px) {
      .has-margin-middle-0-nav {
        margin-bottom: 0;
        margin-top: 0; } }
    @media (min-width: 1024px) {
      .has-margin-middle-0-lg {
        margin-bottom: 0;
        margin-top: 0; } }
    @media (min-width: 1280px) {
      .has-margin-middle-0-xl {
        margin-bottom: 0;
        margin-top: 0; } }
    @media (min-width: 1920px) {
      .has-margin-middle-0-xxl {
        margin-bottom: 0;
        margin-top: 0; } }
  .has-margin-middle-1 {
    margin-bottom: 0.25rem;
    margin-top: 0.25rem; }
    @media (min-width: 0px) {
      .has-margin-middle-1-xs {
        margin-bottom: 0.25rem;
        margin-top: 0.25rem; } }
    @media (min-width: 480px) {
      .has-margin-middle-1-sm {
        margin-bottom: 0.25rem;
        margin-top: 0.25rem; } }
    @media (min-width: 720px) {
      .has-margin-middle-1-md {
        margin-bottom: 0.25rem;
        margin-top: 0.25rem; } }
    @media (min-width: 1024px) {
      .has-margin-middle-1-nav {
        margin-bottom: 0.25rem;
        margin-top: 0.25rem; } }
    @media (min-width: 1024px) {
      .has-margin-middle-1-lg {
        margin-bottom: 0.25rem;
        margin-top: 0.25rem; } }
    @media (min-width: 1280px) {
      .has-margin-middle-1-xl {
        margin-bottom: 0.25rem;
        margin-top: 0.25rem; } }
    @media (min-width: 1920px) {
      .has-margin-middle-1-xxl {
        margin-bottom: 0.25rem;
        margin-top: 0.25rem; } }
  .has-margin-middle-2 {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem; }
    @media (min-width: 0px) {
      .has-margin-middle-2-xs {
        margin-bottom: 0.5rem;
        margin-top: 0.5rem; } }
    @media (min-width: 480px) {
      .has-margin-middle-2-sm {
        margin-bottom: 0.5rem;
        margin-top: 0.5rem; } }
    @media (min-width: 720px) {
      .has-margin-middle-2-md {
        margin-bottom: 0.5rem;
        margin-top: 0.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-middle-2-nav {
        margin-bottom: 0.5rem;
        margin-top: 0.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-middle-2-lg {
        margin-bottom: 0.5rem;
        margin-top: 0.5rem; } }
    @media (min-width: 1280px) {
      .has-margin-middle-2-xl {
        margin-bottom: 0.5rem;
        margin-top: 0.5rem; } }
    @media (min-width: 1920px) {
      .has-margin-middle-2-xxl {
        margin-bottom: 0.5rem;
        margin-top: 0.5rem; } }
  .has-margin-middle-3 {
    margin-bottom: 1rem;
    margin-top: 1rem; }
    @media (min-width: 0px) {
      .has-margin-middle-3-xs {
        margin-bottom: 1rem;
        margin-top: 1rem; } }
    @media (min-width: 480px) {
      .has-margin-middle-3-sm {
        margin-bottom: 1rem;
        margin-top: 1rem; } }
    @media (min-width: 720px) {
      .has-margin-middle-3-md {
        margin-bottom: 1rem;
        margin-top: 1rem; } }
    @media (min-width: 1024px) {
      .has-margin-middle-3-nav {
        margin-bottom: 1rem;
        margin-top: 1rem; } }
    @media (min-width: 1024px) {
      .has-margin-middle-3-lg {
        margin-bottom: 1rem;
        margin-top: 1rem; } }
    @media (min-width: 1280px) {
      .has-margin-middle-3-xl {
        margin-bottom: 1rem;
        margin-top: 1rem; } }
    @media (min-width: 1920px) {
      .has-margin-middle-3-xxl {
        margin-bottom: 1rem;
        margin-top: 1rem; } }
  .has-margin-middle-4 {
    margin-bottom: 1.5rem;
    margin-top: 1.5rem; }
    @media (min-width: 0px) {
      .has-margin-middle-4-xs {
        margin-bottom: 1.5rem;
        margin-top: 1.5rem; } }
    @media (min-width: 480px) {
      .has-margin-middle-4-sm {
        margin-bottom: 1.5rem;
        margin-top: 1.5rem; } }
    @media (min-width: 720px) {
      .has-margin-middle-4-md {
        margin-bottom: 1.5rem;
        margin-top: 1.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-middle-4-nav {
        margin-bottom: 1.5rem;
        margin-top: 1.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-middle-4-lg {
        margin-bottom: 1.5rem;
        margin-top: 1.5rem; } }
    @media (min-width: 1280px) {
      .has-margin-middle-4-xl {
        margin-bottom: 1.5rem;
        margin-top: 1.5rem; } }
    @media (min-width: 1920px) {
      .has-margin-middle-4-xxl {
        margin-bottom: 1.5rem;
        margin-top: 1.5rem; } }
  .has-margin-middle-5 {
    margin-bottom: 2rem;
    margin-top: 2rem; }
    @media (min-width: 0px) {
      .has-margin-middle-5-xs {
        margin-bottom: 2rem;
        margin-top: 2rem; } }
    @media (min-width: 480px) {
      .has-margin-middle-5-sm {
        margin-bottom: 2rem;
        margin-top: 2rem; } }
    @media (min-width: 720px) {
      .has-margin-middle-5-md {
        margin-bottom: 2rem;
        margin-top: 2rem; } }
    @media (min-width: 1024px) {
      .has-margin-middle-5-nav {
        margin-bottom: 2rem;
        margin-top: 2rem; } }
    @media (min-width: 1024px) {
      .has-margin-middle-5-lg {
        margin-bottom: 2rem;
        margin-top: 2rem; } }
    @media (min-width: 1280px) {
      .has-margin-middle-5-xl {
        margin-bottom: 2rem;
        margin-top: 2rem; } }
    @media (min-width: 1920px) {
      .has-margin-middle-5-xxl {
        margin-bottom: 2rem;
        margin-top: 2rem; } }
  .has-margin-middle-6 {
    margin-bottom: 3rem;
    margin-top: 3rem; }
    @media (min-width: 0px) {
      .has-margin-middle-6-xs {
        margin-bottom: 3rem;
        margin-top: 3rem; } }
    @media (min-width: 480px) {
      .has-margin-middle-6-sm {
        margin-bottom: 3rem;
        margin-top: 3rem; } }
    @media (min-width: 720px) {
      .has-margin-middle-6-md {
        margin-bottom: 3rem;
        margin-top: 3rem; } }
    @media (min-width: 1024px) {
      .has-margin-middle-6-nav {
        margin-bottom: 3rem;
        margin-top: 3rem; } }
    @media (min-width: 1024px) {
      .has-margin-middle-6-lg {
        margin-bottom: 3rem;
        margin-top: 3rem; } }
    @media (min-width: 1280px) {
      .has-margin-middle-6-xl {
        margin-bottom: 3rem;
        margin-top: 3rem; } }
    @media (min-width: 1920px) {
      .has-margin-middle-6-xxl {
        margin-bottom: 3rem;
        margin-top: 3rem; } }
  .has-margin-middle-7 {
    margin-bottom: 4rem;
    margin-top: 4rem; }
    @media (min-width: 0px) {
      .has-margin-middle-7-xs {
        margin-bottom: 4rem;
        margin-top: 4rem; } }
    @media (min-width: 480px) {
      .has-margin-middle-7-sm {
        margin-bottom: 4rem;
        margin-top: 4rem; } }
    @media (min-width: 720px) {
      .has-margin-middle-7-md {
        margin-bottom: 4rem;
        margin-top: 4rem; } }
    @media (min-width: 1024px) {
      .has-margin-middle-7-nav {
        margin-bottom: 4rem;
        margin-top: 4rem; } }
    @media (min-width: 1024px) {
      .has-margin-middle-7-lg {
        margin-bottom: 4rem;
        margin-top: 4rem; } }
    @media (min-width: 1280px) {
      .has-margin-middle-7-xl {
        margin-bottom: 4rem;
        margin-top: 4rem; } }
    @media (min-width: 1920px) {
      .has-margin-middle-7-xxl {
        margin-bottom: 4rem;
        margin-top: 4rem; } }
  .has-margin-middle-8 {
    margin-bottom: 5rem;
    margin-top: 5rem; }
    @media (min-width: 0px) {
      .has-margin-middle-8-xs {
        margin-bottom: 5rem;
        margin-top: 5rem; } }
    @media (min-width: 480px) {
      .has-margin-middle-8-sm {
        margin-bottom: 5rem;
        margin-top: 5rem; } }
    @media (min-width: 720px) {
      .has-margin-middle-8-md {
        margin-bottom: 5rem;
        margin-top: 5rem; } }
    @media (min-width: 1024px) {
      .has-margin-middle-8-nav {
        margin-bottom: 5rem;
        margin-top: 5rem; } }
    @media (min-width: 1024px) {
      .has-margin-middle-8-lg {
        margin-bottom: 5rem;
        margin-top: 5rem; } }
    @media (min-width: 1280px) {
      .has-margin-middle-8-xl {
        margin-bottom: 5rem;
        margin-top: 5rem; } }
    @media (min-width: 1920px) {
      .has-margin-middle-8-xxl {
        margin-bottom: 5rem;
        margin-top: 5rem; } }

.has-margin-top {
  margin-top:  1rem; }
  @media (min-width: 0px) {
    .has-margin-top-xs {
      margin-top:  1rem; } }
  @media (min-width: 480px) {
    .has-margin-top-sm {
      margin-top:  1rem; } }
  @media (min-width: 720px) {
    .has-margin-top-md {
      margin-top:  1rem; } }
  @media (min-width: 1024px) {
    .has-margin-top-nav {
      margin-top:  1rem; } }
  @media (min-width: 1024px) {
    .has-margin-top-lg {
      margin-top:  1rem; } }
  @media (min-width: 1280px) {
    .has-margin-top-xl {
      margin-top:  1rem; } }
  @media (min-width: 1920px) {
    .has-margin-top-xxl {
      margin-top:  1rem; } }
  .has-margin-top-smallest {
    margin-top:  0.25rem; }
    @media (min-width: 0px) {
      .has-margin-top-smallest-xs {
        margin-top:  0.25rem; } }
    @media (min-width: 480px) {
      .has-margin-top-smallest-sm {
        margin-top:  0.25rem; } }
    @media (min-width: 720px) {
      .has-margin-top-smallest-md {
        margin-top:  0.25rem; } }
    @media (min-width: 1024px) {
      .has-margin-top-smallest-nav {
        margin-top:  0.25rem; } }
    @media (min-width: 1024px) {
      .has-margin-top-smallest-lg {
        margin-top:  0.25rem; } }
    @media (min-width: 1280px) {
      .has-margin-top-smallest-xl {
        margin-top:  0.25rem; } }
    @media (min-width: 1920px) {
      .has-margin-top-smallest-xxl {
        margin-top:  0.25rem; } }
  .has-margin-top-small {
    margin-top:  0.5rem; }
    @media (min-width: 0px) {
      .has-margin-top-small-xs {
        margin-top:  0.5rem; } }
    @media (min-width: 480px) {
      .has-margin-top-small-sm {
        margin-top:  0.5rem; } }
    @media (min-width: 720px) {
      .has-margin-top-small-md {
        margin-top:  0.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-top-small-nav {
        margin-top:  0.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-top-small-lg {
        margin-top:  0.5rem; } }
    @media (min-width: 1280px) {
      .has-margin-top-small-xl {
        margin-top:  0.5rem; } }
    @media (min-width: 1920px) {
      .has-margin-top-small-xxl {
        margin-top:  0.5rem; } }
  .has-margin-top-big {
    margin-top:  2rem; }
    @media (min-width: 0px) {
      .has-margin-top-big-xs {
        margin-top:  2rem; } }
    @media (min-width: 480px) {
      .has-margin-top-big-sm {
        margin-top:  2rem; } }
    @media (min-width: 720px) {
      .has-margin-top-big-md {
        margin-top:  2rem; } }
    @media (min-width: 1024px) {
      .has-margin-top-big-nav {
        margin-top:  2rem; } }
    @media (min-width: 1024px) {
      .has-margin-top-big-lg {
        margin-top:  2rem; } }
    @media (min-width: 1280px) {
      .has-margin-top-big-xl {
        margin-top:  2rem; } }
    @media (min-width: 1920px) {
      .has-margin-top-big-xxl {
        margin-top:  2rem; } }
  .has-margin-top-0 {
    margin-top: 0; }
    @media (min-width: 0px) {
      .has-margin-top-0-xs {
        margin-top: 0; } }
    @media (min-width: 480px) {
      .has-margin-top-0-sm {
        margin-top: 0; } }
    @media (min-width: 720px) {
      .has-margin-top-0-md {
        margin-top: 0; } }
    @media (min-width: 1024px) {
      .has-margin-top-0-nav {
        margin-top: 0; } }
    @media (min-width: 1024px) {
      .has-margin-top-0-lg {
        margin-top: 0; } }
    @media (min-width: 1280px) {
      .has-margin-top-0-xl {
        margin-top: 0; } }
    @media (min-width: 1920px) {
      .has-margin-top-0-xxl {
        margin-top: 0; } }
  .has-margin-top-1 {
    margin-top: 0.25rem; }
    @media (min-width: 0px) {
      .has-margin-top-1-xs {
        margin-top: 0.25rem; } }
    @media (min-width: 480px) {
      .has-margin-top-1-sm {
        margin-top: 0.25rem; } }
    @media (min-width: 720px) {
      .has-margin-top-1-md {
        margin-top: 0.25rem; } }
    @media (min-width: 1024px) {
      .has-margin-top-1-nav {
        margin-top: 0.25rem; } }
    @media (min-width: 1024px) {
      .has-margin-top-1-lg {
        margin-top: 0.25rem; } }
    @media (min-width: 1280px) {
      .has-margin-top-1-xl {
        margin-top: 0.25rem; } }
    @media (min-width: 1920px) {
      .has-margin-top-1-xxl {
        margin-top: 0.25rem; } }
  .has-margin-top-2 {
    margin-top: 0.5rem; }
    @media (min-width: 0px) {
      .has-margin-top-2-xs {
        margin-top: 0.5rem; } }
    @media (min-width: 480px) {
      .has-margin-top-2-sm {
        margin-top: 0.5rem; } }
    @media (min-width: 720px) {
      .has-margin-top-2-md {
        margin-top: 0.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-top-2-nav {
        margin-top: 0.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-top-2-lg {
        margin-top: 0.5rem; } }
    @media (min-width: 1280px) {
      .has-margin-top-2-xl {
        margin-top: 0.5rem; } }
    @media (min-width: 1920px) {
      .has-margin-top-2-xxl {
        margin-top: 0.5rem; } }
  .has-margin-top-3 {
    margin-top: 1rem; }
    @media (min-width: 0px) {
      .has-margin-top-3-xs {
        margin-top: 1rem; } }
    @media (min-width: 480px) {
      .has-margin-top-3-sm {
        margin-top: 1rem; } }
    @media (min-width: 720px) {
      .has-margin-top-3-md {
        margin-top: 1rem; } }
    @media (min-width: 1024px) {
      .has-margin-top-3-nav {
        margin-top: 1rem; } }
    @media (min-width: 1024px) {
      .has-margin-top-3-lg {
        margin-top: 1rem; } }
    @media (min-width: 1280px) {
      .has-margin-top-3-xl {
        margin-top: 1rem; } }
    @media (min-width: 1920px) {
      .has-margin-top-3-xxl {
        margin-top: 1rem; } }
  .has-margin-top-4 {
    margin-top: 1.5rem; }
    @media (min-width: 0px) {
      .has-margin-top-4-xs {
        margin-top: 1.5rem; } }
    @media (min-width: 480px) {
      .has-margin-top-4-sm {
        margin-top: 1.5rem; } }
    @media (min-width: 720px) {
      .has-margin-top-4-md {
        margin-top: 1.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-top-4-nav {
        margin-top: 1.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-top-4-lg {
        margin-top: 1.5rem; } }
    @media (min-width: 1280px) {
      .has-margin-top-4-xl {
        margin-top: 1.5rem; } }
    @media (min-width: 1920px) {
      .has-margin-top-4-xxl {
        margin-top: 1.5rem; } }
  .has-margin-top-5 {
    margin-top: 2rem; }
    @media (min-width: 0px) {
      .has-margin-top-5-xs {
        margin-top: 2rem; } }
    @media (min-width: 480px) {
      .has-margin-top-5-sm {
        margin-top: 2rem; } }
    @media (min-width: 720px) {
      .has-margin-top-5-md {
        margin-top: 2rem; } }
    @media (min-width: 1024px) {
      .has-margin-top-5-nav {
        margin-top: 2rem; } }
    @media (min-width: 1024px) {
      .has-margin-top-5-lg {
        margin-top: 2rem; } }
    @media (min-width: 1280px) {
      .has-margin-top-5-xl {
        margin-top: 2rem; } }
    @media (min-width: 1920px) {
      .has-margin-top-5-xxl {
        margin-top: 2rem; } }
  .has-margin-top-6 {
    margin-top: 3rem; }
    @media (min-width: 0px) {
      .has-margin-top-6-xs {
        margin-top: 3rem; } }
    @media (min-width: 480px) {
      .has-margin-top-6-sm {
        margin-top: 3rem; } }
    @media (min-width: 720px) {
      .has-margin-top-6-md {
        margin-top: 3rem; } }
    @media (min-width: 1024px) {
      .has-margin-top-6-nav {
        margin-top: 3rem; } }
    @media (min-width: 1024px) {
      .has-margin-top-6-lg {
        margin-top: 3rem; } }
    @media (min-width: 1280px) {
      .has-margin-top-6-xl {
        margin-top: 3rem; } }
    @media (min-width: 1920px) {
      .has-margin-top-6-xxl {
        margin-top: 3rem; } }
  .has-margin-top-7 {
    margin-top: 4rem; }
    @media (min-width: 0px) {
      .has-margin-top-7-xs {
        margin-top: 4rem; } }
    @media (min-width: 480px) {
      .has-margin-top-7-sm {
        margin-top: 4rem; } }
    @media (min-width: 720px) {
      .has-margin-top-7-md {
        margin-top: 4rem; } }
    @media (min-width: 1024px) {
      .has-margin-top-7-nav {
        margin-top: 4rem; } }
    @media (min-width: 1024px) {
      .has-margin-top-7-lg {
        margin-top: 4rem; } }
    @media (min-width: 1280px) {
      .has-margin-top-7-xl {
        margin-top: 4rem; } }
    @media (min-width: 1920px) {
      .has-margin-top-7-xxl {
        margin-top: 4rem; } }
  .has-margin-top-8 {
    margin-top: 5rem; }
    @media (min-width: 0px) {
      .has-margin-top-8-xs {
        margin-top: 5rem; } }
    @media (min-width: 480px) {
      .has-margin-top-8-sm {
        margin-top: 5rem; } }
    @media (min-width: 720px) {
      .has-margin-top-8-md {
        margin-top: 5rem; } }
    @media (min-width: 1024px) {
      .has-margin-top-8-nav {
        margin-top: 5rem; } }
    @media (min-width: 1024px) {
      .has-margin-top-8-lg {
        margin-top: 5rem; } }
    @media (min-width: 1280px) {
      .has-margin-top-8-xl {
        margin-top: 5rem; } }
    @media (min-width: 1920px) {
      .has-margin-top-8-xxl {
        margin-top: 5rem; } }

.has-margin-bottom {
  margin-bottom:  1rem; }
  @media (min-width: 0px) {
    .has-margin-bottom-xs {
      margin-bottom:  1rem; } }
  @media (min-width: 480px) {
    .has-margin-bottom-sm {
      margin-bottom:  1rem; } }
  @media (min-width: 720px) {
    .has-margin-bottom-md {
      margin-bottom:  1rem; } }
  @media (min-width: 1024px) {
    .has-margin-bottom-nav {
      margin-bottom:  1rem; } }
  @media (min-width: 1024px) {
    .has-margin-bottom-lg {
      margin-bottom:  1rem; } }
  @media (min-width: 1280px) {
    .has-margin-bottom-xl {
      margin-bottom:  1rem; } }
  @media (min-width: 1920px) {
    .has-margin-bottom-xxl {
      margin-bottom:  1rem; } }
  .has-margin-bottom-smallest {
    margin-bottom:  0.25rem; }
    @media (min-width: 0px) {
      .has-margin-bottom-smallest-xs {
        margin-bottom:  0.25rem; } }
    @media (min-width: 480px) {
      .has-margin-bottom-smallest-sm {
        margin-bottom:  0.25rem; } }
    @media (min-width: 720px) {
      .has-margin-bottom-smallest-md {
        margin-bottom:  0.25rem; } }
    @media (min-width: 1024px) {
      .has-margin-bottom-smallest-nav {
        margin-bottom:  0.25rem; } }
    @media (min-width: 1024px) {
      .has-margin-bottom-smallest-lg {
        margin-bottom:  0.25rem; } }
    @media (min-width: 1280px) {
      .has-margin-bottom-smallest-xl {
        margin-bottom:  0.25rem; } }
    @media (min-width: 1920px) {
      .has-margin-bottom-smallest-xxl {
        margin-bottom:  0.25rem; } }
  .has-margin-bottom-small {
    margin-bottom:  0.5rem; }
    @media (min-width: 0px) {
      .has-margin-bottom-small-xs {
        margin-bottom:  0.5rem; } }
    @media (min-width: 480px) {
      .has-margin-bottom-small-sm {
        margin-bottom:  0.5rem; } }
    @media (min-width: 720px) {
      .has-margin-bottom-small-md {
        margin-bottom:  0.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-bottom-small-nav {
        margin-bottom:  0.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-bottom-small-lg {
        margin-bottom:  0.5rem; } }
    @media (min-width: 1280px) {
      .has-margin-bottom-small-xl {
        margin-bottom:  0.5rem; } }
    @media (min-width: 1920px) {
      .has-margin-bottom-small-xxl {
        margin-bottom:  0.5rem; } }
  .has-margin-bottom-big {
    margin-bottom:  2rem; }
    @media (min-width: 0px) {
      .has-margin-bottom-big-xs {
        margin-bottom:  2rem; } }
    @media (min-width: 480px) {
      .has-margin-bottom-big-sm {
        margin-bottom:  2rem; } }
    @media (min-width: 720px) {
      .has-margin-bottom-big-md {
        margin-bottom:  2rem; } }
    @media (min-width: 1024px) {
      .has-margin-bottom-big-nav {
        margin-bottom:  2rem; } }
    @media (min-width: 1024px) {
      .has-margin-bottom-big-lg {
        margin-bottom:  2rem; } }
    @media (min-width: 1280px) {
      .has-margin-bottom-big-xl {
        margin-bottom:  2rem; } }
    @media (min-width: 1920px) {
      .has-margin-bottom-big-xxl {
        margin-bottom:  2rem; } }
  .has-margin-bottom-0 {
    margin-bottom: 0; }
    @media (min-width: 0px) {
      .has-margin-bottom-0-xs {
        margin-bottom: 0; } }
    @media (min-width: 480px) {
      .has-margin-bottom-0-sm {
        margin-bottom: 0; } }
    @media (min-width: 720px) {
      .has-margin-bottom-0-md {
        margin-bottom: 0; } }
    @media (min-width: 1024px) {
      .has-margin-bottom-0-nav {
        margin-bottom: 0; } }
    @media (min-width: 1024px) {
      .has-margin-bottom-0-lg {
        margin-bottom: 0; } }
    @media (min-width: 1280px) {
      .has-margin-bottom-0-xl {
        margin-bottom: 0; } }
    @media (min-width: 1920px) {
      .has-margin-bottom-0-xxl {
        margin-bottom: 0; } }
  .has-margin-bottom-1 {
    margin-bottom: 0.25rem; }
    @media (min-width: 0px) {
      .has-margin-bottom-1-xs {
        margin-bottom: 0.25rem; } }
    @media (min-width: 480px) {
      .has-margin-bottom-1-sm {
        margin-bottom: 0.25rem; } }
    @media (min-width: 720px) {
      .has-margin-bottom-1-md {
        margin-bottom: 0.25rem; } }
    @media (min-width: 1024px) {
      .has-margin-bottom-1-nav {
        margin-bottom: 0.25rem; } }
    @media (min-width: 1024px) {
      .has-margin-bottom-1-lg {
        margin-bottom: 0.25rem; } }
    @media (min-width: 1280px) {
      .has-margin-bottom-1-xl {
        margin-bottom: 0.25rem; } }
    @media (min-width: 1920px) {
      .has-margin-bottom-1-xxl {
        margin-bottom: 0.25rem; } }
  .has-margin-bottom-2 {
    margin-bottom: 0.5rem; }
    @media (min-width: 0px) {
      .has-margin-bottom-2-xs {
        margin-bottom: 0.5rem; } }
    @media (min-width: 480px) {
      .has-margin-bottom-2-sm {
        margin-bottom: 0.5rem; } }
    @media (min-width: 720px) {
      .has-margin-bottom-2-md {
        margin-bottom: 0.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-bottom-2-nav {
        margin-bottom: 0.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-bottom-2-lg {
        margin-bottom: 0.5rem; } }
    @media (min-width: 1280px) {
      .has-margin-bottom-2-xl {
        margin-bottom: 0.5rem; } }
    @media (min-width: 1920px) {
      .has-margin-bottom-2-xxl {
        margin-bottom: 0.5rem; } }
  .has-margin-bottom-3 {
    margin-bottom: 1rem; }
    @media (min-width: 0px) {
      .has-margin-bottom-3-xs {
        margin-bottom: 1rem; } }
    @media (min-width: 480px) {
      .has-margin-bottom-3-sm {
        margin-bottom: 1rem; } }
    @media (min-width: 720px) {
      .has-margin-bottom-3-md {
        margin-bottom: 1rem; } }
    @media (min-width: 1024px) {
      .has-margin-bottom-3-nav {
        margin-bottom: 1rem; } }
    @media (min-width: 1024px) {
      .has-margin-bottom-3-lg {
        margin-bottom: 1rem; } }
    @media (min-width: 1280px) {
      .has-margin-bottom-3-xl {
        margin-bottom: 1rem; } }
    @media (min-width: 1920px) {
      .has-margin-bottom-3-xxl {
        margin-bottom: 1rem; } }
  .has-margin-bottom-4 {
    margin-bottom: 1.5rem; }
    @media (min-width: 0px) {
      .has-margin-bottom-4-xs {
        margin-bottom: 1.5rem; } }
    @media (min-width: 480px) {
      .has-margin-bottom-4-sm {
        margin-bottom: 1.5rem; } }
    @media (min-width: 720px) {
      .has-margin-bottom-4-md {
        margin-bottom: 1.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-bottom-4-nav {
        margin-bottom: 1.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-bottom-4-lg {
        margin-bottom: 1.5rem; } }
    @media (min-width: 1280px) {
      .has-margin-bottom-4-xl {
        margin-bottom: 1.5rem; } }
    @media (min-width: 1920px) {
      .has-margin-bottom-4-xxl {
        margin-bottom: 1.5rem; } }
  .has-margin-bottom-5 {
    margin-bottom: 2rem; }
    @media (min-width: 0px) {
      .has-margin-bottom-5-xs {
        margin-bottom: 2rem; } }
    @media (min-width: 480px) {
      .has-margin-bottom-5-sm {
        margin-bottom: 2rem; } }
    @media (min-width: 720px) {
      .has-margin-bottom-5-md {
        margin-bottom: 2rem; } }
    @media (min-width: 1024px) {
      .has-margin-bottom-5-nav {
        margin-bottom: 2rem; } }
    @media (min-width: 1024px) {
      .has-margin-bottom-5-lg {
        margin-bottom: 2rem; } }
    @media (min-width: 1280px) {
      .has-margin-bottom-5-xl {
        margin-bottom: 2rem; } }
    @media (min-width: 1920px) {
      .has-margin-bottom-5-xxl {
        margin-bottom: 2rem; } }
  .has-margin-bottom-6 {
    margin-bottom: 3rem; }
    @media (min-width: 0px) {
      .has-margin-bottom-6-xs {
        margin-bottom: 3rem; } }
    @media (min-width: 480px) {
      .has-margin-bottom-6-sm {
        margin-bottom: 3rem; } }
    @media (min-width: 720px) {
      .has-margin-bottom-6-md {
        margin-bottom: 3rem; } }
    @media (min-width: 1024px) {
      .has-margin-bottom-6-nav {
        margin-bottom: 3rem; } }
    @media (min-width: 1024px) {
      .has-margin-bottom-6-lg {
        margin-bottom: 3rem; } }
    @media (min-width: 1280px) {
      .has-margin-bottom-6-xl {
        margin-bottom: 3rem; } }
    @media (min-width: 1920px) {
      .has-margin-bottom-6-xxl {
        margin-bottom: 3rem; } }
  .has-margin-bottom-7 {
    margin-bottom: 4rem; }
    @media (min-width: 0px) {
      .has-margin-bottom-7-xs {
        margin-bottom: 4rem; } }
    @media (min-width: 480px) {
      .has-margin-bottom-7-sm {
        margin-bottom: 4rem; } }
    @media (min-width: 720px) {
      .has-margin-bottom-7-md {
        margin-bottom: 4rem; } }
    @media (min-width: 1024px) {
      .has-margin-bottom-7-nav {
        margin-bottom: 4rem; } }
    @media (min-width: 1024px) {
      .has-margin-bottom-7-lg {
        margin-bottom: 4rem; } }
    @media (min-width: 1280px) {
      .has-margin-bottom-7-xl {
        margin-bottom: 4rem; } }
    @media (min-width: 1920px) {
      .has-margin-bottom-7-xxl {
        margin-bottom: 4rem; } }
  .has-margin-bottom-8 {
    margin-bottom: 5rem; }
    @media (min-width: 0px) {
      .has-margin-bottom-8-xs {
        margin-bottom: 5rem; } }
    @media (min-width: 480px) {
      .has-margin-bottom-8-sm {
        margin-bottom: 5rem; } }
    @media (min-width: 720px) {
      .has-margin-bottom-8-md {
        margin-bottom: 5rem; } }
    @media (min-width: 1024px) {
      .has-margin-bottom-8-nav {
        margin-bottom: 5rem; } }
    @media (min-width: 1024px) {
      .has-margin-bottom-8-lg {
        margin-bottom: 5rem; } }
    @media (min-width: 1280px) {
      .has-margin-bottom-8-xl {
        margin-bottom: 5rem; } }
    @media (min-width: 1920px) {
      .has-margin-bottom-8-xxl {
        margin-bottom: 5rem; } }

.has-margin-center {
  margin-left:  1rem;
  margin-right:  1rem; }
  @media (min-width: 0px) {
    .has-margin-center-xs {
      margin-left:  1rem;
      margin-right:  1rem; } }
  @media (min-width: 480px) {
    .has-margin-center-sm {
      margin-left:  1rem;
      margin-right:  1rem; } }
  @media (min-width: 720px) {
    .has-margin-center-md {
      margin-left:  1rem;
      margin-right:  1rem; } }
  @media (min-width: 1024px) {
    .has-margin-center-nav {
      margin-left:  1rem;
      margin-right:  1rem; } }
  @media (min-width: 1024px) {
    .has-margin-center-lg {
      margin-left:  1rem;
      margin-right:  1rem; } }
  @media (min-width: 1280px) {
    .has-margin-center-xl {
      margin-left:  1rem;
      margin-right:  1rem; } }
  @media (min-width: 1920px) {
    .has-margin-center-xxl {
      margin-left:  1rem;
      margin-right:  1rem; } }
  .has-margin-center-smallest {
    margin-left:  0.25rem;
    margin-right:  0.25rem; }
    @media (min-width: 0px) {
      .has-margin-center-smallest-xs {
        margin-left:  0.25rem;
        margin-right:  0.25rem; } }
    @media (min-width: 480px) {
      .has-margin-center-smallest-sm {
        margin-left:  0.25rem;
        margin-right:  0.25rem; } }
    @media (min-width: 720px) {
      .has-margin-center-smallest-md {
        margin-left:  0.25rem;
        margin-right:  0.25rem; } }
    @media (min-width: 1024px) {
      .has-margin-center-smallest-nav {
        margin-left:  0.25rem;
        margin-right:  0.25rem; } }
    @media (min-width: 1024px) {
      .has-margin-center-smallest-lg {
        margin-left:  0.25rem;
        margin-right:  0.25rem; } }
    @media (min-width: 1280px) {
      .has-margin-center-smallest-xl {
        margin-left:  0.25rem;
        margin-right:  0.25rem; } }
    @media (min-width: 1920px) {
      .has-margin-center-smallest-xxl {
        margin-left:  0.25rem;
        margin-right:  0.25rem; } }
  .has-margin-center-small {
    margin-left:  0.5rem;
    margin-right:  0.5rem; }
    @media (min-width: 0px) {
      .has-margin-center-small-xs {
        margin-left:  0.5rem;
        margin-right:  0.5rem; } }
    @media (min-width: 480px) {
      .has-margin-center-small-sm {
        margin-left:  0.5rem;
        margin-right:  0.5rem; } }
    @media (min-width: 720px) {
      .has-margin-center-small-md {
        margin-left:  0.5rem;
        margin-right:  0.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-center-small-nav {
        margin-left:  0.5rem;
        margin-right:  0.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-center-small-lg {
        margin-left:  0.5rem;
        margin-right:  0.5rem; } }
    @media (min-width: 1280px) {
      .has-margin-center-small-xl {
        margin-left:  0.5rem;
        margin-right:  0.5rem; } }
    @media (min-width: 1920px) {
      .has-margin-center-small-xxl {
        margin-left:  0.5rem;
        margin-right:  0.5rem; } }
  .has-margin-center-big {
    margin-left:  2rem;
    margin-right:  2rem; }
    @media (min-width: 0px) {
      .has-margin-center-big-xs {
        margin-left:  2rem;
        margin-right:  2rem; } }
    @media (min-width: 480px) {
      .has-margin-center-big-sm {
        margin-left:  2rem;
        margin-right:  2rem; } }
    @media (min-width: 720px) {
      .has-margin-center-big-md {
        margin-left:  2rem;
        margin-right:  2rem; } }
    @media (min-width: 1024px) {
      .has-margin-center-big-nav {
        margin-left:  2rem;
        margin-right:  2rem; } }
    @media (min-width: 1024px) {
      .has-margin-center-big-lg {
        margin-left:  2rem;
        margin-right:  2rem; } }
    @media (min-width: 1280px) {
      .has-margin-center-big-xl {
        margin-left:  2rem;
        margin-right:  2rem; } }
    @media (min-width: 1920px) {
      .has-margin-center-big-xxl {
        margin-left:  2rem;
        margin-right:  2rem; } }
  .has-margin-center-auto {
    margin-left: auto;
    margin-right: auto; }
    @media (min-width: 0px) {
      .has-margin-center-auto-xs {
        margin-left: auto;
        margin-right: auto; } }
    @media (min-width: 480px) {
      .has-margin-center-auto-sm {
        margin-left: auto;
        margin-right: auto; } }
    @media (min-width: 720px) {
      .has-margin-center-auto-md {
        margin-left: auto;
        margin-right: auto; } }
    @media (min-width: 1024px) {
      .has-margin-center-auto-nav {
        margin-left: auto;
        margin-right: auto; } }
    @media (min-width: 1024px) {
      .has-margin-center-auto-lg {
        margin-left: auto;
        margin-right: auto; } }
    @media (min-width: 1280px) {
      .has-margin-center-auto-xl {
        margin-left: auto;
        margin-right: auto; } }
    @media (min-width: 1920px) {
      .has-margin-center-auto-xxl {
        margin-left: auto;
        margin-right: auto; } }
  .has-margin-center-0 {
    margin-left: 0;
    margin-right: 0; }
    @media (min-width: 0px) {
      .has-margin-center-0-xs {
        margin-left: 0;
        margin-right: 0; } }
    @media (min-width: 480px) {
      .has-margin-center-0-sm {
        margin-left: 0;
        margin-right: 0; } }
    @media (min-width: 720px) {
      .has-margin-center-0-md {
        margin-left: 0;
        margin-right: 0; } }
    @media (min-width: 1024px) {
      .has-margin-center-0-nav {
        margin-left: 0;
        margin-right: 0; } }
    @media (min-width: 1024px) {
      .has-margin-center-0-lg {
        margin-left: 0;
        margin-right: 0; } }
    @media (min-width: 1280px) {
      .has-margin-center-0-xl {
        margin-left: 0;
        margin-right: 0; } }
    @media (min-width: 1920px) {
      .has-margin-center-0-xxl {
        margin-left: 0;
        margin-right: 0; } }
  .has-margin-center-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }
    @media (min-width: 0px) {
      .has-margin-center-1-xs {
        margin-left: 0.25rem;
        margin-right: 0.25rem; } }
    @media (min-width: 480px) {
      .has-margin-center-1-sm {
        margin-left: 0.25rem;
        margin-right: 0.25rem; } }
    @media (min-width: 720px) {
      .has-margin-center-1-md {
        margin-left: 0.25rem;
        margin-right: 0.25rem; } }
    @media (min-width: 1024px) {
      .has-margin-center-1-nav {
        margin-left: 0.25rem;
        margin-right: 0.25rem; } }
    @media (min-width: 1024px) {
      .has-margin-center-1-lg {
        margin-left: 0.25rem;
        margin-right: 0.25rem; } }
    @media (min-width: 1280px) {
      .has-margin-center-1-xl {
        margin-left: 0.25rem;
        margin-right: 0.25rem; } }
    @media (min-width: 1920px) {
      .has-margin-center-1-xxl {
        margin-left: 0.25rem;
        margin-right: 0.25rem; } }
  .has-margin-center-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem; }
    @media (min-width: 0px) {
      .has-margin-center-2-xs {
        margin-left: 0.5rem;
        margin-right: 0.5rem; } }
    @media (min-width: 480px) {
      .has-margin-center-2-sm {
        margin-left: 0.5rem;
        margin-right: 0.5rem; } }
    @media (min-width: 720px) {
      .has-margin-center-2-md {
        margin-left: 0.5rem;
        margin-right: 0.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-center-2-nav {
        margin-left: 0.5rem;
        margin-right: 0.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-center-2-lg {
        margin-left: 0.5rem;
        margin-right: 0.5rem; } }
    @media (min-width: 1280px) {
      .has-margin-center-2-xl {
        margin-left: 0.5rem;
        margin-right: 0.5rem; } }
    @media (min-width: 1920px) {
      .has-margin-center-2-xxl {
        margin-left: 0.5rem;
        margin-right: 0.5rem; } }
  .has-margin-center-3 {
    margin-left: 1rem;
    margin-right: 1rem; }
    @media (min-width: 0px) {
      .has-margin-center-3-xs {
        margin-left: 1rem;
        margin-right: 1rem; } }
    @media (min-width: 480px) {
      .has-margin-center-3-sm {
        margin-left: 1rem;
        margin-right: 1rem; } }
    @media (min-width: 720px) {
      .has-margin-center-3-md {
        margin-left: 1rem;
        margin-right: 1rem; } }
    @media (min-width: 1024px) {
      .has-margin-center-3-nav {
        margin-left: 1rem;
        margin-right: 1rem; } }
    @media (min-width: 1024px) {
      .has-margin-center-3-lg {
        margin-left: 1rem;
        margin-right: 1rem; } }
    @media (min-width: 1280px) {
      .has-margin-center-3-xl {
        margin-left: 1rem;
        margin-right: 1rem; } }
    @media (min-width: 1920px) {
      .has-margin-center-3-xxl {
        margin-left: 1rem;
        margin-right: 1rem; } }
  .has-margin-center-4 {
    margin-left: 1.5rem;
    margin-right: 1.5rem; }
    @media (min-width: 0px) {
      .has-margin-center-4-xs {
        margin-left: 1.5rem;
        margin-right: 1.5rem; } }
    @media (min-width: 480px) {
      .has-margin-center-4-sm {
        margin-left: 1.5rem;
        margin-right: 1.5rem; } }
    @media (min-width: 720px) {
      .has-margin-center-4-md {
        margin-left: 1.5rem;
        margin-right: 1.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-center-4-nav {
        margin-left: 1.5rem;
        margin-right: 1.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-center-4-lg {
        margin-left: 1.5rem;
        margin-right: 1.5rem; } }
    @media (min-width: 1280px) {
      .has-margin-center-4-xl {
        margin-left: 1.5rem;
        margin-right: 1.5rem; } }
    @media (min-width: 1920px) {
      .has-margin-center-4-xxl {
        margin-left: 1.5rem;
        margin-right: 1.5rem; } }
  .has-margin-center-5 {
    margin-left: 2rem;
    margin-right: 2rem; }
    @media (min-width: 0px) {
      .has-margin-center-5-xs {
        margin-left: 2rem;
        margin-right: 2rem; } }
    @media (min-width: 480px) {
      .has-margin-center-5-sm {
        margin-left: 2rem;
        margin-right: 2rem; } }
    @media (min-width: 720px) {
      .has-margin-center-5-md {
        margin-left: 2rem;
        margin-right: 2rem; } }
    @media (min-width: 1024px) {
      .has-margin-center-5-nav {
        margin-left: 2rem;
        margin-right: 2rem; } }
    @media (min-width: 1024px) {
      .has-margin-center-5-lg {
        margin-left: 2rem;
        margin-right: 2rem; } }
    @media (min-width: 1280px) {
      .has-margin-center-5-xl {
        margin-left: 2rem;
        margin-right: 2rem; } }
    @media (min-width: 1920px) {
      .has-margin-center-5-xxl {
        margin-left: 2rem;
        margin-right: 2rem; } }
  .has-margin-center-6 {
    margin-left: 3rem;
    margin-right: 3rem; }
    @media (min-width: 0px) {
      .has-margin-center-6-xs {
        margin-left: 3rem;
        margin-right: 3rem; } }
    @media (min-width: 480px) {
      .has-margin-center-6-sm {
        margin-left: 3rem;
        margin-right: 3rem; } }
    @media (min-width: 720px) {
      .has-margin-center-6-md {
        margin-left: 3rem;
        margin-right: 3rem; } }
    @media (min-width: 1024px) {
      .has-margin-center-6-nav {
        margin-left: 3rem;
        margin-right: 3rem; } }
    @media (min-width: 1024px) {
      .has-margin-center-6-lg {
        margin-left: 3rem;
        margin-right: 3rem; } }
    @media (min-width: 1280px) {
      .has-margin-center-6-xl {
        margin-left: 3rem;
        margin-right: 3rem; } }
    @media (min-width: 1920px) {
      .has-margin-center-6-xxl {
        margin-left: 3rem;
        margin-right: 3rem; } }
  .has-margin-center-7 {
    margin-left: 4rem;
    margin-right: 4rem; }
    @media (min-width: 0px) {
      .has-margin-center-7-xs {
        margin-left: 4rem;
        margin-right: 4rem; } }
    @media (min-width: 480px) {
      .has-margin-center-7-sm {
        margin-left: 4rem;
        margin-right: 4rem; } }
    @media (min-width: 720px) {
      .has-margin-center-7-md {
        margin-left: 4rem;
        margin-right: 4rem; } }
    @media (min-width: 1024px) {
      .has-margin-center-7-nav {
        margin-left: 4rem;
        margin-right: 4rem; } }
    @media (min-width: 1024px) {
      .has-margin-center-7-lg {
        margin-left: 4rem;
        margin-right: 4rem; } }
    @media (min-width: 1280px) {
      .has-margin-center-7-xl {
        margin-left: 4rem;
        margin-right: 4rem; } }
    @media (min-width: 1920px) {
      .has-margin-center-7-xxl {
        margin-left: 4rem;
        margin-right: 4rem; } }
  .has-margin-center-8 {
    margin-left: 5rem;
    margin-right: 5rem; }
    @media (min-width: 0px) {
      .has-margin-center-8-xs {
        margin-left: 5rem;
        margin-right: 5rem; } }
    @media (min-width: 480px) {
      .has-margin-center-8-sm {
        margin-left: 5rem;
        margin-right: 5rem; } }
    @media (min-width: 720px) {
      .has-margin-center-8-md {
        margin-left: 5rem;
        margin-right: 5rem; } }
    @media (min-width: 1024px) {
      .has-margin-center-8-nav {
        margin-left: 5rem;
        margin-right: 5rem; } }
    @media (min-width: 1024px) {
      .has-margin-center-8-lg {
        margin-left: 5rem;
        margin-right: 5rem; } }
    @media (min-width: 1280px) {
      .has-margin-center-8-xl {
        margin-left: 5rem;
        margin-right: 5rem; } }
    @media (min-width: 1920px) {
      .has-margin-center-8-xxl {
        margin-left: 5rem;
        margin-right: 5rem; } }

.has-margin-left {
  margin-left:  1rem; }
  @media (min-width: 0px) {
    .has-margin-left-xs {
      margin-left:  1rem; } }
  @media (min-width: 480px) {
    .has-margin-left-sm {
      margin-left:  1rem; } }
  @media (min-width: 720px) {
    .has-margin-left-md {
      margin-left:  1rem; } }
  @media (min-width: 1024px) {
    .has-margin-left-nav {
      margin-left:  1rem; } }
  @media (min-width: 1024px) {
    .has-margin-left-lg {
      margin-left:  1rem; } }
  @media (min-width: 1280px) {
    .has-margin-left-xl {
      margin-left:  1rem; } }
  @media (min-width: 1920px) {
    .has-margin-left-xxl {
      margin-left:  1rem; } }
  .has-margin-left-smallest {
    margin-left:  0.25rem; }
    @media (min-width: 0px) {
      .has-margin-left-smallest-xs {
        margin-left:  0.25rem; } }
    @media (min-width: 480px) {
      .has-margin-left-smallest-sm {
        margin-left:  0.25rem; } }
    @media (min-width: 720px) {
      .has-margin-left-smallest-md {
        margin-left:  0.25rem; } }
    @media (min-width: 1024px) {
      .has-margin-left-smallest-nav {
        margin-left:  0.25rem; } }
    @media (min-width: 1024px) {
      .has-margin-left-smallest-lg {
        margin-left:  0.25rem; } }
    @media (min-width: 1280px) {
      .has-margin-left-smallest-xl {
        margin-left:  0.25rem; } }
    @media (min-width: 1920px) {
      .has-margin-left-smallest-xxl {
        margin-left:  0.25rem; } }
  .has-margin-left-small {
    margin-left:  0.5rem; }
    @media (min-width: 0px) {
      .has-margin-left-small-xs {
        margin-left:  0.5rem; } }
    @media (min-width: 480px) {
      .has-margin-left-small-sm {
        margin-left:  0.5rem; } }
    @media (min-width: 720px) {
      .has-margin-left-small-md {
        margin-left:  0.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-left-small-nav {
        margin-left:  0.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-left-small-lg {
        margin-left:  0.5rem; } }
    @media (min-width: 1280px) {
      .has-margin-left-small-xl {
        margin-left:  0.5rem; } }
    @media (min-width: 1920px) {
      .has-margin-left-small-xxl {
        margin-left:  0.5rem; } }
  .has-margin-left-big {
    margin-left:  2rem; }
    @media (min-width: 0px) {
      .has-margin-left-big-xs {
        margin-left:  2rem; } }
    @media (min-width: 480px) {
      .has-margin-left-big-sm {
        margin-left:  2rem; } }
    @media (min-width: 720px) {
      .has-margin-left-big-md {
        margin-left:  2rem; } }
    @media (min-width: 1024px) {
      .has-margin-left-big-nav {
        margin-left:  2rem; } }
    @media (min-width: 1024px) {
      .has-margin-left-big-lg {
        margin-left:  2rem; } }
    @media (min-width: 1280px) {
      .has-margin-left-big-xl {
        margin-left:  2rem; } }
    @media (min-width: 1920px) {
      .has-margin-left-big-xxl {
        margin-left:  2rem; } }
  .has-margin-left-auto {
    margin-left: auto; }
    @media (min-width: 0px) {
      .has-margin-left-auto-xs {
        margin-left: auto; } }
    @media (min-width: 480px) {
      .has-margin-left-auto-sm {
        margin-left: auto; } }
    @media (min-width: 720px) {
      .has-margin-left-auto-md {
        margin-left: auto; } }
    @media (min-width: 1024px) {
      .has-margin-left-auto-nav {
        margin-left: auto; } }
    @media (min-width: 1024px) {
      .has-margin-left-auto-lg {
        margin-left: auto; } }
    @media (min-width: 1280px) {
      .has-margin-left-auto-xl {
        margin-left: auto; } }
    @media (min-width: 1920px) {
      .has-margin-left-auto-xxl {
        margin-left: auto; } }
  .has-margin-left-0 {
    margin-left: 0; }
    @media (min-width: 0px) {
      .has-margin-left-0-xs {
        margin-left: 0; } }
    @media (min-width: 480px) {
      .has-margin-left-0-sm {
        margin-left: 0; } }
    @media (min-width: 720px) {
      .has-margin-left-0-md {
        margin-left: 0; } }
    @media (min-width: 1024px) {
      .has-margin-left-0-nav {
        margin-left: 0; } }
    @media (min-width: 1024px) {
      .has-margin-left-0-lg {
        margin-left: 0; } }
    @media (min-width: 1280px) {
      .has-margin-left-0-xl {
        margin-left: 0; } }
    @media (min-width: 1920px) {
      .has-margin-left-0-xxl {
        margin-left: 0; } }
  .has-margin-left-1 {
    margin-left: 0.25rem; }
    @media (min-width: 0px) {
      .has-margin-left-1-xs {
        margin-left: 0.25rem; } }
    @media (min-width: 480px) {
      .has-margin-left-1-sm {
        margin-left: 0.25rem; } }
    @media (min-width: 720px) {
      .has-margin-left-1-md {
        margin-left: 0.25rem; } }
    @media (min-width: 1024px) {
      .has-margin-left-1-nav {
        margin-left: 0.25rem; } }
    @media (min-width: 1024px) {
      .has-margin-left-1-lg {
        margin-left: 0.25rem; } }
    @media (min-width: 1280px) {
      .has-margin-left-1-xl {
        margin-left: 0.25rem; } }
    @media (min-width: 1920px) {
      .has-margin-left-1-xxl {
        margin-left: 0.25rem; } }
  .has-margin-left-2 {
    margin-left: 0.5rem; }
    @media (min-width: 0px) {
      .has-margin-left-2-xs {
        margin-left: 0.5rem; } }
    @media (min-width: 480px) {
      .has-margin-left-2-sm {
        margin-left: 0.5rem; } }
    @media (min-width: 720px) {
      .has-margin-left-2-md {
        margin-left: 0.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-left-2-nav {
        margin-left: 0.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-left-2-lg {
        margin-left: 0.5rem; } }
    @media (min-width: 1280px) {
      .has-margin-left-2-xl {
        margin-left: 0.5rem; } }
    @media (min-width: 1920px) {
      .has-margin-left-2-xxl {
        margin-left: 0.5rem; } }
  .has-margin-left-3 {
    margin-left: 1rem; }
    @media (min-width: 0px) {
      .has-margin-left-3-xs {
        margin-left: 1rem; } }
    @media (min-width: 480px) {
      .has-margin-left-3-sm {
        margin-left: 1rem; } }
    @media (min-width: 720px) {
      .has-margin-left-3-md {
        margin-left: 1rem; } }
    @media (min-width: 1024px) {
      .has-margin-left-3-nav {
        margin-left: 1rem; } }
    @media (min-width: 1024px) {
      .has-margin-left-3-lg {
        margin-left: 1rem; } }
    @media (min-width: 1280px) {
      .has-margin-left-3-xl {
        margin-left: 1rem; } }
    @media (min-width: 1920px) {
      .has-margin-left-3-xxl {
        margin-left: 1rem; } }
  .has-margin-left-4 {
    margin-left: 1.5rem; }
    @media (min-width: 0px) {
      .has-margin-left-4-xs {
        margin-left: 1.5rem; } }
    @media (min-width: 480px) {
      .has-margin-left-4-sm {
        margin-left: 1.5rem; } }
    @media (min-width: 720px) {
      .has-margin-left-4-md {
        margin-left: 1.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-left-4-nav {
        margin-left: 1.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-left-4-lg {
        margin-left: 1.5rem; } }
    @media (min-width: 1280px) {
      .has-margin-left-4-xl {
        margin-left: 1.5rem; } }
    @media (min-width: 1920px) {
      .has-margin-left-4-xxl {
        margin-left: 1.5rem; } }
  .has-margin-left-5 {
    margin-left: 2rem; }
    @media (min-width: 0px) {
      .has-margin-left-5-xs {
        margin-left: 2rem; } }
    @media (min-width: 480px) {
      .has-margin-left-5-sm {
        margin-left: 2rem; } }
    @media (min-width: 720px) {
      .has-margin-left-5-md {
        margin-left: 2rem; } }
    @media (min-width: 1024px) {
      .has-margin-left-5-nav {
        margin-left: 2rem; } }
    @media (min-width: 1024px) {
      .has-margin-left-5-lg {
        margin-left: 2rem; } }
    @media (min-width: 1280px) {
      .has-margin-left-5-xl {
        margin-left: 2rem; } }
    @media (min-width: 1920px) {
      .has-margin-left-5-xxl {
        margin-left: 2rem; } }
  .has-margin-left-6 {
    margin-left: 3rem; }
    @media (min-width: 0px) {
      .has-margin-left-6-xs {
        margin-left: 3rem; } }
    @media (min-width: 480px) {
      .has-margin-left-6-sm {
        margin-left: 3rem; } }
    @media (min-width: 720px) {
      .has-margin-left-6-md {
        margin-left: 3rem; } }
    @media (min-width: 1024px) {
      .has-margin-left-6-nav {
        margin-left: 3rem; } }
    @media (min-width: 1024px) {
      .has-margin-left-6-lg {
        margin-left: 3rem; } }
    @media (min-width: 1280px) {
      .has-margin-left-6-xl {
        margin-left: 3rem; } }
    @media (min-width: 1920px) {
      .has-margin-left-6-xxl {
        margin-left: 3rem; } }
  .has-margin-left-7 {
    margin-left: 4rem; }
    @media (min-width: 0px) {
      .has-margin-left-7-xs {
        margin-left: 4rem; } }
    @media (min-width: 480px) {
      .has-margin-left-7-sm {
        margin-left: 4rem; } }
    @media (min-width: 720px) {
      .has-margin-left-7-md {
        margin-left: 4rem; } }
    @media (min-width: 1024px) {
      .has-margin-left-7-nav {
        margin-left: 4rem; } }
    @media (min-width: 1024px) {
      .has-margin-left-7-lg {
        margin-left: 4rem; } }
    @media (min-width: 1280px) {
      .has-margin-left-7-xl {
        margin-left: 4rem; } }
    @media (min-width: 1920px) {
      .has-margin-left-7-xxl {
        margin-left: 4rem; } }
  .has-margin-left-8 {
    margin-left: 5rem; }
    @media (min-width: 0px) {
      .has-margin-left-8-xs {
        margin-left: 5rem; } }
    @media (min-width: 480px) {
      .has-margin-left-8-sm {
        margin-left: 5rem; } }
    @media (min-width: 720px) {
      .has-margin-left-8-md {
        margin-left: 5rem; } }
    @media (min-width: 1024px) {
      .has-margin-left-8-nav {
        margin-left: 5rem; } }
    @media (min-width: 1024px) {
      .has-margin-left-8-lg {
        margin-left: 5rem; } }
    @media (min-width: 1280px) {
      .has-margin-left-8-xl {
        margin-left: 5rem; } }
    @media (min-width: 1920px) {
      .has-margin-left-8-xxl {
        margin-left: 5rem; } }

.has-margin-right {
  margin-right:  1rem; }
  @media (min-width: 0px) {
    .has-margin-right-xs {
      margin-right:  1rem; } }
  @media (min-width: 480px) {
    .has-margin-right-sm {
      margin-right:  1rem; } }
  @media (min-width: 720px) {
    .has-margin-right-md {
      margin-right:  1rem; } }
  @media (min-width: 1024px) {
    .has-margin-right-nav {
      margin-right:  1rem; } }
  @media (min-width: 1024px) {
    .has-margin-right-lg {
      margin-right:  1rem; } }
  @media (min-width: 1280px) {
    .has-margin-right-xl {
      margin-right:  1rem; } }
  @media (min-width: 1920px) {
    .has-margin-right-xxl {
      margin-right:  1rem; } }
  .has-margin-right-smallest {
    margin-right:  0.25rem; }
    @media (min-width: 0px) {
      .has-margin-right-smallest-xs {
        margin-right:  0.25rem; } }
    @media (min-width: 480px) {
      .has-margin-right-smallest-sm {
        margin-right:  0.25rem; } }
    @media (min-width: 720px) {
      .has-margin-right-smallest-md {
        margin-right:  0.25rem; } }
    @media (min-width: 1024px) {
      .has-margin-right-smallest-nav {
        margin-right:  0.25rem; } }
    @media (min-width: 1024px) {
      .has-margin-right-smallest-lg {
        margin-right:  0.25rem; } }
    @media (min-width: 1280px) {
      .has-margin-right-smallest-xl {
        margin-right:  0.25rem; } }
    @media (min-width: 1920px) {
      .has-margin-right-smallest-xxl {
        margin-right:  0.25rem; } }
  .has-margin-right-small {
    margin-right:  0.5rem; }
    @media (min-width: 0px) {
      .has-margin-right-small-xs {
        margin-right:  0.5rem; } }
    @media (min-width: 480px) {
      .has-margin-right-small-sm {
        margin-right:  0.5rem; } }
    @media (min-width: 720px) {
      .has-margin-right-small-md {
        margin-right:  0.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-right-small-nav {
        margin-right:  0.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-right-small-lg {
        margin-right:  0.5rem; } }
    @media (min-width: 1280px) {
      .has-margin-right-small-xl {
        margin-right:  0.5rem; } }
    @media (min-width: 1920px) {
      .has-margin-right-small-xxl {
        margin-right:  0.5rem; } }
  .has-margin-right-big {
    margin-right:  2rem; }
    @media (min-width: 0px) {
      .has-margin-right-big-xs {
        margin-right:  2rem; } }
    @media (min-width: 480px) {
      .has-margin-right-big-sm {
        margin-right:  2rem; } }
    @media (min-width: 720px) {
      .has-margin-right-big-md {
        margin-right:  2rem; } }
    @media (min-width: 1024px) {
      .has-margin-right-big-nav {
        margin-right:  2rem; } }
    @media (min-width: 1024px) {
      .has-margin-right-big-lg {
        margin-right:  2rem; } }
    @media (min-width: 1280px) {
      .has-margin-right-big-xl {
        margin-right:  2rem; } }
    @media (min-width: 1920px) {
      .has-margin-right-big-xxl {
        margin-right:  2rem; } }
  .has-margin-right-auto {
    margin-right: auto; }
    @media (min-width: 0px) {
      .has-margin-right-auto-xs {
        margin-right: auto; } }
    @media (min-width: 480px) {
      .has-margin-right-auto-sm {
        margin-right: auto; } }
    @media (min-width: 720px) {
      .has-margin-right-auto-md {
        margin-right: auto; } }
    @media (min-width: 1024px) {
      .has-margin-right-auto-nav {
        margin-right: auto; } }
    @media (min-width: 1024px) {
      .has-margin-right-auto-lg {
        margin-right: auto; } }
    @media (min-width: 1280px) {
      .has-margin-right-auto-xl {
        margin-right: auto; } }
    @media (min-width: 1920px) {
      .has-margin-right-auto-xxl {
        margin-right: auto; } }
  .has-margin-right-0 {
    margin-right: 0; }
    @media (min-width: 0px) {
      .has-margin-right-0-xs {
        margin-right: 0; } }
    @media (min-width: 480px) {
      .has-margin-right-0-sm {
        margin-right: 0; } }
    @media (min-width: 720px) {
      .has-margin-right-0-md {
        margin-right: 0; } }
    @media (min-width: 1024px) {
      .has-margin-right-0-nav {
        margin-right: 0; } }
    @media (min-width: 1024px) {
      .has-margin-right-0-lg {
        margin-right: 0; } }
    @media (min-width: 1280px) {
      .has-margin-right-0-xl {
        margin-right: 0; } }
    @media (min-width: 1920px) {
      .has-margin-right-0-xxl {
        margin-right: 0; } }
  .has-margin-right-1 {
    margin-right: 0.25rem; }
    @media (min-width: 0px) {
      .has-margin-right-1-xs {
        margin-right: 0.25rem; } }
    @media (min-width: 480px) {
      .has-margin-right-1-sm {
        margin-right: 0.25rem; } }
    @media (min-width: 720px) {
      .has-margin-right-1-md {
        margin-right: 0.25rem; } }
    @media (min-width: 1024px) {
      .has-margin-right-1-nav {
        margin-right: 0.25rem; } }
    @media (min-width: 1024px) {
      .has-margin-right-1-lg {
        margin-right: 0.25rem; } }
    @media (min-width: 1280px) {
      .has-margin-right-1-xl {
        margin-right: 0.25rem; } }
    @media (min-width: 1920px) {
      .has-margin-right-1-xxl {
        margin-right: 0.25rem; } }
  .has-margin-right-2 {
    margin-right: 0.5rem; }
    @media (min-width: 0px) {
      .has-margin-right-2-xs {
        margin-right: 0.5rem; } }
    @media (min-width: 480px) {
      .has-margin-right-2-sm {
        margin-right: 0.5rem; } }
    @media (min-width: 720px) {
      .has-margin-right-2-md {
        margin-right: 0.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-right-2-nav {
        margin-right: 0.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-right-2-lg {
        margin-right: 0.5rem; } }
    @media (min-width: 1280px) {
      .has-margin-right-2-xl {
        margin-right: 0.5rem; } }
    @media (min-width: 1920px) {
      .has-margin-right-2-xxl {
        margin-right: 0.5rem; } }
  .has-margin-right-3 {
    margin-right: 1rem; }
    @media (min-width: 0px) {
      .has-margin-right-3-xs {
        margin-right: 1rem; } }
    @media (min-width: 480px) {
      .has-margin-right-3-sm {
        margin-right: 1rem; } }
    @media (min-width: 720px) {
      .has-margin-right-3-md {
        margin-right: 1rem; } }
    @media (min-width: 1024px) {
      .has-margin-right-3-nav {
        margin-right: 1rem; } }
    @media (min-width: 1024px) {
      .has-margin-right-3-lg {
        margin-right: 1rem; } }
    @media (min-width: 1280px) {
      .has-margin-right-3-xl {
        margin-right: 1rem; } }
    @media (min-width: 1920px) {
      .has-margin-right-3-xxl {
        margin-right: 1rem; } }
  .has-margin-right-4 {
    margin-right: 1.5rem; }
    @media (min-width: 0px) {
      .has-margin-right-4-xs {
        margin-right: 1.5rem; } }
    @media (min-width: 480px) {
      .has-margin-right-4-sm {
        margin-right: 1.5rem; } }
    @media (min-width: 720px) {
      .has-margin-right-4-md {
        margin-right: 1.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-right-4-nav {
        margin-right: 1.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-right-4-lg {
        margin-right: 1.5rem; } }
    @media (min-width: 1280px) {
      .has-margin-right-4-xl {
        margin-right: 1.5rem; } }
    @media (min-width: 1920px) {
      .has-margin-right-4-xxl {
        margin-right: 1.5rem; } }
  .has-margin-right-5 {
    margin-right: 2rem; }
    @media (min-width: 0px) {
      .has-margin-right-5-xs {
        margin-right: 2rem; } }
    @media (min-width: 480px) {
      .has-margin-right-5-sm {
        margin-right: 2rem; } }
    @media (min-width: 720px) {
      .has-margin-right-5-md {
        margin-right: 2rem; } }
    @media (min-width: 1024px) {
      .has-margin-right-5-nav {
        margin-right: 2rem; } }
    @media (min-width: 1024px) {
      .has-margin-right-5-lg {
        margin-right: 2rem; } }
    @media (min-width: 1280px) {
      .has-margin-right-5-xl {
        margin-right: 2rem; } }
    @media (min-width: 1920px) {
      .has-margin-right-5-xxl {
        margin-right: 2rem; } }
  .has-margin-right-6 {
    margin-right: 3rem; }
    @media (min-width: 0px) {
      .has-margin-right-6-xs {
        margin-right: 3rem; } }
    @media (min-width: 480px) {
      .has-margin-right-6-sm {
        margin-right: 3rem; } }
    @media (min-width: 720px) {
      .has-margin-right-6-md {
        margin-right: 3rem; } }
    @media (min-width: 1024px) {
      .has-margin-right-6-nav {
        margin-right: 3rem; } }
    @media (min-width: 1024px) {
      .has-margin-right-6-lg {
        margin-right: 3rem; } }
    @media (min-width: 1280px) {
      .has-margin-right-6-xl {
        margin-right: 3rem; } }
    @media (min-width: 1920px) {
      .has-margin-right-6-xxl {
        margin-right: 3rem; } }
  .has-margin-right-7 {
    margin-right: 4rem; }
    @media (min-width: 0px) {
      .has-margin-right-7-xs {
        margin-right: 4rem; } }
    @media (min-width: 480px) {
      .has-margin-right-7-sm {
        margin-right: 4rem; } }
    @media (min-width: 720px) {
      .has-margin-right-7-md {
        margin-right: 4rem; } }
    @media (min-width: 1024px) {
      .has-margin-right-7-nav {
        margin-right: 4rem; } }
    @media (min-width: 1024px) {
      .has-margin-right-7-lg {
        margin-right: 4rem; } }
    @media (min-width: 1280px) {
      .has-margin-right-7-xl {
        margin-right: 4rem; } }
    @media (min-width: 1920px) {
      .has-margin-right-7-xxl {
        margin-right: 4rem; } }
  .has-margin-right-8 {
    margin-right: 5rem; }
    @media (min-width: 0px) {
      .has-margin-right-8-xs {
        margin-right: 5rem; } }
    @media (min-width: 480px) {
      .has-margin-right-8-sm {
        margin-right: 5rem; } }
    @media (min-width: 720px) {
      .has-margin-right-8-md {
        margin-right: 5rem; } }
    @media (min-width: 1024px) {
      .has-margin-right-8-nav {
        margin-right: 5rem; } }
    @media (min-width: 1024px) {
      .has-margin-right-8-lg {
        margin-right: 5rem; } }
    @media (min-width: 1280px) {
      .has-margin-right-8-xl {
        margin-right: 5rem; } }
    @media (min-width: 1920px) {
      .has-margin-right-8-xxl {
        margin-right: 5rem; } }

.has-padding {
  padding:  1rem; }
  @media (min-width: 0px) {
    .has-padding-xs {
      padding:  1rem; } }
  @media (min-width: 480px) {
    .has-padding-sm {
      padding:  1rem; } }
  @media (min-width: 720px) {
    .has-padding-md {
      padding:  1rem; } }
  @media (min-width: 1024px) {
    .has-padding-nav {
      padding:  1rem; } }
  @media (min-width: 1024px) {
    .has-padding-lg {
      padding:  1rem; } }
  @media (min-width: 1280px) {
    .has-padding-xl {
      padding:  1rem; } }
  @media (min-width: 1920px) {
    .has-padding-xxl {
      padding:  1rem; } }
  .has-padding-smallest {
    padding:  0.25rem; }
    @media (min-width: 0px) {
      .has-padding-smallest-xs {
        padding:  0.25rem; } }
    @media (min-width: 480px) {
      .has-padding-smallest-sm {
        padding:  0.25rem; } }
    @media (min-width: 720px) {
      .has-padding-smallest-md {
        padding:  0.25rem; } }
    @media (min-width: 1024px) {
      .has-padding-smallest-nav {
        padding:  0.25rem; } }
    @media (min-width: 1024px) {
      .has-padding-smallest-lg {
        padding:  0.25rem; } }
    @media (min-width: 1280px) {
      .has-padding-smallest-xl {
        padding:  0.25rem; } }
    @media (min-width: 1920px) {
      .has-padding-smallest-xxl {
        padding:  0.25rem; } }
  .has-padding-small {
    padding:  0.5rem; }
    @media (min-width: 0px) {
      .has-padding-small-xs {
        padding:  0.5rem; } }
    @media (min-width: 480px) {
      .has-padding-small-sm {
        padding:  0.5rem; } }
    @media (min-width: 720px) {
      .has-padding-small-md {
        padding:  0.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-small-nav {
        padding:  0.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-small-lg {
        padding:  0.5rem; } }
    @media (min-width: 1280px) {
      .has-padding-small-xl {
        padding:  0.5rem; } }
    @media (min-width: 1920px) {
      .has-padding-small-xxl {
        padding:  0.5rem; } }
  .has-padding-big {
    padding:  2rem; }
    @media (min-width: 0px) {
      .has-padding-big-xs {
        padding:  2rem; } }
    @media (min-width: 480px) {
      .has-padding-big-sm {
        padding:  2rem; } }
    @media (min-width: 720px) {
      .has-padding-big-md {
        padding:  2rem; } }
    @media (min-width: 1024px) {
      .has-padding-big-nav {
        padding:  2rem; } }
    @media (min-width: 1024px) {
      .has-padding-big-lg {
        padding:  2rem; } }
    @media (min-width: 1280px) {
      .has-padding-big-xl {
        padding:  2rem; } }
    @media (min-width: 1920px) {
      .has-padding-big-xxl {
        padding:  2rem; } }
  .has-padding-0 {
    padding: 0; }
    @media (min-width: 0px) {
      .has-padding-0-xs {
        padding: 0; } }
    @media (min-width: 480px) {
      .has-padding-0-sm {
        padding: 0; } }
    @media (min-width: 720px) {
      .has-padding-0-md {
        padding: 0; } }
    @media (min-width: 1024px) {
      .has-padding-0-nav {
        padding: 0; } }
    @media (min-width: 1024px) {
      .has-padding-0-lg {
        padding: 0; } }
    @media (min-width: 1280px) {
      .has-padding-0-xl {
        padding: 0; } }
    @media (min-width: 1920px) {
      .has-padding-0-xxl {
        padding: 0; } }
  .has-padding-1 {
    padding: 0.25rem; }
    @media (min-width: 0px) {
      .has-padding-1-xs {
        padding: 0.25rem; } }
    @media (min-width: 480px) {
      .has-padding-1-sm {
        padding: 0.25rem; } }
    @media (min-width: 720px) {
      .has-padding-1-md {
        padding: 0.25rem; } }
    @media (min-width: 1024px) {
      .has-padding-1-nav {
        padding: 0.25rem; } }
    @media (min-width: 1024px) {
      .has-padding-1-lg {
        padding: 0.25rem; } }
    @media (min-width: 1280px) {
      .has-padding-1-xl {
        padding: 0.25rem; } }
    @media (min-width: 1920px) {
      .has-padding-1-xxl {
        padding: 0.25rem; } }
  .has-padding-2 {
    padding: 0.5rem; }
    @media (min-width: 0px) {
      .has-padding-2-xs {
        padding: 0.5rem; } }
    @media (min-width: 480px) {
      .has-padding-2-sm {
        padding: 0.5rem; } }
    @media (min-width: 720px) {
      .has-padding-2-md {
        padding: 0.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-2-nav {
        padding: 0.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-2-lg {
        padding: 0.5rem; } }
    @media (min-width: 1280px) {
      .has-padding-2-xl {
        padding: 0.5rem; } }
    @media (min-width: 1920px) {
      .has-padding-2-xxl {
        padding: 0.5rem; } }
  .has-padding-3 {
    padding: 1rem; }
    @media (min-width: 0px) {
      .has-padding-3-xs {
        padding: 1rem; } }
    @media (min-width: 480px) {
      .has-padding-3-sm {
        padding: 1rem; } }
    @media (min-width: 720px) {
      .has-padding-3-md {
        padding: 1rem; } }
    @media (min-width: 1024px) {
      .has-padding-3-nav {
        padding: 1rem; } }
    @media (min-width: 1024px) {
      .has-padding-3-lg {
        padding: 1rem; } }
    @media (min-width: 1280px) {
      .has-padding-3-xl {
        padding: 1rem; } }
    @media (min-width: 1920px) {
      .has-padding-3-xxl {
        padding: 1rem; } }
  .has-padding-4 {
    padding: 1.5rem; }
    @media (min-width: 0px) {
      .has-padding-4-xs {
        padding: 1.5rem; } }
    @media (min-width: 480px) {
      .has-padding-4-sm {
        padding: 1.5rem; } }
    @media (min-width: 720px) {
      .has-padding-4-md {
        padding: 1.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-4-nav {
        padding: 1.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-4-lg {
        padding: 1.5rem; } }
    @media (min-width: 1280px) {
      .has-padding-4-xl {
        padding: 1.5rem; } }
    @media (min-width: 1920px) {
      .has-padding-4-xxl {
        padding: 1.5rem; } }
  .has-padding-5 {
    padding: 2rem; }
    @media (min-width: 0px) {
      .has-padding-5-xs {
        padding: 2rem; } }
    @media (min-width: 480px) {
      .has-padding-5-sm {
        padding: 2rem; } }
    @media (min-width: 720px) {
      .has-padding-5-md {
        padding: 2rem; } }
    @media (min-width: 1024px) {
      .has-padding-5-nav {
        padding: 2rem; } }
    @media (min-width: 1024px) {
      .has-padding-5-lg {
        padding: 2rem; } }
    @media (min-width: 1280px) {
      .has-padding-5-xl {
        padding: 2rem; } }
    @media (min-width: 1920px) {
      .has-padding-5-xxl {
        padding: 2rem; } }
  .has-padding-6 {
    padding: 3rem; }
    @media (min-width: 0px) {
      .has-padding-6-xs {
        padding: 3rem; } }
    @media (min-width: 480px) {
      .has-padding-6-sm {
        padding: 3rem; } }
    @media (min-width: 720px) {
      .has-padding-6-md {
        padding: 3rem; } }
    @media (min-width: 1024px) {
      .has-padding-6-nav {
        padding: 3rem; } }
    @media (min-width: 1024px) {
      .has-padding-6-lg {
        padding: 3rem; } }
    @media (min-width: 1280px) {
      .has-padding-6-xl {
        padding: 3rem; } }
    @media (min-width: 1920px) {
      .has-padding-6-xxl {
        padding: 3rem; } }
  .has-padding-7 {
    padding: 4rem; }
    @media (min-width: 0px) {
      .has-padding-7-xs {
        padding: 4rem; } }
    @media (min-width: 480px) {
      .has-padding-7-sm {
        padding: 4rem; } }
    @media (min-width: 720px) {
      .has-padding-7-md {
        padding: 4rem; } }
    @media (min-width: 1024px) {
      .has-padding-7-nav {
        padding: 4rem; } }
    @media (min-width: 1024px) {
      .has-padding-7-lg {
        padding: 4rem; } }
    @media (min-width: 1280px) {
      .has-padding-7-xl {
        padding: 4rem; } }
    @media (min-width: 1920px) {
      .has-padding-7-xxl {
        padding: 4rem; } }
  .has-padding-8 {
    padding: 5rem; }
    @media (min-width: 0px) {
      .has-padding-8-xs {
        padding: 5rem; } }
    @media (min-width: 480px) {
      .has-padding-8-sm {
        padding: 5rem; } }
    @media (min-width: 720px) {
      .has-padding-8-md {
        padding: 5rem; } }
    @media (min-width: 1024px) {
      .has-padding-8-nav {
        padding: 5rem; } }
    @media (min-width: 1024px) {
      .has-padding-8-lg {
        padding: 5rem; } }
    @media (min-width: 1280px) {
      .has-padding-8-xl {
        padding: 5rem; } }
    @media (min-width: 1920px) {
      .has-padding-8-xxl {
        padding: 5rem; } }

.has-padding-middle {
  padding-bottom:  1rem;
  padding-top:  1rem; }
  @media (min-width: 0px) {
    .has-padding-middle-xs {
      padding-bottom:  1rem;
      padding-top:  1rem; } }
  @media (min-width: 480px) {
    .has-padding-middle-sm {
      padding-bottom:  1rem;
      padding-top:  1rem; } }
  @media (min-width: 720px) {
    .has-padding-middle-md {
      padding-bottom:  1rem;
      padding-top:  1rem; } }
  @media (min-width: 1024px) {
    .has-padding-middle-nav {
      padding-bottom:  1rem;
      padding-top:  1rem; } }
  @media (min-width: 1024px) {
    .has-padding-middle-lg {
      padding-bottom:  1rem;
      padding-top:  1rem; } }
  @media (min-width: 1280px) {
    .has-padding-middle-xl {
      padding-bottom:  1rem;
      padding-top:  1rem; } }
  @media (min-width: 1920px) {
    .has-padding-middle-xxl {
      padding-bottom:  1rem;
      padding-top:  1rem; } }
  .has-padding-middle-smallest {
    padding-bottom:  0.25rem;
    padding-top:  0.25rem; }
    @media (min-width: 0px) {
      .has-padding-middle-smallest-xs {
        padding-bottom:  0.25rem;
        padding-top:  0.25rem; } }
    @media (min-width: 480px) {
      .has-padding-middle-smallest-sm {
        padding-bottom:  0.25rem;
        padding-top:  0.25rem; } }
    @media (min-width: 720px) {
      .has-padding-middle-smallest-md {
        padding-bottom:  0.25rem;
        padding-top:  0.25rem; } }
    @media (min-width: 1024px) {
      .has-padding-middle-smallest-nav {
        padding-bottom:  0.25rem;
        padding-top:  0.25rem; } }
    @media (min-width: 1024px) {
      .has-padding-middle-smallest-lg {
        padding-bottom:  0.25rem;
        padding-top:  0.25rem; } }
    @media (min-width: 1280px) {
      .has-padding-middle-smallest-xl {
        padding-bottom:  0.25rem;
        padding-top:  0.25rem; } }
    @media (min-width: 1920px) {
      .has-padding-middle-smallest-xxl {
        padding-bottom:  0.25rem;
        padding-top:  0.25rem; } }
  .has-padding-middle-small {
    padding-bottom:  0.5rem;
    padding-top:  0.5rem; }
    @media (min-width: 0px) {
      .has-padding-middle-small-xs {
        padding-bottom:  0.5rem;
        padding-top:  0.5rem; } }
    @media (min-width: 480px) {
      .has-padding-middle-small-sm {
        padding-bottom:  0.5rem;
        padding-top:  0.5rem; } }
    @media (min-width: 720px) {
      .has-padding-middle-small-md {
        padding-bottom:  0.5rem;
        padding-top:  0.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-middle-small-nav {
        padding-bottom:  0.5rem;
        padding-top:  0.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-middle-small-lg {
        padding-bottom:  0.5rem;
        padding-top:  0.5rem; } }
    @media (min-width: 1280px) {
      .has-padding-middle-small-xl {
        padding-bottom:  0.5rem;
        padding-top:  0.5rem; } }
    @media (min-width: 1920px) {
      .has-padding-middle-small-xxl {
        padding-bottom:  0.5rem;
        padding-top:  0.5rem; } }
  .has-padding-middle-big {
    padding-bottom:  2rem;
    padding-top:  2rem; }
    @media (min-width: 0px) {
      .has-padding-middle-big-xs {
        padding-bottom:  2rem;
        padding-top:  2rem; } }
    @media (min-width: 480px) {
      .has-padding-middle-big-sm {
        padding-bottom:  2rem;
        padding-top:  2rem; } }
    @media (min-width: 720px) {
      .has-padding-middle-big-md {
        padding-bottom:  2rem;
        padding-top:  2rem; } }
    @media (min-width: 1024px) {
      .has-padding-middle-big-nav {
        padding-bottom:  2rem;
        padding-top:  2rem; } }
    @media (min-width: 1024px) {
      .has-padding-middle-big-lg {
        padding-bottom:  2rem;
        padding-top:  2rem; } }
    @media (min-width: 1280px) {
      .has-padding-middle-big-xl {
        padding-bottom:  2rem;
        padding-top:  2rem; } }
    @media (min-width: 1920px) {
      .has-padding-middle-big-xxl {
        padding-bottom:  2rem;
        padding-top:  2rem; } }
  .has-padding-middle-0 {
    padding-bottom: 0;
    padding-top: 0; }
    @media (min-width: 0px) {
      .has-padding-middle-0-xs {
        padding-bottom: 0;
        padding-top: 0; } }
    @media (min-width: 480px) {
      .has-padding-middle-0-sm {
        padding-bottom: 0;
        padding-top: 0; } }
    @media (min-width: 720px) {
      .has-padding-middle-0-md {
        padding-bottom: 0;
        padding-top: 0; } }
    @media (min-width: 1024px) {
      .has-padding-middle-0-nav {
        padding-bottom: 0;
        padding-top: 0; } }
    @media (min-width: 1024px) {
      .has-padding-middle-0-lg {
        padding-bottom: 0;
        padding-top: 0; } }
    @media (min-width: 1280px) {
      .has-padding-middle-0-xl {
        padding-bottom: 0;
        padding-top: 0; } }
    @media (min-width: 1920px) {
      .has-padding-middle-0-xxl {
        padding-bottom: 0;
        padding-top: 0; } }
  .has-padding-middle-1 {
    padding-bottom: 0.25rem;
    padding-top: 0.25rem; }
    @media (min-width: 0px) {
      .has-padding-middle-1-xs {
        padding-bottom: 0.25rem;
        padding-top: 0.25rem; } }
    @media (min-width: 480px) {
      .has-padding-middle-1-sm {
        padding-bottom: 0.25rem;
        padding-top: 0.25rem; } }
    @media (min-width: 720px) {
      .has-padding-middle-1-md {
        padding-bottom: 0.25rem;
        padding-top: 0.25rem; } }
    @media (min-width: 1024px) {
      .has-padding-middle-1-nav {
        padding-bottom: 0.25rem;
        padding-top: 0.25rem; } }
    @media (min-width: 1024px) {
      .has-padding-middle-1-lg {
        padding-bottom: 0.25rem;
        padding-top: 0.25rem; } }
    @media (min-width: 1280px) {
      .has-padding-middle-1-xl {
        padding-bottom: 0.25rem;
        padding-top: 0.25rem; } }
    @media (min-width: 1920px) {
      .has-padding-middle-1-xxl {
        padding-bottom: 0.25rem;
        padding-top: 0.25rem; } }
  .has-padding-middle-2 {
    padding-bottom: 0.5rem;
    padding-top: 0.5rem; }
    @media (min-width: 0px) {
      .has-padding-middle-2-xs {
        padding-bottom: 0.5rem;
        padding-top: 0.5rem; } }
    @media (min-width: 480px) {
      .has-padding-middle-2-sm {
        padding-bottom: 0.5rem;
        padding-top: 0.5rem; } }
    @media (min-width: 720px) {
      .has-padding-middle-2-md {
        padding-bottom: 0.5rem;
        padding-top: 0.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-middle-2-nav {
        padding-bottom: 0.5rem;
        padding-top: 0.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-middle-2-lg {
        padding-bottom: 0.5rem;
        padding-top: 0.5rem; } }
    @media (min-width: 1280px) {
      .has-padding-middle-2-xl {
        padding-bottom: 0.5rem;
        padding-top: 0.5rem; } }
    @media (min-width: 1920px) {
      .has-padding-middle-2-xxl {
        padding-bottom: 0.5rem;
        padding-top: 0.5rem; } }
  .has-padding-middle-3 {
    padding-bottom: 1rem;
    padding-top: 1rem; }
    @media (min-width: 0px) {
      .has-padding-middle-3-xs {
        padding-bottom: 1rem;
        padding-top: 1rem; } }
    @media (min-width: 480px) {
      .has-padding-middle-3-sm {
        padding-bottom: 1rem;
        padding-top: 1rem; } }
    @media (min-width: 720px) {
      .has-padding-middle-3-md {
        padding-bottom: 1rem;
        padding-top: 1rem; } }
    @media (min-width: 1024px) {
      .has-padding-middle-3-nav {
        padding-bottom: 1rem;
        padding-top: 1rem; } }
    @media (min-width: 1024px) {
      .has-padding-middle-3-lg {
        padding-bottom: 1rem;
        padding-top: 1rem; } }
    @media (min-width: 1280px) {
      .has-padding-middle-3-xl {
        padding-bottom: 1rem;
        padding-top: 1rem; } }
    @media (min-width: 1920px) {
      .has-padding-middle-3-xxl {
        padding-bottom: 1rem;
        padding-top: 1rem; } }
  .has-padding-middle-4 {
    padding-bottom: 1.5rem;
    padding-top: 1.5rem; }
    @media (min-width: 0px) {
      .has-padding-middle-4-xs {
        padding-bottom: 1.5rem;
        padding-top: 1.5rem; } }
    @media (min-width: 480px) {
      .has-padding-middle-4-sm {
        padding-bottom: 1.5rem;
        padding-top: 1.5rem; } }
    @media (min-width: 720px) {
      .has-padding-middle-4-md {
        padding-bottom: 1.5rem;
        padding-top: 1.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-middle-4-nav {
        padding-bottom: 1.5rem;
        padding-top: 1.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-middle-4-lg {
        padding-bottom: 1.5rem;
        padding-top: 1.5rem; } }
    @media (min-width: 1280px) {
      .has-padding-middle-4-xl {
        padding-bottom: 1.5rem;
        padding-top: 1.5rem; } }
    @media (min-width: 1920px) {
      .has-padding-middle-4-xxl {
        padding-bottom: 1.5rem;
        padding-top: 1.5rem; } }
  .has-padding-middle-5 {
    padding-bottom: 2rem;
    padding-top: 2rem; }
    @media (min-width: 0px) {
      .has-padding-middle-5-xs {
        padding-bottom: 2rem;
        padding-top: 2rem; } }
    @media (min-width: 480px) {
      .has-padding-middle-5-sm {
        padding-bottom: 2rem;
        padding-top: 2rem; } }
    @media (min-width: 720px) {
      .has-padding-middle-5-md {
        padding-bottom: 2rem;
        padding-top: 2rem; } }
    @media (min-width: 1024px) {
      .has-padding-middle-5-nav {
        padding-bottom: 2rem;
        padding-top: 2rem; } }
    @media (min-width: 1024px) {
      .has-padding-middle-5-lg {
        padding-bottom: 2rem;
        padding-top: 2rem; } }
    @media (min-width: 1280px) {
      .has-padding-middle-5-xl {
        padding-bottom: 2rem;
        padding-top: 2rem; } }
    @media (min-width: 1920px) {
      .has-padding-middle-5-xxl {
        padding-bottom: 2rem;
        padding-top: 2rem; } }
  .has-padding-middle-6 {
    padding-bottom: 3rem;
    padding-top: 3rem; }
    @media (min-width: 0px) {
      .has-padding-middle-6-xs {
        padding-bottom: 3rem;
        padding-top: 3rem; } }
    @media (min-width: 480px) {
      .has-padding-middle-6-sm {
        padding-bottom: 3rem;
        padding-top: 3rem; } }
    @media (min-width: 720px) {
      .has-padding-middle-6-md {
        padding-bottom: 3rem;
        padding-top: 3rem; } }
    @media (min-width: 1024px) {
      .has-padding-middle-6-nav {
        padding-bottom: 3rem;
        padding-top: 3rem; } }
    @media (min-width: 1024px) {
      .has-padding-middle-6-lg {
        padding-bottom: 3rem;
        padding-top: 3rem; } }
    @media (min-width: 1280px) {
      .has-padding-middle-6-xl {
        padding-bottom: 3rem;
        padding-top: 3rem; } }
    @media (min-width: 1920px) {
      .has-padding-middle-6-xxl {
        padding-bottom: 3rem;
        padding-top: 3rem; } }
  .has-padding-middle-7 {
    padding-bottom: 4rem;
    padding-top: 4rem; }
    @media (min-width: 0px) {
      .has-padding-middle-7-xs {
        padding-bottom: 4rem;
        padding-top: 4rem; } }
    @media (min-width: 480px) {
      .has-padding-middle-7-sm {
        padding-bottom: 4rem;
        padding-top: 4rem; } }
    @media (min-width: 720px) {
      .has-padding-middle-7-md {
        padding-bottom: 4rem;
        padding-top: 4rem; } }
    @media (min-width: 1024px) {
      .has-padding-middle-7-nav {
        padding-bottom: 4rem;
        padding-top: 4rem; } }
    @media (min-width: 1024px) {
      .has-padding-middle-7-lg {
        padding-bottom: 4rem;
        padding-top: 4rem; } }
    @media (min-width: 1280px) {
      .has-padding-middle-7-xl {
        padding-bottom: 4rem;
        padding-top: 4rem; } }
    @media (min-width: 1920px) {
      .has-padding-middle-7-xxl {
        padding-bottom: 4rem;
        padding-top: 4rem; } }
  .has-padding-middle-8 {
    padding-bottom: 5rem;
    padding-top: 5rem; }
    @media (min-width: 0px) {
      .has-padding-middle-8-xs {
        padding-bottom: 5rem;
        padding-top: 5rem; } }
    @media (min-width: 480px) {
      .has-padding-middle-8-sm {
        padding-bottom: 5rem;
        padding-top: 5rem; } }
    @media (min-width: 720px) {
      .has-padding-middle-8-md {
        padding-bottom: 5rem;
        padding-top: 5rem; } }
    @media (min-width: 1024px) {
      .has-padding-middle-8-nav {
        padding-bottom: 5rem;
        padding-top: 5rem; } }
    @media (min-width: 1024px) {
      .has-padding-middle-8-lg {
        padding-bottom: 5rem;
        padding-top: 5rem; } }
    @media (min-width: 1280px) {
      .has-padding-middle-8-xl {
        padding-bottom: 5rem;
        padding-top: 5rem; } }
    @media (min-width: 1920px) {
      .has-padding-middle-8-xxl {
        padding-bottom: 5rem;
        padding-top: 5rem; } }

.has-padding-top {
  padding-top:  1rem; }
  @media (min-width: 0px) {
    .has-padding-top-xs {
      padding-top:  1rem; } }
  @media (min-width: 480px) {
    .has-padding-top-sm {
      padding-top:  1rem; } }
  @media (min-width: 720px) {
    .has-padding-top-md {
      padding-top:  1rem; } }
  @media (min-width: 1024px) {
    .has-padding-top-nav {
      padding-top:  1rem; } }
  @media (min-width: 1024px) {
    .has-padding-top-lg {
      padding-top:  1rem; } }
  @media (min-width: 1280px) {
    .has-padding-top-xl {
      padding-top:  1rem; } }
  @media (min-width: 1920px) {
    .has-padding-top-xxl {
      padding-top:  1rem; } }
  .has-padding-top-smallest {
    padding-top:  0.25rem; }
    @media (min-width: 0px) {
      .has-padding-top-smallest-xs {
        padding-top:  0.25rem; } }
    @media (min-width: 480px) {
      .has-padding-top-smallest-sm {
        padding-top:  0.25rem; } }
    @media (min-width: 720px) {
      .has-padding-top-smallest-md {
        padding-top:  0.25rem; } }
    @media (min-width: 1024px) {
      .has-padding-top-smallest-nav {
        padding-top:  0.25rem; } }
    @media (min-width: 1024px) {
      .has-padding-top-smallest-lg {
        padding-top:  0.25rem; } }
    @media (min-width: 1280px) {
      .has-padding-top-smallest-xl {
        padding-top:  0.25rem; } }
    @media (min-width: 1920px) {
      .has-padding-top-smallest-xxl {
        padding-top:  0.25rem; } }
  .has-padding-top-small {
    padding-top:  0.5rem; }
    @media (min-width: 0px) {
      .has-padding-top-small-xs {
        padding-top:  0.5rem; } }
    @media (min-width: 480px) {
      .has-padding-top-small-sm {
        padding-top:  0.5rem; } }
    @media (min-width: 720px) {
      .has-padding-top-small-md {
        padding-top:  0.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-top-small-nav {
        padding-top:  0.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-top-small-lg {
        padding-top:  0.5rem; } }
    @media (min-width: 1280px) {
      .has-padding-top-small-xl {
        padding-top:  0.5rem; } }
    @media (min-width: 1920px) {
      .has-padding-top-small-xxl {
        padding-top:  0.5rem; } }
  .has-padding-top-big {
    padding-top:  2rem; }
    @media (min-width: 0px) {
      .has-padding-top-big-xs {
        padding-top:  2rem; } }
    @media (min-width: 480px) {
      .has-padding-top-big-sm {
        padding-top:  2rem; } }
    @media (min-width: 720px) {
      .has-padding-top-big-md {
        padding-top:  2rem; } }
    @media (min-width: 1024px) {
      .has-padding-top-big-nav {
        padding-top:  2rem; } }
    @media (min-width: 1024px) {
      .has-padding-top-big-lg {
        padding-top:  2rem; } }
    @media (min-width: 1280px) {
      .has-padding-top-big-xl {
        padding-top:  2rem; } }
    @media (min-width: 1920px) {
      .has-padding-top-big-xxl {
        padding-top:  2rem; } }
  .has-padding-top-0 {
    padding-top: 0; }
    @media (min-width: 0px) {
      .has-padding-top-0-xs {
        padding-top: 0; } }
    @media (min-width: 480px) {
      .has-padding-top-0-sm {
        padding-top: 0; } }
    @media (min-width: 720px) {
      .has-padding-top-0-md {
        padding-top: 0; } }
    @media (min-width: 1024px) {
      .has-padding-top-0-nav {
        padding-top: 0; } }
    @media (min-width: 1024px) {
      .has-padding-top-0-lg {
        padding-top: 0; } }
    @media (min-width: 1280px) {
      .has-padding-top-0-xl {
        padding-top: 0; } }
    @media (min-width: 1920px) {
      .has-padding-top-0-xxl {
        padding-top: 0; } }
  .has-padding-top-1 {
    padding-top: 0.25rem; }
    @media (min-width: 0px) {
      .has-padding-top-1-xs {
        padding-top: 0.25rem; } }
    @media (min-width: 480px) {
      .has-padding-top-1-sm {
        padding-top: 0.25rem; } }
    @media (min-width: 720px) {
      .has-padding-top-1-md {
        padding-top: 0.25rem; } }
    @media (min-width: 1024px) {
      .has-padding-top-1-nav {
        padding-top: 0.25rem; } }
    @media (min-width: 1024px) {
      .has-padding-top-1-lg {
        padding-top: 0.25rem; } }
    @media (min-width: 1280px) {
      .has-padding-top-1-xl {
        padding-top: 0.25rem; } }
    @media (min-width: 1920px) {
      .has-padding-top-1-xxl {
        padding-top: 0.25rem; } }
  .has-padding-top-2 {
    padding-top: 0.5rem; }
    @media (min-width: 0px) {
      .has-padding-top-2-xs {
        padding-top: 0.5rem; } }
    @media (min-width: 480px) {
      .has-padding-top-2-sm {
        padding-top: 0.5rem; } }
    @media (min-width: 720px) {
      .has-padding-top-2-md {
        padding-top: 0.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-top-2-nav {
        padding-top: 0.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-top-2-lg {
        padding-top: 0.5rem; } }
    @media (min-width: 1280px) {
      .has-padding-top-2-xl {
        padding-top: 0.5rem; } }
    @media (min-width: 1920px) {
      .has-padding-top-2-xxl {
        padding-top: 0.5rem; } }
  .has-padding-top-3 {
    padding-top: 1rem; }
    @media (min-width: 0px) {
      .has-padding-top-3-xs {
        padding-top: 1rem; } }
    @media (min-width: 480px) {
      .has-padding-top-3-sm {
        padding-top: 1rem; } }
    @media (min-width: 720px) {
      .has-padding-top-3-md {
        padding-top: 1rem; } }
    @media (min-width: 1024px) {
      .has-padding-top-3-nav {
        padding-top: 1rem; } }
    @media (min-width: 1024px) {
      .has-padding-top-3-lg {
        padding-top: 1rem; } }
    @media (min-width: 1280px) {
      .has-padding-top-3-xl {
        padding-top: 1rem; } }
    @media (min-width: 1920px) {
      .has-padding-top-3-xxl {
        padding-top: 1rem; } }
  .has-padding-top-4 {
    padding-top: 1.5rem; }
    @media (min-width: 0px) {
      .has-padding-top-4-xs {
        padding-top: 1.5rem; } }
    @media (min-width: 480px) {
      .has-padding-top-4-sm {
        padding-top: 1.5rem; } }
    @media (min-width: 720px) {
      .has-padding-top-4-md {
        padding-top: 1.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-top-4-nav {
        padding-top: 1.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-top-4-lg {
        padding-top: 1.5rem; } }
    @media (min-width: 1280px) {
      .has-padding-top-4-xl {
        padding-top: 1.5rem; } }
    @media (min-width: 1920px) {
      .has-padding-top-4-xxl {
        padding-top: 1.5rem; } }
  .has-padding-top-5 {
    padding-top: 2rem; }
    @media (min-width: 0px) {
      .has-padding-top-5-xs {
        padding-top: 2rem; } }
    @media (min-width: 480px) {
      .has-padding-top-5-sm {
        padding-top: 2rem; } }
    @media (min-width: 720px) {
      .has-padding-top-5-md {
        padding-top: 2rem; } }
    @media (min-width: 1024px) {
      .has-padding-top-5-nav {
        padding-top: 2rem; } }
    @media (min-width: 1024px) {
      .has-padding-top-5-lg {
        padding-top: 2rem; } }
    @media (min-width: 1280px) {
      .has-padding-top-5-xl {
        padding-top: 2rem; } }
    @media (min-width: 1920px) {
      .has-padding-top-5-xxl {
        padding-top: 2rem; } }
  .has-padding-top-6 {
    padding-top: 3rem; }
    @media (min-width: 0px) {
      .has-padding-top-6-xs {
        padding-top: 3rem; } }
    @media (min-width: 480px) {
      .has-padding-top-6-sm {
        padding-top: 3rem; } }
    @media (min-width: 720px) {
      .has-padding-top-6-md {
        padding-top: 3rem; } }
    @media (min-width: 1024px) {
      .has-padding-top-6-nav {
        padding-top: 3rem; } }
    @media (min-width: 1024px) {
      .has-padding-top-6-lg {
        padding-top: 3rem; } }
    @media (min-width: 1280px) {
      .has-padding-top-6-xl {
        padding-top: 3rem; } }
    @media (min-width: 1920px) {
      .has-padding-top-6-xxl {
        padding-top: 3rem; } }
  .has-padding-top-7 {
    padding-top: 4rem; }
    @media (min-width: 0px) {
      .has-padding-top-7-xs {
        padding-top: 4rem; } }
    @media (min-width: 480px) {
      .has-padding-top-7-sm {
        padding-top: 4rem; } }
    @media (min-width: 720px) {
      .has-padding-top-7-md {
        padding-top: 4rem; } }
    @media (min-width: 1024px) {
      .has-padding-top-7-nav {
        padding-top: 4rem; } }
    @media (min-width: 1024px) {
      .has-padding-top-7-lg {
        padding-top: 4rem; } }
    @media (min-width: 1280px) {
      .has-padding-top-7-xl {
        padding-top: 4rem; } }
    @media (min-width: 1920px) {
      .has-padding-top-7-xxl {
        padding-top: 4rem; } }
  .has-padding-top-8 {
    padding-top: 5rem; }
    @media (min-width: 0px) {
      .has-padding-top-8-xs {
        padding-top: 5rem; } }
    @media (min-width: 480px) {
      .has-padding-top-8-sm {
        padding-top: 5rem; } }
    @media (min-width: 720px) {
      .has-padding-top-8-md {
        padding-top: 5rem; } }
    @media (min-width: 1024px) {
      .has-padding-top-8-nav {
        padding-top: 5rem; } }
    @media (min-width: 1024px) {
      .has-padding-top-8-lg {
        padding-top: 5rem; } }
    @media (min-width: 1280px) {
      .has-padding-top-8-xl {
        padding-top: 5rem; } }
    @media (min-width: 1920px) {
      .has-padding-top-8-xxl {
        padding-top: 5rem; } }

.has-padding-bottom {
  padding-bottom:  1rem; }
  @media (min-width: 0px) {
    .has-padding-bottom-xs {
      padding-bottom:  1rem; } }
  @media (min-width: 480px) {
    .has-padding-bottom-sm {
      padding-bottom:  1rem; } }
  @media (min-width: 720px) {
    .has-padding-bottom-md {
      padding-bottom:  1rem; } }
  @media (min-width: 1024px) {
    .has-padding-bottom-nav {
      padding-bottom:  1rem; } }
  @media (min-width: 1024px) {
    .has-padding-bottom-lg {
      padding-bottom:  1rem; } }
  @media (min-width: 1280px) {
    .has-padding-bottom-xl {
      padding-bottom:  1rem; } }
  @media (min-width: 1920px) {
    .has-padding-bottom-xxl {
      padding-bottom:  1rem; } }
  .has-padding-bottom-smallest {
    padding-bottom:  0.25rem; }
    @media (min-width: 0px) {
      .has-padding-bottom-smallest-xs {
        padding-bottom:  0.25rem; } }
    @media (min-width: 480px) {
      .has-padding-bottom-smallest-sm {
        padding-bottom:  0.25rem; } }
    @media (min-width: 720px) {
      .has-padding-bottom-smallest-md {
        padding-bottom:  0.25rem; } }
    @media (min-width: 1024px) {
      .has-padding-bottom-smallest-nav {
        padding-bottom:  0.25rem; } }
    @media (min-width: 1024px) {
      .has-padding-bottom-smallest-lg {
        padding-bottom:  0.25rem; } }
    @media (min-width: 1280px) {
      .has-padding-bottom-smallest-xl {
        padding-bottom:  0.25rem; } }
    @media (min-width: 1920px) {
      .has-padding-bottom-smallest-xxl {
        padding-bottom:  0.25rem; } }
  .has-padding-bottom-small {
    padding-bottom:  0.5rem; }
    @media (min-width: 0px) {
      .has-padding-bottom-small-xs {
        padding-bottom:  0.5rem; } }
    @media (min-width: 480px) {
      .has-padding-bottom-small-sm {
        padding-bottom:  0.5rem; } }
    @media (min-width: 720px) {
      .has-padding-bottom-small-md {
        padding-bottom:  0.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-bottom-small-nav {
        padding-bottom:  0.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-bottom-small-lg {
        padding-bottom:  0.5rem; } }
    @media (min-width: 1280px) {
      .has-padding-bottom-small-xl {
        padding-bottom:  0.5rem; } }
    @media (min-width: 1920px) {
      .has-padding-bottom-small-xxl {
        padding-bottom:  0.5rem; } }
  .has-padding-bottom-big {
    padding-bottom:  2rem; }
    @media (min-width: 0px) {
      .has-padding-bottom-big-xs {
        padding-bottom:  2rem; } }
    @media (min-width: 480px) {
      .has-padding-bottom-big-sm {
        padding-bottom:  2rem; } }
    @media (min-width: 720px) {
      .has-padding-bottom-big-md {
        padding-bottom:  2rem; } }
    @media (min-width: 1024px) {
      .has-padding-bottom-big-nav {
        padding-bottom:  2rem; } }
    @media (min-width: 1024px) {
      .has-padding-bottom-big-lg {
        padding-bottom:  2rem; } }
    @media (min-width: 1280px) {
      .has-padding-bottom-big-xl {
        padding-bottom:  2rem; } }
    @media (min-width: 1920px) {
      .has-padding-bottom-big-xxl {
        padding-bottom:  2rem; } }
  .has-padding-bottom-0 {
    padding-bottom: 0; }
    @media (min-width: 0px) {
      .has-padding-bottom-0-xs {
        padding-bottom: 0; } }
    @media (min-width: 480px) {
      .has-padding-bottom-0-sm {
        padding-bottom: 0; } }
    @media (min-width: 720px) {
      .has-padding-bottom-0-md {
        padding-bottom: 0; } }
    @media (min-width: 1024px) {
      .has-padding-bottom-0-nav {
        padding-bottom: 0; } }
    @media (min-width: 1024px) {
      .has-padding-bottom-0-lg {
        padding-bottom: 0; } }
    @media (min-width: 1280px) {
      .has-padding-bottom-0-xl {
        padding-bottom: 0; } }
    @media (min-width: 1920px) {
      .has-padding-bottom-0-xxl {
        padding-bottom: 0; } }
  .has-padding-bottom-1 {
    padding-bottom: 0.25rem; }
    @media (min-width: 0px) {
      .has-padding-bottom-1-xs {
        padding-bottom: 0.25rem; } }
    @media (min-width: 480px) {
      .has-padding-bottom-1-sm {
        padding-bottom: 0.25rem; } }
    @media (min-width: 720px) {
      .has-padding-bottom-1-md {
        padding-bottom: 0.25rem; } }
    @media (min-width: 1024px) {
      .has-padding-bottom-1-nav {
        padding-bottom: 0.25rem; } }
    @media (min-width: 1024px) {
      .has-padding-bottom-1-lg {
        padding-bottom: 0.25rem; } }
    @media (min-width: 1280px) {
      .has-padding-bottom-1-xl {
        padding-bottom: 0.25rem; } }
    @media (min-width: 1920px) {
      .has-padding-bottom-1-xxl {
        padding-bottom: 0.25rem; } }
  .has-padding-bottom-2 {
    padding-bottom: 0.5rem; }
    @media (min-width: 0px) {
      .has-padding-bottom-2-xs {
        padding-bottom: 0.5rem; } }
    @media (min-width: 480px) {
      .has-padding-bottom-2-sm {
        padding-bottom: 0.5rem; } }
    @media (min-width: 720px) {
      .has-padding-bottom-2-md {
        padding-bottom: 0.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-bottom-2-nav {
        padding-bottom: 0.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-bottom-2-lg {
        padding-bottom: 0.5rem; } }
    @media (min-width: 1280px) {
      .has-padding-bottom-2-xl {
        padding-bottom: 0.5rem; } }
    @media (min-width: 1920px) {
      .has-padding-bottom-2-xxl {
        padding-bottom: 0.5rem; } }
  .has-padding-bottom-3 {
    padding-bottom: 1rem; }
    @media (min-width: 0px) {
      .has-padding-bottom-3-xs {
        padding-bottom: 1rem; } }
    @media (min-width: 480px) {
      .has-padding-bottom-3-sm {
        padding-bottom: 1rem; } }
    @media (min-width: 720px) {
      .has-padding-bottom-3-md {
        padding-bottom: 1rem; } }
    @media (min-width: 1024px) {
      .has-padding-bottom-3-nav {
        padding-bottom: 1rem; } }
    @media (min-width: 1024px) {
      .has-padding-bottom-3-lg {
        padding-bottom: 1rem; } }
    @media (min-width: 1280px) {
      .has-padding-bottom-3-xl {
        padding-bottom: 1rem; } }
    @media (min-width: 1920px) {
      .has-padding-bottom-3-xxl {
        padding-bottom: 1rem; } }
  .has-padding-bottom-4 {
    padding-bottom: 1.5rem; }
    @media (min-width: 0px) {
      .has-padding-bottom-4-xs {
        padding-bottom: 1.5rem; } }
    @media (min-width: 480px) {
      .has-padding-bottom-4-sm {
        padding-bottom: 1.5rem; } }
    @media (min-width: 720px) {
      .has-padding-bottom-4-md {
        padding-bottom: 1.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-bottom-4-nav {
        padding-bottom: 1.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-bottom-4-lg {
        padding-bottom: 1.5rem; } }
    @media (min-width: 1280px) {
      .has-padding-bottom-4-xl {
        padding-bottom: 1.5rem; } }
    @media (min-width: 1920px) {
      .has-padding-bottom-4-xxl {
        padding-bottom: 1.5rem; } }
  .has-padding-bottom-5 {
    padding-bottom: 2rem; }
    @media (min-width: 0px) {
      .has-padding-bottom-5-xs {
        padding-bottom: 2rem; } }
    @media (min-width: 480px) {
      .has-padding-bottom-5-sm {
        padding-bottom: 2rem; } }
    @media (min-width: 720px) {
      .has-padding-bottom-5-md {
        padding-bottom: 2rem; } }
    @media (min-width: 1024px) {
      .has-padding-bottom-5-nav {
        padding-bottom: 2rem; } }
    @media (min-width: 1024px) {
      .has-padding-bottom-5-lg {
        padding-bottom: 2rem; } }
    @media (min-width: 1280px) {
      .has-padding-bottom-5-xl {
        padding-bottom: 2rem; } }
    @media (min-width: 1920px) {
      .has-padding-bottom-5-xxl {
        padding-bottom: 2rem; } }
  .has-padding-bottom-6 {
    padding-bottom: 3rem; }
    @media (min-width: 0px) {
      .has-padding-bottom-6-xs {
        padding-bottom: 3rem; } }
    @media (min-width: 480px) {
      .has-padding-bottom-6-sm {
        padding-bottom: 3rem; } }
    @media (min-width: 720px) {
      .has-padding-bottom-6-md {
        padding-bottom: 3rem; } }
    @media (min-width: 1024px) {
      .has-padding-bottom-6-nav {
        padding-bottom: 3rem; } }
    @media (min-width: 1024px) {
      .has-padding-bottom-6-lg {
        padding-bottom: 3rem; } }
    @media (min-width: 1280px) {
      .has-padding-bottom-6-xl {
        padding-bottom: 3rem; } }
    @media (min-width: 1920px) {
      .has-padding-bottom-6-xxl {
        padding-bottom: 3rem; } }
  .has-padding-bottom-7 {
    padding-bottom: 4rem; }
    @media (min-width: 0px) {
      .has-padding-bottom-7-xs {
        padding-bottom: 4rem; } }
    @media (min-width: 480px) {
      .has-padding-bottom-7-sm {
        padding-bottom: 4rem; } }
    @media (min-width: 720px) {
      .has-padding-bottom-7-md {
        padding-bottom: 4rem; } }
    @media (min-width: 1024px) {
      .has-padding-bottom-7-nav {
        padding-bottom: 4rem; } }
    @media (min-width: 1024px) {
      .has-padding-bottom-7-lg {
        padding-bottom: 4rem; } }
    @media (min-width: 1280px) {
      .has-padding-bottom-7-xl {
        padding-bottom: 4rem; } }
    @media (min-width: 1920px) {
      .has-padding-bottom-7-xxl {
        padding-bottom: 4rem; } }
  .has-padding-bottom-8 {
    padding-bottom: 5rem; }
    @media (min-width: 0px) {
      .has-padding-bottom-8-xs {
        padding-bottom: 5rem; } }
    @media (min-width: 480px) {
      .has-padding-bottom-8-sm {
        padding-bottom: 5rem; } }
    @media (min-width: 720px) {
      .has-padding-bottom-8-md {
        padding-bottom: 5rem; } }
    @media (min-width: 1024px) {
      .has-padding-bottom-8-nav {
        padding-bottom: 5rem; } }
    @media (min-width: 1024px) {
      .has-padding-bottom-8-lg {
        padding-bottom: 5rem; } }
    @media (min-width: 1280px) {
      .has-padding-bottom-8-xl {
        padding-bottom: 5rem; } }
    @media (min-width: 1920px) {
      .has-padding-bottom-8-xxl {
        padding-bottom: 5rem; } }

.has-padding-center {
  padding-left:  1rem;
  padding-right:  1rem; }
  @media (min-width: 0px) {
    .has-padding-center-xs {
      padding-left:  1rem;
      padding-right:  1rem; } }
  @media (min-width: 480px) {
    .has-padding-center-sm {
      padding-left:  1rem;
      padding-right:  1rem; } }
  @media (min-width: 720px) {
    .has-padding-center-md {
      padding-left:  1rem;
      padding-right:  1rem; } }
  @media (min-width: 1024px) {
    .has-padding-center-nav {
      padding-left:  1rem;
      padding-right:  1rem; } }
  @media (min-width: 1024px) {
    .has-padding-center-lg {
      padding-left:  1rem;
      padding-right:  1rem; } }
  @media (min-width: 1280px) {
    .has-padding-center-xl {
      padding-left:  1rem;
      padding-right:  1rem; } }
  @media (min-width: 1920px) {
    .has-padding-center-xxl {
      padding-left:  1rem;
      padding-right:  1rem; } }
  .has-padding-center-smallest {
    padding-left:  0.25rem;
    padding-right:  0.25rem; }
    @media (min-width: 0px) {
      .has-padding-center-smallest-xs {
        padding-left:  0.25rem;
        padding-right:  0.25rem; } }
    @media (min-width: 480px) {
      .has-padding-center-smallest-sm {
        padding-left:  0.25rem;
        padding-right:  0.25rem; } }
    @media (min-width: 720px) {
      .has-padding-center-smallest-md {
        padding-left:  0.25rem;
        padding-right:  0.25rem; } }
    @media (min-width: 1024px) {
      .has-padding-center-smallest-nav {
        padding-left:  0.25rem;
        padding-right:  0.25rem; } }
    @media (min-width: 1024px) {
      .has-padding-center-smallest-lg {
        padding-left:  0.25rem;
        padding-right:  0.25rem; } }
    @media (min-width: 1280px) {
      .has-padding-center-smallest-xl {
        padding-left:  0.25rem;
        padding-right:  0.25rem; } }
    @media (min-width: 1920px) {
      .has-padding-center-smallest-xxl {
        padding-left:  0.25rem;
        padding-right:  0.25rem; } }
  .has-padding-center-small {
    padding-left:  0.5rem;
    padding-right:  0.5rem; }
    @media (min-width: 0px) {
      .has-padding-center-small-xs {
        padding-left:  0.5rem;
        padding-right:  0.5rem; } }
    @media (min-width: 480px) {
      .has-padding-center-small-sm {
        padding-left:  0.5rem;
        padding-right:  0.5rem; } }
    @media (min-width: 720px) {
      .has-padding-center-small-md {
        padding-left:  0.5rem;
        padding-right:  0.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-center-small-nav {
        padding-left:  0.5rem;
        padding-right:  0.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-center-small-lg {
        padding-left:  0.5rem;
        padding-right:  0.5rem; } }
    @media (min-width: 1280px) {
      .has-padding-center-small-xl {
        padding-left:  0.5rem;
        padding-right:  0.5rem; } }
    @media (min-width: 1920px) {
      .has-padding-center-small-xxl {
        padding-left:  0.5rem;
        padding-right:  0.5rem; } }
  .has-padding-center-big {
    padding-left:  2rem;
    padding-right:  2rem; }
    @media (min-width: 0px) {
      .has-padding-center-big-xs {
        padding-left:  2rem;
        padding-right:  2rem; } }
    @media (min-width: 480px) {
      .has-padding-center-big-sm {
        padding-left:  2rem;
        padding-right:  2rem; } }
    @media (min-width: 720px) {
      .has-padding-center-big-md {
        padding-left:  2rem;
        padding-right:  2rem; } }
    @media (min-width: 1024px) {
      .has-padding-center-big-nav {
        padding-left:  2rem;
        padding-right:  2rem; } }
    @media (min-width: 1024px) {
      .has-padding-center-big-lg {
        padding-left:  2rem;
        padding-right:  2rem; } }
    @media (min-width: 1280px) {
      .has-padding-center-big-xl {
        padding-left:  2rem;
        padding-right:  2rem; } }
    @media (min-width: 1920px) {
      .has-padding-center-big-xxl {
        padding-left:  2rem;
        padding-right:  2rem; } }
  .has-padding-center-0 {
    padding-left: 0;
    padding-right: 0; }
    @media (min-width: 0px) {
      .has-padding-center-0-xs {
        padding-left: 0;
        padding-right: 0; } }
    @media (min-width: 480px) {
      .has-padding-center-0-sm {
        padding-left: 0;
        padding-right: 0; } }
    @media (min-width: 720px) {
      .has-padding-center-0-md {
        padding-left: 0;
        padding-right: 0; } }
    @media (min-width: 1024px) {
      .has-padding-center-0-nav {
        padding-left: 0;
        padding-right: 0; } }
    @media (min-width: 1024px) {
      .has-padding-center-0-lg {
        padding-left: 0;
        padding-right: 0; } }
    @media (min-width: 1280px) {
      .has-padding-center-0-xl {
        padding-left: 0;
        padding-right: 0; } }
    @media (min-width: 1920px) {
      .has-padding-center-0-xxl {
        padding-left: 0;
        padding-right: 0; } }
  .has-padding-center-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem; }
    @media (min-width: 0px) {
      .has-padding-center-1-xs {
        padding-left: 0.25rem;
        padding-right: 0.25rem; } }
    @media (min-width: 480px) {
      .has-padding-center-1-sm {
        padding-left: 0.25rem;
        padding-right: 0.25rem; } }
    @media (min-width: 720px) {
      .has-padding-center-1-md {
        padding-left: 0.25rem;
        padding-right: 0.25rem; } }
    @media (min-width: 1024px) {
      .has-padding-center-1-nav {
        padding-left: 0.25rem;
        padding-right: 0.25rem; } }
    @media (min-width: 1024px) {
      .has-padding-center-1-lg {
        padding-left: 0.25rem;
        padding-right: 0.25rem; } }
    @media (min-width: 1280px) {
      .has-padding-center-1-xl {
        padding-left: 0.25rem;
        padding-right: 0.25rem; } }
    @media (min-width: 1920px) {
      .has-padding-center-1-xxl {
        padding-left: 0.25rem;
        padding-right: 0.25rem; } }
  .has-padding-center-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
    @media (min-width: 0px) {
      .has-padding-center-2-xs {
        padding-left: 0.5rem;
        padding-right: 0.5rem; } }
    @media (min-width: 480px) {
      .has-padding-center-2-sm {
        padding-left: 0.5rem;
        padding-right: 0.5rem; } }
    @media (min-width: 720px) {
      .has-padding-center-2-md {
        padding-left: 0.5rem;
        padding-right: 0.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-center-2-nav {
        padding-left: 0.5rem;
        padding-right: 0.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-center-2-lg {
        padding-left: 0.5rem;
        padding-right: 0.5rem; } }
    @media (min-width: 1280px) {
      .has-padding-center-2-xl {
        padding-left: 0.5rem;
        padding-right: 0.5rem; } }
    @media (min-width: 1920px) {
      .has-padding-center-2-xxl {
        padding-left: 0.5rem;
        padding-right: 0.5rem; } }
  .has-padding-center-3 {
    padding-left: 1rem;
    padding-right: 1rem; }
    @media (min-width: 0px) {
      .has-padding-center-3-xs {
        padding-left: 1rem;
        padding-right: 1rem; } }
    @media (min-width: 480px) {
      .has-padding-center-3-sm {
        padding-left: 1rem;
        padding-right: 1rem; } }
    @media (min-width: 720px) {
      .has-padding-center-3-md {
        padding-left: 1rem;
        padding-right: 1rem; } }
    @media (min-width: 1024px) {
      .has-padding-center-3-nav {
        padding-left: 1rem;
        padding-right: 1rem; } }
    @media (min-width: 1024px) {
      .has-padding-center-3-lg {
        padding-left: 1rem;
        padding-right: 1rem; } }
    @media (min-width: 1280px) {
      .has-padding-center-3-xl {
        padding-left: 1rem;
        padding-right: 1rem; } }
    @media (min-width: 1920px) {
      .has-padding-center-3-xxl {
        padding-left: 1rem;
        padding-right: 1rem; } }
  .has-padding-center-4 {
    padding-left: 1.5rem;
    padding-right: 1.5rem; }
    @media (min-width: 0px) {
      .has-padding-center-4-xs {
        padding-left: 1.5rem;
        padding-right: 1.5rem; } }
    @media (min-width: 480px) {
      .has-padding-center-4-sm {
        padding-left: 1.5rem;
        padding-right: 1.5rem; } }
    @media (min-width: 720px) {
      .has-padding-center-4-md {
        padding-left: 1.5rem;
        padding-right: 1.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-center-4-nav {
        padding-left: 1.5rem;
        padding-right: 1.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-center-4-lg {
        padding-left: 1.5rem;
        padding-right: 1.5rem; } }
    @media (min-width: 1280px) {
      .has-padding-center-4-xl {
        padding-left: 1.5rem;
        padding-right: 1.5rem; } }
    @media (min-width: 1920px) {
      .has-padding-center-4-xxl {
        padding-left: 1.5rem;
        padding-right: 1.5rem; } }
  .has-padding-center-5 {
    padding-left: 2rem;
    padding-right: 2rem; }
    @media (min-width: 0px) {
      .has-padding-center-5-xs {
        padding-left: 2rem;
        padding-right: 2rem; } }
    @media (min-width: 480px) {
      .has-padding-center-5-sm {
        padding-left: 2rem;
        padding-right: 2rem; } }
    @media (min-width: 720px) {
      .has-padding-center-5-md {
        padding-left: 2rem;
        padding-right: 2rem; } }
    @media (min-width: 1024px) {
      .has-padding-center-5-nav {
        padding-left: 2rem;
        padding-right: 2rem; } }
    @media (min-width: 1024px) {
      .has-padding-center-5-lg {
        padding-left: 2rem;
        padding-right: 2rem; } }
    @media (min-width: 1280px) {
      .has-padding-center-5-xl {
        padding-left: 2rem;
        padding-right: 2rem; } }
    @media (min-width: 1920px) {
      .has-padding-center-5-xxl {
        padding-left: 2rem;
        padding-right: 2rem; } }
  .has-padding-center-6 {
    padding-left: 3rem;
    padding-right: 3rem; }
    @media (min-width: 0px) {
      .has-padding-center-6-xs {
        padding-left: 3rem;
        padding-right: 3rem; } }
    @media (min-width: 480px) {
      .has-padding-center-6-sm {
        padding-left: 3rem;
        padding-right: 3rem; } }
    @media (min-width: 720px) {
      .has-padding-center-6-md {
        padding-left: 3rem;
        padding-right: 3rem; } }
    @media (min-width: 1024px) {
      .has-padding-center-6-nav {
        padding-left: 3rem;
        padding-right: 3rem; } }
    @media (min-width: 1024px) {
      .has-padding-center-6-lg {
        padding-left: 3rem;
        padding-right: 3rem; } }
    @media (min-width: 1280px) {
      .has-padding-center-6-xl {
        padding-left: 3rem;
        padding-right: 3rem; } }
    @media (min-width: 1920px) {
      .has-padding-center-6-xxl {
        padding-left: 3rem;
        padding-right: 3rem; } }
  .has-padding-center-7 {
    padding-left: 4rem;
    padding-right: 4rem; }
    @media (min-width: 0px) {
      .has-padding-center-7-xs {
        padding-left: 4rem;
        padding-right: 4rem; } }
    @media (min-width: 480px) {
      .has-padding-center-7-sm {
        padding-left: 4rem;
        padding-right: 4rem; } }
    @media (min-width: 720px) {
      .has-padding-center-7-md {
        padding-left: 4rem;
        padding-right: 4rem; } }
    @media (min-width: 1024px) {
      .has-padding-center-7-nav {
        padding-left: 4rem;
        padding-right: 4rem; } }
    @media (min-width: 1024px) {
      .has-padding-center-7-lg {
        padding-left: 4rem;
        padding-right: 4rem; } }
    @media (min-width: 1280px) {
      .has-padding-center-7-xl {
        padding-left: 4rem;
        padding-right: 4rem; } }
    @media (min-width: 1920px) {
      .has-padding-center-7-xxl {
        padding-left: 4rem;
        padding-right: 4rem; } }
  .has-padding-center-8 {
    padding-left: 5rem;
    padding-right: 5rem; }
    @media (min-width: 0px) {
      .has-padding-center-8-xs {
        padding-left: 5rem;
        padding-right: 5rem; } }
    @media (min-width: 480px) {
      .has-padding-center-8-sm {
        padding-left: 5rem;
        padding-right: 5rem; } }
    @media (min-width: 720px) {
      .has-padding-center-8-md {
        padding-left: 5rem;
        padding-right: 5rem; } }
    @media (min-width: 1024px) {
      .has-padding-center-8-nav {
        padding-left: 5rem;
        padding-right: 5rem; } }
    @media (min-width: 1024px) {
      .has-padding-center-8-lg {
        padding-left: 5rem;
        padding-right: 5rem; } }
    @media (min-width: 1280px) {
      .has-padding-center-8-xl {
        padding-left: 5rem;
        padding-right: 5rem; } }
    @media (min-width: 1920px) {
      .has-padding-center-8-xxl {
        padding-left: 5rem;
        padding-right: 5rem; } }

.has-padding-left {
  padding-left:  1rem; }
  @media (min-width: 0px) {
    .has-padding-left-xs {
      padding-left:  1rem; } }
  @media (min-width: 480px) {
    .has-padding-left-sm {
      padding-left:  1rem; } }
  @media (min-width: 720px) {
    .has-padding-left-md {
      padding-left:  1rem; } }
  @media (min-width: 1024px) {
    .has-padding-left-nav {
      padding-left:  1rem; } }
  @media (min-width: 1024px) {
    .has-padding-left-lg {
      padding-left:  1rem; } }
  @media (min-width: 1280px) {
    .has-padding-left-xl {
      padding-left:  1rem; } }
  @media (min-width: 1920px) {
    .has-padding-left-xxl {
      padding-left:  1rem; } }
  .has-padding-left-smallest {
    padding-left:  0.25rem; }
    @media (min-width: 0px) {
      .has-padding-left-smallest-xs {
        padding-left:  0.25rem; } }
    @media (min-width: 480px) {
      .has-padding-left-smallest-sm {
        padding-left:  0.25rem; } }
    @media (min-width: 720px) {
      .has-padding-left-smallest-md {
        padding-left:  0.25rem; } }
    @media (min-width: 1024px) {
      .has-padding-left-smallest-nav {
        padding-left:  0.25rem; } }
    @media (min-width: 1024px) {
      .has-padding-left-smallest-lg {
        padding-left:  0.25rem; } }
    @media (min-width: 1280px) {
      .has-padding-left-smallest-xl {
        padding-left:  0.25rem; } }
    @media (min-width: 1920px) {
      .has-padding-left-smallest-xxl {
        padding-left:  0.25rem; } }
  .has-padding-left-small {
    padding-left:  0.5rem; }
    @media (min-width: 0px) {
      .has-padding-left-small-xs {
        padding-left:  0.5rem; } }
    @media (min-width: 480px) {
      .has-padding-left-small-sm {
        padding-left:  0.5rem; } }
    @media (min-width: 720px) {
      .has-padding-left-small-md {
        padding-left:  0.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-left-small-nav {
        padding-left:  0.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-left-small-lg {
        padding-left:  0.5rem; } }
    @media (min-width: 1280px) {
      .has-padding-left-small-xl {
        padding-left:  0.5rem; } }
    @media (min-width: 1920px) {
      .has-padding-left-small-xxl {
        padding-left:  0.5rem; } }
  .has-padding-left-big {
    padding-left:  2rem; }
    @media (min-width: 0px) {
      .has-padding-left-big-xs {
        padding-left:  2rem; } }
    @media (min-width: 480px) {
      .has-padding-left-big-sm {
        padding-left:  2rem; } }
    @media (min-width: 720px) {
      .has-padding-left-big-md {
        padding-left:  2rem; } }
    @media (min-width: 1024px) {
      .has-padding-left-big-nav {
        padding-left:  2rem; } }
    @media (min-width: 1024px) {
      .has-padding-left-big-lg {
        padding-left:  2rem; } }
    @media (min-width: 1280px) {
      .has-padding-left-big-xl {
        padding-left:  2rem; } }
    @media (min-width: 1920px) {
      .has-padding-left-big-xxl {
        padding-left:  2rem; } }
  .has-padding-left-0 {
    padding-left: 0; }
    @media (min-width: 0px) {
      .has-padding-left-0-xs {
        padding-left: 0; } }
    @media (min-width: 480px) {
      .has-padding-left-0-sm {
        padding-left: 0; } }
    @media (min-width: 720px) {
      .has-padding-left-0-md {
        padding-left: 0; } }
    @media (min-width: 1024px) {
      .has-padding-left-0-nav {
        padding-left: 0; } }
    @media (min-width: 1024px) {
      .has-padding-left-0-lg {
        padding-left: 0; } }
    @media (min-width: 1280px) {
      .has-padding-left-0-xl {
        padding-left: 0; } }
    @media (min-width: 1920px) {
      .has-padding-left-0-xxl {
        padding-left: 0; } }
  .has-padding-left-1 {
    padding-left: 0.25rem; }
    @media (min-width: 0px) {
      .has-padding-left-1-xs {
        padding-left: 0.25rem; } }
    @media (min-width: 480px) {
      .has-padding-left-1-sm {
        padding-left: 0.25rem; } }
    @media (min-width: 720px) {
      .has-padding-left-1-md {
        padding-left: 0.25rem; } }
    @media (min-width: 1024px) {
      .has-padding-left-1-nav {
        padding-left: 0.25rem; } }
    @media (min-width: 1024px) {
      .has-padding-left-1-lg {
        padding-left: 0.25rem; } }
    @media (min-width: 1280px) {
      .has-padding-left-1-xl {
        padding-left: 0.25rem; } }
    @media (min-width: 1920px) {
      .has-padding-left-1-xxl {
        padding-left: 0.25rem; } }
  .has-padding-left-2 {
    padding-left: 0.5rem; }
    @media (min-width: 0px) {
      .has-padding-left-2-xs {
        padding-left: 0.5rem; } }
    @media (min-width: 480px) {
      .has-padding-left-2-sm {
        padding-left: 0.5rem; } }
    @media (min-width: 720px) {
      .has-padding-left-2-md {
        padding-left: 0.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-left-2-nav {
        padding-left: 0.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-left-2-lg {
        padding-left: 0.5rem; } }
    @media (min-width: 1280px) {
      .has-padding-left-2-xl {
        padding-left: 0.5rem; } }
    @media (min-width: 1920px) {
      .has-padding-left-2-xxl {
        padding-left: 0.5rem; } }
  .has-padding-left-3 {
    padding-left: 1rem; }
    @media (min-width: 0px) {
      .has-padding-left-3-xs {
        padding-left: 1rem; } }
    @media (min-width: 480px) {
      .has-padding-left-3-sm {
        padding-left: 1rem; } }
    @media (min-width: 720px) {
      .has-padding-left-3-md {
        padding-left: 1rem; } }
    @media (min-width: 1024px) {
      .has-padding-left-3-nav {
        padding-left: 1rem; } }
    @media (min-width: 1024px) {
      .has-padding-left-3-lg {
        padding-left: 1rem; } }
    @media (min-width: 1280px) {
      .has-padding-left-3-xl {
        padding-left: 1rem; } }
    @media (min-width: 1920px) {
      .has-padding-left-3-xxl {
        padding-left: 1rem; } }
  .has-padding-left-4 {
    padding-left: 1.5rem; }
    @media (min-width: 0px) {
      .has-padding-left-4-xs {
        padding-left: 1.5rem; } }
    @media (min-width: 480px) {
      .has-padding-left-4-sm {
        padding-left: 1.5rem; } }
    @media (min-width: 720px) {
      .has-padding-left-4-md {
        padding-left: 1.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-left-4-nav {
        padding-left: 1.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-left-4-lg {
        padding-left: 1.5rem; } }
    @media (min-width: 1280px) {
      .has-padding-left-4-xl {
        padding-left: 1.5rem; } }
    @media (min-width: 1920px) {
      .has-padding-left-4-xxl {
        padding-left: 1.5rem; } }
  .has-padding-left-5 {
    padding-left: 2rem; }
    @media (min-width: 0px) {
      .has-padding-left-5-xs {
        padding-left: 2rem; } }
    @media (min-width: 480px) {
      .has-padding-left-5-sm {
        padding-left: 2rem; } }
    @media (min-width: 720px) {
      .has-padding-left-5-md {
        padding-left: 2rem; } }
    @media (min-width: 1024px) {
      .has-padding-left-5-nav {
        padding-left: 2rem; } }
    @media (min-width: 1024px) {
      .has-padding-left-5-lg {
        padding-left: 2rem; } }
    @media (min-width: 1280px) {
      .has-padding-left-5-xl {
        padding-left: 2rem; } }
    @media (min-width: 1920px) {
      .has-padding-left-5-xxl {
        padding-left: 2rem; } }
  .has-padding-left-6 {
    padding-left: 3rem; }
    @media (min-width: 0px) {
      .has-padding-left-6-xs {
        padding-left: 3rem; } }
    @media (min-width: 480px) {
      .has-padding-left-6-sm {
        padding-left: 3rem; } }
    @media (min-width: 720px) {
      .has-padding-left-6-md {
        padding-left: 3rem; } }
    @media (min-width: 1024px) {
      .has-padding-left-6-nav {
        padding-left: 3rem; } }
    @media (min-width: 1024px) {
      .has-padding-left-6-lg {
        padding-left: 3rem; } }
    @media (min-width: 1280px) {
      .has-padding-left-6-xl {
        padding-left: 3rem; } }
    @media (min-width: 1920px) {
      .has-padding-left-6-xxl {
        padding-left: 3rem; } }
  .has-padding-left-7 {
    padding-left: 4rem; }
    @media (min-width: 0px) {
      .has-padding-left-7-xs {
        padding-left: 4rem; } }
    @media (min-width: 480px) {
      .has-padding-left-7-sm {
        padding-left: 4rem; } }
    @media (min-width: 720px) {
      .has-padding-left-7-md {
        padding-left: 4rem; } }
    @media (min-width: 1024px) {
      .has-padding-left-7-nav {
        padding-left: 4rem; } }
    @media (min-width: 1024px) {
      .has-padding-left-7-lg {
        padding-left: 4rem; } }
    @media (min-width: 1280px) {
      .has-padding-left-7-xl {
        padding-left: 4rem; } }
    @media (min-width: 1920px) {
      .has-padding-left-7-xxl {
        padding-left: 4rem; } }
  .has-padding-left-8 {
    padding-left: 5rem; }
    @media (min-width: 0px) {
      .has-padding-left-8-xs {
        padding-left: 5rem; } }
    @media (min-width: 480px) {
      .has-padding-left-8-sm {
        padding-left: 5rem; } }
    @media (min-width: 720px) {
      .has-padding-left-8-md {
        padding-left: 5rem; } }
    @media (min-width: 1024px) {
      .has-padding-left-8-nav {
        padding-left: 5rem; } }
    @media (min-width: 1024px) {
      .has-padding-left-8-lg {
        padding-left: 5rem; } }
    @media (min-width: 1280px) {
      .has-padding-left-8-xl {
        padding-left: 5rem; } }
    @media (min-width: 1920px) {
      .has-padding-left-8-xxl {
        padding-left: 5rem; } }

.has-padding-right {
  padding-right:  1rem; }
  @media (min-width: 0px) {
    .has-padding-right-xs {
      padding-right:  1rem; } }
  @media (min-width: 480px) {
    .has-padding-right-sm {
      padding-right:  1rem; } }
  @media (min-width: 720px) {
    .has-padding-right-md {
      padding-right:  1rem; } }
  @media (min-width: 1024px) {
    .has-padding-right-nav {
      padding-right:  1rem; } }
  @media (min-width: 1024px) {
    .has-padding-right-lg {
      padding-right:  1rem; } }
  @media (min-width: 1280px) {
    .has-padding-right-xl {
      padding-right:  1rem; } }
  @media (min-width: 1920px) {
    .has-padding-right-xxl {
      padding-right:  1rem; } }
  .has-padding-right-smallest {
    padding-right:  0.25rem; }
    @media (min-width: 0px) {
      .has-padding-right-smallest-xs {
        padding-right:  0.25rem; } }
    @media (min-width: 480px) {
      .has-padding-right-smallest-sm {
        padding-right:  0.25rem; } }
    @media (min-width: 720px) {
      .has-padding-right-smallest-md {
        padding-right:  0.25rem; } }
    @media (min-width: 1024px) {
      .has-padding-right-smallest-nav {
        padding-right:  0.25rem; } }
    @media (min-width: 1024px) {
      .has-padding-right-smallest-lg {
        padding-right:  0.25rem; } }
    @media (min-width: 1280px) {
      .has-padding-right-smallest-xl {
        padding-right:  0.25rem; } }
    @media (min-width: 1920px) {
      .has-padding-right-smallest-xxl {
        padding-right:  0.25rem; } }
  .has-padding-right-small {
    padding-right:  0.5rem; }
    @media (min-width: 0px) {
      .has-padding-right-small-xs {
        padding-right:  0.5rem; } }
    @media (min-width: 480px) {
      .has-padding-right-small-sm {
        padding-right:  0.5rem; } }
    @media (min-width: 720px) {
      .has-padding-right-small-md {
        padding-right:  0.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-right-small-nav {
        padding-right:  0.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-right-small-lg {
        padding-right:  0.5rem; } }
    @media (min-width: 1280px) {
      .has-padding-right-small-xl {
        padding-right:  0.5rem; } }
    @media (min-width: 1920px) {
      .has-padding-right-small-xxl {
        padding-right:  0.5rem; } }
  .has-padding-right-big {
    padding-right:  2rem; }
    @media (min-width: 0px) {
      .has-padding-right-big-xs {
        padding-right:  2rem; } }
    @media (min-width: 480px) {
      .has-padding-right-big-sm {
        padding-right:  2rem; } }
    @media (min-width: 720px) {
      .has-padding-right-big-md {
        padding-right:  2rem; } }
    @media (min-width: 1024px) {
      .has-padding-right-big-nav {
        padding-right:  2rem; } }
    @media (min-width: 1024px) {
      .has-padding-right-big-lg {
        padding-right:  2rem; } }
    @media (min-width: 1280px) {
      .has-padding-right-big-xl {
        padding-right:  2rem; } }
    @media (min-width: 1920px) {
      .has-padding-right-big-xxl {
        padding-right:  2rem; } }
  .has-padding-right-0 {
    padding-right: 0; }
    @media (min-width: 0px) {
      .has-padding-right-0-xs {
        padding-right: 0; } }
    @media (min-width: 480px) {
      .has-padding-right-0-sm {
        padding-right: 0; } }
    @media (min-width: 720px) {
      .has-padding-right-0-md {
        padding-right: 0; } }
    @media (min-width: 1024px) {
      .has-padding-right-0-nav {
        padding-right: 0; } }
    @media (min-width: 1024px) {
      .has-padding-right-0-lg {
        padding-right: 0; } }
    @media (min-width: 1280px) {
      .has-padding-right-0-xl {
        padding-right: 0; } }
    @media (min-width: 1920px) {
      .has-padding-right-0-xxl {
        padding-right: 0; } }
  .has-padding-right-1 {
    padding-right: 0.25rem; }
    @media (min-width: 0px) {
      .has-padding-right-1-xs {
        padding-right: 0.25rem; } }
    @media (min-width: 480px) {
      .has-padding-right-1-sm {
        padding-right: 0.25rem; } }
    @media (min-width: 720px) {
      .has-padding-right-1-md {
        padding-right: 0.25rem; } }
    @media (min-width: 1024px) {
      .has-padding-right-1-nav {
        padding-right: 0.25rem; } }
    @media (min-width: 1024px) {
      .has-padding-right-1-lg {
        padding-right: 0.25rem; } }
    @media (min-width: 1280px) {
      .has-padding-right-1-xl {
        padding-right: 0.25rem; } }
    @media (min-width: 1920px) {
      .has-padding-right-1-xxl {
        padding-right: 0.25rem; } }
  .has-padding-right-2 {
    padding-right: 0.5rem; }
    @media (min-width: 0px) {
      .has-padding-right-2-xs {
        padding-right: 0.5rem; } }
    @media (min-width: 480px) {
      .has-padding-right-2-sm {
        padding-right: 0.5rem; } }
    @media (min-width: 720px) {
      .has-padding-right-2-md {
        padding-right: 0.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-right-2-nav {
        padding-right: 0.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-right-2-lg {
        padding-right: 0.5rem; } }
    @media (min-width: 1280px) {
      .has-padding-right-2-xl {
        padding-right: 0.5rem; } }
    @media (min-width: 1920px) {
      .has-padding-right-2-xxl {
        padding-right: 0.5rem; } }
  .has-padding-right-3 {
    padding-right: 1rem; }
    @media (min-width: 0px) {
      .has-padding-right-3-xs {
        padding-right: 1rem; } }
    @media (min-width: 480px) {
      .has-padding-right-3-sm {
        padding-right: 1rem; } }
    @media (min-width: 720px) {
      .has-padding-right-3-md {
        padding-right: 1rem; } }
    @media (min-width: 1024px) {
      .has-padding-right-3-nav {
        padding-right: 1rem; } }
    @media (min-width: 1024px) {
      .has-padding-right-3-lg {
        padding-right: 1rem; } }
    @media (min-width: 1280px) {
      .has-padding-right-3-xl {
        padding-right: 1rem; } }
    @media (min-width: 1920px) {
      .has-padding-right-3-xxl {
        padding-right: 1rem; } }
  .has-padding-right-4 {
    padding-right: 1.5rem; }
    @media (min-width: 0px) {
      .has-padding-right-4-xs {
        padding-right: 1.5rem; } }
    @media (min-width: 480px) {
      .has-padding-right-4-sm {
        padding-right: 1.5rem; } }
    @media (min-width: 720px) {
      .has-padding-right-4-md {
        padding-right: 1.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-right-4-nav {
        padding-right: 1.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-right-4-lg {
        padding-right: 1.5rem; } }
    @media (min-width: 1280px) {
      .has-padding-right-4-xl {
        padding-right: 1.5rem; } }
    @media (min-width: 1920px) {
      .has-padding-right-4-xxl {
        padding-right: 1.5rem; } }
  .has-padding-right-5 {
    padding-right: 2rem; }
    @media (min-width: 0px) {
      .has-padding-right-5-xs {
        padding-right: 2rem; } }
    @media (min-width: 480px) {
      .has-padding-right-5-sm {
        padding-right: 2rem; } }
    @media (min-width: 720px) {
      .has-padding-right-5-md {
        padding-right: 2rem; } }
    @media (min-width: 1024px) {
      .has-padding-right-5-nav {
        padding-right: 2rem; } }
    @media (min-width: 1024px) {
      .has-padding-right-5-lg {
        padding-right: 2rem; } }
    @media (min-width: 1280px) {
      .has-padding-right-5-xl {
        padding-right: 2rem; } }
    @media (min-width: 1920px) {
      .has-padding-right-5-xxl {
        padding-right: 2rem; } }
  .has-padding-right-6 {
    padding-right: 3rem; }
    @media (min-width: 0px) {
      .has-padding-right-6-xs {
        padding-right: 3rem; } }
    @media (min-width: 480px) {
      .has-padding-right-6-sm {
        padding-right: 3rem; } }
    @media (min-width: 720px) {
      .has-padding-right-6-md {
        padding-right: 3rem; } }
    @media (min-width: 1024px) {
      .has-padding-right-6-nav {
        padding-right: 3rem; } }
    @media (min-width: 1024px) {
      .has-padding-right-6-lg {
        padding-right: 3rem; } }
    @media (min-width: 1280px) {
      .has-padding-right-6-xl {
        padding-right: 3rem; } }
    @media (min-width: 1920px) {
      .has-padding-right-6-xxl {
        padding-right: 3rem; } }
  .has-padding-right-7 {
    padding-right: 4rem; }
    @media (min-width: 0px) {
      .has-padding-right-7-xs {
        padding-right: 4rem; } }
    @media (min-width: 480px) {
      .has-padding-right-7-sm {
        padding-right: 4rem; } }
    @media (min-width: 720px) {
      .has-padding-right-7-md {
        padding-right: 4rem; } }
    @media (min-width: 1024px) {
      .has-padding-right-7-nav {
        padding-right: 4rem; } }
    @media (min-width: 1024px) {
      .has-padding-right-7-lg {
        padding-right: 4rem; } }
    @media (min-width: 1280px) {
      .has-padding-right-7-xl {
        padding-right: 4rem; } }
    @media (min-width: 1920px) {
      .has-padding-right-7-xxl {
        padding-right: 4rem; } }
  .has-padding-right-8 {
    padding-right: 5rem; }
    @media (min-width: 0px) {
      .has-padding-right-8-xs {
        padding-right: 5rem; } }
    @media (min-width: 480px) {
      .has-padding-right-8-sm {
        padding-right: 5rem; } }
    @media (min-width: 720px) {
      .has-padding-right-8-md {
        padding-right: 5rem; } }
    @media (min-width: 1024px) {
      .has-padding-right-8-nav {
        padding-right: 5rem; } }
    @media (min-width: 1024px) {
      .has-padding-right-8-lg {
        padding-right: 5rem; } }
    @media (min-width: 1280px) {
      .has-padding-right-8-xl {
        padding-right: 5rem; } }
    @media (min-width: 1920px) {
      .has-padding-right-8-xxl {
        padding-right: 5rem; } }

.no-padding {
  padding: 0 !important; }
  @media (min-width: 0px) {
    .no-padding-xs {
      padding: 0 !important; } }
  @media (min-width: 480px) {
    .no-padding-sm {
      padding: 0 !important; } }
  @media (min-width: 720px) {
    .no-padding-md {
      padding: 0 !important; } }
  @media (min-width: 1024px) {
    .no-padding-nav {
      padding: 0 !important; } }
  @media (min-width: 1024px) {
    .no-padding-lg {
      padding: 0 !important; } }
  @media (min-width: 1280px) {
    .no-padding-xl {
      padding: 0 !important; } }
  @media (min-width: 1920px) {
    .no-padding-xxl {
      padding: 0 !important; } }

.no-padding-middle {
  padding-bottom: 0 !important;
  padding-top: 0 !important; }
  @media (min-width: 0px) {
    .no-padding-middle-xs {
      padding-bottom: 0 !important;
      padding-top: 0 !important; } }
  @media (min-width: 480px) {
    .no-padding-middle-sm {
      padding-bottom: 0 !important;
      padding-top: 0 !important; } }
  @media (min-width: 720px) {
    .no-padding-middle-md {
      padding-bottom: 0 !important;
      padding-top: 0 !important; } }
  @media (min-width: 1024px) {
    .no-padding-middle-nav {
      padding-bottom: 0 !important;
      padding-top: 0 !important; } }
  @media (min-width: 1024px) {
    .no-padding-middle-lg {
      padding-bottom: 0 !important;
      padding-top: 0 !important; } }
  @media (min-width: 1280px) {
    .no-padding-middle-xl {
      padding-bottom: 0 !important;
      padding-top: 0 !important; } }
  @media (min-width: 1920px) {
    .no-padding-middle-xxl {
      padding-bottom: 0 !important;
      padding-top: 0 !important; } }

.no-padding-top {
  padding-top: 0 !important; }
  @media (min-width: 0px) {
    .no-padding-top-xs {
      padding-top: 0 !important; } }
  @media (min-width: 480px) {
    .no-padding-top-sm {
      padding-top: 0 !important; } }
  @media (min-width: 720px) {
    .no-padding-top-md {
      padding-top: 0 !important; } }
  @media (min-width: 1024px) {
    .no-padding-top-nav {
      padding-top: 0 !important; } }
  @media (min-width: 1024px) {
    .no-padding-top-lg {
      padding-top: 0 !important; } }
  @media (min-width: 1280px) {
    .no-padding-top-xl {
      padding-top: 0 !important; } }
  @media (min-width: 1920px) {
    .no-padding-top-xxl {
      padding-top: 0 !important; } }

.no-padding-bottom {
  padding-bottom: 0 !important; }
  @media (min-width: 0px) {
    .no-padding-bottom-xs {
      padding-bottom: 0 !important; } }
  @media (min-width: 480px) {
    .no-padding-bottom-sm {
      padding-bottom: 0 !important; } }
  @media (min-width: 720px) {
    .no-padding-bottom-md {
      padding-bottom: 0 !important; } }
  @media (min-width: 1024px) {
    .no-padding-bottom-nav {
      padding-bottom: 0 !important; } }
  @media (min-width: 1024px) {
    .no-padding-bottom-lg {
      padding-bottom: 0 !important; } }
  @media (min-width: 1280px) {
    .no-padding-bottom-xl {
      padding-bottom: 0 !important; } }
  @media (min-width: 1920px) {
    .no-padding-bottom-xxl {
      padding-bottom: 0 !important; } }

.no-padding-center {
  padding-left: 0 !important;
  padding-right: 0 !important; }
  @media (min-width: 0px) {
    .no-padding-center-xs {
      padding-left: 0 !important;
      padding-right: 0 !important; } }
  @media (min-width: 480px) {
    .no-padding-center-sm {
      padding-left: 0 !important;
      padding-right: 0 !important; } }
  @media (min-width: 720px) {
    .no-padding-center-md {
      padding-left: 0 !important;
      padding-right: 0 !important; } }
  @media (min-width: 1024px) {
    .no-padding-center-nav {
      padding-left: 0 !important;
      padding-right: 0 !important; } }
  @media (min-width: 1024px) {
    .no-padding-center-lg {
      padding-left: 0 !important;
      padding-right: 0 !important; } }
  @media (min-width: 1280px) {
    .no-padding-center-xl {
      padding-left: 0 !important;
      padding-right: 0 !important; } }
  @media (min-width: 1920px) {
    .no-padding-center-xxl {
      padding-left: 0 !important;
      padding-right: 0 !important; } }

.no-padding-left {
  padding-left: 0 !important; }
  @media (min-width: 0px) {
    .no-padding-left-xs {
      padding-left: 0 !important; } }
  @media (min-width: 480px) {
    .no-padding-left-sm {
      padding-left: 0 !important; } }
  @media (min-width: 720px) {
    .no-padding-left-md {
      padding-left: 0 !important; } }
  @media (min-width: 1024px) {
    .no-padding-left-nav {
      padding-left: 0 !important; } }
  @media (min-width: 1024px) {
    .no-padding-left-lg {
      padding-left: 0 !important; } }
  @media (min-width: 1280px) {
    .no-padding-left-xl {
      padding-left: 0 !important; } }
  @media (min-width: 1920px) {
    .no-padding-left-xxl {
      padding-left: 0 !important; } }

.no-padding-right {
  padding-right: 0 !important; }
  @media (min-width: 0px) {
    .no-padding-right-xs {
      padding-right: 0 !important; } }
  @media (min-width: 480px) {
    .no-padding-right-sm {
      padding-right: 0 !important; } }
  @media (min-width: 720px) {
    .no-padding-right-md {
      padding-right: 0 !important; } }
  @media (min-width: 1024px) {
    .no-padding-right-nav {
      padding-right: 0 !important; } }
  @media (min-width: 1024px) {
    .no-padding-right-lg {
      padding-right: 0 !important; } }
  @media (min-width: 1280px) {
    .no-padding-right-xl {
      padding-right: 0 !important; } }
  @media (min-width: 1920px) {
    .no-padding-right-xxl {
      padding-right: 0 !important; } }

.no-margin {
  margin: 0 !important; }
  @media (min-width: 0px) {
    .no-margin-xs {
      margin: 0 !important; } }
  @media (min-width: 480px) {
    .no-margin-sm {
      margin: 0 !important; } }
  @media (min-width: 720px) {
    .no-margin-md {
      margin: 0 !important; } }
  @media (min-width: 1024px) {
    .no-margin-nav {
      margin: 0 !important; } }
  @media (min-width: 1024px) {
    .no-margin-lg {
      margin: 0 !important; } }
  @media (min-width: 1280px) {
    .no-margin-xl {
      margin: 0 !important; } }
  @media (min-width: 1920px) {
    .no-margin-xxl {
      margin: 0 !important; } }

.no-margin-middle {
  margin-bottom: 0 !important;
  margin-top: 0 !important; }
  @media (min-width: 0px) {
    .no-margin-middle-xs {
      margin-bottom: 0 !important;
      margin-top: 0 !important; } }
  @media (min-width: 480px) {
    .no-margin-middle-sm {
      margin-bottom: 0 !important;
      margin-top: 0 !important; } }
  @media (min-width: 720px) {
    .no-margin-middle-md {
      margin-bottom: 0 !important;
      margin-top: 0 !important; } }
  @media (min-width: 1024px) {
    .no-margin-middle-nav {
      margin-bottom: 0 !important;
      margin-top: 0 !important; } }
  @media (min-width: 1024px) {
    .no-margin-middle-lg {
      margin-bottom: 0 !important;
      margin-top: 0 !important; } }
  @media (min-width: 1280px) {
    .no-margin-middle-xl {
      margin-bottom: 0 !important;
      margin-top: 0 !important; } }
  @media (min-width: 1920px) {
    .no-margin-middle-xxl {
      margin-bottom: 0 !important;
      margin-top: 0 !important; } }

.no-margin-top {
  margin-top: 0 !important; }
  @media (min-width: 0px) {
    .no-margin-top-xs {
      margin-top: 0 !important; } }
  @media (min-width: 480px) {
    .no-margin-top-sm {
      margin-top: 0 !important; } }
  @media (min-width: 720px) {
    .no-margin-top-md {
      margin-top: 0 !important; } }
  @media (min-width: 1024px) {
    .no-margin-top-nav {
      margin-top: 0 !important; } }
  @media (min-width: 1024px) {
    .no-margin-top-lg {
      margin-top: 0 !important; } }
  @media (min-width: 1280px) {
    .no-margin-top-xl {
      margin-top: 0 !important; } }
  @media (min-width: 1920px) {
    .no-margin-top-xxl {
      margin-top: 0 !important; } }

.no-margin-bottom {
  margin-bottom: 0 !important; }
  @media (min-width: 0px) {
    .no-margin-bottom-xs {
      margin-bottom: 0 !important; } }
  @media (min-width: 480px) {
    .no-margin-bottom-sm {
      margin-bottom: 0 !important; } }
  @media (min-width: 720px) {
    .no-margin-bottom-md {
      margin-bottom: 0 !important; } }
  @media (min-width: 1024px) {
    .no-margin-bottom-nav {
      margin-bottom: 0 !important; } }
  @media (min-width: 1024px) {
    .no-margin-bottom-lg {
      margin-bottom: 0 !important; } }
  @media (min-width: 1280px) {
    .no-margin-bottom-xl {
      margin-bottom: 0 !important; } }
  @media (min-width: 1920px) {
    .no-margin-bottom-xxl {
      margin-bottom: 0 !important; } }

.no-margin-center {
  margin-left: 0 !important;
  margin-right: 0 !important; }
  @media (min-width: 0px) {
    .no-margin-center-xs {
      margin-left: 0 !important;
      margin-right: 0 !important; } }
  @media (min-width: 480px) {
    .no-margin-center-sm {
      margin-left: 0 !important;
      margin-right: 0 !important; } }
  @media (min-width: 720px) {
    .no-margin-center-md {
      margin-left: 0 !important;
      margin-right: 0 !important; } }
  @media (min-width: 1024px) {
    .no-margin-center-nav {
      margin-left: 0 !important;
      margin-right: 0 !important; } }
  @media (min-width: 1024px) {
    .no-margin-center-lg {
      margin-left: 0 !important;
      margin-right: 0 !important; } }
  @media (min-width: 1280px) {
    .no-margin-center-xl {
      margin-left: 0 !important;
      margin-right: 0 !important; } }
  @media (min-width: 1920px) {
    .no-margin-center-xxl {
      margin-left: 0 !important;
      margin-right: 0 !important; } }

.no-margin-left {
  margin-left: 0 !important; }
  @media (min-width: 0px) {
    .no-margin-left-xs {
      margin-left: 0 !important; } }
  @media (min-width: 480px) {
    .no-margin-left-sm {
      margin-left: 0 !important; } }
  @media (min-width: 720px) {
    .no-margin-left-md {
      margin-left: 0 !important; } }
  @media (min-width: 1024px) {
    .no-margin-left-nav {
      margin-left: 0 !important; } }
  @media (min-width: 1024px) {
    .no-margin-left-lg {
      margin-left: 0 !important; } }
  @media (min-width: 1280px) {
    .no-margin-left-xl {
      margin-left: 0 !important; } }
  @media (min-width: 1920px) {
    .no-margin-left-xxl {
      margin-left: 0 !important; } }

.no-margin-right {
  margin-right: 0 !important; }
  @media (min-width: 0px) {
    .no-margin-right-xs {
      margin-right: 0 !important; } }
  @media (min-width: 480px) {
    .no-margin-right-sm {
      margin-right: 0 !important; } }
  @media (min-width: 720px) {
    .no-margin-right-md {
      margin-right: 0 !important; } }
  @media (min-width: 1024px) {
    .no-margin-right-nav {
      margin-right: 0 !important; } }
  @media (min-width: 1024px) {
    .no-margin-right-lg {
      margin-right: 0 !important; } }
  @media (min-width: 1280px) {
    .no-margin-right-xl {
      margin-right: 0 !important; } }
  @media (min-width: 1920px) {
    .no-margin-right-xxl {
      margin-right: 0 !important; } }

.has-height-full {
  height: 100%; }
  @media (min-width: 0px) {
    .has-height-full-xs {
      height: 100%; } }
  @media (min-width: 480px) {
    .has-height-full-sm {
      height: 100%; } }
  @media (min-width: 720px) {
    .has-height-full-md {
      height: 100%; } }
  @media (min-width: 1024px) {
    .has-height-full-nav {
      height: 100%; } }
  @media (min-width: 1024px) {
    .has-height-full-lg {
      height: 100%; } }
  @media (min-width: 1280px) {
    .has-height-full-xl {
      height: 100%; } }
  @media (min-width: 1920px) {
    .has-height-full-xxl {
      height: 100%; } }

.has-height-1-4 {
  height: 25%; }
  @media (min-width: 0px) {
    .has-height-1-4-xs {
      height: 25%; } }
  @media (min-width: 480px) {
    .has-height-1-4-sm {
      height: 25%; } }
  @media (min-width: 720px) {
    .has-height-1-4-md {
      height: 25%; } }
  @media (min-width: 1024px) {
    .has-height-1-4-nav {
      height: 25%; } }
  @media (min-width: 1024px) {
    .has-height-1-4-lg {
      height: 25%; } }
  @media (min-width: 1280px) {
    .has-height-1-4-xl {
      height: 25%; } }
  @media (min-width: 1920px) {
    .has-height-1-4-xxl {
      height: 25%; } }

.has-height-half {
  height: 50%; }
  @media (min-width: 0px) {
    .has-height-half-xs {
      height: 50%; } }
  @media (min-width: 480px) {
    .has-height-half-sm {
      height: 50%; } }
  @media (min-width: 720px) {
    .has-height-half-md {
      height: 50%; } }
  @media (min-width: 1024px) {
    .has-height-half-nav {
      height: 50%; } }
  @media (min-width: 1024px) {
    .has-height-half-lg {
      height: 50%; } }
  @media (min-width: 1280px) {
    .has-height-half-xl {
      height: 50%; } }
  @media (min-width: 1920px) {
    .has-height-half-xxl {
      height: 50%; } }

.has-height-3-4 {
  height: 75%; }
  @media (min-width: 0px) {
    .has-height-3-4-xs {
      height: 75%; } }
  @media (min-width: 480px) {
    .has-height-3-4-sm {
      height: 75%; } }
  @media (min-width: 720px) {
    .has-height-3-4-md {
      height: 75%; } }
  @media (min-width: 1024px) {
    .has-height-3-4-nav {
      height: 75%; } }
  @media (min-width: 1024px) {
    .has-height-3-4-lg {
      height: 75%; } }
  @media (min-width: 1280px) {
    .has-height-3-4-xl {
      height: 75%; } }
  @media (min-width: 1920px) {
    .has-height-3-4-xxl {
      height: 75%; } }

.has-height-16-9 {
  height: 56.25%; }
  @media (min-width: 0px) {
    .has-height-16-9-xs {
      height: 56.25%; } }
  @media (min-width: 480px) {
    .has-height-16-9-sm {
      height: 56.25%; } }
  @media (min-width: 720px) {
    .has-height-16-9-md {
      height: 56.25%; } }
  @media (min-width: 1024px) {
    .has-height-16-9-nav {
      height: 56.25%; } }
  @media (min-width: 1024px) {
    .has-height-16-9-lg {
      height: 56.25%; } }
  @media (min-width: 1280px) {
    .has-height-16-9-xl {
      height: 56.25%; } }
  @media (min-width: 1920px) {
    .has-height-16-9-xxl {
      height: 56.25%; } }

.has-height-golden {
  height: 61.803%; }
  @media (min-width: 0px) {
    .has-height-golden-xs {
      height: 61.803%; } }
  @media (min-width: 480px) {
    .has-height-golden-sm {
      height: 61.803%; } }
  @media (min-width: 720px) {
    .has-height-golden-md {
      height: 61.803%; } }
  @media (min-width: 1024px) {
    .has-height-golden-nav {
      height: 61.803%; } }
  @media (min-width: 1024px) {
    .has-height-golden-lg {
      height: 61.803%; } }
  @media (min-width: 1280px) {
    .has-height-golden-xl {
      height: 61.803%; } }
  @media (min-width: 1920px) {
    .has-height-golden-xxl {
      height: 61.803%; } }

.has-height-vh-full {
  height: 100vh; }
  @media (min-width: 0px) {
    .has-height-vh-full-xs {
      height: 100vh; } }
  @media (min-width: 480px) {
    .has-height-vh-full-sm {
      height: 100vh; } }
  @media (min-width: 720px) {
    .has-height-vh-full-md {
      height: 100vh; } }
  @media (min-width: 1024px) {
    .has-height-vh-full-nav {
      height: 100vh; } }
  @media (min-width: 1024px) {
    .has-height-vh-full-lg {
      height: 100vh; } }
  @media (min-width: 1280px) {
    .has-height-vh-full-xl {
      height: 100vh; } }
  @media (min-width: 1920px) {
    .has-height-vh-full-xxl {
      height: 100vh; } }

.has-height-vh-1-4 {
  height: 25vh; }
  @media (min-width: 0px) {
    .has-height-vh-1-4-xs {
      height: 25vh; } }
  @media (min-width: 480px) {
    .has-height-vh-1-4-sm {
      height: 25vh; } }
  @media (min-width: 720px) {
    .has-height-vh-1-4-md {
      height: 25vh; } }
  @media (min-width: 1024px) {
    .has-height-vh-1-4-nav {
      height: 25vh; } }
  @media (min-width: 1024px) {
    .has-height-vh-1-4-lg {
      height: 25vh; } }
  @media (min-width: 1280px) {
    .has-height-vh-1-4-xl {
      height: 25vh; } }
  @media (min-width: 1920px) {
    .has-height-vh-1-4-xxl {
      height: 25vh; } }

.has-height-vh-half {
  height: 50vh; }
  @media (min-width: 0px) {
    .has-height-vh-half-xs {
      height: 50vh; } }
  @media (min-width: 480px) {
    .has-height-vh-half-sm {
      height: 50vh; } }
  @media (min-width: 720px) {
    .has-height-vh-half-md {
      height: 50vh; } }
  @media (min-width: 1024px) {
    .has-height-vh-half-nav {
      height: 50vh; } }
  @media (min-width: 1024px) {
    .has-height-vh-half-lg {
      height: 50vh; } }
  @media (min-width: 1280px) {
    .has-height-vh-half-xl {
      height: 50vh; } }
  @media (min-width: 1920px) {
    .has-height-vh-half-xxl {
      height: 50vh; } }

.has-height-vh-3-4 {
  height: 75vh; }
  @media (min-width: 0px) {
    .has-height-vh-3-4-xs {
      height: 75vh; } }
  @media (min-width: 480px) {
    .has-height-vh-3-4-sm {
      height: 75vh; } }
  @media (min-width: 720px) {
    .has-height-vh-3-4-md {
      height: 75vh; } }
  @media (min-width: 1024px) {
    .has-height-vh-3-4-nav {
      height: 75vh; } }
  @media (min-width: 1024px) {
    .has-height-vh-3-4-lg {
      height: 75vh; } }
  @media (min-width: 1280px) {
    .has-height-vh-3-4-xl {
      height: 75vh; } }
  @media (min-width: 1920px) {
    .has-height-vh-3-4-xxl {
      height: 75vh; } }

.has-height-vh-16-9 {
  height: 56.25vh; }
  @media (min-width: 0px) {
    .has-height-vh-16-9-xs {
      height: 56.25vh; } }
  @media (min-width: 480px) {
    .has-height-vh-16-9-sm {
      height: 56.25vh; } }
  @media (min-width: 720px) {
    .has-height-vh-16-9-md {
      height: 56.25vh; } }
  @media (min-width: 1024px) {
    .has-height-vh-16-9-nav {
      height: 56.25vh; } }
  @media (min-width: 1024px) {
    .has-height-vh-16-9-lg {
      height: 56.25vh; } }
  @media (min-width: 1280px) {
    .has-height-vh-16-9-xl {
      height: 56.25vh; } }
  @media (min-width: 1920px) {
    .has-height-vh-16-9-xxl {
      height: 56.25vh; } }

.has-height-vh-golden {
  height: 61.803vh; }
  @media (min-width: 0px) {
    .has-height-vh-golden-xs {
      height: 61.803vh; } }
  @media (min-width: 480px) {
    .has-height-vh-golden-sm {
      height: 61.803vh; } }
  @media (min-width: 720px) {
    .has-height-vh-golden-md {
      height: 61.803vh; } }
  @media (min-width: 1024px) {
    .has-height-vh-golden-nav {
      height: 61.803vh; } }
  @media (min-width: 1024px) {
    .has-height-vh-golden-lg {
      height: 61.803vh; } }
  @media (min-width: 1280px) {
    .has-height-vh-golden-xl {
      height: 61.803vh; } }
  @media (min-width: 1920px) {
    .has-height-vh-golden-xxl {
      height: 61.803vh; } }

.has-height-vw-full {
  height: 100vw; }
  @media (min-width: 0px) {
    .has-height-vw-full-xs {
      height: 100vw; } }
  @media (min-width: 480px) {
    .has-height-vw-full-sm {
      height: 100vw; } }
  @media (min-width: 720px) {
    .has-height-vw-full-md {
      height: 100vw; } }
  @media (min-width: 1024px) {
    .has-height-vw-full-nav {
      height: 100vw; } }
  @media (min-width: 1024px) {
    .has-height-vw-full-lg {
      height: 100vw; } }
  @media (min-width: 1280px) {
    .has-height-vw-full-xl {
      height: 100vw; } }
  @media (min-width: 1920px) {
    .has-height-vw-full-xxl {
      height: 100vw; } }

.has-height-vw-1-4 {
  height: 25vw; }
  @media (min-width: 0px) {
    .has-height-vw-1-4-xs {
      height: 25vw; } }
  @media (min-width: 480px) {
    .has-height-vw-1-4-sm {
      height: 25vw; } }
  @media (min-width: 720px) {
    .has-height-vw-1-4-md {
      height: 25vw; } }
  @media (min-width: 1024px) {
    .has-height-vw-1-4-nav {
      height: 25vw; } }
  @media (min-width: 1024px) {
    .has-height-vw-1-4-lg {
      height: 25vw; } }
  @media (min-width: 1280px) {
    .has-height-vw-1-4-xl {
      height: 25vw; } }
  @media (min-width: 1920px) {
    .has-height-vw-1-4-xxl {
      height: 25vw; } }

.has-height-vw-half {
  height: 50vw; }
  @media (min-width: 0px) {
    .has-height-vw-half-xs {
      height: 50vw; } }
  @media (min-width: 480px) {
    .has-height-vw-half-sm {
      height: 50vw; } }
  @media (min-width: 720px) {
    .has-height-vw-half-md {
      height: 50vw; } }
  @media (min-width: 1024px) {
    .has-height-vw-half-nav {
      height: 50vw; } }
  @media (min-width: 1024px) {
    .has-height-vw-half-lg {
      height: 50vw; } }
  @media (min-width: 1280px) {
    .has-height-vw-half-xl {
      height: 50vw; } }
  @media (min-width: 1920px) {
    .has-height-vw-half-xxl {
      height: 50vw; } }

.has-height-vw-3-4 {
  height: 75vw; }
  @media (min-width: 0px) {
    .has-height-vw-3-4-xs {
      height: 75vw; } }
  @media (min-width: 480px) {
    .has-height-vw-3-4-sm {
      height: 75vw; } }
  @media (min-width: 720px) {
    .has-height-vw-3-4-md {
      height: 75vw; } }
  @media (min-width: 1024px) {
    .has-height-vw-3-4-nav {
      height: 75vw; } }
  @media (min-width: 1024px) {
    .has-height-vw-3-4-lg {
      height: 75vw; } }
  @media (min-width: 1280px) {
    .has-height-vw-3-4-xl {
      height: 75vw; } }
  @media (min-width: 1920px) {
    .has-height-vw-3-4-xxl {
      height: 75vw; } }

.has-height-vw-16-9 {
  height: 56.25vw; }
  @media (min-width: 0px) {
    .has-height-vw-16-9-xs {
      height: 56.25vw; } }
  @media (min-width: 480px) {
    .has-height-vw-16-9-sm {
      height: 56.25vw; } }
  @media (min-width: 720px) {
    .has-height-vw-16-9-md {
      height: 56.25vw; } }
  @media (min-width: 1024px) {
    .has-height-vw-16-9-nav {
      height: 56.25vw; } }
  @media (min-width: 1024px) {
    .has-height-vw-16-9-lg {
      height: 56.25vw; } }
  @media (min-width: 1280px) {
    .has-height-vw-16-9-xl {
      height: 56.25vw; } }
  @media (min-width: 1920px) {
    .has-height-vw-16-9-xxl {
      height: 56.25vw; } }

.has-height-vw-golden {
  height: 61.803vw; }
  @media (min-width: 0px) {
    .has-height-vw-golden-xs {
      height: 61.803vw; } }
  @media (min-width: 480px) {
    .has-height-vw-golden-sm {
      height: 61.803vw; } }
  @media (min-width: 720px) {
    .has-height-vw-golden-md {
      height: 61.803vw; } }
  @media (min-width: 1024px) {
    .has-height-vw-golden-nav {
      height: 61.803vw; } }
  @media (min-width: 1024px) {
    .has-height-vw-golden-lg {
      height: 61.803vw; } }
  @media (min-width: 1280px) {
    .has-height-vw-golden-xl {
      height: 61.803vw; } }
  @media (min-width: 1920px) {
    .has-height-vw-golden-xxl {
      height: 61.803vw; } }

.has-min-height-full {
  min-height: 100%; }
  @media (min-width: 0px) {
    .has-min-height-full-xs {
      min-height: 100%; } }
  @media (min-width: 480px) {
    .has-min-height-full-sm {
      min-height: 100%; } }
  @media (min-width: 720px) {
    .has-min-height-full-md {
      min-height: 100%; } }
  @media (min-width: 1024px) {
    .has-min-height-full-nav {
      min-height: 100%; } }
  @media (min-width: 1024px) {
    .has-min-height-full-lg {
      min-height: 100%; } }
  @media (min-width: 1280px) {
    .has-min-height-full-xl {
      min-height: 100%; } }
  @media (min-width: 1920px) {
    .has-min-height-full-xxl {
      min-height: 100%; } }

.has-min-height-1-4 {
  min-height: 25%; }
  @media (min-width: 0px) {
    .has-min-height-1-4-xs {
      min-height: 25%; } }
  @media (min-width: 480px) {
    .has-min-height-1-4-sm {
      min-height: 25%; } }
  @media (min-width: 720px) {
    .has-min-height-1-4-md {
      min-height: 25%; } }
  @media (min-width: 1024px) {
    .has-min-height-1-4-nav {
      min-height: 25%; } }
  @media (min-width: 1024px) {
    .has-min-height-1-4-lg {
      min-height: 25%; } }
  @media (min-width: 1280px) {
    .has-min-height-1-4-xl {
      min-height: 25%; } }
  @media (min-width: 1920px) {
    .has-min-height-1-4-xxl {
      min-height: 25%; } }

.has-min-height-half {
  min-height: 50%; }
  @media (min-width: 0px) {
    .has-min-height-half-xs {
      min-height: 50%; } }
  @media (min-width: 480px) {
    .has-min-height-half-sm {
      min-height: 50%; } }
  @media (min-width: 720px) {
    .has-min-height-half-md {
      min-height: 50%; } }
  @media (min-width: 1024px) {
    .has-min-height-half-nav {
      min-height: 50%; } }
  @media (min-width: 1024px) {
    .has-min-height-half-lg {
      min-height: 50%; } }
  @media (min-width: 1280px) {
    .has-min-height-half-xl {
      min-height: 50%; } }
  @media (min-width: 1920px) {
    .has-min-height-half-xxl {
      min-height: 50%; } }

.has-min-height-3-4 {
  min-height: 75%; }
  @media (min-width: 0px) {
    .has-min-height-3-4-xs {
      min-height: 75%; } }
  @media (min-width: 480px) {
    .has-min-height-3-4-sm {
      min-height: 75%; } }
  @media (min-width: 720px) {
    .has-min-height-3-4-md {
      min-height: 75%; } }
  @media (min-width: 1024px) {
    .has-min-height-3-4-nav {
      min-height: 75%; } }
  @media (min-width: 1024px) {
    .has-min-height-3-4-lg {
      min-height: 75%; } }
  @media (min-width: 1280px) {
    .has-min-height-3-4-xl {
      min-height: 75%; } }
  @media (min-width: 1920px) {
    .has-min-height-3-4-xxl {
      min-height: 75%; } }

.has-min-height-16-9 {
  min-height: 56.25%; }
  @media (min-width: 0px) {
    .has-min-height-16-9-xs {
      min-height: 56.25%; } }
  @media (min-width: 480px) {
    .has-min-height-16-9-sm {
      min-height: 56.25%; } }
  @media (min-width: 720px) {
    .has-min-height-16-9-md {
      min-height: 56.25%; } }
  @media (min-width: 1024px) {
    .has-min-height-16-9-nav {
      min-height: 56.25%; } }
  @media (min-width: 1024px) {
    .has-min-height-16-9-lg {
      min-height: 56.25%; } }
  @media (min-width: 1280px) {
    .has-min-height-16-9-xl {
      min-height: 56.25%; } }
  @media (min-width: 1920px) {
    .has-min-height-16-9-xxl {
      min-height: 56.25%; } }

.has-min-height-golden {
  min-height: 61.803%; }
  @media (min-width: 0px) {
    .has-min-height-golden-xs {
      min-height: 61.803%; } }
  @media (min-width: 480px) {
    .has-min-height-golden-sm {
      min-height: 61.803%; } }
  @media (min-width: 720px) {
    .has-min-height-golden-md {
      min-height: 61.803%; } }
  @media (min-width: 1024px) {
    .has-min-height-golden-nav {
      min-height: 61.803%; } }
  @media (min-width: 1024px) {
    .has-min-height-golden-lg {
      min-height: 61.803%; } }
  @media (min-width: 1280px) {
    .has-min-height-golden-xl {
      min-height: 61.803%; } }
  @media (min-width: 1920px) {
    .has-min-height-golden-xxl {
      min-height: 61.803%; } }

.has-min-height-vh-full {
  min-height: 100vh; }
  @media (min-width: 0px) {
    .has-min-height-vh-full-xs {
      min-height: 100vh; } }
  @media (min-width: 480px) {
    .has-min-height-vh-full-sm {
      min-height: 100vh; } }
  @media (min-width: 720px) {
    .has-min-height-vh-full-md {
      min-height: 100vh; } }
  @media (min-width: 1024px) {
    .has-min-height-vh-full-nav {
      min-height: 100vh; } }
  @media (min-width: 1024px) {
    .has-min-height-vh-full-lg {
      min-height: 100vh; } }
  @media (min-width: 1280px) {
    .has-min-height-vh-full-xl {
      min-height: 100vh; } }
  @media (min-width: 1920px) {
    .has-min-height-vh-full-xxl {
      min-height: 100vh; } }

.has-min-height-vh-1-4 {
  min-height: 25vh; }
  @media (min-width: 0px) {
    .has-min-height-vh-1-4-xs {
      min-height: 25vh; } }
  @media (min-width: 480px) {
    .has-min-height-vh-1-4-sm {
      min-height: 25vh; } }
  @media (min-width: 720px) {
    .has-min-height-vh-1-4-md {
      min-height: 25vh; } }
  @media (min-width: 1024px) {
    .has-min-height-vh-1-4-nav {
      min-height: 25vh; } }
  @media (min-width: 1024px) {
    .has-min-height-vh-1-4-lg {
      min-height: 25vh; } }
  @media (min-width: 1280px) {
    .has-min-height-vh-1-4-xl {
      min-height: 25vh; } }
  @media (min-width: 1920px) {
    .has-min-height-vh-1-4-xxl {
      min-height: 25vh; } }

.has-min-height-vh-half {
  min-height: 50vh; }
  @media (min-width: 0px) {
    .has-min-height-vh-half-xs {
      min-height: 50vh; } }
  @media (min-width: 480px) {
    .has-min-height-vh-half-sm {
      min-height: 50vh; } }
  @media (min-width: 720px) {
    .has-min-height-vh-half-md {
      min-height: 50vh; } }
  @media (min-width: 1024px) {
    .has-min-height-vh-half-nav {
      min-height: 50vh; } }
  @media (min-width: 1024px) {
    .has-min-height-vh-half-lg {
      min-height: 50vh; } }
  @media (min-width: 1280px) {
    .has-min-height-vh-half-xl {
      min-height: 50vh; } }
  @media (min-width: 1920px) {
    .has-min-height-vh-half-xxl {
      min-height: 50vh; } }

.has-min-height-vh-3-4 {
  min-height: 75vh; }
  @media (min-width: 0px) {
    .has-min-height-vh-3-4-xs {
      min-height: 75vh; } }
  @media (min-width: 480px) {
    .has-min-height-vh-3-4-sm {
      min-height: 75vh; } }
  @media (min-width: 720px) {
    .has-min-height-vh-3-4-md {
      min-height: 75vh; } }
  @media (min-width: 1024px) {
    .has-min-height-vh-3-4-nav {
      min-height: 75vh; } }
  @media (min-width: 1024px) {
    .has-min-height-vh-3-4-lg {
      min-height: 75vh; } }
  @media (min-width: 1280px) {
    .has-min-height-vh-3-4-xl {
      min-height: 75vh; } }
  @media (min-width: 1920px) {
    .has-min-height-vh-3-4-xxl {
      min-height: 75vh; } }

.has-min-height-vh-16-9 {
  min-height: 56.25vh; }
  @media (min-width: 0px) {
    .has-min-height-vh-16-9-xs {
      min-height: 56.25vh; } }
  @media (min-width: 480px) {
    .has-min-height-vh-16-9-sm {
      min-height: 56.25vh; } }
  @media (min-width: 720px) {
    .has-min-height-vh-16-9-md {
      min-height: 56.25vh; } }
  @media (min-width: 1024px) {
    .has-min-height-vh-16-9-nav {
      min-height: 56.25vh; } }
  @media (min-width: 1024px) {
    .has-min-height-vh-16-9-lg {
      min-height: 56.25vh; } }
  @media (min-width: 1280px) {
    .has-min-height-vh-16-9-xl {
      min-height: 56.25vh; } }
  @media (min-width: 1920px) {
    .has-min-height-vh-16-9-xxl {
      min-height: 56.25vh; } }

.has-min-height-vh-golden {
  min-height: 61.803vh; }
  @media (min-width: 0px) {
    .has-min-height-vh-golden-xs {
      min-height: 61.803vh; } }
  @media (min-width: 480px) {
    .has-min-height-vh-golden-sm {
      min-height: 61.803vh; } }
  @media (min-width: 720px) {
    .has-min-height-vh-golden-md {
      min-height: 61.803vh; } }
  @media (min-width: 1024px) {
    .has-min-height-vh-golden-nav {
      min-height: 61.803vh; } }
  @media (min-width: 1024px) {
    .has-min-height-vh-golden-lg {
      min-height: 61.803vh; } }
  @media (min-width: 1280px) {
    .has-min-height-vh-golden-xl {
      min-height: 61.803vh; } }
  @media (min-width: 1920px) {
    .has-min-height-vh-golden-xxl {
      min-height: 61.803vh; } }

.has-min-height-vw-full {
  min-height: 100vw; }
  @media (min-width: 0px) {
    .has-min-height-vw-full-xs {
      min-height: 100vw; } }
  @media (min-width: 480px) {
    .has-min-height-vw-full-sm {
      min-height: 100vw; } }
  @media (min-width: 720px) {
    .has-min-height-vw-full-md {
      min-height: 100vw; } }
  @media (min-width: 1024px) {
    .has-min-height-vw-full-nav {
      min-height: 100vw; } }
  @media (min-width: 1024px) {
    .has-min-height-vw-full-lg {
      min-height: 100vw; } }
  @media (min-width: 1280px) {
    .has-min-height-vw-full-xl {
      min-height: 100vw; } }
  @media (min-width: 1920px) {
    .has-min-height-vw-full-xxl {
      min-height: 100vw; } }

.has-min-height-vw-1-4 {
  min-height: 25vw; }
  @media (min-width: 0px) {
    .has-min-height-vw-1-4-xs {
      min-height: 25vw; } }
  @media (min-width: 480px) {
    .has-min-height-vw-1-4-sm {
      min-height: 25vw; } }
  @media (min-width: 720px) {
    .has-min-height-vw-1-4-md {
      min-height: 25vw; } }
  @media (min-width: 1024px) {
    .has-min-height-vw-1-4-nav {
      min-height: 25vw; } }
  @media (min-width: 1024px) {
    .has-min-height-vw-1-4-lg {
      min-height: 25vw; } }
  @media (min-width: 1280px) {
    .has-min-height-vw-1-4-xl {
      min-height: 25vw; } }
  @media (min-width: 1920px) {
    .has-min-height-vw-1-4-xxl {
      min-height: 25vw; } }

.has-min-height-vw-half {
  min-height: 50vw; }
  @media (min-width: 0px) {
    .has-min-height-vw-half-xs {
      min-height: 50vw; } }
  @media (min-width: 480px) {
    .has-min-height-vw-half-sm {
      min-height: 50vw; } }
  @media (min-width: 720px) {
    .has-min-height-vw-half-md {
      min-height: 50vw; } }
  @media (min-width: 1024px) {
    .has-min-height-vw-half-nav {
      min-height: 50vw; } }
  @media (min-width: 1024px) {
    .has-min-height-vw-half-lg {
      min-height: 50vw; } }
  @media (min-width: 1280px) {
    .has-min-height-vw-half-xl {
      min-height: 50vw; } }
  @media (min-width: 1920px) {
    .has-min-height-vw-half-xxl {
      min-height: 50vw; } }

.has-min-height-vw-3-4 {
  min-height: 75vw; }
  @media (min-width: 0px) {
    .has-min-height-vw-3-4-xs {
      min-height: 75vw; } }
  @media (min-width: 480px) {
    .has-min-height-vw-3-4-sm {
      min-height: 75vw; } }
  @media (min-width: 720px) {
    .has-min-height-vw-3-4-md {
      min-height: 75vw; } }
  @media (min-width: 1024px) {
    .has-min-height-vw-3-4-nav {
      min-height: 75vw; } }
  @media (min-width: 1024px) {
    .has-min-height-vw-3-4-lg {
      min-height: 75vw; } }
  @media (min-width: 1280px) {
    .has-min-height-vw-3-4-xl {
      min-height: 75vw; } }
  @media (min-width: 1920px) {
    .has-min-height-vw-3-4-xxl {
      min-height: 75vw; } }

.has-min-height-vw-16-9 {
  min-height: 56.25vw; }
  @media (min-width: 0px) {
    .has-min-height-vw-16-9-xs {
      min-height: 56.25vw; } }
  @media (min-width: 480px) {
    .has-min-height-vw-16-9-sm {
      min-height: 56.25vw; } }
  @media (min-width: 720px) {
    .has-min-height-vw-16-9-md {
      min-height: 56.25vw; } }
  @media (min-width: 1024px) {
    .has-min-height-vw-16-9-nav {
      min-height: 56.25vw; } }
  @media (min-width: 1024px) {
    .has-min-height-vw-16-9-lg {
      min-height: 56.25vw; } }
  @media (min-width: 1280px) {
    .has-min-height-vw-16-9-xl {
      min-height: 56.25vw; } }
  @media (min-width: 1920px) {
    .has-min-height-vw-16-9-xxl {
      min-height: 56.25vw; } }

.has-min-height-vw-golden {
  min-height: 61.803vw; }
  @media (min-width: 0px) {
    .has-min-height-vw-golden-xs {
      min-height: 61.803vw; } }
  @media (min-width: 480px) {
    .has-min-height-vw-golden-sm {
      min-height: 61.803vw; } }
  @media (min-width: 720px) {
    .has-min-height-vw-golden-md {
      min-height: 61.803vw; } }
  @media (min-width: 1024px) {
    .has-min-height-vw-golden-nav {
      min-height: 61.803vw; } }
  @media (min-width: 1024px) {
    .has-min-height-vw-golden-lg {
      min-height: 61.803vw; } }
  @media (min-width: 1280px) {
    .has-min-height-vw-golden-xl {
      min-height: 61.803vw; } }
  @media (min-width: 1920px) {
    .has-min-height-vw-golden-xxl {
      min-height: 61.803vw; } }

.has-width-full {
  width: 100%; }
  @media (min-width: 0px) {
    .has-width-full-xs {
      width: 100%; } }
  @media (min-width: 480px) {
    .has-width-full-sm {
      width: 100%; } }
  @media (min-width: 720px) {
    .has-width-full-md {
      width: 100%; } }
  @media (min-width: 1024px) {
    .has-width-full-nav {
      width: 100%; } }
  @media (min-width: 1024px) {
    .has-width-full-lg {
      width: 100%; } }
  @media (min-width: 1280px) {
    .has-width-full-xl {
      width: 100%; } }
  @media (min-width: 1920px) {
    .has-width-full-xxl {
      width: 100%; } }

.has-width-1-4 {
  width: 25%; }
  @media (min-width: 0px) {
    .has-width-1-4-xs {
      width: 25%; } }
  @media (min-width: 480px) {
    .has-width-1-4-sm {
      width: 25%; } }
  @media (min-width: 720px) {
    .has-width-1-4-md {
      width: 25%; } }
  @media (min-width: 1024px) {
    .has-width-1-4-nav {
      width: 25%; } }
  @media (min-width: 1024px) {
    .has-width-1-4-lg {
      width: 25%; } }
  @media (min-width: 1280px) {
    .has-width-1-4-xl {
      width: 25%; } }
  @media (min-width: 1920px) {
    .has-width-1-4-xxl {
      width: 25%; } }

.has-width-half {
  width: 50%; }
  @media (min-width: 0px) {
    .has-width-half-xs {
      width: 50%; } }
  @media (min-width: 480px) {
    .has-width-half-sm {
      width: 50%; } }
  @media (min-width: 720px) {
    .has-width-half-md {
      width: 50%; } }
  @media (min-width: 1024px) {
    .has-width-half-nav {
      width: 50%; } }
  @media (min-width: 1024px) {
    .has-width-half-lg {
      width: 50%; } }
  @media (min-width: 1280px) {
    .has-width-half-xl {
      width: 50%; } }
  @media (min-width: 1920px) {
    .has-width-half-xxl {
      width: 50%; } }

.has-width-3-4 {
  width: 75%; }
  @media (min-width: 0px) {
    .has-width-3-4-xs {
      width: 75%; } }
  @media (min-width: 480px) {
    .has-width-3-4-sm {
      width: 75%; } }
  @media (min-width: 720px) {
    .has-width-3-4-md {
      width: 75%; } }
  @media (min-width: 1024px) {
    .has-width-3-4-nav {
      width: 75%; } }
  @media (min-width: 1024px) {
    .has-width-3-4-lg {
      width: 75%; } }
  @media (min-width: 1280px) {
    .has-width-3-4-xl {
      width: 75%; } }
  @media (min-width: 1920px) {
    .has-width-3-4-xxl {
      width: 75%; } }

.has-width-16-9 {
  width: 56.25%; }
  @media (min-width: 0px) {
    .has-width-16-9-xs {
      width: 56.25%; } }
  @media (min-width: 480px) {
    .has-width-16-9-sm {
      width: 56.25%; } }
  @media (min-width: 720px) {
    .has-width-16-9-md {
      width: 56.25%; } }
  @media (min-width: 1024px) {
    .has-width-16-9-nav {
      width: 56.25%; } }
  @media (min-width: 1024px) {
    .has-width-16-9-lg {
      width: 56.25%; } }
  @media (min-width: 1280px) {
    .has-width-16-9-xl {
      width: 56.25%; } }
  @media (min-width: 1920px) {
    .has-width-16-9-xxl {
      width: 56.25%; } }

.has-width-golden {
  width: 61.803%; }
  @media (min-width: 0px) {
    .has-width-golden-xs {
      width: 61.803%; } }
  @media (min-width: 480px) {
    .has-width-golden-sm {
      width: 61.803%; } }
  @media (min-width: 720px) {
    .has-width-golden-md {
      width: 61.803%; } }
  @media (min-width: 1024px) {
    .has-width-golden-nav {
      width: 61.803%; } }
  @media (min-width: 1024px) {
    .has-width-golden-lg {
      width: 61.803%; } }
  @media (min-width: 1280px) {
    .has-width-golden-xl {
      width: 61.803%; } }
  @media (min-width: 1920px) {
    .has-width-golden-xxl {
      width: 61.803%; } }

.has-width-vh-full {
  width: 100vh; }
  @media (min-width: 0px) {
    .has-width-vh-full-xs {
      width: 100vh; } }
  @media (min-width: 480px) {
    .has-width-vh-full-sm {
      width: 100vh; } }
  @media (min-width: 720px) {
    .has-width-vh-full-md {
      width: 100vh; } }
  @media (min-width: 1024px) {
    .has-width-vh-full-nav {
      width: 100vh; } }
  @media (min-width: 1024px) {
    .has-width-vh-full-lg {
      width: 100vh; } }
  @media (min-width: 1280px) {
    .has-width-vh-full-xl {
      width: 100vh; } }
  @media (min-width: 1920px) {
    .has-width-vh-full-xxl {
      width: 100vh; } }

.has-width-vh-1-4 {
  width: 25vh; }
  @media (min-width: 0px) {
    .has-width-vh-1-4-xs {
      width: 25vh; } }
  @media (min-width: 480px) {
    .has-width-vh-1-4-sm {
      width: 25vh; } }
  @media (min-width: 720px) {
    .has-width-vh-1-4-md {
      width: 25vh; } }
  @media (min-width: 1024px) {
    .has-width-vh-1-4-nav {
      width: 25vh; } }
  @media (min-width: 1024px) {
    .has-width-vh-1-4-lg {
      width: 25vh; } }
  @media (min-width: 1280px) {
    .has-width-vh-1-4-xl {
      width: 25vh; } }
  @media (min-width: 1920px) {
    .has-width-vh-1-4-xxl {
      width: 25vh; } }

.has-width-vh-half {
  width: 50vh; }
  @media (min-width: 0px) {
    .has-width-vh-half-xs {
      width: 50vh; } }
  @media (min-width: 480px) {
    .has-width-vh-half-sm {
      width: 50vh; } }
  @media (min-width: 720px) {
    .has-width-vh-half-md {
      width: 50vh; } }
  @media (min-width: 1024px) {
    .has-width-vh-half-nav {
      width: 50vh; } }
  @media (min-width: 1024px) {
    .has-width-vh-half-lg {
      width: 50vh; } }
  @media (min-width: 1280px) {
    .has-width-vh-half-xl {
      width: 50vh; } }
  @media (min-width: 1920px) {
    .has-width-vh-half-xxl {
      width: 50vh; } }

.has-width-vh-3-4 {
  width: 75vh; }
  @media (min-width: 0px) {
    .has-width-vh-3-4-xs {
      width: 75vh; } }
  @media (min-width: 480px) {
    .has-width-vh-3-4-sm {
      width: 75vh; } }
  @media (min-width: 720px) {
    .has-width-vh-3-4-md {
      width: 75vh; } }
  @media (min-width: 1024px) {
    .has-width-vh-3-4-nav {
      width: 75vh; } }
  @media (min-width: 1024px) {
    .has-width-vh-3-4-lg {
      width: 75vh; } }
  @media (min-width: 1280px) {
    .has-width-vh-3-4-xl {
      width: 75vh; } }
  @media (min-width: 1920px) {
    .has-width-vh-3-4-xxl {
      width: 75vh; } }

.has-width-vh-16-9 {
  width: 56.25vh; }
  @media (min-width: 0px) {
    .has-width-vh-16-9-xs {
      width: 56.25vh; } }
  @media (min-width: 480px) {
    .has-width-vh-16-9-sm {
      width: 56.25vh; } }
  @media (min-width: 720px) {
    .has-width-vh-16-9-md {
      width: 56.25vh; } }
  @media (min-width: 1024px) {
    .has-width-vh-16-9-nav {
      width: 56.25vh; } }
  @media (min-width: 1024px) {
    .has-width-vh-16-9-lg {
      width: 56.25vh; } }
  @media (min-width: 1280px) {
    .has-width-vh-16-9-xl {
      width: 56.25vh; } }
  @media (min-width: 1920px) {
    .has-width-vh-16-9-xxl {
      width: 56.25vh; } }

.has-width-vh-golden {
  width: 61.803vh; }
  @media (min-width: 0px) {
    .has-width-vh-golden-xs {
      width: 61.803vh; } }
  @media (min-width: 480px) {
    .has-width-vh-golden-sm {
      width: 61.803vh; } }
  @media (min-width: 720px) {
    .has-width-vh-golden-md {
      width: 61.803vh; } }
  @media (min-width: 1024px) {
    .has-width-vh-golden-nav {
      width: 61.803vh; } }
  @media (min-width: 1024px) {
    .has-width-vh-golden-lg {
      width: 61.803vh; } }
  @media (min-width: 1280px) {
    .has-width-vh-golden-xl {
      width: 61.803vh; } }
  @media (min-width: 1920px) {
    .has-width-vh-golden-xxl {
      width: 61.803vh; } }

.has-width-vw-full {
  width: 100vw; }
  @media (min-width: 0px) {
    .has-width-vw-full-xs {
      width: 100vw; } }
  @media (min-width: 480px) {
    .has-width-vw-full-sm {
      width: 100vw; } }
  @media (min-width: 720px) {
    .has-width-vw-full-md {
      width: 100vw; } }
  @media (min-width: 1024px) {
    .has-width-vw-full-nav {
      width: 100vw; } }
  @media (min-width: 1024px) {
    .has-width-vw-full-lg {
      width: 100vw; } }
  @media (min-width: 1280px) {
    .has-width-vw-full-xl {
      width: 100vw; } }
  @media (min-width: 1920px) {
    .has-width-vw-full-xxl {
      width: 100vw; } }

.has-width-vw-1-4 {
  width: 25vw; }
  @media (min-width: 0px) {
    .has-width-vw-1-4-xs {
      width: 25vw; } }
  @media (min-width: 480px) {
    .has-width-vw-1-4-sm {
      width: 25vw; } }
  @media (min-width: 720px) {
    .has-width-vw-1-4-md {
      width: 25vw; } }
  @media (min-width: 1024px) {
    .has-width-vw-1-4-nav {
      width: 25vw; } }
  @media (min-width: 1024px) {
    .has-width-vw-1-4-lg {
      width: 25vw; } }
  @media (min-width: 1280px) {
    .has-width-vw-1-4-xl {
      width: 25vw; } }
  @media (min-width: 1920px) {
    .has-width-vw-1-4-xxl {
      width: 25vw; } }

.has-width-vw-half {
  width: 50vw; }
  @media (min-width: 0px) {
    .has-width-vw-half-xs {
      width: 50vw; } }
  @media (min-width: 480px) {
    .has-width-vw-half-sm {
      width: 50vw; } }
  @media (min-width: 720px) {
    .has-width-vw-half-md {
      width: 50vw; } }
  @media (min-width: 1024px) {
    .has-width-vw-half-nav {
      width: 50vw; } }
  @media (min-width: 1024px) {
    .has-width-vw-half-lg {
      width: 50vw; } }
  @media (min-width: 1280px) {
    .has-width-vw-half-xl {
      width: 50vw; } }
  @media (min-width: 1920px) {
    .has-width-vw-half-xxl {
      width: 50vw; } }

.has-width-vw-3-4 {
  width: 75vw; }
  @media (min-width: 0px) {
    .has-width-vw-3-4-xs {
      width: 75vw; } }
  @media (min-width: 480px) {
    .has-width-vw-3-4-sm {
      width: 75vw; } }
  @media (min-width: 720px) {
    .has-width-vw-3-4-md {
      width: 75vw; } }
  @media (min-width: 1024px) {
    .has-width-vw-3-4-nav {
      width: 75vw; } }
  @media (min-width: 1024px) {
    .has-width-vw-3-4-lg {
      width: 75vw; } }
  @media (min-width: 1280px) {
    .has-width-vw-3-4-xl {
      width: 75vw; } }
  @media (min-width: 1920px) {
    .has-width-vw-3-4-xxl {
      width: 75vw; } }

.has-width-vw-16-9 {
  width: 56.25vw; }
  @media (min-width: 0px) {
    .has-width-vw-16-9-xs {
      width: 56.25vw; } }
  @media (min-width: 480px) {
    .has-width-vw-16-9-sm {
      width: 56.25vw; } }
  @media (min-width: 720px) {
    .has-width-vw-16-9-md {
      width: 56.25vw; } }
  @media (min-width: 1024px) {
    .has-width-vw-16-9-nav {
      width: 56.25vw; } }
  @media (min-width: 1024px) {
    .has-width-vw-16-9-lg {
      width: 56.25vw; } }
  @media (min-width: 1280px) {
    .has-width-vw-16-9-xl {
      width: 56.25vw; } }
  @media (min-width: 1920px) {
    .has-width-vw-16-9-xxl {
      width: 56.25vw; } }

.has-width-vw-golden {
  width: 61.803vw; }
  @media (min-width: 0px) {
    .has-width-vw-golden-xs {
      width: 61.803vw; } }
  @media (min-width: 480px) {
    .has-width-vw-golden-sm {
      width: 61.803vw; } }
  @media (min-width: 720px) {
    .has-width-vw-golden-md {
      width: 61.803vw; } }
  @media (min-width: 1024px) {
    .has-width-vw-golden-nav {
      width: 61.803vw; } }
  @media (min-width: 1024px) {
    .has-width-vw-golden-lg {
      width: 61.803vw; } }
  @media (min-width: 1280px) {
    .has-width-vw-golden-xl {
      width: 61.803vw; } }
  @media (min-width: 1920px) {
    .has-width-vw-golden-xxl {
      width: 61.803vw; } }

.has-min-width-full {
  min-width: 100%; }
  @media (min-width: 0px) {
    .has-min-width-full-xs {
      min-width: 100%; } }
  @media (min-width: 480px) {
    .has-min-width-full-sm {
      min-width: 100%; } }
  @media (min-width: 720px) {
    .has-min-width-full-md {
      min-width: 100%; } }
  @media (min-width: 1024px) {
    .has-min-width-full-nav {
      min-width: 100%; } }
  @media (min-width: 1024px) {
    .has-min-width-full-lg {
      min-width: 100%; } }
  @media (min-width: 1280px) {
    .has-min-width-full-xl {
      min-width: 100%; } }
  @media (min-width: 1920px) {
    .has-min-width-full-xxl {
      min-width: 100%; } }

.has-min-width-1-4 {
  min-width: 25%; }
  @media (min-width: 0px) {
    .has-min-width-1-4-xs {
      min-width: 25%; } }
  @media (min-width: 480px) {
    .has-min-width-1-4-sm {
      min-width: 25%; } }
  @media (min-width: 720px) {
    .has-min-width-1-4-md {
      min-width: 25%; } }
  @media (min-width: 1024px) {
    .has-min-width-1-4-nav {
      min-width: 25%; } }
  @media (min-width: 1024px) {
    .has-min-width-1-4-lg {
      min-width: 25%; } }
  @media (min-width: 1280px) {
    .has-min-width-1-4-xl {
      min-width: 25%; } }
  @media (min-width: 1920px) {
    .has-min-width-1-4-xxl {
      min-width: 25%; } }

.has-min-width-half {
  min-width: 50%; }
  @media (min-width: 0px) {
    .has-min-width-half-xs {
      min-width: 50%; } }
  @media (min-width: 480px) {
    .has-min-width-half-sm {
      min-width: 50%; } }
  @media (min-width: 720px) {
    .has-min-width-half-md {
      min-width: 50%; } }
  @media (min-width: 1024px) {
    .has-min-width-half-nav {
      min-width: 50%; } }
  @media (min-width: 1024px) {
    .has-min-width-half-lg {
      min-width: 50%; } }
  @media (min-width: 1280px) {
    .has-min-width-half-xl {
      min-width: 50%; } }
  @media (min-width: 1920px) {
    .has-min-width-half-xxl {
      min-width: 50%; } }

.has-min-width-3-4 {
  min-width: 75%; }
  @media (min-width: 0px) {
    .has-min-width-3-4-xs {
      min-width: 75%; } }
  @media (min-width: 480px) {
    .has-min-width-3-4-sm {
      min-width: 75%; } }
  @media (min-width: 720px) {
    .has-min-width-3-4-md {
      min-width: 75%; } }
  @media (min-width: 1024px) {
    .has-min-width-3-4-nav {
      min-width: 75%; } }
  @media (min-width: 1024px) {
    .has-min-width-3-4-lg {
      min-width: 75%; } }
  @media (min-width: 1280px) {
    .has-min-width-3-4-xl {
      min-width: 75%; } }
  @media (min-width: 1920px) {
    .has-min-width-3-4-xxl {
      min-width: 75%; } }

.has-min-width-16-9 {
  min-width: 56.25%; }
  @media (min-width: 0px) {
    .has-min-width-16-9-xs {
      min-width: 56.25%; } }
  @media (min-width: 480px) {
    .has-min-width-16-9-sm {
      min-width: 56.25%; } }
  @media (min-width: 720px) {
    .has-min-width-16-9-md {
      min-width: 56.25%; } }
  @media (min-width: 1024px) {
    .has-min-width-16-9-nav {
      min-width: 56.25%; } }
  @media (min-width: 1024px) {
    .has-min-width-16-9-lg {
      min-width: 56.25%; } }
  @media (min-width: 1280px) {
    .has-min-width-16-9-xl {
      min-width: 56.25%; } }
  @media (min-width: 1920px) {
    .has-min-width-16-9-xxl {
      min-width: 56.25%; } }

.has-min-width-golden {
  min-width: 61.803%; }
  @media (min-width: 0px) {
    .has-min-width-golden-xs {
      min-width: 61.803%; } }
  @media (min-width: 480px) {
    .has-min-width-golden-sm {
      min-width: 61.803%; } }
  @media (min-width: 720px) {
    .has-min-width-golden-md {
      min-width: 61.803%; } }
  @media (min-width: 1024px) {
    .has-min-width-golden-nav {
      min-width: 61.803%; } }
  @media (min-width: 1024px) {
    .has-min-width-golden-lg {
      min-width: 61.803%; } }
  @media (min-width: 1280px) {
    .has-min-width-golden-xl {
      min-width: 61.803%; } }
  @media (min-width: 1920px) {
    .has-min-width-golden-xxl {
      min-width: 61.803%; } }

.has-min-width-vh-full {
  min-width: 100vh; }
  @media (min-width: 0px) {
    .has-min-width-vh-full-xs {
      min-width: 100vh; } }
  @media (min-width: 480px) {
    .has-min-width-vh-full-sm {
      min-width: 100vh; } }
  @media (min-width: 720px) {
    .has-min-width-vh-full-md {
      min-width: 100vh; } }
  @media (min-width: 1024px) {
    .has-min-width-vh-full-nav {
      min-width: 100vh; } }
  @media (min-width: 1024px) {
    .has-min-width-vh-full-lg {
      min-width: 100vh; } }
  @media (min-width: 1280px) {
    .has-min-width-vh-full-xl {
      min-width: 100vh; } }
  @media (min-width: 1920px) {
    .has-min-width-vh-full-xxl {
      min-width: 100vh; } }

.has-min-width-vh-1-4 {
  min-width: 25vh; }
  @media (min-width: 0px) {
    .has-min-width-vh-1-4-xs {
      min-width: 25vh; } }
  @media (min-width: 480px) {
    .has-min-width-vh-1-4-sm {
      min-width: 25vh; } }
  @media (min-width: 720px) {
    .has-min-width-vh-1-4-md {
      min-width: 25vh; } }
  @media (min-width: 1024px) {
    .has-min-width-vh-1-4-nav {
      min-width: 25vh; } }
  @media (min-width: 1024px) {
    .has-min-width-vh-1-4-lg {
      min-width: 25vh; } }
  @media (min-width: 1280px) {
    .has-min-width-vh-1-4-xl {
      min-width: 25vh; } }
  @media (min-width: 1920px) {
    .has-min-width-vh-1-4-xxl {
      min-width: 25vh; } }

.has-min-width-vh-half {
  min-width: 50vh; }
  @media (min-width: 0px) {
    .has-min-width-vh-half-xs {
      min-width: 50vh; } }
  @media (min-width: 480px) {
    .has-min-width-vh-half-sm {
      min-width: 50vh; } }
  @media (min-width: 720px) {
    .has-min-width-vh-half-md {
      min-width: 50vh; } }
  @media (min-width: 1024px) {
    .has-min-width-vh-half-nav {
      min-width: 50vh; } }
  @media (min-width: 1024px) {
    .has-min-width-vh-half-lg {
      min-width: 50vh; } }
  @media (min-width: 1280px) {
    .has-min-width-vh-half-xl {
      min-width: 50vh; } }
  @media (min-width: 1920px) {
    .has-min-width-vh-half-xxl {
      min-width: 50vh; } }

.has-min-width-vh-3-4 {
  min-width: 75vh; }
  @media (min-width: 0px) {
    .has-min-width-vh-3-4-xs {
      min-width: 75vh; } }
  @media (min-width: 480px) {
    .has-min-width-vh-3-4-sm {
      min-width: 75vh; } }
  @media (min-width: 720px) {
    .has-min-width-vh-3-4-md {
      min-width: 75vh; } }
  @media (min-width: 1024px) {
    .has-min-width-vh-3-4-nav {
      min-width: 75vh; } }
  @media (min-width: 1024px) {
    .has-min-width-vh-3-4-lg {
      min-width: 75vh; } }
  @media (min-width: 1280px) {
    .has-min-width-vh-3-4-xl {
      min-width: 75vh; } }
  @media (min-width: 1920px) {
    .has-min-width-vh-3-4-xxl {
      min-width: 75vh; } }

.has-min-width-vh-16-9 {
  min-width: 56.25vh; }
  @media (min-width: 0px) {
    .has-min-width-vh-16-9-xs {
      min-width: 56.25vh; } }
  @media (min-width: 480px) {
    .has-min-width-vh-16-9-sm {
      min-width: 56.25vh; } }
  @media (min-width: 720px) {
    .has-min-width-vh-16-9-md {
      min-width: 56.25vh; } }
  @media (min-width: 1024px) {
    .has-min-width-vh-16-9-nav {
      min-width: 56.25vh; } }
  @media (min-width: 1024px) {
    .has-min-width-vh-16-9-lg {
      min-width: 56.25vh; } }
  @media (min-width: 1280px) {
    .has-min-width-vh-16-9-xl {
      min-width: 56.25vh; } }
  @media (min-width: 1920px) {
    .has-min-width-vh-16-9-xxl {
      min-width: 56.25vh; } }

.has-min-width-vh-golden {
  min-width: 61.803vh; }
  @media (min-width: 0px) {
    .has-min-width-vh-golden-xs {
      min-width: 61.803vh; } }
  @media (min-width: 480px) {
    .has-min-width-vh-golden-sm {
      min-width: 61.803vh; } }
  @media (min-width: 720px) {
    .has-min-width-vh-golden-md {
      min-width: 61.803vh; } }
  @media (min-width: 1024px) {
    .has-min-width-vh-golden-nav {
      min-width: 61.803vh; } }
  @media (min-width: 1024px) {
    .has-min-width-vh-golden-lg {
      min-width: 61.803vh; } }
  @media (min-width: 1280px) {
    .has-min-width-vh-golden-xl {
      min-width: 61.803vh; } }
  @media (min-width: 1920px) {
    .has-min-width-vh-golden-xxl {
      min-width: 61.803vh; } }

.has-min-width-vw-full {
  min-width: 100vw; }
  @media (min-width: 0px) {
    .has-min-width-vw-full-xs {
      min-width: 100vw; } }
  @media (min-width: 480px) {
    .has-min-width-vw-full-sm {
      min-width: 100vw; } }
  @media (min-width: 720px) {
    .has-min-width-vw-full-md {
      min-width: 100vw; } }
  @media (min-width: 1024px) {
    .has-min-width-vw-full-nav {
      min-width: 100vw; } }
  @media (min-width: 1024px) {
    .has-min-width-vw-full-lg {
      min-width: 100vw; } }
  @media (min-width: 1280px) {
    .has-min-width-vw-full-xl {
      min-width: 100vw; } }
  @media (min-width: 1920px) {
    .has-min-width-vw-full-xxl {
      min-width: 100vw; } }

.has-min-width-vw-1-4 {
  min-width: 25vw; }
  @media (min-width: 0px) {
    .has-min-width-vw-1-4-xs {
      min-width: 25vw; } }
  @media (min-width: 480px) {
    .has-min-width-vw-1-4-sm {
      min-width: 25vw; } }
  @media (min-width: 720px) {
    .has-min-width-vw-1-4-md {
      min-width: 25vw; } }
  @media (min-width: 1024px) {
    .has-min-width-vw-1-4-nav {
      min-width: 25vw; } }
  @media (min-width: 1024px) {
    .has-min-width-vw-1-4-lg {
      min-width: 25vw; } }
  @media (min-width: 1280px) {
    .has-min-width-vw-1-4-xl {
      min-width: 25vw; } }
  @media (min-width: 1920px) {
    .has-min-width-vw-1-4-xxl {
      min-width: 25vw; } }

.has-min-width-vw-half {
  min-width: 50vw; }
  @media (min-width: 0px) {
    .has-min-width-vw-half-xs {
      min-width: 50vw; } }
  @media (min-width: 480px) {
    .has-min-width-vw-half-sm {
      min-width: 50vw; } }
  @media (min-width: 720px) {
    .has-min-width-vw-half-md {
      min-width: 50vw; } }
  @media (min-width: 1024px) {
    .has-min-width-vw-half-nav {
      min-width: 50vw; } }
  @media (min-width: 1024px) {
    .has-min-width-vw-half-lg {
      min-width: 50vw; } }
  @media (min-width: 1280px) {
    .has-min-width-vw-half-xl {
      min-width: 50vw; } }
  @media (min-width: 1920px) {
    .has-min-width-vw-half-xxl {
      min-width: 50vw; } }

.has-min-width-vw-3-4 {
  min-width: 75vw; }
  @media (min-width: 0px) {
    .has-min-width-vw-3-4-xs {
      min-width: 75vw; } }
  @media (min-width: 480px) {
    .has-min-width-vw-3-4-sm {
      min-width: 75vw; } }
  @media (min-width: 720px) {
    .has-min-width-vw-3-4-md {
      min-width: 75vw; } }
  @media (min-width: 1024px) {
    .has-min-width-vw-3-4-nav {
      min-width: 75vw; } }
  @media (min-width: 1024px) {
    .has-min-width-vw-3-4-lg {
      min-width: 75vw; } }
  @media (min-width: 1280px) {
    .has-min-width-vw-3-4-xl {
      min-width: 75vw; } }
  @media (min-width: 1920px) {
    .has-min-width-vw-3-4-xxl {
      min-width: 75vw; } }

.has-min-width-vw-16-9 {
  min-width: 56.25vw; }
  @media (min-width: 0px) {
    .has-min-width-vw-16-9-xs {
      min-width: 56.25vw; } }
  @media (min-width: 480px) {
    .has-min-width-vw-16-9-sm {
      min-width: 56.25vw; } }
  @media (min-width: 720px) {
    .has-min-width-vw-16-9-md {
      min-width: 56.25vw; } }
  @media (min-width: 1024px) {
    .has-min-width-vw-16-9-nav {
      min-width: 56.25vw; } }
  @media (min-width: 1024px) {
    .has-min-width-vw-16-9-lg {
      min-width: 56.25vw; } }
  @media (min-width: 1280px) {
    .has-min-width-vw-16-9-xl {
      min-width: 56.25vw; } }
  @media (min-width: 1920px) {
    .has-min-width-vw-16-9-xxl {
      min-width: 56.25vw; } }

.has-min-width-vw-golden {
  min-width: 61.803vw; }
  @media (min-width: 0px) {
    .has-min-width-vw-golden-xs {
      min-width: 61.803vw; } }
  @media (min-width: 480px) {
    .has-min-width-vw-golden-sm {
      min-width: 61.803vw; } }
  @media (min-width: 720px) {
    .has-min-width-vw-golden-md {
      min-width: 61.803vw; } }
  @media (min-width: 1024px) {
    .has-min-width-vw-golden-nav {
      min-width: 61.803vw; } }
  @media (min-width: 1024px) {
    .has-min-width-vw-golden-lg {
      min-width: 61.803vw; } }
  @media (min-width: 1280px) {
    .has-min-width-vw-golden-xl {
      min-width: 61.803vw; } }
  @media (min-width: 1920px) {
    .has-min-width-vw-golden-xxl {
      min-width: 61.803vw; } }

.pull-right {
  float: right !important; }
  @media (min-width: 0px) {
    .pull-right-xs {
      float: right !important; } }
  @media (min-width: 480px) {
    .pull-right-sm {
      float: right !important; } }
  @media (min-width: 720px) {
    .pull-right-md {
      float: right !important; } }
  @media (min-width: 1024px) {
    .pull-right-nav {
      float: right !important; } }
  @media (min-width: 1024px) {
    .pull-right-lg {
      float: right !important; } }
  @media (min-width: 1280px) {
    .pull-right-xl {
      float: right !important; } }
  @media (min-width: 1920px) {
    .pull-right-xxl {
      float: right !important; } }

.pull-left {
  float: left !important; }
  @media (min-width: 0px) {
    .pull-left-xs {
      float: left !important; } }
  @media (min-width: 480px) {
    .pull-left-sm {
      float: left !important; } }
  @media (min-width: 720px) {
    .pull-left-md {
      float: left !important; } }
  @media (min-width: 1024px) {
    .pull-left-nav {
      float: left !important; } }
  @media (min-width: 1024px) {
    .pull-left-lg {
      float: left !important; } }
  @media (min-width: 1280px) {
    .pull-left-xl {
      float: left !important; } }
  @media (min-width: 1920px) {
    .pull-left-xxl {
      float: left !important; } }

.is-scrollable {
  -webkit-overflow-scrolling: touch;
  overflow: scroll; }
  @media (min-width: 0px) {
    .is-scrollable-xs {
      -webkit-overflow-scrolling: touch;
      overflow: scroll; } }
  @media (min-width: 480px) {
    .is-scrollable-sm {
      -webkit-overflow-scrolling: touch;
      overflow: scroll; } }
  @media (min-width: 720px) {
    .is-scrollable-md {
      -webkit-overflow-scrolling: touch;
      overflow: scroll; } }
  @media (min-width: 1024px) {
    .is-scrollable-nav {
      -webkit-overflow-scrolling: touch;
      overflow: scroll; } }
  @media (min-width: 1024px) {
    .is-scrollable-lg {
      -webkit-overflow-scrolling: touch;
      overflow: scroll; } }
  @media (min-width: 1280px) {
    .is-scrollable-xl {
      -webkit-overflow-scrolling: touch;
      overflow: scroll; } }
  @media (min-width: 1920px) {
    .is-scrollable-xxl {
      -webkit-overflow-scrolling: touch;
      overflow: scroll; } }
  .is-scrollable-x {
    -webkit-overflow-scrolling: touch;
    overflow-x: scroll; }
    @media (min-width: 0px) {
      .is-scrollable-x-xs {
        -webkit-overflow-scrolling: touch;
        overflow-x: scroll; } }
    @media (min-width: 480px) {
      .is-scrollable-x-sm {
        -webkit-overflow-scrolling: touch;
        overflow-x: scroll; } }
    @media (min-width: 720px) {
      .is-scrollable-x-md {
        -webkit-overflow-scrolling: touch;
        overflow-x: scroll; } }
    @media (min-width: 1024px) {
      .is-scrollable-x-nav {
        -webkit-overflow-scrolling: touch;
        overflow-x: scroll; } }
    @media (min-width: 1024px) {
      .is-scrollable-x-lg {
        -webkit-overflow-scrolling: touch;
        overflow-x: scroll; } }
    @media (min-width: 1280px) {
      .is-scrollable-x-xl {
        -webkit-overflow-scrolling: touch;
        overflow-x: scroll; } }
    @media (min-width: 1920px) {
      .is-scrollable-x-xxl {
        -webkit-overflow-scrolling: touch;
        overflow-x: scroll; } }
    .is-scrollable-x-contain {
      -webkit-overflow-scrolling: touch;
      overflow-x: scroll;
      overscroll-behavior-x: contain; }
      @media (min-width: 0px) {
        .is-scrollable-x-contain-xs {
          -webkit-overflow-scrolling: touch;
          overflow-x: scroll;
          overscroll-behavior-x: contain; } }
      @media (min-width: 480px) {
        .is-scrollable-x-contain-sm {
          -webkit-overflow-scrolling: touch;
          overflow-x: scroll;
          overscroll-behavior-x: contain; } }
      @media (min-width: 720px) {
        .is-scrollable-x-contain-md {
          -webkit-overflow-scrolling: touch;
          overflow-x: scroll;
          overscroll-behavior-x: contain; } }
      @media (min-width: 1024px) {
        .is-scrollable-x-contain-nav {
          -webkit-overflow-scrolling: touch;
          overflow-x: scroll;
          overscroll-behavior-x: contain; } }
      @media (min-width: 1024px) {
        .is-scrollable-x-contain-lg {
          -webkit-overflow-scrolling: touch;
          overflow-x: scroll;
          overscroll-behavior-x: contain; } }
      @media (min-width: 1280px) {
        .is-scrollable-x-contain-xl {
          -webkit-overflow-scrolling: touch;
          overflow-x: scroll;
          overscroll-behavior-x: contain; } }
      @media (min-width: 1920px) {
        .is-scrollable-x-contain-xxl {
          -webkit-overflow-scrolling: touch;
          overflow-x: scroll;
          overscroll-behavior-x: contain; } }
  .is-scrollable-y {
    -webkit-overflow-scrolling: touch;
    overflow-y: scroll; }
    @media (min-width: 0px) {
      .is-scrollable-y-xs {
        -webkit-overflow-scrolling: touch;
        overflow-y: scroll; } }
    @media (min-width: 480px) {
      .is-scrollable-y-sm {
        -webkit-overflow-scrolling: touch;
        overflow-y: scroll; } }
    @media (min-width: 720px) {
      .is-scrollable-y-md {
        -webkit-overflow-scrolling: touch;
        overflow-y: scroll; } }
    @media (min-width: 1024px) {
      .is-scrollable-y-nav {
        -webkit-overflow-scrolling: touch;
        overflow-y: scroll; } }
    @media (min-width: 1024px) {
      .is-scrollable-y-lg {
        -webkit-overflow-scrolling: touch;
        overflow-y: scroll; } }
    @media (min-width: 1280px) {
      .is-scrollable-y-xl {
        -webkit-overflow-scrolling: touch;
        overflow-y: scroll; } }
    @media (min-width: 1920px) {
      .is-scrollable-y-xxl {
        -webkit-overflow-scrolling: touch;
        overflow-y: scroll; } }
    .is-scrollable-y-contain {
      -webkit-overflow-scrolling: touch;
      overflow-y: scroll;
      overscroll-behavior-y: contain; }
      @media (min-width: 0px) {
        .is-scrollable-y-contain-xs {
          -webkit-overflow-scrolling: touch;
          overflow-y: scroll;
          overscroll-behavior-y: contain; } }
      @media (min-width: 480px) {
        .is-scrollable-y-contain-sm {
          -webkit-overflow-scrolling: touch;
          overflow-y: scroll;
          overscroll-behavior-y: contain; } }
      @media (min-width: 720px) {
        .is-scrollable-y-contain-md {
          -webkit-overflow-scrolling: touch;
          overflow-y: scroll;
          overscroll-behavior-y: contain; } }
      @media (min-width: 1024px) {
        .is-scrollable-y-contain-nav {
          -webkit-overflow-scrolling: touch;
          overflow-y: scroll;
          overscroll-behavior-y: contain; } }
      @media (min-width: 1024px) {
        .is-scrollable-y-contain-lg {
          -webkit-overflow-scrolling: touch;
          overflow-y: scroll;
          overscroll-behavior-y: contain; } }
      @media (min-width: 1280px) {
        .is-scrollable-y-contain-xl {
          -webkit-overflow-scrolling: touch;
          overflow-y: scroll;
          overscroll-behavior-y: contain; } }
      @media (min-width: 1920px) {
        .is-scrollable-y-contain-xxl {
          -webkit-overflow-scrolling: touch;
          overflow-y: scroll;
          overscroll-behavior-y: contain; } }

.is-scrollable-auto-x {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto; }
  @media (min-width: 0px) {
    .is-scrollable-auto-x-xs {
      -webkit-overflow-scrolling: touch;
      overflow-x: auto; } }
  @media (min-width: 480px) {
    .is-scrollable-auto-x-sm {
      -webkit-overflow-scrolling: touch;
      overflow-x: auto; } }
  @media (min-width: 720px) {
    .is-scrollable-auto-x-md {
      -webkit-overflow-scrolling: touch;
      overflow-x: auto; } }
  @media (min-width: 1024px) {
    .is-scrollable-auto-x-nav {
      -webkit-overflow-scrolling: touch;
      overflow-x: auto; } }
  @media (min-width: 1024px) {
    .is-scrollable-auto-x-lg {
      -webkit-overflow-scrolling: touch;
      overflow-x: auto; } }
  @media (min-width: 1280px) {
    .is-scrollable-auto-x-xl {
      -webkit-overflow-scrolling: touch;
      overflow-x: auto; } }
  @media (min-width: 1920px) {
    .is-scrollable-auto-x-xxl {
      -webkit-overflow-scrolling: touch;
      overflow-x: auto; } }
  .is-scrollable-auto-x-contain {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    overscroll-behavior-x: contain; }
    @media (min-width: 0px) {
      .is-scrollable-auto-x-contain-xs {
        -webkit-overflow-scrolling: touch;
        overflow-x: auto;
        overscroll-behavior-x: contain; } }
    @media (min-width: 480px) {
      .is-scrollable-auto-x-contain-sm {
        -webkit-overflow-scrolling: touch;
        overflow-x: auto;
        overscroll-behavior-x: contain; } }
    @media (min-width: 720px) {
      .is-scrollable-auto-x-contain-md {
        -webkit-overflow-scrolling: touch;
        overflow-x: auto;
        overscroll-behavior-x: contain; } }
    @media (min-width: 1024px) {
      .is-scrollable-auto-x-contain-nav {
        -webkit-overflow-scrolling: touch;
        overflow-x: auto;
        overscroll-behavior-x: contain; } }
    @media (min-width: 1024px) {
      .is-scrollable-auto-x-contain-lg {
        -webkit-overflow-scrolling: touch;
        overflow-x: auto;
        overscroll-behavior-x: contain; } }
    @media (min-width: 1280px) {
      .is-scrollable-auto-x-contain-xl {
        -webkit-overflow-scrolling: touch;
        overflow-x: auto;
        overscroll-behavior-x: contain; } }
    @media (min-width: 1920px) {
      .is-scrollable-auto-x-contain-xxl {
        -webkit-overflow-scrolling: touch;
        overflow-x: auto;
        overscroll-behavior-x: contain; } }

.is-scrollable-auto-y {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto; }
  @media (min-width: 0px) {
    .is-scrollable-auto-y-xs {
      -webkit-overflow-scrolling: touch;
      overflow-y: auto; } }
  @media (min-width: 480px) {
    .is-scrollable-auto-y-sm {
      -webkit-overflow-scrolling: touch;
      overflow-y: auto; } }
  @media (min-width: 720px) {
    .is-scrollable-auto-y-md {
      -webkit-overflow-scrolling: touch;
      overflow-y: auto; } }
  @media (min-width: 1024px) {
    .is-scrollable-auto-y-nav {
      -webkit-overflow-scrolling: touch;
      overflow-y: auto; } }
  @media (min-width: 1024px) {
    .is-scrollable-auto-y-lg {
      -webkit-overflow-scrolling: touch;
      overflow-y: auto; } }
  @media (min-width: 1280px) {
    .is-scrollable-auto-y-xl {
      -webkit-overflow-scrolling: touch;
      overflow-y: auto; } }
  @media (min-width: 1920px) {
    .is-scrollable-auto-y-xxl {
      -webkit-overflow-scrolling: touch;
      overflow-y: auto; } }
  .is-scrollable-auto-y-contain {
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
    overscroll-behavior-y: contain; }
    @media (min-width: 0px) {
      .is-scrollable-auto-y-contain-xs {
        -webkit-overflow-scrolling: touch;
        overflow-y: auto;
        overscroll-behavior-y: contain; } }
    @media (min-width: 480px) {
      .is-scrollable-auto-y-contain-sm {
        -webkit-overflow-scrolling: touch;
        overflow-y: auto;
        overscroll-behavior-y: contain; } }
    @media (min-width: 720px) {
      .is-scrollable-auto-y-contain-md {
        -webkit-overflow-scrolling: touch;
        overflow-y: auto;
        overscroll-behavior-y: contain; } }
    @media (min-width: 1024px) {
      .is-scrollable-auto-y-contain-nav {
        -webkit-overflow-scrolling: touch;
        overflow-y: auto;
        overscroll-behavior-y: contain; } }
    @media (min-width: 1024px) {
      .is-scrollable-auto-y-contain-lg {
        -webkit-overflow-scrolling: touch;
        overflow-y: auto;
        overscroll-behavior-y: contain; } }
    @media (min-width: 1280px) {
      .is-scrollable-auto-y-contain-xl {
        -webkit-overflow-scrolling: touch;
        overflow-y: auto;
        overscroll-behavior-y: contain; } }
    @media (min-width: 1920px) {
      .is-scrollable-auto-y-contain-xxl {
        -webkit-overflow-scrolling: touch;
        overflow-y: auto;
        overscroll-behavior-y: contain; } }

.is-clipped {
  overflow: hidden; }
  @media (min-width: 0px) {
    .is-clipped-xs {
      overflow: hidden; } }
  @media (min-width: 480px) {
    .is-clipped-sm {
      overflow: hidden; } }
  @media (min-width: 720px) {
    .is-clipped-md {
      overflow: hidden; } }
  @media (min-width: 1024px) {
    .is-clipped-nav {
      overflow: hidden; } }
  @media (min-width: 1024px) {
    .is-clipped-lg {
      overflow: hidden; } }
  @media (min-width: 1280px) {
    .is-clipped-xl {
      overflow: hidden; } }
  @media (min-width: 1920px) {
    .is-clipped-xxl {
      overflow: hidden; } }
  .is-clipped-x {
    overflow-x: hidden; }
    @media (min-width: 0px) {
      .is-clipped-x-xs {
        overflow-x: hidden; } }
    @media (min-width: 480px) {
      .is-clipped-x-sm {
        overflow-x: hidden; } }
    @media (min-width: 720px) {
      .is-clipped-x-md {
        overflow-x: hidden; } }
    @media (min-width: 1024px) {
      .is-clipped-x-nav {
        overflow-x: hidden; } }
    @media (min-width: 1024px) {
      .is-clipped-x-lg {
        overflow-x: hidden; } }
    @media (min-width: 1280px) {
      .is-clipped-x-xl {
        overflow-x: hidden; } }
    @media (min-width: 1920px) {
      .is-clipped-x-xxl {
        overflow-x: hidden; } }
  .is-clipped-y {
    overflow-y: hidden; }
    @media (min-width: 0px) {
      .is-clipped-y-xs {
        overflow-y: hidden; } }
    @media (min-width: 480px) {
      .is-clipped-y-sm {
        overflow-y: hidden; } }
    @media (min-width: 720px) {
      .is-clipped-y-md {
        overflow-y: hidden; } }
    @media (min-width: 1024px) {
      .is-clipped-y-nav {
        overflow-y: hidden; } }
    @media (min-width: 1024px) {
      .is-clipped-y-lg {
        overflow-y: hidden; } }
    @media (min-width: 1280px) {
      .is-clipped-y-xl {
        overflow-y: hidden; } }
    @media (min-width: 1920px) {
      .is-clipped-y-xxl {
        overflow-y: hidden; } }

.is-relative {
  position: relative; }
  @media (min-width: 0px) {
    .is-relative-xs {
      position: relative; } }
  @media (min-width: 480px) {
    .is-relative-sm {
      position: relative; } }
  @media (min-width: 720px) {
    .is-relative-md {
      position: relative; } }
  @media (min-width: 1024px) {
    .is-relative-nav {
      position: relative; } }
  @media (min-width: 1024px) {
    .is-relative-lg {
      position: relative; } }
  @media (min-width: 1280px) {
    .is-relative-xl {
      position: relative; } }
  @media (min-width: 1920px) {
    .is-relative-xxl {
      position: relative; } }

.is-absolute {
  position: absolute; }
  @media (min-width: 0px) {
    .is-absolute-xs {
      position: absolute; } }
  @media (min-width: 480px) {
    .is-absolute-sm {
      position: absolute; } }
  @media (min-width: 720px) {
    .is-absolute-md {
      position: absolute; } }
  @media (min-width: 1024px) {
    .is-absolute-nav {
      position: absolute; } }
  @media (min-width: 1024px) {
    .is-absolute-lg {
      position: absolute; } }
  @media (min-width: 1280px) {
    .is-absolute-xl {
      position: absolute; } }
  @media (min-width: 1920px) {
    .is-absolute-xxl {
      position: absolute; } }

.is-fixed {
  position: fixed; }
  @media (min-width: 0px) {
    .is-fixed-xs {
      position: fixed; } }
  @media (min-width: 480px) {
    .is-fixed-sm {
      position: fixed; } }
  @media (min-width: 720px) {
    .is-fixed-md {
      position: fixed; } }
  @media (min-width: 1024px) {
    .is-fixed-nav {
      position: fixed; } }
  @media (min-width: 1024px) {
    .is-fixed-lg {
      position: fixed; } }
  @media (min-width: 1280px) {
    .is-fixed-xl {
      position: fixed; } }
  @media (min-width: 1920px) {
    .is-fixed-xxl {
      position: fixed; } }

.is-sticky {
  position: sticky; }
  @media (min-width: 0px) {
    .is-sticky-xs {
      position: sticky; } }
  @media (min-width: 480px) {
    .is-sticky-sm {
      position: sticky; } }
  @media (min-width: 720px) {
    .is-sticky-md {
      position: sticky; } }
  @media (min-width: 1024px) {
    .is-sticky-nav {
      position: sticky; } }
  @media (min-width: 1024px) {
    .is-sticky-lg {
      position: sticky; } }
  @media (min-width: 1280px) {
    .is-sticky-xl {
      position: sticky; } }
  @media (min-width: 1920px) {
    .is-sticky-xxl {
      position: sticky; } }

.is-static {
  position: static; }
  @media (min-width: 0px) {
    .is-static-xs {
      position: static; } }
  @media (min-width: 480px) {
    .is-static-sm {
      position: static; } }
  @media (min-width: 720px) {
    .is-static-md {
      position: static; } }
  @media (min-width: 1024px) {
    .is-static-nav {
      position: static; } }
  @media (min-width: 1024px) {
    .is-static-lg {
      position: static; } }
  @media (min-width: 1280px) {
    .is-static-xl {
      position: static; } }
  @media (min-width: 1920px) {
    .is-static-xxl {
      position: static; } }

.is-positioned-n {
  left: 50%;
  top: 0;
  transform: translateX(-50%); }
  @media (min-width: 0px) {
    .is-positioned-n-xs {
      left: 50%;
      top: 0;
      transform: translateX(-50%); } }
  @media (min-width: 480px) {
    .is-positioned-n-sm {
      left: 50%;
      top: 0;
      transform: translateX(-50%); } }
  @media (min-width: 720px) {
    .is-positioned-n-md {
      left: 50%;
      top: 0;
      transform: translateX(-50%); } }
  @media (min-width: 1024px) {
    .is-positioned-n-nav {
      left: 50%;
      top: 0;
      transform: translateX(-50%); } }
  @media (min-width: 1024px) {
    .is-positioned-n-lg {
      left: 50%;
      top: 0;
      transform: translateX(-50%); } }
  @media (min-width: 1280px) {
    .is-positioned-n-xl {
      left: 50%;
      top: 0;
      transform: translateX(-50%); } }
  @media (min-width: 1920px) {
    .is-positioned-n-xxl {
      left: 50%;
      top: 0;
      transform: translateX(-50%); } }

.is-positioned-ne {
  right: 0;
  top: 0; }
  @media (min-width: 0px) {
    .is-positioned-ne-xs {
      right: 0;
      top: 0; } }
  @media (min-width: 480px) {
    .is-positioned-ne-sm {
      right: 0;
      top: 0; } }
  @media (min-width: 720px) {
    .is-positioned-ne-md {
      right: 0;
      top: 0; } }
  @media (min-width: 1024px) {
    .is-positioned-ne-nav {
      right: 0;
      top: 0; } }
  @media (min-width: 1024px) {
    .is-positioned-ne-lg {
      right: 0;
      top: 0; } }
  @media (min-width: 1280px) {
    .is-positioned-ne-xl {
      right: 0;
      top: 0; } }
  @media (min-width: 1920px) {
    .is-positioned-ne-xxl {
      right: 0;
      top: 0; } }

.is-positioned-e {
  right: 0;
  top: 50%;
  transform: translateY(-50%); }
  @media (min-width: 0px) {
    .is-positioned-e-xs {
      right: 0;
      top: 50%;
      transform: translateY(-50%); } }
  @media (min-width: 480px) {
    .is-positioned-e-sm {
      right: 0;
      top: 50%;
      transform: translateY(-50%); } }
  @media (min-width: 720px) {
    .is-positioned-e-md {
      right: 0;
      top: 50%;
      transform: translateY(-50%); } }
  @media (min-width: 1024px) {
    .is-positioned-e-nav {
      right: 0;
      top: 50%;
      transform: translateY(-50%); } }
  @media (min-width: 1024px) {
    .is-positioned-e-lg {
      right: 0;
      top: 50%;
      transform: translateY(-50%); } }
  @media (min-width: 1280px) {
    .is-positioned-e-xl {
      right: 0;
      top: 50%;
      transform: translateY(-50%); } }
  @media (min-width: 1920px) {
    .is-positioned-e-xxl {
      right: 0;
      top: 50%;
      transform: translateY(-50%); } }

.is-positioned-se {
  bottom: 0;
  right: 0; }
  @media (min-width: 0px) {
    .is-positioned-se-xs {
      bottom: 0;
      right: 0; } }
  @media (min-width: 480px) {
    .is-positioned-se-sm {
      bottom: 0;
      right: 0; } }
  @media (min-width: 720px) {
    .is-positioned-se-md {
      bottom: 0;
      right: 0; } }
  @media (min-width: 1024px) {
    .is-positioned-se-nav {
      bottom: 0;
      right: 0; } }
  @media (min-width: 1024px) {
    .is-positioned-se-lg {
      bottom: 0;
      right: 0; } }
  @media (min-width: 1280px) {
    .is-positioned-se-xl {
      bottom: 0;
      right: 0; } }
  @media (min-width: 1920px) {
    .is-positioned-se-xxl {
      bottom: 0;
      right: 0; } }

.is-positioned-s {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%); }
  @media (min-width: 0px) {
    .is-positioned-s-xs {
      bottom: 0;
      left: 50%;
      transform: translateX(-50%); } }
  @media (min-width: 480px) {
    .is-positioned-s-sm {
      bottom: 0;
      left: 50%;
      transform: translateX(-50%); } }
  @media (min-width: 720px) {
    .is-positioned-s-md {
      bottom: 0;
      left: 50%;
      transform: translateX(-50%); } }
  @media (min-width: 1024px) {
    .is-positioned-s-nav {
      bottom: 0;
      left: 50%;
      transform: translateX(-50%); } }
  @media (min-width: 1024px) {
    .is-positioned-s-lg {
      bottom: 0;
      left: 50%;
      transform: translateX(-50%); } }
  @media (min-width: 1280px) {
    .is-positioned-s-xl {
      bottom: 0;
      left: 50%;
      transform: translateX(-50%); } }
  @media (min-width: 1920px) {
    .is-positioned-s-xxl {
      bottom: 0;
      left: 50%;
      transform: translateX(-50%); } }

.is-positioned-sw {
  bottom: 0;
  left: 0; }
  @media (min-width: 0px) {
    .is-positioned-sw-xs {
      bottom: 0;
      left: 0; } }
  @media (min-width: 480px) {
    .is-positioned-sw-sm {
      bottom: 0;
      left: 0; } }
  @media (min-width: 720px) {
    .is-positioned-sw-md {
      bottom: 0;
      left: 0; } }
  @media (min-width: 1024px) {
    .is-positioned-sw-nav {
      bottom: 0;
      left: 0; } }
  @media (min-width: 1024px) {
    .is-positioned-sw-lg {
      bottom: 0;
      left: 0; } }
  @media (min-width: 1280px) {
    .is-positioned-sw-xl {
      bottom: 0;
      left: 0; } }
  @media (min-width: 1920px) {
    .is-positioned-sw-xxl {
      bottom: 0;
      left: 0; } }

.is-positioned-w {
  left: 0;
  top: 50%;
  transform: translateY(-50%); }
  @media (min-width: 0px) {
    .is-positioned-w-xs {
      left: 0;
      top: 50%;
      transform: translateY(-50%); } }
  @media (min-width: 480px) {
    .is-positioned-w-sm {
      left: 0;
      top: 50%;
      transform: translateY(-50%); } }
  @media (min-width: 720px) {
    .is-positioned-w-md {
      left: 0;
      top: 50%;
      transform: translateY(-50%); } }
  @media (min-width: 1024px) {
    .is-positioned-w-nav {
      left: 0;
      top: 50%;
      transform: translateY(-50%); } }
  @media (min-width: 1024px) {
    .is-positioned-w-lg {
      left: 0;
      top: 50%;
      transform: translateY(-50%); } }
  @media (min-width: 1280px) {
    .is-positioned-w-xl {
      left: 0;
      top: 50%;
      transform: translateY(-50%); } }
  @media (min-width: 1920px) {
    .is-positioned-w-xxl {
      left: 0;
      top: 50%;
      transform: translateY(-50%); } }

.is-positioned-nw {
  left: 0;
  top: 0; }
  @media (min-width: 0px) {
    .is-positioned-nw-xs {
      left: 0;
      top: 0; } }
  @media (min-width: 480px) {
    .is-positioned-nw-sm {
      left: 0;
      top: 0; } }
  @media (min-width: 720px) {
    .is-positioned-nw-md {
      left: 0;
      top: 0; } }
  @media (min-width: 1024px) {
    .is-positioned-nw-nav {
      left: 0;
      top: 0; } }
  @media (min-width: 1024px) {
    .is-positioned-nw-lg {
      left: 0;
      top: 0; } }
  @media (min-width: 1280px) {
    .is-positioned-nw-xl {
      left: 0;
      top: 0; } }
  @media (min-width: 1920px) {
    .is-positioned-nw-xxl {
      left: 0;
      top: 0; } }

.is-positioned-0 {
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%); }
  @media (min-width: 0px) {
    .is-positioned-0-xs {
      left: 50%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%); } }
  @media (min-width: 480px) {
    .is-positioned-0-sm {
      left: 50%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%); } }
  @media (min-width: 720px) {
    .is-positioned-0-md {
      left: 50%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%); } }
  @media (min-width: 1024px) {
    .is-positioned-0-nav {
      left: 50%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%); } }
  @media (min-width: 1024px) {
    .is-positioned-0-lg {
      left: 50%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%); } }
  @media (min-width: 1280px) {
    .is-positioned-0-xl {
      left: 50%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%); } }
  @media (min-width: 1920px) {
    .is-positioned-0-xxl {
      left: 50%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%); } }

.not-positioned {
  bottom: auto;
  left: auto;
  right: auto;
  top: auto;
  transform: initial; }
  @media (min-width: 0px) {
    .not-positioned-xs {
      bottom: auto;
      left: auto;
      right: auto;
      top: auto;
      transform: initial; } }
  @media (min-width: 480px) {
    .not-positioned-sm {
      bottom: auto;
      left: auto;
      right: auto;
      top: auto;
      transform: initial; } }
  @media (min-width: 720px) {
    .not-positioned-md {
      bottom: auto;
      left: auto;
      right: auto;
      top: auto;
      transform: initial; } }
  @media (min-width: 1024px) {
    .not-positioned-nav {
      bottom: auto;
      left: auto;
      right: auto;
      top: auto;
      transform: initial; } }
  @media (min-width: 1024px) {
    .not-positioned-lg {
      bottom: auto;
      left: auto;
      right: auto;
      top: auto;
      transform: initial; } }
  @media (min-width: 1280px) {
    .not-positioned-xl {
      bottom: auto;
      left: auto;
      right: auto;
      top: auto;
      transform: initial; } }
  @media (min-width: 1920px) {
    .not-positioned-xxl {
      bottom: auto;
      left: auto;
      right: auto;
      top: auto;
      transform: initial; } }

.is-layer-0 {
  z-index: 0; }
  @media (min-width: 0px) {
    .is-layer-0-xs {
      z-index: 0; } }
  @media (min-width: 480px) {
    .is-layer-0-sm {
      z-index: 0; } }
  @media (min-width: 720px) {
    .is-layer-0-md {
      z-index: 0; } }
  @media (min-width: 1024px) {
    .is-layer-0-nav {
      z-index: 0; } }
  @media (min-width: 1024px) {
    .is-layer-0-lg {
      z-index: 0; } }
  @media (min-width: 1280px) {
    .is-layer-0-xl {
      z-index: 0; } }
  @media (min-width: 1920px) {
    .is-layer-0-xxl {
      z-index: 0; } }

.is-layer-10 {
  z-index: 10; }
  @media (min-width: 0px) {
    .is-layer-10-xs {
      z-index: 10; } }
  @media (min-width: 480px) {
    .is-layer-10-sm {
      z-index: 10; } }
  @media (min-width: 720px) {
    .is-layer-10-md {
      z-index: 10; } }
  @media (min-width: 1024px) {
    .is-layer-10-nav {
      z-index: 10; } }
  @media (min-width: 1024px) {
    .is-layer-10-lg {
      z-index: 10; } }
  @media (min-width: 1280px) {
    .is-layer-10-xl {
      z-index: 10; } }
  @media (min-width: 1920px) {
    .is-layer-10-xxl {
      z-index: 10; } }

.is-layer-20 {
  z-index: 20; }
  @media (min-width: 0px) {
    .is-layer-20-xs {
      z-index: 20; } }
  @media (min-width: 480px) {
    .is-layer-20-sm {
      z-index: 20; } }
  @media (min-width: 720px) {
    .is-layer-20-md {
      z-index: 20; } }
  @media (min-width: 1024px) {
    .is-layer-20-nav {
      z-index: 20; } }
  @media (min-width: 1024px) {
    .is-layer-20-lg {
      z-index: 20; } }
  @media (min-width: 1280px) {
    .is-layer-20-xl {
      z-index: 20; } }
  @media (min-width: 1920px) {
    .is-layer-20-xxl {
      z-index: 20; } }

.is-layer-30 {
  z-index: 30; }
  @media (min-width: 0px) {
    .is-layer-30-xs {
      z-index: 30; } }
  @media (min-width: 480px) {
    .is-layer-30-sm {
      z-index: 30; } }
  @media (min-width: 720px) {
    .is-layer-30-md {
      z-index: 30; } }
  @media (min-width: 1024px) {
    .is-layer-30-nav {
      z-index: 30; } }
  @media (min-width: 1024px) {
    .is-layer-30-lg {
      z-index: 30; } }
  @media (min-width: 1280px) {
    .is-layer-30-xl {
      z-index: 30; } }
  @media (min-width: 1920px) {
    .is-layer-30-xxl {
      z-index: 30; } }

.is-layer-40 {
  z-index: 40; }
  @media (min-width: 0px) {
    .is-layer-40-xs {
      z-index: 40; } }
  @media (min-width: 480px) {
    .is-layer-40-sm {
      z-index: 40; } }
  @media (min-width: 720px) {
    .is-layer-40-md {
      z-index: 40; } }
  @media (min-width: 1024px) {
    .is-layer-40-nav {
      z-index: 40; } }
  @media (min-width: 1024px) {
    .is-layer-40-lg {
      z-index: 40; } }
  @media (min-width: 1280px) {
    .is-layer-40-xl {
      z-index: 40; } }
  @media (min-width: 1920px) {
    .is-layer-40-xxl {
      z-index: 40; } }

.is-layer-50 {
  z-index: 50; }
  @media (min-width: 0px) {
    .is-layer-50-xs {
      z-index: 50; } }
  @media (min-width: 480px) {
    .is-layer-50-sm {
      z-index: 50; } }
  @media (min-width: 720px) {
    .is-layer-50-md {
      z-index: 50; } }
  @media (min-width: 1024px) {
    .is-layer-50-nav {
      z-index: 50; } }
  @media (min-width: 1024px) {
    .is-layer-50-lg {
      z-index: 50; } }
  @media (min-width: 1280px) {
    .is-layer-50-xl {
      z-index: 50; } }
  @media (min-width: 1920px) {
    .is-layer-50-xxl {
      z-index: 50; } }

.is-layer-60 {
  z-index: 60; }
  @media (min-width: 0px) {
    .is-layer-60-xs {
      z-index: 60; } }
  @media (min-width: 480px) {
    .is-layer-60-sm {
      z-index: 60; } }
  @media (min-width: 720px) {
    .is-layer-60-md {
      z-index: 60; } }
  @media (min-width: 1024px) {
    .is-layer-60-nav {
      z-index: 60; } }
  @media (min-width: 1024px) {
    .is-layer-60-lg {
      z-index: 60; } }
  @media (min-width: 1280px) {
    .is-layer-60-xl {
      z-index: 60; } }
  @media (min-width: 1920px) {
    .is-layer-60-xxl {
      z-index: 60; } }

.is-layer-70 {
  z-index: 70; }
  @media (min-width: 0px) {
    .is-layer-70-xs {
      z-index: 70; } }
  @media (min-width: 480px) {
    .is-layer-70-sm {
      z-index: 70; } }
  @media (min-width: 720px) {
    .is-layer-70-md {
      z-index: 70; } }
  @media (min-width: 1024px) {
    .is-layer-70-nav {
      z-index: 70; } }
  @media (min-width: 1024px) {
    .is-layer-70-lg {
      z-index: 70; } }
  @media (min-width: 1280px) {
    .is-layer-70-xl {
      z-index: 70; } }
  @media (min-width: 1920px) {
    .is-layer-70-xxl {
      z-index: 70; } }

.is-layer-80 {
  z-index: 80; }
  @media (min-width: 0px) {
    .is-layer-80-xs {
      z-index: 80; } }
  @media (min-width: 480px) {
    .is-layer-80-sm {
      z-index: 80; } }
  @media (min-width: 720px) {
    .is-layer-80-md {
      z-index: 80; } }
  @media (min-width: 1024px) {
    .is-layer-80-nav {
      z-index: 80; } }
  @media (min-width: 1024px) {
    .is-layer-80-lg {
      z-index: 80; } }
  @media (min-width: 1280px) {
    .is-layer-80-xl {
      z-index: 80; } }
  @media (min-width: 1920px) {
    .is-layer-80-xxl {
      z-index: 80; } }

.is-layer-90 {
  z-index: 90; }
  @media (min-width: 0px) {
    .is-layer-90-xs {
      z-index: 90; } }
  @media (min-width: 480px) {
    .is-layer-90-sm {
      z-index: 90; } }
  @media (min-width: 720px) {
    .is-layer-90-md {
      z-index: 90; } }
  @media (min-width: 1024px) {
    .is-layer-90-nav {
      z-index: 90; } }
  @media (min-width: 1024px) {
    .is-layer-90-lg {
      z-index: 90; } }
  @media (min-width: 1280px) {
    .is-layer-90-xl {
      z-index: 90; } }
  @media (min-width: 1920px) {
    .is-layer-90-xxl {
      z-index: 90; } }

.is-layer-100 {
  z-index: 100; }
  @media (min-width: 0px) {
    .is-layer-100-xs {
      z-index: 100; } }
  @media (min-width: 480px) {
    .is-layer-100-sm {
      z-index: 100; } }
  @media (min-width: 720px) {
    .is-layer-100-md {
      z-index: 100; } }
  @media (min-width: 1024px) {
    .is-layer-100-nav {
      z-index: 100; } }
  @media (min-width: 1024px) {
    .is-layer-100-lg {
      z-index: 100; } }
  @media (min-width: 1280px) {
    .is-layer-100-xl {
      z-index: 100; } }
  @media (min-width: 1920px) {
    .is-layer-100-xxl {
      z-index: 100; } }

.is-layer-200 {
  z-index: 200; }
  @media (min-width: 0px) {
    .is-layer-200-xs {
      z-index: 200; } }
  @media (min-width: 480px) {
    .is-layer-200-sm {
      z-index: 200; } }
  @media (min-width: 720px) {
    .is-layer-200-md {
      z-index: 200; } }
  @media (min-width: 1024px) {
    .is-layer-200-nav {
      z-index: 200; } }
  @media (min-width: 1024px) {
    .is-layer-200-lg {
      z-index: 200; } }
  @media (min-width: 1280px) {
    .is-layer-200-xl {
      z-index: 200; } }
  @media (min-width: 1920px) {
    .is-layer-200-xxl {
      z-index: 200; } }

.is-layer-300 {
  z-index: 300; }
  @media (min-width: 0px) {
    .is-layer-300-xs {
      z-index: 300; } }
  @media (min-width: 480px) {
    .is-layer-300-sm {
      z-index: 300; } }
  @media (min-width: 720px) {
    .is-layer-300-md {
      z-index: 300; } }
  @media (min-width: 1024px) {
    .is-layer-300-nav {
      z-index: 300; } }
  @media (min-width: 1024px) {
    .is-layer-300-lg {
      z-index: 300; } }
  @media (min-width: 1280px) {
    .is-layer-300-xl {
      z-index: 300; } }
  @media (min-width: 1920px) {
    .is-layer-300-xxl {
      z-index: 300; } }

.is-layer-400 {
  z-index: 400; }
  @media (min-width: 0px) {
    .is-layer-400-xs {
      z-index: 400; } }
  @media (min-width: 480px) {
    .is-layer-400-sm {
      z-index: 400; } }
  @media (min-width: 720px) {
    .is-layer-400-md {
      z-index: 400; } }
  @media (min-width: 1024px) {
    .is-layer-400-nav {
      z-index: 400; } }
  @media (min-width: 1024px) {
    .is-layer-400-lg {
      z-index: 400; } }
  @media (min-width: 1280px) {
    .is-layer-400-xl {
      z-index: 400; } }
  @media (min-width: 1920px) {
    .is-layer-400-xxl {
      z-index: 400; } }

.is-layer-500 {
  z-index: 500; }
  @media (min-width: 0px) {
    .is-layer-500-xs {
      z-index: 500; } }
  @media (min-width: 480px) {
    .is-layer-500-sm {
      z-index: 500; } }
  @media (min-width: 720px) {
    .is-layer-500-md {
      z-index: 500; } }
  @media (min-width: 1024px) {
    .is-layer-500-nav {
      z-index: 500; } }
  @media (min-width: 1024px) {
    .is-layer-500-lg {
      z-index: 500; } }
  @media (min-width: 1280px) {
    .is-layer-500-xl {
      z-index: 500; } }
  @media (min-width: 1920px) {
    .is-layer-500-xxl {
      z-index: 500; } }

.is-layer-600 {
  z-index: 600; }
  @media (min-width: 0px) {
    .is-layer-600-xs {
      z-index: 600; } }
  @media (min-width: 480px) {
    .is-layer-600-sm {
      z-index: 600; } }
  @media (min-width: 720px) {
    .is-layer-600-md {
      z-index: 600; } }
  @media (min-width: 1024px) {
    .is-layer-600-nav {
      z-index: 600; } }
  @media (min-width: 1024px) {
    .is-layer-600-lg {
      z-index: 600; } }
  @media (min-width: 1280px) {
    .is-layer-600-xl {
      z-index: 600; } }
  @media (min-width: 1920px) {
    .is-layer-600-xxl {
      z-index: 600; } }

.is-layer-700 {
  z-index: 700; }
  @media (min-width: 0px) {
    .is-layer-700-xs {
      z-index: 700; } }
  @media (min-width: 480px) {
    .is-layer-700-sm {
      z-index: 700; } }
  @media (min-width: 720px) {
    .is-layer-700-md {
      z-index: 700; } }
  @media (min-width: 1024px) {
    .is-layer-700-nav {
      z-index: 700; } }
  @media (min-width: 1024px) {
    .is-layer-700-lg {
      z-index: 700; } }
  @media (min-width: 1280px) {
    .is-layer-700-xl {
      z-index: 700; } }
  @media (min-width: 1920px) {
    .is-layer-700-xxl {
      z-index: 700; } }

.is-layer-800 {
  z-index: 800; }
  @media (min-width: 0px) {
    .is-layer-800-xs {
      z-index: 800; } }
  @media (min-width: 480px) {
    .is-layer-800-sm {
      z-index: 800; } }
  @media (min-width: 720px) {
    .is-layer-800-md {
      z-index: 800; } }
  @media (min-width: 1024px) {
    .is-layer-800-nav {
      z-index: 800; } }
  @media (min-width: 1024px) {
    .is-layer-800-lg {
      z-index: 800; } }
  @media (min-width: 1280px) {
    .is-layer-800-xl {
      z-index: 800; } }
  @media (min-width: 1920px) {
    .is-layer-800-xxl {
      z-index: 800; } }

.is-layer-900 {
  z-index: 900; }
  @media (min-width: 0px) {
    .is-layer-900-xs {
      z-index: 900; } }
  @media (min-width: 480px) {
    .is-layer-900-sm {
      z-index: 900; } }
  @media (min-width: 720px) {
    .is-layer-900-md {
      z-index: 900; } }
  @media (min-width: 1024px) {
    .is-layer-900-nav {
      z-index: 900; } }
  @media (min-width: 1024px) {
    .is-layer-900-lg {
      z-index: 900; } }
  @media (min-width: 1280px) {
    .is-layer-900-xl {
      z-index: 900; } }
  @media (min-width: 1920px) {
    .is-layer-900-xxl {
      z-index: 900; } }

.is-layer-1000 {
  z-index: 1000; }
  @media (min-width: 0px) {
    .is-layer-1000-xs {
      z-index: 1000; } }
  @media (min-width: 480px) {
    .is-layer-1000-sm {
      z-index: 1000; } }
  @media (min-width: 720px) {
    .is-layer-1000-md {
      z-index: 1000; } }
  @media (min-width: 1024px) {
    .is-layer-1000-nav {
      z-index: 1000; } }
  @media (min-width: 1024px) {
    .is-layer-1000-lg {
      z-index: 1000; } }
  @media (min-width: 1280px) {
    .is-layer-1000-xl {
      z-index: 1000; } }
  @media (min-width: 1920px) {
    .is-layer-1000-xxl {
      z-index: 1000; } }

.is-layer-behind {
  z-index: -1; }
  @media (min-width: 0px) {
    .is-layer-behind-xs {
      z-index: -1; } }
  @media (min-width: 480px) {
    .is-layer-behind-sm {
      z-index: -1; } }
  @media (min-width: 720px) {
    .is-layer-behind-md {
      z-index: -1; } }
  @media (min-width: 1024px) {
    .is-layer-behind-nav {
      z-index: -1; } }
  @media (min-width: 1024px) {
    .is-layer-behind-lg {
      z-index: -1; } }
  @media (min-width: 1280px) {
    .is-layer-behind-xl {
      z-index: -1; } }
  @media (min-width: 1920px) {
    .is-layer-behind-xxl {
      z-index: -1; } }

.is-layer-max {
  z-index: 99999; }
  @media (min-width: 0px) {
    .is-layer-max-xs {
      z-index: 99999; } }
  @media (min-width: 480px) {
    .is-layer-max-sm {
      z-index: 99999; } }
  @media (min-width: 720px) {
    .is-layer-max-md {
      z-index: 99999; } }
  @media (min-width: 1024px) {
    .is-layer-max-nav {
      z-index: 99999; } }
  @media (min-width: 1024px) {
    .is-layer-max-lg {
      z-index: 99999; } }
  @media (min-width: 1280px) {
    .is-layer-max-xl {
      z-index: 99999; } }
  @media (min-width: 1920px) {
    .is-layer-max-xxl {
      z-index: 99999; } }

.is-visible-from-xs {
  display: none !important; }

.is-visible-xs {
  display: none !important; }

.is-visible-from-sm {
  display: none !important; }

.is-visible-sm {
  display: none !important; }

.is-visible-from-md {
  display: none !important; }

.is-visible-md {
  display: none !important; }

.is-visible-from-nav {
  display: none !important; }

.is-visible-nav {
  display: none !important; }

.is-visible-from-lg {
  display: none !important; }

.is-visible-lg {
  display: none !important; }

.is-visible-from-xl {
  display: none !important; }

.is-visible-xl {
  display: none !important; }

.is-visible-from-xxl {
  display: none !important; }

.is-visible-xxl {
  display: none !important; }

@media (min-width: 0px) and (max-width: 479px) {
  .is-visible-xs {
    display: inherit !important; } }

@media (min-width: 480px) and (max-width: 719px) {
  .is-visible-sm {
    display: inherit !important; } }

@media (min-width: 720px) and (max-width: 1023px) {
  .is-visible-md {
    display: inherit !important; } }

@media (min-width: 1024px) and (max-width: 1023px) {
  .is-visible-nav {
    display: inherit !important; } }

@media (min-width: 1024px) and (max-width: 1279px) {
  .is-visible-lg {
    display: inherit !important; } }

@media (min-width: 1280px) and (max-width: 1919px) {
  .is-visible-xl {
    display: inherit !important; } }

@media (min-width: 1920px) {
  .is-visible-xxl {
    display: inherit !important; } }

@media (min-width: 0px) {
  .is-visible-from-xs {
    display: inherit !important; } }

@media (min-width: 480px) {
  .is-visible-from-sm {
    display: inherit !important; } }

@media (min-width: 720px) {
  .is-visible-from-md {
    display: inherit !important; } }

@media (min-width: 1024px) {
  .is-visible-from-nav {
    display: inherit !important; } }

@media (min-width: 1024px) {
  .is-visible-from-lg {
    display: inherit !important; } }

@media (min-width: 1280px) {
  .is-visible-from-xl {
    display: inherit !important; } }

@media (min-width: 1920px) {
  .is-visible-from-xxl {
    display: inherit !important; } }

.is-hidden {
  display: none !important; }
  @media (min-width: 0px) and (max-width: 479px) {
    .is-hidden-xs {
      display: none !important; } }
  @media (min-width: 480px) and (max-width: 719px) {
    .is-hidden-sm {
      display: none !important; } }
  @media (min-width: 720px) and (max-width: 1023px) {
    .is-hidden-md {
      display: none !important; } }
  @media (min-width: 1024px) and (max-width: 1023px) {
    .is-hidden-nav {
      display: none !important; } }
  @media (min-width: 1024px) and (max-width: 1279px) {
    .is-hidden-lg {
      display: none !important; } }
  @media (min-width: 1280px) and (max-width: 1919px) {
    .is-hidden-xl {
      display: none !important; } }
  @media (min-width: 1920px) {
    .is-hidden-xxl {
      display: none !important; } }
  @media (min-width: 0px) {
    .is-hidden-from-xs {
      display: none !important; } }
  @media (min-width: 480px) {
    .is-hidden-from-sm {
      display: none !important; } }
  @media (min-width: 720px) {
    .is-hidden-from-md {
      display: none !important; } }
  @media (min-width: 1024px) {
    .is-hidden-from-nav {
      display: none !important; } }
  @media (min-width: 1024px) {
    .is-hidden-from-lg {
      display: none !important; } }
  @media (min-width: 1280px) {
    .is-hidden-from-xl {
      display: none !important; } }
  @media (min-width: 1920px) {
    .is-hidden-from-xxl {
      display: none !important; } }

.xui-checkbox [type='checkbox'], .xui-radio [type='radio'], .xui-toggle [type='radio'],
.xui-toggle [type='checkbox'], .xui-tabs [type='radio'], .xui-accordion-item > [type='radio'],
.xui-accordion-item > [type='checkbox'], .is-hidden-input {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.align-center {
  text-align: center; }
  @media (min-width: 0px) {
    .align-center-xs {
      text-align: center; } }
  @media (min-width: 480px) {
    .align-center-sm {
      text-align: center; } }
  @media (min-width: 720px) {
    .align-center-md {
      text-align: center; } }
  @media (min-width: 1024px) {
    .align-center-nav {
      text-align: center; } }
  @media (min-width: 1024px) {
    .align-center-lg {
      text-align: center; } }
  @media (min-width: 1280px) {
    .align-center-xl {
      text-align: center; } }
  @media (min-width: 1920px) {
    .align-center-xxl {
      text-align: center; } }

.align-right {
  text-align: right; }
  @media (min-width: 0px) {
    .align-right-xs {
      text-align: right; } }
  @media (min-width: 480px) {
    .align-right-sm {
      text-align: right; } }
  @media (min-width: 720px) {
    .align-right-md {
      text-align: right; } }
  @media (min-width: 1024px) {
    .align-right-nav {
      text-align: right; } }
  @media (min-width: 1024px) {
    .align-right-lg {
      text-align: right; } }
  @media (min-width: 1280px) {
    .align-right-xl {
      text-align: right; } }
  @media (min-width: 1920px) {
    .align-right-xxl {
      text-align: right; } }

.align-left {
  text-align: left; }
  @media (min-width: 0px) {
    .align-left-xs {
      text-align: left; } }
  @media (min-width: 480px) {
    .align-left-sm {
      text-align: left; } }
  @media (min-width: 720px) {
    .align-left-md {
      text-align: left; } }
  @media (min-width: 1024px) {
    .align-left-nav {
      text-align: left; } }
  @media (min-width: 1024px) {
    .align-left-lg {
      text-align: left; } }
  @media (min-width: 1280px) {
    .align-left-xl {
      text-align: left; } }
  @media (min-width: 1920px) {
    .align-left-xxl {
      text-align: left; } }

.is-uppercase {
  text-transform: uppercase; }

.is-lowercase {
  text-transform: lowercase; }

.is-bold {
  font-weight: bolder; }

.is-medium {
  font-weight: bold; }

.is-normal {
  font-weight: normal; }

.is-light {
  font-weight: lighter; }

.is-nowrap {
  vertical-align: baseline;
  white-space: nowrap; }
  @media (min-width: 0px) {
    .is-nowrap-xs {
      vertical-align: baseline;
      white-space: nowrap; } }
  @media (min-width: 480px) {
    .is-nowrap-sm {
      vertical-align: baseline;
      white-space: nowrap; } }
  @media (min-width: 720px) {
    .is-nowrap-md {
      vertical-align: baseline;
      white-space: nowrap; } }
  @media (min-width: 1024px) {
    .is-nowrap-nav {
      vertical-align: baseline;
      white-space: nowrap; } }
  @media (min-width: 1024px) {
    .is-nowrap-lg {
      vertical-align: baseline;
      white-space: nowrap; } }
  @media (min-width: 1280px) {
    .is-nowrap-xl {
      vertical-align: baseline;
      white-space: nowrap; } }
  @media (min-width: 1920px) {
    .is-nowrap-xxl {
      vertical-align: baseline;
      white-space: nowrap; } }

.is-hyphen {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
  hyphens: auto; }
  @media (min-width: 0px) {
    .is-hyphen-xs {
      overflow-wrap: break-word;
      word-wrap: break-word;
      -ms-word-break: break-all;
      word-break: break-word;
      hyphens: auto; } }
  @media (min-width: 480px) {
    .is-hyphen-sm {
      overflow-wrap: break-word;
      word-wrap: break-word;
      -ms-word-break: break-all;
      word-break: break-word;
      hyphens: auto; } }
  @media (min-width: 720px) {
    .is-hyphen-md {
      overflow-wrap: break-word;
      word-wrap: break-word;
      -ms-word-break: break-all;
      word-break: break-word;
      hyphens: auto; } }
  @media (min-width: 1024px) {
    .is-hyphen-nav {
      overflow-wrap: break-word;
      word-wrap: break-word;
      -ms-word-break: break-all;
      word-break: break-word;
      hyphens: auto; } }
  @media (min-width: 1024px) {
    .is-hyphen-lg {
      overflow-wrap: break-word;
      word-wrap: break-word;
      -ms-word-break: break-all;
      word-break: break-word;
      hyphens: auto; } }
  @media (min-width: 1280px) {
    .is-hyphen-xl {
      overflow-wrap: break-word;
      word-wrap: break-word;
      -ms-word-break: break-all;
      word-break: break-word;
      hyphens: auto; } }
  @media (min-width: 1920px) {
    .is-hyphen-xxl {
      overflow-wrap: break-word;
      word-wrap: break-word;
      -ms-word-break: break-all;
      word-break: break-word;
      hyphens: auto; } }

.is-hyphen-block {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
  hyphens: auto;
  text-align: justify; }
  @media (min-width: 0px) {
    .is-hyphen-block-xs {
      overflow-wrap: break-word;
      word-wrap: break-word;
      -ms-word-break: break-all;
      word-break: break-word;
      hyphens: auto;
      text-align: justify; } }
  @media (min-width: 480px) {
    .is-hyphen-block-sm {
      overflow-wrap: break-word;
      word-wrap: break-word;
      -ms-word-break: break-all;
      word-break: break-word;
      hyphens: auto;
      text-align: justify; } }
  @media (min-width: 720px) {
    .is-hyphen-block-md {
      overflow-wrap: break-word;
      word-wrap: break-word;
      -ms-word-break: break-all;
      word-break: break-word;
      hyphens: auto;
      text-align: justify; } }
  @media (min-width: 1024px) {
    .is-hyphen-block-nav {
      overflow-wrap: break-word;
      word-wrap: break-word;
      -ms-word-break: break-all;
      word-break: break-word;
      hyphens: auto;
      text-align: justify; } }
  @media (min-width: 1024px) {
    .is-hyphen-block-lg {
      overflow-wrap: break-word;
      word-wrap: break-word;
      -ms-word-break: break-all;
      word-break: break-word;
      hyphens: auto;
      text-align: justify; } }
  @media (min-width: 1280px) {
    .is-hyphen-block-xl {
      overflow-wrap: break-word;
      word-wrap: break-word;
      -ms-word-break: break-all;
      word-break: break-word;
      hyphens: auto;
      text-align: justify; } }
  @media (min-width: 1920px) {
    .is-hyphen-block-xxl {
      overflow-wrap: break-word;
      word-wrap: break-word;
      -ms-word-break: break-all;
      word-break: break-word;
      hyphens: auto;
      text-align: justify; } }

.is-ellipsis {
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block; }
  @media (min-width: 0px) {
    .is-ellipsis-xs {
      -ms-text-overflow: ellipsis;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: block; } }
  @media (min-width: 480px) {
    .is-ellipsis-sm {
      -ms-text-overflow: ellipsis;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: block; } }
  @media (min-width: 720px) {
    .is-ellipsis-md {
      -ms-text-overflow: ellipsis;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: block; } }
  @media (min-width: 1024px) {
    .is-ellipsis-nav {
      -ms-text-overflow: ellipsis;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: block; } }
  @media (min-width: 1024px) {
    .is-ellipsis-lg {
      -ms-text-overflow: ellipsis;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: block; } }
  @media (min-width: 1280px) {
    .is-ellipsis-xl {
      -ms-text-overflow: ellipsis;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: block; } }
  @media (min-width: 1920px) {
    .is-ellipsis-xxl {
      -ms-text-overflow: ellipsis;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: block; } }

.is-ink-default {
  color: #2a3d54 !important; }

.is-ink-light {
  color: rgba(42, 61, 84, 0.6) !important; }

.is-ink-lighter {
  color: rgba(42, 61, 84, 0.4) !important; }

.is-ink-lightest {
  color: rgba(42, 61, 84, 0.2) !important; }

.is-ink-minor {
  color: rgba(47, 206, 111, 0.2) !important; }

.is-ink-link {
  color: #2fce6f !important; }

.is-ink-super {
  color: #14a656 !important; }

.is-ink-highlight {
  color: #fcff66 !important; }

.is-ink-invert {
  color: #fff !important; }

.is-ink-info {
  color: #22e7e7 !important; }

.is-ink-disabled {
  color: rgba(42, 61, 84, 0.2) !important; }

.is-ink-error {
  color: #e72222 !important; }

.is-ink-success {
  color: #22e722 !important; }

.is-ink-warning {
  color: #e7c822 !important; }

.is-theme {
  background-color: white; }
  .is-theme_darkest {
    background-color: #e6e6e6; }
  .is-theme_darker {
    background-color: #ebebeb; }
  .is-theme_dark {
    background-color: whitesmoke; }
  .is-theme_light {
    background-color: white; }
  .is-theme_lighter {
    background-color: white; }
  .is-theme_lightest {
    background-color: white; }

.is-theme-minor {
  background-color: #d5f5e2;
  color: #192432; }
  .is-theme-minor a:not(.btn):not(.tag) {
    color: #13512c; }
  .is-theme-minor_darkest {
    background-color: #a9efc3;
    color: #080c10; }
    .is-theme-minor_darkest a:not(.btn) {
      color: #092815; }
  .is-theme-minor_darker {
    background-color: #b2f0ca;
    color: #192432; }
    .is-theme-minor_darker a:not(.btn) {
      color: #092815; }
  .is-theme-minor_dark {
    background-color: #c4f2d6;
    color: #192432; }
    .is-theme-minor_dark a:not(.btn) {
      color: #13512c; }
  .is-theme-minor_light {
    background-color: #e6f9ee;
    color: #2a3d54; }
    .is-theme-minor_light a:not(.btn) {
      color: #13512c; }
  .is-theme-minor_lighter {
    background-color: #f7fdf9;
    color: #2a3d54; }
    .is-theme-minor_lighter a:not(.btn) {
      color: #1c7b42; }
  .is-theme-minor_lightest {
    background-color: white;
    color: #2a3d54; }
    .is-theme-minor_lightest a:not(.btn) {
      color: #1c7b42; }

.is-theme-link {
  background-color: #2fce6f;
  color: white; }
  .is-theme-link a:not(.btn):not(.tag) {
    color: white; }
  .is-theme-link_darkest {
    background-color: #1fab54;
    color: white; }
    .is-theme-link_darkest a:not(.btn) {
      color: white; }
  .is-theme-link_darker {
    background-color: #22b259;
    color: white; }
    .is-theme-link_darker a:not(.btn) {
      color: white; }
  .is-theme-link_dark {
    background-color: #28c064;
    color: white; }
    .is-theme-link_dark a:not(.btn) {
      color: white; }
  .is-theme-link_light {
    background-color: #41d07c;
    color: black; }
    .is-theme-link_light a:not(.btn) {
      color: black; }
  .is-theme-link_lighter {
    background-color: #55d189;
    color: black; }
    .is-theme-link_lighter a:not(.btn) {
      color: black; }
  .is-theme-link_lightest {
    background-color: #67d396;
    color: black; }
    .is-theme-link_lightest a:not(.btn) {
      color: black; }

.is-theme-super {
  background-color: #14a656;
  color: white; }
  .is-theme-super a:not(.btn):not(.tag) {
    color: white; }
  .is-theme-super_darkest {
    background-color: #0a7d3b;
    color: white; }
    .is-theme-super_darkest a:not(.btn) {
      color: #fdfffe; }
  .is-theme-super_darker {
    background-color: #0c8540;
    color: white; }
    .is-theme-super_darker a:not(.btn) {
      color: #fdfffe; }
  .is-theme-super_dark {
    background-color: #10964b;
    color: white; }
    .is-theme-super_dark a:not(.btn) {
      color: #fdfffe; }
  .is-theme-super_light {
    background-color: #19b561;
    color: white; }
    .is-theme-super_light a:not(.btn) {
      color: white; }
  .is-theme-super_lighter {
    background-color: #1ec46d;
    color: white; }
    .is-theme-super_lighter a:not(.btn) {
      color: white; }
  .is-theme-super_lightest {
    background-color: #24d378;
    color: white; }
    .is-theme-super_lightest a:not(.btn) {
      color: white; }

.is-theme-highlight {
  background-color: #fcff66;
  color: #080c10; }
  .is-theme-highlight a:not(.btn):not(.tag) {
    color: #092815; }
  .is-theme-highlight_darkest {
    background-color: #f6ff33;
    color: #080c10; }
    .is-theme-highlight_darkest a:not(.btn) {
      color: black; }
  .is-theme-highlight_darker {
    background-color: #f7ff3d;
    color: #080c10; }
    .is-theme-highlight_darker a:not(.btn) {
      color: black; }
  .is-theme-highlight_dark {
    background-color: #faff52;
    color: #080c10; }
    .is-theme-highlight_dark a:not(.btn) {
      color: #092815; }
  .is-theme-highlight_light {
    background-color: #fcfd7c;
    color: #192432; }
    .is-theme-highlight_light a:not(.btn) {
      color: #092815; }
  .is-theme-highlight_lighter {
    background-color: #fcfc92;
    color: #192432; }
    .is-theme-highlight_lighter a:not(.btn) {
      color: #13512c; }
  .is-theme-highlight_lightest {
    background-color: #fbfaa7;
    color: #192432; }
    .is-theme-highlight_lightest a:not(.btn) {
      color: #13512c; }

.is-theme-invert {
  background-color: #2a3d54;
  color: white; }
  .is-theme-invert a:not(.btn):not(.tag) {
    color: #57d98b; }
  .is-theme-invert_darkest {
    background-color: #172334;
    color: white; }
    .is-theme-invert_darkest a:not(.btn) {
      color: #2fce6f; }
  .is-theme-invert_darker {
    background-color: #1a283b;
    color: white; }
    .is-theme-invert_darker a:not(.btn) {
      color: #2fce6f; }
  .is-theme-invert_dark {
    background-color: #223348;
    color: white; }
    .is-theme-invert_dark a:not(.btn) {
      color: #57d98b; }
  .is-theme-invert_light {
    background-color: #334860;
    color: white; }
    .is-theme-invert_light a:not(.btn) {
      color: #81e2a8; }
  .is-theme-invert_lighter {
    background-color: #3c526b;
    color: white; }
    .is-theme-invert_lighter a:not(.btn) {
      color: #81e2a8; }
  .is-theme-invert_lightest {
    background-color: #465d75;
    color: white; }
    .is-theme-invert_lightest a:not(.btn) {
      color: #aaecc5; }

[data-animate] {
  animation-fill-mode: forwards;
  backface-visibility: hidden;
  opacity: 0;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1); }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

[data-animate~='fadeIn'] {
  animation-name: fadeIn; }

@keyframes popIn {
  0% {
    opacity: 0;
    transform: scale(0.6); }
  80% {
    transform: scale(1.2); }
  100% {
    opacity: 1;
    transform: initial; } }

[data-animate~='popIn'] {
  animation-name: popIn; }

@keyframes flipLeft {
  0% {
    opacity: 0;
    transform: rotateY(180deg); }
  100% {
    opacity: 1;
    transform: rotateY(0); } }

[data-animate~='flipLeft'] {
  animation-name: flipLeft; }

@keyframes flipRight {
  0% {
    opacity: 0;
    transform: rotateY(-180deg); }
  100% {
    opacity: 1;
    transform: rotateY(0); } }

[data-animate~='flipRight'] {
  animation-name: flipRight; }

@keyframes slideRight {
  0% {
    opacity: 0;
    transform: translateX(-100%); }
  100% {
    opacity: 1;
    transform: initial; } }

[data-animate~='slideRight'] {
  animation-name: slideRight; }

@keyframes slideLeft {
  0% {
    opacity: 0;
    transform: translateX(100%); }
  100% {
    opacity: 1;
    transform: initial; } }

[data-animate~='slideLeft'] {
  animation-name: slideLeft; }

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(100%); }
  100% {
    opacity: 1;
    transform: initial; } }

[data-animate~='slideUp'] {
  animation-name: slideUp; }

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-100%); }
  100% {
    opacity: 1;
    transform: initial; } }

[data-animate~='slideDown'] {
  animation-name: slideDown; }

[data-animate~='+1'] {
  animation-delay: 0.1s; }

[data-animate~='+2'] {
  animation-delay: 0.2s; }

[data-animate~='+3'] {
  animation-delay: 0.3s; }

[data-animate~='+4'] {
  animation-delay: 0.4s; }

[data-animate~='+5'] {
  animation-delay: 0.5s; }

[data-animate~="1"] {
  animation-duration: 0.1s; }

[data-animate~="2"] {
  animation-duration: 0.2s; }

[data-animate~="3"] {
  animation-duration: 0.3s; }

[data-animate~="4"] {
  animation-duration: 0.4s; }

[data-animate~="5"] {
  animation-duration: 0.5s; }

.has-transition {
  transition: all .4s; }

:root {
  box-sizing: border-box;
  cursor: default;
  font-size: 16px;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow-y: scroll;
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
  vertical-align: top; }

input[type='number'] {
  max-width: 100%;
  width: auto; }

input[type='search'] {
  -webkit-appearance: textfield; }
  input[type='search']::-webkit-search-cancel-button, input[type='search']::-webkit-search-decoration {
    -webkit-appearance: none; }

[hidden] {
  display: none; }

[unselectable] {
  user-select: none; }

*,
::after,
::before {
  box-sizing: border-box;
  /* color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  text-decoration: inherit;
  vertical-align: inherit; */ }

* {
  box-sizing: border-box;
  outline: none; }

html,
body {
  height: 100%;
  margin: 0;
  padding: 0; }

h1,
h2,
h3,
h4,
h5,
h6,
.is-h1,
.is-h2,
.is-h3,
.is-h4,
.is-h5,
.is-h6,
.is-giga,
.is-mega,
.is-kilo {
  font-family: sans-serif;
  font-weight: bolder;
  line-height: 1.4; }
  h1 small,
  h2 small,
  h3 small,
  h4 small,
  h5 small,
  h6 small,
  .is-h1 small,
  .is-h2 small,
  .is-h3 small,
  .is-h4 small,
  .is-h5 small,
  .is-h6 small,
  .is-giga small,
  .is-mega small,
  .is-kilo small {
    font-size: 0.5em;
    vertical-align: baseline; }
  h1 a,
  h2 a,
  h3 a,
  h4 a,
  h5 a,
  h6 a,
  .is-h1 a,
  .is-h2 a,
  .is-h3 a,
  .is-h4 a,
  .is-h5 a,
  .is-h6 a,
  .is-giga a,
  .is-mega a,
  .is-kilo a {
    font-family: inherit;
    font-weight: inherit;
    line-height: inherit; }

.is-giga {
  margin: 0; }
  @media (min-width: 0px) {
    .is-giga {
      font-size: 1.75em; } }
  @media (min-width: 720px) {
    .is-giga {
      font-size: 2em; } }
  @media (min-width: 1920px) {
    .is-giga {
      font-size: 2.5em; } }

.is-mega {
  margin: 0; }
  @media (min-width: 0px) {
    .is-mega {
      font-size: 1.3125em; } }
  @media (min-width: 720px) {
    .is-mega {
      font-size: 1.5em; } }
  @media (min-width: 1920px) {
    .is-mega {
      font-size: 1.875em; } }

.is-kilo {
  margin: 0; }
  @media (min-width: 0px) {
    .is-kilo {
      font-size: 0.875em; } }
  @media (min-width: 720px) {
    .is-kilo {
      font-size: 1em; } }
  @media (min-width: 1920px) {
    .is-kilo {
      font-size: 1.25em; } }

h1,
.is-h1 {
  margin: 0 0 2.75rem 0; }
  @media (min-width: 0px) {
    h1,
    .is-h1 {
      font-size: 2.6705em; } }
  @media (min-width: 720px) {
    h1,
    .is-h1 {
      font-size: 3.052em; } }
  @media (min-width: 1920px) {
    h1,
    .is-h1 {
      font-size: 3.815em; } }

h2,
.is-h2 {
  margin: 0 0 1.375rem 0; }
  @media (min-width: 0px) {
    h2,
    .is-h2 {
      font-size: 2.13587em; } }
  @media (min-width: 720px) {
    h2,
    .is-h2 {
      font-size: 2.441em; } }
  @media (min-width: 1920px) {
    h2,
    .is-h2 {
      font-size: 3.05125em; } }

h3,
.is-h3 {
  margin: 0 0 1.375rem 0; }
  @media (min-width: 0px) {
    h3,
    .is-h3 {
      font-size: 1.70888em; } }
  @media (min-width: 720px) {
    h3,
    .is-h3 {
      font-size: 1.953em; } }
  @media (min-width: 1920px) {
    h3,
    .is-h3 {
      font-size: 2.44125em; } }

h4,
.is-h4 {
  margin: 0 0 1.375rem 0; }
  @media (min-width: 0px) {
    h4,
    .is-h4 {
      font-size: 1.36762em; } }
  @media (min-width: 720px) {
    h4,
    .is-h4 {
      font-size: 1.563em; } }
  @media (min-width: 1920px) {
    h4,
    .is-h4 {
      font-size: 1.95375em; } }

h5,
.is-h5 {
  margin: 0 0 0.6875rem 0; }
  @media (min-width: 0px) {
    h5,
    .is-h5 {
      font-size: 1.09375em; } }
  @media (min-width: 720px) {
    h5,
    .is-h5 {
      font-size: 1.25em; } }
  @media (min-width: 1920px) {
    h5,
    .is-h5 {
      font-size: 1.5625em; } }

h6,
.is-h6 {
  margin: 0 0 0.6875rem 0; }
  @media (min-width: 0px) {
    h6,
    .is-h6 {
      font-size: 0.875em; } }
  @media (min-width: 720px) {
    h6,
    .is-h6 {
      font-size: 1em; } }
  @media (min-width: 1920px) {
    h6,
    .is-h6 {
      font-size: 1.25em; } }

h1 .tag,
h2 .tag,
h3 .tag,
h4 .tag,
h5 .tag,
h6 .tag {
  font-size: 0.875rem;
  vertical-align: middle; }
  h1 .tag.tag_small,
  h2 .tag.tag_small,
  h3 .tag.tag_small,
  h4 .tag.tag_small,
  h5 .tag.tag_small,
  h6 .tag.tag_small {
    font-size: 0.76562rem; }
  h1 .tag.tag_big,
  h2 .tag.tag_big,
  h3 .tag.tag_big,
  h4 .tag.tag_big,
  h5 .tag.tag_big,
  h6 .tag.tag_big {
    font-size: 0.98438rem; }

h1 .bubble,
h2 .bubble,
h3 .bubble,
h4 .bubble,
h5 .bubble,
h6 .bubble {
  font-size: 0.875rem;
  vertical-align: middle; }
  h1 .bubble.bubble_small,
  h2 .bubble.bubble_small,
  h3 .bubble.bubble_small,
  h4 .bubble.bubble_small,
  h5 .bubble.bubble_small,
  h6 .bubble.bubble_small {
    font-size: 0.625rem; }
  h1 .bubble.bubble_big,
  h2 .bubble.bubble_big,
  h3 .bubble.bubble_big,
  h4 .bubble.bubble_big,
  h5 .bubble.bubble_big,
  h6 .bubble.bubble_big {
    font-size: 1rem; }

body {
  color: #2a3d54;
  background: #fff;
  font-family: sans-serif;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.4; }

a {
  cursor: pointer;
  outline: none;
  transition: color 0.2s;
  color: #2fce6f;
  font-weight: normal;
  text-decoration: none; }
  a * {
    cursor: pointer; }
  a:hover {
    color: #28c064;
    text-decoration: none; }

p {
  line-height: 1.4;
  font-weight: normal;
  margin: 0 0 1.375rem 0; }
  @media (min-width: 0px) {
    p {
      font-size: 0.875em; } }
  @media (min-width: 720px) {
    p {
      font-size: 1em; } }
  @media (min-width: 1920px) {
    p {
      font-size: 1.25em; } }
  p strong,
  p small {
    vertical-align: baseline; }

b,
strong {
  font-weight: bolder; }

small {
  vertical-align: baseline;
  font-size: 0.875em; }

code {
  font-family: monospace; }

.is-lead {
  font-weight: lighter;
  line-height: 1.4;
  margin: 0 0 1.375rem 0; }
  @media (min-width: 0px) {
    .is-lead {
      font-size: 1.09375em; } }
  @media (min-width: 720px) {
    .is-lead {
      font-size: 1.25em; } }
  @media (min-width: 1920px) {
    .is-lead {
      font-size: 1.5625em; } }

.is-unstyled {
  list-style: none;
  margin: 0;
  padding: 0; }

.is-inline {
  list-style: none;
  margin: 0;
  padding: 0; }
  .is-inline > li {
    display: inline-block; }

mark {
  background: #fcff66;
  color: #080c10; }

::selection {
  background: #fcff66;
  color: #080c10; }

::-moz-selection {
  background: #fcff66;
  color: #080c10; }

.img_responsive {
  height: auto;
  max-width: 100%; }

.img_full {
  height: auto;
  width: 100%; }

.avatar {
  display: inline-block;
  overflow: hidden;
  border: 0;
  border-radius: 50%;
  height: 2.5rem;
  width: 2.5rem; }
  @supports (display: inline-flex) {
    .avatar {
      display: inline-flex;
      flex-shrink: 0; } }
  @media (min-width: 0px) {
    .avatar-xs {
      height: 2.5rem;
      width: 2.5rem; } }
  @media (min-width: 480px) {
    .avatar-sm {
      height: 2.5rem;
      width: 2.5rem; } }
  @media (min-width: 720px) {
    .avatar-md {
      height: 2.5rem;
      width: 2.5rem; } }
  @media (min-width: 1024px) {
    .avatar-nav {
      height: 2.5rem;
      width: 2.5rem; } }
  @media (min-width: 1024px) {
    .avatar-lg {
      height: 2.5rem;
      width: 2.5rem; } }
  @media (min-width: 1280px) {
    .avatar-xl {
      height: 2.5rem;
      width: 2.5rem; } }
  @media (min-width: 1920px) {
    .avatar-xxl {
      height: 2.5rem;
      width: 2.5rem; } }
  .avatar img {
    height: 100%;
    object-fit: cover;
    object-position: center;
    width: 100%; }
  .avatar_square {
    border-radius: 0.25rem; }
  .avatar_round {
    border-radius: 50%; }
  .avatar_small {
    height: 2rem;
    width: 2rem; }
    @media (min-width: 0px) {
      .avatar_small-xs {
        height: 2rem;
        width: 2rem; } }
    @media (min-width: 480px) {
      .avatar_small-sm {
        height: 2rem;
        width: 2rem; } }
    @media (min-width: 720px) {
      .avatar_small-md {
        height: 2rem;
        width: 2rem; } }
    @media (min-width: 1024px) {
      .avatar_small-nav {
        height: 2rem;
        width: 2rem; } }
    @media (min-width: 1024px) {
      .avatar_small-lg {
        height: 2rem;
        width: 2rem; } }
    @media (min-width: 1280px) {
      .avatar_small-xl {
        height: 2rem;
        width: 2rem; } }
    @media (min-width: 1920px) {
      .avatar_small-xxl {
        height: 2rem;
        width: 2rem; } }
  .avatar_big {
    height: 3rem;
    width: 3rem; }
    @media (min-width: 0px) {
      .avatar_big-xs {
        height: 3rem;
        width: 3rem; } }
    @media (min-width: 480px) {
      .avatar_big-sm {
        height: 3rem;
        width: 3rem; } }
    @media (min-width: 720px) {
      .avatar_big-md {
        height: 3rem;
        width: 3rem; } }
    @media (min-width: 1024px) {
      .avatar_big-nav {
        height: 3rem;
        width: 3rem; } }
    @media (min-width: 1024px) {
      .avatar_big-lg {
        height: 3rem;
        width: 3rem; } }
    @media (min-width: 1280px) {
      .avatar_big-xl {
        height: 3rem;
        width: 3rem; } }
    @media (min-width: 1920px) {
      .avatar_big-xxl {
        height: 3rem;
        width: 3rem; } }

.img-crop-1-1 {
  padding-top: 100%;
  position: relative;
  width: 100%; }
  .img-crop-1-1 img {
    height: 100%;
    left: 0;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    width: 100%;
    vertical-align: top; }

.img-crop-3-4 {
  padding-top: 133.33333%;
  position: relative;
  width: 100%; }
  .img-crop-3-4 img {
    height: 100%;
    left: 0;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    width: 100%;
    vertical-align: top; }

.img-crop-4-3 {
  padding-top: 75%;
  position: relative;
  width: 100%; }
  .img-crop-4-3 img {
    height: 100%;
    left: 0;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    width: 100%;
    vertical-align: top; }

.img-crop-16-9 {
  padding-top: 56.25%;
  position: relative;
  width: 100%; }
  .img-crop-16-9 img {
    height: 100%;
    left: 0;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    width: 100%;
    vertical-align: top; }

.img-crop-golden {
  padding-top: 61.8047%;
  position: relative;
  width: 100%; }
  .img-crop-golden img {
    height: 100%;
    left: 0;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    width: 100%;
    vertical-align: top; }

.img-crop-silver {
  padding-top: 41.42502%;
  position: relative;
  width: 100%; }
  .img-crop-silver img {
    height: 100%;
    left: 0;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    width: 100%;
    vertical-align: top; }

.img-crop-ultrawide {
  padding-top: 27.77778%;
  position: relative;
  width: 100%; }
  .img-crop-ultrawide img {
    height: 100%;
    left: 0;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    width: 100%;
    vertical-align: top; }

fieldset {
  padding: 0;
  margin: 0;
  background: #fff;
  border: 0; }

.input {
  display: block;
  flex-grow: 1;
  outline: none;
  text-align: left;
  width: 100%;
  padding: 0.2375rem 0.475rem;
  font-size: 1rem;
  line-height: 1.4rem;
  min-height: 2rem;
  min-width: 2rem;
  border-style: solid;
  border-width: 0.0625rem;
  background: #fff;
  border-radius: 0.25rem;
  border-color: #cacfd4;
  border-style: solid;
  margin: 0;
  color: #2a3d54; }
  .input .bubble:first-child,
  .input .tag:first-child {
    margin-left: -0.2375rem;
    margin-right: 0.2375rem; }
  .input .bubble:last-child,
  .input .tag:last-child {
    margin-left: 0.2375rem;
    margin-right: -0.2375rem; }
  .input .bubble:only-child,
  .input .tag:only-child {
    margin-left: -0.475rem;
    margin-right: -0.475rem; }
  .input .ec-icon {
    padding-right: 0.2375rem; }
    @supports (display: inline-flex) {
      .input .ec-icon {
        display: inline-flex;
        align-items: center;
        justify-content: center; } }
    .input .ec-icon::before {
      font-size: 1em;
      line-height: inherit;
      vertical-align: top; }
    .input .ec-icon_right {
      padding-left: 0.2375rem;
      padding-right: 0; }
    .input .ec-icon:empty {
      padding-left: 0;
      padding-right: 0; }
      .input .ec-icon:empty:first-child {
        margin-left: -0.11875rem;
        margin-right: 0.2375rem; }
      .input .ec-icon:empty:last-child {
        margin-left: 0.2375rem;
        margin-right: -0.11875rem; }
      .input .ec-icon:empty:only-child {
        margin-left: -0.475rem;
        margin-right: -0.475rem; }
      .input .ec-icon:empty::before {
        margin-left: 0;
        margin-right: 0; }
  .input::-webkit-input-placeholder {
    color: rgba(42, 61, 84, 0.6); }
  .input::-moz-placeholder {
    color: rgba(42, 61, 84, 0.6); }
  .input::-moz-placeholder {
    color: rgba(42, 61, 84, 0.6); }
  .input:-ms-input-placeholder {
    color: rgba(42, 61, 84, 0.6); }

.input:hover {
  box-shadow: inset 0 0 0 2px rgba(47, 206, 111, 0.1); }
  .input:hover::-webkit-input-placeholder {
    color: #2a3d54; }
  .input:hover::-moz-placeholder {
    color: #2a3d54; }
  .input:hover::-moz-placeholder {
    color: #2a3d54; }
  .input:hover:-ms-input-placeholder {
    color: #2a3d54; }

.input:focus {
  outline: none;
  box-shadow: inset 0 0 0 2px rgba(47, 206, 111, 0.1); }
  .input:focus::-webkit-input-placeholder {
    color: #2a3d54; }
  .input:focus::-moz-placeholder {
    color: #2a3d54; }
  .input:focus::-moz-placeholder {
    color: #2a3d54; }
  .input:focus:-ms-input-placeholder {
    color: #2a3d54; }

.input.is-disabled, .input[disabled] {
  cursor: not-allowed;
  opacity: 0.4; }

select.input {
  height: 2em; }
  select.input[multiple] {
    height: auto; }
  select.input option {
    padding: 0 0.5rem; }

textarea.input {
  height: auto;
  resize: vertical; }

.input_clear {
  padding: 0.3rem 0.6rem;
  font-size: 1rem;
  line-height: 1.4rem;
  min-height: 2rem;
  min-width: 2rem;
  border: 0; }
  .input_clear .bubble:first-child,
  .input_clear .tag:first-child {
    margin-left: -0.3rem;
    margin-right: 0.3rem; }
  .input_clear .bubble:last-child,
  .input_clear .tag:last-child {
    margin-left: 0.3rem;
    margin-right: -0.3rem; }
  .input_clear .bubble:only-child,
  .input_clear .tag:only-child {
    margin-left: -0.6rem;
    margin-right: -0.6rem; }
  .input_clear .ec-icon {
    padding-right: 0.3rem; }
    @supports (display: inline-flex) {
      .input_clear .ec-icon {
        display: inline-flex;
        align-items: center;
        justify-content: center; } }
    .input_clear .ec-icon::before {
      font-size: 1em;
      line-height: inherit;
      vertical-align: top; }
    .input_clear .ec-icon_right {
      padding-left: 0.3rem;
      padding-right: 0; }
    .input_clear .ec-icon:empty {
      padding-left: 0;
      padding-right: 0; }
      .input_clear .ec-icon:empty:first-child {
        margin-left: -0.15rem;
        margin-right: 0.3rem; }
      .input_clear .ec-icon:empty:last-child {
        margin-left: 0.3rem;
        margin-right: -0.15rem; }
      .input_clear .ec-icon:empty:only-child {
        margin-left: -0.6rem;
        margin-right: -0.6rem; }
      .input_clear .ec-icon:empty::before {
        margin-left: 0;
        margin-right: 0; }
  .input_clear:focus, .input_clear:hover {
    box-shadow: none; }

.input:-webkit-autofill, .input:-webkit-autofill:hover, .input:-webkit-autofill:focus, .input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  color: #2a3d54;
  -webkit-text-fill-color: #2a3d54; }

.input_small {
  padding: 0.2rem 0.4rem;
  font-size: 0.875rem;
  line-height: 1.225rem;
  min-height: 1.75rem;
  min-width: 1.75rem;
  border-style: solid;
  border-width: 0.0625rem;
  border-radius: 0.25rem; }
  .input_small .bubble:first-child,
  .input_small .tag:first-child {
    margin-left: -0.2rem;
    margin-right: 0.2rem; }
  .input_small .bubble:last-child,
  .input_small .tag:last-child {
    margin-left: 0.2rem;
    margin-right: -0.2rem; }
  .input_small .bubble:only-child,
  .input_small .tag:only-child {
    margin-left: -0.4rem;
    margin-right: -0.4rem; }
  .input_small .ec-icon {
    padding-right: 0.2rem; }
    @supports (display: inline-flex) {
      .input_small .ec-icon {
        display: inline-flex;
        align-items: center;
        justify-content: center; } }
    .input_small .ec-icon::before {
      font-size: 1.14286em;
      line-height: inherit;
      vertical-align: top; }
    .input_small .ec-icon_right {
      padding-left: 0.2rem;
      padding-right: 0; }
    .input_small .ec-icon:empty {
      padding-left: 0;
      padding-right: 0; }
      .input_small .ec-icon:empty:first-child {
        margin-left: -0.1rem;
        margin-right: 0.2rem; }
      .input_small .ec-icon:empty:last-child {
        margin-left: 0.2rem;
        margin-right: -0.1rem; }
      .input_small .ec-icon:empty:only-child {
        margin-left: -0.4rem;
        margin-right: -0.4rem; }
      .input_small .ec-icon:empty::before {
        margin-left: 0;
        margin-right: 0; }
  select.input_small {
    height: 2em; }
  .input_small.input_clear {
    padding: 0.2625rem 0.525rem;
    font-size: 0.875rem;
    line-height: 1.225rem;
    min-height: 1.75rem;
    min-width: 1.75rem;
    border: 0; }
    .input_small.input_clear .bubble:first-child,
    .input_small.input_clear .tag:first-child {
      margin-left: -0.2625rem;
      margin-right: 0.2625rem; }
    .input_small.input_clear .bubble:last-child,
    .input_small.input_clear .tag:last-child {
      margin-left: 0.2625rem;
      margin-right: -0.2625rem; }
    .input_small.input_clear .bubble:only-child,
    .input_small.input_clear .tag:only-child {
      margin-left: -0.525rem;
      margin-right: -0.525rem; }
    .input_small.input_clear .ec-icon {
      padding-right: 0.2625rem; }
      @supports (display: inline-flex) {
        .input_small.input_clear .ec-icon {
          display: inline-flex;
          align-items: center;
          justify-content: center; } }
      .input_small.input_clear .ec-icon::before {
        font-size: 1.14286em;
        line-height: inherit;
        vertical-align: top; }
      .input_small.input_clear .ec-icon_right {
        padding-left: 0.2625rem;
        padding-right: 0; }
      .input_small.input_clear .ec-icon:empty {
        padding-left: 0;
        padding-right: 0; }
        .input_small.input_clear .ec-icon:empty:first-child {
          margin-left: -0.13125rem;
          margin-right: 0.2625rem; }
        .input_small.input_clear .ec-icon:empty:last-child {
          margin-left: 0.2625rem;
          margin-right: -0.13125rem; }
        .input_small.input_clear .ec-icon:empty:only-child {
          margin-left: -0.525rem;
          margin-right: -0.525rem; }
        .input_small.input_clear .ec-icon:empty::before {
          margin-left: 0;
          margin-right: 0; }

.input_big {
  padding: 0.4rem 0.8rem;
  font-size: 1.125rem;
  line-height: 1.575rem;
  min-height: 2.5rem;
  min-width: 2.5rem;
  border-style: solid;
  border-width: 0.0625rem;
  border-radius: 0.25rem; }
  .input_big .bubble:first-child,
  .input_big .tag:first-child {
    margin-left: -0.4rem;
    margin-right: 0.4rem; }
  .input_big .bubble:last-child,
  .input_big .tag:last-child {
    margin-left: 0.4rem;
    margin-right: -0.4rem; }
  .input_big .bubble:only-child,
  .input_big .tag:only-child {
    margin-left: -0.8rem;
    margin-right: -0.8rem; }
  .input_big .ec-icon {
    padding-right: 0.4rem; }
    @supports (display: inline-flex) {
      .input_big .ec-icon {
        display: inline-flex;
        align-items: center;
        justify-content: center; } }
    .input_big .ec-icon::before {
      font-size: 0.88889em;
      line-height: inherit;
      vertical-align: top; }
    .input_big .ec-icon_right {
      padding-left: 0.4rem;
      padding-right: 0; }
    .input_big .ec-icon:empty {
      padding-left: 0;
      padding-right: 0; }
      .input_big .ec-icon:empty:first-child {
        margin-left: -0.2rem;
        margin-right: 0.4rem; }
      .input_big .ec-icon:empty:last-child {
        margin-left: 0.4rem;
        margin-right: -0.2rem; }
      .input_big .ec-icon:empty:only-child {
        margin-left: -0.8rem;
        margin-right: -0.8rem; }
      .input_big .ec-icon:empty::before {
        margin-left: 0;
        margin-right: 0; }
  select.input_small {
    height: 2.22222em; }
  .input_big.input_clear {
    padding: 0.4625rem 0.925rem;
    font-size: 1.125rem;
    line-height: 1.575rem;
    min-height: 2.5rem;
    min-width: 2.5rem;
    border: 0; }
    .input_big.input_clear .bubble:first-child,
    .input_big.input_clear .tag:first-child {
      margin-left: -0.4625rem;
      margin-right: 0.4625rem; }
    .input_big.input_clear .bubble:last-child,
    .input_big.input_clear .tag:last-child {
      margin-left: 0.4625rem;
      margin-right: -0.4625rem; }
    .input_big.input_clear .bubble:only-child,
    .input_big.input_clear .tag:only-child {
      margin-left: -0.925rem;
      margin-right: -0.925rem; }
    .input_big.input_clear .ec-icon {
      padding-right: 0.4625rem; }
      @supports (display: inline-flex) {
        .input_big.input_clear .ec-icon {
          display: inline-flex;
          align-items: center;
          justify-content: center; } }
      .input_big.input_clear .ec-icon::before {
        font-size: 0.88889em;
        line-height: inherit;
        vertical-align: top; }
      .input_big.input_clear .ec-icon_right {
        padding-left: 0.4625rem;
        padding-right: 0; }
      .input_big.input_clear .ec-icon:empty {
        padding-left: 0;
        padding-right: 0; }
        .input_big.input_clear .ec-icon:empty:first-child {
          margin-left: -0.23125rem;
          margin-right: 0.4625rem; }
        .input_big.input_clear .ec-icon:empty:last-child {
          margin-left: 0.4625rem;
          margin-right: -0.23125rem; }
        .input_big.input_clear .ec-icon:empty:only-child {
          margin-left: -0.925rem;
          margin-right: -0.925rem; }
        .input_big.input_clear .ec-icon:empty::before {
          margin-left: 0;
          margin-right: 0; }

[type='checkbox'],
[type='file'],
[type='image'],
[type='radio'] {
  display: inline-block;
  height: auto;
  width: auto; }

[type='checkbox'],
[type='radio'] {
  line-height: normal;
  padding: 0;
  vertical-align: middle; }
  [type='checkbox'] + small,
  [type='radio'] + small {
    cursor: pointer;
    display: inline-block; }

[type='range'].slider {
  appearance: none;
  width: 100%;
  outline: none;
  margin: 0;
  height: 0.5rem;
  background: rgba(0, 0, 0, 0.04);
  border: 0;
  border-radius: 0.25rem; }

[type='range'].slider::-webkit-slider-runnable-track {
  appearance: none;
  width: 100%;
  outline: none;
  margin: 0;
  height: 0.5rem;
  background: rgba(0, 0, 0, 0.04);
  border: 0;
  border-radius: 0.25rem; }

[type='range'].slider::-moz-range-track {
  appearance: none;
  width: 100%;
  outline: none;
  margin: 0;
  height: 0.5rem;
  background: rgba(0, 0, 0, 0.04);
  border: 0;
  border-radius: 0.25rem; }

[type='range'].slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  border: 0;
  box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.2);
  width: 1rem;
  height: 1rem;
  background: #2fce6f;
  border-radius: 0.5rem;
  margin-top: -0.25rem; }
  [type='range'].slider::-webkit-slider-thumb:hover {
    box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.2); }

[type='range'].slider::-moz-range-thumb {
  cursor: pointer;
  border: 0;
  box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.2);
  width: 1rem;
  height: 1rem;
  background: #2fce6f;
  border-radius: 0.5rem; }
  [type='range'].slider::-moz-range-thumb:hover {
    box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.2); }

.file-upload {
  cursor: pointer;
  display: block;
  position: relative;
  font-size: 1rem;
  text-align: center;
  background: none;
  padding: 1rem;
  margin: 0 0 1rem;
  border-radius: 0.25rem;
  border-width: 0.125rem;
  border-color: rgba(47, 206, 111, 0.2);
  border-style: dashed; }
  @supports (display: flex) {
    .file-upload {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; } }
  .file-upload [type='file'] {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    opacity: 0; }

.input-group {
  align-items: stretch;
  display: flex;
  position: relative;
  margin: 0; }
  .input-group .input-group__addon {
    display: flex;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: center;
    width: auto;
    color: rgba(42, 61, 84, 0.6);
    background: #f7f7f7;
    border-width: 0.0625rem;
    border-color: #cacfd4;
    border-style: solid;
    border-radius: 0.25rem;
    padding: 0 0.5rem;
    min-width: 2rem; }
  .input-group .input-group__addon:not(:only-child):first-child,
  .input-group > .btn:not(:only-child):first-child,
  .input-group > .input:not(:only-child):first-child {
    border-bottom-right-radius: 0;
    border-right: 0;
    border-top-right-radius: 0; }
  .input-group .input-group__addon:not(:only-child):last-child,
  .input-group > .btn:not(:only-child):last-child,
  .input-group > .input:not(:only-child):last-child {
    border-bottom-left-radius: 0;
    border-left: 0;
    border-top-left-radius: 0; }
  .input-group .input-group__addon:not(:last-child):not(:first-child),
  .input-group > .btn:not(:last-child):not(:first-child),
  .input-group > .input:not(:last-child):not(:first-child) {
    border-left: 0;
    border-radius: 0;
    border-right: 0; }
  .input-group > .btn {
    align-items: center;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: center; }
    .input-group > .btn:active {
      transform: initial; }
    .input-group > .btn:first-child.btn_outlined {
      border-right-style: solid;
      border-right-width: 0.125rem; }
    .input-group > .btn.btn_clear + .input,
    .input-group > .btn.btn_clear + .input-group__addon {
      border-bottom-left-radius: 0.25rem;
      border-left: 0.0625rem solid #cacfd4;
      border-top-left-radius: 0.25rem;
      margin-left: 0.25rem; }
  .input-group .input-group__addon + .btn.btn_clear::before {
    border-width: 0.0625rem;
    border-style: solid;
    border-color: #cacfd4;
    background: #f7f7f7; }
  .input-group .input {
    margin: 0; }
    .input-group .input + .btn.btn_clear::before {
      border-width: 0.0625rem;
      border-style: solid;
      border-color: #cacfd4;
      background: #fff; }
  .input-group .input + .btn.btn_clear,
  .input-group .input-group__addon + .btn.btn_clear {
    margin-left: 0.5rem; }
    .input-group .input + .btn.btn_clear::before,
    .input-group .input-group__addon + .btn.btn_clear::before {
      content: '';
      position: absolute;
      top: 0;
      border-left: 0;
      height: 100%;
      border-radius: 0 0.25rem 0.25rem 0;
      left: -0.5rem;
      width: 0.25rem; }
  .input-group .input + .btn.btn_outlined,
  .input-group .input-group__addon + .btn.btn_outlined {
    border-left-style: solid;
    border-left-width: 0.125rem; }

.input_round {
  border-radius: 1rem; }
  .input_round.input_small {
    border-radius: 0.875rem; }
  .input_round.input_big {
    border-radius: 1.25rem; }

.input.ng-touched.ng-valid {
  border-color: #22e722;
  color: #15c115; }

.input.ng-touched.ng-invalid {
  border-color: #e72222;
  color: #c11515; }

.xui-select {
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-style: solid;
  flex-grow: 1;
  outline: none;
  text-align: left;
  position: relative;
  padding: 0.2375rem 0.475rem;
  font-size: 1rem;
  line-height: 1.4rem;
  min-height: 2rem;
  min-width: 2rem;
  border-style: solid;
  border-width: 0.0625rem;
  background: #fff;
  border-color: #cacfd4;
  color: #2a3d54;
  margin: 0;
  border-radius: 0.25rem; }
  .xui-select .bubble:first-child,
  .xui-select .tag:first-child {
    margin-left: -0.2375rem;
    margin-right: 0.2375rem; }
  .xui-select .bubble:last-child,
  .xui-select .tag:last-child {
    margin-left: 0.2375rem;
    margin-right: -0.2375rem; }
  .xui-select .bubble:only-child,
  .xui-select .tag:only-child {
    margin-left: -0.475rem;
    margin-right: -0.475rem; }
  .xui-select .ec-icon {
    padding-right: 0.2375rem; }
    @supports (display: inline-flex) {
      .xui-select .ec-icon {
        display: inline-flex;
        align-items: center;
        justify-content: center; } }
    .xui-select .ec-icon::before {
      font-size: 1em;
      line-height: inherit;
      vertical-align: top; }
    .xui-select .ec-icon_right {
      padding-left: 0.2375rem;
      padding-right: 0; }
    .xui-select .ec-icon:empty {
      padding-left: 0;
      padding-right: 0; }
      .xui-select .ec-icon:empty:first-child {
        margin-left: -0.11875rem;
        margin-right: 0.2375rem; }
      .xui-select .ec-icon:empty:last-child {
        margin-left: 0.2375rem;
        margin-right: -0.11875rem; }
      .xui-select .ec-icon:empty:only-child {
        margin-left: -0.475rem;
        margin-right: -0.475rem; }
      .xui-select .ec-icon:empty::before {
        margin-left: 0;
        margin-right: 0; }
  .xui-select::after {
    content: '';
    border: 4px solid transparent;
    border-top-color: rgba(42, 61, 84, 0.2);
    flex-grow: 0;
    flex-shrink: 0;
    margin-top: 0.25rem;
    transition: border-top-color 0.2s;
    z-index: 1; }
  .xui-select:hover {
    box-shadow: 0 0 0 2px rgba(47, 206, 111, 0.1); }
    .xui-select:hover::after {
      border-top-color: rgba(42, 61, 84, 0.4); }
    .xui-select:hover .xui-select__placeholder {
      color: #2a3d54; }
  .xui-select:focus::after {
    margin-bottom: 0.25rem;
    margin-top: 0;
    transform: rotate(180deg);
    z-index: 11; }
  .xui-select:focus .xui-select-options {
    clip-path: initial;
    opacity: 1;
    visibility: visible; }
  .xui-select .xui-select__placeholder {
    flex-grow: 1;
    flex-shrink: 0;
    user-select: none;
    color: rgba(42, 61, 84, 0.6); }
  .xui-select.xui-select_top .xui-select-options {
    bottom: 100%;
    top: auto; }

.xui-select-options {
  width: 100%;
  opacity: 0;
  list-style: none;
  position: absolute;
  left: 0;
  top: 100%;
  margin: 0;
  transition: visibility 0.5s, opacity 0.2s;
  visibility: hidden;
  z-index: 10;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  background: #fff;
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem -0.25rem rgba(0, 0, 0, 0.16) , 0 0.25rem 1.5rem 0 rgba(0, 0, 0, 0.1);
  max-height: 15rem;
  padding: 0.25rem; }

.xui-select-option {
  outline: none;
  padding: 0.125rem 0; }
  .xui-select-option a:not(.btn) {
    display: block;
    outline: none;
    transition: background-color 0.4s, color 0.4s;
    background: none;
    color: #2a3d54;
    border-radius: 0.25rem;
    padding: 0.25rem;
    font-size: 1em; }
    .xui-select-option a:not(.btn):hover {
      background: rgba(47, 206, 111, 0.1);
      color: inherit; }
  .xui-select-option.is-selected a:not(.btn) {
    background: rgba(47, 206, 111, 0.1);
    color: inherit; }

.xui-checkbox label {
  cursor: pointer;
  display: flex;
  align-items: flex-start; }

.xui-checkbox [type='checkbox'] + label::before {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
  text-align: center;
  overflow: hidden;
  transition: all 0.2s;
  box-shadow: inset 0 1px 2px 1px rgba(0, 0, 0, 0.2);
  content: "✔︎";
  font-family: sans-serif;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  height: 1.25rem;
  line-height: 2.75rem;
  margin: 0 0.5rem 0 0;
  width: 1.25rem;
  background-color: #fff;
  color: transparent; }

@media (hover: hover) {
  .xui-checkbox [type='checkbox'] + label:hover::before {
    background-color: inherit;
    color: rgba(42, 61, 84, 0.2);
    line-height: 1.375rem; } }

.xui-checkbox [type='checkbox']:checked + label::before {
  background-color: inherit;
  color: #2fce6f;
  line-height: 1.375rem; }

.xui-radio label {
  cursor: pointer;
  display: flex;
  align-items: flex-start; }

.xui-radio [type='radio'] + label::before {
  display: inline-flex;
  flex-grow: 0;
  flex-shrink: 0;
  font-family: initial;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: all 0.2s;
  box-shadow: inset 0 1px 2px 1px rgba(0, 0, 0, 0.2);
  content: "⬤";
  font-family: sans-serif;
  border-radius: 0.625rem;
  background: #fff;
  color: transparent;
  font-size: 0.15625rem;
  line-height: 1.25rem;
  height: 1.25rem;
  width: 1.25rem;
  margin: 0 0.5rem 0 0; }

@media (hover: hover) {
  .xui-radio [type='radio'] + label:hover::before {
    background-color: inherit;
    color: rgba(42, 61, 84, 0.2);
    font-size: 0.625rem; } }

.xui-radio [type='radio']:checked + label::before {
  background-color: inherit;
  color: #2fce6f;
  font-size: 0.625rem; }

.xui-toggle .xui-toggle__switch {
  cursor: pointer;
  direction: ltr;
  display: inline-block;
  background: #d5f5e2;
  border-radius: 0.5rem;
  height: 1rem;
  width: 1.75rem;
  transition: background-color 0.2s; }
  .xui-toggle .xui-toggle__switch::after {
    content: '';
    display: block;
    box-shadow: 0 2px 8px 1px rgba(0, 0, 0, 0.2);
    background: #fff;
    border-radius: 0.5rem;
    height: 1rem;
    width: 1rem;
    margin: 0 -0.0625rem; }

.xui-toggle [type='radio']:checked + label .xui-toggle__switch,
.xui-toggle [type='checkbox']:checked + label .xui-toggle__switch, .xui-toggle [type='radio']:checked + label.xui-toggle__switch,
.xui-toggle [type='checkbox']:checked + label.xui-toggle__switch {
  direction: rtl;
  background: #2fce6f; }

.xui-toggle {
  min-height: 2rem;
  margin: 0 0 0.5rem; }
  @supports (display: flex) {
    .xui-toggle {
      display: flex;
      align-items: center; } }
  .xui-toggle:hover .xui-toggle__switch {
    background: #c4f2d6; }
  .xui-toggle .xui-toggle__switch {
    margin-top: 0.2rem; }
  .xui-toggle label {
    cursor: pointer;
    display: flex;
    user-select: none;
    margin: 0;
    font-size: 1em;
    line-height: 1.4em; }
    .xui-toggle label .xui-toggle__switch {
      margin-right: 0.5rem; }

.btn, .hamburger-btn, .dialog-dismiss, .deck-dismiss, .toast-dismiss, .snackbar-dismiss, .xui-overlay__dismiss, .overlay-dismiss {
  cursor: pointer;
  appearance: none;
  user-select: none;
  outline: none;
  touch-action: manipulation;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
  position: relative;
  height: auto;
  border: 0;
  display: inline-block;
  font-family: sans-serif;
  margin: 0;
  padding: 0.55rem 0.825rem;
  font-size: 1rem;
  line-height: 1.4rem;
  min-height: 2.5rem;
  min-width: 2.5rem;
  border-radius: 0.25rem;
  font-weight: bolder; }
  @supports (display: inline-flex) {
    .btn, .hamburger-btn, .dialog-dismiss, .deck-dismiss, .toast-dismiss, .snackbar-dismiss, .xui-overlay__dismiss, .overlay-dismiss {
      display: inline-flex;
      align-items: center;
      justify-content: center; } }
  .btn .bubble:first-child, .hamburger-btn .bubble:first-child, .dialog-dismiss .bubble:first-child, .deck-dismiss .bubble:first-child, .toast-dismiss .bubble:first-child, .snackbar-dismiss .bubble:first-child, .xui-overlay__dismiss .bubble:first-child, .overlay-dismiss .bubble:first-child,
  .btn .tag:first-child,
  .hamburger-btn .tag:first-child,
  .dialog-dismiss .tag:first-child,
  .deck-dismiss .tag:first-child,
  .toast-dismiss .tag:first-child,
  .snackbar-dismiss .tag:first-child,
  .xui-overlay__dismiss .tag:first-child,
  .overlay-dismiss .tag:first-child {
    margin-left: -0.55rem;
    margin-right: 0.55rem; }
  .btn .bubble:last-child, .hamburger-btn .bubble:last-child, .dialog-dismiss .bubble:last-child, .deck-dismiss .bubble:last-child, .toast-dismiss .bubble:last-child, .snackbar-dismiss .bubble:last-child, .xui-overlay__dismiss .bubble:last-child, .overlay-dismiss .bubble:last-child,
  .btn .tag:last-child,
  .hamburger-btn .tag:last-child,
  .dialog-dismiss .tag:last-child,
  .deck-dismiss .tag:last-child,
  .toast-dismiss .tag:last-child,
  .snackbar-dismiss .tag:last-child,
  .xui-overlay__dismiss .tag:last-child,
  .overlay-dismiss .tag:last-child {
    margin-left: 0.55rem;
    margin-right: -0.55rem; }
  .btn .bubble:only-child, .hamburger-btn .bubble:only-child, .dialog-dismiss .bubble:only-child, .deck-dismiss .bubble:only-child, .toast-dismiss .bubble:only-child, .snackbar-dismiss .bubble:only-child, .xui-overlay__dismiss .bubble:only-child, .overlay-dismiss .bubble:only-child,
  .btn .tag:only-child,
  .hamburger-btn .tag:only-child,
  .dialog-dismiss .tag:only-child,
  .deck-dismiss .tag:only-child,
  .toast-dismiss .tag:only-child,
  .snackbar-dismiss .tag:only-child,
  .xui-overlay__dismiss .tag:only-child,
  .overlay-dismiss .tag:only-child {
    margin-left: -0.825rem;
    margin-right: -0.825rem; }
  .btn .ec-icon, .hamburger-btn .ec-icon, .dialog-dismiss .ec-icon, .deck-dismiss .ec-icon, .toast-dismiss .ec-icon, .snackbar-dismiss .ec-icon, .xui-overlay__dismiss .ec-icon, .overlay-dismiss .ec-icon {
    padding-right: 0.55rem; }
    @supports (display: inline-flex) {
      .btn .ec-icon, .hamburger-btn .ec-icon, .dialog-dismiss .ec-icon, .deck-dismiss .ec-icon, .toast-dismiss .ec-icon, .snackbar-dismiss .ec-icon, .xui-overlay__dismiss .ec-icon, .overlay-dismiss .ec-icon {
        display: inline-flex;
        align-items: center;
        justify-content: center; } }
    .btn .ec-icon::before, .hamburger-btn .ec-icon::before, .dialog-dismiss .ec-icon::before, .deck-dismiss .ec-icon::before, .toast-dismiss .ec-icon::before, .snackbar-dismiss .ec-icon::before, .xui-overlay__dismiss .ec-icon::before, .overlay-dismiss .ec-icon::before {
      font-size: 1em;
      line-height: inherit;
      vertical-align: top; }
    .btn .ec-icon_right, .hamburger-btn .ec-icon_right, .dialog-dismiss .ec-icon_right, .deck-dismiss .ec-icon_right, .toast-dismiss .ec-icon_right, .snackbar-dismiss .ec-icon_right, .xui-overlay__dismiss .ec-icon_right, .overlay-dismiss .ec-icon_right {
      padding-left: 0.55rem;
      padding-right: 0; }
    .btn .ec-icon:empty, .hamburger-btn .ec-icon:empty, .dialog-dismiss .ec-icon:empty, .deck-dismiss .ec-icon:empty, .toast-dismiss .ec-icon:empty, .snackbar-dismiss .ec-icon:empty, .xui-overlay__dismiss .ec-icon:empty, .overlay-dismiss .ec-icon:empty {
      padding-left: 0;
      padding-right: 0; }
      .btn .ec-icon:empty:first-child, .hamburger-btn .ec-icon:empty:first-child, .dialog-dismiss .ec-icon:empty:first-child, .deck-dismiss .ec-icon:empty:first-child, .toast-dismiss .ec-icon:empty:first-child, .snackbar-dismiss .ec-icon:empty:first-child, .xui-overlay__dismiss .ec-icon:empty:first-child, .overlay-dismiss .ec-icon:empty:first-child {
        margin-left: -0.275rem;
        margin-right: 0.55rem; }
      .btn .ec-icon:empty:last-child, .hamburger-btn .ec-icon:empty:last-child, .dialog-dismiss .ec-icon:empty:last-child, .deck-dismiss .ec-icon:empty:last-child, .toast-dismiss .ec-icon:empty:last-child, .snackbar-dismiss .ec-icon:empty:last-child, .xui-overlay__dismiss .ec-icon:empty:last-child, .overlay-dismiss .ec-icon:empty:last-child {
        margin-left: 0.55rem;
        margin-right: -0.275rem; }
      .btn .ec-icon:empty:only-child, .hamburger-btn .ec-icon:empty:only-child, .dialog-dismiss .ec-icon:empty:only-child, .deck-dismiss .ec-icon:empty:only-child, .toast-dismiss .ec-icon:empty:only-child, .snackbar-dismiss .ec-icon:empty:only-child, .xui-overlay__dismiss .ec-icon:empty:only-child, .overlay-dismiss .ec-icon:empty:only-child {
        margin-left: -0.825rem;
        margin-right: -0.825rem; }
      .btn .ec-icon:empty::before, .hamburger-btn .ec-icon:empty::before, .dialog-dismiss .ec-icon:empty::before, .deck-dismiss .ec-icon:empty::before, .toast-dismiss .ec-icon:empty::before, .snackbar-dismiss .ec-icon:empty::before, .xui-overlay__dismiss .ec-icon:empty::before, .overlay-dismiss .ec-icon:empty::before {
        margin-left: 0;
        margin-right: 0; }

.btn.btn_small {
  cursor: pointer;
  appearance: none;
  user-select: none;
  outline: none;
  touch-action: manipulation;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
  position: relative;
  height: auto;
  border: 0;
  display: inline-block;
  font-family: sans-serif;
  margin: 0;
  padding: 0.3875rem 0.58125rem;
  font-size: 0.875rem;
  line-height: 1.225rem;
  min-height: 2rem;
  min-width: 2rem;
  border-radius: 0.25rem;
  font-weight: bolder; }
  @supports (display: inline-flex) {
    .btn.btn_small {
      display: inline-flex;
      align-items: center;
      justify-content: center; } }
  .btn.btn_small .bubble:first-child,
  .btn.btn_small .tag:first-child {
    margin-left: -0.3875rem;
    margin-right: 0.3875rem; }
  .btn.btn_small .bubble:last-child,
  .btn.btn_small .tag:last-child {
    margin-left: 0.3875rem;
    margin-right: -0.3875rem; }
  .btn.btn_small .bubble:only-child,
  .btn.btn_small .tag:only-child {
    margin-left: -0.58125rem;
    margin-right: -0.58125rem; }
  .btn.btn_small .ec-icon {
    padding-right: 0.3875rem; }
    @supports (display: inline-flex) {
      .btn.btn_small .ec-icon {
        display: inline-flex;
        align-items: center;
        justify-content: center; } }
    .btn.btn_small .ec-icon::before {
      font-size: 1.14286em;
      line-height: inherit;
      vertical-align: top; }
    .btn.btn_small .ec-icon_right {
      padding-left: 0.3875rem;
      padding-right: 0; }
    .btn.btn_small .ec-icon:empty {
      padding-left: 0;
      padding-right: 0; }
      .btn.btn_small .ec-icon:empty:first-child {
        margin-left: -0.19375rem;
        margin-right: 0.3875rem; }
      .btn.btn_small .ec-icon:empty:last-child {
        margin-left: 0.3875rem;
        margin-right: -0.19375rem; }
      .btn.btn_small .ec-icon:empty:only-child {
        margin-left: -0.58125rem;
        margin-right: -0.58125rem; }
      .btn.btn_small .ec-icon:empty::before {
        margin-left: 0;
        margin-right: 0; }

.btn.btn_big {
  cursor: pointer;
  appearance: none;
  user-select: none;
  outline: none;
  touch-action: manipulation;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
  position: relative;
  height: auto;
  border: 0;
  display: inline-block;
  font-family: sans-serif;
  margin: 0;
  padding: 0.7125rem 1.06875rem;
  font-size: 1.125rem;
  line-height: 1.575rem;
  min-height: 3rem;
  min-width: 3rem;
  border-radius: 0.25rem;
  font-weight: bolder; }
  @supports (display: inline-flex) {
    .btn.btn_big {
      display: inline-flex;
      align-items: center;
      justify-content: center; } }
  .btn.btn_big .bubble:first-child,
  .btn.btn_big .tag:first-child {
    margin-left: -0.7125rem;
    margin-right: 0.7125rem; }
  .btn.btn_big .bubble:last-child,
  .btn.btn_big .tag:last-child {
    margin-left: 0.7125rem;
    margin-right: -0.7125rem; }
  .btn.btn_big .bubble:only-child,
  .btn.btn_big .tag:only-child {
    margin-left: -1.06875rem;
    margin-right: -1.06875rem; }
  .btn.btn_big .ec-icon {
    padding-right: 0.7125rem; }
    @supports (display: inline-flex) {
      .btn.btn_big .ec-icon {
        display: inline-flex;
        align-items: center;
        justify-content: center; } }
    .btn.btn_big .ec-icon::before {
      font-size: 0.88889em;
      line-height: inherit;
      vertical-align: top; }
    .btn.btn_big .ec-icon_right {
      padding-left: 0.7125rem;
      padding-right: 0; }
    .btn.btn_big .ec-icon:empty {
      padding-left: 0;
      padding-right: 0; }
      .btn.btn_big .ec-icon:empty:first-child {
        margin-left: -0.35625rem;
        margin-right: 0.7125rem; }
      .btn.btn_big .ec-icon:empty:last-child {
        margin-left: 0.7125rem;
        margin-right: -0.35625rem; }
      .btn.btn_big .ec-icon:empty:only-child {
        margin-left: -1.06875rem;
        margin-right: -1.06875rem; }
      .btn.btn_big .ec-icon:empty::before {
        margin-left: 0;
        margin-right: 0; }

.btn {
  background: #2fce6f;
  color: white;
  transition: background-color 0.2s, box-shadow 0.1s; }
  .btn * {
    cursor: pointer; }
  .btn strong {
    vertical-align: baseline; }
  .btn.is-active, .btn:hover, .btn:focus {
    outline: none;
    background: #28c064;
    color: white; }
  .btn.is-disabled, .btn[disabled] {
    cursor: not-allowed;
    box-shadow: none;
    opacity: 0.6; }
  .btn:active {
    transform: translateY(2px); }
  .btn.btn_wide {
    min-width: 15rem; }
    .btn.btn_wide.btn_small {
      min-width: 10rem; }
    .btn.btn_wide.btn_big {
      min-width: 15rem; }

.btn_block {
  display: block;
  width: 100%; }
  @supports (display: flex) {
    .btn_block {
      display: flex;
      width: initial; } }

.btn_minor {
  background: rgba(47, 206, 111, 0.2);
  color: #192432; }
  .btn_minor.is-active, .btn_minor:hover, .btn_minor:focus {
    background: rgba(47, 206, 111, 0.2);
    color: #192432; }

.btn_super {
  background: #14a656;
  color: white; }
  .btn_super.is-active, .btn_super:hover, .btn_super:focus {
    background: #19b561;
    color: white; }

.btn_invert {
  background: #fff;
  color: #2fce6f; }
  .btn_invert.is-active, .btn_invert:hover, .btn_invert:focus {
    background: whitesmoke;
    color: #28c064; }

.btn_clear {
  background: transparent;
  color: #2fce6f; }
  .btn_clear.is-active, .btn_clear:hover, .btn_clear:focus {
    background: transparent;
    color: #28c064; }

.btn .tag,
.btn .bubble {
  margin-bottom: 0;
  margin-top: 0; }

.btn .tag {
  margin-top: -0.175rem;
  margin-bottom: -0.175rem; }
  .btn .tag.tag_small {
    margin-top: -0.05rem;
    margin-bottom: -0.05rem; }
  .btn .tag.tag_big {
    margin-top: -0.3rem;
    margin-bottom: -0.3rem; }

.btn .bubble {
  margin-top: -0.175rem;
  margin-bottom: -0.175rem; }
  .btn .bubble.bubble_small {
    margin-top: -0.05rem;
    margin-bottom: -0.05rem; }
  .btn .bubble.bubble_big {
    margin-top: -0.3rem;
    margin-bottom: -0.3rem; }

.btn_small .tag {
  margin-top: -0.2625rem;
  margin-bottom: -0.2625rem; }
  .btn_small .tag.tag_small {
    margin-top: -0.1375rem;
    margin-bottom: -0.1375rem; }
  .btn_small .tag.tag_big {
    margin-top: -0.3875rem;
    margin-bottom: -0.3875rem; }

.btn_small .bubble {
  margin-top: -0.2625rem;
  margin-bottom: -0.2625rem; }
  .btn_small .bubble.bubble_small {
    margin-top: -0.1375rem;
    margin-bottom: -0.1375rem; }
  .btn_small .bubble.bubble_big {
    margin-top: -0.3875rem;
    margin-bottom: -0.3875rem; }

.btn_big .tag {
  margin-top: -0.0875rem;
  margin-bottom: -0.0875rem; }
  .btn_big .tag.tag_small {
    margin-top: 0.0375rem;
    margin-bottom: 0.0375rem; }
  .btn_big .tag.tag_big {
    margin-top: -0.2125rem;
    margin-bottom: -0.2125rem; }

.btn_big .bubble {
  margin-top: -0.0875rem;
  margin-bottom: -0.0875rem; }
  .btn_big .bubble.bubble_small {
    margin-top: 0.0375rem;
    margin-bottom: 0.0375rem; }
  .btn_big .bubble.bubble_big {
    margin-top: -0.2125rem;
    margin-bottom: -0.2125rem; }

.btn_outlined {
  padding: 0.425rem 0.6375rem;
  font-size: 1rem;
  line-height: 1.4rem;
  min-height: 2.5rem;
  min-width: 2.5rem;
  border-style: solid;
  border-width: 0.125rem;
  border-style: solid;
  border-color: #2fce6f;
  color: #2fce6f;
  background: none; }
  .btn_outlined .bubble:first-child,
  .btn_outlined .tag:first-child {
    margin-left: -0.425rem;
    margin-right: 0.425rem; }
  .btn_outlined .bubble:last-child,
  .btn_outlined .tag:last-child {
    margin-left: 0.425rem;
    margin-right: -0.425rem; }
  .btn_outlined .bubble:only-child,
  .btn_outlined .tag:only-child {
    margin-left: -0.6375rem;
    margin-right: -0.6375rem; }
  .btn_outlined .ec-icon {
    padding-right: 0.425rem; }
    @supports (display: inline-flex) {
      .btn_outlined .ec-icon {
        display: inline-flex;
        align-items: center;
        justify-content: center; } }
    .btn_outlined .ec-icon::before {
      font-size: 1em;
      line-height: inherit;
      vertical-align: top; }
    .btn_outlined .ec-icon_right {
      padding-left: 0.425rem;
      padding-right: 0; }
    .btn_outlined .ec-icon:empty {
      padding-left: 0;
      padding-right: 0; }
      .btn_outlined .ec-icon:empty:first-child {
        margin-left: -0.2125rem;
        margin-right: 0.425rem; }
      .btn_outlined .ec-icon:empty:last-child {
        margin-left: 0.425rem;
        margin-right: -0.2125rem; }
      .btn_outlined .ec-icon:empty:only-child {
        margin-left: -0.6375rem;
        margin-right: -0.6375rem; }
      .btn_outlined .ec-icon:empty::before {
        margin-left: 0;
        margin-right: 0; }
  .btn_outlined.btn_small {
    padding: 0.2625rem 0.39375rem;
    font-size: 0.875rem;
    line-height: 1.225rem;
    min-height: 2rem;
    min-width: 2rem;
    border-style: solid;
    border-width: 0.125rem; }
    .btn_outlined.btn_small .bubble:first-child,
    .btn_outlined.btn_small .tag:first-child {
      margin-left: -0.2625rem;
      margin-right: 0.2625rem; }
    .btn_outlined.btn_small .bubble:last-child,
    .btn_outlined.btn_small .tag:last-child {
      margin-left: 0.2625rem;
      margin-right: -0.2625rem; }
    .btn_outlined.btn_small .bubble:only-child,
    .btn_outlined.btn_small .tag:only-child {
      margin-left: -0.39375rem;
      margin-right: -0.39375rem; }
    .btn_outlined.btn_small .ec-icon {
      padding-right: 0.2625rem; }
      @supports (display: inline-flex) {
        .btn_outlined.btn_small .ec-icon {
          display: inline-flex;
          align-items: center;
          justify-content: center; } }
      .btn_outlined.btn_small .ec-icon::before {
        font-size: 1.14286em;
        line-height: inherit;
        vertical-align: top; }
      .btn_outlined.btn_small .ec-icon_right {
        padding-left: 0.2625rem;
        padding-right: 0; }
      .btn_outlined.btn_small .ec-icon:empty {
        padding-left: 0;
        padding-right: 0; }
        .btn_outlined.btn_small .ec-icon:empty:first-child {
          margin-left: -0.13125rem;
          margin-right: 0.2625rem; }
        .btn_outlined.btn_small .ec-icon:empty:last-child {
          margin-left: 0.2625rem;
          margin-right: -0.13125rem; }
        .btn_outlined.btn_small .ec-icon:empty:only-child {
          margin-left: -0.39375rem;
          margin-right: -0.39375rem; }
        .btn_outlined.btn_small .ec-icon:empty::before {
          margin-left: 0;
          margin-right: 0; }
  .btn_outlined.btn_big {
    padding: 0.5875rem 0.88125rem;
    font-size: 1.125rem;
    line-height: 1.575rem;
    min-height: 3rem;
    min-width: 3rem;
    border-style: solid;
    border-width: 0.125rem; }
    .btn_outlined.btn_big .bubble:first-child,
    .btn_outlined.btn_big .tag:first-child {
      margin-left: -0.5875rem;
      margin-right: 0.5875rem; }
    .btn_outlined.btn_big .bubble:last-child,
    .btn_outlined.btn_big .tag:last-child {
      margin-left: 0.5875rem;
      margin-right: -0.5875rem; }
    .btn_outlined.btn_big .bubble:only-child,
    .btn_outlined.btn_big .tag:only-child {
      margin-left: -0.88125rem;
      margin-right: -0.88125rem; }
    .btn_outlined.btn_big .ec-icon {
      padding-right: 0.5875rem; }
      @supports (display: inline-flex) {
        .btn_outlined.btn_big .ec-icon {
          display: inline-flex;
          align-items: center;
          justify-content: center; } }
      .btn_outlined.btn_big .ec-icon::before {
        font-size: 0.88889em;
        line-height: inherit;
        vertical-align: top; }
      .btn_outlined.btn_big .ec-icon_right {
        padding-left: 0.5875rem;
        padding-right: 0; }
      .btn_outlined.btn_big .ec-icon:empty {
        padding-left: 0;
        padding-right: 0; }
        .btn_outlined.btn_big .ec-icon:empty:first-child {
          margin-left: -0.29375rem;
          margin-right: 0.5875rem; }
        .btn_outlined.btn_big .ec-icon:empty:last-child {
          margin-left: 0.5875rem;
          margin-right: -0.29375rem; }
        .btn_outlined.btn_big .ec-icon:empty:only-child {
          margin-left: -0.88125rem;
          margin-right: -0.88125rem; }
        .btn_outlined.btn_big .ec-icon:empty::before {
          margin-left: 0;
          margin-right: 0; }
  .btn_outlined.is-active, .btn_outlined:hover, .btn_outlined:focus {
    border-color: #28c064;
    color: #28c064;
    background: none; }
  .btn_outlined.btn_minor {
    border-color: rgba(47, 206, 111, 0.2);
    color: #212f3e;
    background: none; }
    .btn_outlined.btn_minor.is-active, .btn_outlined.btn_minor:hover, .btn_outlined.btn_minor:focus {
      border-color: #c4f2d6;
      color: #212f3e;
      background: none; }
  .btn_outlined.btn_super {
    border-color: #14a656;
    color: whitesmoke;
    background: none; }
    .btn_outlined.btn_super.is-active, .btn_outlined.btn_super:hover, .btn_outlined.btn_super:focus {
      border-color: #19b561;
      color: whitesmoke;
      background: none; }
  .btn_outlined.btn_invert {
    border-color: #fff;
    color: whitesmoke;
    background: none; }
    .btn_outlined.btn_invert.is-active, .btn_outlined.btn_invert:hover, .btn_outlined.btn_invert:focus {
      border-color: whitesmoke;
      color: whitesmoke;
      background: none; }

.btn.btn_round {
  border-radius: 1.25rem; }
  .btn.btn_round.btn_small {
    border-radius: 1rem; }
  .btn.btn_round.btn_big {
    border-radius: 1.5rem; }

.btn_glow.btn {
  box-shadow: 0 12px 24px -8px rgba(47, 206, 111, 0.6); }
  .btn_glow.btn:hover {
    box-shadow: 0 8px 16px -8px rgba(47, 206, 111, 0.6); }
  .btn_glow.btn.is-disabled, .btn_glow.btn:disabled {
    box-shadow: none; }
  .btn_glow.btn.btn_super {
    box-shadow: 0 12px 24px -8px rgba(20, 166, 86, 0.6); }
    .btn_glow.btn.btn_super:hover {
      box-shadow: 0 8px 16px -8px rgba(20, 166, 86, 0.6); }
    .btn_glow.btn.btn_super.is-disabled, .btn_glow.btn.btn_super:disabled {
      box-shadow: none; }
  .btn_glow.btn.btn_minor {
    box-shadow: 0 12px 24px -8px rgba(47, 206, 111, 0.6); }
    .btn_glow.btn.btn_minor:hover {
      box-shadow: 0 8px 16px -8px rgba(47, 206, 111, 0.6); }
    .btn_glow.btn.btn_minor.is-disabled, .btn_glow.btn.btn_minor:disabled {
      box-shadow: none; }

.hamburger-btn {
  position: relative;
  background-color: transparent;
  margin: 0; }
  .hamburger-btn::after {
    content: '';
    position: absolute;
    top: 50%;
    transition: all 0.4s;
    background-color: #2fce6f;
    border-radius: 0.0625rem;
    box-shadow: 0 0.375rem 0 0 #2fce6f, 0 -0.375rem 0 0 #2fce6f;
    height: 0.125rem;
    left: 0.75rem;
    margin-top: -0.0625rem;
    width: 1rem; }
  .hamburger-btn::before {
    content: '';
    position: absolute;
    top: 50%;
    transition: all 0.4s;
    background-color: #2fce6f;
    border-radius: 0.0625rem;
    height: 0.125rem;
    left: 0.75rem;
    margin-top: -0.0625rem;
    width: 1rem; }
  .hamburger-btn.is-active::after {
    content: '';
    position: absolute;
    top: 50%;
    box-shadow: 0 0 0 0 transparent, 0 0 0 0 transparent;
    background-color: #2fce6f;
    border-radius: 0.0625rem;
    height: 0.125rem;
    left: 0.75rem;
    margin-top: -0.0625rem;
    transform: rotate(45deg);
    width: 1rem; }
  .hamburger-btn.is-active::before {
    transform: rotate(-45deg); }

.tag {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: text-bottom;
  padding: 0.2625rem 0.525rem;
  font-size: 0.875rem;
  line-height: 1.225rem;
  min-height: 1.75rem;
  min-width: 1.75rem;
  background-color: rgba(47, 206, 111, 0.2);
  color: #192432;
  border-radius: 0.125rem;
  font-weight: bolder; }
  .tag .bubble:first-child,
  .tag .tag:first-child {
    margin-left: -0.2625rem;
    margin-right: 0.2625rem; }
  .tag .bubble:last-child,
  .tag .tag:last-child {
    margin-left: 0.2625rem;
    margin-right: -0.2625rem; }
  .tag .bubble:only-child,
  .tag .tag:only-child {
    margin-left: -0.525rem;
    margin-right: -0.525rem; }
  .tag .ec-icon {
    padding-right: 0.2625rem; }
    @supports (display: inline-flex) {
      .tag .ec-icon {
        display: inline-flex;
        align-items: center;
        justify-content: center; } }
    .tag .ec-icon::before {
      font-size: 1.14286em;
      line-height: inherit;
      vertical-align: top; }
    .tag .ec-icon_right {
      padding-left: 0.2625rem;
      padding-right: 0; }
    .tag .ec-icon:empty {
      padding-left: 0;
      padding-right: 0; }
      .tag .ec-icon:empty:first-child {
        margin-left: -0.13125rem;
        margin-right: 0.2625rem; }
      .tag .ec-icon:empty:last-child {
        margin-left: 0.2625rem;
        margin-right: -0.13125rem; }
      .tag .ec-icon:empty:only-child {
        margin-left: -0.525rem;
        margin-right: -0.525rem; }
      .tag .ec-icon:empty::before {
        margin-left: 0;
        margin-right: 0; }
  .tag a {
    color: #192432; }
  .tag.tag_small {
    padding: 0.21406rem 0.42813rem;
    font-size: 0.76562rem;
    line-height: 1.07187rem;
    min-height: 1.5rem;
    min-width: 1.5rem;
    border-radius: 0.125rem; }
    .tag.tag_small .bubble:first-child,
    .tag.tag_small .tag:first-child {
      margin-left: -0.21406rem;
      margin-right: 0.21406rem; }
    .tag.tag_small .bubble:last-child,
    .tag.tag_small .tag:last-child {
      margin-left: 0.21406rem;
      margin-right: -0.21406rem; }
    .tag.tag_small .bubble:only-child,
    .tag.tag_small .tag:only-child {
      margin-left: -0.42813rem;
      margin-right: -0.42813rem; }
    .tag.tag_small .ec-icon {
      padding-right: 0.21406rem; }
      @supports (display: inline-flex) {
        .tag.tag_small .ec-icon {
          display: inline-flex;
          align-items: center;
          justify-content: center; } }
      .tag.tag_small .ec-icon::before {
        font-size: 1.30612em;
        line-height: inherit;
        vertical-align: top; }
      .tag.tag_small .ec-icon_right {
        padding-left: 0.21406rem;
        padding-right: 0; }
      .tag.tag_small .ec-icon:empty {
        padding-left: 0;
        padding-right: 0; }
        .tag.tag_small .ec-icon:empty:first-child {
          margin-left: -0.10703rem;
          margin-right: 0.21406rem; }
        .tag.tag_small .ec-icon:empty:last-child {
          margin-left: 0.21406rem;
          margin-right: -0.10703rem; }
        .tag.tag_small .ec-icon:empty:only-child {
          margin-left: -0.42813rem;
          margin-right: -0.42813rem; }
        .tag.tag_small .ec-icon:empty::before {
          margin-left: 0;
          margin-right: 0; }
  .tag.tag_big {
    padding: 0.31094rem 0.62188rem;
    font-size: 0.98438rem;
    line-height: 1.37812rem;
    min-height: 2rem;
    min-width: 2rem;
    border-radius: 0.125rem; }
    .tag.tag_big .bubble:first-child,
    .tag.tag_big .tag:first-child {
      margin-left: -0.31094rem;
      margin-right: 0.31094rem; }
    .tag.tag_big .bubble:last-child,
    .tag.tag_big .tag:last-child {
      margin-left: 0.31094rem;
      margin-right: -0.31094rem; }
    .tag.tag_big .bubble:only-child,
    .tag.tag_big .tag:only-child {
      margin-left: -0.62188rem;
      margin-right: -0.62188rem; }
    .tag.tag_big .ec-icon {
      padding-right: 0.31094rem; }
      @supports (display: inline-flex) {
        .tag.tag_big .ec-icon {
          display: inline-flex;
          align-items: center;
          justify-content: center; } }
      .tag.tag_big .ec-icon::before {
        font-size: 1.01587em;
        line-height: inherit;
        vertical-align: top; }
      .tag.tag_big .ec-icon_right {
        padding-left: 0.31094rem;
        padding-right: 0; }
      .tag.tag_big .ec-icon:empty {
        padding-left: 0;
        padding-right: 0; }
        .tag.tag_big .ec-icon:empty:first-child {
          margin-left: -0.15547rem;
          margin-right: 0.31094rem; }
        .tag.tag_big .ec-icon:empty:last-child {
          margin-left: 0.31094rem;
          margin-right: -0.15547rem; }
        .tag.tag_big .ec-icon:empty:only-child {
          margin-left: -0.62188rem;
          margin-right: -0.62188rem; }
        .tag.tag_big .ec-icon:empty::before {
          margin-left: 0;
          margin-right: 0; }
  .tag + .tag {
    margin-left: 0.25rem; }

.tag_minor {
  background-color: rgba(47, 206, 111, 0.2);
  color: #192432; }
  .tag_minor a {
    color: #192432; }
  a.tag_minor:hover {
    background: #c4f2d6;
    color: #192432; }

.tag_link {
  background-color: #2fce6f;
  color: white; }
  .tag_link a {
    color: white; }
  a.tag_link:hover {
    background: #28c064;
    color: white; }

.tag_super {
  background-color: #14a656;
  color: white; }
  .tag_super a {
    color: white; }
  a.tag_super:hover {
    background: #19b561;
    color: white; }

.tag_highlight {
  background-color: #fcff66;
  color: #080c10; }
  .tag_highlight a {
    color: #080c10; }
  a.tag_highlight:hover {
    background: #faff52;
    color: #080c10; }

.tag_invert {
  background-color: #fff;
  color: #2a3d54; }
  .tag_invert a {
    color: #2a3d54; }
  a.tag_invert:hover {
    background: whitesmoke;
    color: #2a3d54; }

.tag_info {
  background-color: #22e7e7;
  color: black; }
  .tag_info a {
    color: black; }
  a.tag_info:hover {
    background: #15dee0;
    color: black; }

.tag_error {
  background-color: #e72222;
  color: white; }
  .tag_error a {
    color: white; }
  a.tag_error:hover {
    background: #e63938;
    color: white; }

.tag_success {
  background-color: #22e722;
  color: white; }
  .tag_success a {
    color: white; }
  a.tag_success:hover {
    background: #38e639;
    color: white; }

.tag_warning {
  background-color: #e7c822;
  color: black; }
  .tag_warning a {
    color: black; }
  a.tag_warning:hover {
    background: #e0be15;
    color: black; }

.tag_outlined {
  padding: 0.1375rem 0.275rem;
  font-size: 0.875rem;
  line-height: 1.225rem;
  min-height: 1.75rem;
  min-width: 1.75rem;
  border-style: solid;
  border-width: 0.125rem;
  background: none;
  border-color: rgba(47, 206, 111, 0.2);
  border-style: solid; }
  .tag_outlined .bubble:first-child,
  .tag_outlined .tag:first-child {
    margin-left: -0.1375rem;
    margin-right: 0.1375rem; }
  .tag_outlined .bubble:last-child,
  .tag_outlined .tag:last-child {
    margin-left: 0.1375rem;
    margin-right: -0.1375rem; }
  .tag_outlined .bubble:only-child,
  .tag_outlined .tag:only-child {
    margin-left: -0.275rem;
    margin-right: -0.275rem; }
  .tag_outlined .ec-icon {
    padding-right: 0.1375rem; }
    @supports (display: inline-flex) {
      .tag_outlined .ec-icon {
        display: inline-flex;
        align-items: center;
        justify-content: center; } }
    .tag_outlined .ec-icon::before {
      font-size: 1.14286em;
      line-height: inherit;
      vertical-align: top; }
    .tag_outlined .ec-icon_right {
      padding-left: 0.1375rem;
      padding-right: 0; }
    .tag_outlined .ec-icon:empty {
      padding-left: 0;
      padding-right: 0; }
      .tag_outlined .ec-icon:empty:first-child {
        margin-left: -0.06875rem;
        margin-right: 0.1375rem; }
      .tag_outlined .ec-icon:empty:last-child {
        margin-left: 0.1375rem;
        margin-right: -0.06875rem; }
      .tag_outlined .ec-icon:empty:only-child {
        margin-left: -0.275rem;
        margin-right: -0.275rem; }
      .tag_outlined .ec-icon:empty::before {
        margin-left: 0;
        margin-right: 0; }
  a:hover .tag_outlined {
    border-color: #c4f2d6;
    color: #c4f2d6; }
  .tag_outlined.tag_small {
    padding: 0.08906rem 0.17813rem;
    font-size: 0.76562rem;
    line-height: 1.07187rem;
    min-height: 1.5rem;
    min-width: 1.5rem;
    border-style: solid;
    border-width: 0.125rem; }
    .tag_outlined.tag_small .bubble:first-child,
    .tag_outlined.tag_small .tag:first-child {
      margin-left: -0.08906rem;
      margin-right: 0.08906rem; }
    .tag_outlined.tag_small .bubble:last-child,
    .tag_outlined.tag_small .tag:last-child {
      margin-left: 0.08906rem;
      margin-right: -0.08906rem; }
    .tag_outlined.tag_small .bubble:only-child,
    .tag_outlined.tag_small .tag:only-child {
      margin-left: -0.17813rem;
      margin-right: -0.17813rem; }
    .tag_outlined.tag_small .ec-icon {
      padding-right: 0.08906rem; }
      @supports (display: inline-flex) {
        .tag_outlined.tag_small .ec-icon {
          display: inline-flex;
          align-items: center;
          justify-content: center; } }
      .tag_outlined.tag_small .ec-icon::before {
        font-size: 1.30612em;
        line-height: inherit;
        vertical-align: top; }
      .tag_outlined.tag_small .ec-icon_right {
        padding-left: 0.08906rem;
        padding-right: 0; }
      .tag_outlined.tag_small .ec-icon:empty {
        padding-left: 0;
        padding-right: 0; }
        .tag_outlined.tag_small .ec-icon:empty:first-child {
          margin-left: -0.04453rem;
          margin-right: 0.08906rem; }
        .tag_outlined.tag_small .ec-icon:empty:last-child {
          margin-left: 0.08906rem;
          margin-right: -0.04453rem; }
        .tag_outlined.tag_small .ec-icon:empty:only-child {
          margin-left: -0.17813rem;
          margin-right: -0.17813rem; }
        .tag_outlined.tag_small .ec-icon:empty::before {
          margin-left: 0;
          margin-right: 0; }
  .tag_outlined.tag_big {
    padding: 0.18594rem 0.37188rem;
    font-size: 0.98438rem;
    line-height: 1.37812rem;
    min-height: 2rem;
    min-width: 2rem;
    border-style: solid;
    border-width: 0.125rem; }
    .tag_outlined.tag_big .bubble:first-child,
    .tag_outlined.tag_big .tag:first-child {
      margin-left: -0.18594rem;
      margin-right: 0.18594rem; }
    .tag_outlined.tag_big .bubble:last-child,
    .tag_outlined.tag_big .tag:last-child {
      margin-left: 0.18594rem;
      margin-right: -0.18594rem; }
    .tag_outlined.tag_big .bubble:only-child,
    .tag_outlined.tag_big .tag:only-child {
      margin-left: -0.37188rem;
      margin-right: -0.37188rem; }
    .tag_outlined.tag_big .ec-icon {
      padding-right: 0.18594rem; }
      @supports (display: inline-flex) {
        .tag_outlined.tag_big .ec-icon {
          display: inline-flex;
          align-items: center;
          justify-content: center; } }
      .tag_outlined.tag_big .ec-icon::before {
        font-size: 1.01587em;
        line-height: inherit;
        vertical-align: top; }
      .tag_outlined.tag_big .ec-icon_right {
        padding-left: 0.18594rem;
        padding-right: 0; }
      .tag_outlined.tag_big .ec-icon:empty {
        padding-left: 0;
        padding-right: 0; }
        .tag_outlined.tag_big .ec-icon:empty:first-child {
          margin-left: -0.09297rem;
          margin-right: 0.18594rem; }
        .tag_outlined.tag_big .ec-icon:empty:last-child {
          margin-left: 0.18594rem;
          margin-right: -0.09297rem; }
        .tag_outlined.tag_big .ec-icon:empty:only-child {
          margin-left: -0.37188rem;
          margin-right: -0.37188rem; }
        .tag_outlined.tag_big .ec-icon:empty::before {
          margin-left: 0;
          margin-right: 0; }

.tag_outlined.tag_minor {
  border-color: rgba(47, 206, 111, 0.2);
  color: rgba(47, 206, 111, 0.2); }
  a.tag_outlined.tag_minor:hover {
    background: none;
    border-color: #c4f2d6;
    color: rgba(47, 206, 111, 0.2); }

.tag_outlined.tag_link {
  border-color: #2fce6f;
  color: #2fce6f; }
  a.tag_outlined.tag_link:hover {
    background: none;
    border-color: #28c064;
    color: #2fce6f; }

.tag_outlined.tag_super {
  border-color: #14a656;
  color: #14a656; }
  a.tag_outlined.tag_super:hover {
    background: none;
    border-color: #19b561;
    color: #14a656; }

.tag_outlined.tag_highlight {
  border-color: #fcff66;
  color: #fcff66; }
  a.tag_outlined.tag_highlight:hover {
    background: none;
    border-color: #faff52;
    color: #fcff66; }

.tag_outlined.tag_invert {
  border-color: #fff;
  color: #fff; }
  a.tag_outlined.tag_invert:hover {
    background: none;
    border-color: whitesmoke;
    color: #fff; }

.tag_outlined.tag_info {
  border-color: #22e7e7;
  color: #22e7e7; }
  a.tag_outlined.tag_info:hover {
    background: none;
    border-color: #15dee0;
    color: #22e7e7; }

.tag_outlined.tag_error {
  border-color: #e72222;
  color: #e72222; }
  a.tag_outlined.tag_error:hover {
    background: none;
    border-color: #e63938;
    color: #e72222; }

.tag_outlined.tag_success {
  border-color: #22e722;
  color: #22e722; }
  a.tag_outlined.tag_success:hover {
    background: none;
    border-color: #38e639;
    color: #22e722; }

.tag_outlined.tag_warning {
  border-color: #e7c822;
  color: #e7c822; }
  a.tag_outlined.tag_warning:hover {
    background: none;
    border-color: #e0be15;
    color: #e7c822; }

.tag.tag_round {
  border-radius: 0.875rem; }
  .tag.tag_round.tag_small {
    border-radius: 0.75rem; }
  .tag.tag_round.tag_big {
    border-radius: 1rem; }

.bubble {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  background-color: rgba(47, 206, 111, 0.2);
  color: #192432;
  padding: 0.375rem 0.375rem;
  font-size: 0.875rem;
  line-height: 1rem;
  min-height: 1.75rem;
  min-width: 1.75rem;
  border-radius: 0.25rem;
  font-weight: bolder; }
  .bubble.bubble_small {
    padding: 0.375rem 0.375rem;
    font-size: 0.625rem;
    line-height: 0.75rem;
    min-height: 1.5rem;
    min-width: 1.5rem;
    border-radius: 0.25rem; }
  .bubble.bubble_big {
    padding: 0.375rem 0.375rem;
    font-size: 1rem;
    line-height: 1.25rem;
    min-height: 2rem;
    min-width: 2rem;
    border-radius: 0.25rem; }

.bubble_minor {
  background-color: rgba(47, 206, 111, 0.2);
  color: #192432; }

.bubble_link {
  background-color: #2fce6f;
  color: white; }

.bubble_super {
  background-color: #14a656;
  color: white; }

.bubble_highlight {
  background-color: #fcff66;
  color: #080c10; }

.bubble_invert {
  background-color: #fff;
  color: #2a3d54; }

.bubble_info {
  background-color: #22e7e7;
  color: black; }

.bubble_error {
  background-color: #e72222;
  color: white; }

.bubble_success {
  background-color: #22e722;
  color: white; }

.bubble_warning {
  background-color: #e7c822;
  color: black; }

.bubble {
  border-radius: 0.875rem; }
  .bubble.bubble_small {
    border-radius: 0.75rem; }
  .bubble.bubble_big {
    border-radius: 1rem; }

@keyframes loaderDefault {
  0% {
    transform: rotate(0);
    border-right-color: #2fce6f; }
  50% {
    border-right-color: #d03190; }
  100% {
    border-right-color: #2fce6f;
    transform: rotate(360deg); } }

.loader {
  display: none;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin: 0 auto;
  display: flex; }
  .loader::after {
    content: '';
    display: block;
    border-radius: 50%;
    height: 1.5em;
    border: 0.25em solid rgba(42, 61, 84, 0.1);
    border-right-color: #2fce6f;
    animation: loaderDefault 1s infinite linear;
    width: 1.5em;
    flex-grow: 0; }
  .loader.is-local {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .loader.is-global {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.2); }

.btn .loader:not(.is-local) {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem; }

.btn_small .loader:not(.is-local) {
  margin-top: -0.25rem;
  margin-bottom: -0.25rem; }

.btn_big .loader:not(.is-local) {
  margin-top: -0.75rem;
  margin-bottom: -0.75rem; }

.bumper {
  flex-shrink: 0;
  width: 100%;
  position: relative;
  text-align: center;
  height: 0.25rem;
  margin: 2rem 0; }
  @media (min-width: 0px) {
    .bumper-xs {
      margin: 2rem 0; } }
  @media (min-width: 480px) {
    .bumper-sm {
      margin: 2rem 0; } }
  @media (min-width: 720px) {
    .bumper-md {
      margin: 2rem 0; } }
  @media (min-width: 1024px) {
    .bumper-nav {
      margin: 2rem 0; } }
  @media (min-width: 1024px) {
    .bumper-lg {
      margin: 2rem 0; } }
  @media (min-width: 1280px) {
    .bumper-xl {
      margin: 2rem 0; } }
  @media (min-width: 1920px) {
    .bumper-xxl {
      margin: 2rem 0; } }
  .bumper::before {
    content: '';
    border-radius: 50%;
    position: absolute;
    background-color: rgba(42, 61, 84, 0.1);
    box-shadow: 0.5rem 0 0 rgba(42, 61, 84, 0.1), 1rem 0 0 rgba(42, 61, 84, 0.1);
    height: 0.25rem;
    margin-left: -0.625rem;
    width: 0.25rem; }
  .bumper.bumper_invert::before {
    background-color: rgba(255, 255, 255, 0.2);
    box-shadow: 0.5rem 0 0 rgba(255, 255, 255, 0.2), 1rem 0 0 rgba(255, 255, 255, 0.2); }

.bumper_small {
  margin: 1rem 0; }
  @media (min-width: 0px) {
    .bumper_small-xs {
      margin: 1rem 0; } }
  @media (min-width: 480px) {
    .bumper_small-sm {
      margin: 1rem 0; } }
  @media (min-width: 720px) {
    .bumper_small-md {
      margin: 1rem 0; } }
  @media (min-width: 1024px) {
    .bumper_small-nav {
      margin: 1rem 0; } }
  @media (min-width: 1024px) {
    .bumper_small-lg {
      margin: 1rem 0; } }
  @media (min-width: 1280px) {
    .bumper_small-xl {
      margin: 1rem 0; } }
  @media (min-width: 1920px) {
    .bumper_small-xxl {
      margin: 1rem 0; } }

.bumper_big {
  margin: 3rem 0; }
  @media (min-width: 0px) {
    .bumper_big-xs {
      margin: 3rem 0; } }
  @media (min-width: 480px) {
    .bumper_big-sm {
      margin: 3rem 0; } }
  @media (min-width: 720px) {
    .bumper_big-md {
      margin: 3rem 0; } }
  @media (min-width: 1024px) {
    .bumper_big-nav {
      margin: 3rem 0; } }
  @media (min-width: 1024px) {
    .bumper_big-lg {
      margin: 3rem 0; } }
  @media (min-width: 1280px) {
    .bumper_big-xl {
      margin: 3rem 0; } }
  @media (min-width: 1920px) {
    .bumper_big-xxl {
      margin: 3rem 0; } }

.bumper-small {
  position: relative;
  height: 0.25rem;
  text-align: center;
  flex-shrink: 0;
  margin: 1rem 0;
  width: 100%; }
  .bumper-small::before {
    background-color: rgba(42, 61, 84, 0.1);
    border-radius: 50%;
    box-shadow: 0.5rem 0 0 rgba(42, 61, 84, 0.1), 1rem 0 0 rgba(42, 61, 84, 0.1);
    content: '';
    height: 0.25rem;
    margin-left: -0.625rem;
    position: absolute;
    width: 0.25rem; }
  .bumper-small.bumper_invert::before {
    background-color: rgba(255, 255, 255, 0.2);
    box-shadow: 0.5rem 0 0 rgba(255, 255, 255, 0.2), 1rem 0 0 rgba(255, 255, 255, 0.2); }
  @media (min-width: 0px) {
    .bumper-small-xs {
      position: relative;
      height: 0.25rem;
      text-align: center;
      flex-shrink: 0;
      margin: 1rem 0;
      width: 100%; }
      .bumper-small-xs::before {
        background-color: rgba(42, 61, 84, 0.1);
        border-radius: 50%;
        box-shadow: 0.5rem 0 0 rgba(42, 61, 84, 0.1), 1rem 0 0 rgba(42, 61, 84, 0.1);
        content: '';
        height: 0.25rem;
        margin-left: -0.625rem;
        position: absolute;
        width: 0.25rem; }
      .bumper-small-xs.bumper_invert::before {
        background-color: rgba(255, 255, 255, 0.2);
        box-shadow: 0.5rem 0 0 rgba(255, 255, 255, 0.2), 1rem 0 0 rgba(255, 255, 255, 0.2); } }
  @media (min-width: 480px) {
    .bumper-small-sm {
      position: relative;
      height: 0.25rem;
      text-align: center;
      flex-shrink: 0;
      margin: 1rem 0;
      width: 100%; }
      .bumper-small-sm::before {
        background-color: rgba(42, 61, 84, 0.1);
        border-radius: 50%;
        box-shadow: 0.5rem 0 0 rgba(42, 61, 84, 0.1), 1rem 0 0 rgba(42, 61, 84, 0.1);
        content: '';
        height: 0.25rem;
        margin-left: -0.625rem;
        position: absolute;
        width: 0.25rem; }
      .bumper-small-sm.bumper_invert::before {
        background-color: rgba(255, 255, 255, 0.2);
        box-shadow: 0.5rem 0 0 rgba(255, 255, 255, 0.2), 1rem 0 0 rgba(255, 255, 255, 0.2); } }
  @media (min-width: 720px) {
    .bumper-small-md {
      position: relative;
      height: 0.25rem;
      text-align: center;
      flex-shrink: 0;
      margin: 1rem 0;
      width: 100%; }
      .bumper-small-md::before {
        background-color: rgba(42, 61, 84, 0.1);
        border-radius: 50%;
        box-shadow: 0.5rem 0 0 rgba(42, 61, 84, 0.1), 1rem 0 0 rgba(42, 61, 84, 0.1);
        content: '';
        height: 0.25rem;
        margin-left: -0.625rem;
        position: absolute;
        width: 0.25rem; }
      .bumper-small-md.bumper_invert::before {
        background-color: rgba(255, 255, 255, 0.2);
        box-shadow: 0.5rem 0 0 rgba(255, 255, 255, 0.2), 1rem 0 0 rgba(255, 255, 255, 0.2); } }
  @media (min-width: 1024px) {
    .bumper-small-nav {
      position: relative;
      height: 0.25rem;
      text-align: center;
      flex-shrink: 0;
      margin: 1rem 0;
      width: 100%; }
      .bumper-small-nav::before {
        background-color: rgba(42, 61, 84, 0.1);
        border-radius: 50%;
        box-shadow: 0.5rem 0 0 rgba(42, 61, 84, 0.1), 1rem 0 0 rgba(42, 61, 84, 0.1);
        content: '';
        height: 0.25rem;
        margin-left: -0.625rem;
        position: absolute;
        width: 0.25rem; }
      .bumper-small-nav.bumper_invert::before {
        background-color: rgba(255, 255, 255, 0.2);
        box-shadow: 0.5rem 0 0 rgba(255, 255, 255, 0.2), 1rem 0 0 rgba(255, 255, 255, 0.2); } }
  @media (min-width: 1024px) {
    .bumper-small-lg {
      position: relative;
      height: 0.25rem;
      text-align: center;
      flex-shrink: 0;
      margin: 1rem 0;
      width: 100%; }
      .bumper-small-lg::before {
        background-color: rgba(42, 61, 84, 0.1);
        border-radius: 50%;
        box-shadow: 0.5rem 0 0 rgba(42, 61, 84, 0.1), 1rem 0 0 rgba(42, 61, 84, 0.1);
        content: '';
        height: 0.25rem;
        margin-left: -0.625rem;
        position: absolute;
        width: 0.25rem; }
      .bumper-small-lg.bumper_invert::before {
        background-color: rgba(255, 255, 255, 0.2);
        box-shadow: 0.5rem 0 0 rgba(255, 255, 255, 0.2), 1rem 0 0 rgba(255, 255, 255, 0.2); } }
  @media (min-width: 1280px) {
    .bumper-small-xl {
      position: relative;
      height: 0.25rem;
      text-align: center;
      flex-shrink: 0;
      margin: 1rem 0;
      width: 100%; }
      .bumper-small-xl::before {
        background-color: rgba(42, 61, 84, 0.1);
        border-radius: 50%;
        box-shadow: 0.5rem 0 0 rgba(42, 61, 84, 0.1), 1rem 0 0 rgba(42, 61, 84, 0.1);
        content: '';
        height: 0.25rem;
        margin-left: -0.625rem;
        position: absolute;
        width: 0.25rem; }
      .bumper-small-xl.bumper_invert::before {
        background-color: rgba(255, 255, 255, 0.2);
        box-shadow: 0.5rem 0 0 rgba(255, 255, 255, 0.2), 1rem 0 0 rgba(255, 255, 255, 0.2); } }
  @media (min-width: 1920px) {
    .bumper-small-xxl {
      position: relative;
      height: 0.25rem;
      text-align: center;
      flex-shrink: 0;
      margin: 1rem 0;
      width: 100%; }
      .bumper-small-xxl::before {
        background-color: rgba(42, 61, 84, 0.1);
        border-radius: 50%;
        box-shadow: 0.5rem 0 0 rgba(42, 61, 84, 0.1), 1rem 0 0 rgba(42, 61, 84, 0.1);
        content: '';
        height: 0.25rem;
        margin-left: -0.625rem;
        position: absolute;
        width: 0.25rem; }
      .bumper-small-xxl.bumper_invert::before {
        background-color: rgba(255, 255, 255, 0.2);
        box-shadow: 0.5rem 0 0 rgba(255, 255, 255, 0.2), 1rem 0 0 rgba(255, 255, 255, 0.2); } }

.bumper-big {
  position: relative;
  height: 0.25rem;
  text-align: center;
  flex-shrink: 0;
  margin: 3rem 0;
  width: 100%; }
  .bumper-big::before {
    background-color: rgba(42, 61, 84, 0.1);
    border-radius: 50%;
    box-shadow: 0.5rem 0 0 rgba(42, 61, 84, 0.1), 1rem 0 0 rgba(42, 61, 84, 0.1);
    content: '';
    height: 0.25rem;
    margin-left: -0.625rem;
    position: absolute;
    width: 0.25rem; }
  .bumper-big.bumper_invert::before {
    background-color: rgba(255, 255, 255, 0.2);
    box-shadow: 0.5rem 0 0 rgba(255, 255, 255, 0.2), 1rem 0 0 rgba(255, 255, 255, 0.2); }
  @media (min-width: 0px) {
    .bumper-big-xs {
      position: relative;
      height: 0.25rem;
      text-align: center;
      flex-shrink: 0;
      margin: 3rem 0;
      width: 100%; }
      .bumper-big-xs::before {
        background-color: rgba(42, 61, 84, 0.1);
        border-radius: 50%;
        box-shadow: 0.5rem 0 0 rgba(42, 61, 84, 0.1), 1rem 0 0 rgba(42, 61, 84, 0.1);
        content: '';
        height: 0.25rem;
        margin-left: -0.625rem;
        position: absolute;
        width: 0.25rem; }
      .bumper-big-xs.bumper_invert::before {
        background-color: rgba(255, 255, 255, 0.2);
        box-shadow: 0.5rem 0 0 rgba(255, 255, 255, 0.2), 1rem 0 0 rgba(255, 255, 255, 0.2); } }
  @media (min-width: 480px) {
    .bumper-big-sm {
      position: relative;
      height: 0.25rem;
      text-align: center;
      flex-shrink: 0;
      margin: 3rem 0;
      width: 100%; }
      .bumper-big-sm::before {
        background-color: rgba(42, 61, 84, 0.1);
        border-radius: 50%;
        box-shadow: 0.5rem 0 0 rgba(42, 61, 84, 0.1), 1rem 0 0 rgba(42, 61, 84, 0.1);
        content: '';
        height: 0.25rem;
        margin-left: -0.625rem;
        position: absolute;
        width: 0.25rem; }
      .bumper-big-sm.bumper_invert::before {
        background-color: rgba(255, 255, 255, 0.2);
        box-shadow: 0.5rem 0 0 rgba(255, 255, 255, 0.2), 1rem 0 0 rgba(255, 255, 255, 0.2); } }
  @media (min-width: 720px) {
    .bumper-big-md {
      position: relative;
      height: 0.25rem;
      text-align: center;
      flex-shrink: 0;
      margin: 3rem 0;
      width: 100%; }
      .bumper-big-md::before {
        background-color: rgba(42, 61, 84, 0.1);
        border-radius: 50%;
        box-shadow: 0.5rem 0 0 rgba(42, 61, 84, 0.1), 1rem 0 0 rgba(42, 61, 84, 0.1);
        content: '';
        height: 0.25rem;
        margin-left: -0.625rem;
        position: absolute;
        width: 0.25rem; }
      .bumper-big-md.bumper_invert::before {
        background-color: rgba(255, 255, 255, 0.2);
        box-shadow: 0.5rem 0 0 rgba(255, 255, 255, 0.2), 1rem 0 0 rgba(255, 255, 255, 0.2); } }
  @media (min-width: 1024px) {
    .bumper-big-nav {
      position: relative;
      height: 0.25rem;
      text-align: center;
      flex-shrink: 0;
      margin: 3rem 0;
      width: 100%; }
      .bumper-big-nav::before {
        background-color: rgba(42, 61, 84, 0.1);
        border-radius: 50%;
        box-shadow: 0.5rem 0 0 rgba(42, 61, 84, 0.1), 1rem 0 0 rgba(42, 61, 84, 0.1);
        content: '';
        height: 0.25rem;
        margin-left: -0.625rem;
        position: absolute;
        width: 0.25rem; }
      .bumper-big-nav.bumper_invert::before {
        background-color: rgba(255, 255, 255, 0.2);
        box-shadow: 0.5rem 0 0 rgba(255, 255, 255, 0.2), 1rem 0 0 rgba(255, 255, 255, 0.2); } }
  @media (min-width: 1024px) {
    .bumper-big-lg {
      position: relative;
      height: 0.25rem;
      text-align: center;
      flex-shrink: 0;
      margin: 3rem 0;
      width: 100%; }
      .bumper-big-lg::before {
        background-color: rgba(42, 61, 84, 0.1);
        border-radius: 50%;
        box-shadow: 0.5rem 0 0 rgba(42, 61, 84, 0.1), 1rem 0 0 rgba(42, 61, 84, 0.1);
        content: '';
        height: 0.25rem;
        margin-left: -0.625rem;
        position: absolute;
        width: 0.25rem; }
      .bumper-big-lg.bumper_invert::before {
        background-color: rgba(255, 255, 255, 0.2);
        box-shadow: 0.5rem 0 0 rgba(255, 255, 255, 0.2), 1rem 0 0 rgba(255, 255, 255, 0.2); } }
  @media (min-width: 1280px) {
    .bumper-big-xl {
      position: relative;
      height: 0.25rem;
      text-align: center;
      flex-shrink: 0;
      margin: 3rem 0;
      width: 100%; }
      .bumper-big-xl::before {
        background-color: rgba(42, 61, 84, 0.1);
        border-radius: 50%;
        box-shadow: 0.5rem 0 0 rgba(42, 61, 84, 0.1), 1rem 0 0 rgba(42, 61, 84, 0.1);
        content: '';
        height: 0.25rem;
        margin-left: -0.625rem;
        position: absolute;
        width: 0.25rem; }
      .bumper-big-xl.bumper_invert::before {
        background-color: rgba(255, 255, 255, 0.2);
        box-shadow: 0.5rem 0 0 rgba(255, 255, 255, 0.2), 1rem 0 0 rgba(255, 255, 255, 0.2); } }
  @media (min-width: 1920px) {
    .bumper-big-xxl {
      position: relative;
      height: 0.25rem;
      text-align: center;
      flex-shrink: 0;
      margin: 3rem 0;
      width: 100%; }
      .bumper-big-xxl::before {
        background-color: rgba(42, 61, 84, 0.1);
        border-radius: 50%;
        box-shadow: 0.5rem 0 0 rgba(42, 61, 84, 0.1), 1rem 0 0 rgba(42, 61, 84, 0.1);
        content: '';
        height: 0.25rem;
        margin-left: -0.625rem;
        position: absolute;
        width: 0.25rem; }
      .bumper-big-xxl.bumper_invert::before {
        background-color: rgba(255, 255, 255, 0.2);
        box-shadow: 0.5rem 0 0 rgba(255, 255, 255, 0.2), 1rem 0 0 rgba(255, 255, 255, 0.2); } }

.divider {
  border: 0;
  width: 100%;
  height: 0.0625rem;
  background: rgba(42, 61, 84, 0.1);
  margin: 2rem 0; }
  @media (min-width: 0px) {
    .divider-xs {
      margin: 2rem 0; } }
  @media (min-width: 480px) {
    .divider-sm {
      margin: 2rem 0; } }
  @media (min-width: 720px) {
    .divider-md {
      margin: 2rem 0; } }
  @media (min-width: 1024px) {
    .divider-nav {
      margin: 2rem 0; } }
  @media (min-width: 1024px) {
    .divider-lg {
      margin: 2rem 0; } }
  @media (min-width: 1280px) {
    .divider-xl {
      margin: 2rem 0; } }
  @media (min-width: 1920px) {
    .divider-xxl {
      margin: 2rem 0; } }
  .divider.divider_invert {
    background: rgba(255, 255, 255, 0.2); }

.divider_small {
  margin: 1rem 0; }
  @media (min-width: 0px) {
    .divider_small-xs {
      margin: 1rem 0; } }
  @media (min-width: 480px) {
    .divider_small-sm {
      margin: 1rem 0; } }
  @media (min-width: 720px) {
    .divider_small-md {
      margin: 1rem 0; } }
  @media (min-width: 1024px) {
    .divider_small-nav {
      margin: 1rem 0; } }
  @media (min-width: 1024px) {
    .divider_small-lg {
      margin: 1rem 0; } }
  @media (min-width: 1280px) {
    .divider_small-xl {
      margin: 1rem 0; } }
  @media (min-width: 1920px) {
    .divider_small-xxl {
      margin: 1rem 0; } }

.divider_big {
  margin: 3rem 0; }
  @media (min-width: 0px) {
    .divider_big-xs {
      margin: 3rem 0; } }
  @media (min-width: 480px) {
    .divider_big-sm {
      margin: 3rem 0; } }
  @media (min-width: 720px) {
    .divider_big-md {
      margin: 3rem 0; } }
  @media (min-width: 1024px) {
    .divider_big-nav {
      margin: 3rem 0; } }
  @media (min-width: 1024px) {
    .divider_big-lg {
      margin: 3rem 0; } }
  @media (min-width: 1280px) {
    .divider_big-xl {
      margin: 3rem 0; } }
  @media (min-width: 1920px) {
    .divider_big-xxl {
      margin: 3rem 0; } }

.spacer {
  align-self: center;
  flex-shrink: 0;
  width: 100%;
  height: 2rem; }
  @media (min-width: 0px) {
    .spacer-xs {
      height: 2rem; } }
  @media (min-width: 480px) {
    .spacer-sm {
      height: 2rem; } }
  @media (min-width: 720px) {
    .spacer-md {
      height: 2rem; } }
  @media (min-width: 1024px) {
    .spacer-nav {
      height: 2rem; } }
  @media (min-width: 1024px) {
    .spacer-lg {
      height: 2rem; } }
  @media (min-width: 1280px) {
    .spacer-xl {
      height: 2rem; } }
  @media (min-width: 1920px) {
    .spacer-xxl {
      height: 2rem; } }
  .spacer-small, .spacer_small {
    height: 1rem; }
    @media (min-width: 0px) {
      .spacer-small-xs, .spacer_small-xs {
        height: 1rem; } }
    @media (min-width: 480px) {
      .spacer-small-sm, .spacer_small-sm {
        height: 1rem; } }
    @media (min-width: 720px) {
      .spacer-small-md, .spacer_small-md {
        height: 1rem; } }
    @media (min-width: 1024px) {
      .spacer-small-nav, .spacer_small-nav {
        height: 1rem; } }
    @media (min-width: 1024px) {
      .spacer-small-lg, .spacer_small-lg {
        height: 1rem; } }
    @media (min-width: 1280px) {
      .spacer-small-xl, .spacer_small-xl {
        height: 1rem; } }
    @media (min-width: 1920px) {
      .spacer-small-xxl, .spacer_small-xxl {
        height: 1rem; } }
  .spacer-big, .spacer_big {
    height: 3rem; }
    @media (min-width: 0px) {
      .spacer-big-xs, .spacer_big-xs {
        height: 3rem; } }
    @media (min-width: 480px) {
      .spacer-big-sm, .spacer_big-sm {
        height: 3rem; } }
    @media (min-width: 720px) {
      .spacer-big-md, .spacer_big-md {
        height: 3rem; } }
    @media (min-width: 1024px) {
      .spacer-big-nav, .spacer_big-nav {
        height: 3rem; } }
    @media (min-width: 1024px) {
      .spacer-big-lg, .spacer_big-lg {
        height: 3rem; } }
    @media (min-width: 1280px) {
      .spacer-big-xl, .spacer_big-xl {
        height: 3rem; } }
    @media (min-width: 1920px) {
      .spacer-big-xxl, .spacer_big-xxl {
        height: 3rem; } }

.flex-spacer,
.flex-space {
  flex-grow: 1; }

.y-space-0 {
  width: auto;
  height: 0;
  flex-shrink: 0; }
  @media (min-width: 0px) {
    .y-space-0-xs {
      width: auto;
      height: 0;
      flex-shrink: 0; } }
  @media (min-width: 480px) {
    .y-space-0-sm {
      width: auto;
      height: 0;
      flex-shrink: 0; } }
  @media (min-width: 720px) {
    .y-space-0-md {
      width: auto;
      height: 0;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .y-space-0-nav {
      width: auto;
      height: 0;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .y-space-0-lg {
      width: auto;
      height: 0;
      flex-shrink: 0; } }
  @media (min-width: 1280px) {
    .y-space-0-xl {
      width: auto;
      height: 0;
      flex-shrink: 0; } }
  @media (min-width: 1920px) {
    .y-space-0-xxl {
      width: auto;
      height: 0;
      flex-shrink: 0; } }

.y-space-1 {
  width: auto;
  height: 0.25rem;
  flex-shrink: 0; }
  @media (min-width: 0px) {
    .y-space-1-xs {
      width: auto;
      height: 0.25rem;
      flex-shrink: 0; } }
  @media (min-width: 480px) {
    .y-space-1-sm {
      width: auto;
      height: 0.25rem;
      flex-shrink: 0; } }
  @media (min-width: 720px) {
    .y-space-1-md {
      width: auto;
      height: 0.25rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .y-space-1-nav {
      width: auto;
      height: 0.25rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .y-space-1-lg {
      width: auto;
      height: 0.25rem;
      flex-shrink: 0; } }
  @media (min-width: 1280px) {
    .y-space-1-xl {
      width: auto;
      height: 0.25rem;
      flex-shrink: 0; } }
  @media (min-width: 1920px) {
    .y-space-1-xxl {
      width: auto;
      height: 0.25rem;
      flex-shrink: 0; } }

.y-space-2 {
  width: auto;
  height: 0.5rem;
  flex-shrink: 0; }
  @media (min-width: 0px) {
    .y-space-2-xs {
      width: auto;
      height: 0.5rem;
      flex-shrink: 0; } }
  @media (min-width: 480px) {
    .y-space-2-sm {
      width: auto;
      height: 0.5rem;
      flex-shrink: 0; } }
  @media (min-width: 720px) {
    .y-space-2-md {
      width: auto;
      height: 0.5rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .y-space-2-nav {
      width: auto;
      height: 0.5rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .y-space-2-lg {
      width: auto;
      height: 0.5rem;
      flex-shrink: 0; } }
  @media (min-width: 1280px) {
    .y-space-2-xl {
      width: auto;
      height: 0.5rem;
      flex-shrink: 0; } }
  @media (min-width: 1920px) {
    .y-space-2-xxl {
      width: auto;
      height: 0.5rem;
      flex-shrink: 0; } }

.y-space-3 {
  width: auto;
  height: 1rem;
  flex-shrink: 0; }
  @media (min-width: 0px) {
    .y-space-3-xs {
      width: auto;
      height: 1rem;
      flex-shrink: 0; } }
  @media (min-width: 480px) {
    .y-space-3-sm {
      width: auto;
      height: 1rem;
      flex-shrink: 0; } }
  @media (min-width: 720px) {
    .y-space-3-md {
      width: auto;
      height: 1rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .y-space-3-nav {
      width: auto;
      height: 1rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .y-space-3-lg {
      width: auto;
      height: 1rem;
      flex-shrink: 0; } }
  @media (min-width: 1280px) {
    .y-space-3-xl {
      width: auto;
      height: 1rem;
      flex-shrink: 0; } }
  @media (min-width: 1920px) {
    .y-space-3-xxl {
      width: auto;
      height: 1rem;
      flex-shrink: 0; } }

.y-space-4 {
  width: auto;
  height: 1.5rem;
  flex-shrink: 0; }
  @media (min-width: 0px) {
    .y-space-4-xs {
      width: auto;
      height: 1.5rem;
      flex-shrink: 0; } }
  @media (min-width: 480px) {
    .y-space-4-sm {
      width: auto;
      height: 1.5rem;
      flex-shrink: 0; } }
  @media (min-width: 720px) {
    .y-space-4-md {
      width: auto;
      height: 1.5rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .y-space-4-nav {
      width: auto;
      height: 1.5rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .y-space-4-lg {
      width: auto;
      height: 1.5rem;
      flex-shrink: 0; } }
  @media (min-width: 1280px) {
    .y-space-4-xl {
      width: auto;
      height: 1.5rem;
      flex-shrink: 0; } }
  @media (min-width: 1920px) {
    .y-space-4-xxl {
      width: auto;
      height: 1.5rem;
      flex-shrink: 0; } }

.y-space-5 {
  width: auto;
  height: 2rem;
  flex-shrink: 0; }
  @media (min-width: 0px) {
    .y-space-5-xs {
      width: auto;
      height: 2rem;
      flex-shrink: 0; } }
  @media (min-width: 480px) {
    .y-space-5-sm {
      width: auto;
      height: 2rem;
      flex-shrink: 0; } }
  @media (min-width: 720px) {
    .y-space-5-md {
      width: auto;
      height: 2rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .y-space-5-nav {
      width: auto;
      height: 2rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .y-space-5-lg {
      width: auto;
      height: 2rem;
      flex-shrink: 0; } }
  @media (min-width: 1280px) {
    .y-space-5-xl {
      width: auto;
      height: 2rem;
      flex-shrink: 0; } }
  @media (min-width: 1920px) {
    .y-space-5-xxl {
      width: auto;
      height: 2rem;
      flex-shrink: 0; } }

.y-space-6 {
  width: auto;
  height: 3rem;
  flex-shrink: 0; }
  @media (min-width: 0px) {
    .y-space-6-xs {
      width: auto;
      height: 3rem;
      flex-shrink: 0; } }
  @media (min-width: 480px) {
    .y-space-6-sm {
      width: auto;
      height: 3rem;
      flex-shrink: 0; } }
  @media (min-width: 720px) {
    .y-space-6-md {
      width: auto;
      height: 3rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .y-space-6-nav {
      width: auto;
      height: 3rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .y-space-6-lg {
      width: auto;
      height: 3rem;
      flex-shrink: 0; } }
  @media (min-width: 1280px) {
    .y-space-6-xl {
      width: auto;
      height: 3rem;
      flex-shrink: 0; } }
  @media (min-width: 1920px) {
    .y-space-6-xxl {
      width: auto;
      height: 3rem;
      flex-shrink: 0; } }

.y-space-7 {
  width: auto;
  height: 4rem;
  flex-shrink: 0; }
  @media (min-width: 0px) {
    .y-space-7-xs {
      width: auto;
      height: 4rem;
      flex-shrink: 0; } }
  @media (min-width: 480px) {
    .y-space-7-sm {
      width: auto;
      height: 4rem;
      flex-shrink: 0; } }
  @media (min-width: 720px) {
    .y-space-7-md {
      width: auto;
      height: 4rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .y-space-7-nav {
      width: auto;
      height: 4rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .y-space-7-lg {
      width: auto;
      height: 4rem;
      flex-shrink: 0; } }
  @media (min-width: 1280px) {
    .y-space-7-xl {
      width: auto;
      height: 4rem;
      flex-shrink: 0; } }
  @media (min-width: 1920px) {
    .y-space-7-xxl {
      width: auto;
      height: 4rem;
      flex-shrink: 0; } }

.y-space-8 {
  width: auto;
  height: 5rem;
  flex-shrink: 0; }
  @media (min-width: 0px) {
    .y-space-8-xs {
      width: auto;
      height: 5rem;
      flex-shrink: 0; } }
  @media (min-width: 480px) {
    .y-space-8-sm {
      width: auto;
      height: 5rem;
      flex-shrink: 0; } }
  @media (min-width: 720px) {
    .y-space-8-md {
      width: auto;
      height: 5rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .y-space-8-nav {
      width: auto;
      height: 5rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .y-space-8-lg {
      width: auto;
      height: 5rem;
      flex-shrink: 0; } }
  @media (min-width: 1280px) {
    .y-space-8-xl {
      width: auto;
      height: 5rem;
      flex-shrink: 0; } }
  @media (min-width: 1920px) {
    .y-space-8-xxl {
      width: auto;
      height: 5rem;
      flex-shrink: 0; } }

.x-space-0 {
  height: auto;
  width: 0;
  flex-shrink: 0; }
  @media (min-width: 0px) {
    .x-space-0-xs {
      height: auto;
      width: 0;
      flex-shrink: 0; } }
  @media (min-width: 480px) {
    .x-space-0-sm {
      height: auto;
      width: 0;
      flex-shrink: 0; } }
  @media (min-width: 720px) {
    .x-space-0-md {
      height: auto;
      width: 0;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .x-space-0-nav {
      height: auto;
      width: 0;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .x-space-0-lg {
      height: auto;
      width: 0;
      flex-shrink: 0; } }
  @media (min-width: 1280px) {
    .x-space-0-xl {
      height: auto;
      width: 0;
      flex-shrink: 0; } }
  @media (min-width: 1920px) {
    .x-space-0-xxl {
      height: auto;
      width: 0;
      flex-shrink: 0; } }

.x-space-1 {
  height: auto;
  width: 0.25rem;
  flex-shrink: 0; }
  @media (min-width: 0px) {
    .x-space-1-xs {
      height: auto;
      width: 0.25rem;
      flex-shrink: 0; } }
  @media (min-width: 480px) {
    .x-space-1-sm {
      height: auto;
      width: 0.25rem;
      flex-shrink: 0; } }
  @media (min-width: 720px) {
    .x-space-1-md {
      height: auto;
      width: 0.25rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .x-space-1-nav {
      height: auto;
      width: 0.25rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .x-space-1-lg {
      height: auto;
      width: 0.25rem;
      flex-shrink: 0; } }
  @media (min-width: 1280px) {
    .x-space-1-xl {
      height: auto;
      width: 0.25rem;
      flex-shrink: 0; } }
  @media (min-width: 1920px) {
    .x-space-1-xxl {
      height: auto;
      width: 0.25rem;
      flex-shrink: 0; } }

.x-space-2 {
  height: auto;
  width: 0.5rem;
  flex-shrink: 0; }
  @media (min-width: 0px) {
    .x-space-2-xs {
      height: auto;
      width: 0.5rem;
      flex-shrink: 0; } }
  @media (min-width: 480px) {
    .x-space-2-sm {
      height: auto;
      width: 0.5rem;
      flex-shrink: 0; } }
  @media (min-width: 720px) {
    .x-space-2-md {
      height: auto;
      width: 0.5rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .x-space-2-nav {
      height: auto;
      width: 0.5rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .x-space-2-lg {
      height: auto;
      width: 0.5rem;
      flex-shrink: 0; } }
  @media (min-width: 1280px) {
    .x-space-2-xl {
      height: auto;
      width: 0.5rem;
      flex-shrink: 0; } }
  @media (min-width: 1920px) {
    .x-space-2-xxl {
      height: auto;
      width: 0.5rem;
      flex-shrink: 0; } }

.x-space-3 {
  height: auto;
  width: 1rem;
  flex-shrink: 0; }
  @media (min-width: 0px) {
    .x-space-3-xs {
      height: auto;
      width: 1rem;
      flex-shrink: 0; } }
  @media (min-width: 480px) {
    .x-space-3-sm {
      height: auto;
      width: 1rem;
      flex-shrink: 0; } }
  @media (min-width: 720px) {
    .x-space-3-md {
      height: auto;
      width: 1rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .x-space-3-nav {
      height: auto;
      width: 1rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .x-space-3-lg {
      height: auto;
      width: 1rem;
      flex-shrink: 0; } }
  @media (min-width: 1280px) {
    .x-space-3-xl {
      height: auto;
      width: 1rem;
      flex-shrink: 0; } }
  @media (min-width: 1920px) {
    .x-space-3-xxl {
      height: auto;
      width: 1rem;
      flex-shrink: 0; } }

.x-space-4 {
  height: auto;
  width: 1.5rem;
  flex-shrink: 0; }
  @media (min-width: 0px) {
    .x-space-4-xs {
      height: auto;
      width: 1.5rem;
      flex-shrink: 0; } }
  @media (min-width: 480px) {
    .x-space-4-sm {
      height: auto;
      width: 1.5rem;
      flex-shrink: 0; } }
  @media (min-width: 720px) {
    .x-space-4-md {
      height: auto;
      width: 1.5rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .x-space-4-nav {
      height: auto;
      width: 1.5rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .x-space-4-lg {
      height: auto;
      width: 1.5rem;
      flex-shrink: 0; } }
  @media (min-width: 1280px) {
    .x-space-4-xl {
      height: auto;
      width: 1.5rem;
      flex-shrink: 0; } }
  @media (min-width: 1920px) {
    .x-space-4-xxl {
      height: auto;
      width: 1.5rem;
      flex-shrink: 0; } }

.x-space-5 {
  height: auto;
  width: 2rem;
  flex-shrink: 0; }
  @media (min-width: 0px) {
    .x-space-5-xs {
      height: auto;
      width: 2rem;
      flex-shrink: 0; } }
  @media (min-width: 480px) {
    .x-space-5-sm {
      height: auto;
      width: 2rem;
      flex-shrink: 0; } }
  @media (min-width: 720px) {
    .x-space-5-md {
      height: auto;
      width: 2rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .x-space-5-nav {
      height: auto;
      width: 2rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .x-space-5-lg {
      height: auto;
      width: 2rem;
      flex-shrink: 0; } }
  @media (min-width: 1280px) {
    .x-space-5-xl {
      height: auto;
      width: 2rem;
      flex-shrink: 0; } }
  @media (min-width: 1920px) {
    .x-space-5-xxl {
      height: auto;
      width: 2rem;
      flex-shrink: 0; } }

.x-space-6 {
  height: auto;
  width: 3rem;
  flex-shrink: 0; }
  @media (min-width: 0px) {
    .x-space-6-xs {
      height: auto;
      width: 3rem;
      flex-shrink: 0; } }
  @media (min-width: 480px) {
    .x-space-6-sm {
      height: auto;
      width: 3rem;
      flex-shrink: 0; } }
  @media (min-width: 720px) {
    .x-space-6-md {
      height: auto;
      width: 3rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .x-space-6-nav {
      height: auto;
      width: 3rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .x-space-6-lg {
      height: auto;
      width: 3rem;
      flex-shrink: 0; } }
  @media (min-width: 1280px) {
    .x-space-6-xl {
      height: auto;
      width: 3rem;
      flex-shrink: 0; } }
  @media (min-width: 1920px) {
    .x-space-6-xxl {
      height: auto;
      width: 3rem;
      flex-shrink: 0; } }

.x-space-7 {
  height: auto;
  width: 4rem;
  flex-shrink: 0; }
  @media (min-width: 0px) {
    .x-space-7-xs {
      height: auto;
      width: 4rem;
      flex-shrink: 0; } }
  @media (min-width: 480px) {
    .x-space-7-sm {
      height: auto;
      width: 4rem;
      flex-shrink: 0; } }
  @media (min-width: 720px) {
    .x-space-7-md {
      height: auto;
      width: 4rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .x-space-7-nav {
      height: auto;
      width: 4rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .x-space-7-lg {
      height: auto;
      width: 4rem;
      flex-shrink: 0; } }
  @media (min-width: 1280px) {
    .x-space-7-xl {
      height: auto;
      width: 4rem;
      flex-shrink: 0; } }
  @media (min-width: 1920px) {
    .x-space-7-xxl {
      height: auto;
      width: 4rem;
      flex-shrink: 0; } }

.x-space-8 {
  height: auto;
  width: 5rem;
  flex-shrink: 0; }
  @media (min-width: 0px) {
    .x-space-8-xs {
      height: auto;
      width: 5rem;
      flex-shrink: 0; } }
  @media (min-width: 480px) {
    .x-space-8-sm {
      height: auto;
      width: 5rem;
      flex-shrink: 0; } }
  @media (min-width: 720px) {
    .x-space-8-md {
      height: auto;
      width: 5rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .x-space-8-nav {
      height: auto;
      width: 5rem;
      flex-shrink: 0; } }
  @media (min-width: 1024px) {
    .x-space-8-lg {
      height: auto;
      width: 5rem;
      flex-shrink: 0; } }
  @media (min-width: 1280px) {
    .x-space-8-xl {
      height: auto;
      width: 5rem;
      flex-shrink: 0; } }
  @media (min-width: 1920px) {
    .x-space-8-xxl {
      height: auto;
      width: 5rem;
      flex-shrink: 0; } }

.form-group {
  background: none;
  margin: 0 0 0.5rem;
  padding: 0; }
  .form-group > label {
    display: block;
    font-size: 1em;
    margin: 0 0 0.25rem 0; }
  .form-group.is-disabled, .form-group[disabled] {
    cursor: not-allowed; }
    .form-group.is-disabled:not(.is-active), .form-group[disabled]:not(.is-active) {
      opacity: 0.4; }
      .form-group.is-disabled:not(.is-active):hover, .form-group[disabled]:not(.is-active):hover {
        opacity: 0.2; }
  .form-group[data-grid] > label {
    align-self: center;
    margin: 0; }

.form-boolean {
  background: none;
  margin: 0 0 0.5rem;
  padding: 0; }
  @supports (display: flex) {
    .form-boolean {
      display: flex;
      flex-direction: row; }
      .form-boolean label {
        order: 1; } }
  .form-boolean label {
    font-size: 1em;
    margin: 0 0 0.25rem 0; }
  .form-boolean [type='radio'],
  .form-boolean [type='checkbox'] {
    flex-shrink: 0;
    margin-right: 0.5rem; }
  .form-boolean.is-disabled, .form-boolean[disabled] {
    cursor: not-allowed; }
    .form-boolean.is-disabled:not(.is-active), .form-boolean[disabled]:not(.is-active) {
      opacity: 0.4; }
      .form-boolean.is-disabled:not(.is-active):hover, .form-boolean[disabled]:not(.is-active):hover {
        opacity: 0.2; }

.alert, .alert-small, .alert-big {
  background-color: #f7f7f7;
  color: #2a3d54;
  border-radius: 0.25rem;
  font-weight: normal;
  text-align: center; }

.alert {
  font-size: 1em;
  margin: 0 0 1rem 0;
  padding: 1rem 1.5rem; }

.alert-small {
  font-size: 0.875em;
  margin: 0 0 1rem 0;
  padding: 0.5rem 0.75rem; }

.alert-big {
  font-size: 1.125em;
  margin: 0 0 1rem 0;
  padding: 1.5rem 1.75rem; }

.alert_minor {
  background-color: rgba(47, 206, 111, 0.2);
  color: #192432; }

.alert_link {
  background-color: #2fce6f;
  color: white; }

.alert_super {
  background-color: #14a656;
  color: white; }

.alert_highlight {
  background-color: #fcff66;
  color: #080c10; }

.alert_invert {
  background-color: #fff;
  color: #2a3d54; }

.alert_info {
  background-color: #22e7e7;
  color: black; }

.alert_error {
  background-color: #e72222;
  color: white; }

.alert_success {
  background-color: #22e722;
  color: white; }

.alert_warning {
  background-color: #e7c822;
  color: black; }

.alert h1:last-child, .alert h1:only-child,
.alert h2:last-child,
.alert h2:only-child,
.alert h3:last-child,
.alert h3:only-child,
.alert h4:last-child,
.alert h4:only-child,
.alert h5:last-child,
.alert h5:only-child,
.alert h6:last-child,
.alert h6:only-child {
  margin: 0; }

.alert p:last-child, .alert p:only-child {
  margin: 0; }

.alert.alert_outlined {
  background: none;
  border-style: solid;
  border-color: #f7f7f7;
  border-width: 0.125rem; }
  .alert.alert_outlined.alert_minor {
    background: none;
    border-color: rgba(47, 206, 111, 0.2);
    color: #1d7c43; }
  .alert.alert_outlined.alert_link {
    background: none;
    border-color: #2fce6f;
    color: #1c7b42; }
  .alert.alert_outlined.alert_super {
    background: none;
    border-color: #14a656;
    color: #0f783e; }
  .alert.alert_outlined.alert_highlight {
    background: none;
    border-color: #fcff66;
    color: #646600; }
  .alert.alert_outlined.alert_invert {
    background: none;
    border-color: #fff;
    color: #2a3d54; }
  .alert.alert_outlined.alert_info {
    background: none;
    border-color: #22e7e7;
    color: #0b6565; }
  .alert.alert_outlined.alert_error {
    background: none;
    border-color: #e72222;
    color: #c11515; }
  .alert.alert_outlined.alert_success {
    background: none;
    border-color: #22e722;
    color: #15c115; }
  .alert.alert_outlined.alert_warning {
    background: none;
    border-color: #e7c822;
    color: #65570b; }

.btn-group > .btn {
  border-radius: 0;
  margin: 0;
  position: relative; }
  @supports not (display: flex) {
    .btn-group > .btn {
      float: left; } }
  .btn-group > .btn:first-child {
    border-radius: 0.25rem 0 0 0.25rem; }
    .btn-group > .btn:first-child.btn_round {
      border-radius: 1.25rem 0 0 1.25rem; }
  .btn-group > .btn:last-child {
    border-radius: 0 0.25rem 0.25rem 0; }
    .btn-group > .btn:last-child.btn_round {
      border-radius: 0 1.25rem 1.25rem 0; }
  .btn-group > .btn.btn_small {
    border-radius: 0; }
    .btn-group > .btn.btn_small:first-child {
      border-radius: 0.25rem 0 0 0.25rem; }
      .btn-group > .btn.btn_small:first-child.btn_round {
        border-radius: 1rem 0 0 1rem; }
    .btn-group > .btn.btn_small:last-child {
      border-radius: 0 0.25rem 0.25rem 0; }
      .btn-group > .btn.btn_small:last-child.btn_round {
        border-radius: 0 1rem 1rem 0; }
  .btn-group > .btn.btn_big {
    border-radius: 0; }
    .btn-group > .btn.btn_big:first-child {
      border-radius: 0.25rem 0 0 0.25rem; }
      .btn-group > .btn.btn_big:first-child.btn_round {
        border-radius: 1.5rem 0 0 1.5rem; }
    .btn-group > .btn.btn_big:last-child {
      border-radius: 0 0.25rem 0.25rem 0; }
      .btn-group > .btn.btn_big:last-child.btn_round {
        border-radius: 0 1.5rem 1.5rem 0; }
  .btn-group > .btn.btn_outlined {
    border-left-width: 0; }
    .btn-group > .btn.btn_outlined:first-child {
      border-left-width: 0.125rem; }

@supports not (display: flex) {
  .btn-group > .dropdown {
    float: left; } }

.btn-group > .dropdown > .btn {
  border-radius: 0;
  margin: 0;
  position: relative; }
  .btn-group > .dropdown > .btn.btn_outlined {
    border-left-width: 0; }

.btn-group > .dropdown:first-child > .btn {
  border-radius: 0.25rem 0 0 0.25rem; }
  .btn-group > .dropdown:first-child > .btn.btn_round {
    border-radius: 1.25rem 0 0 1.25rem; }
  .btn-group > .dropdown:first-child > .btn.btn_small {
    border-radius: 0.25rem 0 0 0.25rem; }
    .btn-group > .dropdown:first-child > .btn.btn_small.btn_round {
      border-radius: 1rem 0 0 1rem; }
  .btn-group > .dropdown:first-child > .btn.btn_big {
    border-radius: 0.25rem 0 0 0.25rem; }
    .btn-group > .dropdown:first-child > .btn.btn_big.btn_round {
      border-radius: 1.5rem 0 0 1.5rem; }
  .btn-group > .dropdown:first-child > .btn.btn_outlined {
    border-left-width: 0.125rem; }

.btn-group > .dropdown:last-child > .btn {
  border-radius: 0 0.25rem 0.25rem 0; }
  .btn-group > .dropdown:last-child > .btn.btn_round {
    border-radius: 0 1.25rem 1.25rem 0; }
  .btn-group > .dropdown:last-child > .btn.btn_small {
    border-radius: 0 0.25rem 0.25rem 0; }
    .btn-group > .dropdown:last-child > .btn.btn_small.btn_round {
      border-radius: 0 1rem 1rem 0; }
  .btn-group > .dropdown:last-child > .btn.btn_big {
    border-radius: 0 0.25rem 0.25rem 0; }
    .btn-group > .dropdown:last-child > .btn.btn_big.btn_round {
      border-radius: 0 1.5rem 1.5rem 0; }

@supports (display: flex) {
  .btn-group {
    display: flex; } }

.btn-group__divider {
  background: rgba(42, 61, 84, 0.1);
  width: 0.0625rem;
  margin-left: -0.0625rem;
  z-index: 1; }

.card {
  position: relative;
  z-index: 1;
  background: #fff;
  color: inherit;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem; }

.card {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.1), 0px 4px 5px 0px rgba(0, 0, 0, 0.08), 0px 1px 10px 0px rgba(0, 0, 0, 0.06); }

.dialog-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: none;
  overflow: hidden;
  overscroll-behavior: contain;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.2);
  z-index: 100; }
  .dialog-wrapper.is-active {
    display: flex; }
    .dialog-wrapper.is-active > .dialog {
      display: flex; }

.dialog {
  display: none;
  position: relative;
  max-height: 100%;
  flex-direction: column;
  background: #fff;
  color: #2a3d54;
  padding: 0;
  border-radius: 0.5rem;
  box-shadow: 0 0.25rem 1rem -0.25rem rgba(0, 0, 0, 0.16) , 0 0.5rem 1.5rem 0 rgba(0, 0, 0, 0.1); }
  .dialog:not([data-col]) {
    min-width: 15rem; }
    @media (min-width: 640px) {
      .dialog:not([data-col]) {
        max-width: 50%; }
        .dialog:not([data-col]) .dialog-header,
        .dialog:not([data-col]) .dialog-body,
        .dialog:not([data-col]) .dialog-footer {
          max-width: 40rem; } }
  .dialog.is-active {
    display: flex;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 100; }
  .dialog > .dialog-dismiss + .dialog-body {
    margin-top: 2.5rem; }

.dialog-dismiss {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  opacity: 0.4;
  transition: opacity 0.2s; }
  .dialog-dismiss:only-child {
    margin-left: auto; }
  .dialog-dismiss:hover {
    opacity: 1; }

.dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0;
  padding: 0.5rem; }
  .dialog-header .dialog-dismiss {
    position: relative;
    top: auto;
    right: auto; }

.dialog-body {
  flex-grow: 1;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  margin: 0;
  padding: 0.5rem; }

.dialog-footer {
  flex-shrink: 0;
  flex-grow: 0;
  margin: 0;
  padding: 0.5rem; }

.deck-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  flex-direction: column;
  overflow: hidden;
  overscroll-behavior: contain;
  background: rgba(0, 0, 0, 0.2);
  z-index: 100; }
  .deck-wrapper.is-active {
    display: flex; }
    .deck-wrapper.is-active > .deck {
      display: flex;
      align-self: flex-start; }
    .deck-wrapper.is-active > .opens-left {
      align-self: flex-start; }
    .deck-wrapper.is-active > .opens-right {
      align-self: flex-end; }

.deck {
  display: none;
  position: relative;
  height: 100%;
  flex-direction: column;
  background: #fff;
  color: #2a3d54;
  padding: 0;
  box-shadow: 0.25rem 0 1rem -0.25rem rgba(0, 0, 0, 0.16) , 0.5rem 0 1.5rem 0 rgba(0, 0, 0, 0.1); }
  .deck:not([data-col]) {
    min-width: 15rem; }
    @media (min-width: 640px) {
      .deck:not([data-col]) {
        max-width: 50%; }
        .deck:not([data-col]) .deck-header,
        .deck:not([data-col]) .deck-body,
        .deck:not([data-col]) .deck-footer {
          max-width: 40rem; } }
  .deck.is-active {
    left: 0; }
  .deck.opens-left {
    box-shadow: 0.25rem 0 1rem -0.25rem rgba(0, 0, 0, 0.16) , 0.5rem 0 1.5rem 0 rgba(0, 0, 0, 0.1); }
    .deck.opens-left.is-active {
      left: 0; }
  .deck.opens-right {
    box-shadow: -0.25rem 0 1rem -0.25rem rgba(0, 0, 0, 0.16) , -0.5rem 0 1.5rem 0 rgba(0, 0, 0, 0.1); }
    .deck.opens-right.is-active {
      left: auto;
      right: 0; }
  .deck.is-active {
    display: flex;
    position: fixed;
    top: 0;
    z-index: 100; }
  .deck > .deck-dismiss + .deck-body {
    margin-top: 2.5rem; }

.deck-dismiss {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  opacity: 0.4;
  transition: opacity 0.2s;
  color: #2a3d54; }
  .deck-dismiss:only-child {
    margin-left: auto; }
  .deck-dismiss:hover {
    opacity: 1; }

.deck-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0;
  padding: 0.5rem; }
  .deck-header .deck-dismiss {
    position: relative;
    top: auto;
    right: auto; }

.deck-body {
  flex-grow: 1;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  margin: 0;
  padding: 0.5rem; }

.deck-footer {
  flex-shrink: 0;
  flex-grow: 0;
  margin: 0;
  padding: 0.5rem; }

.toast-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  overflow: hidden;
  overscroll-behavior: contain;
  background: rgba(0, 0, 0, 0.2);
  z-index: 100; }
  .toast-wrapper.is-active {
    display: flex; }
    .toast-wrapper.is-active > .toast {
      display: flex;
      align-self: flex-start; }
    .toast-wrapper.is-active > .opens-top {
      align-self: flex-start; }
    .toast-wrapper.is-active > .opens-bottom {
      align-self: flex-end; }

.toast {
  display: none;
  position: relative;
  width: 100%;
  max-height: 100%;
  flex-direction: column;
  background: #fff;
  color: #2a3d54;
  padding: 0;
  box-shadow: 0 0.25rem 1rem -0.25rem rgba(0, 0, 0, 0.16) , 0 0.5rem 1.5rem 0 rgba(0, 0, 0, 0.1); }
  .toast.is-active {
    top: 0; }
  .toast.opens-top {
    box-shadow: 0 0.25rem 1rem -0.25rem rgba(0, 0, 0, 0.16) , 0 0.5rem 1.5rem 0 rgba(0, 0, 0, 0.1); }
    .toast.opens-top.is-active {
      top: 0; }
  .toast.opens-bottom {
    box-shadow: 0 -0.25rem 1rem -0.25rem rgba(0, 0, 0, 0.16) , 0 -0.5rem 1.5rem 0 rgba(0, 0, 0, 0.1); }
    .toast.opens-bottom.is-active {
      top: auto;
      bottom: 0; }
  .toast.is-active {
    display: flex;
    position: fixed;
    left: 0;
    z-index: 100; }
  .toast[data-col~='1'] {
    margin-left: 45.83333%; }
  .toast[data-col~='2'] {
    margin-left: 41.66667%; }
  .toast[data-col~='3'] {
    margin-left: 37.5%; }
  .toast[data-col~='4'] {
    margin-left: 33.33333%; }
  .toast[data-col~='5'] {
    margin-left: 29.16667%; }
  .toast[data-col~='6'] {
    margin-left: 25%; }
  .toast[data-col~='7'] {
    margin-left: 20.83333%; }
  .toast[data-col~='8'] {
    margin-left: 16.66667%; }
  .toast[data-col~='9'] {
    margin-left: 12.5%; }
  .toast[data-col~='10'] {
    margin-left: 8.33333%; }
  .toast[data-col~='11'] {
    margin-left: 4.16667%; }
  .toast[data-col~='12'] {
    margin-left: 0%; }

.toast-dismiss {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  opacity: 0.4;
  transition: opacity 0.2s;
  color: #2a3d54; }
  .toast-dismiss:only-child {
    margin-left: auto; }
  .toast-dismiss:hover {
    opacity: 1; }

.toast-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0;
  padding: 0.5rem; }
  .toast-header .toast-dismiss {
    position: relative;
    top: auto;
    right: auto; }

.toast-body {
  flex-grow: 1;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  margin: 0;
  padding: 0.5rem; }

.toast-footer {
  flex-shrink: 0;
  flex-grow: 0;
  margin: 0;
  padding: 0.5rem; }

.snackbar-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  overflow: hidden;
  overscroll-behavior: contain;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.2);
  z-index: 100; }
  .snackbar-wrapper.is-active {
    display: flex; }
    .snackbar-wrapper.is-active > .snackbar {
      display: flex; }
    .snackbar-wrapper.is-active > .opens-bottom {
      margin-top: auto; }

.snackbar {
  display: none;
  position: relative;
  width: 100%;
  max-height: 100%;
  flex-direction: column;
  background: rgba(42, 61, 84, 0.8);
  color: #fff;
  padding: 0;
  border-radius: 0.25rem; }
  .snackbar:not([data-col]) {
    min-width: 15rem; }
    @media (min-width: 640px) {
      .snackbar:not([data-col]) {
        max-width: 50%; }
        .snackbar:not([data-col]) .dialog-header,
        .snackbar:not([data-col]) .dialog-body,
        .snackbar:not([data-col]) .dialog-footer {
          max-width: 40rem; } }
  .snackbar.is-active {
    top: 0; }
  .snackbar.opens-top {
    box-shadow: none; }
    .snackbar.opens-top.is-active {
      top: 1rem; }
  .snackbar.opens-bottom {
    box-shadow: none; }
    .snackbar.opens-bottom.is-active {
      top: auto;
      bottom: 1rem; }
  .snackbar.is-active {
    display: flex;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100; }

.snackbar-dismiss {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  opacity: 0.4;
  transition: opacity 0.2s;
  color: #fff; }
  .snackbar-dismiss:only-child {
    margin-left: auto; }
  .snackbar-dismiss:hover {
    opacity: 1; }

.snackbar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0;
  padding: 0.5rem; }
  .snackbar-header .snackbar-dismiss {
    position: relative;
    top: auto;
    right: auto; }

.snackbar-body {
  flex-grow: 1;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  margin: 0;
  padding: 0.5rem; }

.snackbar-footer {
  flex-shrink: 0;
  flex-grow: 0;
  margin: 0;
  padding: 0.5rem; }

.xui-overlay {
  position: relative;
  background-color: #fff;
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem -0.25rem rgba(0, 0, 0, 0.16) , 0 0.25rem 1.5rem 0 rgba(0, 0, 0, 0.1);
  color: #2a3d54;
  padding: 0.25rem; }
  .xui-overlay__dismiss {
    padding-left: 0;
    padding-right: 0;
    position: absolute;
    right: 0;
    top: 0; }

@keyframes showOverlayWrapper {
  0% {
    clip-path: circle(0% at 50% 50%);
    opacity: 0; }
  100% {
    clip-path: circle(100% at 50% 50%);
    opacity: 1; } }

@keyframes showOverlay {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.xui-overlay-wrapper {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  z-index: 100; }
  .xui-overlay-wrapper.is-targeted .xui-overlay {
    animation: showOverlay 0.4s forwards ease-out;
    animation-delay: 0.4s;
    display: none;
    opacity: 0; }
  .xui-overlay-wrapper.is-targeted:target {
    animation: showOverlayWrapper 0.4s forwards ease-in-out;
    display: initial;
    opacity: 1; }
    .xui-overlay-wrapper.is-targeted:target .xui-overlay {
      display: initial; }
  .xui-overlay-wrapper.has-backdrop {
    background: rgba(0, 0, 0, 0.2); }

.overlay-wrapper.is-active .overlay {
  display: flex; }

.overlay {
  display: none;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #fff;
  color: #2a3d54;
  padding: 0;
  z-index: 100; }
  .overlay.is-active {
    display: flex; }

.overlay-dismiss {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  opacity: .4;
  transition: opacity .2s;
  color: #2a3d54; }
  .overlay-dismiss:only-child {
    margin-left: auto; }
  .overlay-dismiss:hover {
    opacity: 1; }

.overlay-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0;
  padding: 0.5rem; }
  .overlay-header .overlay-dismiss {
    position: relative;
    top: auto;
    right: auto; }

.overlay-body {
  flex-grow: 1;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  margin: 0;
  padding: 0.5rem; }

.overlay-footer {
  flex-shrink: 0;
  flex-grow: 0;
  margin: 0;
  padding: 0.5rem; }

.dropdown {
  cursor: pointer;
  outline: none;
  position: relative; }
  .dropdown.dropdown_center .dropdown-options {
    left: 50%;
    transform: translateX(-50%); }
  .dropdown.dropdown_right .dropdown-options {
    right: 0; }
  .dropdown.dropdown_left .dropdown-options {
    left: 0; }
  .dropdown.dropdown_full .dropdown-options {
    left: 0;
    width: 100%; }
  .dropdown.dropdown_above .dropdown-options {
    bottom: 100%;
    top: initial; }
  .dropdown:focus > .dropdown-options {
    opacity: 1;
    visibility: visible; }
  .dropdown.on-hover:hover .dropdown-options {
    opacity: 1;
    visibility: visible; }

.dropdown-options {
  list-style: none;
  margin: 0;
  opacity: 0;
  position: absolute;
  top: 100%;
  transition: visibility 0.5s, opacity 0.2s;
  visibility: hidden;
  z-index: 10;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  background: #fff;
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem -0.25rem rgba(0, 0, 0, 0.16) , 0 0.25rem 1.5rem 0 rgba(0, 0, 0, 0.1);
  color: #2a3d54;
  max-height: 20rem;
  padding: 0.25rem; }
  .dropdown-options .dropdown_divider {
    background-color: rgba(42, 61, 84, 0.1);
    height: 0.0625rem;
    margin: 0.25rem 0;
    padding: 0; }

.dropdown-option {
  display: block; }
  @supports (display: flex) {
    .dropdown-option {
      display: flex; } }
  .dropdown-option > a:not([class]) {
    cursor: pointer;
    border: 0;
    display: block;
    height: auto;
    outline: none;
    position: relative;
    text-decoration: none;
    touch-action: manipulation;
    user-select: none;
    width: 100%;
    padding: 0.55rem 0.825rem;
    font-size: 1rem;
    line-height: 1.4rem;
    min-height: 2.5rem;
    min-width: 2.5rem;
    color: #2a3d54;
    background: none;
    font-weight: bolder;
    margin: 0;
    text-align: center; }
    .dropdown-option > a:not([class]) .bubble:first-child,
    .dropdown-option > a:not([class]) .tag:first-child {
      margin-left: -0.55rem;
      margin-right: 0.55rem; }
    .dropdown-option > a:not([class]) .bubble:last-child,
    .dropdown-option > a:not([class]) .tag:last-child {
      margin-left: 0.55rem;
      margin-right: -0.55rem; }
    .dropdown-option > a:not([class]) .bubble:only-child,
    .dropdown-option > a:not([class]) .tag:only-child {
      margin-left: -0.825rem;
      margin-right: -0.825rem; }
    .dropdown-option > a:not([class]) .ec-icon {
      padding-right: 0.55rem; }
      @supports (display: inline-flex) {
        .dropdown-option > a:not([class]) .ec-icon {
          display: inline-flex;
          align-items: center;
          justify-content: center; } }
      .dropdown-option > a:not([class]) .ec-icon::before {
        font-size: 1em;
        line-height: inherit;
        vertical-align: top; }
      .dropdown-option > a:not([class]) .ec-icon_right {
        padding-left: 0.55rem;
        padding-right: 0; }
      .dropdown-option > a:not([class]) .ec-icon:empty {
        padding-left: 0;
        padding-right: 0; }
        .dropdown-option > a:not([class]) .ec-icon:empty:first-child {
          margin-left: -0.275rem;
          margin-right: 0.55rem; }
        .dropdown-option > a:not([class]) .ec-icon:empty:last-child {
          margin-left: 0.55rem;
          margin-right: -0.275rem; }
        .dropdown-option > a:not([class]) .ec-icon:empty:only-child {
          margin-left: -0.825rem;
          margin-right: -0.825rem; }
        .dropdown-option > a:not([class]) .ec-icon:empty::before {
          margin-left: 0;
          margin-right: 0; }
    .dropdown-option > a:not([class]):hover {
      color: #2fce6f;
      background: rgba(47, 206, 111, 0.1); }
  .dropdown-option > .btn {
    display: block;
    width: 100%; }

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%; }

.table {
  margin: 0 0 1rem 0; }
  .table thead {
    border-top: 0.0625rem solid rgba(42, 61, 84, 0.1); }
  .table td {
    border-bottom: 0.0625rem solid rgba(42, 61, 84, 0.1); }
  .table td,
  .table th {
    padding: 0.5rem; }
  .table tbody tr:first-child td {
    border-top: 0.0625rem solid rgba(42, 61, 84, 0.1); }

.table_clear thead {
  border-top: 0; }

.table_clear td {
  border-bottom: 0; }

.table_clear tbody tr:first-child td {
  border-top: 0; }

.table_bordered td,
.table_bordered th {
  border-right: 0.0625rem solid rgba(42, 61, 84, 0.1); }
  .table_bordered td:first-child,
  .table_bordered th:first-child {
    border-left: 0.0625rem solid rgba(42, 61, 84, 0.1); }

.table_bordered tbody tr:first-child td {
  border-top: 0.0625rem solid rgba(42, 61, 84, 0.1); }

.nav {
  margin: 0;
  padding: 0; }

.nav__items {
  list-style: none;
  margin: 0;
  padding: 0; }
  @supports (display: flex) {
    .nav__items {
      display: flex;
      align-items: center; } }

.nav__item {
  display: inline-block;
  padding: 0.25rem; }
  .nav__item .btn {
    margin: 0; }

.nav__divider {
  display: block;
  height: 100%;
  background-color: rgba(42, 61, 84, 0.1);
  margin: 0 0.25rem;
  width: 0.0625rem; }

@supports (display: flex) {
  .nav_stacked .nav__items {
    display: flex;
    flex-direction: column;
    align-items: stretch; } }

.nav_stacked .nav__divider {
  width: 100%;
  height: 0.0625rem;
  margin: 0.25rem 0; }

.nav_stacked .nav__item {
  display: block; }
  .nav_stacked .nav__item .btn {
    display: block;
    width: 100%; }

.well, .well-small, .well-big {
  border-radius: 0.25rem;
  font-weight: normal;
  background: #f7f7f7;
  color: inherit; }

.well {
  margin: 0 0 1rem 0;
  padding: 1rem 1.5rem; }
  .well_small {
    margin: 0 0 1rem 0;
    padding: 0.5rem 0.75rem; }
  .well_big {
    margin: 0 0 1rem 0;
    padding: 1.5rem 1.75rem; }

.well-small {
  margin: 0 0 1rem 0;
  padding: 0.5rem 0.75rem; }

.well-big {
  margin: 0 0 1rem 0;
  padding: 1.5rem 1.75rem; }

.well > p:last-child, .well > p:only-child {
  margin: 0; }

.xui-tabs {
  background: none;
  margin: 0;
  padding: 0; }
  .xui-tabs [type='radio']:nth-of-type(1):checked ~ .xui-tab:nth-of-type(1) {
    display: block; }
  .xui-tabs [type='radio']:nth-of-type(2):checked ~ .xui-tab:nth-of-type(2) {
    display: block; }
  .xui-tabs [type='radio']:nth-of-type(3):checked ~ .xui-tab:nth-of-type(3) {
    display: block; }
  .xui-tabs [type='radio']:nth-of-type(4):checked ~ .xui-tab:nth-of-type(4) {
    display: block; }
  .xui-tabs [type='radio']:nth-of-type(5):checked ~ .xui-tab:nth-of-type(5) {
    display: block; }
  .xui-tabs [type='radio']:nth-of-type(6):checked ~ .xui-tab:nth-of-type(6) {
    display: block; }
  .xui-tabs [type='radio']:nth-of-type(7):checked ~ .xui-tab:nth-of-type(7) {
    display: block; }
  .xui-tabs [type='radio']:nth-of-type(8):checked ~ .xui-tab:nth-of-type(8) {
    display: block; }
  .xui-tabs [type='radio']:nth-of-type(9):checked ~ .xui-tab:nth-of-type(9) {
    display: block; }
  .xui-tabs [type='radio']:nth-of-type(10):checked ~ .xui-tab:nth-of-type(10) {
    display: block; }

.xui-tabs-nav {
  background: none;
  margin: 0;
  padding: 0; }
  .xui-tabs-nav > ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    @supports (display: flex) {
      .xui-tabs-nav > ul {
        align-items: center;
        display: flex;
        justify-content: center; } }

.xui-tabs-nav-item {
  position: relative;
  text-align: center;
  padding: 0; }
  @supports not (display: flex) {
    .xui-tabs-nav-item {
      display: inline-block; } }
  .xui-tabs-nav-item label {
    cursor: pointer;
    display: block;
    text-align: center;
    border-radius: 0;
    background: none;
    color: #2a3d54;
    font-weight: bolder;
    padding: 0.25rem 0.5rem; }
    .xui-tabs-nav-item label:target {
      background: none;
      color: #2fce6f; }
    .xui-tabs-nav-item label:hover {
      background: none;
      color: #2fce6f; }

.xui-tab {
  display: none;
  background: none;
  margin: 0;
  padding: 0; }

.xui-accordion {
  list-style: none;
  margin: 0 -0.5rem;
  padding: 0; }

.xui-accordion-item {
  padding: 0.25rem 0;
  position: relative; }
  .xui-accordion-item__summary {
    align-items: center;
    border-radius: 0.25rem;
    color: #2fce6f;
    cursor: pointer;
    display: flex;
    font-weight: bolder;
    justify-content: space-between;
    padding: 0.5rem;
    transition: all 0.4s; }
    .xui-accordion-item__summary h1,
    .xui-accordion-item__summary h2,
    .xui-accordion-item__summary h3,
    .xui-accordion-item__summary h4,
    .xui-accordion-item__summary h5,
    .xui-accordion-item__summary h6 {
      margin: 0; }
    .xui-accordion-item__summary:hover {
      background-color: rgba(47, 206, 111, 0.1);
      color: #28c064; }
      .xui-accordion-item__summary:hover::after {
        color: #28c064; }
    .xui-accordion-item__summary::after {
      color: rgba(42, 61, 84, 0.6);
      margin: 0 0.5rem;
      transition: all 0.4s;
      content: '\e01b'; }
  .xui-accordion-item__detail {
    display: none;
    height: auto;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    padding: 0.5rem;
    transition: all 0.8s; }
  .xui-accordion-item > [type='radio']:checked + .xui-accordion-item__summary,
  .xui-accordion-item > [type='checkbox']:checked + .xui-accordion-item__summary {
    color: #28c064; }
    .xui-accordion-item > [type='radio']:checked + .xui-accordion-item__summary::after,
    .xui-accordion-item > [type='checkbox']:checked + .xui-accordion-item__summary::after {
      color: #28c064;
      content: '\e098'; }
    .xui-accordion-item > [type='radio']:checked + .xui-accordion-item__summary + .xui-accordion-item__detail,
    .xui-accordion-item > [type='checkbox']:checked + .xui-accordion-item__summary + .xui-accordion-item__detail {
      display: block;
      max-height: 800px;
      opacity: 1; }

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0; }
  .title.title_stacked {
    align-items: flex-start;
    flex-direction: column;
    margin: 0;
    padding: 0; }
  .title > .nav,
  .title > .btn {
    align-self: flex-start; }

.toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  min-height: 4rem;
  padding: 0.5rem 1rem;
  margin: 0; }
  .toolbar > .hamburger-btn {
    margin: 0; }
    .toolbar > .hamburger-btn:first-child {
      margin-right: 1rem; }
    .toolbar > .hamburger-btn:last-child {
      margin-left: 1rem; }
    .toolbar > .hamburger-btn:only-child {
      margin: 0; }
  .toolbar .nav {
    flex-grow: 0;
    margin: 0;
    padding: 0; }
    .toolbar .nav:first-child {
      margin-right: 1rem; }
    .toolbar .nav:last-child {
      margin-left: 1rem; }
    .toolbar .nav .btn {
      margin: 0; }
    .toolbar .nav:only-child {
      display: flex;
      justify-content: flex-end;
      margin: 0;
      width: 100%; }

.toolbar__title {
  flex-grow: 1; }
  .toolbar__title h1,
  .toolbar__title h2,
  .toolbar__title h3,
  .toolbar__title h4,
  .toolbar__title h5,
  .toolbar__title h6 {
    line-height: 1em;
    margin: 0;
    font-size: 1.25em; }
  .toolbar__title img {
    height: 2rem;
    width: auto; }
  .toolbar__title.toolbar__title_center h1,
  .toolbar__title.toolbar__title_center h2,
  .toolbar__title.toolbar__title_center h3,
  .toolbar__title.toolbar__title_center h4,
  .toolbar__title.toolbar__title_center h5,
  .toolbar__title.toolbar__title_center h6 {
    -webkit-font-smoothing: antialiased;
    left: 50%;
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) translateX(-50%); }
  .toolbar__title.toolbar__title_center img {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) translateX(-50%); }

.xui-list-group {
  width: 100%;
  border-radius: 0;
  padding: 0;
  margin: 0 0 1rem;
  background: none; }
  .xui-list-group__header {
    border-radius: 0;
    padding: 0;
    margin: 0 0 1rem;
    background: none;
    color: inherit; }

.xui-list {
  list-style: none;
  border-radius: 0;
  padding: 0;
  margin: 0 0 1rem;
  background: none;
  color: inherit; }

.xui-list-item {
  align-items: center;
  display: flex;
  transition: background-color 0.4s;
  border-radius: 0.25rem;
  padding: 0.5rem;
  margin: 0;
  background: none;
  color: inherit; }
  .xui-list-item h1,
  .xui-list-item h2,
  .xui-list-item h3,
  .xui-list-item h4,
  .xui-list-item h5,
  .xui-list-item h6 {
    margin: 0; }
  .xui-list-item:hover {
    background: none;
    color: inherit; }
  .xui-list-item.is-active {
    background: none;
    color: inherit; }
  .xui-list-item__start {
    margin-right: 1rem; }
  .xui-list-item__label {
    margin-right: 1rem; }
  .xui-list-item__end {
    margin-left: auto;
    margin-right: 0; }

.xui-accordion-item__summary::after {
  direction: ltr;
  display: inline-block;
  font-family: "ec-icon" !important;
  font-size: inherit;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal !important;
  font-weight: normal !important;
  letter-spacing: normal;
  line-height: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none !important;
  vertical-align: text-bottom;
  white-space: nowrap;
  word-wrap: normal; }

[data-row] {
  margin-left: -16px;
  margin-right: -16px; }
  [data-row] > [data-col] {
    float: left;
    padding-left: 16px !important;
    padding-right: 16px !important;
    position: relative; }
  [data-row]::before, [data-row]::after {
    content: '';
    display: table; }
  [data-row]::after {
    clear: both; }

[data-row~="no-gutter"] {
  margin-left: 0 !important;
  margin-right: 0 !important; }
  [data-row~="no-gutter"] > [data-col] {
    padding-left: 0 !important;
    padding-right: 0 !important; }

[data-row~="small-grid"] {
  margin-left: -8px !important;
  margin-right: -8px !important; }
  [data-row~="small-grid"] > [data-col] {
    padding-left: 8px !important;
    padding-right: 8px !important; }

[data-col~="pull-0"] {
  left: initial;
  right: initial; }

[data-col~="push-0"] {
  right: initial;
  left: initial; }

[data-col~="pull-1"] {
  left: initial;
  right: 8.33333%; }

[data-col~="push-1"] {
  right: initial;
  left: 8.33333%; }

[data-col~="pull-2"] {
  left: initial;
  right: 16.66667%; }

[data-col~="push-2"] {
  right: initial;
  left: 16.66667%; }

[data-col~="pull-3"] {
  left: initial;
  right: 25%; }

[data-col~="push-3"] {
  right: initial;
  left: 25%; }

[data-col~="pull-4"] {
  left: initial;
  right: 33.33333%; }

[data-col~="push-4"] {
  right: initial;
  left: 33.33333%; }

[data-col~="pull-5"] {
  left: initial;
  right: 41.66667%; }

[data-col~="push-5"] {
  right: initial;
  left: 41.66667%; }

[data-col~="pull-6"] {
  left: initial;
  right: 50%; }

[data-col~="push-6"] {
  right: initial;
  left: 50%; }

[data-col~="pull-7"] {
  left: initial;
  right: 58.33333%; }

[data-col~="push-7"] {
  right: initial;
  left: 58.33333%; }

[data-col~="pull-8"] {
  left: initial;
  right: 66.66667%; }

[data-col~="push-8"] {
  right: initial;
  left: 66.66667%; }

[data-col~="pull-9"] {
  left: initial;
  right: 75%; }

[data-col~="push-9"] {
  right: initial;
  left: 75%; }

[data-col~="pull-10"] {
  left: initial;
  right: 83.33333%; }

[data-col~="push-10"] {
  right: initial;
  left: 83.33333%; }

[data-col~="pull-11"] {
  left: initial;
  right: 91.66667%; }

[data-col~="push-11"] {
  right: initial;
  left: 91.66667%; }

@media (min-width: 0px) {
  [data-col~="pull-0-xs"] {
    left: initial;
    right: initial; } }

@media (min-width: 0px) {
  [data-col~="push-0-xs"] {
    right: initial;
    left: initial; } }

@media (min-width: 0px) {
  [data-col~="pull-1-xs"] {
    left: initial;
    right: 8.33333%; } }

@media (min-width: 0px) {
  [data-col~="push-1-xs"] {
    right: initial;
    left: 8.33333%; } }

@media (min-width: 0px) {
  [data-col~="pull-2-xs"] {
    left: initial;
    right: 16.66667%; } }

@media (min-width: 0px) {
  [data-col~="push-2-xs"] {
    right: initial;
    left: 16.66667%; } }

@media (min-width: 0px) {
  [data-col~="pull-3-xs"] {
    left: initial;
    right: 25%; } }

@media (min-width: 0px) {
  [data-col~="push-3-xs"] {
    right: initial;
    left: 25%; } }

@media (min-width: 0px) {
  [data-col~="pull-4-xs"] {
    left: initial;
    right: 33.33333%; } }

@media (min-width: 0px) {
  [data-col~="push-4-xs"] {
    right: initial;
    left: 33.33333%; } }

@media (min-width: 0px) {
  [data-col~="pull-5-xs"] {
    left: initial;
    right: 41.66667%; } }

@media (min-width: 0px) {
  [data-col~="push-5-xs"] {
    right: initial;
    left: 41.66667%; } }

@media (min-width: 0px) {
  [data-col~="pull-6-xs"] {
    left: initial;
    right: 50%; } }

@media (min-width: 0px) {
  [data-col~="push-6-xs"] {
    right: initial;
    left: 50%; } }

@media (min-width: 0px) {
  [data-col~="pull-7-xs"] {
    left: initial;
    right: 58.33333%; } }

@media (min-width: 0px) {
  [data-col~="push-7-xs"] {
    right: initial;
    left: 58.33333%; } }

@media (min-width: 0px) {
  [data-col~="pull-8-xs"] {
    left: initial;
    right: 66.66667%; } }

@media (min-width: 0px) {
  [data-col~="push-8-xs"] {
    right: initial;
    left: 66.66667%; } }

@media (min-width: 0px) {
  [data-col~="pull-9-xs"] {
    left: initial;
    right: 75%; } }

@media (min-width: 0px) {
  [data-col~="push-9-xs"] {
    right: initial;
    left: 75%; } }

@media (min-width: 0px) {
  [data-col~="pull-10-xs"] {
    left: initial;
    right: 83.33333%; } }

@media (min-width: 0px) {
  [data-col~="push-10-xs"] {
    right: initial;
    left: 83.33333%; } }

@media (min-width: 0px) {
  [data-col~="pull-11-xs"] {
    left: initial;
    right: 91.66667%; } }

@media (min-width: 0px) {
  [data-col~="push-11-xs"] {
    right: initial;
    left: 91.66667%; } }

@media (min-width: 480px) {
  [data-col~="pull-0-sm"] {
    left: initial;
    right: initial; } }

@media (min-width: 480px) {
  [data-col~="push-0-sm"] {
    right: initial;
    left: initial; } }

@media (min-width: 480px) {
  [data-col~="pull-1-sm"] {
    left: initial;
    right: 8.33333%; } }

@media (min-width: 480px) {
  [data-col~="push-1-sm"] {
    right: initial;
    left: 8.33333%; } }

@media (min-width: 480px) {
  [data-col~="pull-2-sm"] {
    left: initial;
    right: 16.66667%; } }

@media (min-width: 480px) {
  [data-col~="push-2-sm"] {
    right: initial;
    left: 16.66667%; } }

@media (min-width: 480px) {
  [data-col~="pull-3-sm"] {
    left: initial;
    right: 25%; } }

@media (min-width: 480px) {
  [data-col~="push-3-sm"] {
    right: initial;
    left: 25%; } }

@media (min-width: 480px) {
  [data-col~="pull-4-sm"] {
    left: initial;
    right: 33.33333%; } }

@media (min-width: 480px) {
  [data-col~="push-4-sm"] {
    right: initial;
    left: 33.33333%; } }

@media (min-width: 480px) {
  [data-col~="pull-5-sm"] {
    left: initial;
    right: 41.66667%; } }

@media (min-width: 480px) {
  [data-col~="push-5-sm"] {
    right: initial;
    left: 41.66667%; } }

@media (min-width: 480px) {
  [data-col~="pull-6-sm"] {
    left: initial;
    right: 50%; } }

@media (min-width: 480px) {
  [data-col~="push-6-sm"] {
    right: initial;
    left: 50%; } }

@media (min-width: 480px) {
  [data-col~="pull-7-sm"] {
    left: initial;
    right: 58.33333%; } }

@media (min-width: 480px) {
  [data-col~="push-7-sm"] {
    right: initial;
    left: 58.33333%; } }

@media (min-width: 480px) {
  [data-col~="pull-8-sm"] {
    left: initial;
    right: 66.66667%; } }

@media (min-width: 480px) {
  [data-col~="push-8-sm"] {
    right: initial;
    left: 66.66667%; } }

@media (min-width: 480px) {
  [data-col~="pull-9-sm"] {
    left: initial;
    right: 75%; } }

@media (min-width: 480px) {
  [data-col~="push-9-sm"] {
    right: initial;
    left: 75%; } }

@media (min-width: 480px) {
  [data-col~="pull-10-sm"] {
    left: initial;
    right: 83.33333%; } }

@media (min-width: 480px) {
  [data-col~="push-10-sm"] {
    right: initial;
    left: 83.33333%; } }

@media (min-width: 480px) {
  [data-col~="pull-11-sm"] {
    left: initial;
    right: 91.66667%; } }

@media (min-width: 480px) {
  [data-col~="push-11-sm"] {
    right: initial;
    left: 91.66667%; } }

@media (min-width: 720px) {
  [data-col~="pull-0-md"] {
    left: initial;
    right: initial; } }

@media (min-width: 720px) {
  [data-col~="push-0-md"] {
    right: initial;
    left: initial; } }

@media (min-width: 720px) {
  [data-col~="pull-1-md"] {
    left: initial;
    right: 8.33333%; } }

@media (min-width: 720px) {
  [data-col~="push-1-md"] {
    right: initial;
    left: 8.33333%; } }

@media (min-width: 720px) {
  [data-col~="pull-2-md"] {
    left: initial;
    right: 16.66667%; } }

@media (min-width: 720px) {
  [data-col~="push-2-md"] {
    right: initial;
    left: 16.66667%; } }

@media (min-width: 720px) {
  [data-col~="pull-3-md"] {
    left: initial;
    right: 25%; } }

@media (min-width: 720px) {
  [data-col~="push-3-md"] {
    right: initial;
    left: 25%; } }

@media (min-width: 720px) {
  [data-col~="pull-4-md"] {
    left: initial;
    right: 33.33333%; } }

@media (min-width: 720px) {
  [data-col~="push-4-md"] {
    right: initial;
    left: 33.33333%; } }

@media (min-width: 720px) {
  [data-col~="pull-5-md"] {
    left: initial;
    right: 41.66667%; } }

@media (min-width: 720px) {
  [data-col~="push-5-md"] {
    right: initial;
    left: 41.66667%; } }

@media (min-width: 720px) {
  [data-col~="pull-6-md"] {
    left: initial;
    right: 50%; } }

@media (min-width: 720px) {
  [data-col~="push-6-md"] {
    right: initial;
    left: 50%; } }

@media (min-width: 720px) {
  [data-col~="pull-7-md"] {
    left: initial;
    right: 58.33333%; } }

@media (min-width: 720px) {
  [data-col~="push-7-md"] {
    right: initial;
    left: 58.33333%; } }

@media (min-width: 720px) {
  [data-col~="pull-8-md"] {
    left: initial;
    right: 66.66667%; } }

@media (min-width: 720px) {
  [data-col~="push-8-md"] {
    right: initial;
    left: 66.66667%; } }

@media (min-width: 720px) {
  [data-col~="pull-9-md"] {
    left: initial;
    right: 75%; } }

@media (min-width: 720px) {
  [data-col~="push-9-md"] {
    right: initial;
    left: 75%; } }

@media (min-width: 720px) {
  [data-col~="pull-10-md"] {
    left: initial;
    right: 83.33333%; } }

@media (min-width: 720px) {
  [data-col~="push-10-md"] {
    right: initial;
    left: 83.33333%; } }

@media (min-width: 720px) {
  [data-col~="pull-11-md"] {
    left: initial;
    right: 91.66667%; } }

@media (min-width: 720px) {
  [data-col~="push-11-md"] {
    right: initial;
    left: 91.66667%; } }

@media (min-width: 1024px) {
  [data-col~="pull-0-nav"] {
    left: initial;
    right: initial; } }

@media (min-width: 1024px) {
  [data-col~="push-0-nav"] {
    right: initial;
    left: initial; } }

@media (min-width: 1024px) {
  [data-col~="pull-1-nav"] {
    left: initial;
    right: 8.33333%; } }

@media (min-width: 1024px) {
  [data-col~="push-1-nav"] {
    right: initial;
    left: 8.33333%; } }

@media (min-width: 1024px) {
  [data-col~="pull-2-nav"] {
    left: initial;
    right: 16.66667%; } }

@media (min-width: 1024px) {
  [data-col~="push-2-nav"] {
    right: initial;
    left: 16.66667%; } }

@media (min-width: 1024px) {
  [data-col~="pull-3-nav"] {
    left: initial;
    right: 25%; } }

@media (min-width: 1024px) {
  [data-col~="push-3-nav"] {
    right: initial;
    left: 25%; } }

@media (min-width: 1024px) {
  [data-col~="pull-4-nav"] {
    left: initial;
    right: 33.33333%; } }

@media (min-width: 1024px) {
  [data-col~="push-4-nav"] {
    right: initial;
    left: 33.33333%; } }

@media (min-width: 1024px) {
  [data-col~="pull-5-nav"] {
    left: initial;
    right: 41.66667%; } }

@media (min-width: 1024px) {
  [data-col~="push-5-nav"] {
    right: initial;
    left: 41.66667%; } }

@media (min-width: 1024px) {
  [data-col~="pull-6-nav"] {
    left: initial;
    right: 50%; } }

@media (min-width: 1024px) {
  [data-col~="push-6-nav"] {
    right: initial;
    left: 50%; } }

@media (min-width: 1024px) {
  [data-col~="pull-7-nav"] {
    left: initial;
    right: 58.33333%; } }

@media (min-width: 1024px) {
  [data-col~="push-7-nav"] {
    right: initial;
    left: 58.33333%; } }

@media (min-width: 1024px) {
  [data-col~="pull-8-nav"] {
    left: initial;
    right: 66.66667%; } }

@media (min-width: 1024px) {
  [data-col~="push-8-nav"] {
    right: initial;
    left: 66.66667%; } }

@media (min-width: 1024px) {
  [data-col~="pull-9-nav"] {
    left: initial;
    right: 75%; } }

@media (min-width: 1024px) {
  [data-col~="push-9-nav"] {
    right: initial;
    left: 75%; } }

@media (min-width: 1024px) {
  [data-col~="pull-10-nav"] {
    left: initial;
    right: 83.33333%; } }

@media (min-width: 1024px) {
  [data-col~="push-10-nav"] {
    right: initial;
    left: 83.33333%; } }

@media (min-width: 1024px) {
  [data-col~="pull-11-nav"] {
    left: initial;
    right: 91.66667%; } }

@media (min-width: 1024px) {
  [data-col~="push-11-nav"] {
    right: initial;
    left: 91.66667%; } }

@media (min-width: 1024px) {
  [data-col~="pull-0-lg"] {
    left: initial;
    right: initial; } }

@media (min-width: 1024px) {
  [data-col~="push-0-lg"] {
    right: initial;
    left: initial; } }

@media (min-width: 1024px) {
  [data-col~="pull-1-lg"] {
    left: initial;
    right: 8.33333%; } }

@media (min-width: 1024px) {
  [data-col~="push-1-lg"] {
    right: initial;
    left: 8.33333%; } }

@media (min-width: 1024px) {
  [data-col~="pull-2-lg"] {
    left: initial;
    right: 16.66667%; } }

@media (min-width: 1024px) {
  [data-col~="push-2-lg"] {
    right: initial;
    left: 16.66667%; } }

@media (min-width: 1024px) {
  [data-col~="pull-3-lg"] {
    left: initial;
    right: 25%; } }

@media (min-width: 1024px) {
  [data-col~="push-3-lg"] {
    right: initial;
    left: 25%; } }

@media (min-width: 1024px) {
  [data-col~="pull-4-lg"] {
    left: initial;
    right: 33.33333%; } }

@media (min-width: 1024px) {
  [data-col~="push-4-lg"] {
    right: initial;
    left: 33.33333%; } }

@media (min-width: 1024px) {
  [data-col~="pull-5-lg"] {
    left: initial;
    right: 41.66667%; } }

@media (min-width: 1024px) {
  [data-col~="push-5-lg"] {
    right: initial;
    left: 41.66667%; } }

@media (min-width: 1024px) {
  [data-col~="pull-6-lg"] {
    left: initial;
    right: 50%; } }

@media (min-width: 1024px) {
  [data-col~="push-6-lg"] {
    right: initial;
    left: 50%; } }

@media (min-width: 1024px) {
  [data-col~="pull-7-lg"] {
    left: initial;
    right: 58.33333%; } }

@media (min-width: 1024px) {
  [data-col~="push-7-lg"] {
    right: initial;
    left: 58.33333%; } }

@media (min-width: 1024px) {
  [data-col~="pull-8-lg"] {
    left: initial;
    right: 66.66667%; } }

@media (min-width: 1024px) {
  [data-col~="push-8-lg"] {
    right: initial;
    left: 66.66667%; } }

@media (min-width: 1024px) {
  [data-col~="pull-9-lg"] {
    left: initial;
    right: 75%; } }

@media (min-width: 1024px) {
  [data-col~="push-9-lg"] {
    right: initial;
    left: 75%; } }

@media (min-width: 1024px) {
  [data-col~="pull-10-lg"] {
    left: initial;
    right: 83.33333%; } }

@media (min-width: 1024px) {
  [data-col~="push-10-lg"] {
    right: initial;
    left: 83.33333%; } }

@media (min-width: 1024px) {
  [data-col~="pull-11-lg"] {
    left: initial;
    right: 91.66667%; } }

@media (min-width: 1024px) {
  [data-col~="push-11-lg"] {
    right: initial;
    left: 91.66667%; } }

@media (min-width: 1280px) {
  [data-col~="pull-0-xl"] {
    left: initial;
    right: initial; } }

@media (min-width: 1280px) {
  [data-col~="push-0-xl"] {
    right: initial;
    left: initial; } }

@media (min-width: 1280px) {
  [data-col~="pull-1-xl"] {
    left: initial;
    right: 8.33333%; } }

@media (min-width: 1280px) {
  [data-col~="push-1-xl"] {
    right: initial;
    left: 8.33333%; } }

@media (min-width: 1280px) {
  [data-col~="pull-2-xl"] {
    left: initial;
    right: 16.66667%; } }

@media (min-width: 1280px) {
  [data-col~="push-2-xl"] {
    right: initial;
    left: 16.66667%; } }

@media (min-width: 1280px) {
  [data-col~="pull-3-xl"] {
    left: initial;
    right: 25%; } }

@media (min-width: 1280px) {
  [data-col~="push-3-xl"] {
    right: initial;
    left: 25%; } }

@media (min-width: 1280px) {
  [data-col~="pull-4-xl"] {
    left: initial;
    right: 33.33333%; } }

@media (min-width: 1280px) {
  [data-col~="push-4-xl"] {
    right: initial;
    left: 33.33333%; } }

@media (min-width: 1280px) {
  [data-col~="pull-5-xl"] {
    left: initial;
    right: 41.66667%; } }

@media (min-width: 1280px) {
  [data-col~="push-5-xl"] {
    right: initial;
    left: 41.66667%; } }

@media (min-width: 1280px) {
  [data-col~="pull-6-xl"] {
    left: initial;
    right: 50%; } }

@media (min-width: 1280px) {
  [data-col~="push-6-xl"] {
    right: initial;
    left: 50%; } }

@media (min-width: 1280px) {
  [data-col~="pull-7-xl"] {
    left: initial;
    right: 58.33333%; } }

@media (min-width: 1280px) {
  [data-col~="push-7-xl"] {
    right: initial;
    left: 58.33333%; } }

@media (min-width: 1280px) {
  [data-col~="pull-8-xl"] {
    left: initial;
    right: 66.66667%; } }

@media (min-width: 1280px) {
  [data-col~="push-8-xl"] {
    right: initial;
    left: 66.66667%; } }

@media (min-width: 1280px) {
  [data-col~="pull-9-xl"] {
    left: initial;
    right: 75%; } }

@media (min-width: 1280px) {
  [data-col~="push-9-xl"] {
    right: initial;
    left: 75%; } }

@media (min-width: 1280px) {
  [data-col~="pull-10-xl"] {
    left: initial;
    right: 83.33333%; } }

@media (min-width: 1280px) {
  [data-col~="push-10-xl"] {
    right: initial;
    left: 83.33333%; } }

@media (min-width: 1280px) {
  [data-col~="pull-11-xl"] {
    left: initial;
    right: 91.66667%; } }

@media (min-width: 1280px) {
  [data-col~="push-11-xl"] {
    right: initial;
    left: 91.66667%; } }

@media (min-width: 1920px) {
  [data-col~="pull-0-xxl"] {
    left: initial;
    right: initial; } }

@media (min-width: 1920px) {
  [data-col~="push-0-xxl"] {
    right: initial;
    left: initial; } }

@media (min-width: 1920px) {
  [data-col~="pull-1-xxl"] {
    left: initial;
    right: 8.33333%; } }

@media (min-width: 1920px) {
  [data-col~="push-1-xxl"] {
    right: initial;
    left: 8.33333%; } }

@media (min-width: 1920px) {
  [data-col~="pull-2-xxl"] {
    left: initial;
    right: 16.66667%; } }

@media (min-width: 1920px) {
  [data-col~="push-2-xxl"] {
    right: initial;
    left: 16.66667%; } }

@media (min-width: 1920px) {
  [data-col~="pull-3-xxl"] {
    left: initial;
    right: 25%; } }

@media (min-width: 1920px) {
  [data-col~="push-3-xxl"] {
    right: initial;
    left: 25%; } }

@media (min-width: 1920px) {
  [data-col~="pull-4-xxl"] {
    left: initial;
    right: 33.33333%; } }

@media (min-width: 1920px) {
  [data-col~="push-4-xxl"] {
    right: initial;
    left: 33.33333%; } }

@media (min-width: 1920px) {
  [data-col~="pull-5-xxl"] {
    left: initial;
    right: 41.66667%; } }

@media (min-width: 1920px) {
  [data-col~="push-5-xxl"] {
    right: initial;
    left: 41.66667%; } }

@media (min-width: 1920px) {
  [data-col~="pull-6-xxl"] {
    left: initial;
    right: 50%; } }

@media (min-width: 1920px) {
  [data-col~="push-6-xxl"] {
    right: initial;
    left: 50%; } }

@media (min-width: 1920px) {
  [data-col~="pull-7-xxl"] {
    left: initial;
    right: 58.33333%; } }

@media (min-width: 1920px) {
  [data-col~="push-7-xxl"] {
    right: initial;
    left: 58.33333%; } }

@media (min-width: 1920px) {
  [data-col~="pull-8-xxl"] {
    left: initial;
    right: 66.66667%; } }

@media (min-width: 1920px) {
  [data-col~="push-8-xxl"] {
    right: initial;
    left: 66.66667%; } }

@media (min-width: 1920px) {
  [data-col~="pull-9-xxl"] {
    left: initial;
    right: 75%; } }

@media (min-width: 1920px) {
  [data-col~="push-9-xxl"] {
    right: initial;
    left: 75%; } }

@media (min-width: 1920px) {
  [data-col~="pull-10-xxl"] {
    left: initial;
    right: 83.33333%; } }

@media (min-width: 1920px) {
  [data-col~="push-10-xxl"] {
    right: initial;
    left: 83.33333%; } }

@media (min-width: 1920px) {
  [data-col~="pull-11-xxl"] {
    left: initial;
    right: 91.66667%; } }

@media (min-width: 1920px) {
  [data-col~="push-11-xxl"] {
    right: initial;
    left: 91.66667%; } }

[data-container] {
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1200px;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%; }

[data-container~='is-left'] {
  margin: 0 auto 0 0; }

@media (min-width: 0px) {
  [data-container~='is-left-xs'] {
    margin: 0 auto 0 0; } }

@media (min-width: 480px) {
  [data-container~='is-left-sm'] {
    margin: 0 auto 0 0; } }

@media (min-width: 720px) {
  [data-container~='is-left-md'] {
    margin: 0 auto 0 0; } }

@media (min-width: 1024px) {
  [data-container~='is-left-nav'] {
    margin: 0 auto 0 0; } }

@media (min-width: 1024px) {
  [data-container~='is-left-lg'] {
    margin: 0 auto 0 0; } }

@media (min-width: 1280px) {
  [data-container~='is-left-xl'] {
    margin: 0 auto 0 0; } }

@media (min-width: 1920px) {
  [data-container~='is-left-xxl'] {
    margin: 0 auto 0 0; } }

[data-container~='is-right'] {
  margin: 0 0 0 auto; }

@media (min-width: 0px) {
  [data-container~='is-right-xs'] {
    margin: 0 0 0 auto; } }

@media (min-width: 480px) {
  [data-container~='is-right-sm'] {
    margin: 0 0 0 auto; } }

@media (min-width: 720px) {
  [data-container~='is-right-md'] {
    margin: 0 0 0 auto; } }

@media (min-width: 1024px) {
  [data-container~='is-right-nav'] {
    margin: 0 0 0 auto; } }

@media (min-width: 1024px) {
  [data-container~='is-right-lg'] {
    margin: 0 0 0 auto; } }

@media (min-width: 1280px) {
  [data-container~='is-right-xl'] {
    margin: 0 0 0 auto; } }

@media (min-width: 1920px) {
  [data-container~='is-right-xxl'] {
    margin: 0 0 0 auto; } }

[data-grid] {
  display: flex;
  flex-wrap: wrap;
  margin-left: -16px !important;
  margin-right: -16px !important; }
  ul[data-grid] {
    list-style: none;
    margin: 0;
    padding: 0; }
  [data-grid] > [data-col] {
    float: initial;
    padding-left: 16px !important;
    padding-right: 16px !important; }

[data-grid~='is-default'] {
  margin-left: -16px !important;
  margin-right: -16px !important; }
  [data-grid~='is-default'] > [data-col] {
    padding-left: 16px !important;
    padding-right: 16px !important; }

@media (min-width: 0px) {
  [data-grid~='is-default-xs'] {
    margin-left: -16px !important;
    margin-right: -16px !important; }
    [data-grid~='is-default-xs'] > [data-col] {
      padding-left: 16px !important;
      padding-right: 16px !important; } }

@media (min-width: 480px) {
  [data-grid~='is-default-sm'] {
    margin-left: -16px !important;
    margin-right: -16px !important; }
    [data-grid~='is-default-sm'] > [data-col] {
      padding-left: 16px !important;
      padding-right: 16px !important; } }

@media (min-width: 720px) {
  [data-grid~='is-default-md'] {
    margin-left: -16px !important;
    margin-right: -16px !important; }
    [data-grid~='is-default-md'] > [data-col] {
      padding-left: 16px !important;
      padding-right: 16px !important; } }

@media (min-width: 1024px) {
  [data-grid~='is-default-nav'] {
    margin-left: -16px !important;
    margin-right: -16px !important; }
    [data-grid~='is-default-nav'] > [data-col] {
      padding-left: 16px !important;
      padding-right: 16px !important; } }

@media (min-width: 1024px) {
  [data-grid~='is-default-lg'] {
    margin-left: -16px !important;
    margin-right: -16px !important; }
    [data-grid~='is-default-lg'] > [data-col] {
      padding-left: 16px !important;
      padding-right: 16px !important; } }

@media (min-width: 1280px) {
  [data-grid~='is-default-xl'] {
    margin-left: -16px !important;
    margin-right: -16px !important; }
    [data-grid~='is-default-xl'] > [data-col] {
      padding-left: 16px !important;
      padding-right: 16px !important; } }

@media (min-width: 1920px) {
  [data-grid~='is-default-xxl'] {
    margin-left: -16px !important;
    margin-right: -16px !important; }
    [data-grid~='is-default-xxl'] > [data-col] {
      padding-left: 16px !important;
      padding-right: 16px !important; } }

[data-grid~='is-small'] {
  margin-left: -8px !important;
  margin-right: -8px !important; }
  [data-grid~='is-small'] > [data-col] {
    padding-left: 8px !important;
    padding-right: 8px !important; }

@media (min-width: 0px) {
  [data-grid~='is-small-xs'] {
    margin-left: -8px !important;
    margin-right: -8px !important; }
    [data-grid~='is-small-xs'] > [data-col] {
      padding-left: 8px !important;
      padding-right: 8px !important; } }

@media (min-width: 480px) {
  [data-grid~='is-small-sm'] {
    margin-left: -8px !important;
    margin-right: -8px !important; }
    [data-grid~='is-small-sm'] > [data-col] {
      padding-left: 8px !important;
      padding-right: 8px !important; } }

@media (min-width: 720px) {
  [data-grid~='is-small-md'] {
    margin-left: -8px !important;
    margin-right: -8px !important; }
    [data-grid~='is-small-md'] > [data-col] {
      padding-left: 8px !important;
      padding-right: 8px !important; } }

@media (min-width: 1024px) {
  [data-grid~='is-small-nav'] {
    margin-left: -8px !important;
    margin-right: -8px !important; }
    [data-grid~='is-small-nav'] > [data-col] {
      padding-left: 8px !important;
      padding-right: 8px !important; } }

@media (min-width: 1024px) {
  [data-grid~='is-small-lg'] {
    margin-left: -8px !important;
    margin-right: -8px !important; }
    [data-grid~='is-small-lg'] > [data-col] {
      padding-left: 8px !important;
      padding-right: 8px !important; } }

@media (min-width: 1280px) {
  [data-grid~='is-small-xl'] {
    margin-left: -8px !important;
    margin-right: -8px !important; }
    [data-grid~='is-small-xl'] > [data-col] {
      padding-left: 8px !important;
      padding-right: 8px !important; } }

@media (min-width: 1920px) {
  [data-grid~='is-small-xxl'] {
    margin-left: -8px !important;
    margin-right: -8px !important; }
    [data-grid~='is-small-xxl'] > [data-col] {
      padding-left: 8px !important;
      padding-right: 8px !important; } }

[data-grid~='no-gutter'] {
  margin-left: 0 !important;
  margin-right: 0 !important; }
  [data-grid~='no-gutter'] > [data-col] {
    padding-left: 0 !important;
    padding-right: 0 !important; }

@media (min-width: 0px) {
  [data-grid~='no-gutter-xs'] {
    margin-left: 0 !important;
    margin-right: 0 !important; }
    [data-grid~='no-gutter-xs'] > [data-col] {
      padding-left: 0 !important;
      padding-right: 0 !important; } }

@media (min-width: 480px) {
  [data-grid~='no-gutter-sm'] {
    margin-left: 0 !important;
    margin-right: 0 !important; }
    [data-grid~='no-gutter-sm'] > [data-col] {
      padding-left: 0 !important;
      padding-right: 0 !important; } }

@media (min-width: 720px) {
  [data-grid~='no-gutter-md'] {
    margin-left: 0 !important;
    margin-right: 0 !important; }
    [data-grid~='no-gutter-md'] > [data-col] {
      padding-left: 0 !important;
      padding-right: 0 !important; } }

@media (min-width: 1024px) {
  [data-grid~='no-gutter-nav'] {
    margin-left: 0 !important;
    margin-right: 0 !important; }
    [data-grid~='no-gutter-nav'] > [data-col] {
      padding-left: 0 !important;
      padding-right: 0 !important; } }

@media (min-width: 1024px) {
  [data-grid~='no-gutter-lg'] {
    margin-left: 0 !important;
    margin-right: 0 !important; }
    [data-grid~='no-gutter-lg'] > [data-col] {
      padding-left: 0 !important;
      padding-right: 0 !important; } }

@media (min-width: 1280px) {
  [data-grid~='no-gutter-xl'] {
    margin-left: 0 !important;
    margin-right: 0 !important; }
    [data-grid~='no-gutter-xl'] > [data-col] {
      padding-left: 0 !important;
      padding-right: 0 !important; } }

@media (min-width: 1920px) {
  [data-grid~='no-gutter-xxl'] {
    margin-left: 0 !important;
    margin-right: 0 !important; }
    [data-grid~='no-gutter-xxl'] > [data-col] {
      padding-left: 0 !important;
      padding-right: 0 !important; } }

[data-grid~='is-center'] {
  justify-content: center; }

@media (min-width: 0px) {
  [data-grid~='is-center-xs'] {
    justify-content: center; } }

@media (min-width: 480px) {
  [data-grid~='is-center-sm'] {
    justify-content: center; } }

@media (min-width: 720px) {
  [data-grid~='is-center-md'] {
    justify-content: center; } }

@media (min-width: 1024px) {
  [data-grid~='is-center-nav'] {
    justify-content: center; } }

@media (min-width: 1024px) {
  [data-grid~='is-center-lg'] {
    justify-content: center; } }

@media (min-width: 1280px) {
  [data-grid~='is-center-xl'] {
    justify-content: center; } }

@media (min-width: 1920px) {
  [data-grid~='is-center-xxl'] {
    justify-content: center; } }

[data-grid~='is-start'] {
  justify-content: flex-start; }

@media (min-width: 0px) {
  [data-grid~='is-start-xs'] {
    justify-content: flex-start; } }

@media (min-width: 480px) {
  [data-grid~='is-start-sm'] {
    justify-content: flex-start; } }

@media (min-width: 720px) {
  [data-grid~='is-start-md'] {
    justify-content: flex-start; } }

@media (min-width: 1024px) {
  [data-grid~='is-start-nav'] {
    justify-content: flex-start; } }

@media (min-width: 1024px) {
  [data-grid~='is-start-lg'] {
    justify-content: flex-start; } }

@media (min-width: 1280px) {
  [data-grid~='is-start-xl'] {
    justify-content: flex-start; } }

@media (min-width: 1920px) {
  [data-grid~='is-start-xxl'] {
    justify-content: flex-start; } }

[data-grid~='is-end'] {
  justify-content: flex-end; }

@media (min-width: 0px) {
  [data-grid~='is-end-xs'] {
    justify-content: flex-end; } }

@media (min-width: 480px) {
  [data-grid~='is-end-sm'] {
    justify-content: flex-end; } }

@media (min-width: 720px) {
  [data-grid~='is-end-md'] {
    justify-content: flex-end; } }

@media (min-width: 1024px) {
  [data-grid~='is-end-nav'] {
    justify-content: flex-end; } }

@media (min-width: 1024px) {
  [data-grid~='is-end-lg'] {
    justify-content: flex-end; } }

@media (min-width: 1280px) {
  [data-grid~='is-end-xl'] {
    justify-content: flex-end; } }

@media (min-width: 1920px) {
  [data-grid~='is-end-xxl'] {
    justify-content: flex-end; } }

[data-col] {
  min-height: 1px;
  width: 100%; }

[data-col~='auto'] {
  width: auto;
  flex-grow: 1; }

[data-col~='1'] {
  max-width: 8.33333%; }

[data-col~='2'] {
  max-width: 16.66667%; }

[data-col~='3'] {
  max-width: 25%; }

[data-col~='4'] {
  max-width: 33.33333%; }

[data-col~='5'] {
  max-width: 41.66667%; }

[data-col~='6'] {
  max-width: 50%; }

[data-col~='7'] {
  max-width: 58.33333%; }

[data-col~='8'] {
  max-width: 66.66667%; }

[data-col~='9'] {
  max-width: 75%; }

[data-col~='10'] {
  max-width: 83.33333%; }

[data-col~='11'] {
  max-width: 91.66667%; }

[data-col~='12'] {
  max-width: 100%; }

@media (min-width: 0px) {
  [data-col~='1-xs'] {
    max-width: 8.33333%; } }

@media (min-width: 0px) {
  [data-col~='2-xs'] {
    max-width: 16.66667%; } }

@media (min-width: 0px) {
  [data-col~='3-xs'] {
    max-width: 25%; } }

@media (min-width: 0px) {
  [data-col~='4-xs'] {
    max-width: 33.33333%; } }

@media (min-width: 0px) {
  [data-col~='5-xs'] {
    max-width: 41.66667%; } }

@media (min-width: 0px) {
  [data-col~='6-xs'] {
    max-width: 50%; } }

@media (min-width: 0px) {
  [data-col~='7-xs'] {
    max-width: 58.33333%; } }

@media (min-width: 0px) {
  [data-col~='8-xs'] {
    max-width: 66.66667%; } }

@media (min-width: 0px) {
  [data-col~='9-xs'] {
    max-width: 75%; } }

@media (min-width: 0px) {
  [data-col~='10-xs'] {
    max-width: 83.33333%; } }

@media (min-width: 0px) {
  [data-col~='11-xs'] {
    max-width: 91.66667%; } }

@media (min-width: 0px) {
  [data-col~='12-xs'] {
    max-width: 100%; } }

@media (min-width: 480px) {
  [data-col~='1-sm'] {
    max-width: 8.33333%; } }

@media (min-width: 480px) {
  [data-col~='2-sm'] {
    max-width: 16.66667%; } }

@media (min-width: 480px) {
  [data-col~='3-sm'] {
    max-width: 25%; } }

@media (min-width: 480px) {
  [data-col~='4-sm'] {
    max-width: 33.33333%; } }

@media (min-width: 480px) {
  [data-col~='5-sm'] {
    max-width: 41.66667%; } }

@media (min-width: 480px) {
  [data-col~='6-sm'] {
    max-width: 50%; } }

@media (min-width: 480px) {
  [data-col~='7-sm'] {
    max-width: 58.33333%; } }

@media (min-width: 480px) {
  [data-col~='8-sm'] {
    max-width: 66.66667%; } }

@media (min-width: 480px) {
  [data-col~='9-sm'] {
    max-width: 75%; } }

@media (min-width: 480px) {
  [data-col~='10-sm'] {
    max-width: 83.33333%; } }

@media (min-width: 480px) {
  [data-col~='11-sm'] {
    max-width: 91.66667%; } }

@media (min-width: 480px) {
  [data-col~='12-sm'] {
    max-width: 100%; } }

@media (min-width: 720px) {
  [data-col~='1-md'] {
    max-width: 8.33333%; } }

@media (min-width: 720px) {
  [data-col~='2-md'] {
    max-width: 16.66667%; } }

@media (min-width: 720px) {
  [data-col~='3-md'] {
    max-width: 25%; } }

@media (min-width: 720px) {
  [data-col~='4-md'] {
    max-width: 33.33333%; } }

@media (min-width: 720px) {
  [data-col~='5-md'] {
    max-width: 41.66667%; } }

@media (min-width: 720px) {
  [data-col~='6-md'] {
    max-width: 50%; } }

@media (min-width: 720px) {
  [data-col~='7-md'] {
    max-width: 58.33333%; } }

@media (min-width: 720px) {
  [data-col~='8-md'] {
    max-width: 66.66667%; } }

@media (min-width: 720px) {
  [data-col~='9-md'] {
    max-width: 75%; } }

@media (min-width: 720px) {
  [data-col~='10-md'] {
    max-width: 83.33333%; } }

@media (min-width: 720px) {
  [data-col~='11-md'] {
    max-width: 91.66667%; } }

@media (min-width: 720px) {
  [data-col~='12-md'] {
    max-width: 100%; } }

@media (min-width: 1024px) {
  [data-col~='1-nav'] {
    max-width: 8.33333%; } }

@media (min-width: 1024px) {
  [data-col~='2-nav'] {
    max-width: 16.66667%; } }

@media (min-width: 1024px) {
  [data-col~='3-nav'] {
    max-width: 25%; } }

@media (min-width: 1024px) {
  [data-col~='4-nav'] {
    max-width: 33.33333%; } }

@media (min-width: 1024px) {
  [data-col~='5-nav'] {
    max-width: 41.66667%; } }

@media (min-width: 1024px) {
  [data-col~='6-nav'] {
    max-width: 50%; } }

@media (min-width: 1024px) {
  [data-col~='7-nav'] {
    max-width: 58.33333%; } }

@media (min-width: 1024px) {
  [data-col~='8-nav'] {
    max-width: 66.66667%; } }

@media (min-width: 1024px) {
  [data-col~='9-nav'] {
    max-width: 75%; } }

@media (min-width: 1024px) {
  [data-col~='10-nav'] {
    max-width: 83.33333%; } }

@media (min-width: 1024px) {
  [data-col~='11-nav'] {
    max-width: 91.66667%; } }

@media (min-width: 1024px) {
  [data-col~='12-nav'] {
    max-width: 100%; } }

@media (min-width: 1024px) {
  [data-col~='1-lg'] {
    max-width: 8.33333%; } }

@media (min-width: 1024px) {
  [data-col~='2-lg'] {
    max-width: 16.66667%; } }

@media (min-width: 1024px) {
  [data-col~='3-lg'] {
    max-width: 25%; } }

@media (min-width: 1024px) {
  [data-col~='4-lg'] {
    max-width: 33.33333%; } }

@media (min-width: 1024px) {
  [data-col~='5-lg'] {
    max-width: 41.66667%; } }

@media (min-width: 1024px) {
  [data-col~='6-lg'] {
    max-width: 50%; } }

@media (min-width: 1024px) {
  [data-col~='7-lg'] {
    max-width: 58.33333%; } }

@media (min-width: 1024px) {
  [data-col~='8-lg'] {
    max-width: 66.66667%; } }

@media (min-width: 1024px) {
  [data-col~='9-lg'] {
    max-width: 75%; } }

@media (min-width: 1024px) {
  [data-col~='10-lg'] {
    max-width: 83.33333%; } }

@media (min-width: 1024px) {
  [data-col~='11-lg'] {
    max-width: 91.66667%; } }

@media (min-width: 1024px) {
  [data-col~='12-lg'] {
    max-width: 100%; } }

@media (min-width: 1280px) {
  [data-col~='1-xl'] {
    max-width: 8.33333%; } }

@media (min-width: 1280px) {
  [data-col~='2-xl'] {
    max-width: 16.66667%; } }

@media (min-width: 1280px) {
  [data-col~='3-xl'] {
    max-width: 25%; } }

@media (min-width: 1280px) {
  [data-col~='4-xl'] {
    max-width: 33.33333%; } }

@media (min-width: 1280px) {
  [data-col~='5-xl'] {
    max-width: 41.66667%; } }

@media (min-width: 1280px) {
  [data-col~='6-xl'] {
    max-width: 50%; } }

@media (min-width: 1280px) {
  [data-col~='7-xl'] {
    max-width: 58.33333%; } }

@media (min-width: 1280px) {
  [data-col~='8-xl'] {
    max-width: 66.66667%; } }

@media (min-width: 1280px) {
  [data-col~='9-xl'] {
    max-width: 75%; } }

@media (min-width: 1280px) {
  [data-col~='10-xl'] {
    max-width: 83.33333%; } }

@media (min-width: 1280px) {
  [data-col~='11-xl'] {
    max-width: 91.66667%; } }

@media (min-width: 1280px) {
  [data-col~='12-xl'] {
    max-width: 100%; } }

@media (min-width: 1920px) {
  [data-col~='1-xxl'] {
    max-width: 8.33333%; } }

@media (min-width: 1920px) {
  [data-col~='2-xxl'] {
    max-width: 16.66667%; } }

@media (min-width: 1920px) {
  [data-col~='3-xxl'] {
    max-width: 25%; } }

@media (min-width: 1920px) {
  [data-col~='4-xxl'] {
    max-width: 33.33333%; } }

@media (min-width: 1920px) {
  [data-col~='5-xxl'] {
    max-width: 41.66667%; } }

@media (min-width: 1920px) {
  [data-col~='6-xxl'] {
    max-width: 50%; } }

@media (min-width: 1920px) {
  [data-col~='7-xxl'] {
    max-width: 58.33333%; } }

@media (min-width: 1920px) {
  [data-col~='8-xxl'] {
    max-width: 66.66667%; } }

@media (min-width: 1920px) {
  [data-col~='9-xxl'] {
    max-width: 75%; } }

@media (min-width: 1920px) {
  [data-col~='10-xxl'] {
    max-width: 83.33333%; } }

@media (min-width: 1920px) {
  [data-col~='11-xxl'] {
    max-width: 91.66667%; } }

@media (min-width: 1920px) {
  [data-col~='12-xxl'] {
    max-width: 100%; } }

@media (min-width: 0px) {
  [data-col~='auto-xs'] {
    width: auto;
    flex-grow: 1; } }

@media (min-width: 480px) {
  [data-col~='auto-sm'] {
    width: auto;
    flex-grow: 1; } }

@media (min-width: 720px) {
  [data-col~='auto-md'] {
    width: auto;
    flex-grow: 1; } }

@media (min-width: 1024px) {
  [data-col~='auto-nav'] {
    width: auto;
    flex-grow: 1; } }

@media (min-width: 1024px) {
  [data-col~='auto-lg'] {
    width: auto;
    flex-grow: 1; } }

@media (min-width: 1280px) {
  [data-col~='auto-xl'] {
    width: auto;
    flex-grow: 1; } }

@media (min-width: 1920px) {
  [data-col~='auto-xxl'] {
    width: auto;
    flex-grow: 1; } }

[data-col~='no-gutter'] {
  padding-left: 0 !important;
  padding-right: 0 !important; }

[data-col~='+0'] {
  margin-left: 0%; }

[data-col~='+1'] {
  margin-left: 8.33333%; }

[data-col~='+2'] {
  margin-left: 16.66667%; }

[data-col~='+3'] {
  margin-left: 25%; }

[data-col~='+4'] {
  margin-left: 33.33333%; }

[data-col~='+5'] {
  margin-left: 41.66667%; }

[data-col~='+6'] {
  margin-left: 50%; }

[data-col~='+7'] {
  margin-left: 58.33333%; }

[data-col~='+8'] {
  margin-left: 66.66667%; }

[data-col~='+9'] {
  margin-left: 75%; }

[data-col~='+10'] {
  margin-left: 83.33333%; }

[data-col~='+11'] {
  margin-left: 91.66667%; }

@media (min-width: 0px) {
  [data-col~='+0-xs'] {
    margin-left: 0%; } }

@media (min-width: 0px) {
  [data-col~='+1-xs'] {
    margin-left: 8.33333%; } }

@media (min-width: 0px) {
  [data-col~='+2-xs'] {
    margin-left: 16.66667%; } }

@media (min-width: 0px) {
  [data-col~='+3-xs'] {
    margin-left: 25%; } }

@media (min-width: 0px) {
  [data-col~='+4-xs'] {
    margin-left: 33.33333%; } }

@media (min-width: 0px) {
  [data-col~='+5-xs'] {
    margin-left: 41.66667%; } }

@media (min-width: 0px) {
  [data-col~='+6-xs'] {
    margin-left: 50%; } }

@media (min-width: 0px) {
  [data-col~='+7-xs'] {
    margin-left: 58.33333%; } }

@media (min-width: 0px) {
  [data-col~='+8-xs'] {
    margin-left: 66.66667%; } }

@media (min-width: 0px) {
  [data-col~='+9-xs'] {
    margin-left: 75%; } }

@media (min-width: 0px) {
  [data-col~='+10-xs'] {
    margin-left: 83.33333%; } }

@media (min-width: 0px) {
  [data-col~='+11-xs'] {
    margin-left: 91.66667%; } }

@media (min-width: 480px) {
  [data-col~='+0-sm'] {
    margin-left: 0%; } }

@media (min-width: 480px) {
  [data-col~='+1-sm'] {
    margin-left: 8.33333%; } }

@media (min-width: 480px) {
  [data-col~='+2-sm'] {
    margin-left: 16.66667%; } }

@media (min-width: 480px) {
  [data-col~='+3-sm'] {
    margin-left: 25%; } }

@media (min-width: 480px) {
  [data-col~='+4-sm'] {
    margin-left: 33.33333%; } }

@media (min-width: 480px) {
  [data-col~='+5-sm'] {
    margin-left: 41.66667%; } }

@media (min-width: 480px) {
  [data-col~='+6-sm'] {
    margin-left: 50%; } }

@media (min-width: 480px) {
  [data-col~='+7-sm'] {
    margin-left: 58.33333%; } }

@media (min-width: 480px) {
  [data-col~='+8-sm'] {
    margin-left: 66.66667%; } }

@media (min-width: 480px) {
  [data-col~='+9-sm'] {
    margin-left: 75%; } }

@media (min-width: 480px) {
  [data-col~='+10-sm'] {
    margin-left: 83.33333%; } }

@media (min-width: 480px) {
  [data-col~='+11-sm'] {
    margin-left: 91.66667%; } }

@media (min-width: 720px) {
  [data-col~='+0-md'] {
    margin-left: 0%; } }

@media (min-width: 720px) {
  [data-col~='+1-md'] {
    margin-left: 8.33333%; } }

@media (min-width: 720px) {
  [data-col~='+2-md'] {
    margin-left: 16.66667%; } }

@media (min-width: 720px) {
  [data-col~='+3-md'] {
    margin-left: 25%; } }

@media (min-width: 720px) {
  [data-col~='+4-md'] {
    margin-left: 33.33333%; } }

@media (min-width: 720px) {
  [data-col~='+5-md'] {
    margin-left: 41.66667%; } }

@media (min-width: 720px) {
  [data-col~='+6-md'] {
    margin-left: 50%; } }

@media (min-width: 720px) {
  [data-col~='+7-md'] {
    margin-left: 58.33333%; } }

@media (min-width: 720px) {
  [data-col~='+8-md'] {
    margin-left: 66.66667%; } }

@media (min-width: 720px) {
  [data-col~='+9-md'] {
    margin-left: 75%; } }

@media (min-width: 720px) {
  [data-col~='+10-md'] {
    margin-left: 83.33333%; } }

@media (min-width: 720px) {
  [data-col~='+11-md'] {
    margin-left: 91.66667%; } }

@media (min-width: 1024px) {
  [data-col~='+0-nav'] {
    margin-left: 0%; } }

@media (min-width: 1024px) {
  [data-col~='+1-nav'] {
    margin-left: 8.33333%; } }

@media (min-width: 1024px) {
  [data-col~='+2-nav'] {
    margin-left: 16.66667%; } }

@media (min-width: 1024px) {
  [data-col~='+3-nav'] {
    margin-left: 25%; } }

@media (min-width: 1024px) {
  [data-col~='+4-nav'] {
    margin-left: 33.33333%; } }

@media (min-width: 1024px) {
  [data-col~='+5-nav'] {
    margin-left: 41.66667%; } }

@media (min-width: 1024px) {
  [data-col~='+6-nav'] {
    margin-left: 50%; } }

@media (min-width: 1024px) {
  [data-col~='+7-nav'] {
    margin-left: 58.33333%; } }

@media (min-width: 1024px) {
  [data-col~='+8-nav'] {
    margin-left: 66.66667%; } }

@media (min-width: 1024px) {
  [data-col~='+9-nav'] {
    margin-left: 75%; } }

@media (min-width: 1024px) {
  [data-col~='+10-nav'] {
    margin-left: 83.33333%; } }

@media (min-width: 1024px) {
  [data-col~='+11-nav'] {
    margin-left: 91.66667%; } }

@media (min-width: 1024px) {
  [data-col~='+0-lg'] {
    margin-left: 0%; } }

@media (min-width: 1024px) {
  [data-col~='+1-lg'] {
    margin-left: 8.33333%; } }

@media (min-width: 1024px) {
  [data-col~='+2-lg'] {
    margin-left: 16.66667%; } }

@media (min-width: 1024px) {
  [data-col~='+3-lg'] {
    margin-left: 25%; } }

@media (min-width: 1024px) {
  [data-col~='+4-lg'] {
    margin-left: 33.33333%; } }

@media (min-width: 1024px) {
  [data-col~='+5-lg'] {
    margin-left: 41.66667%; } }

@media (min-width: 1024px) {
  [data-col~='+6-lg'] {
    margin-left: 50%; } }

@media (min-width: 1024px) {
  [data-col~='+7-lg'] {
    margin-left: 58.33333%; } }

@media (min-width: 1024px) {
  [data-col~='+8-lg'] {
    margin-left: 66.66667%; } }

@media (min-width: 1024px) {
  [data-col~='+9-lg'] {
    margin-left: 75%; } }

@media (min-width: 1024px) {
  [data-col~='+10-lg'] {
    margin-left: 83.33333%; } }

@media (min-width: 1024px) {
  [data-col~='+11-lg'] {
    margin-left: 91.66667%; } }

@media (min-width: 1280px) {
  [data-col~='+0-xl'] {
    margin-left: 0%; } }

@media (min-width: 1280px) {
  [data-col~='+1-xl'] {
    margin-left: 8.33333%; } }

@media (min-width: 1280px) {
  [data-col~='+2-xl'] {
    margin-left: 16.66667%; } }

@media (min-width: 1280px) {
  [data-col~='+3-xl'] {
    margin-left: 25%; } }

@media (min-width: 1280px) {
  [data-col~='+4-xl'] {
    margin-left: 33.33333%; } }

@media (min-width: 1280px) {
  [data-col~='+5-xl'] {
    margin-left: 41.66667%; } }

@media (min-width: 1280px) {
  [data-col~='+6-xl'] {
    margin-left: 50%; } }

@media (min-width: 1280px) {
  [data-col~='+7-xl'] {
    margin-left: 58.33333%; } }

@media (min-width: 1280px) {
  [data-col~='+8-xl'] {
    margin-left: 66.66667%; } }

@media (min-width: 1280px) {
  [data-col~='+9-xl'] {
    margin-left: 75%; } }

@media (min-width: 1280px) {
  [data-col~='+10-xl'] {
    margin-left: 83.33333%; } }

@media (min-width: 1280px) {
  [data-col~='+11-xl'] {
    margin-left: 91.66667%; } }

@media (min-width: 1920px) {
  [data-col~='+0-xxl'] {
    margin-left: 0%; } }

@media (min-width: 1920px) {
  [data-col~='+1-xxl'] {
    margin-left: 8.33333%; } }

@media (min-width: 1920px) {
  [data-col~='+2-xxl'] {
    margin-left: 16.66667%; } }

@media (min-width: 1920px) {
  [data-col~='+3-xxl'] {
    margin-left: 25%; } }

@media (min-width: 1920px) {
  [data-col~='+4-xxl'] {
    margin-left: 33.33333%; } }

@media (min-width: 1920px) {
  [data-col~='+5-xxl'] {
    margin-left: 41.66667%; } }

@media (min-width: 1920px) {
  [data-col~='+6-xxl'] {
    margin-left: 50%; } }

@media (min-width: 1920px) {
  [data-col~='+7-xxl'] {
    margin-left: 58.33333%; } }

@media (min-width: 1920px) {
  [data-col~='+8-xxl'] {
    margin-left: 66.66667%; } }

@media (min-width: 1920px) {
  [data-col~='+9-xxl'] {
    margin-left: 75%; } }

@media (min-width: 1920px) {
  [data-col~='+10-xxl'] {
    margin-left: 83.33333%; } }

@media (min-width: 1920px) {
  [data-col~='+11-xxl'] {
    margin-left: 91.66667%; } }

[data-flex] {
  display: flex; }
  ul[data-flex] {
    list-style: none;
    margin: 0;
    padding: 0; }

[data-flex~='is-column'] {
  flex-direction: column; }

[data-flex~='is-column-reverse'] {
  flex-direction: column-reverse; }

[data-flex~='is-row'] {
  flex-direction: row; }

[data-flex~='is-row-reverse'] {
  flex-direction: row-reverse; }

@media (min-width: 0px) {
  [data-flex~='is-column'] {
    flex-direction: column; } }

@media (min-width: 0px) {
  [data-flex~='is-column-reverse'] {
    flex-direction: column-reverse; } }

@media (min-width: 0px) {
  [data-flex~='is-row'] {
    flex-direction: row; } }

@media (min-width: 0px) {
  [data-flex~='is-row-reverse'] {
    flex-direction: row-reverse; } }

@media (min-width: 480px) {
  [data-flex~='is-column'] {
    flex-direction: column; } }

@media (min-width: 480px) {
  [data-flex~='is-column-reverse'] {
    flex-direction: column-reverse; } }

@media (min-width: 480px) {
  [data-flex~='is-row'] {
    flex-direction: row; } }

@media (min-width: 480px) {
  [data-flex~='is-row-reverse'] {
    flex-direction: row-reverse; } }

@media (min-width: 720px) {
  [data-flex~='is-column'] {
    flex-direction: column; } }

@media (min-width: 720px) {
  [data-flex~='is-column-reverse'] {
    flex-direction: column-reverse; } }

@media (min-width: 720px) {
  [data-flex~='is-row'] {
    flex-direction: row; } }

@media (min-width: 720px) {
  [data-flex~='is-row-reverse'] {
    flex-direction: row-reverse; } }

@media (min-width: 1024px) {
  [data-flex~='is-column'] {
    flex-direction: column; } }

@media (min-width: 1024px) {
  [data-flex~='is-column-reverse'] {
    flex-direction: column-reverse; } }

@media (min-width: 1024px) {
  [data-flex~='is-row'] {
    flex-direction: row; } }

@media (min-width: 1024px) {
  [data-flex~='is-row-reverse'] {
    flex-direction: row-reverse; } }

@media (min-width: 1024px) {
  [data-flex~='is-column'] {
    flex-direction: column; } }

@media (min-width: 1024px) {
  [data-flex~='is-column-reverse'] {
    flex-direction: column-reverse; } }

@media (min-width: 1024px) {
  [data-flex~='is-row'] {
    flex-direction: row; } }

@media (min-width: 1024px) {
  [data-flex~='is-row-reverse'] {
    flex-direction: row-reverse; } }

@media (min-width: 1280px) {
  [data-flex~='is-column'] {
    flex-direction: column; } }

@media (min-width: 1280px) {
  [data-flex~='is-column-reverse'] {
    flex-direction: column-reverse; } }

@media (min-width: 1280px) {
  [data-flex~='is-row'] {
    flex-direction: row; } }

@media (min-width: 1280px) {
  [data-flex~='is-row-reverse'] {
    flex-direction: row-reverse; } }

@media (min-width: 1920px) {
  [data-flex~='is-column'] {
    flex-direction: column; } }

@media (min-width: 1920px) {
  [data-flex~='is-column-reverse'] {
    flex-direction: column-reverse; } }

@media (min-width: 1920px) {
  [data-flex~='is-row'] {
    flex-direction: row; } }

@media (min-width: 1920px) {
  [data-flex~='is-row-reverse'] {
    flex-direction: row-reverse; } }

[data-flex~='is-around'] {
  justify-content: space-around; }

[data-flex~='is-between'] {
  justify-content: space-between; }

[data-flex~='is-center'] {
  justify-content: center; }

[data-flex~='is-start'] {
  justify-content: flex-start; }

[data-flex~='is-end'] {
  justify-content: flex-end; }

@media (min-width: 0px) {
  [data-flex~='is-around'] {
    justify-content: space-around; } }

@media (min-width: 0px) {
  [data-flex~='is-between'] {
    justify-content: space-between; } }

@media (min-width: 0px) {
  [data-flex~='is-center'] {
    justify-content: center; } }

@media (min-width: 0px) {
  [data-flex~='is-start'] {
    justify-content: flex-start; } }

@media (min-width: 0px) {
  [data-flex~='is-end'] {
    justify-content: flex-end; } }

@media (min-width: 480px) {
  [data-flex~='is-around'] {
    justify-content: space-around; } }

@media (min-width: 480px) {
  [data-flex~='is-between'] {
    justify-content: space-between; } }

@media (min-width: 480px) {
  [data-flex~='is-center'] {
    justify-content: center; } }

@media (min-width: 480px) {
  [data-flex~='is-start'] {
    justify-content: flex-start; } }

@media (min-width: 480px) {
  [data-flex~='is-end'] {
    justify-content: flex-end; } }

@media (min-width: 720px) {
  [data-flex~='is-around'] {
    justify-content: space-around; } }

@media (min-width: 720px) {
  [data-flex~='is-between'] {
    justify-content: space-between; } }

@media (min-width: 720px) {
  [data-flex~='is-center'] {
    justify-content: center; } }

@media (min-width: 720px) {
  [data-flex~='is-start'] {
    justify-content: flex-start; } }

@media (min-width: 720px) {
  [data-flex~='is-end'] {
    justify-content: flex-end; } }

@media (min-width: 1024px) {
  [data-flex~='is-around'] {
    justify-content: space-around; } }

@media (min-width: 1024px) {
  [data-flex~='is-between'] {
    justify-content: space-between; } }

@media (min-width: 1024px) {
  [data-flex~='is-center'] {
    justify-content: center; } }

@media (min-width: 1024px) {
  [data-flex~='is-start'] {
    justify-content: flex-start; } }

@media (min-width: 1024px) {
  [data-flex~='is-end'] {
    justify-content: flex-end; } }

@media (min-width: 1024px) {
  [data-flex~='is-around'] {
    justify-content: space-around; } }

@media (min-width: 1024px) {
  [data-flex~='is-between'] {
    justify-content: space-between; } }

@media (min-width: 1024px) {
  [data-flex~='is-center'] {
    justify-content: center; } }

@media (min-width: 1024px) {
  [data-flex~='is-start'] {
    justify-content: flex-start; } }

@media (min-width: 1024px) {
  [data-flex~='is-end'] {
    justify-content: flex-end; } }

@media (min-width: 1280px) {
  [data-flex~='is-around'] {
    justify-content: space-around; } }

@media (min-width: 1280px) {
  [data-flex~='is-between'] {
    justify-content: space-between; } }

@media (min-width: 1280px) {
  [data-flex~='is-center'] {
    justify-content: center; } }

@media (min-width: 1280px) {
  [data-flex~='is-start'] {
    justify-content: flex-start; } }

@media (min-width: 1280px) {
  [data-flex~='is-end'] {
    justify-content: flex-end; } }

@media (min-width: 1920px) {
  [data-flex~='is-around'] {
    justify-content: space-around; } }

@media (min-width: 1920px) {
  [data-flex~='is-between'] {
    justify-content: space-between; } }

@media (min-width: 1920px) {
  [data-flex~='is-center'] {
    justify-content: center; } }

@media (min-width: 1920px) {
  [data-flex~='is-start'] {
    justify-content: flex-start; } }

@media (min-width: 1920px) {
  [data-flex~='is-end'] {
    justify-content: flex-end; } }

[data-flex~='is-end-items'] {
  align-items: flex-end; }

[data-flex~='is-start-items'] {
  align-items: flex-start; }

[data-flex~='is-center-items'] {
  align-items: center; }

[data-flex~='is-baseline-items'] {
  align-items: baseline; }

[data-flex~='is-stretch-items'] {
  align-items: stretch; }

@media (min-width: 0px) {
  [data-flex~='is-end-items'] {
    align-items: flex-end; } }

@media (min-width: 0px) {
  [data-flex~='is-start-items'] {
    align-items: flex-start; } }

@media (min-width: 0px) {
  [data-flex~='is-center-items'] {
    align-items: center; } }

@media (min-width: 0px) {
  [data-flex~='is-baseline-items'] {
    align-items: baseline; } }

@media (min-width: 0px) {
  [data-flex~='is-stretch-items'] {
    align-items: stretch; } }

@media (min-width: 480px) {
  [data-flex~='is-end-items'] {
    align-items: flex-end; } }

@media (min-width: 480px) {
  [data-flex~='is-start-items'] {
    align-items: flex-start; } }

@media (min-width: 480px) {
  [data-flex~='is-center-items'] {
    align-items: center; } }

@media (min-width: 480px) {
  [data-flex~='is-baseline-items'] {
    align-items: baseline; } }

@media (min-width: 480px) {
  [data-flex~='is-stretch-items'] {
    align-items: stretch; } }

@media (min-width: 720px) {
  [data-flex~='is-end-items'] {
    align-items: flex-end; } }

@media (min-width: 720px) {
  [data-flex~='is-start-items'] {
    align-items: flex-start; } }

@media (min-width: 720px) {
  [data-flex~='is-center-items'] {
    align-items: center; } }

@media (min-width: 720px) {
  [data-flex~='is-baseline-items'] {
    align-items: baseline; } }

@media (min-width: 720px) {
  [data-flex~='is-stretch-items'] {
    align-items: stretch; } }

@media (min-width: 1024px) {
  [data-flex~='is-end-items'] {
    align-items: flex-end; } }

@media (min-width: 1024px) {
  [data-flex~='is-start-items'] {
    align-items: flex-start; } }

@media (min-width: 1024px) {
  [data-flex~='is-center-items'] {
    align-items: center; } }

@media (min-width: 1024px) {
  [data-flex~='is-baseline-items'] {
    align-items: baseline; } }

@media (min-width: 1024px) {
  [data-flex~='is-stretch-items'] {
    align-items: stretch; } }

@media (min-width: 1024px) {
  [data-flex~='is-end-items'] {
    align-items: flex-end; } }

@media (min-width: 1024px) {
  [data-flex~='is-start-items'] {
    align-items: flex-start; } }

@media (min-width: 1024px) {
  [data-flex~='is-center-items'] {
    align-items: center; } }

@media (min-width: 1024px) {
  [data-flex~='is-baseline-items'] {
    align-items: baseline; } }

@media (min-width: 1024px) {
  [data-flex~='is-stretch-items'] {
    align-items: stretch; } }

@media (min-width: 1280px) {
  [data-flex~='is-end-items'] {
    align-items: flex-end; } }

@media (min-width: 1280px) {
  [data-flex~='is-start-items'] {
    align-items: flex-start; } }

@media (min-width: 1280px) {
  [data-flex~='is-center-items'] {
    align-items: center; } }

@media (min-width: 1280px) {
  [data-flex~='is-baseline-items'] {
    align-items: baseline; } }

@media (min-width: 1280px) {
  [data-flex~='is-stretch-items'] {
    align-items: stretch; } }

@media (min-width: 1920px) {
  [data-flex~='is-end-items'] {
    align-items: flex-end; } }

@media (min-width: 1920px) {
  [data-flex~='is-start-items'] {
    align-items: flex-start; } }

@media (min-width: 1920px) {
  [data-flex~='is-center-items'] {
    align-items: center; } }

@media (min-width: 1920px) {
  [data-flex~='is-baseline-items'] {
    align-items: baseline; } }

@media (min-width: 1920px) {
  [data-flex~='is-stretch-items'] {
    align-items: stretch; } }

[data-flex~='is-end-content'] {
  align-content: flex-end; }

[data-flex~='is-start-content'] {
  align-content: flex-start; }

[data-flex~='is-center-content'] {
  align-content: center; }

[data-flex~='is-stretch-content'] {
  align-content: stretch; }

[data-flex~='is-between-content'] {
  align-content: space-between; }

[data-flex~='is-around-content'] {
  align-content: space-around; }

@media (min-width: 0px) {
  [data-flex~='is-end-content'] {
    align-content: flex-end; } }

@media (min-width: 0px) {
  [data-flex~='is-start-content'] {
    align-content: flex-start; } }

@media (min-width: 0px) {
  [data-flex~='is-center-content'] {
    align-content: center; } }

@media (min-width: 0px) {
  [data-flex~='is-stretch-content'] {
    align-content: stretch; } }

@media (min-width: 0px) {
  [data-flex~='is-between-content'] {
    align-content: space-between; } }

@media (min-width: 0px) {
  [data-flex~='is-around-content'] {
    align-content: space-around; } }

@media (min-width: 480px) {
  [data-flex~='is-end-content'] {
    align-content: flex-end; } }

@media (min-width: 480px) {
  [data-flex~='is-start-content'] {
    align-content: flex-start; } }

@media (min-width: 480px) {
  [data-flex~='is-center-content'] {
    align-content: center; } }

@media (min-width: 480px) {
  [data-flex~='is-stretch-content'] {
    align-content: stretch; } }

@media (min-width: 480px) {
  [data-flex~='is-between-content'] {
    align-content: space-between; } }

@media (min-width: 480px) {
  [data-flex~='is-around-content'] {
    align-content: space-around; } }

@media (min-width: 720px) {
  [data-flex~='is-end-content'] {
    align-content: flex-end; } }

@media (min-width: 720px) {
  [data-flex~='is-start-content'] {
    align-content: flex-start; } }

@media (min-width: 720px) {
  [data-flex~='is-center-content'] {
    align-content: center; } }

@media (min-width: 720px) {
  [data-flex~='is-stretch-content'] {
    align-content: stretch; } }

@media (min-width: 720px) {
  [data-flex~='is-between-content'] {
    align-content: space-between; } }

@media (min-width: 720px) {
  [data-flex~='is-around-content'] {
    align-content: space-around; } }

@media (min-width: 1024px) {
  [data-flex~='is-end-content'] {
    align-content: flex-end; } }

@media (min-width: 1024px) {
  [data-flex~='is-start-content'] {
    align-content: flex-start; } }

@media (min-width: 1024px) {
  [data-flex~='is-center-content'] {
    align-content: center; } }

@media (min-width: 1024px) {
  [data-flex~='is-stretch-content'] {
    align-content: stretch; } }

@media (min-width: 1024px) {
  [data-flex~='is-between-content'] {
    align-content: space-between; } }

@media (min-width: 1024px) {
  [data-flex~='is-around-content'] {
    align-content: space-around; } }

@media (min-width: 1024px) {
  [data-flex~='is-end-content'] {
    align-content: flex-end; } }

@media (min-width: 1024px) {
  [data-flex~='is-start-content'] {
    align-content: flex-start; } }

@media (min-width: 1024px) {
  [data-flex~='is-center-content'] {
    align-content: center; } }

@media (min-width: 1024px) {
  [data-flex~='is-stretch-content'] {
    align-content: stretch; } }

@media (min-width: 1024px) {
  [data-flex~='is-between-content'] {
    align-content: space-between; } }

@media (min-width: 1024px) {
  [data-flex~='is-around-content'] {
    align-content: space-around; } }

@media (min-width: 1280px) {
  [data-flex~='is-end-content'] {
    align-content: flex-end; } }

@media (min-width: 1280px) {
  [data-flex~='is-start-content'] {
    align-content: flex-start; } }

@media (min-width: 1280px) {
  [data-flex~='is-center-content'] {
    align-content: center; } }

@media (min-width: 1280px) {
  [data-flex~='is-stretch-content'] {
    align-content: stretch; } }

@media (min-width: 1280px) {
  [data-flex~='is-between-content'] {
    align-content: space-between; } }

@media (min-width: 1280px) {
  [data-flex~='is-around-content'] {
    align-content: space-around; } }

@media (min-width: 1920px) {
  [data-flex~='is-end-content'] {
    align-content: flex-end; } }

@media (min-width: 1920px) {
  [data-flex~='is-start-content'] {
    align-content: flex-start; } }

@media (min-width: 1920px) {
  [data-flex~='is-center-content'] {
    align-content: center; } }

@media (min-width: 1920px) {
  [data-flex~='is-stretch-content'] {
    align-content: stretch; } }

@media (min-width: 1920px) {
  [data-flex~='is-between-content'] {
    align-content: space-between; } }

@media (min-width: 1920px) {
  [data-flex~='is-around-content'] {
    align-content: space-around; } }

[data-flex~='is-wrap'] {
  flex-wrap: wrap; }

[data-flex~='is-nowrap'] {
  flex-wrap: nowrap; }

[data-flex~='is-wrap-reverse'] {
  flex-wrap: wrap-reverse; }

@media (min-width: 0px) {
  [data-flex~='is-wrap'] {
    flex-wrap: wrap; } }

@media (min-width: 0px) {
  [data-flex~='is-nowrap'] {
    flex-wrap: nowrap; } }

@media (min-width: 0px) {
  [data-flex~='is-wrap-reverse'] {
    flex-wrap: wrap-reverse; } }

@media (min-width: 480px) {
  [data-flex~='is-wrap'] {
    flex-wrap: wrap; } }

@media (min-width: 480px) {
  [data-flex~='is-nowrap'] {
    flex-wrap: nowrap; } }

@media (min-width: 480px) {
  [data-flex~='is-wrap-reverse'] {
    flex-wrap: wrap-reverse; } }

@media (min-width: 720px) {
  [data-flex~='is-wrap'] {
    flex-wrap: wrap; } }

@media (min-width: 720px) {
  [data-flex~='is-nowrap'] {
    flex-wrap: nowrap; } }

@media (min-width: 720px) {
  [data-flex~='is-wrap-reverse'] {
    flex-wrap: wrap-reverse; } }

@media (min-width: 1024px) {
  [data-flex~='is-wrap'] {
    flex-wrap: wrap; } }

@media (min-width: 1024px) {
  [data-flex~='is-nowrap'] {
    flex-wrap: nowrap; } }

@media (min-width: 1024px) {
  [data-flex~='is-wrap-reverse'] {
    flex-wrap: wrap-reverse; } }

@media (min-width: 1024px) {
  [data-flex~='is-wrap'] {
    flex-wrap: wrap; } }

@media (min-width: 1024px) {
  [data-flex~='is-nowrap'] {
    flex-wrap: nowrap; } }

@media (min-width: 1024px) {
  [data-flex~='is-wrap-reverse'] {
    flex-wrap: wrap-reverse; } }

@media (min-width: 1280px) {
  [data-flex~='is-wrap'] {
    flex-wrap: wrap; } }

@media (min-width: 1280px) {
  [data-flex~='is-nowrap'] {
    flex-wrap: nowrap; } }

@media (min-width: 1280px) {
  [data-flex~='is-wrap-reverse'] {
    flex-wrap: wrap-reverse; } }

@media (min-width: 1920px) {
  [data-flex~='is-wrap'] {
    flex-wrap: wrap; } }

@media (min-width: 1920px) {
  [data-flex~='is-nowrap'] {
    flex-wrap: nowrap; } }

@media (min-width: 1920px) {
  [data-flex~='is-wrap-reverse'] {
    flex-wrap: wrap-reverse; } }

[data-flex-order~='is-first'] {
  order: 1; }

[data-flex-order~='is-last'] {
  order: -1; }

@media (min-width: 0px) {
  [data-flex-order~='is-first'] {
    order: 1; } }

@media (min-width: 0px) {
  [data-flex-order~='is-last'] {
    order: -1; } }

@media (min-width: 480px) {
  [data-flex-order~='is-first'] {
    order: 1; } }

@media (min-width: 480px) {
  [data-flex-order~='is-last'] {
    order: -1; } }

@media (min-width: 720px) {
  [data-flex-order~='is-first'] {
    order: 1; } }

@media (min-width: 720px) {
  [data-flex-order~='is-last'] {
    order: -1; } }

@media (min-width: 1024px) {
  [data-flex-order~='is-first'] {
    order: 1; } }

@media (min-width: 1024px) {
  [data-flex-order~='is-last'] {
    order: -1; } }

@media (min-width: 1024px) {
  [data-flex-order~='is-first'] {
    order: 1; } }

@media (min-width: 1024px) {
  [data-flex-order~='is-last'] {
    order: -1; } }

@media (min-width: 1280px) {
  [data-flex-order~='is-first'] {
    order: 1; } }

@media (min-width: 1280px) {
  [data-flex-order~='is-last'] {
    order: -1; } }

@media (min-width: 1920px) {
  [data-flex-order~='is-first'] {
    order: 1; } }

@media (min-width: 1920px) {
  [data-flex-order~='is-last'] {
    order: -1; } }

[data-flex-order~='1'] {
  order: 1; }

@media (min-width: 0px) {
  [data-flex-order~='1'] {
    order: 1; } }

@media (min-width: 480px) {
  [data-flex-order~='1'] {
    order: 1; } }

@media (min-width: 720px) {
  [data-flex-order~='1'] {
    order: 1; } }

@media (min-width: 1024px) {
  [data-flex-order~='1'] {
    order: 1; } }

@media (min-width: 1024px) {
  [data-flex-order~='1'] {
    order: 1; } }

@media (min-width: 1280px) {
  [data-flex-order~='1'] {
    order: 1; } }

@media (min-width: 1920px) {
  [data-flex-order~='1'] {
    order: 1; } }

[data-flex-order~='2'] {
  order: 2; }

@media (min-width: 0px) {
  [data-flex-order~='2'] {
    order: 2; } }

@media (min-width: 480px) {
  [data-flex-order~='2'] {
    order: 2; } }

@media (min-width: 720px) {
  [data-flex-order~='2'] {
    order: 2; } }

@media (min-width: 1024px) {
  [data-flex-order~='2'] {
    order: 2; } }

@media (min-width: 1024px) {
  [data-flex-order~='2'] {
    order: 2; } }

@media (min-width: 1280px) {
  [data-flex-order~='2'] {
    order: 2; } }

@media (min-width: 1920px) {
  [data-flex-order~='2'] {
    order: 2; } }

[data-flex-order~='3'] {
  order: 3; }

@media (min-width: 0px) {
  [data-flex-order~='3'] {
    order: 3; } }

@media (min-width: 480px) {
  [data-flex-order~='3'] {
    order: 3; } }

@media (min-width: 720px) {
  [data-flex-order~='3'] {
    order: 3; } }

@media (min-width: 1024px) {
  [data-flex-order~='3'] {
    order: 3; } }

@media (min-width: 1024px) {
  [data-flex-order~='3'] {
    order: 3; } }

@media (min-width: 1280px) {
  [data-flex-order~='3'] {
    order: 3; } }

@media (min-width: 1920px) {
  [data-flex-order~='3'] {
    order: 3; } }

[data-flex-order~='4'] {
  order: 4; }

@media (min-width: 0px) {
  [data-flex-order~='4'] {
    order: 4; } }

@media (min-width: 480px) {
  [data-flex-order~='4'] {
    order: 4; } }

@media (min-width: 720px) {
  [data-flex-order~='4'] {
    order: 4; } }

@media (min-width: 1024px) {
  [data-flex-order~='4'] {
    order: 4; } }

@media (min-width: 1024px) {
  [data-flex-order~='4'] {
    order: 4; } }

@media (min-width: 1280px) {
  [data-flex-order~='4'] {
    order: 4; } }

@media (min-width: 1920px) {
  [data-flex-order~='4'] {
    order: 4; } }

[data-flex-order~='5'] {
  order: 5; }

@media (min-width: 0px) {
  [data-flex-order~='5'] {
    order: 5; } }

@media (min-width: 480px) {
  [data-flex-order~='5'] {
    order: 5; } }

@media (min-width: 720px) {
  [data-flex-order~='5'] {
    order: 5; } }

@media (min-width: 1024px) {
  [data-flex-order~='5'] {
    order: 5; } }

@media (min-width: 1024px) {
  [data-flex-order~='5'] {
    order: 5; } }

@media (min-width: 1280px) {
  [data-flex-order~='5'] {
    order: 5; } }

@media (min-width: 1920px) {
  [data-flex-order~='5'] {
    order: 5; } }

[data-flex-order~='6'] {
  order: 6; }

@media (min-width: 0px) {
  [data-flex-order~='6'] {
    order: 6; } }

@media (min-width: 480px) {
  [data-flex-order~='6'] {
    order: 6; } }

@media (min-width: 720px) {
  [data-flex-order~='6'] {
    order: 6; } }

@media (min-width: 1024px) {
  [data-flex-order~='6'] {
    order: 6; } }

@media (min-width: 1024px) {
  [data-flex-order~='6'] {
    order: 6; } }

@media (min-width: 1280px) {
  [data-flex-order~='6'] {
    order: 6; } }

@media (min-width: 1920px) {
  [data-flex-order~='6'] {
    order: 6; } }

[data-flex-order~='7'] {
  order: 7; }

@media (min-width: 0px) {
  [data-flex-order~='7'] {
    order: 7; } }

@media (min-width: 480px) {
  [data-flex-order~='7'] {
    order: 7; } }

@media (min-width: 720px) {
  [data-flex-order~='7'] {
    order: 7; } }

@media (min-width: 1024px) {
  [data-flex-order~='7'] {
    order: 7; } }

@media (min-width: 1024px) {
  [data-flex-order~='7'] {
    order: 7; } }

@media (min-width: 1280px) {
  [data-flex-order~='7'] {
    order: 7; } }

@media (min-width: 1920px) {
  [data-flex-order~='7'] {
    order: 7; } }

[data-flex-order~='8'] {
  order: 8; }

@media (min-width: 0px) {
  [data-flex-order~='8'] {
    order: 8; } }

@media (min-width: 480px) {
  [data-flex-order~='8'] {
    order: 8; } }

@media (min-width: 720px) {
  [data-flex-order~='8'] {
    order: 8; } }

@media (min-width: 1024px) {
  [data-flex-order~='8'] {
    order: 8; } }

@media (min-width: 1024px) {
  [data-flex-order~='8'] {
    order: 8; } }

@media (min-width: 1280px) {
  [data-flex-order~='8'] {
    order: 8; } }

@media (min-width: 1920px) {
  [data-flex-order~='8'] {
    order: 8; } }

[data-flex-order~='9'] {
  order: 9; }

@media (min-width: 0px) {
  [data-flex-order~='9'] {
    order: 9; } }

@media (min-width: 480px) {
  [data-flex-order~='9'] {
    order: 9; } }

@media (min-width: 720px) {
  [data-flex-order~='9'] {
    order: 9; } }

@media (min-width: 1024px) {
  [data-flex-order~='9'] {
    order: 9; } }

@media (min-width: 1024px) {
  [data-flex-order~='9'] {
    order: 9; } }

@media (min-width: 1280px) {
  [data-flex-order~='9'] {
    order: 9; } }

@media (min-width: 1920px) {
  [data-flex-order~='9'] {
    order: 9; } }

[data-flex-order~='10'] {
  order: 10; }

@media (min-width: 0px) {
  [data-flex-order~='10'] {
    order: 10; } }

@media (min-width: 480px) {
  [data-flex-order~='10'] {
    order: 10; } }

@media (min-width: 720px) {
  [data-flex-order~='10'] {
    order: 10; } }

@media (min-width: 1024px) {
  [data-flex-order~='10'] {
    order: 10; } }

@media (min-width: 1024px) {
  [data-flex-order~='10'] {
    order: 10; } }

@media (min-width: 1280px) {
  [data-flex-order~='10'] {
    order: 10; } }

@media (min-width: 1920px) {
  [data-flex-order~='10'] {
    order: 10; } }

[data-flex-order~='11'] {
  order: 11; }

@media (min-width: 0px) {
  [data-flex-order~='11'] {
    order: 11; } }

@media (min-width: 480px) {
  [data-flex-order~='11'] {
    order: 11; } }

@media (min-width: 720px) {
  [data-flex-order~='11'] {
    order: 11; } }

@media (min-width: 1024px) {
  [data-flex-order~='11'] {
    order: 11; } }

@media (min-width: 1024px) {
  [data-flex-order~='11'] {
    order: 11; } }

@media (min-width: 1280px) {
  [data-flex-order~='11'] {
    order: 11; } }

@media (min-width: 1920px) {
  [data-flex-order~='11'] {
    order: 11; } }

[data-flex-order~='12'] {
  order: 12; }

@media (min-width: 0px) {
  [data-flex-order~='12'] {
    order: 12; } }

@media (min-width: 480px) {
  [data-flex-order~='12'] {
    order: 12; } }

@media (min-width: 720px) {
  [data-flex-order~='12'] {
    order: 12; } }

@media (min-width: 1024px) {
  [data-flex-order~='12'] {
    order: 12; } }

@media (min-width: 1024px) {
  [data-flex-order~='12'] {
    order: 12; } }

@media (min-width: 1280px) {
  [data-flex-order~='12'] {
    order: 12; } }

@media (min-width: 1920px) {
  [data-flex-order~='12'] {
    order: 12; } }

[data-flex-item~='grow'] {
  flex: 1 1 auto; }

[data-flex-item~='grow-even'] {
  flex: 1; }

[data-flex-item~='fit-self'] {
  flex: 0 0 auto; }

@media (min-width: 0px) {
  [data-flex-item~='grow'] {
    flex: 1 1 auto; } }

@media (min-width: 0px) {
  [data-flex-item~='grow-even'] {
    flex: 1; } }

@media (min-width: 0px) {
  [data-flex-item~='fit-self'] {
    flex: 0 0 auto; } }

@media (min-width: 480px) {
  [data-flex-item~='grow'] {
    flex: 1 1 auto; } }

@media (min-width: 480px) {
  [data-flex-item~='grow-even'] {
    flex: 1; } }

@media (min-width: 480px) {
  [data-flex-item~='fit-self'] {
    flex: 0 0 auto; } }

@media (min-width: 720px) {
  [data-flex-item~='grow'] {
    flex: 1 1 auto; } }

@media (min-width: 720px) {
  [data-flex-item~='grow-even'] {
    flex: 1; } }

@media (min-width: 720px) {
  [data-flex-item~='fit-self'] {
    flex: 0 0 auto; } }

@media (min-width: 1024px) {
  [data-flex-item~='grow'] {
    flex: 1 1 auto; } }

@media (min-width: 1024px) {
  [data-flex-item~='grow-even'] {
    flex: 1; } }

@media (min-width: 1024px) {
  [data-flex-item~='fit-self'] {
    flex: 0 0 auto; } }

@media (min-width: 1024px) {
  [data-flex-item~='grow'] {
    flex: 1 1 auto; } }

@media (min-width: 1024px) {
  [data-flex-item~='grow-even'] {
    flex: 1; } }

@media (min-width: 1024px) {
  [data-flex-item~='fit-self'] {
    flex: 0 0 auto; } }

@media (min-width: 1280px) {
  [data-flex-item~='grow'] {
    flex: 1 1 auto; } }

@media (min-width: 1280px) {
  [data-flex-item~='grow-even'] {
    flex: 1; } }

@media (min-width: 1280px) {
  [data-flex-item~='fit-self'] {
    flex: 0 0 auto; } }

@media (min-width: 1920px) {
  [data-flex-item~='grow'] {
    flex: 1 1 auto; } }

@media (min-width: 1920px) {
  [data-flex-item~='grow-even'] {
    flex: 1; } }

@media (min-width: 1920px) {
  [data-flex-item~='fit-self'] {
    flex: 0 0 auto; } }

[data-flex-item~='is-auto'] {
  align-self: auto; }

[data-flex-item~='is-baseline'] {
  align-self: baseline; }

[data-flex-item~='is-stretch'] {
  align-self: stretch; }

[data-flex-item~='is-center'] {
  align-self: center; }

[data-flex-item~='is-start'] {
  align-self: flex-start; }

[data-flex-item~='is-end'] {
  align-self: flex-end; }

@media (min-width: 0px) {
  [data-flex-item~='is-auto'] {
    align-self: auto; } }

@media (min-width: 0px) {
  [data-flex-item~='is-baseline'] {
    align-self: baseline; } }

@media (min-width: 0px) {
  [data-flex-item~='is-stretch'] {
    align-self: stretch; } }

@media (min-width: 0px) {
  [data-flex-item~='is-center'] {
    align-self: center; } }

@media (min-width: 0px) {
  [data-flex-item~='is-start'] {
    align-self: flex-start; } }

@media (min-width: 0px) {
  [data-flex-item~='is-end'] {
    align-self: flex-end; } }

@media (min-width: 480px) {
  [data-flex-item~='is-auto'] {
    align-self: auto; } }

@media (min-width: 480px) {
  [data-flex-item~='is-baseline'] {
    align-self: baseline; } }

@media (min-width: 480px) {
  [data-flex-item~='is-stretch'] {
    align-self: stretch; } }

@media (min-width: 480px) {
  [data-flex-item~='is-center'] {
    align-self: center; } }

@media (min-width: 480px) {
  [data-flex-item~='is-start'] {
    align-self: flex-start; } }

@media (min-width: 480px) {
  [data-flex-item~='is-end'] {
    align-self: flex-end; } }

@media (min-width: 720px) {
  [data-flex-item~='is-auto'] {
    align-self: auto; } }

@media (min-width: 720px) {
  [data-flex-item~='is-baseline'] {
    align-self: baseline; } }

@media (min-width: 720px) {
  [data-flex-item~='is-stretch'] {
    align-self: stretch; } }

@media (min-width: 720px) {
  [data-flex-item~='is-center'] {
    align-self: center; } }

@media (min-width: 720px) {
  [data-flex-item~='is-start'] {
    align-self: flex-start; } }

@media (min-width: 720px) {
  [data-flex-item~='is-end'] {
    align-self: flex-end; } }

@media (min-width: 1024px) {
  [data-flex-item~='is-auto'] {
    align-self: auto; } }

@media (min-width: 1024px) {
  [data-flex-item~='is-baseline'] {
    align-self: baseline; } }

@media (min-width: 1024px) {
  [data-flex-item~='is-stretch'] {
    align-self: stretch; } }

@media (min-width: 1024px) {
  [data-flex-item~='is-center'] {
    align-self: center; } }

@media (min-width: 1024px) {
  [data-flex-item~='is-start'] {
    align-self: flex-start; } }

@media (min-width: 1024px) {
  [data-flex-item~='is-end'] {
    align-self: flex-end; } }

@media (min-width: 1024px) {
  [data-flex-item~='is-auto'] {
    align-self: auto; } }

@media (min-width: 1024px) {
  [data-flex-item~='is-baseline'] {
    align-self: baseline; } }

@media (min-width: 1024px) {
  [data-flex-item~='is-stretch'] {
    align-self: stretch; } }

@media (min-width: 1024px) {
  [data-flex-item~='is-center'] {
    align-self: center; } }

@media (min-width: 1024px) {
  [data-flex-item~='is-start'] {
    align-self: flex-start; } }

@media (min-width: 1024px) {
  [data-flex-item~='is-end'] {
    align-self: flex-end; } }

@media (min-width: 1280px) {
  [data-flex-item~='is-auto'] {
    align-self: auto; } }

@media (min-width: 1280px) {
  [data-flex-item~='is-baseline'] {
    align-self: baseline; } }

@media (min-width: 1280px) {
  [data-flex-item~='is-stretch'] {
    align-self: stretch; } }

@media (min-width: 1280px) {
  [data-flex-item~='is-center'] {
    align-self: center; } }

@media (min-width: 1280px) {
  [data-flex-item~='is-start'] {
    align-self: flex-start; } }

@media (min-width: 1280px) {
  [data-flex-item~='is-end'] {
    align-self: flex-end; } }

@media (min-width: 1920px) {
  [data-flex-item~='is-auto'] {
    align-self: auto; } }

@media (min-width: 1920px) {
  [data-flex-item~='is-baseline'] {
    align-self: baseline; } }

@media (min-width: 1920px) {
  [data-flex-item~='is-stretch'] {
    align-self: stretch; } }

@media (min-width: 1920px) {
  [data-flex-item~='is-center'] {
    align-self: center; } }

@media (min-width: 1920px) {
  [data-flex-item~='is-start'] {
    align-self: flex-start; } }

@media (min-width: 1920px) {
  [data-flex-item~='is-end'] {
    align-self: flex-end; } }

@keyframes tooltipBottom {
  0% {
    opacity: 0;
    transform: translateX(-50%) translateY(-0.25rem); }
  100% {
    opacity: 1;
    transform: translateX(-50%) translateY(0.25rem); } }

@keyframes tooltipTop {
  0% {
    opacity: 0;
    transform: translateX(-50%) translateY(0.25rem); }
  100% {
    opacity: 1;
    transform: translateX(-50%) translateY(-0.25rem); } }

@keyframes tooltipLeft {
  0% {
    opacity: 0;
    transform: translateX(0.25rem) translateY(-50%); }
  100% {
    opacity: 1;
    transform: translateX(-0.25rem) translateY(-50%); } }

@keyframes tooltipRight {
  0% {
    opacity: 0;
    transform: translateX(-0.25rem) translateY(-50%); }
  100% {
    opacity: 1;
    transform: translateX(0.25rem) translateY(-50%); } }

[data-tooltip-bottom], [data-tooltip-left], [data-tooltip-right], [data-tooltip-top] {
  overflow: visible;
  position: relative; }
  [data-tooltip-bottom]::after, [data-tooltip-left]::after, [data-tooltip-right]::after, [data-tooltip-top]::after {
    display: none;
    letter-spacing: 0;
    pointer-events: none;
    position: absolute;
    text-align: center;
    text-decoration: none;
    text-transform: initial;
    white-space: nowrap;
    z-index: 100;
    padding: 0.1875rem 0.375rem;
    font-size: 0.75rem;
    line-height: 0.875rem;
    min-height: 1.25rem;
    min-width: 1.25rem;
    background: #2a3d54;
    color: #fff;
    font-weight: normal;
    border-radius: 0.125rem;
    border-radius: 0.125rem; }
    [data-tooltip-bottom]::after .bubble:first-child, [data-tooltip-left]::after .bubble:first-child, [data-tooltip-right]::after .bubble:first-child, [data-tooltip-top]::after .bubble:first-child,
    [data-tooltip-bottom]::after .tag:first-child,
    [data-tooltip-left]::after .tag:first-child,
    [data-tooltip-right]::after .tag:first-child,
    [data-tooltip-top]::after .tag:first-child {
      margin-left: -0.1875rem;
      margin-right: 0.1875rem; }
    [data-tooltip-bottom]::after .bubble:last-child, [data-tooltip-left]::after .bubble:last-child, [data-tooltip-right]::after .bubble:last-child, [data-tooltip-top]::after .bubble:last-child,
    [data-tooltip-bottom]::after .tag:last-child,
    [data-tooltip-left]::after .tag:last-child,
    [data-tooltip-right]::after .tag:last-child,
    [data-tooltip-top]::after .tag:last-child {
      margin-left: 0.1875rem;
      margin-right: -0.1875rem; }
    [data-tooltip-bottom]::after .bubble:only-child, [data-tooltip-left]::after .bubble:only-child, [data-tooltip-right]::after .bubble:only-child, [data-tooltip-top]::after .bubble:only-child,
    [data-tooltip-bottom]::after .tag:only-child,
    [data-tooltip-left]::after .tag:only-child,
    [data-tooltip-right]::after .tag:only-child,
    [data-tooltip-top]::after .tag:only-child {
      margin-left: -0.375rem;
      margin-right: -0.375rem; }
    [data-tooltip-bottom]::after .ec-icon, [data-tooltip-left]::after .ec-icon, [data-tooltip-right]::after .ec-icon, [data-tooltip-top]::after .ec-icon {
      padding-right: 0.1875rem; }
      @supports (display: inline-flex) {
        [data-tooltip-bottom]::after .ec-icon, [data-tooltip-left]::after .ec-icon, [data-tooltip-right]::after .ec-icon, [data-tooltip-top]::after .ec-icon {
          display: inline-flex;
          align-items: center;
          justify-content: center; } }
      [data-tooltip-bottom]::after .ec-icon::before, [data-tooltip-left]::after .ec-icon::before, [data-tooltip-right]::after .ec-icon::before, [data-tooltip-top]::after .ec-icon::before {
        font-size: 1.33333em;
        line-height: inherit;
        vertical-align: top; }
      [data-tooltip-bottom]::after .ec-icon_right, [data-tooltip-left]::after .ec-icon_right, [data-tooltip-right]::after .ec-icon_right, [data-tooltip-top]::after .ec-icon_right {
        padding-left: 0.1875rem;
        padding-right: 0; }
      [data-tooltip-bottom]::after .ec-icon:empty, [data-tooltip-left]::after .ec-icon:empty, [data-tooltip-right]::after .ec-icon:empty, [data-tooltip-top]::after .ec-icon:empty {
        padding-left: 0;
        padding-right: 0; }
        [data-tooltip-bottom]::after .ec-icon:empty:first-child, [data-tooltip-left]::after .ec-icon:empty:first-child, [data-tooltip-right]::after .ec-icon:empty:first-child, [data-tooltip-top]::after .ec-icon:empty:first-child {
          margin-left: -0.09375rem;
          margin-right: 0.1875rem; }
        [data-tooltip-bottom]::after .ec-icon:empty:last-child, [data-tooltip-left]::after .ec-icon:empty:last-child, [data-tooltip-right]::after .ec-icon:empty:last-child, [data-tooltip-top]::after .ec-icon:empty:last-child {
          margin-left: 0.1875rem;
          margin-right: -0.09375rem; }
        [data-tooltip-bottom]::after .ec-icon:empty:only-child, [data-tooltip-left]::after .ec-icon:empty:only-child, [data-tooltip-right]::after .ec-icon:empty:only-child, [data-tooltip-top]::after .ec-icon:empty:only-child {
          margin-left: -0.375rem;
          margin-right: -0.375rem; }
        [data-tooltip-bottom]::after .ec-icon:empty::before, [data-tooltip-left]::after .ec-icon:empty::before, [data-tooltip-right]::after .ec-icon:empty::before, [data-tooltip-top]::after .ec-icon:empty::before {
          margin-left: 0;
          margin-right: 0; }
  [data-tooltip-bottom]:hover::after, [data-tooltip-left]:hover::after, [data-tooltip-right]:hover::after, [data-tooltip-top]:hover::after {
    display: block;
    opacity: 1; }

[data-tooltip-bottom]::after {
  content: attr(data-tooltip-bottom);
  animation: tooltipBottom 0.2s forwards;
  left: 50%;
  opacity: 0;
  top: 100%; }

[data-tooltip-bottom]:hover::after {
  margin-top: 0.25rem; }

[data-tooltip-left]::after {
  content: attr(data-tooltip-left);
  animation: tooltipLeft 0.2s forwards;
  opacity: 0;
  right: 100%;
  top: 50%; }

[data-tooltip-left]:hover::after {
  margin-right: 0.25rem; }

[data-tooltip-right]::after {
  content: attr(data-tooltip-right);
  animation: tooltipRight 0.2s forwards;
  left: 100%;
  opacity: 0;
  top: 50%; }

[data-tooltip-right]:hover::after {
  margin-right: 0.25rem; }

[data-tooltip-top]::after {
  content: attr(data-tooltip-top);
  animation: tooltipTop 0.2s forwards;
  bottom: 100%;
  left: 50%;
  opacity: 0; }

[data-tooltip-top]:hover::after {
  margin-top: 0.25rem; }

.is-raised {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) , 0 2px 8px rgba(0, 0, 0, 0.1); }
  @media (min-width: 0px) {
    .is-raised-xs {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) , 0 2px 8px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 480px) {
    .is-raised-sm {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) , 0 2px 8px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 720px) {
    .is-raised-md {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) , 0 2px 8px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 1024px) {
    .is-raised-nav {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) , 0 2px 8px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 1024px) {
    .is-raised-lg {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) , 0 2px 8px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 1280px) {
    .is-raised-xl {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) , 0 2px 8px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 1920px) {
    .is-raised-xxl {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) , 0 2px 8px rgba(0, 0, 0, 0.1); } }

.is-raised-small {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) , 0 1px 4px rgba(0, 0, 0, 0.1); }
  @media (min-width: 0px) {
    .is-raised-small-xs {
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) , 0 1px 4px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 480px) {
    .is-raised-small-sm {
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) , 0 1px 4px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 720px) {
    .is-raised-small-md {
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) , 0 1px 4px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 1024px) {
    .is-raised-small-nav {
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) , 0 1px 4px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 1024px) {
    .is-raised-small-lg {
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) , 0 1px 4px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 1280px) {
    .is-raised-small-xl {
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) , 0 1px 4px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 1920px) {
    .is-raised-small-xxl {
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) , 0 1px 4px rgba(0, 0, 0, 0.1); } }

.is-raised-big {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) , 0 4px 16px rgba(0, 0, 0, 0.1); }
  @media (min-width: 0px) {
    .is-raised-big-xs {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) , 0 4px 16px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 480px) {
    .is-raised-big-sm {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) , 0 4px 16px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 720px) {
    .is-raised-big-md {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) , 0 4px 16px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 1024px) {
    .is-raised-big-nav {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) , 0 4px 16px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 1024px) {
    .is-raised-big-lg {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) , 0 4px 16px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 1280px) {
    .is-raised-big-xl {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) , 0 4px 16px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 1920px) {
    .is-raised-big-xxl {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) , 0 4px 16px rgba(0, 0, 0, 0.1); } }

a.is-raised:focus, a.is-raised:hover,
.input.is-raised:focus,
.input.is-raised:hover,
.input_clear.is-raised:focus,
.input_clear.is-raised:hover,
.btn.is-raised:focus,
.btn.is-raised:hover {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1); }

@media (min-width: 0px) {
  a.is-raised-xs:focus, a.is-raised-xs:hover,
  .input.is-raised-xs:focus,
  .input.is-raised-xs:hover,
  .input_clear.is-raised-xs:focus,
  .input_clear.is-raised-xs:hover,
  .btn.is-raised-xs:focus,
  .btn.is-raised-xs:hover {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1); } }

@media (min-width: 480px) {
  a.is-raised-sm:focus, a.is-raised-sm:hover,
  .input.is-raised-sm:focus,
  .input.is-raised-sm:hover,
  .input_clear.is-raised-sm:focus,
  .input_clear.is-raised-sm:hover,
  .btn.is-raised-sm:focus,
  .btn.is-raised-sm:hover {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1); } }

@media (min-width: 720px) {
  a.is-raised-md:focus, a.is-raised-md:hover,
  .input.is-raised-md:focus,
  .input.is-raised-md:hover,
  .input_clear.is-raised-md:focus,
  .input_clear.is-raised-md:hover,
  .btn.is-raised-md:focus,
  .btn.is-raised-md:hover {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1); } }

@media (min-width: 1024px) {
  a.is-raised-nav:focus, a.is-raised-nav:hover,
  .input.is-raised-nav:focus,
  .input.is-raised-nav:hover,
  .input_clear.is-raised-nav:focus,
  .input_clear.is-raised-nav:hover,
  .btn.is-raised-nav:focus,
  .btn.is-raised-nav:hover {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1); } }

@media (min-width: 1024px) {
  a.is-raised-lg:focus, a.is-raised-lg:hover,
  .input.is-raised-lg:focus,
  .input.is-raised-lg:hover,
  .input_clear.is-raised-lg:focus,
  .input_clear.is-raised-lg:hover,
  .btn.is-raised-lg:focus,
  .btn.is-raised-lg:hover {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1); } }

@media (min-width: 1280px) {
  a.is-raised-xl:focus, a.is-raised-xl:hover,
  .input.is-raised-xl:focus,
  .input.is-raised-xl:hover,
  .input_clear.is-raised-xl:focus,
  .input_clear.is-raised-xl:hover,
  .btn.is-raised-xl:focus,
  .btn.is-raised-xl:hover {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1); } }

@media (min-width: 1920px) {
  a.is-raised-xxl:focus, a.is-raised-xxl:hover,
  .input.is-raised-xxl:focus,
  .input.is-raised-xxl:hover,
  .input_clear.is-raised-xxl:focus,
  .input_clear.is-raised-xxl:hover,
  .btn.is-raised-xxl:focus,
  .btn.is-raised-xxl:hover {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1); } }

a.is-raised-small:focus, a.is-raised-small:hover,
.input.is-raised-small:focus,
.input.is-raised-small:hover,
.input_clear.is-raised-small:focus,
.input_clear.is-raised-small:hover,
.btn.is-raised-small:focus,
.btn.is-raised-small:hover {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) , 0 1px 2px rgba(0, 0, 0, 0.1); }

@media (min-width: 0px) {
  a.is-raised-small-xs:focus, a.is-raised-small-xs:hover,
  .input.is-raised-small-xs:focus,
  .input.is-raised-small-xs:hover,
  .input_clear.is-raised-small-xs:focus,
  .input_clear.is-raised-small-xs:hover,
  .btn.is-raised-small-xs:focus,
  .btn.is-raised-small-xs:hover {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) , 0 1px 2px rgba(0, 0, 0, 0.1); } }

@media (min-width: 480px) {
  a.is-raised-small-sm:focus, a.is-raised-small-sm:hover,
  .input.is-raised-small-sm:focus,
  .input.is-raised-small-sm:hover,
  .input_clear.is-raised-small-sm:focus,
  .input_clear.is-raised-small-sm:hover,
  .btn.is-raised-small-sm:focus,
  .btn.is-raised-small-sm:hover {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) , 0 1px 2px rgba(0, 0, 0, 0.1); } }

@media (min-width: 720px) {
  a.is-raised-small-md:focus, a.is-raised-small-md:hover,
  .input.is-raised-small-md:focus,
  .input.is-raised-small-md:hover,
  .input_clear.is-raised-small-md:focus,
  .input_clear.is-raised-small-md:hover,
  .btn.is-raised-small-md:focus,
  .btn.is-raised-small-md:hover {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) , 0 1px 2px rgba(0, 0, 0, 0.1); } }

@media (min-width: 1024px) {
  a.is-raised-small-nav:focus, a.is-raised-small-nav:hover,
  .input.is-raised-small-nav:focus,
  .input.is-raised-small-nav:hover,
  .input_clear.is-raised-small-nav:focus,
  .input_clear.is-raised-small-nav:hover,
  .btn.is-raised-small-nav:focus,
  .btn.is-raised-small-nav:hover {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) , 0 1px 2px rgba(0, 0, 0, 0.1); } }

@media (min-width: 1024px) {
  a.is-raised-small-lg:focus, a.is-raised-small-lg:hover,
  .input.is-raised-small-lg:focus,
  .input.is-raised-small-lg:hover,
  .input_clear.is-raised-small-lg:focus,
  .input_clear.is-raised-small-lg:hover,
  .btn.is-raised-small-lg:focus,
  .btn.is-raised-small-lg:hover {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) , 0 1px 2px rgba(0, 0, 0, 0.1); } }

@media (min-width: 1280px) {
  a.is-raised-small-xl:focus, a.is-raised-small-xl:hover,
  .input.is-raised-small-xl:focus,
  .input.is-raised-small-xl:hover,
  .input_clear.is-raised-small-xl:focus,
  .input_clear.is-raised-small-xl:hover,
  .btn.is-raised-small-xl:focus,
  .btn.is-raised-small-xl:hover {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) , 0 1px 2px rgba(0, 0, 0, 0.1); } }

@media (min-width: 1920px) {
  a.is-raised-small-xxl:focus, a.is-raised-small-xxl:hover,
  .input.is-raised-small-xxl:focus,
  .input.is-raised-small-xxl:hover,
  .input_clear.is-raised-small-xxl:focus,
  .input_clear.is-raised-small-xxl:hover,
  .btn.is-raised-small-xxl:focus,
  .btn.is-raised-small-xxl:hover {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) , 0 1px 2px rgba(0, 0, 0, 0.1); } }

a.is-raised-big:focus, a.is-raised-big:hover,
.input.is-raised-big:focus,
.input.is-raised-big:hover,
.input_clear.is-raised-big:focus,
.input_clear.is-raised-big:hover,
.btn.is-raised-big:focus,
.btn.is-raised-big:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) , 0 2px 8px rgba(0, 0, 0, 0.1); }

@media (min-width: 0px) {
  a.is-raised-big-xs:focus, a.is-raised-big-xs:hover,
  .input.is-raised-big-xs:focus,
  .input.is-raised-big-xs:hover,
  .input_clear.is-raised-big-xs:focus,
  .input_clear.is-raised-big-xs:hover,
  .btn.is-raised-big-xs:focus,
  .btn.is-raised-big-xs:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) , 0 2px 8px rgba(0, 0, 0, 0.1); } }

@media (min-width: 480px) {
  a.is-raised-big-sm:focus, a.is-raised-big-sm:hover,
  .input.is-raised-big-sm:focus,
  .input.is-raised-big-sm:hover,
  .input_clear.is-raised-big-sm:focus,
  .input_clear.is-raised-big-sm:hover,
  .btn.is-raised-big-sm:focus,
  .btn.is-raised-big-sm:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) , 0 2px 8px rgba(0, 0, 0, 0.1); } }

@media (min-width: 720px) {
  a.is-raised-big-md:focus, a.is-raised-big-md:hover,
  .input.is-raised-big-md:focus,
  .input.is-raised-big-md:hover,
  .input_clear.is-raised-big-md:focus,
  .input_clear.is-raised-big-md:hover,
  .btn.is-raised-big-md:focus,
  .btn.is-raised-big-md:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) , 0 2px 8px rgba(0, 0, 0, 0.1); } }

@media (min-width: 1024px) {
  a.is-raised-big-nav:focus, a.is-raised-big-nav:hover,
  .input.is-raised-big-nav:focus,
  .input.is-raised-big-nav:hover,
  .input_clear.is-raised-big-nav:focus,
  .input_clear.is-raised-big-nav:hover,
  .btn.is-raised-big-nav:focus,
  .btn.is-raised-big-nav:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) , 0 2px 8px rgba(0, 0, 0, 0.1); } }

@media (min-width: 1024px) {
  a.is-raised-big-lg:focus, a.is-raised-big-lg:hover,
  .input.is-raised-big-lg:focus,
  .input.is-raised-big-lg:hover,
  .input_clear.is-raised-big-lg:focus,
  .input_clear.is-raised-big-lg:hover,
  .btn.is-raised-big-lg:focus,
  .btn.is-raised-big-lg:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) , 0 2px 8px rgba(0, 0, 0, 0.1); } }

@media (min-width: 1280px) {
  a.is-raised-big-xl:focus, a.is-raised-big-xl:hover,
  .input.is-raised-big-xl:focus,
  .input.is-raised-big-xl:hover,
  .input_clear.is-raised-big-xl:focus,
  .input_clear.is-raised-big-xl:hover,
  .btn.is-raised-big-xl:focus,
  .btn.is-raised-big-xl:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) , 0 2px 8px rgba(0, 0, 0, 0.1); } }

@media (min-width: 1920px) {
  a.is-raised-big-xxl:focus, a.is-raised-big-xxl:hover,
  .input.is-raised-big-xxl:focus,
  .input.is-raised-big-xxl:hover,
  .input_clear.is-raised-big-xxl:focus,
  .input_clear.is-raised-big-xxl:hover,
  .btn.is-raised-big-xxl:focus,
  .btn.is-raised-big-xxl:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) , 0 2px 8px rgba(0, 0, 0, 0.1); } }

.is-lifted {
  box-shadow: 0 16px 16px -4px rgba(0, 0, 0, 0.2) , 0 16px 24px rgba(0, 0, 0, 0.1); }
  @media (min-width: 0px) {
    .is-lifted-xs {
      box-shadow: 0 16px 16px -4px rgba(0, 0, 0, 0.2) , 0 16px 24px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 480px) {
    .is-lifted-sm {
      box-shadow: 0 16px 16px -4px rgba(0, 0, 0, 0.2) , 0 16px 24px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 720px) {
    .is-lifted-md {
      box-shadow: 0 16px 16px -4px rgba(0, 0, 0, 0.2) , 0 16px 24px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 1024px) {
    .is-lifted-nav {
      box-shadow: 0 16px 16px -4px rgba(0, 0, 0, 0.2) , 0 16px 24px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 1024px) {
    .is-lifted-lg {
      box-shadow: 0 16px 16px -4px rgba(0, 0, 0, 0.2) , 0 16px 24px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 1280px) {
    .is-lifted-xl {
      box-shadow: 0 16px 16px -4px rgba(0, 0, 0, 0.2) , 0 16px 24px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 1920px) {
    .is-lifted-xxl {
      box-shadow: 0 16px 16px -4px rgba(0, 0, 0, 0.2) , 0 16px 24px rgba(0, 0, 0, 0.1); } }

.is-lifted-small {
  box-shadow: 0 12px 8px -8px rgba(0, 0, 0, 0.1); }
  @media (min-width: 0px) {
    .is-lifted-small-xs {
      box-shadow: 0 12px 8px -8px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 480px) {
    .is-lifted-small-sm {
      box-shadow: 0 12px 8px -8px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 720px) {
    .is-lifted-small-md {
      box-shadow: 0 12px 8px -8px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 1024px) {
    .is-lifted-small-nav {
      box-shadow: 0 12px 8px -8px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 1024px) {
    .is-lifted-small-lg {
      box-shadow: 0 12px 8px -8px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 1280px) {
    .is-lifted-small-xl {
      box-shadow: 0 12px 8px -8px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 1920px) {
    .is-lifted-small-xxl {
      box-shadow: 0 12px 8px -8px rgba(0, 0, 0, 0.1); } }

.is-lifted-big {
  box-shadow: 0 24px 28px 0 rgba(0, 0, 0, 0.2) , 0 24px 40px rgba(0, 0, 0, 0.1); }
  @media (min-width: 0px) {
    .is-lifted-big-xs {
      box-shadow: 0 24px 28px 0 rgba(0, 0, 0, 0.2) , 0 24px 40px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 480px) {
    .is-lifted-big-sm {
      box-shadow: 0 24px 28px 0 rgba(0, 0, 0, 0.2) , 0 24px 40px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 720px) {
    .is-lifted-big-md {
      box-shadow: 0 24px 28px 0 rgba(0, 0, 0, 0.2) , 0 24px 40px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 1024px) {
    .is-lifted-big-nav {
      box-shadow: 0 24px 28px 0 rgba(0, 0, 0, 0.2) , 0 24px 40px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 1024px) {
    .is-lifted-big-lg {
      box-shadow: 0 24px 28px 0 rgba(0, 0, 0, 0.2) , 0 24px 40px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 1280px) {
    .is-lifted-big-xl {
      box-shadow: 0 24px 28px 0 rgba(0, 0, 0, 0.2) , 0 24px 40px rgba(0, 0, 0, 0.1); } }
  @media (min-width: 1920px) {
    .is-lifted-big-xxl {
      box-shadow: 0 24px 28px 0 rgba(0, 0, 0, 0.2) , 0 24px 40px rgba(0, 0, 0, 0.1); } }

a.is-lifted:hover, a.is-lifted:focus,
.input.is-lifted:hover,
.input.is-lifted:focus,
.btn.is-lifted:hover,
.btn.is-lifted:focus {
  box-shadow: 0 12px 8px -8px rgba(0, 0, 0, 0.1); }

@media (min-width: 0px) {
  a.is-lifted-xs:hover, a.is-lifted-xs:focus,
  .input.is-lifted-xs:hover,
  .input.is-lifted-xs:focus,
  .btn.is-lifted-xs:hover,
  .btn.is-lifted-xs:focus {
    box-shadow: 0 12px 8px -8px rgba(0, 0, 0, 0.1); } }

@media (min-width: 480px) {
  a.is-lifted-sm:hover, a.is-lifted-sm:focus,
  .input.is-lifted-sm:hover,
  .input.is-lifted-sm:focus,
  .btn.is-lifted-sm:hover,
  .btn.is-lifted-sm:focus {
    box-shadow: 0 12px 8px -8px rgba(0, 0, 0, 0.1); } }

@media (min-width: 720px) {
  a.is-lifted-md:hover, a.is-lifted-md:focus,
  .input.is-lifted-md:hover,
  .input.is-lifted-md:focus,
  .btn.is-lifted-md:hover,
  .btn.is-lifted-md:focus {
    box-shadow: 0 12px 8px -8px rgba(0, 0, 0, 0.1); } }

@media (min-width: 1024px) {
  a.is-lifted-nav:hover, a.is-lifted-nav:focus,
  .input.is-lifted-nav:hover,
  .input.is-lifted-nav:focus,
  .btn.is-lifted-nav:hover,
  .btn.is-lifted-nav:focus {
    box-shadow: 0 12px 8px -8px rgba(0, 0, 0, 0.1); } }

@media (min-width: 1024px) {
  a.is-lifted-lg:hover, a.is-lifted-lg:focus,
  .input.is-lifted-lg:hover,
  .input.is-lifted-lg:focus,
  .btn.is-lifted-lg:hover,
  .btn.is-lifted-lg:focus {
    box-shadow: 0 12px 8px -8px rgba(0, 0, 0, 0.1); } }

@media (min-width: 1280px) {
  a.is-lifted-xl:hover, a.is-lifted-xl:focus,
  .input.is-lifted-xl:hover,
  .input.is-lifted-xl:focus,
  .btn.is-lifted-xl:hover,
  .btn.is-lifted-xl:focus {
    box-shadow: 0 12px 8px -8px rgba(0, 0, 0, 0.1); } }

@media (min-width: 1920px) {
  a.is-lifted-xxl:hover, a.is-lifted-xxl:focus,
  .input.is-lifted-xxl:hover,
  .input.is-lifted-xxl:focus,
  .btn.is-lifted-xxl:hover,
  .btn.is-lifted-xxl:focus {
    box-shadow: 0 12px 8px -8px rgba(0, 0, 0, 0.1); } }

a.is-lifted-small:hover, a.is-lifted-small:focus,
.input.is-lifted-small:hover,
.input.is-lifted-small:focus,
.btn.is-lifted-small:hover,
.btn.is-lifted-small:focus {
  box-shadow: 0 6px 4px -2px rgba(0, 0, 0, 0.1); }

@media (min-width: 0px) {
  a.is-lifted-small-xs:hover, a.is-lifted-small-xs:focus,
  .input.is-lifted-small-xs:hover,
  .input.is-lifted-small-xs:focus,
  .btn.is-lifted-small-xs:hover,
  .btn.is-lifted-small-xs:focus {
    box-shadow: 0 6px 4px -2px rgba(0, 0, 0, 0.1); } }

@media (min-width: 480px) {
  a.is-lifted-small-sm:hover, a.is-lifted-small-sm:focus,
  .input.is-lifted-small-sm:hover,
  .input.is-lifted-small-sm:focus,
  .btn.is-lifted-small-sm:hover,
  .btn.is-lifted-small-sm:focus {
    box-shadow: 0 6px 4px -2px rgba(0, 0, 0, 0.1); } }

@media (min-width: 720px) {
  a.is-lifted-small-md:hover, a.is-lifted-small-md:focus,
  .input.is-lifted-small-md:hover,
  .input.is-lifted-small-md:focus,
  .btn.is-lifted-small-md:hover,
  .btn.is-lifted-small-md:focus {
    box-shadow: 0 6px 4px -2px rgba(0, 0, 0, 0.1); } }

@media (min-width: 1024px) {
  a.is-lifted-small-nav:hover, a.is-lifted-small-nav:focus,
  .input.is-lifted-small-nav:hover,
  .input.is-lifted-small-nav:focus,
  .btn.is-lifted-small-nav:hover,
  .btn.is-lifted-small-nav:focus {
    box-shadow: 0 6px 4px -2px rgba(0, 0, 0, 0.1); } }

@media (min-width: 1024px) {
  a.is-lifted-small-lg:hover, a.is-lifted-small-lg:focus,
  .input.is-lifted-small-lg:hover,
  .input.is-lifted-small-lg:focus,
  .btn.is-lifted-small-lg:hover,
  .btn.is-lifted-small-lg:focus {
    box-shadow: 0 6px 4px -2px rgba(0, 0, 0, 0.1); } }

@media (min-width: 1280px) {
  a.is-lifted-small-xl:hover, a.is-lifted-small-xl:focus,
  .input.is-lifted-small-xl:hover,
  .input.is-lifted-small-xl:focus,
  .btn.is-lifted-small-xl:hover,
  .btn.is-lifted-small-xl:focus {
    box-shadow: 0 6px 4px -2px rgba(0, 0, 0, 0.1); } }

@media (min-width: 1920px) {
  a.is-lifted-small-xxl:hover, a.is-lifted-small-xxl:focus,
  .input.is-lifted-small-xxl:hover,
  .input.is-lifted-small-xxl:focus,
  .btn.is-lifted-small-xxl:hover,
  .btn.is-lifted-small-xxl:focus {
    box-shadow: 0 6px 4px -2px rgba(0, 0, 0, 0.1); } }

a-big:hover, a-big:focus,
.input-big:hover,
.input-big:focus,
.btn-big:hover,
.btn-big:focus {
  box-shadow: 0 12px 8px -8px rgba(0, 0, 0, 0.1); }

@media (min-width: 0px) {
  a-big-xs:hover, a-big-xs:focus,
  .input-big-xs:hover,
  .input-big-xs:focus,
  .btn-big-xs:hover,
  .btn-big-xs:focus {
    box-shadow: 0 12px 8px -8px rgba(0, 0, 0, 0.1); } }

@media (min-width: 480px) {
  a-big-sm:hover, a-big-sm:focus,
  .input-big-sm:hover,
  .input-big-sm:focus,
  .btn-big-sm:hover,
  .btn-big-sm:focus {
    box-shadow: 0 12px 8px -8px rgba(0, 0, 0, 0.1); } }

@media (min-width: 720px) {
  a-big-md:hover, a-big-md:focus,
  .input-big-md:hover,
  .input-big-md:focus,
  .btn-big-md:hover,
  .btn-big-md:focus {
    box-shadow: 0 12px 8px -8px rgba(0, 0, 0, 0.1); } }

@media (min-width: 1024px) {
  a-big-nav:hover, a-big-nav:focus,
  .input-big-nav:hover,
  .input-big-nav:focus,
  .btn-big-nav:hover,
  .btn-big-nav:focus {
    box-shadow: 0 12px 8px -8px rgba(0, 0, 0, 0.1); } }

@media (min-width: 1024px) {
  a-big-lg:hover, a-big-lg:focus,
  .input-big-lg:hover,
  .input-big-lg:focus,
  .btn-big-lg:hover,
  .btn-big-lg:focus {
    box-shadow: 0 12px 8px -8px rgba(0, 0, 0, 0.1); } }

@media (min-width: 1280px) {
  a-big-xl:hover, a-big-xl:focus,
  .input-big-xl:hover,
  .input-big-xl:focus,
  .btn-big-xl:hover,
  .btn-big-xl:focus {
    box-shadow: 0 12px 8px -8px rgba(0, 0, 0, 0.1); } }

@media (min-width: 1920px) {
  a-big-xxl:hover, a-big-xxl:focus,
  .input-big-xxl:hover,
  .input-big-xxl:focus,
  .btn-big-xxl:hover,
  .btn-big-xxl:focus {
    box-shadow: 0 12px 8px -8px rgba(0, 0, 0, 0.1); } }

.is-elevated-1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.1), 0px 1px 1px 0px rgba(0, 0, 0, 0.08), 0px 1px 3px 0px rgba(0, 0, 0, 0.06); }

.is-elevated-2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.1), 0px 2px 2px 0px rgba(0, 0, 0, 0.08), 0px 1px 5px 0px rgba(0, 0, 0, 0.06); }

.is-elevated-3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.1), 0px 3px 4px 0px rgba(0, 0, 0, 0.08), 0px 1px 8px 0px rgba(0, 0, 0, 0.06); }

.is-elevated-4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.1), 0px 4px 5px 0px rgba(0, 0, 0, 0.08), 0px 1px 10px 0px rgba(0, 0, 0, 0.06); }

.is-elevated-5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.1), 0px 5px 8px 0px rgba(0, 0, 0, 0.08), 0px 1px 14px 0px rgba(0, 0, 0, 0.06); }

.is-elevated-6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.1), 0px 6px 10px 0px rgba(0, 0, 0, 0.08), 0px 1px 18px 0px rgba(0, 0, 0, 0.06); }

.is-elevated-7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.1), 0px 7px 10px 1px rgba(0, 0, 0, 0.08), 0px 2px 16px 1px rgba(0, 0, 0, 0.06); }

.is-elevated-8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.1), 0px 8px 10px 1px rgba(0, 0, 0, 0.08), 0px 3px 14px 2px rgba(0, 0, 0, 0.06); }

.is-elevated-9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.1), 0px 9px 12px 1px rgba(0, 0, 0, 0.08), 0px 3px 16px 2px rgba(0, 0, 0, 0.06); }

.is-elevated-10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.1), 0px 10px 14px 1px rgba(0, 0, 0, 0.08), 0px 4px 18px 3px rgba(0, 0, 0, 0.06); }

.is-elevated-11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.1), 0px 11px 15px 1px rgba(0, 0, 0, 0.08), 0px 4px 20px 3px rgba(0, 0, 0, 0.06); }

.is-elevated-12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.1), 0px 12px 17px 2px rgba(0, 0, 0, 0.08), 0px 5px 22px 4px rgba(0, 0, 0, 0.06); }

.is-elevated-13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.1), 0px 13px 19px 2px rgba(0, 0, 0, 0.08), 0px 5px 24px 4px rgba(0, 0, 0, 0.06); }

.is-elevated-14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.1), 0px 14px 21px 2px rgba(0, 0, 0, 0.08), 0px 5px 26px 4px rgba(0, 0, 0, 0.06); }

.is-elevated-15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.1), 0px 15px 22px 2px rgba(0, 0, 0, 0.08), 0px 6px 28px 5px rgba(0, 0, 0, 0.06); }

.is-elevated-16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.1), 0px 16px 24px 2px rgba(0, 0, 0, 0.08), 0px 6px 30px 5px rgba(0, 0, 0, 0.06); }

.is-elevated-17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.1), 0px 17px 26px 2px rgba(0, 0, 0, 0.08), 0px 6px 32px 5px rgba(0, 0, 0, 0.06); }

.is-elevated-18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.1), 0px 18px 28px 2px rgba(0, 0, 0, 0.08), 0px 7px 34px 6px rgba(0, 0, 0, 0.06); }

.is-elevated-19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.1), 0px 19px 29px 2px rgba(0, 0, 0, 0.08), 0px 7px 36px 6px rgba(0, 0, 0, 0.06); }

.is-elevated-20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.1), 0px 20px 31px 3px rgba(0, 0, 0, 0.08), 0px 8px 38px 7px rgba(0, 0, 0, 0.06); }

.is-elevated-21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.1), 0px 21px 33px 3px rgba(0, 0, 0, 0.08), 0px 8px 40px 7px rgba(0, 0, 0, 0.06); }

.is-elevated-22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.1), 0px 22px 35px 3px rgba(0, 0, 0, 0.08), 0px 8px 42px 7px rgba(0, 0, 0, 0.06); }

.is-elevated-23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.1), 0px 23px 36px 3px rgba(0, 0, 0, 0.08), 0px 9px 44px 8px rgba(0, 0, 0, 0.06); }

.is-elevated-24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.1), 0px 24px 38px 3px rgba(0, 0, 0, 0.08), 0px 9px 46px 8px rgba(0, 0, 0, 0.06); }

.is-rounded {
  border-radius: 0.25rem; }
  @media (min-width: 0px) {
    .is-rounded-xs {
      border-radius: 0.25rem; } }
  @media (min-width: 480px) {
    .is-rounded-sm {
      border-radius: 0.25rem; } }
  @media (min-width: 720px) {
    .is-rounded-md {
      border-radius: 0.25rem; } }
  @media (min-width: 1024px) {
    .is-rounded-nav {
      border-radius: 0.25rem; } }
  @media (min-width: 1024px) {
    .is-rounded-lg {
      border-radius: 0.25rem; } }
  @media (min-width: 1280px) {
    .is-rounded-xl {
      border-radius: 0.25rem; } }
  @media (min-width: 1920px) {
    .is-rounded-xxl {
      border-radius: 0.25rem; } }
  .is-rounded-0 {
    border-radius: 0; }
    @media (min-width: 0px) {
      .is-rounded-0-xs {
        border-radius: 0; } }
    @media (min-width: 480px) {
      .is-rounded-0-sm {
        border-radius: 0; } }
    @media (min-width: 720px) {
      .is-rounded-0-md {
        border-radius: 0; } }
    @media (min-width: 1024px) {
      .is-rounded-0-nav {
        border-radius: 0; } }
    @media (min-width: 1024px) {
      .is-rounded-0-lg {
        border-radius: 0; } }
    @media (min-width: 1280px) {
      .is-rounded-0-xl {
        border-radius: 0; } }
    @media (min-width: 1920px) {
      .is-rounded-0-xxl {
        border-radius: 0; } }
  .is-rounded-top {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    @media (min-width: 0px) {
      .is-rounded-top-xs {
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem; } }
    @media (min-width: 480px) {
      .is-rounded-top-sm {
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem; } }
    @media (min-width: 720px) {
      .is-rounded-top-md {
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem; } }
    @media (min-width: 1024px) {
      .is-rounded-top-nav {
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem; } }
    @media (min-width: 1024px) {
      .is-rounded-top-lg {
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem; } }
    @media (min-width: 1280px) {
      .is-rounded-top-xl {
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem; } }
    @media (min-width: 1920px) {
      .is-rounded-top-xxl {
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem; } }
    .is-rounded-top-0 {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
      @media (min-width: 0px) {
        .is-rounded-top-0-xs {
          border-top-left-radius: 0;
          border-top-right-radius: 0; } }
      @media (min-width: 480px) {
        .is-rounded-top-0-sm {
          border-top-left-radius: 0;
          border-top-right-radius: 0; } }
      @media (min-width: 720px) {
        .is-rounded-top-0-md {
          border-top-left-radius: 0;
          border-top-right-radius: 0; } }
      @media (min-width: 1024px) {
        .is-rounded-top-0-nav {
          border-top-left-radius: 0;
          border-top-right-radius: 0; } }
      @media (min-width: 1024px) {
        .is-rounded-top-0-lg {
          border-top-left-radius: 0;
          border-top-right-radius: 0; } }
      @media (min-width: 1280px) {
        .is-rounded-top-0-xl {
          border-top-left-radius: 0;
          border-top-right-radius: 0; } }
      @media (min-width: 1920px) {
        .is-rounded-top-0-xxl {
          border-top-left-radius: 0;
          border-top-right-radius: 0; } }
  .is-rounded-right {
    border-bottom-right-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    @media (min-width: 0px) {
      .is-rounded-right-xs {
        border-bottom-right-radius: 0.25rem;
        border-top-right-radius: 0.25rem; } }
    @media (min-width: 480px) {
      .is-rounded-right-sm {
        border-bottom-right-radius: 0.25rem;
        border-top-right-radius: 0.25rem; } }
    @media (min-width: 720px) {
      .is-rounded-right-md {
        border-bottom-right-radius: 0.25rem;
        border-top-right-radius: 0.25rem; } }
    @media (min-width: 1024px) {
      .is-rounded-right-nav {
        border-bottom-right-radius: 0.25rem;
        border-top-right-radius: 0.25rem; } }
    @media (min-width: 1024px) {
      .is-rounded-right-lg {
        border-bottom-right-radius: 0.25rem;
        border-top-right-radius: 0.25rem; } }
    @media (min-width: 1280px) {
      .is-rounded-right-xl {
        border-bottom-right-radius: 0.25rem;
        border-top-right-radius: 0.25rem; } }
    @media (min-width: 1920px) {
      .is-rounded-right-xxl {
        border-bottom-right-radius: 0.25rem;
        border-top-right-radius: 0.25rem; } }
    .is-rounded-right-0 {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0; }
      @media (min-width: 0px) {
        .is-rounded-right-0-xs {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0; } }
      @media (min-width: 480px) {
        .is-rounded-right-0-sm {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0; } }
      @media (min-width: 720px) {
        .is-rounded-right-0-md {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0; } }
      @media (min-width: 1024px) {
        .is-rounded-right-0-nav {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0; } }
      @media (min-width: 1024px) {
        .is-rounded-right-0-lg {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0; } }
      @media (min-width: 1280px) {
        .is-rounded-right-0-xl {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0; } }
      @media (min-width: 1920px) {
        .is-rounded-right-0-xxl {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0; } }
  .is-rounded-bottom {
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem; }
    @media (min-width: 0px) {
      .is-rounded-bottom-xs {
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem; } }
    @media (min-width: 480px) {
      .is-rounded-bottom-sm {
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem; } }
    @media (min-width: 720px) {
      .is-rounded-bottom-md {
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem; } }
    @media (min-width: 1024px) {
      .is-rounded-bottom-nav {
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem; } }
    @media (min-width: 1024px) {
      .is-rounded-bottom-lg {
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem; } }
    @media (min-width: 1280px) {
      .is-rounded-bottom-xl {
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem; } }
    @media (min-width: 1920px) {
      .is-rounded-bottom-xxl {
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem; } }
    .is-rounded-bottom-0 {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0; }
      @media (min-width: 0px) {
        .is-rounded-bottom-0-xs {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0; } }
      @media (min-width: 480px) {
        .is-rounded-bottom-0-sm {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0; } }
      @media (min-width: 720px) {
        .is-rounded-bottom-0-md {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0; } }
      @media (min-width: 1024px) {
        .is-rounded-bottom-0-nav {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0; } }
      @media (min-width: 1024px) {
        .is-rounded-bottom-0-lg {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0; } }
      @media (min-width: 1280px) {
        .is-rounded-bottom-0-xl {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0; } }
      @media (min-width: 1920px) {
        .is-rounded-bottom-0-xxl {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0; } }
  .is-rounded-left {
    border-bottom-left-radius: 0.25rem;
    border-top-left-radius: 0.25rem; }
    @media (min-width: 0px) {
      .is-rounded-left-xs {
        border-bottom-left-radius: 0.25rem;
        border-top-left-radius: 0.25rem; } }
    @media (min-width: 480px) {
      .is-rounded-left-sm {
        border-bottom-left-radius: 0.25rem;
        border-top-left-radius: 0.25rem; } }
    @media (min-width: 720px) {
      .is-rounded-left-md {
        border-bottom-left-radius: 0.25rem;
        border-top-left-radius: 0.25rem; } }
    @media (min-width: 1024px) {
      .is-rounded-left-nav {
        border-bottom-left-radius: 0.25rem;
        border-top-left-radius: 0.25rem; } }
    @media (min-width: 1024px) {
      .is-rounded-left-lg {
        border-bottom-left-radius: 0.25rem;
        border-top-left-radius: 0.25rem; } }
    @media (min-width: 1280px) {
      .is-rounded-left-xl {
        border-bottom-left-radius: 0.25rem;
        border-top-left-radius: 0.25rem; } }
    @media (min-width: 1920px) {
      .is-rounded-left-xxl {
        border-bottom-left-radius: 0.25rem;
        border-top-left-radius: 0.25rem; } }
    .is-rounded-left-0 {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0; }
      @media (min-width: 0px) {
        .is-rounded-left-0-xs {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0; } }
      @media (min-width: 480px) {
        .is-rounded-left-0-sm {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0; } }
      @media (min-width: 720px) {
        .is-rounded-left-0-md {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0; } }
      @media (min-width: 1024px) {
        .is-rounded-left-0-nav {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0; } }
      @media (min-width: 1024px) {
        .is-rounded-left-0-lg {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0; } }
      @media (min-width: 1280px) {
        .is-rounded-left-0-xl {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0; } }
      @media (min-width: 1920px) {
        .is-rounded-left-0-xxl {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0; } }
  .is-rounded-small {
    border-radius: 0.125rem; }
    @media (min-width: 0px) {
      .is-rounded-small-xs {
        border-radius: 0.125rem; } }
    @media (min-width: 480px) {
      .is-rounded-small-sm {
        border-radius: 0.125rem; } }
    @media (min-width: 720px) {
      .is-rounded-small-md {
        border-radius: 0.125rem; } }
    @media (min-width: 1024px) {
      .is-rounded-small-nav {
        border-radius: 0.125rem; } }
    @media (min-width: 1024px) {
      .is-rounded-small-lg {
        border-radius: 0.125rem; } }
    @media (min-width: 1280px) {
      .is-rounded-small-xl {
        border-radius: 0.125rem; } }
    @media (min-width: 1920px) {
      .is-rounded-small-xxl {
        border-radius: 0.125rem; } }
    .is-rounded-small-0 {
      border-radius: 0; }
      @media (min-width: 0px) {
        .is-rounded-small-0-xs {
          border-radius: 0; } }
      @media (min-width: 480px) {
        .is-rounded-small-0-sm {
          border-radius: 0; } }
      @media (min-width: 720px) {
        .is-rounded-small-0-md {
          border-radius: 0; } }
      @media (min-width: 1024px) {
        .is-rounded-small-0-nav {
          border-radius: 0; } }
      @media (min-width: 1024px) {
        .is-rounded-small-0-lg {
          border-radius: 0; } }
      @media (min-width: 1280px) {
        .is-rounded-small-0-xl {
          border-radius: 0; } }
      @media (min-width: 1920px) {
        .is-rounded-small-0-xxl {
          border-radius: 0; } }
    .is-rounded-small-top {
      border-top-left-radius: 0.125rem;
      border-top-right-radius: 0.125rem; }
      @media (min-width: 0px) {
        .is-rounded-small-top-xs {
          border-top-left-radius: 0.125rem;
          border-top-right-radius: 0.125rem; } }
      @media (min-width: 480px) {
        .is-rounded-small-top-sm {
          border-top-left-radius: 0.125rem;
          border-top-right-radius: 0.125rem; } }
      @media (min-width: 720px) {
        .is-rounded-small-top-md {
          border-top-left-radius: 0.125rem;
          border-top-right-radius: 0.125rem; } }
      @media (min-width: 1024px) {
        .is-rounded-small-top-nav {
          border-top-left-radius: 0.125rem;
          border-top-right-radius: 0.125rem; } }
      @media (min-width: 1024px) {
        .is-rounded-small-top-lg {
          border-top-left-radius: 0.125rem;
          border-top-right-radius: 0.125rem; } }
      @media (min-width: 1280px) {
        .is-rounded-small-top-xl {
          border-top-left-radius: 0.125rem;
          border-top-right-radius: 0.125rem; } }
      @media (min-width: 1920px) {
        .is-rounded-small-top-xxl {
          border-top-left-radius: 0.125rem;
          border-top-right-radius: 0.125rem; } }
      .is-rounded-small-top-0 {
        border-top-left-radius: 0;
        border-top-right-radius: 0; }
        @media (min-width: 0px) {
          .is-rounded-small-top-0-xs {
            border-top-left-radius: 0;
            border-top-right-radius: 0; } }
        @media (min-width: 480px) {
          .is-rounded-small-top-0-sm {
            border-top-left-radius: 0;
            border-top-right-radius: 0; } }
        @media (min-width: 720px) {
          .is-rounded-small-top-0-md {
            border-top-left-radius: 0;
            border-top-right-radius: 0; } }
        @media (min-width: 1024px) {
          .is-rounded-small-top-0-nav {
            border-top-left-radius: 0;
            border-top-right-radius: 0; } }
        @media (min-width: 1024px) {
          .is-rounded-small-top-0-lg {
            border-top-left-radius: 0;
            border-top-right-radius: 0; } }
        @media (min-width: 1280px) {
          .is-rounded-small-top-0-xl {
            border-top-left-radius: 0;
            border-top-right-radius: 0; } }
        @media (min-width: 1920px) {
          .is-rounded-small-top-0-xxl {
            border-top-left-radius: 0;
            border-top-right-radius: 0; } }
    .is-rounded-small-right {
      border-bottom-right-radius: 0.125rem;
      border-top-right-radius: 0.125rem; }
      @media (min-width: 0px) {
        .is-rounded-small-right-xs {
          border-bottom-right-radius: 0.125rem;
          border-top-right-radius: 0.125rem; } }
      @media (min-width: 480px) {
        .is-rounded-small-right-sm {
          border-bottom-right-radius: 0.125rem;
          border-top-right-radius: 0.125rem; } }
      @media (min-width: 720px) {
        .is-rounded-small-right-md {
          border-bottom-right-radius: 0.125rem;
          border-top-right-radius: 0.125rem; } }
      @media (min-width: 1024px) {
        .is-rounded-small-right-nav {
          border-bottom-right-radius: 0.125rem;
          border-top-right-radius: 0.125rem; } }
      @media (min-width: 1024px) {
        .is-rounded-small-right-lg {
          border-bottom-right-radius: 0.125rem;
          border-top-right-radius: 0.125rem; } }
      @media (min-width: 1280px) {
        .is-rounded-small-right-xl {
          border-bottom-right-radius: 0.125rem;
          border-top-right-radius: 0.125rem; } }
      @media (min-width: 1920px) {
        .is-rounded-small-right-xxl {
          border-bottom-right-radius: 0.125rem;
          border-top-right-radius: 0.125rem; } }
      .is-rounded-small-right-0 {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0; }
        @media (min-width: 0px) {
          .is-rounded-small-right-0-xs {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0; } }
        @media (min-width: 480px) {
          .is-rounded-small-right-0-sm {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0; } }
        @media (min-width: 720px) {
          .is-rounded-small-right-0-md {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0; } }
        @media (min-width: 1024px) {
          .is-rounded-small-right-0-nav {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0; } }
        @media (min-width: 1024px) {
          .is-rounded-small-right-0-lg {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0; } }
        @media (min-width: 1280px) {
          .is-rounded-small-right-0-xl {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0; } }
        @media (min-width: 1920px) {
          .is-rounded-small-right-0-xxl {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0; } }
    .is-rounded-small-bottom {
      border-bottom-left-radius: 0.125rem;
      border-bottom-right-radius: 0.125rem; }
      @media (min-width: 0px) {
        .is-rounded-small-bottom-xs {
          border-bottom-left-radius: 0.125rem;
          border-bottom-right-radius: 0.125rem; } }
      @media (min-width: 480px) {
        .is-rounded-small-bottom-sm {
          border-bottom-left-radius: 0.125rem;
          border-bottom-right-radius: 0.125rem; } }
      @media (min-width: 720px) {
        .is-rounded-small-bottom-md {
          border-bottom-left-radius: 0.125rem;
          border-bottom-right-radius: 0.125rem; } }
      @media (min-width: 1024px) {
        .is-rounded-small-bottom-nav {
          border-bottom-left-radius: 0.125rem;
          border-bottom-right-radius: 0.125rem; } }
      @media (min-width: 1024px) {
        .is-rounded-small-bottom-lg {
          border-bottom-left-radius: 0.125rem;
          border-bottom-right-radius: 0.125rem; } }
      @media (min-width: 1280px) {
        .is-rounded-small-bottom-xl {
          border-bottom-left-radius: 0.125rem;
          border-bottom-right-radius: 0.125rem; } }
      @media (min-width: 1920px) {
        .is-rounded-small-bottom-xxl {
          border-bottom-left-radius: 0.125rem;
          border-bottom-right-radius: 0.125rem; } }
      .is-rounded-small-bottom-0 {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0; }
        @media (min-width: 0px) {
          .is-rounded-small-bottom-0-xs {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0; } }
        @media (min-width: 480px) {
          .is-rounded-small-bottom-0-sm {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0; } }
        @media (min-width: 720px) {
          .is-rounded-small-bottom-0-md {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0; } }
        @media (min-width: 1024px) {
          .is-rounded-small-bottom-0-nav {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0; } }
        @media (min-width: 1024px) {
          .is-rounded-small-bottom-0-lg {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0; } }
        @media (min-width: 1280px) {
          .is-rounded-small-bottom-0-xl {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0; } }
        @media (min-width: 1920px) {
          .is-rounded-small-bottom-0-xxl {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0; } }
    .is-rounded-small-left {
      border-bottom-left-radius: 0.125rem;
      border-top-left-radius: 0.125rem; }
      @media (min-width: 0px) {
        .is-rounded-small-left-xs {
          border-bottom-left-radius: 0.125rem;
          border-top-left-radius: 0.125rem; } }
      @media (min-width: 480px) {
        .is-rounded-small-left-sm {
          border-bottom-left-radius: 0.125rem;
          border-top-left-radius: 0.125rem; } }
      @media (min-width: 720px) {
        .is-rounded-small-left-md {
          border-bottom-left-radius: 0.125rem;
          border-top-left-radius: 0.125rem; } }
      @media (min-width: 1024px) {
        .is-rounded-small-left-nav {
          border-bottom-left-radius: 0.125rem;
          border-top-left-radius: 0.125rem; } }
      @media (min-width: 1024px) {
        .is-rounded-small-left-lg {
          border-bottom-left-radius: 0.125rem;
          border-top-left-radius: 0.125rem; } }
      @media (min-width: 1280px) {
        .is-rounded-small-left-xl {
          border-bottom-left-radius: 0.125rem;
          border-top-left-radius: 0.125rem; } }
      @media (min-width: 1920px) {
        .is-rounded-small-left-xxl {
          border-bottom-left-radius: 0.125rem;
          border-top-left-radius: 0.125rem; } }
      .is-rounded-small-left-0 {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0; }
        @media (min-width: 0px) {
          .is-rounded-small-left-0-xs {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0; } }
        @media (min-width: 480px) {
          .is-rounded-small-left-0-sm {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0; } }
        @media (min-width: 720px) {
          .is-rounded-small-left-0-md {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0; } }
        @media (min-width: 1024px) {
          .is-rounded-small-left-0-nav {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0; } }
        @media (min-width: 1024px) {
          .is-rounded-small-left-0-lg {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0; } }
        @media (min-width: 1280px) {
          .is-rounded-small-left-0-xl {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0; } }
        @media (min-width: 1920px) {
          .is-rounded-small-left-0-xxl {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0; } }
  .is-rounded-big {
    border-radius: 0.5rem; }
    @media (min-width: 0px) {
      .is-rounded-big-xs {
        border-radius: 0.5rem; } }
    @media (min-width: 480px) {
      .is-rounded-big-sm {
        border-radius: 0.5rem; } }
    @media (min-width: 720px) {
      .is-rounded-big-md {
        border-radius: 0.5rem; } }
    @media (min-width: 1024px) {
      .is-rounded-big-nav {
        border-radius: 0.5rem; } }
    @media (min-width: 1024px) {
      .is-rounded-big-lg {
        border-radius: 0.5rem; } }
    @media (min-width: 1280px) {
      .is-rounded-big-xl {
        border-radius: 0.5rem; } }
    @media (min-width: 1920px) {
      .is-rounded-big-xxl {
        border-radius: 0.5rem; } }
    .is-rounded-big-0 {
      border-radius: 0; }
      @media (min-width: 0px) {
        .is-rounded-big-0-xs {
          border-radius: 0; } }
      @media (min-width: 480px) {
        .is-rounded-big-0-sm {
          border-radius: 0; } }
      @media (min-width: 720px) {
        .is-rounded-big-0-md {
          border-radius: 0; } }
      @media (min-width: 1024px) {
        .is-rounded-big-0-nav {
          border-radius: 0; } }
      @media (min-width: 1024px) {
        .is-rounded-big-0-lg {
          border-radius: 0; } }
      @media (min-width: 1280px) {
        .is-rounded-big-0-xl {
          border-radius: 0; } }
      @media (min-width: 1920px) {
        .is-rounded-big-0-xxl {
          border-radius: 0; } }
    .is-rounded-big-top {
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem; }
      @media (min-width: 0px) {
        .is-rounded-big-top-xs {
          border-top-left-radius: 0.5rem;
          border-top-right-radius: 0.5rem; } }
      @media (min-width: 480px) {
        .is-rounded-big-top-sm {
          border-top-left-radius: 0.5rem;
          border-top-right-radius: 0.5rem; } }
      @media (min-width: 720px) {
        .is-rounded-big-top-md {
          border-top-left-radius: 0.5rem;
          border-top-right-radius: 0.5rem; } }
      @media (min-width: 1024px) {
        .is-rounded-big-top-nav {
          border-top-left-radius: 0.5rem;
          border-top-right-radius: 0.5rem; } }
      @media (min-width: 1024px) {
        .is-rounded-big-top-lg {
          border-top-left-radius: 0.5rem;
          border-top-right-radius: 0.5rem; } }
      @media (min-width: 1280px) {
        .is-rounded-big-top-xl {
          border-top-left-radius: 0.5rem;
          border-top-right-radius: 0.5rem; } }
      @media (min-width: 1920px) {
        .is-rounded-big-top-xxl {
          border-top-left-radius: 0.5rem;
          border-top-right-radius: 0.5rem; } }
      .is-rounded-big-top-0 {
        border-top-left-radius: 0;
        border-top-right-radius: 0; }
        @media (min-width: 0px) {
          .is-rounded-big-top-0-xs {
            border-top-left-radius: 0;
            border-top-right-radius: 0; } }
        @media (min-width: 480px) {
          .is-rounded-big-top-0-sm {
            border-top-left-radius: 0;
            border-top-right-radius: 0; } }
        @media (min-width: 720px) {
          .is-rounded-big-top-0-md {
            border-top-left-radius: 0;
            border-top-right-radius: 0; } }
        @media (min-width: 1024px) {
          .is-rounded-big-top-0-nav {
            border-top-left-radius: 0;
            border-top-right-radius: 0; } }
        @media (min-width: 1024px) {
          .is-rounded-big-top-0-lg {
            border-top-left-radius: 0;
            border-top-right-radius: 0; } }
        @media (min-width: 1280px) {
          .is-rounded-big-top-0-xl {
            border-top-left-radius: 0;
            border-top-right-radius: 0; } }
        @media (min-width: 1920px) {
          .is-rounded-big-top-0-xxl {
            border-top-left-radius: 0;
            border-top-right-radius: 0; } }
    .is-rounded-big-right {
      border-bottom-right-radius: 0.5rem;
      border-top-right-radius: 0.5rem; }
      @media (min-width: 0px) {
        .is-rounded-big-right-xs {
          border-bottom-right-radius: 0.5rem;
          border-top-right-radius: 0.5rem; } }
      @media (min-width: 480px) {
        .is-rounded-big-right-sm {
          border-bottom-right-radius: 0.5rem;
          border-top-right-radius: 0.5rem; } }
      @media (min-width: 720px) {
        .is-rounded-big-right-md {
          border-bottom-right-radius: 0.5rem;
          border-top-right-radius: 0.5rem; } }
      @media (min-width: 1024px) {
        .is-rounded-big-right-nav {
          border-bottom-right-radius: 0.5rem;
          border-top-right-radius: 0.5rem; } }
      @media (min-width: 1024px) {
        .is-rounded-big-right-lg {
          border-bottom-right-radius: 0.5rem;
          border-top-right-radius: 0.5rem; } }
      @media (min-width: 1280px) {
        .is-rounded-big-right-xl {
          border-bottom-right-radius: 0.5rem;
          border-top-right-radius: 0.5rem; } }
      @media (min-width: 1920px) {
        .is-rounded-big-right-xxl {
          border-bottom-right-radius: 0.5rem;
          border-top-right-radius: 0.5rem; } }
      .is-rounded-big-right-0 {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0; }
        @media (min-width: 0px) {
          .is-rounded-big-right-0-xs {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0; } }
        @media (min-width: 480px) {
          .is-rounded-big-right-0-sm {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0; } }
        @media (min-width: 720px) {
          .is-rounded-big-right-0-md {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0; } }
        @media (min-width: 1024px) {
          .is-rounded-big-right-0-nav {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0; } }
        @media (min-width: 1024px) {
          .is-rounded-big-right-0-lg {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0; } }
        @media (min-width: 1280px) {
          .is-rounded-big-right-0-xl {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0; } }
        @media (min-width: 1920px) {
          .is-rounded-big-right-0-xxl {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0; } }
    .is-rounded-big-bottom {
      border-bottom-left-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem; }
      @media (min-width: 0px) {
        .is-rounded-big-bottom-xs {
          border-bottom-left-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem; } }
      @media (min-width: 480px) {
        .is-rounded-big-bottom-sm {
          border-bottom-left-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem; } }
      @media (min-width: 720px) {
        .is-rounded-big-bottom-md {
          border-bottom-left-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem; } }
      @media (min-width: 1024px) {
        .is-rounded-big-bottom-nav {
          border-bottom-left-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem; } }
      @media (min-width: 1024px) {
        .is-rounded-big-bottom-lg {
          border-bottom-left-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem; } }
      @media (min-width: 1280px) {
        .is-rounded-big-bottom-xl {
          border-bottom-left-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem; } }
      @media (min-width: 1920px) {
        .is-rounded-big-bottom-xxl {
          border-bottom-left-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem; } }
      .is-rounded-big-bottom-0 {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0; }
        @media (min-width: 0px) {
          .is-rounded-big-bottom-0-xs {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0; } }
        @media (min-width: 480px) {
          .is-rounded-big-bottom-0-sm {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0; } }
        @media (min-width: 720px) {
          .is-rounded-big-bottom-0-md {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0; } }
        @media (min-width: 1024px) {
          .is-rounded-big-bottom-0-nav {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0; } }
        @media (min-width: 1024px) {
          .is-rounded-big-bottom-0-lg {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0; } }
        @media (min-width: 1280px) {
          .is-rounded-big-bottom-0-xl {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0; } }
        @media (min-width: 1920px) {
          .is-rounded-big-bottom-0-xxl {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0; } }
    .is-rounded-big-left {
      border-bottom-left-radius: 0.5rem;
      border-top-left-radius: 0.5rem; }
      @media (min-width: 0px) {
        .is-rounded-big-left-xs {
          border-bottom-left-radius: 0.5rem;
          border-top-left-radius: 0.5rem; } }
      @media (min-width: 480px) {
        .is-rounded-big-left-sm {
          border-bottom-left-radius: 0.5rem;
          border-top-left-radius: 0.5rem; } }
      @media (min-width: 720px) {
        .is-rounded-big-left-md {
          border-bottom-left-radius: 0.5rem;
          border-top-left-radius: 0.5rem; } }
      @media (min-width: 1024px) {
        .is-rounded-big-left-nav {
          border-bottom-left-radius: 0.5rem;
          border-top-left-radius: 0.5rem; } }
      @media (min-width: 1024px) {
        .is-rounded-big-left-lg {
          border-bottom-left-radius: 0.5rem;
          border-top-left-radius: 0.5rem; } }
      @media (min-width: 1280px) {
        .is-rounded-big-left-xl {
          border-bottom-left-radius: 0.5rem;
          border-top-left-radius: 0.5rem; } }
      @media (min-width: 1920px) {
        .is-rounded-big-left-xxl {
          border-bottom-left-radius: 0.5rem;
          border-top-left-radius: 0.5rem; } }
      .is-rounded-big-left-0 {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0; }
        @media (min-width: 0px) {
          .is-rounded-big-left-0-xs {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0; } }
        @media (min-width: 480px) {
          .is-rounded-big-left-0-sm {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0; } }
        @media (min-width: 720px) {
          .is-rounded-big-left-0-md {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0; } }
        @media (min-width: 1024px) {
          .is-rounded-big-left-0-nav {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0; } }
        @media (min-width: 1024px) {
          .is-rounded-big-left-0-lg {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0; } }
        @media (min-width: 1280px) {
          .is-rounded-big-left-0-xl {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0; } }
        @media (min-width: 1920px) {
          .is-rounded-big-left-0-xxl {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0; } }

.is-bordered {
  border: 0.0625rem solid rgba(42, 61, 84, 0.1); }
  @media (min-width: 0px) {
    .is-bordered-xs {
      border: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
  @media (min-width: 480px) {
    .is-bordered-sm {
      border: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
  @media (min-width: 720px) {
    .is-bordered-md {
      border: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
  @media (min-width: 1024px) {
    .is-bordered-nav {
      border: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
  @media (min-width: 1024px) {
    .is-bordered-lg {
      border: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
  @media (min-width: 1280px) {
    .is-bordered-xl {
      border: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
  @media (min-width: 1920px) {
    .is-bordered-xxl {
      border: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
  .is-bordered-top {
    border-top: 0.0625rem solid rgba(42, 61, 84, 0.1); }
    @media (min-width: 0px) {
      .is-bordered-top-xs {
        border-top: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 480px) {
      .is-bordered-top-sm {
        border-top: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 720px) {
      .is-bordered-top-md {
        border-top: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 1024px) {
      .is-bordered-top-nav {
        border-top: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 1024px) {
      .is-bordered-top-lg {
        border-top: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 1280px) {
      .is-bordered-top-xl {
        border-top: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 1920px) {
      .is-bordered-top-xxl {
        border-top: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
  .is-bordered-right {
    border-right: 0.0625rem solid rgba(42, 61, 84, 0.1); }
    @media (min-width: 0px) {
      .is-bordered-right-xs {
        border-right: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 480px) {
      .is-bordered-right-sm {
        border-right: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 720px) {
      .is-bordered-right-md {
        border-right: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 1024px) {
      .is-bordered-right-nav {
        border-right: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 1024px) {
      .is-bordered-right-lg {
        border-right: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 1280px) {
      .is-bordered-right-xl {
        border-right: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 1920px) {
      .is-bordered-right-xxl {
        border-right: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
  .is-bordered-bottom {
    border-bottom: 0.0625rem solid rgba(42, 61, 84, 0.1); }
    @media (min-width: 0px) {
      .is-bordered-bottom-xs {
        border-bottom: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 480px) {
      .is-bordered-bottom-sm {
        border-bottom: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 720px) {
      .is-bordered-bottom-md {
        border-bottom: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 1024px) {
      .is-bordered-bottom-nav {
        border-bottom: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 1024px) {
      .is-bordered-bottom-lg {
        border-bottom: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 1280px) {
      .is-bordered-bottom-xl {
        border-bottom: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 1920px) {
      .is-bordered-bottom-xxl {
        border-bottom: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
  .is-bordered-left {
    border-left: 0.0625rem solid rgba(42, 61, 84, 0.1); }
    @media (min-width: 0px) {
      .is-bordered-left-xs {
        border-left: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 480px) {
      .is-bordered-left-sm {
        border-left: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 720px) {
      .is-bordered-left-md {
        border-left: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 1024px) {
      .is-bordered-left-nav {
        border-left: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 1024px) {
      .is-bordered-left-lg {
        border-left: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 1280px) {
      .is-bordered-left-xl {
        border-left: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 1920px) {
      .is-bordered-left-xxl {
        border-left: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
  .is-bordered-middle {
    border-bottom: 0.0625rem solid rgba(42, 61, 84, 0.1);
    border-top: 0.0625rem solid rgba(42, 61, 84, 0.1); }
    @media (min-width: 0px) {
      .is-bordered-middle-xs {
        border-bottom: 0.0625rem solid rgba(42, 61, 84, 0.1);
        border-top: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 480px) {
      .is-bordered-middle-sm {
        border-bottom: 0.0625rem solid rgba(42, 61, 84, 0.1);
        border-top: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 720px) {
      .is-bordered-middle-md {
        border-bottom: 0.0625rem solid rgba(42, 61, 84, 0.1);
        border-top: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 1024px) {
      .is-bordered-middle-nav {
        border-bottom: 0.0625rem solid rgba(42, 61, 84, 0.1);
        border-top: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 1024px) {
      .is-bordered-middle-lg {
        border-bottom: 0.0625rem solid rgba(42, 61, 84, 0.1);
        border-top: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 1280px) {
      .is-bordered-middle-xl {
        border-bottom: 0.0625rem solid rgba(42, 61, 84, 0.1);
        border-top: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 1920px) {
      .is-bordered-middle-xxl {
        border-bottom: 0.0625rem solid rgba(42, 61, 84, 0.1);
        border-top: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
  .is-bordered-center {
    border-left: 0.0625rem solid rgba(42, 61, 84, 0.1);
    border-right: 0.0625rem solid rgba(42, 61, 84, 0.1); }
    @media (min-width: 0px) {
      .is-bordered-center-xs {
        border-left: 0.0625rem solid rgba(42, 61, 84, 0.1);
        border-right: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 480px) {
      .is-bordered-center-sm {
        border-left: 0.0625rem solid rgba(42, 61, 84, 0.1);
        border-right: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 720px) {
      .is-bordered-center-md {
        border-left: 0.0625rem solid rgba(42, 61, 84, 0.1);
        border-right: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 1024px) {
      .is-bordered-center-nav {
        border-left: 0.0625rem solid rgba(42, 61, 84, 0.1);
        border-right: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 1024px) {
      .is-bordered-center-lg {
        border-left: 0.0625rem solid rgba(42, 61, 84, 0.1);
        border-right: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 1280px) {
      .is-bordered-center-xl {
        border-left: 0.0625rem solid rgba(42, 61, 84, 0.1);
        border-right: 0.0625rem solid rgba(42, 61, 84, 0.1); } }
    @media (min-width: 1920px) {
      .is-bordered-center-xxl {
        border-left: 0.0625rem solid rgba(42, 61, 84, 0.1);
        border-right: 0.0625rem solid rgba(42, 61, 84, 0.1); } }

.is-fab-n {
  left: 50%;
  top: 1rem;
  transform: translateX(-50%); }
  @media (min-width: 0px) {
    .is-fab-n-xs {
      left: 50%;
      top: 1rem;
      transform: translateX(-50%); } }
  @media (min-width: 480px) {
    .is-fab-n-sm {
      left: 50%;
      top: 1rem;
      transform: translateX(-50%); } }
  @media (min-width: 720px) {
    .is-fab-n-md {
      left: 50%;
      top: 1rem;
      transform: translateX(-50%); } }
  @media (min-width: 1024px) {
    .is-fab-n-nav {
      left: 50%;
      top: 1rem;
      transform: translateX(-50%); } }
  @media (min-width: 1024px) {
    .is-fab-n-lg {
      left: 50%;
      top: 1rem;
      transform: translateX(-50%); } }
  @media (min-width: 1280px) {
    .is-fab-n-xl {
      left: 50%;
      top: 1rem;
      transform: translateX(-50%); } }
  @media (min-width: 1920px) {
    .is-fab-n-xxl {
      left: 50%;
      top: 1rem;
      transform: translateX(-50%); } }

.is-fab-ne {
  right: 1rem;
  top: 1rem; }
  @media (min-width: 0px) {
    .is-fab-ne-xs {
      right: 1rem;
      top: 1rem; } }
  @media (min-width: 480px) {
    .is-fab-ne-sm {
      right: 1rem;
      top: 1rem; } }
  @media (min-width: 720px) {
    .is-fab-ne-md {
      right: 1rem;
      top: 1rem; } }
  @media (min-width: 1024px) {
    .is-fab-ne-nav {
      right: 1rem;
      top: 1rem; } }
  @media (min-width: 1024px) {
    .is-fab-ne-lg {
      right: 1rem;
      top: 1rem; } }
  @media (min-width: 1280px) {
    .is-fab-ne-xl {
      right: 1rem;
      top: 1rem; } }
  @media (min-width: 1920px) {
    .is-fab-ne-xxl {
      right: 1rem;
      top: 1rem; } }

.is-fab-e {
  right: 1rem;
  top: 50%;
  transform: translateY(-50%); }
  @media (min-width: 0px) {
    .is-fab-e-xs {
      right: 1rem;
      top: 50%;
      transform: translateY(-50%); } }
  @media (min-width: 480px) {
    .is-fab-e-sm {
      right: 1rem;
      top: 50%;
      transform: translateY(-50%); } }
  @media (min-width: 720px) {
    .is-fab-e-md {
      right: 1rem;
      top: 50%;
      transform: translateY(-50%); } }
  @media (min-width: 1024px) {
    .is-fab-e-nav {
      right: 1rem;
      top: 50%;
      transform: translateY(-50%); } }
  @media (min-width: 1024px) {
    .is-fab-e-lg {
      right: 1rem;
      top: 50%;
      transform: translateY(-50%); } }
  @media (min-width: 1280px) {
    .is-fab-e-xl {
      right: 1rem;
      top: 50%;
      transform: translateY(-50%); } }
  @media (min-width: 1920px) {
    .is-fab-e-xxl {
      right: 1rem;
      top: 50%;
      transform: translateY(-50%); } }

.is-fab-se {
  bottom: 1rem;
  right: 1rem; }
  @media (min-width: 0px) {
    .is-fab-se-xs {
      bottom: 1rem;
      right: 1rem; } }
  @media (min-width: 480px) {
    .is-fab-se-sm {
      bottom: 1rem;
      right: 1rem; } }
  @media (min-width: 720px) {
    .is-fab-se-md {
      bottom: 1rem;
      right: 1rem; } }
  @media (min-width: 1024px) {
    .is-fab-se-nav {
      bottom: 1rem;
      right: 1rem; } }
  @media (min-width: 1024px) {
    .is-fab-se-lg {
      bottom: 1rem;
      right: 1rem; } }
  @media (min-width: 1280px) {
    .is-fab-se-xl {
      bottom: 1rem;
      right: 1rem; } }
  @media (min-width: 1920px) {
    .is-fab-se-xxl {
      bottom: 1rem;
      right: 1rem; } }

.is-fab-s {
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%); }
  @media (min-width: 0px) {
    .is-fab-s-xs {
      bottom: 1rem;
      left: 50%;
      transform: translateX(-50%); } }
  @media (min-width: 480px) {
    .is-fab-s-sm {
      bottom: 1rem;
      left: 50%;
      transform: translateX(-50%); } }
  @media (min-width: 720px) {
    .is-fab-s-md {
      bottom: 1rem;
      left: 50%;
      transform: translateX(-50%); } }
  @media (min-width: 1024px) {
    .is-fab-s-nav {
      bottom: 1rem;
      left: 50%;
      transform: translateX(-50%); } }
  @media (min-width: 1024px) {
    .is-fab-s-lg {
      bottom: 1rem;
      left: 50%;
      transform: translateX(-50%); } }
  @media (min-width: 1280px) {
    .is-fab-s-xl {
      bottom: 1rem;
      left: 50%;
      transform: translateX(-50%); } }
  @media (min-width: 1920px) {
    .is-fab-s-xxl {
      bottom: 1rem;
      left: 50%;
      transform: translateX(-50%); } }

.is-fab-sw {
  bottom: 1rem;
  left: 1rem; }
  @media (min-width: 0px) {
    .is-fab-sw-xs {
      bottom: 1rem;
      left: 1rem; } }
  @media (min-width: 480px) {
    .is-fab-sw-sm {
      bottom: 1rem;
      left: 1rem; } }
  @media (min-width: 720px) {
    .is-fab-sw-md {
      bottom: 1rem;
      left: 1rem; } }
  @media (min-width: 1024px) {
    .is-fab-sw-nav {
      bottom: 1rem;
      left: 1rem; } }
  @media (min-width: 1024px) {
    .is-fab-sw-lg {
      bottom: 1rem;
      left: 1rem; } }
  @media (min-width: 1280px) {
    .is-fab-sw-xl {
      bottom: 1rem;
      left: 1rem; } }
  @media (min-width: 1920px) {
    .is-fab-sw-xxl {
      bottom: 1rem;
      left: 1rem; } }

.is-fab-w {
  left: 1rem;
  top: 50%;
  transform: translateY(-50%); }
  @media (min-width: 0px) {
    .is-fab-w-xs {
      left: 1rem;
      top: 50%;
      transform: translateY(-50%); } }
  @media (min-width: 480px) {
    .is-fab-w-sm {
      left: 1rem;
      top: 50%;
      transform: translateY(-50%); } }
  @media (min-width: 720px) {
    .is-fab-w-md {
      left: 1rem;
      top: 50%;
      transform: translateY(-50%); } }
  @media (min-width: 1024px) {
    .is-fab-w-nav {
      left: 1rem;
      top: 50%;
      transform: translateY(-50%); } }
  @media (min-width: 1024px) {
    .is-fab-w-lg {
      left: 1rem;
      top: 50%;
      transform: translateY(-50%); } }
  @media (min-width: 1280px) {
    .is-fab-w-xl {
      left: 1rem;
      top: 50%;
      transform: translateY(-50%); } }
  @media (min-width: 1920px) {
    .is-fab-w-xxl {
      left: 1rem;
      top: 50%;
      transform: translateY(-50%); } }

.is-fab-nw {
  left: 1rem;
  top: 1rem; }
  @media (min-width: 0px) {
    .is-fab-nw-xs {
      left: 1rem;
      top: 1rem; } }
  @media (min-width: 480px) {
    .is-fab-nw-sm {
      left: 1rem;
      top: 1rem; } }
  @media (min-width: 720px) {
    .is-fab-nw-md {
      left: 1rem;
      top: 1rem; } }
  @media (min-width: 1024px) {
    .is-fab-nw-nav {
      left: 1rem;
      top: 1rem; } }
  @media (min-width: 1024px) {
    .is-fab-nw-lg {
      left: 1rem;
      top: 1rem; } }
  @media (min-width: 1280px) {
    .is-fab-nw-xl {
      left: 1rem;
      top: 1rem; } }
  @media (min-width: 1920px) {
    .is-fab-nw-xxl {
      left: 1rem;
      top: 1rem; } }

.is-fab-0 {
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%); }
  @media (min-width: 0px) {
    .is-fab-0-xs {
      left: 50%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%); } }
  @media (min-width: 480px) {
    .is-fab-0-sm {
      left: 50%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%); } }
  @media (min-width: 720px) {
    .is-fab-0-md {
      left: 50%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%); } }
  @media (min-width: 1024px) {
    .is-fab-0-nav {
      left: 50%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%); } }
  @media (min-width: 1024px) {
    .is-fab-0-lg {
      left: 50%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%); } }
  @media (min-width: 1280px) {
    .is-fab-0-xl {
      left: 50%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%); } }
  @media (min-width: 1920px) {
    .is-fab-0-xxl {
      left: 50%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%); } }

[class*="is-fab-"] {
  position: fixed;
  z-index: 10; }

.has-scroll-shadow {
  background: linear-gradient(#fff 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), #fff 70%) 0 100%, radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
  background-attachment: local, local, scroll, scroll;
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: 100% 40px, 100% 40px, 100% 8px, 100% 8px; }

.is-outset {
  width: 100%;
  padding: 1rem 0;
  margin: 0; }
  .is-outset img {
    width: 100%; }
  .is-outset:first-child {
    padding-top: 0; }
  @media (min-width: 75rem) {
    .is-outset_left:not([data-col]), .is-outset_right:not([data-col]) {
      max-width: 50%; } }
  @media (min-width: 75rem) {
    .is-outset_left {
      margin-left: -3rem;
      float: left;
      margin-right: 2rem; } }
  @media (min-width: 75rem) {
    .is-outset_right {
      margin-right: -3rem;
      float: right;
      margin-left: 2rem; } }
  @media (min-width: 75rem) {
    .is-outset_full {
      width: initial;
      margin-right: -3rem;
      margin-left: -3rem; } }

.has-margin {
  margin:  1rem; }
  @media (min-width: 0px) {
    .has-margin-xs {
      margin:  1rem; } }
  @media (min-width: 480px) {
    .has-margin-sm {
      margin:  1rem; } }
  @media (min-width: 720px) {
    .has-margin-md {
      margin:  1rem; } }
  @media (min-width: 1024px) {
    .has-margin-nav {
      margin:  1rem; } }
  @media (min-width: 1024px) {
    .has-margin-lg {
      margin:  1rem; } }
  @media (min-width: 1280px) {
    .has-margin-xl {
      margin:  1rem; } }
  @media (min-width: 1920px) {
    .has-margin-xxl {
      margin:  1rem; } }
  .has-margin-smallest {
    margin:  0.25rem; }
    @media (min-width: 0px) {
      .has-margin-smallest-xs {
        margin:  0.25rem; } }
    @media (min-width: 480px) {
      .has-margin-smallest-sm {
        margin:  0.25rem; } }
    @media (min-width: 720px) {
      .has-margin-smallest-md {
        margin:  0.25rem; } }
    @media (min-width: 1024px) {
      .has-margin-smallest-nav {
        margin:  0.25rem; } }
    @media (min-width: 1024px) {
      .has-margin-smallest-lg {
        margin:  0.25rem; } }
    @media (min-width: 1280px) {
      .has-margin-smallest-xl {
        margin:  0.25rem; } }
    @media (min-width: 1920px) {
      .has-margin-smallest-xxl {
        margin:  0.25rem; } }
  .has-margin-small {
    margin:  0.5rem; }
    @media (min-width: 0px) {
      .has-margin-small-xs {
        margin:  0.5rem; } }
    @media (min-width: 480px) {
      .has-margin-small-sm {
        margin:  0.5rem; } }
    @media (min-width: 720px) {
      .has-margin-small-md {
        margin:  0.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-small-nav {
        margin:  0.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-small-lg {
        margin:  0.5rem; } }
    @media (min-width: 1280px) {
      .has-margin-small-xl {
        margin:  0.5rem; } }
    @media (min-width: 1920px) {
      .has-margin-small-xxl {
        margin:  0.5rem; } }
  .has-margin-big {
    margin:  2rem; }
    @media (min-width: 0px) {
      .has-margin-big-xs {
        margin:  2rem; } }
    @media (min-width: 480px) {
      .has-margin-big-sm {
        margin:  2rem; } }
    @media (min-width: 720px) {
      .has-margin-big-md {
        margin:  2rem; } }
    @media (min-width: 1024px) {
      .has-margin-big-nav {
        margin:  2rem; } }
    @media (min-width: 1024px) {
      .has-margin-big-lg {
        margin:  2rem; } }
    @media (min-width: 1280px) {
      .has-margin-big-xl {
        margin:  2rem; } }
    @media (min-width: 1920px) {
      .has-margin-big-xxl {
        margin:  2rem; } }
  .has-margin-0 {
    margin: 0; }
    @media (min-width: 0px) {
      .has-margin-0-xs {
        margin: 0; } }
    @media (min-width: 480px) {
      .has-margin-0-sm {
        margin: 0; } }
    @media (min-width: 720px) {
      .has-margin-0-md {
        margin: 0; } }
    @media (min-width: 1024px) {
      .has-margin-0-nav {
        margin: 0; } }
    @media (min-width: 1024px) {
      .has-margin-0-lg {
        margin: 0; } }
    @media (min-width: 1280px) {
      .has-margin-0-xl {
        margin: 0; } }
    @media (min-width: 1920px) {
      .has-margin-0-xxl {
        margin: 0; } }
  .has-margin-1 {
    margin: 0.25rem; }
    @media (min-width: 0px) {
      .has-margin-1-xs {
        margin: 0.25rem; } }
    @media (min-width: 480px) {
      .has-margin-1-sm {
        margin: 0.25rem; } }
    @media (min-width: 720px) {
      .has-margin-1-md {
        margin: 0.25rem; } }
    @media (min-width: 1024px) {
      .has-margin-1-nav {
        margin: 0.25rem; } }
    @media (min-width: 1024px) {
      .has-margin-1-lg {
        margin: 0.25rem; } }
    @media (min-width: 1280px) {
      .has-margin-1-xl {
        margin: 0.25rem; } }
    @media (min-width: 1920px) {
      .has-margin-1-xxl {
        margin: 0.25rem; } }
  .has-margin-2 {
    margin: 0.5rem; }
    @media (min-width: 0px) {
      .has-margin-2-xs {
        margin: 0.5rem; } }
    @media (min-width: 480px) {
      .has-margin-2-sm {
        margin: 0.5rem; } }
    @media (min-width: 720px) {
      .has-margin-2-md {
        margin: 0.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-2-nav {
        margin: 0.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-2-lg {
        margin: 0.5rem; } }
    @media (min-width: 1280px) {
      .has-margin-2-xl {
        margin: 0.5rem; } }
    @media (min-width: 1920px) {
      .has-margin-2-xxl {
        margin: 0.5rem; } }
  .has-margin-3 {
    margin: 1rem; }
    @media (min-width: 0px) {
      .has-margin-3-xs {
        margin: 1rem; } }
    @media (min-width: 480px) {
      .has-margin-3-sm {
        margin: 1rem; } }
    @media (min-width: 720px) {
      .has-margin-3-md {
        margin: 1rem; } }
    @media (min-width: 1024px) {
      .has-margin-3-nav {
        margin: 1rem; } }
    @media (min-width: 1024px) {
      .has-margin-3-lg {
        margin: 1rem; } }
    @media (min-width: 1280px) {
      .has-margin-3-xl {
        margin: 1rem; } }
    @media (min-width: 1920px) {
      .has-margin-3-xxl {
        margin: 1rem; } }
  .has-margin-4 {
    margin: 1.5rem; }
    @media (min-width: 0px) {
      .has-margin-4-xs {
        margin: 1.5rem; } }
    @media (min-width: 480px) {
      .has-margin-4-sm {
        margin: 1.5rem; } }
    @media (min-width: 720px) {
      .has-margin-4-md {
        margin: 1.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-4-nav {
        margin: 1.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-4-lg {
        margin: 1.5rem; } }
    @media (min-width: 1280px) {
      .has-margin-4-xl {
        margin: 1.5rem; } }
    @media (min-width: 1920px) {
      .has-margin-4-xxl {
        margin: 1.5rem; } }
  .has-margin-5 {
    margin: 2rem; }
    @media (min-width: 0px) {
      .has-margin-5-xs {
        margin: 2rem; } }
    @media (min-width: 480px) {
      .has-margin-5-sm {
        margin: 2rem; } }
    @media (min-width: 720px) {
      .has-margin-5-md {
        margin: 2rem; } }
    @media (min-width: 1024px) {
      .has-margin-5-nav {
        margin: 2rem; } }
    @media (min-width: 1024px) {
      .has-margin-5-lg {
        margin: 2rem; } }
    @media (min-width: 1280px) {
      .has-margin-5-xl {
        margin: 2rem; } }
    @media (min-width: 1920px) {
      .has-margin-5-xxl {
        margin: 2rem; } }
  .has-margin-6 {
    margin: 3rem; }
    @media (min-width: 0px) {
      .has-margin-6-xs {
        margin: 3rem; } }
    @media (min-width: 480px) {
      .has-margin-6-sm {
        margin: 3rem; } }
    @media (min-width: 720px) {
      .has-margin-6-md {
        margin: 3rem; } }
    @media (min-width: 1024px) {
      .has-margin-6-nav {
        margin: 3rem; } }
    @media (min-width: 1024px) {
      .has-margin-6-lg {
        margin: 3rem; } }
    @media (min-width: 1280px) {
      .has-margin-6-xl {
        margin: 3rem; } }
    @media (min-width: 1920px) {
      .has-margin-6-xxl {
        margin: 3rem; } }
  .has-margin-7 {
    margin: 4rem; }
    @media (min-width: 0px) {
      .has-margin-7-xs {
        margin: 4rem; } }
    @media (min-width: 480px) {
      .has-margin-7-sm {
        margin: 4rem; } }
    @media (min-width: 720px) {
      .has-margin-7-md {
        margin: 4rem; } }
    @media (min-width: 1024px) {
      .has-margin-7-nav {
        margin: 4rem; } }
    @media (min-width: 1024px) {
      .has-margin-7-lg {
        margin: 4rem; } }
    @media (min-width: 1280px) {
      .has-margin-7-xl {
        margin: 4rem; } }
    @media (min-width: 1920px) {
      .has-margin-7-xxl {
        margin: 4rem; } }
  .has-margin-8 {
    margin: 5rem; }
    @media (min-width: 0px) {
      .has-margin-8-xs {
        margin: 5rem; } }
    @media (min-width: 480px) {
      .has-margin-8-sm {
        margin: 5rem; } }
    @media (min-width: 720px) {
      .has-margin-8-md {
        margin: 5rem; } }
    @media (min-width: 1024px) {
      .has-margin-8-nav {
        margin: 5rem; } }
    @media (min-width: 1024px) {
      .has-margin-8-lg {
        margin: 5rem; } }
    @media (min-width: 1280px) {
      .has-margin-8-xl {
        margin: 5rem; } }
    @media (min-width: 1920px) {
      .has-margin-8-xxl {
        margin: 5rem; } }

.has-margin-middle {
  margin-bottom:  1rem;
  margin-top:  1rem; }
  @media (min-width: 0px) {
    .has-margin-middle-xs {
      margin-bottom:  1rem;
      margin-top:  1rem; } }
  @media (min-width: 480px) {
    .has-margin-middle-sm {
      margin-bottom:  1rem;
      margin-top:  1rem; } }
  @media (min-width: 720px) {
    .has-margin-middle-md {
      margin-bottom:  1rem;
      margin-top:  1rem; } }
  @media (min-width: 1024px) {
    .has-margin-middle-nav {
      margin-bottom:  1rem;
      margin-top:  1rem; } }
  @media (min-width: 1024px) {
    .has-margin-middle-lg {
      margin-bottom:  1rem;
      margin-top:  1rem; } }
  @media (min-width: 1280px) {
    .has-margin-middle-xl {
      margin-bottom:  1rem;
      margin-top:  1rem; } }
  @media (min-width: 1920px) {
    .has-margin-middle-xxl {
      margin-bottom:  1rem;
      margin-top:  1rem; } }
  .has-margin-middle-smallest {
    margin-bottom:  0.25rem;
    margin-top:  0.25rem; }
    @media (min-width: 0px) {
      .has-margin-middle-smallest-xs {
        margin-bottom:  0.25rem;
        margin-top:  0.25rem; } }
    @media (min-width: 480px) {
      .has-margin-middle-smallest-sm {
        margin-bottom:  0.25rem;
        margin-top:  0.25rem; } }
    @media (min-width: 720px) {
      .has-margin-middle-smallest-md {
        margin-bottom:  0.25rem;
        margin-top:  0.25rem; } }
    @media (min-width: 1024px) {
      .has-margin-middle-smallest-nav {
        margin-bottom:  0.25rem;
        margin-top:  0.25rem; } }
    @media (min-width: 1024px) {
      .has-margin-middle-smallest-lg {
        margin-bottom:  0.25rem;
        margin-top:  0.25rem; } }
    @media (min-width: 1280px) {
      .has-margin-middle-smallest-xl {
        margin-bottom:  0.25rem;
        margin-top:  0.25rem; } }
    @media (min-width: 1920px) {
      .has-margin-middle-smallest-xxl {
        margin-bottom:  0.25rem;
        margin-top:  0.25rem; } }
  .has-margin-middle-small {
    margin-bottom:  0.5rem;
    margin-top:  0.5rem; }
    @media (min-width: 0px) {
      .has-margin-middle-small-xs {
        margin-bottom:  0.5rem;
        margin-top:  0.5rem; } }
    @media (min-width: 480px) {
      .has-margin-middle-small-sm {
        margin-bottom:  0.5rem;
        margin-top:  0.5rem; } }
    @media (min-width: 720px) {
      .has-margin-middle-small-md {
        margin-bottom:  0.5rem;
        margin-top:  0.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-middle-small-nav {
        margin-bottom:  0.5rem;
        margin-top:  0.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-middle-small-lg {
        margin-bottom:  0.5rem;
        margin-top:  0.5rem; } }
    @media (min-width: 1280px) {
      .has-margin-middle-small-xl {
        margin-bottom:  0.5rem;
        margin-top:  0.5rem; } }
    @media (min-width: 1920px) {
      .has-margin-middle-small-xxl {
        margin-bottom:  0.5rem;
        margin-top:  0.5rem; } }
  .has-margin-middle-big {
    margin-bottom:  2rem;
    margin-top:  2rem; }
    @media (min-width: 0px) {
      .has-margin-middle-big-xs {
        margin-bottom:  2rem;
        margin-top:  2rem; } }
    @media (min-width: 480px) {
      .has-margin-middle-big-sm {
        margin-bottom:  2rem;
        margin-top:  2rem; } }
    @media (min-width: 720px) {
      .has-margin-middle-big-md {
        margin-bottom:  2rem;
        margin-top:  2rem; } }
    @media (min-width: 1024px) {
      .has-margin-middle-big-nav {
        margin-bottom:  2rem;
        margin-top:  2rem; } }
    @media (min-width: 1024px) {
      .has-margin-middle-big-lg {
        margin-bottom:  2rem;
        margin-top:  2rem; } }
    @media (min-width: 1280px) {
      .has-margin-middle-big-xl {
        margin-bottom:  2rem;
        margin-top:  2rem; } }
    @media (min-width: 1920px) {
      .has-margin-middle-big-xxl {
        margin-bottom:  2rem;
        margin-top:  2rem; } }
  .has-margin-middle-0 {
    margin-bottom: 0;
    margin-top: 0; }
    @media (min-width: 0px) {
      .has-margin-middle-0-xs {
        margin-bottom: 0;
        margin-top: 0; } }
    @media (min-width: 480px) {
      .has-margin-middle-0-sm {
        margin-bottom: 0;
        margin-top: 0; } }
    @media (min-width: 720px) {
      .has-margin-middle-0-md {
        margin-bottom: 0;
        margin-top: 0; } }
    @media (min-width: 1024px) {
      .has-margin-middle-0-nav {
        margin-bottom: 0;
        margin-top: 0; } }
    @media (min-width: 1024px) {
      .has-margin-middle-0-lg {
        margin-bottom: 0;
        margin-top: 0; } }
    @media (min-width: 1280px) {
      .has-margin-middle-0-xl {
        margin-bottom: 0;
        margin-top: 0; } }
    @media (min-width: 1920px) {
      .has-margin-middle-0-xxl {
        margin-bottom: 0;
        margin-top: 0; } }
  .has-margin-middle-1 {
    margin-bottom: 0.25rem;
    margin-top: 0.25rem; }
    @media (min-width: 0px) {
      .has-margin-middle-1-xs {
        margin-bottom: 0.25rem;
        margin-top: 0.25rem; } }
    @media (min-width: 480px) {
      .has-margin-middle-1-sm {
        margin-bottom: 0.25rem;
        margin-top: 0.25rem; } }
    @media (min-width: 720px) {
      .has-margin-middle-1-md {
        margin-bottom: 0.25rem;
        margin-top: 0.25rem; } }
    @media (min-width: 1024px) {
      .has-margin-middle-1-nav {
        margin-bottom: 0.25rem;
        margin-top: 0.25rem; } }
    @media (min-width: 1024px) {
      .has-margin-middle-1-lg {
        margin-bottom: 0.25rem;
        margin-top: 0.25rem; } }
    @media (min-width: 1280px) {
      .has-margin-middle-1-xl {
        margin-bottom: 0.25rem;
        margin-top: 0.25rem; } }
    @media (min-width: 1920px) {
      .has-margin-middle-1-xxl {
        margin-bottom: 0.25rem;
        margin-top: 0.25rem; } }
  .has-margin-middle-2 {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem; }
    @media (min-width: 0px) {
      .has-margin-middle-2-xs {
        margin-bottom: 0.5rem;
        margin-top: 0.5rem; } }
    @media (min-width: 480px) {
      .has-margin-middle-2-sm {
        margin-bottom: 0.5rem;
        margin-top: 0.5rem; } }
    @media (min-width: 720px) {
      .has-margin-middle-2-md {
        margin-bottom: 0.5rem;
        margin-top: 0.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-middle-2-nav {
        margin-bottom: 0.5rem;
        margin-top: 0.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-middle-2-lg {
        margin-bottom: 0.5rem;
        margin-top: 0.5rem; } }
    @media (min-width: 1280px) {
      .has-margin-middle-2-xl {
        margin-bottom: 0.5rem;
        margin-top: 0.5rem; } }
    @media (min-width: 1920px) {
      .has-margin-middle-2-xxl {
        margin-bottom: 0.5rem;
        margin-top: 0.5rem; } }
  .has-margin-middle-3 {
    margin-bottom: 1rem;
    margin-top: 1rem; }
    @media (min-width: 0px) {
      .has-margin-middle-3-xs {
        margin-bottom: 1rem;
        margin-top: 1rem; } }
    @media (min-width: 480px) {
      .has-margin-middle-3-sm {
        margin-bottom: 1rem;
        margin-top: 1rem; } }
    @media (min-width: 720px) {
      .has-margin-middle-3-md {
        margin-bottom: 1rem;
        margin-top: 1rem; } }
    @media (min-width: 1024px) {
      .has-margin-middle-3-nav {
        margin-bottom: 1rem;
        margin-top: 1rem; } }
    @media (min-width: 1024px) {
      .has-margin-middle-3-lg {
        margin-bottom: 1rem;
        margin-top: 1rem; } }
    @media (min-width: 1280px) {
      .has-margin-middle-3-xl {
        margin-bottom: 1rem;
        margin-top: 1rem; } }
    @media (min-width: 1920px) {
      .has-margin-middle-3-xxl {
        margin-bottom: 1rem;
        margin-top: 1rem; } }
  .has-margin-middle-4 {
    margin-bottom: 1.5rem;
    margin-top: 1.5rem; }
    @media (min-width: 0px) {
      .has-margin-middle-4-xs {
        margin-bottom: 1.5rem;
        margin-top: 1.5rem; } }
    @media (min-width: 480px) {
      .has-margin-middle-4-sm {
        margin-bottom: 1.5rem;
        margin-top: 1.5rem; } }
    @media (min-width: 720px) {
      .has-margin-middle-4-md {
        margin-bottom: 1.5rem;
        margin-top: 1.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-middle-4-nav {
        margin-bottom: 1.5rem;
        margin-top: 1.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-middle-4-lg {
        margin-bottom: 1.5rem;
        margin-top: 1.5rem; } }
    @media (min-width: 1280px) {
      .has-margin-middle-4-xl {
        margin-bottom: 1.5rem;
        margin-top: 1.5rem; } }
    @media (min-width: 1920px) {
      .has-margin-middle-4-xxl {
        margin-bottom: 1.5rem;
        margin-top: 1.5rem; } }
  .has-margin-middle-5 {
    margin-bottom: 2rem;
    margin-top: 2rem; }
    @media (min-width: 0px) {
      .has-margin-middle-5-xs {
        margin-bottom: 2rem;
        margin-top: 2rem; } }
    @media (min-width: 480px) {
      .has-margin-middle-5-sm {
        margin-bottom: 2rem;
        margin-top: 2rem; } }
    @media (min-width: 720px) {
      .has-margin-middle-5-md {
        margin-bottom: 2rem;
        margin-top: 2rem; } }
    @media (min-width: 1024px) {
      .has-margin-middle-5-nav {
        margin-bottom: 2rem;
        margin-top: 2rem; } }
    @media (min-width: 1024px) {
      .has-margin-middle-5-lg {
        margin-bottom: 2rem;
        margin-top: 2rem; } }
    @media (min-width: 1280px) {
      .has-margin-middle-5-xl {
        margin-bottom: 2rem;
        margin-top: 2rem; } }
    @media (min-width: 1920px) {
      .has-margin-middle-5-xxl {
        margin-bottom: 2rem;
        margin-top: 2rem; } }
  .has-margin-middle-6 {
    margin-bottom: 3rem;
    margin-top: 3rem; }
    @media (min-width: 0px) {
      .has-margin-middle-6-xs {
        margin-bottom: 3rem;
        margin-top: 3rem; } }
    @media (min-width: 480px) {
      .has-margin-middle-6-sm {
        margin-bottom: 3rem;
        margin-top: 3rem; } }
    @media (min-width: 720px) {
      .has-margin-middle-6-md {
        margin-bottom: 3rem;
        margin-top: 3rem; } }
    @media (min-width: 1024px) {
      .has-margin-middle-6-nav {
        margin-bottom: 3rem;
        margin-top: 3rem; } }
    @media (min-width: 1024px) {
      .has-margin-middle-6-lg {
        margin-bottom: 3rem;
        margin-top: 3rem; } }
    @media (min-width: 1280px) {
      .has-margin-middle-6-xl {
        margin-bottom: 3rem;
        margin-top: 3rem; } }
    @media (min-width: 1920px) {
      .has-margin-middle-6-xxl {
        margin-bottom: 3rem;
        margin-top: 3rem; } }
  .has-margin-middle-7 {
    margin-bottom: 4rem;
    margin-top: 4rem; }
    @media (min-width: 0px) {
      .has-margin-middle-7-xs {
        margin-bottom: 4rem;
        margin-top: 4rem; } }
    @media (min-width: 480px) {
      .has-margin-middle-7-sm {
        margin-bottom: 4rem;
        margin-top: 4rem; } }
    @media (min-width: 720px) {
      .has-margin-middle-7-md {
        margin-bottom: 4rem;
        margin-top: 4rem; } }
    @media (min-width: 1024px) {
      .has-margin-middle-7-nav {
        margin-bottom: 4rem;
        margin-top: 4rem; } }
    @media (min-width: 1024px) {
      .has-margin-middle-7-lg {
        margin-bottom: 4rem;
        margin-top: 4rem; } }
    @media (min-width: 1280px) {
      .has-margin-middle-7-xl {
        margin-bottom: 4rem;
        margin-top: 4rem; } }
    @media (min-width: 1920px) {
      .has-margin-middle-7-xxl {
        margin-bottom: 4rem;
        margin-top: 4rem; } }
  .has-margin-middle-8 {
    margin-bottom: 5rem;
    margin-top: 5rem; }
    @media (min-width: 0px) {
      .has-margin-middle-8-xs {
        margin-bottom: 5rem;
        margin-top: 5rem; } }
    @media (min-width: 480px) {
      .has-margin-middle-8-sm {
        margin-bottom: 5rem;
        margin-top: 5rem; } }
    @media (min-width: 720px) {
      .has-margin-middle-8-md {
        margin-bottom: 5rem;
        margin-top: 5rem; } }
    @media (min-width: 1024px) {
      .has-margin-middle-8-nav {
        margin-bottom: 5rem;
        margin-top: 5rem; } }
    @media (min-width: 1024px) {
      .has-margin-middle-8-lg {
        margin-bottom: 5rem;
        margin-top: 5rem; } }
    @media (min-width: 1280px) {
      .has-margin-middle-8-xl {
        margin-bottom: 5rem;
        margin-top: 5rem; } }
    @media (min-width: 1920px) {
      .has-margin-middle-8-xxl {
        margin-bottom: 5rem;
        margin-top: 5rem; } }

.has-margin-top {
  margin-top:  1rem; }
  @media (min-width: 0px) {
    .has-margin-top-xs {
      margin-top:  1rem; } }
  @media (min-width: 480px) {
    .has-margin-top-sm {
      margin-top:  1rem; } }
  @media (min-width: 720px) {
    .has-margin-top-md {
      margin-top:  1rem; } }
  @media (min-width: 1024px) {
    .has-margin-top-nav {
      margin-top:  1rem; } }
  @media (min-width: 1024px) {
    .has-margin-top-lg {
      margin-top:  1rem; } }
  @media (min-width: 1280px) {
    .has-margin-top-xl {
      margin-top:  1rem; } }
  @media (min-width: 1920px) {
    .has-margin-top-xxl {
      margin-top:  1rem; } }
  .has-margin-top-smallest {
    margin-top:  0.25rem; }
    @media (min-width: 0px) {
      .has-margin-top-smallest-xs {
        margin-top:  0.25rem; } }
    @media (min-width: 480px) {
      .has-margin-top-smallest-sm {
        margin-top:  0.25rem; } }
    @media (min-width: 720px) {
      .has-margin-top-smallest-md {
        margin-top:  0.25rem; } }
    @media (min-width: 1024px) {
      .has-margin-top-smallest-nav {
        margin-top:  0.25rem; } }
    @media (min-width: 1024px) {
      .has-margin-top-smallest-lg {
        margin-top:  0.25rem; } }
    @media (min-width: 1280px) {
      .has-margin-top-smallest-xl {
        margin-top:  0.25rem; } }
    @media (min-width: 1920px) {
      .has-margin-top-smallest-xxl {
        margin-top:  0.25rem; } }
  .has-margin-top-small {
    margin-top:  0.5rem; }
    @media (min-width: 0px) {
      .has-margin-top-small-xs {
        margin-top:  0.5rem; } }
    @media (min-width: 480px) {
      .has-margin-top-small-sm {
        margin-top:  0.5rem; } }
    @media (min-width: 720px) {
      .has-margin-top-small-md {
        margin-top:  0.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-top-small-nav {
        margin-top:  0.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-top-small-lg {
        margin-top:  0.5rem; } }
    @media (min-width: 1280px) {
      .has-margin-top-small-xl {
        margin-top:  0.5rem; } }
    @media (min-width: 1920px) {
      .has-margin-top-small-xxl {
        margin-top:  0.5rem; } }
  .has-margin-top-big {
    margin-top:  2rem; }
    @media (min-width: 0px) {
      .has-margin-top-big-xs {
        margin-top:  2rem; } }
    @media (min-width: 480px) {
      .has-margin-top-big-sm {
        margin-top:  2rem; } }
    @media (min-width: 720px) {
      .has-margin-top-big-md {
        margin-top:  2rem; } }
    @media (min-width: 1024px) {
      .has-margin-top-big-nav {
        margin-top:  2rem; } }
    @media (min-width: 1024px) {
      .has-margin-top-big-lg {
        margin-top:  2rem; } }
    @media (min-width: 1280px) {
      .has-margin-top-big-xl {
        margin-top:  2rem; } }
    @media (min-width: 1920px) {
      .has-margin-top-big-xxl {
        margin-top:  2rem; } }
  .has-margin-top-0 {
    margin-top: 0; }
    @media (min-width: 0px) {
      .has-margin-top-0-xs {
        margin-top: 0; } }
    @media (min-width: 480px) {
      .has-margin-top-0-sm {
        margin-top: 0; } }
    @media (min-width: 720px) {
      .has-margin-top-0-md {
        margin-top: 0; } }
    @media (min-width: 1024px) {
      .has-margin-top-0-nav {
        margin-top: 0; } }
    @media (min-width: 1024px) {
      .has-margin-top-0-lg {
        margin-top: 0; } }
    @media (min-width: 1280px) {
      .has-margin-top-0-xl {
        margin-top: 0; } }
    @media (min-width: 1920px) {
      .has-margin-top-0-xxl {
        margin-top: 0; } }
  .has-margin-top-1 {
    margin-top: 0.25rem; }
    @media (min-width: 0px) {
      .has-margin-top-1-xs {
        margin-top: 0.25rem; } }
    @media (min-width: 480px) {
      .has-margin-top-1-sm {
        margin-top: 0.25rem; } }
    @media (min-width: 720px) {
      .has-margin-top-1-md {
        margin-top: 0.25rem; } }
    @media (min-width: 1024px) {
      .has-margin-top-1-nav {
        margin-top: 0.25rem; } }
    @media (min-width: 1024px) {
      .has-margin-top-1-lg {
        margin-top: 0.25rem; } }
    @media (min-width: 1280px) {
      .has-margin-top-1-xl {
        margin-top: 0.25rem; } }
    @media (min-width: 1920px) {
      .has-margin-top-1-xxl {
        margin-top: 0.25rem; } }
  .has-margin-top-2 {
    margin-top: 0.5rem; }
    @media (min-width: 0px) {
      .has-margin-top-2-xs {
        margin-top: 0.5rem; } }
    @media (min-width: 480px) {
      .has-margin-top-2-sm {
        margin-top: 0.5rem; } }
    @media (min-width: 720px) {
      .has-margin-top-2-md {
        margin-top: 0.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-top-2-nav {
        margin-top: 0.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-top-2-lg {
        margin-top: 0.5rem; } }
    @media (min-width: 1280px) {
      .has-margin-top-2-xl {
        margin-top: 0.5rem; } }
    @media (min-width: 1920px) {
      .has-margin-top-2-xxl {
        margin-top: 0.5rem; } }
  .has-margin-top-3 {
    margin-top: 1rem; }
    @media (min-width: 0px) {
      .has-margin-top-3-xs {
        margin-top: 1rem; } }
    @media (min-width: 480px) {
      .has-margin-top-3-sm {
        margin-top: 1rem; } }
    @media (min-width: 720px) {
      .has-margin-top-3-md {
        margin-top: 1rem; } }
    @media (min-width: 1024px) {
      .has-margin-top-3-nav {
        margin-top: 1rem; } }
    @media (min-width: 1024px) {
      .has-margin-top-3-lg {
        margin-top: 1rem; } }
    @media (min-width: 1280px) {
      .has-margin-top-3-xl {
        margin-top: 1rem; } }
    @media (min-width: 1920px) {
      .has-margin-top-3-xxl {
        margin-top: 1rem; } }
  .has-margin-top-4 {
    margin-top: 1.5rem; }
    @media (min-width: 0px) {
      .has-margin-top-4-xs {
        margin-top: 1.5rem; } }
    @media (min-width: 480px) {
      .has-margin-top-4-sm {
        margin-top: 1.5rem; } }
    @media (min-width: 720px) {
      .has-margin-top-4-md {
        margin-top: 1.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-top-4-nav {
        margin-top: 1.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-top-4-lg {
        margin-top: 1.5rem; } }
    @media (min-width: 1280px) {
      .has-margin-top-4-xl {
        margin-top: 1.5rem; } }
    @media (min-width: 1920px) {
      .has-margin-top-4-xxl {
        margin-top: 1.5rem; } }
  .has-margin-top-5 {
    margin-top: 2rem; }
    @media (min-width: 0px) {
      .has-margin-top-5-xs {
        margin-top: 2rem; } }
    @media (min-width: 480px) {
      .has-margin-top-5-sm {
        margin-top: 2rem; } }
    @media (min-width: 720px) {
      .has-margin-top-5-md {
        margin-top: 2rem; } }
    @media (min-width: 1024px) {
      .has-margin-top-5-nav {
        margin-top: 2rem; } }
    @media (min-width: 1024px) {
      .has-margin-top-5-lg {
        margin-top: 2rem; } }
    @media (min-width: 1280px) {
      .has-margin-top-5-xl {
        margin-top: 2rem; } }
    @media (min-width: 1920px) {
      .has-margin-top-5-xxl {
        margin-top: 2rem; } }
  .has-margin-top-6 {
    margin-top: 3rem; }
    @media (min-width: 0px) {
      .has-margin-top-6-xs {
        margin-top: 3rem; } }
    @media (min-width: 480px) {
      .has-margin-top-6-sm {
        margin-top: 3rem; } }
    @media (min-width: 720px) {
      .has-margin-top-6-md {
        margin-top: 3rem; } }
    @media (min-width: 1024px) {
      .has-margin-top-6-nav {
        margin-top: 3rem; } }
    @media (min-width: 1024px) {
      .has-margin-top-6-lg {
        margin-top: 3rem; } }
    @media (min-width: 1280px) {
      .has-margin-top-6-xl {
        margin-top: 3rem; } }
    @media (min-width: 1920px) {
      .has-margin-top-6-xxl {
        margin-top: 3rem; } }
  .has-margin-top-7 {
    margin-top: 4rem; }
    @media (min-width: 0px) {
      .has-margin-top-7-xs {
        margin-top: 4rem; } }
    @media (min-width: 480px) {
      .has-margin-top-7-sm {
        margin-top: 4rem; } }
    @media (min-width: 720px) {
      .has-margin-top-7-md {
        margin-top: 4rem; } }
    @media (min-width: 1024px) {
      .has-margin-top-7-nav {
        margin-top: 4rem; } }
    @media (min-width: 1024px) {
      .has-margin-top-7-lg {
        margin-top: 4rem; } }
    @media (min-width: 1280px) {
      .has-margin-top-7-xl {
        margin-top: 4rem; } }
    @media (min-width: 1920px) {
      .has-margin-top-7-xxl {
        margin-top: 4rem; } }
  .has-margin-top-8 {
    margin-top: 5rem; }
    @media (min-width: 0px) {
      .has-margin-top-8-xs {
        margin-top: 5rem; } }
    @media (min-width: 480px) {
      .has-margin-top-8-sm {
        margin-top: 5rem; } }
    @media (min-width: 720px) {
      .has-margin-top-8-md {
        margin-top: 5rem; } }
    @media (min-width: 1024px) {
      .has-margin-top-8-nav {
        margin-top: 5rem; } }
    @media (min-width: 1024px) {
      .has-margin-top-8-lg {
        margin-top: 5rem; } }
    @media (min-width: 1280px) {
      .has-margin-top-8-xl {
        margin-top: 5rem; } }
    @media (min-width: 1920px) {
      .has-margin-top-8-xxl {
        margin-top: 5rem; } }

.has-margin-bottom {
  margin-bottom:  1rem; }
  @media (min-width: 0px) {
    .has-margin-bottom-xs {
      margin-bottom:  1rem; } }
  @media (min-width: 480px) {
    .has-margin-bottom-sm {
      margin-bottom:  1rem; } }
  @media (min-width: 720px) {
    .has-margin-bottom-md {
      margin-bottom:  1rem; } }
  @media (min-width: 1024px) {
    .has-margin-bottom-nav {
      margin-bottom:  1rem; } }
  @media (min-width: 1024px) {
    .has-margin-bottom-lg {
      margin-bottom:  1rem; } }
  @media (min-width: 1280px) {
    .has-margin-bottom-xl {
      margin-bottom:  1rem; } }
  @media (min-width: 1920px) {
    .has-margin-bottom-xxl {
      margin-bottom:  1rem; } }
  .has-margin-bottom-smallest {
    margin-bottom:  0.25rem; }
    @media (min-width: 0px) {
      .has-margin-bottom-smallest-xs {
        margin-bottom:  0.25rem; } }
    @media (min-width: 480px) {
      .has-margin-bottom-smallest-sm {
        margin-bottom:  0.25rem; } }
    @media (min-width: 720px) {
      .has-margin-bottom-smallest-md {
        margin-bottom:  0.25rem; } }
    @media (min-width: 1024px) {
      .has-margin-bottom-smallest-nav {
        margin-bottom:  0.25rem; } }
    @media (min-width: 1024px) {
      .has-margin-bottom-smallest-lg {
        margin-bottom:  0.25rem; } }
    @media (min-width: 1280px) {
      .has-margin-bottom-smallest-xl {
        margin-bottom:  0.25rem; } }
    @media (min-width: 1920px) {
      .has-margin-bottom-smallest-xxl {
        margin-bottom:  0.25rem; } }
  .has-margin-bottom-small {
    margin-bottom:  0.5rem; }
    @media (min-width: 0px) {
      .has-margin-bottom-small-xs {
        margin-bottom:  0.5rem; } }
    @media (min-width: 480px) {
      .has-margin-bottom-small-sm {
        margin-bottom:  0.5rem; } }
    @media (min-width: 720px) {
      .has-margin-bottom-small-md {
        margin-bottom:  0.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-bottom-small-nav {
        margin-bottom:  0.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-bottom-small-lg {
        margin-bottom:  0.5rem; } }
    @media (min-width: 1280px) {
      .has-margin-bottom-small-xl {
        margin-bottom:  0.5rem; } }
    @media (min-width: 1920px) {
      .has-margin-bottom-small-xxl {
        margin-bottom:  0.5rem; } }
  .has-margin-bottom-big {
    margin-bottom:  2rem; }
    @media (min-width: 0px) {
      .has-margin-bottom-big-xs {
        margin-bottom:  2rem; } }
    @media (min-width: 480px) {
      .has-margin-bottom-big-sm {
        margin-bottom:  2rem; } }
    @media (min-width: 720px) {
      .has-margin-bottom-big-md {
        margin-bottom:  2rem; } }
    @media (min-width: 1024px) {
      .has-margin-bottom-big-nav {
        margin-bottom:  2rem; } }
    @media (min-width: 1024px) {
      .has-margin-bottom-big-lg {
        margin-bottom:  2rem; } }
    @media (min-width: 1280px) {
      .has-margin-bottom-big-xl {
        margin-bottom:  2rem; } }
    @media (min-width: 1920px) {
      .has-margin-bottom-big-xxl {
        margin-bottom:  2rem; } }
  .has-margin-bottom-0 {
    margin-bottom: 0; }
    @media (min-width: 0px) {
      .has-margin-bottom-0-xs {
        margin-bottom: 0; } }
    @media (min-width: 480px) {
      .has-margin-bottom-0-sm {
        margin-bottom: 0; } }
    @media (min-width: 720px) {
      .has-margin-bottom-0-md {
        margin-bottom: 0; } }
    @media (min-width: 1024px) {
      .has-margin-bottom-0-nav {
        margin-bottom: 0; } }
    @media (min-width: 1024px) {
      .has-margin-bottom-0-lg {
        margin-bottom: 0; } }
    @media (min-width: 1280px) {
      .has-margin-bottom-0-xl {
        margin-bottom: 0; } }
    @media (min-width: 1920px) {
      .has-margin-bottom-0-xxl {
        margin-bottom: 0; } }
  .has-margin-bottom-1 {
    margin-bottom: 0.25rem; }
    @media (min-width: 0px) {
      .has-margin-bottom-1-xs {
        margin-bottom: 0.25rem; } }
    @media (min-width: 480px) {
      .has-margin-bottom-1-sm {
        margin-bottom: 0.25rem; } }
    @media (min-width: 720px) {
      .has-margin-bottom-1-md {
        margin-bottom: 0.25rem; } }
    @media (min-width: 1024px) {
      .has-margin-bottom-1-nav {
        margin-bottom: 0.25rem; } }
    @media (min-width: 1024px) {
      .has-margin-bottom-1-lg {
        margin-bottom: 0.25rem; } }
    @media (min-width: 1280px) {
      .has-margin-bottom-1-xl {
        margin-bottom: 0.25rem; } }
    @media (min-width: 1920px) {
      .has-margin-bottom-1-xxl {
        margin-bottom: 0.25rem; } }
  .has-margin-bottom-2 {
    margin-bottom: 0.5rem; }
    @media (min-width: 0px) {
      .has-margin-bottom-2-xs {
        margin-bottom: 0.5rem; } }
    @media (min-width: 480px) {
      .has-margin-bottom-2-sm {
        margin-bottom: 0.5rem; } }
    @media (min-width: 720px) {
      .has-margin-bottom-2-md {
        margin-bottom: 0.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-bottom-2-nav {
        margin-bottom: 0.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-bottom-2-lg {
        margin-bottom: 0.5rem; } }
    @media (min-width: 1280px) {
      .has-margin-bottom-2-xl {
        margin-bottom: 0.5rem; } }
    @media (min-width: 1920px) {
      .has-margin-bottom-2-xxl {
        margin-bottom: 0.5rem; } }
  .has-margin-bottom-3 {
    margin-bottom: 1rem; }
    @media (min-width: 0px) {
      .has-margin-bottom-3-xs {
        margin-bottom: 1rem; } }
    @media (min-width: 480px) {
      .has-margin-bottom-3-sm {
        margin-bottom: 1rem; } }
    @media (min-width: 720px) {
      .has-margin-bottom-3-md {
        margin-bottom: 1rem; } }
    @media (min-width: 1024px) {
      .has-margin-bottom-3-nav {
        margin-bottom: 1rem; } }
    @media (min-width: 1024px) {
      .has-margin-bottom-3-lg {
        margin-bottom: 1rem; } }
    @media (min-width: 1280px) {
      .has-margin-bottom-3-xl {
        margin-bottom: 1rem; } }
    @media (min-width: 1920px) {
      .has-margin-bottom-3-xxl {
        margin-bottom: 1rem; } }
  .has-margin-bottom-4 {
    margin-bottom: 1.5rem; }
    @media (min-width: 0px) {
      .has-margin-bottom-4-xs {
        margin-bottom: 1.5rem; } }
    @media (min-width: 480px) {
      .has-margin-bottom-4-sm {
        margin-bottom: 1.5rem; } }
    @media (min-width: 720px) {
      .has-margin-bottom-4-md {
        margin-bottom: 1.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-bottom-4-nav {
        margin-bottom: 1.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-bottom-4-lg {
        margin-bottom: 1.5rem; } }
    @media (min-width: 1280px) {
      .has-margin-bottom-4-xl {
        margin-bottom: 1.5rem; } }
    @media (min-width: 1920px) {
      .has-margin-bottom-4-xxl {
        margin-bottom: 1.5rem; } }
  .has-margin-bottom-5 {
    margin-bottom: 2rem; }
    @media (min-width: 0px) {
      .has-margin-bottom-5-xs {
        margin-bottom: 2rem; } }
    @media (min-width: 480px) {
      .has-margin-bottom-5-sm {
        margin-bottom: 2rem; } }
    @media (min-width: 720px) {
      .has-margin-bottom-5-md {
        margin-bottom: 2rem; } }
    @media (min-width: 1024px) {
      .has-margin-bottom-5-nav {
        margin-bottom: 2rem; } }
    @media (min-width: 1024px) {
      .has-margin-bottom-5-lg {
        margin-bottom: 2rem; } }
    @media (min-width: 1280px) {
      .has-margin-bottom-5-xl {
        margin-bottom: 2rem; } }
    @media (min-width: 1920px) {
      .has-margin-bottom-5-xxl {
        margin-bottom: 2rem; } }
  .has-margin-bottom-6 {
    margin-bottom: 3rem; }
    @media (min-width: 0px) {
      .has-margin-bottom-6-xs {
        margin-bottom: 3rem; } }
    @media (min-width: 480px) {
      .has-margin-bottom-6-sm {
        margin-bottom: 3rem; } }
    @media (min-width: 720px) {
      .has-margin-bottom-6-md {
        margin-bottom: 3rem; } }
    @media (min-width: 1024px) {
      .has-margin-bottom-6-nav {
        margin-bottom: 3rem; } }
    @media (min-width: 1024px) {
      .has-margin-bottom-6-lg {
        margin-bottom: 3rem; } }
    @media (min-width: 1280px) {
      .has-margin-bottom-6-xl {
        margin-bottom: 3rem; } }
    @media (min-width: 1920px) {
      .has-margin-bottom-6-xxl {
        margin-bottom: 3rem; } }
  .has-margin-bottom-7 {
    margin-bottom: 4rem; }
    @media (min-width: 0px) {
      .has-margin-bottom-7-xs {
        margin-bottom: 4rem; } }
    @media (min-width: 480px) {
      .has-margin-bottom-7-sm {
        margin-bottom: 4rem; } }
    @media (min-width: 720px) {
      .has-margin-bottom-7-md {
        margin-bottom: 4rem; } }
    @media (min-width: 1024px) {
      .has-margin-bottom-7-nav {
        margin-bottom: 4rem; } }
    @media (min-width: 1024px) {
      .has-margin-bottom-7-lg {
        margin-bottom: 4rem; } }
    @media (min-width: 1280px) {
      .has-margin-bottom-7-xl {
        margin-bottom: 4rem; } }
    @media (min-width: 1920px) {
      .has-margin-bottom-7-xxl {
        margin-bottom: 4rem; } }
  .has-margin-bottom-8 {
    margin-bottom: 5rem; }
    @media (min-width: 0px) {
      .has-margin-bottom-8-xs {
        margin-bottom: 5rem; } }
    @media (min-width: 480px) {
      .has-margin-bottom-8-sm {
        margin-bottom: 5rem; } }
    @media (min-width: 720px) {
      .has-margin-bottom-8-md {
        margin-bottom: 5rem; } }
    @media (min-width: 1024px) {
      .has-margin-bottom-8-nav {
        margin-bottom: 5rem; } }
    @media (min-width: 1024px) {
      .has-margin-bottom-8-lg {
        margin-bottom: 5rem; } }
    @media (min-width: 1280px) {
      .has-margin-bottom-8-xl {
        margin-bottom: 5rem; } }
    @media (min-width: 1920px) {
      .has-margin-bottom-8-xxl {
        margin-bottom: 5rem; } }

.has-margin-center {
  margin-left:  1rem;
  margin-right:  1rem; }
  @media (min-width: 0px) {
    .has-margin-center-xs {
      margin-left:  1rem;
      margin-right:  1rem; } }
  @media (min-width: 480px) {
    .has-margin-center-sm {
      margin-left:  1rem;
      margin-right:  1rem; } }
  @media (min-width: 720px) {
    .has-margin-center-md {
      margin-left:  1rem;
      margin-right:  1rem; } }
  @media (min-width: 1024px) {
    .has-margin-center-nav {
      margin-left:  1rem;
      margin-right:  1rem; } }
  @media (min-width: 1024px) {
    .has-margin-center-lg {
      margin-left:  1rem;
      margin-right:  1rem; } }
  @media (min-width: 1280px) {
    .has-margin-center-xl {
      margin-left:  1rem;
      margin-right:  1rem; } }
  @media (min-width: 1920px) {
    .has-margin-center-xxl {
      margin-left:  1rem;
      margin-right:  1rem; } }
  .has-margin-center-smallest {
    margin-left:  0.25rem;
    margin-right:  0.25rem; }
    @media (min-width: 0px) {
      .has-margin-center-smallest-xs {
        margin-left:  0.25rem;
        margin-right:  0.25rem; } }
    @media (min-width: 480px) {
      .has-margin-center-smallest-sm {
        margin-left:  0.25rem;
        margin-right:  0.25rem; } }
    @media (min-width: 720px) {
      .has-margin-center-smallest-md {
        margin-left:  0.25rem;
        margin-right:  0.25rem; } }
    @media (min-width: 1024px) {
      .has-margin-center-smallest-nav {
        margin-left:  0.25rem;
        margin-right:  0.25rem; } }
    @media (min-width: 1024px) {
      .has-margin-center-smallest-lg {
        margin-left:  0.25rem;
        margin-right:  0.25rem; } }
    @media (min-width: 1280px) {
      .has-margin-center-smallest-xl {
        margin-left:  0.25rem;
        margin-right:  0.25rem; } }
    @media (min-width: 1920px) {
      .has-margin-center-smallest-xxl {
        margin-left:  0.25rem;
        margin-right:  0.25rem; } }
  .has-margin-center-small {
    margin-left:  0.5rem;
    margin-right:  0.5rem; }
    @media (min-width: 0px) {
      .has-margin-center-small-xs {
        margin-left:  0.5rem;
        margin-right:  0.5rem; } }
    @media (min-width: 480px) {
      .has-margin-center-small-sm {
        margin-left:  0.5rem;
        margin-right:  0.5rem; } }
    @media (min-width: 720px) {
      .has-margin-center-small-md {
        margin-left:  0.5rem;
        margin-right:  0.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-center-small-nav {
        margin-left:  0.5rem;
        margin-right:  0.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-center-small-lg {
        margin-left:  0.5rem;
        margin-right:  0.5rem; } }
    @media (min-width: 1280px) {
      .has-margin-center-small-xl {
        margin-left:  0.5rem;
        margin-right:  0.5rem; } }
    @media (min-width: 1920px) {
      .has-margin-center-small-xxl {
        margin-left:  0.5rem;
        margin-right:  0.5rem; } }
  .has-margin-center-big {
    margin-left:  2rem;
    margin-right:  2rem; }
    @media (min-width: 0px) {
      .has-margin-center-big-xs {
        margin-left:  2rem;
        margin-right:  2rem; } }
    @media (min-width: 480px) {
      .has-margin-center-big-sm {
        margin-left:  2rem;
        margin-right:  2rem; } }
    @media (min-width: 720px) {
      .has-margin-center-big-md {
        margin-left:  2rem;
        margin-right:  2rem; } }
    @media (min-width: 1024px) {
      .has-margin-center-big-nav {
        margin-left:  2rem;
        margin-right:  2rem; } }
    @media (min-width: 1024px) {
      .has-margin-center-big-lg {
        margin-left:  2rem;
        margin-right:  2rem; } }
    @media (min-width: 1280px) {
      .has-margin-center-big-xl {
        margin-left:  2rem;
        margin-right:  2rem; } }
    @media (min-width: 1920px) {
      .has-margin-center-big-xxl {
        margin-left:  2rem;
        margin-right:  2rem; } }
  .has-margin-center-auto {
    margin-left: auto;
    margin-right: auto; }
    @media (min-width: 0px) {
      .has-margin-center-auto-xs {
        margin-left: auto;
        margin-right: auto; } }
    @media (min-width: 480px) {
      .has-margin-center-auto-sm {
        margin-left: auto;
        margin-right: auto; } }
    @media (min-width: 720px) {
      .has-margin-center-auto-md {
        margin-left: auto;
        margin-right: auto; } }
    @media (min-width: 1024px) {
      .has-margin-center-auto-nav {
        margin-left: auto;
        margin-right: auto; } }
    @media (min-width: 1024px) {
      .has-margin-center-auto-lg {
        margin-left: auto;
        margin-right: auto; } }
    @media (min-width: 1280px) {
      .has-margin-center-auto-xl {
        margin-left: auto;
        margin-right: auto; } }
    @media (min-width: 1920px) {
      .has-margin-center-auto-xxl {
        margin-left: auto;
        margin-right: auto; } }
  .has-margin-center-0 {
    margin-left: 0;
    margin-right: 0; }
    @media (min-width: 0px) {
      .has-margin-center-0-xs {
        margin-left: 0;
        margin-right: 0; } }
    @media (min-width: 480px) {
      .has-margin-center-0-sm {
        margin-left: 0;
        margin-right: 0; } }
    @media (min-width: 720px) {
      .has-margin-center-0-md {
        margin-left: 0;
        margin-right: 0; } }
    @media (min-width: 1024px) {
      .has-margin-center-0-nav {
        margin-left: 0;
        margin-right: 0; } }
    @media (min-width: 1024px) {
      .has-margin-center-0-lg {
        margin-left: 0;
        margin-right: 0; } }
    @media (min-width: 1280px) {
      .has-margin-center-0-xl {
        margin-left: 0;
        margin-right: 0; } }
    @media (min-width: 1920px) {
      .has-margin-center-0-xxl {
        margin-left: 0;
        margin-right: 0; } }
  .has-margin-center-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }
    @media (min-width: 0px) {
      .has-margin-center-1-xs {
        margin-left: 0.25rem;
        margin-right: 0.25rem; } }
    @media (min-width: 480px) {
      .has-margin-center-1-sm {
        margin-left: 0.25rem;
        margin-right: 0.25rem; } }
    @media (min-width: 720px) {
      .has-margin-center-1-md {
        margin-left: 0.25rem;
        margin-right: 0.25rem; } }
    @media (min-width: 1024px) {
      .has-margin-center-1-nav {
        margin-left: 0.25rem;
        margin-right: 0.25rem; } }
    @media (min-width: 1024px) {
      .has-margin-center-1-lg {
        margin-left: 0.25rem;
        margin-right: 0.25rem; } }
    @media (min-width: 1280px) {
      .has-margin-center-1-xl {
        margin-left: 0.25rem;
        margin-right: 0.25rem; } }
    @media (min-width: 1920px) {
      .has-margin-center-1-xxl {
        margin-left: 0.25rem;
        margin-right: 0.25rem; } }
  .has-margin-center-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem; }
    @media (min-width: 0px) {
      .has-margin-center-2-xs {
        margin-left: 0.5rem;
        margin-right: 0.5rem; } }
    @media (min-width: 480px) {
      .has-margin-center-2-sm {
        margin-left: 0.5rem;
        margin-right: 0.5rem; } }
    @media (min-width: 720px) {
      .has-margin-center-2-md {
        margin-left: 0.5rem;
        margin-right: 0.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-center-2-nav {
        margin-left: 0.5rem;
        margin-right: 0.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-center-2-lg {
        margin-left: 0.5rem;
        margin-right: 0.5rem; } }
    @media (min-width: 1280px) {
      .has-margin-center-2-xl {
        margin-left: 0.5rem;
        margin-right: 0.5rem; } }
    @media (min-width: 1920px) {
      .has-margin-center-2-xxl {
        margin-left: 0.5rem;
        margin-right: 0.5rem; } }
  .has-margin-center-3 {
    margin-left: 1rem;
    margin-right: 1rem; }
    @media (min-width: 0px) {
      .has-margin-center-3-xs {
        margin-left: 1rem;
        margin-right: 1rem; } }
    @media (min-width: 480px) {
      .has-margin-center-3-sm {
        margin-left: 1rem;
        margin-right: 1rem; } }
    @media (min-width: 720px) {
      .has-margin-center-3-md {
        margin-left: 1rem;
        margin-right: 1rem; } }
    @media (min-width: 1024px) {
      .has-margin-center-3-nav {
        margin-left: 1rem;
        margin-right: 1rem; } }
    @media (min-width: 1024px) {
      .has-margin-center-3-lg {
        margin-left: 1rem;
        margin-right: 1rem; } }
    @media (min-width: 1280px) {
      .has-margin-center-3-xl {
        margin-left: 1rem;
        margin-right: 1rem; } }
    @media (min-width: 1920px) {
      .has-margin-center-3-xxl {
        margin-left: 1rem;
        margin-right: 1rem; } }
  .has-margin-center-4 {
    margin-left: 1.5rem;
    margin-right: 1.5rem; }
    @media (min-width: 0px) {
      .has-margin-center-4-xs {
        margin-left: 1.5rem;
        margin-right: 1.5rem; } }
    @media (min-width: 480px) {
      .has-margin-center-4-sm {
        margin-left: 1.5rem;
        margin-right: 1.5rem; } }
    @media (min-width: 720px) {
      .has-margin-center-4-md {
        margin-left: 1.5rem;
        margin-right: 1.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-center-4-nav {
        margin-left: 1.5rem;
        margin-right: 1.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-center-4-lg {
        margin-left: 1.5rem;
        margin-right: 1.5rem; } }
    @media (min-width: 1280px) {
      .has-margin-center-4-xl {
        margin-left: 1.5rem;
        margin-right: 1.5rem; } }
    @media (min-width: 1920px) {
      .has-margin-center-4-xxl {
        margin-left: 1.5rem;
        margin-right: 1.5rem; } }
  .has-margin-center-5 {
    margin-left: 2rem;
    margin-right: 2rem; }
    @media (min-width: 0px) {
      .has-margin-center-5-xs {
        margin-left: 2rem;
        margin-right: 2rem; } }
    @media (min-width: 480px) {
      .has-margin-center-5-sm {
        margin-left: 2rem;
        margin-right: 2rem; } }
    @media (min-width: 720px) {
      .has-margin-center-5-md {
        margin-left: 2rem;
        margin-right: 2rem; } }
    @media (min-width: 1024px) {
      .has-margin-center-5-nav {
        margin-left: 2rem;
        margin-right: 2rem; } }
    @media (min-width: 1024px) {
      .has-margin-center-5-lg {
        margin-left: 2rem;
        margin-right: 2rem; } }
    @media (min-width: 1280px) {
      .has-margin-center-5-xl {
        margin-left: 2rem;
        margin-right: 2rem; } }
    @media (min-width: 1920px) {
      .has-margin-center-5-xxl {
        margin-left: 2rem;
        margin-right: 2rem; } }
  .has-margin-center-6 {
    margin-left: 3rem;
    margin-right: 3rem; }
    @media (min-width: 0px) {
      .has-margin-center-6-xs {
        margin-left: 3rem;
        margin-right: 3rem; } }
    @media (min-width: 480px) {
      .has-margin-center-6-sm {
        margin-left: 3rem;
        margin-right: 3rem; } }
    @media (min-width: 720px) {
      .has-margin-center-6-md {
        margin-left: 3rem;
        margin-right: 3rem; } }
    @media (min-width: 1024px) {
      .has-margin-center-6-nav {
        margin-left: 3rem;
        margin-right: 3rem; } }
    @media (min-width: 1024px) {
      .has-margin-center-6-lg {
        margin-left: 3rem;
        margin-right: 3rem; } }
    @media (min-width: 1280px) {
      .has-margin-center-6-xl {
        margin-left: 3rem;
        margin-right: 3rem; } }
    @media (min-width: 1920px) {
      .has-margin-center-6-xxl {
        margin-left: 3rem;
        margin-right: 3rem; } }
  .has-margin-center-7 {
    margin-left: 4rem;
    margin-right: 4rem; }
    @media (min-width: 0px) {
      .has-margin-center-7-xs {
        margin-left: 4rem;
        margin-right: 4rem; } }
    @media (min-width: 480px) {
      .has-margin-center-7-sm {
        margin-left: 4rem;
        margin-right: 4rem; } }
    @media (min-width: 720px) {
      .has-margin-center-7-md {
        margin-left: 4rem;
        margin-right: 4rem; } }
    @media (min-width: 1024px) {
      .has-margin-center-7-nav {
        margin-left: 4rem;
        margin-right: 4rem; } }
    @media (min-width: 1024px) {
      .has-margin-center-7-lg {
        margin-left: 4rem;
        margin-right: 4rem; } }
    @media (min-width: 1280px) {
      .has-margin-center-7-xl {
        margin-left: 4rem;
        margin-right: 4rem; } }
    @media (min-width: 1920px) {
      .has-margin-center-7-xxl {
        margin-left: 4rem;
        margin-right: 4rem; } }
  .has-margin-center-8 {
    margin-left: 5rem;
    margin-right: 5rem; }
    @media (min-width: 0px) {
      .has-margin-center-8-xs {
        margin-left: 5rem;
        margin-right: 5rem; } }
    @media (min-width: 480px) {
      .has-margin-center-8-sm {
        margin-left: 5rem;
        margin-right: 5rem; } }
    @media (min-width: 720px) {
      .has-margin-center-8-md {
        margin-left: 5rem;
        margin-right: 5rem; } }
    @media (min-width: 1024px) {
      .has-margin-center-8-nav {
        margin-left: 5rem;
        margin-right: 5rem; } }
    @media (min-width: 1024px) {
      .has-margin-center-8-lg {
        margin-left: 5rem;
        margin-right: 5rem; } }
    @media (min-width: 1280px) {
      .has-margin-center-8-xl {
        margin-left: 5rem;
        margin-right: 5rem; } }
    @media (min-width: 1920px) {
      .has-margin-center-8-xxl {
        margin-left: 5rem;
        margin-right: 5rem; } }

.has-margin-left {
  margin-left:  1rem; }
  @media (min-width: 0px) {
    .has-margin-left-xs {
      margin-left:  1rem; } }
  @media (min-width: 480px) {
    .has-margin-left-sm {
      margin-left:  1rem; } }
  @media (min-width: 720px) {
    .has-margin-left-md {
      margin-left:  1rem; } }
  @media (min-width: 1024px) {
    .has-margin-left-nav {
      margin-left:  1rem; } }
  @media (min-width: 1024px) {
    .has-margin-left-lg {
      margin-left:  1rem; } }
  @media (min-width: 1280px) {
    .has-margin-left-xl {
      margin-left:  1rem; } }
  @media (min-width: 1920px) {
    .has-margin-left-xxl {
      margin-left:  1rem; } }
  .has-margin-left-smallest {
    margin-left:  0.25rem; }
    @media (min-width: 0px) {
      .has-margin-left-smallest-xs {
        margin-left:  0.25rem; } }
    @media (min-width: 480px) {
      .has-margin-left-smallest-sm {
        margin-left:  0.25rem; } }
    @media (min-width: 720px) {
      .has-margin-left-smallest-md {
        margin-left:  0.25rem; } }
    @media (min-width: 1024px) {
      .has-margin-left-smallest-nav {
        margin-left:  0.25rem; } }
    @media (min-width: 1024px) {
      .has-margin-left-smallest-lg {
        margin-left:  0.25rem; } }
    @media (min-width: 1280px) {
      .has-margin-left-smallest-xl {
        margin-left:  0.25rem; } }
    @media (min-width: 1920px) {
      .has-margin-left-smallest-xxl {
        margin-left:  0.25rem; } }
  .has-margin-left-small {
    margin-left:  0.5rem; }
    @media (min-width: 0px) {
      .has-margin-left-small-xs {
        margin-left:  0.5rem; } }
    @media (min-width: 480px) {
      .has-margin-left-small-sm {
        margin-left:  0.5rem; } }
    @media (min-width: 720px) {
      .has-margin-left-small-md {
        margin-left:  0.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-left-small-nav {
        margin-left:  0.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-left-small-lg {
        margin-left:  0.5rem; } }
    @media (min-width: 1280px) {
      .has-margin-left-small-xl {
        margin-left:  0.5rem; } }
    @media (min-width: 1920px) {
      .has-margin-left-small-xxl {
        margin-left:  0.5rem; } }
  .has-margin-left-big {
    margin-left:  2rem; }
    @media (min-width: 0px) {
      .has-margin-left-big-xs {
        margin-left:  2rem; } }
    @media (min-width: 480px) {
      .has-margin-left-big-sm {
        margin-left:  2rem; } }
    @media (min-width: 720px) {
      .has-margin-left-big-md {
        margin-left:  2rem; } }
    @media (min-width: 1024px) {
      .has-margin-left-big-nav {
        margin-left:  2rem; } }
    @media (min-width: 1024px) {
      .has-margin-left-big-lg {
        margin-left:  2rem; } }
    @media (min-width: 1280px) {
      .has-margin-left-big-xl {
        margin-left:  2rem; } }
    @media (min-width: 1920px) {
      .has-margin-left-big-xxl {
        margin-left:  2rem; } }
  .has-margin-left-auto {
    margin-left: auto; }
    @media (min-width: 0px) {
      .has-margin-left-auto-xs {
        margin-left: auto; } }
    @media (min-width: 480px) {
      .has-margin-left-auto-sm {
        margin-left: auto; } }
    @media (min-width: 720px) {
      .has-margin-left-auto-md {
        margin-left: auto; } }
    @media (min-width: 1024px) {
      .has-margin-left-auto-nav {
        margin-left: auto; } }
    @media (min-width: 1024px) {
      .has-margin-left-auto-lg {
        margin-left: auto; } }
    @media (min-width: 1280px) {
      .has-margin-left-auto-xl {
        margin-left: auto; } }
    @media (min-width: 1920px) {
      .has-margin-left-auto-xxl {
        margin-left: auto; } }
  .has-margin-left-0 {
    margin-left: 0; }
    @media (min-width: 0px) {
      .has-margin-left-0-xs {
        margin-left: 0; } }
    @media (min-width: 480px) {
      .has-margin-left-0-sm {
        margin-left: 0; } }
    @media (min-width: 720px) {
      .has-margin-left-0-md {
        margin-left: 0; } }
    @media (min-width: 1024px) {
      .has-margin-left-0-nav {
        margin-left: 0; } }
    @media (min-width: 1024px) {
      .has-margin-left-0-lg {
        margin-left: 0; } }
    @media (min-width: 1280px) {
      .has-margin-left-0-xl {
        margin-left: 0; } }
    @media (min-width: 1920px) {
      .has-margin-left-0-xxl {
        margin-left: 0; } }
  .has-margin-left-1 {
    margin-left: 0.25rem; }
    @media (min-width: 0px) {
      .has-margin-left-1-xs {
        margin-left: 0.25rem; } }
    @media (min-width: 480px) {
      .has-margin-left-1-sm {
        margin-left: 0.25rem; } }
    @media (min-width: 720px) {
      .has-margin-left-1-md {
        margin-left: 0.25rem; } }
    @media (min-width: 1024px) {
      .has-margin-left-1-nav {
        margin-left: 0.25rem; } }
    @media (min-width: 1024px) {
      .has-margin-left-1-lg {
        margin-left: 0.25rem; } }
    @media (min-width: 1280px) {
      .has-margin-left-1-xl {
        margin-left: 0.25rem; } }
    @media (min-width: 1920px) {
      .has-margin-left-1-xxl {
        margin-left: 0.25rem; } }
  .has-margin-left-2 {
    margin-left: 0.5rem; }
    @media (min-width: 0px) {
      .has-margin-left-2-xs {
        margin-left: 0.5rem; } }
    @media (min-width: 480px) {
      .has-margin-left-2-sm {
        margin-left: 0.5rem; } }
    @media (min-width: 720px) {
      .has-margin-left-2-md {
        margin-left: 0.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-left-2-nav {
        margin-left: 0.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-left-2-lg {
        margin-left: 0.5rem; } }
    @media (min-width: 1280px) {
      .has-margin-left-2-xl {
        margin-left: 0.5rem; } }
    @media (min-width: 1920px) {
      .has-margin-left-2-xxl {
        margin-left: 0.5rem; } }
  .has-margin-left-3 {
    margin-left: 1rem; }
    @media (min-width: 0px) {
      .has-margin-left-3-xs {
        margin-left: 1rem; } }
    @media (min-width: 480px) {
      .has-margin-left-3-sm {
        margin-left: 1rem; } }
    @media (min-width: 720px) {
      .has-margin-left-3-md {
        margin-left: 1rem; } }
    @media (min-width: 1024px) {
      .has-margin-left-3-nav {
        margin-left: 1rem; } }
    @media (min-width: 1024px) {
      .has-margin-left-3-lg {
        margin-left: 1rem; } }
    @media (min-width: 1280px) {
      .has-margin-left-3-xl {
        margin-left: 1rem; } }
    @media (min-width: 1920px) {
      .has-margin-left-3-xxl {
        margin-left: 1rem; } }
  .has-margin-left-4 {
    margin-left: 1.5rem; }
    @media (min-width: 0px) {
      .has-margin-left-4-xs {
        margin-left: 1.5rem; } }
    @media (min-width: 480px) {
      .has-margin-left-4-sm {
        margin-left: 1.5rem; } }
    @media (min-width: 720px) {
      .has-margin-left-4-md {
        margin-left: 1.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-left-4-nav {
        margin-left: 1.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-left-4-lg {
        margin-left: 1.5rem; } }
    @media (min-width: 1280px) {
      .has-margin-left-4-xl {
        margin-left: 1.5rem; } }
    @media (min-width: 1920px) {
      .has-margin-left-4-xxl {
        margin-left: 1.5rem; } }
  .has-margin-left-5 {
    margin-left: 2rem; }
    @media (min-width: 0px) {
      .has-margin-left-5-xs {
        margin-left: 2rem; } }
    @media (min-width: 480px) {
      .has-margin-left-5-sm {
        margin-left: 2rem; } }
    @media (min-width: 720px) {
      .has-margin-left-5-md {
        margin-left: 2rem; } }
    @media (min-width: 1024px) {
      .has-margin-left-5-nav {
        margin-left: 2rem; } }
    @media (min-width: 1024px) {
      .has-margin-left-5-lg {
        margin-left: 2rem; } }
    @media (min-width: 1280px) {
      .has-margin-left-5-xl {
        margin-left: 2rem; } }
    @media (min-width: 1920px) {
      .has-margin-left-5-xxl {
        margin-left: 2rem; } }
  .has-margin-left-6 {
    margin-left: 3rem; }
    @media (min-width: 0px) {
      .has-margin-left-6-xs {
        margin-left: 3rem; } }
    @media (min-width: 480px) {
      .has-margin-left-6-sm {
        margin-left: 3rem; } }
    @media (min-width: 720px) {
      .has-margin-left-6-md {
        margin-left: 3rem; } }
    @media (min-width: 1024px) {
      .has-margin-left-6-nav {
        margin-left: 3rem; } }
    @media (min-width: 1024px) {
      .has-margin-left-6-lg {
        margin-left: 3rem; } }
    @media (min-width: 1280px) {
      .has-margin-left-6-xl {
        margin-left: 3rem; } }
    @media (min-width: 1920px) {
      .has-margin-left-6-xxl {
        margin-left: 3rem; } }
  .has-margin-left-7 {
    margin-left: 4rem; }
    @media (min-width: 0px) {
      .has-margin-left-7-xs {
        margin-left: 4rem; } }
    @media (min-width: 480px) {
      .has-margin-left-7-sm {
        margin-left: 4rem; } }
    @media (min-width: 720px) {
      .has-margin-left-7-md {
        margin-left: 4rem; } }
    @media (min-width: 1024px) {
      .has-margin-left-7-nav {
        margin-left: 4rem; } }
    @media (min-width: 1024px) {
      .has-margin-left-7-lg {
        margin-left: 4rem; } }
    @media (min-width: 1280px) {
      .has-margin-left-7-xl {
        margin-left: 4rem; } }
    @media (min-width: 1920px) {
      .has-margin-left-7-xxl {
        margin-left: 4rem; } }
  .has-margin-left-8 {
    margin-left: 5rem; }
    @media (min-width: 0px) {
      .has-margin-left-8-xs {
        margin-left: 5rem; } }
    @media (min-width: 480px) {
      .has-margin-left-8-sm {
        margin-left: 5rem; } }
    @media (min-width: 720px) {
      .has-margin-left-8-md {
        margin-left: 5rem; } }
    @media (min-width: 1024px) {
      .has-margin-left-8-nav {
        margin-left: 5rem; } }
    @media (min-width: 1024px) {
      .has-margin-left-8-lg {
        margin-left: 5rem; } }
    @media (min-width: 1280px) {
      .has-margin-left-8-xl {
        margin-left: 5rem; } }
    @media (min-width: 1920px) {
      .has-margin-left-8-xxl {
        margin-left: 5rem; } }

.has-margin-right {
  margin-right:  1rem; }
  @media (min-width: 0px) {
    .has-margin-right-xs {
      margin-right:  1rem; } }
  @media (min-width: 480px) {
    .has-margin-right-sm {
      margin-right:  1rem; } }
  @media (min-width: 720px) {
    .has-margin-right-md {
      margin-right:  1rem; } }
  @media (min-width: 1024px) {
    .has-margin-right-nav {
      margin-right:  1rem; } }
  @media (min-width: 1024px) {
    .has-margin-right-lg {
      margin-right:  1rem; } }
  @media (min-width: 1280px) {
    .has-margin-right-xl {
      margin-right:  1rem; } }
  @media (min-width: 1920px) {
    .has-margin-right-xxl {
      margin-right:  1rem; } }
  .has-margin-right-smallest {
    margin-right:  0.25rem; }
    @media (min-width: 0px) {
      .has-margin-right-smallest-xs {
        margin-right:  0.25rem; } }
    @media (min-width: 480px) {
      .has-margin-right-smallest-sm {
        margin-right:  0.25rem; } }
    @media (min-width: 720px) {
      .has-margin-right-smallest-md {
        margin-right:  0.25rem; } }
    @media (min-width: 1024px) {
      .has-margin-right-smallest-nav {
        margin-right:  0.25rem; } }
    @media (min-width: 1024px) {
      .has-margin-right-smallest-lg {
        margin-right:  0.25rem; } }
    @media (min-width: 1280px) {
      .has-margin-right-smallest-xl {
        margin-right:  0.25rem; } }
    @media (min-width: 1920px) {
      .has-margin-right-smallest-xxl {
        margin-right:  0.25rem; } }
  .has-margin-right-small {
    margin-right:  0.5rem; }
    @media (min-width: 0px) {
      .has-margin-right-small-xs {
        margin-right:  0.5rem; } }
    @media (min-width: 480px) {
      .has-margin-right-small-sm {
        margin-right:  0.5rem; } }
    @media (min-width: 720px) {
      .has-margin-right-small-md {
        margin-right:  0.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-right-small-nav {
        margin-right:  0.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-right-small-lg {
        margin-right:  0.5rem; } }
    @media (min-width: 1280px) {
      .has-margin-right-small-xl {
        margin-right:  0.5rem; } }
    @media (min-width: 1920px) {
      .has-margin-right-small-xxl {
        margin-right:  0.5rem; } }
  .has-margin-right-big {
    margin-right:  2rem; }
    @media (min-width: 0px) {
      .has-margin-right-big-xs {
        margin-right:  2rem; } }
    @media (min-width: 480px) {
      .has-margin-right-big-sm {
        margin-right:  2rem; } }
    @media (min-width: 720px) {
      .has-margin-right-big-md {
        margin-right:  2rem; } }
    @media (min-width: 1024px) {
      .has-margin-right-big-nav {
        margin-right:  2rem; } }
    @media (min-width: 1024px) {
      .has-margin-right-big-lg {
        margin-right:  2rem; } }
    @media (min-width: 1280px) {
      .has-margin-right-big-xl {
        margin-right:  2rem; } }
    @media (min-width: 1920px) {
      .has-margin-right-big-xxl {
        margin-right:  2rem; } }
  .has-margin-right-auto {
    margin-right: auto; }
    @media (min-width: 0px) {
      .has-margin-right-auto-xs {
        margin-right: auto; } }
    @media (min-width: 480px) {
      .has-margin-right-auto-sm {
        margin-right: auto; } }
    @media (min-width: 720px) {
      .has-margin-right-auto-md {
        margin-right: auto; } }
    @media (min-width: 1024px) {
      .has-margin-right-auto-nav {
        margin-right: auto; } }
    @media (min-width: 1024px) {
      .has-margin-right-auto-lg {
        margin-right: auto; } }
    @media (min-width: 1280px) {
      .has-margin-right-auto-xl {
        margin-right: auto; } }
    @media (min-width: 1920px) {
      .has-margin-right-auto-xxl {
        margin-right: auto; } }
  .has-margin-right-0 {
    margin-right: 0; }
    @media (min-width: 0px) {
      .has-margin-right-0-xs {
        margin-right: 0; } }
    @media (min-width: 480px) {
      .has-margin-right-0-sm {
        margin-right: 0; } }
    @media (min-width: 720px) {
      .has-margin-right-0-md {
        margin-right: 0; } }
    @media (min-width: 1024px) {
      .has-margin-right-0-nav {
        margin-right: 0; } }
    @media (min-width: 1024px) {
      .has-margin-right-0-lg {
        margin-right: 0; } }
    @media (min-width: 1280px) {
      .has-margin-right-0-xl {
        margin-right: 0; } }
    @media (min-width: 1920px) {
      .has-margin-right-0-xxl {
        margin-right: 0; } }
  .has-margin-right-1 {
    margin-right: 0.25rem; }
    @media (min-width: 0px) {
      .has-margin-right-1-xs {
        margin-right: 0.25rem; } }
    @media (min-width: 480px) {
      .has-margin-right-1-sm {
        margin-right: 0.25rem; } }
    @media (min-width: 720px) {
      .has-margin-right-1-md {
        margin-right: 0.25rem; } }
    @media (min-width: 1024px) {
      .has-margin-right-1-nav {
        margin-right: 0.25rem; } }
    @media (min-width: 1024px) {
      .has-margin-right-1-lg {
        margin-right: 0.25rem; } }
    @media (min-width: 1280px) {
      .has-margin-right-1-xl {
        margin-right: 0.25rem; } }
    @media (min-width: 1920px) {
      .has-margin-right-1-xxl {
        margin-right: 0.25rem; } }
  .has-margin-right-2 {
    margin-right: 0.5rem; }
    @media (min-width: 0px) {
      .has-margin-right-2-xs {
        margin-right: 0.5rem; } }
    @media (min-width: 480px) {
      .has-margin-right-2-sm {
        margin-right: 0.5rem; } }
    @media (min-width: 720px) {
      .has-margin-right-2-md {
        margin-right: 0.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-right-2-nav {
        margin-right: 0.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-right-2-lg {
        margin-right: 0.5rem; } }
    @media (min-width: 1280px) {
      .has-margin-right-2-xl {
        margin-right: 0.5rem; } }
    @media (min-width: 1920px) {
      .has-margin-right-2-xxl {
        margin-right: 0.5rem; } }
  .has-margin-right-3 {
    margin-right: 1rem; }
    @media (min-width: 0px) {
      .has-margin-right-3-xs {
        margin-right: 1rem; } }
    @media (min-width: 480px) {
      .has-margin-right-3-sm {
        margin-right: 1rem; } }
    @media (min-width: 720px) {
      .has-margin-right-3-md {
        margin-right: 1rem; } }
    @media (min-width: 1024px) {
      .has-margin-right-3-nav {
        margin-right: 1rem; } }
    @media (min-width: 1024px) {
      .has-margin-right-3-lg {
        margin-right: 1rem; } }
    @media (min-width: 1280px) {
      .has-margin-right-3-xl {
        margin-right: 1rem; } }
    @media (min-width: 1920px) {
      .has-margin-right-3-xxl {
        margin-right: 1rem; } }
  .has-margin-right-4 {
    margin-right: 1.5rem; }
    @media (min-width: 0px) {
      .has-margin-right-4-xs {
        margin-right: 1.5rem; } }
    @media (min-width: 480px) {
      .has-margin-right-4-sm {
        margin-right: 1.5rem; } }
    @media (min-width: 720px) {
      .has-margin-right-4-md {
        margin-right: 1.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-right-4-nav {
        margin-right: 1.5rem; } }
    @media (min-width: 1024px) {
      .has-margin-right-4-lg {
        margin-right: 1.5rem; } }
    @media (min-width: 1280px) {
      .has-margin-right-4-xl {
        margin-right: 1.5rem; } }
    @media (min-width: 1920px) {
      .has-margin-right-4-xxl {
        margin-right: 1.5rem; } }
  .has-margin-right-5 {
    margin-right: 2rem; }
    @media (min-width: 0px) {
      .has-margin-right-5-xs {
        margin-right: 2rem; } }
    @media (min-width: 480px) {
      .has-margin-right-5-sm {
        margin-right: 2rem; } }
    @media (min-width: 720px) {
      .has-margin-right-5-md {
        margin-right: 2rem; } }
    @media (min-width: 1024px) {
      .has-margin-right-5-nav {
        margin-right: 2rem; } }
    @media (min-width: 1024px) {
      .has-margin-right-5-lg {
        margin-right: 2rem; } }
    @media (min-width: 1280px) {
      .has-margin-right-5-xl {
        margin-right: 2rem; } }
    @media (min-width: 1920px) {
      .has-margin-right-5-xxl {
        margin-right: 2rem; } }
  .has-margin-right-6 {
    margin-right: 3rem; }
    @media (min-width: 0px) {
      .has-margin-right-6-xs {
        margin-right: 3rem; } }
    @media (min-width: 480px) {
      .has-margin-right-6-sm {
        margin-right: 3rem; } }
    @media (min-width: 720px) {
      .has-margin-right-6-md {
        margin-right: 3rem; } }
    @media (min-width: 1024px) {
      .has-margin-right-6-nav {
        margin-right: 3rem; } }
    @media (min-width: 1024px) {
      .has-margin-right-6-lg {
        margin-right: 3rem; } }
    @media (min-width: 1280px) {
      .has-margin-right-6-xl {
        margin-right: 3rem; } }
    @media (min-width: 1920px) {
      .has-margin-right-6-xxl {
        margin-right: 3rem; } }
  .has-margin-right-7 {
    margin-right: 4rem; }
    @media (min-width: 0px) {
      .has-margin-right-7-xs {
        margin-right: 4rem; } }
    @media (min-width: 480px) {
      .has-margin-right-7-sm {
        margin-right: 4rem; } }
    @media (min-width: 720px) {
      .has-margin-right-7-md {
        margin-right: 4rem; } }
    @media (min-width: 1024px) {
      .has-margin-right-7-nav {
        margin-right: 4rem; } }
    @media (min-width: 1024px) {
      .has-margin-right-7-lg {
        margin-right: 4rem; } }
    @media (min-width: 1280px) {
      .has-margin-right-7-xl {
        margin-right: 4rem; } }
    @media (min-width: 1920px) {
      .has-margin-right-7-xxl {
        margin-right: 4rem; } }
  .has-margin-right-8 {
    margin-right: 5rem; }
    @media (min-width: 0px) {
      .has-margin-right-8-xs {
        margin-right: 5rem; } }
    @media (min-width: 480px) {
      .has-margin-right-8-sm {
        margin-right: 5rem; } }
    @media (min-width: 720px) {
      .has-margin-right-8-md {
        margin-right: 5rem; } }
    @media (min-width: 1024px) {
      .has-margin-right-8-nav {
        margin-right: 5rem; } }
    @media (min-width: 1024px) {
      .has-margin-right-8-lg {
        margin-right: 5rem; } }
    @media (min-width: 1280px) {
      .has-margin-right-8-xl {
        margin-right: 5rem; } }
    @media (min-width: 1920px) {
      .has-margin-right-8-xxl {
        margin-right: 5rem; } }

.has-padding {
  padding:  1rem; }
  @media (min-width: 0px) {
    .has-padding-xs {
      padding:  1rem; } }
  @media (min-width: 480px) {
    .has-padding-sm {
      padding:  1rem; } }
  @media (min-width: 720px) {
    .has-padding-md {
      padding:  1rem; } }
  @media (min-width: 1024px) {
    .has-padding-nav {
      padding:  1rem; } }
  @media (min-width: 1024px) {
    .has-padding-lg {
      padding:  1rem; } }
  @media (min-width: 1280px) {
    .has-padding-xl {
      padding:  1rem; } }
  @media (min-width: 1920px) {
    .has-padding-xxl {
      padding:  1rem; } }
  .has-padding-smallest {
    padding:  0.25rem; }
    @media (min-width: 0px) {
      .has-padding-smallest-xs {
        padding:  0.25rem; } }
    @media (min-width: 480px) {
      .has-padding-smallest-sm {
        padding:  0.25rem; } }
    @media (min-width: 720px) {
      .has-padding-smallest-md {
        padding:  0.25rem; } }
    @media (min-width: 1024px) {
      .has-padding-smallest-nav {
        padding:  0.25rem; } }
    @media (min-width: 1024px) {
      .has-padding-smallest-lg {
        padding:  0.25rem; } }
    @media (min-width: 1280px) {
      .has-padding-smallest-xl {
        padding:  0.25rem; } }
    @media (min-width: 1920px) {
      .has-padding-smallest-xxl {
        padding:  0.25rem; } }
  .has-padding-small {
    padding:  0.5rem; }
    @media (min-width: 0px) {
      .has-padding-small-xs {
        padding:  0.5rem; } }
    @media (min-width: 480px) {
      .has-padding-small-sm {
        padding:  0.5rem; } }
    @media (min-width: 720px) {
      .has-padding-small-md {
        padding:  0.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-small-nav {
        padding:  0.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-small-lg {
        padding:  0.5rem; } }
    @media (min-width: 1280px) {
      .has-padding-small-xl {
        padding:  0.5rem; } }
    @media (min-width: 1920px) {
      .has-padding-small-xxl {
        padding:  0.5rem; } }
  .has-padding-big {
    padding:  2rem; }
    @media (min-width: 0px) {
      .has-padding-big-xs {
        padding:  2rem; } }
    @media (min-width: 480px) {
      .has-padding-big-sm {
        padding:  2rem; } }
    @media (min-width: 720px) {
      .has-padding-big-md {
        padding:  2rem; } }
    @media (min-width: 1024px) {
      .has-padding-big-nav {
        padding:  2rem; } }
    @media (min-width: 1024px) {
      .has-padding-big-lg {
        padding:  2rem; } }
    @media (min-width: 1280px) {
      .has-padding-big-xl {
        padding:  2rem; } }
    @media (min-width: 1920px) {
      .has-padding-big-xxl {
        padding:  2rem; } }
  .has-padding-0 {
    padding: 0; }
    @media (min-width: 0px) {
      .has-padding-0-xs {
        padding: 0; } }
    @media (min-width: 480px) {
      .has-padding-0-sm {
        padding: 0; } }
    @media (min-width: 720px) {
      .has-padding-0-md {
        padding: 0; } }
    @media (min-width: 1024px) {
      .has-padding-0-nav {
        padding: 0; } }
    @media (min-width: 1024px) {
      .has-padding-0-lg {
        padding: 0; } }
    @media (min-width: 1280px) {
      .has-padding-0-xl {
        padding: 0; } }
    @media (min-width: 1920px) {
      .has-padding-0-xxl {
        padding: 0; } }
  .has-padding-1 {
    padding: 0.25rem; }
    @media (min-width: 0px) {
      .has-padding-1-xs {
        padding: 0.25rem; } }
    @media (min-width: 480px) {
      .has-padding-1-sm {
        padding: 0.25rem; } }
    @media (min-width: 720px) {
      .has-padding-1-md {
        padding: 0.25rem; } }
    @media (min-width: 1024px) {
      .has-padding-1-nav {
        padding: 0.25rem; } }
    @media (min-width: 1024px) {
      .has-padding-1-lg {
        padding: 0.25rem; } }
    @media (min-width: 1280px) {
      .has-padding-1-xl {
        padding: 0.25rem; } }
    @media (min-width: 1920px) {
      .has-padding-1-xxl {
        padding: 0.25rem; } }
  .has-padding-2 {
    padding: 0.5rem; }
    @media (min-width: 0px) {
      .has-padding-2-xs {
        padding: 0.5rem; } }
    @media (min-width: 480px) {
      .has-padding-2-sm {
        padding: 0.5rem; } }
    @media (min-width: 720px) {
      .has-padding-2-md {
        padding: 0.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-2-nav {
        padding: 0.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-2-lg {
        padding: 0.5rem; } }
    @media (min-width: 1280px) {
      .has-padding-2-xl {
        padding: 0.5rem; } }
    @media (min-width: 1920px) {
      .has-padding-2-xxl {
        padding: 0.5rem; } }
  .has-padding-3 {
    padding: 1rem; }
    @media (min-width: 0px) {
      .has-padding-3-xs {
        padding: 1rem; } }
    @media (min-width: 480px) {
      .has-padding-3-sm {
        padding: 1rem; } }
    @media (min-width: 720px) {
      .has-padding-3-md {
        padding: 1rem; } }
    @media (min-width: 1024px) {
      .has-padding-3-nav {
        padding: 1rem; } }
    @media (min-width: 1024px) {
      .has-padding-3-lg {
        padding: 1rem; } }
    @media (min-width: 1280px) {
      .has-padding-3-xl {
        padding: 1rem; } }
    @media (min-width: 1920px) {
      .has-padding-3-xxl {
        padding: 1rem; } }
  .has-padding-4 {
    padding: 1.5rem; }
    @media (min-width: 0px) {
      .has-padding-4-xs {
        padding: 1.5rem; } }
    @media (min-width: 480px) {
      .has-padding-4-sm {
        padding: 1.5rem; } }
    @media (min-width: 720px) {
      .has-padding-4-md {
        padding: 1.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-4-nav {
        padding: 1.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-4-lg {
        padding: 1.5rem; } }
    @media (min-width: 1280px) {
      .has-padding-4-xl {
        padding: 1.5rem; } }
    @media (min-width: 1920px) {
      .has-padding-4-xxl {
        padding: 1.5rem; } }
  .has-padding-5 {
    padding: 2rem; }
    @media (min-width: 0px) {
      .has-padding-5-xs {
        padding: 2rem; } }
    @media (min-width: 480px) {
      .has-padding-5-sm {
        padding: 2rem; } }
    @media (min-width: 720px) {
      .has-padding-5-md {
        padding: 2rem; } }
    @media (min-width: 1024px) {
      .has-padding-5-nav {
        padding: 2rem; } }
    @media (min-width: 1024px) {
      .has-padding-5-lg {
        padding: 2rem; } }
    @media (min-width: 1280px) {
      .has-padding-5-xl {
        padding: 2rem; } }
    @media (min-width: 1920px) {
      .has-padding-5-xxl {
        padding: 2rem; } }
  .has-padding-6 {
    padding: 3rem; }
    @media (min-width: 0px) {
      .has-padding-6-xs {
        padding: 3rem; } }
    @media (min-width: 480px) {
      .has-padding-6-sm {
        padding: 3rem; } }
    @media (min-width: 720px) {
      .has-padding-6-md {
        padding: 3rem; } }
    @media (min-width: 1024px) {
      .has-padding-6-nav {
        padding: 3rem; } }
    @media (min-width: 1024px) {
      .has-padding-6-lg {
        padding: 3rem; } }
    @media (min-width: 1280px) {
      .has-padding-6-xl {
        padding: 3rem; } }
    @media (min-width: 1920px) {
      .has-padding-6-xxl {
        padding: 3rem; } }
  .has-padding-7 {
    padding: 4rem; }
    @media (min-width: 0px) {
      .has-padding-7-xs {
        padding: 4rem; } }
    @media (min-width: 480px) {
      .has-padding-7-sm {
        padding: 4rem; } }
    @media (min-width: 720px) {
      .has-padding-7-md {
        padding: 4rem; } }
    @media (min-width: 1024px) {
      .has-padding-7-nav {
        padding: 4rem; } }
    @media (min-width: 1024px) {
      .has-padding-7-lg {
        padding: 4rem; } }
    @media (min-width: 1280px) {
      .has-padding-7-xl {
        padding: 4rem; } }
    @media (min-width: 1920px) {
      .has-padding-7-xxl {
        padding: 4rem; } }
  .has-padding-8 {
    padding: 5rem; }
    @media (min-width: 0px) {
      .has-padding-8-xs {
        padding: 5rem; } }
    @media (min-width: 480px) {
      .has-padding-8-sm {
        padding: 5rem; } }
    @media (min-width: 720px) {
      .has-padding-8-md {
        padding: 5rem; } }
    @media (min-width: 1024px) {
      .has-padding-8-nav {
        padding: 5rem; } }
    @media (min-width: 1024px) {
      .has-padding-8-lg {
        padding: 5rem; } }
    @media (min-width: 1280px) {
      .has-padding-8-xl {
        padding: 5rem; } }
    @media (min-width: 1920px) {
      .has-padding-8-xxl {
        padding: 5rem; } }

.has-padding-middle {
  padding-bottom:  1rem;
  padding-top:  1rem; }
  @media (min-width: 0px) {
    .has-padding-middle-xs {
      padding-bottom:  1rem;
      padding-top:  1rem; } }
  @media (min-width: 480px) {
    .has-padding-middle-sm {
      padding-bottom:  1rem;
      padding-top:  1rem; } }
  @media (min-width: 720px) {
    .has-padding-middle-md {
      padding-bottom:  1rem;
      padding-top:  1rem; } }
  @media (min-width: 1024px) {
    .has-padding-middle-nav {
      padding-bottom:  1rem;
      padding-top:  1rem; } }
  @media (min-width: 1024px) {
    .has-padding-middle-lg {
      padding-bottom:  1rem;
      padding-top:  1rem; } }
  @media (min-width: 1280px) {
    .has-padding-middle-xl {
      padding-bottom:  1rem;
      padding-top:  1rem; } }
  @media (min-width: 1920px) {
    .has-padding-middle-xxl {
      padding-bottom:  1rem;
      padding-top:  1rem; } }
  .has-padding-middle-smallest {
    padding-bottom:  0.25rem;
    padding-top:  0.25rem; }
    @media (min-width: 0px) {
      .has-padding-middle-smallest-xs {
        padding-bottom:  0.25rem;
        padding-top:  0.25rem; } }
    @media (min-width: 480px) {
      .has-padding-middle-smallest-sm {
        padding-bottom:  0.25rem;
        padding-top:  0.25rem; } }
    @media (min-width: 720px) {
      .has-padding-middle-smallest-md {
        padding-bottom:  0.25rem;
        padding-top:  0.25rem; } }
    @media (min-width: 1024px) {
      .has-padding-middle-smallest-nav {
        padding-bottom:  0.25rem;
        padding-top:  0.25rem; } }
    @media (min-width: 1024px) {
      .has-padding-middle-smallest-lg {
        padding-bottom:  0.25rem;
        padding-top:  0.25rem; } }
    @media (min-width: 1280px) {
      .has-padding-middle-smallest-xl {
        padding-bottom:  0.25rem;
        padding-top:  0.25rem; } }
    @media (min-width: 1920px) {
      .has-padding-middle-smallest-xxl {
        padding-bottom:  0.25rem;
        padding-top:  0.25rem; } }
  .has-padding-middle-small {
    padding-bottom:  0.5rem;
    padding-top:  0.5rem; }
    @media (min-width: 0px) {
      .has-padding-middle-small-xs {
        padding-bottom:  0.5rem;
        padding-top:  0.5rem; } }
    @media (min-width: 480px) {
      .has-padding-middle-small-sm {
        padding-bottom:  0.5rem;
        padding-top:  0.5rem; } }
    @media (min-width: 720px) {
      .has-padding-middle-small-md {
        padding-bottom:  0.5rem;
        padding-top:  0.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-middle-small-nav {
        padding-bottom:  0.5rem;
        padding-top:  0.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-middle-small-lg {
        padding-bottom:  0.5rem;
        padding-top:  0.5rem; } }
    @media (min-width: 1280px) {
      .has-padding-middle-small-xl {
        padding-bottom:  0.5rem;
        padding-top:  0.5rem; } }
    @media (min-width: 1920px) {
      .has-padding-middle-small-xxl {
        padding-bottom:  0.5rem;
        padding-top:  0.5rem; } }
  .has-padding-middle-big {
    padding-bottom:  2rem;
    padding-top:  2rem; }
    @media (min-width: 0px) {
      .has-padding-middle-big-xs {
        padding-bottom:  2rem;
        padding-top:  2rem; } }
    @media (min-width: 480px) {
      .has-padding-middle-big-sm {
        padding-bottom:  2rem;
        padding-top:  2rem; } }
    @media (min-width: 720px) {
      .has-padding-middle-big-md {
        padding-bottom:  2rem;
        padding-top:  2rem; } }
    @media (min-width: 1024px) {
      .has-padding-middle-big-nav {
        padding-bottom:  2rem;
        padding-top:  2rem; } }
    @media (min-width: 1024px) {
      .has-padding-middle-big-lg {
        padding-bottom:  2rem;
        padding-top:  2rem; } }
    @media (min-width: 1280px) {
      .has-padding-middle-big-xl {
        padding-bottom:  2rem;
        padding-top:  2rem; } }
    @media (min-width: 1920px) {
      .has-padding-middle-big-xxl {
        padding-bottom:  2rem;
        padding-top:  2rem; } }
  .has-padding-middle-0 {
    padding-bottom: 0;
    padding-top: 0; }
    @media (min-width: 0px) {
      .has-padding-middle-0-xs {
        padding-bottom: 0;
        padding-top: 0; } }
    @media (min-width: 480px) {
      .has-padding-middle-0-sm {
        padding-bottom: 0;
        padding-top: 0; } }
    @media (min-width: 720px) {
      .has-padding-middle-0-md {
        padding-bottom: 0;
        padding-top: 0; } }
    @media (min-width: 1024px) {
      .has-padding-middle-0-nav {
        padding-bottom: 0;
        padding-top: 0; } }
    @media (min-width: 1024px) {
      .has-padding-middle-0-lg {
        padding-bottom: 0;
        padding-top: 0; } }
    @media (min-width: 1280px) {
      .has-padding-middle-0-xl {
        padding-bottom: 0;
        padding-top: 0; } }
    @media (min-width: 1920px) {
      .has-padding-middle-0-xxl {
        padding-bottom: 0;
        padding-top: 0; } }
  .has-padding-middle-1 {
    padding-bottom: 0.25rem;
    padding-top: 0.25rem; }
    @media (min-width: 0px) {
      .has-padding-middle-1-xs {
        padding-bottom: 0.25rem;
        padding-top: 0.25rem; } }
    @media (min-width: 480px) {
      .has-padding-middle-1-sm {
        padding-bottom: 0.25rem;
        padding-top: 0.25rem; } }
    @media (min-width: 720px) {
      .has-padding-middle-1-md {
        padding-bottom: 0.25rem;
        padding-top: 0.25rem; } }
    @media (min-width: 1024px) {
      .has-padding-middle-1-nav {
        padding-bottom: 0.25rem;
        padding-top: 0.25rem; } }
    @media (min-width: 1024px) {
      .has-padding-middle-1-lg {
        padding-bottom: 0.25rem;
        padding-top: 0.25rem; } }
    @media (min-width: 1280px) {
      .has-padding-middle-1-xl {
        padding-bottom: 0.25rem;
        padding-top: 0.25rem; } }
    @media (min-width: 1920px) {
      .has-padding-middle-1-xxl {
        padding-bottom: 0.25rem;
        padding-top: 0.25rem; } }
  .has-padding-middle-2 {
    padding-bottom: 0.5rem;
    padding-top: 0.5rem; }
    @media (min-width: 0px) {
      .has-padding-middle-2-xs {
        padding-bottom: 0.5rem;
        padding-top: 0.5rem; } }
    @media (min-width: 480px) {
      .has-padding-middle-2-sm {
        padding-bottom: 0.5rem;
        padding-top: 0.5rem; } }
    @media (min-width: 720px) {
      .has-padding-middle-2-md {
        padding-bottom: 0.5rem;
        padding-top: 0.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-middle-2-nav {
        padding-bottom: 0.5rem;
        padding-top: 0.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-middle-2-lg {
        padding-bottom: 0.5rem;
        padding-top: 0.5rem; } }
    @media (min-width: 1280px) {
      .has-padding-middle-2-xl {
        padding-bottom: 0.5rem;
        padding-top: 0.5rem; } }
    @media (min-width: 1920px) {
      .has-padding-middle-2-xxl {
        padding-bottom: 0.5rem;
        padding-top: 0.5rem; } }
  .has-padding-middle-3 {
    padding-bottom: 1rem;
    padding-top: 1rem; }
    @media (min-width: 0px) {
      .has-padding-middle-3-xs {
        padding-bottom: 1rem;
        padding-top: 1rem; } }
    @media (min-width: 480px) {
      .has-padding-middle-3-sm {
        padding-bottom: 1rem;
        padding-top: 1rem; } }
    @media (min-width: 720px) {
      .has-padding-middle-3-md {
        padding-bottom: 1rem;
        padding-top: 1rem; } }
    @media (min-width: 1024px) {
      .has-padding-middle-3-nav {
        padding-bottom: 1rem;
        padding-top: 1rem; } }
    @media (min-width: 1024px) {
      .has-padding-middle-3-lg {
        padding-bottom: 1rem;
        padding-top: 1rem; } }
    @media (min-width: 1280px) {
      .has-padding-middle-3-xl {
        padding-bottom: 1rem;
        padding-top: 1rem; } }
    @media (min-width: 1920px) {
      .has-padding-middle-3-xxl {
        padding-bottom: 1rem;
        padding-top: 1rem; } }
  .has-padding-middle-4 {
    padding-bottom: 1.5rem;
    padding-top: 1.5rem; }
    @media (min-width: 0px) {
      .has-padding-middle-4-xs {
        padding-bottom: 1.5rem;
        padding-top: 1.5rem; } }
    @media (min-width: 480px) {
      .has-padding-middle-4-sm {
        padding-bottom: 1.5rem;
        padding-top: 1.5rem; } }
    @media (min-width: 720px) {
      .has-padding-middle-4-md {
        padding-bottom: 1.5rem;
        padding-top: 1.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-middle-4-nav {
        padding-bottom: 1.5rem;
        padding-top: 1.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-middle-4-lg {
        padding-bottom: 1.5rem;
        padding-top: 1.5rem; } }
    @media (min-width: 1280px) {
      .has-padding-middle-4-xl {
        padding-bottom: 1.5rem;
        padding-top: 1.5rem; } }
    @media (min-width: 1920px) {
      .has-padding-middle-4-xxl {
        padding-bottom: 1.5rem;
        padding-top: 1.5rem; } }
  .has-padding-middle-5 {
    padding-bottom: 2rem;
    padding-top: 2rem; }
    @media (min-width: 0px) {
      .has-padding-middle-5-xs {
        padding-bottom: 2rem;
        padding-top: 2rem; } }
    @media (min-width: 480px) {
      .has-padding-middle-5-sm {
        padding-bottom: 2rem;
        padding-top: 2rem; } }
    @media (min-width: 720px) {
      .has-padding-middle-5-md {
        padding-bottom: 2rem;
        padding-top: 2rem; } }
    @media (min-width: 1024px) {
      .has-padding-middle-5-nav {
        padding-bottom: 2rem;
        padding-top: 2rem; } }
    @media (min-width: 1024px) {
      .has-padding-middle-5-lg {
        padding-bottom: 2rem;
        padding-top: 2rem; } }
    @media (min-width: 1280px) {
      .has-padding-middle-5-xl {
        padding-bottom: 2rem;
        padding-top: 2rem; } }
    @media (min-width: 1920px) {
      .has-padding-middle-5-xxl {
        padding-bottom: 2rem;
        padding-top: 2rem; } }
  .has-padding-middle-6 {
    padding-bottom: 3rem;
    padding-top: 3rem; }
    @media (min-width: 0px) {
      .has-padding-middle-6-xs {
        padding-bottom: 3rem;
        padding-top: 3rem; } }
    @media (min-width: 480px) {
      .has-padding-middle-6-sm {
        padding-bottom: 3rem;
        padding-top: 3rem; } }
    @media (min-width: 720px) {
      .has-padding-middle-6-md {
        padding-bottom: 3rem;
        padding-top: 3rem; } }
    @media (min-width: 1024px) {
      .has-padding-middle-6-nav {
        padding-bottom: 3rem;
        padding-top: 3rem; } }
    @media (min-width: 1024px) {
      .has-padding-middle-6-lg {
        padding-bottom: 3rem;
        padding-top: 3rem; } }
    @media (min-width: 1280px) {
      .has-padding-middle-6-xl {
        padding-bottom: 3rem;
        padding-top: 3rem; } }
    @media (min-width: 1920px) {
      .has-padding-middle-6-xxl {
        padding-bottom: 3rem;
        padding-top: 3rem; } }
  .has-padding-middle-7 {
    padding-bottom: 4rem;
    padding-top: 4rem; }
    @media (min-width: 0px) {
      .has-padding-middle-7-xs {
        padding-bottom: 4rem;
        padding-top: 4rem; } }
    @media (min-width: 480px) {
      .has-padding-middle-7-sm {
        padding-bottom: 4rem;
        padding-top: 4rem; } }
    @media (min-width: 720px) {
      .has-padding-middle-7-md {
        padding-bottom: 4rem;
        padding-top: 4rem; } }
    @media (min-width: 1024px) {
      .has-padding-middle-7-nav {
        padding-bottom: 4rem;
        padding-top: 4rem; } }
    @media (min-width: 1024px) {
      .has-padding-middle-7-lg {
        padding-bottom: 4rem;
        padding-top: 4rem; } }
    @media (min-width: 1280px) {
      .has-padding-middle-7-xl {
        padding-bottom: 4rem;
        padding-top: 4rem; } }
    @media (min-width: 1920px) {
      .has-padding-middle-7-xxl {
        padding-bottom: 4rem;
        padding-top: 4rem; } }
  .has-padding-middle-8 {
    padding-bottom: 5rem;
    padding-top: 5rem; }
    @media (min-width: 0px) {
      .has-padding-middle-8-xs {
        padding-bottom: 5rem;
        padding-top: 5rem; } }
    @media (min-width: 480px) {
      .has-padding-middle-8-sm {
        padding-bottom: 5rem;
        padding-top: 5rem; } }
    @media (min-width: 720px) {
      .has-padding-middle-8-md {
        padding-bottom: 5rem;
        padding-top: 5rem; } }
    @media (min-width: 1024px) {
      .has-padding-middle-8-nav {
        padding-bottom: 5rem;
        padding-top: 5rem; } }
    @media (min-width: 1024px) {
      .has-padding-middle-8-lg {
        padding-bottom: 5rem;
        padding-top: 5rem; } }
    @media (min-width: 1280px) {
      .has-padding-middle-8-xl {
        padding-bottom: 5rem;
        padding-top: 5rem; } }
    @media (min-width: 1920px) {
      .has-padding-middle-8-xxl {
        padding-bottom: 5rem;
        padding-top: 5rem; } }

.has-padding-top {
  padding-top:  1rem; }
  @media (min-width: 0px) {
    .has-padding-top-xs {
      padding-top:  1rem; } }
  @media (min-width: 480px) {
    .has-padding-top-sm {
      padding-top:  1rem; } }
  @media (min-width: 720px) {
    .has-padding-top-md {
      padding-top:  1rem; } }
  @media (min-width: 1024px) {
    .has-padding-top-nav {
      padding-top:  1rem; } }
  @media (min-width: 1024px) {
    .has-padding-top-lg {
      padding-top:  1rem; } }
  @media (min-width: 1280px) {
    .has-padding-top-xl {
      padding-top:  1rem; } }
  @media (min-width: 1920px) {
    .has-padding-top-xxl {
      padding-top:  1rem; } }
  .has-padding-top-smallest {
    padding-top:  0.25rem; }
    @media (min-width: 0px) {
      .has-padding-top-smallest-xs {
        padding-top:  0.25rem; } }
    @media (min-width: 480px) {
      .has-padding-top-smallest-sm {
        padding-top:  0.25rem; } }
    @media (min-width: 720px) {
      .has-padding-top-smallest-md {
        padding-top:  0.25rem; } }
    @media (min-width: 1024px) {
      .has-padding-top-smallest-nav {
        padding-top:  0.25rem; } }
    @media (min-width: 1024px) {
      .has-padding-top-smallest-lg {
        padding-top:  0.25rem; } }
    @media (min-width: 1280px) {
      .has-padding-top-smallest-xl {
        padding-top:  0.25rem; } }
    @media (min-width: 1920px) {
      .has-padding-top-smallest-xxl {
        padding-top:  0.25rem; } }
  .has-padding-top-small {
    padding-top:  0.5rem; }
    @media (min-width: 0px) {
      .has-padding-top-small-xs {
        padding-top:  0.5rem; } }
    @media (min-width: 480px) {
      .has-padding-top-small-sm {
        padding-top:  0.5rem; } }
    @media (min-width: 720px) {
      .has-padding-top-small-md {
        padding-top:  0.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-top-small-nav {
        padding-top:  0.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-top-small-lg {
        padding-top:  0.5rem; } }
    @media (min-width: 1280px) {
      .has-padding-top-small-xl {
        padding-top:  0.5rem; } }
    @media (min-width: 1920px) {
      .has-padding-top-small-xxl {
        padding-top:  0.5rem; } }
  .has-padding-top-big {
    padding-top:  2rem; }
    @media (min-width: 0px) {
      .has-padding-top-big-xs {
        padding-top:  2rem; } }
    @media (min-width: 480px) {
      .has-padding-top-big-sm {
        padding-top:  2rem; } }
    @media (min-width: 720px) {
      .has-padding-top-big-md {
        padding-top:  2rem; } }
    @media (min-width: 1024px) {
      .has-padding-top-big-nav {
        padding-top:  2rem; } }
    @media (min-width: 1024px) {
      .has-padding-top-big-lg {
        padding-top:  2rem; } }
    @media (min-width: 1280px) {
      .has-padding-top-big-xl {
        padding-top:  2rem; } }
    @media (min-width: 1920px) {
      .has-padding-top-big-xxl {
        padding-top:  2rem; } }
  .has-padding-top-0 {
    padding-top: 0; }
    @media (min-width: 0px) {
      .has-padding-top-0-xs {
        padding-top: 0; } }
    @media (min-width: 480px) {
      .has-padding-top-0-sm {
        padding-top: 0; } }
    @media (min-width: 720px) {
      .has-padding-top-0-md {
        padding-top: 0; } }
    @media (min-width: 1024px) {
      .has-padding-top-0-nav {
        padding-top: 0; } }
    @media (min-width: 1024px) {
      .has-padding-top-0-lg {
        padding-top: 0; } }
    @media (min-width: 1280px) {
      .has-padding-top-0-xl {
        padding-top: 0; } }
    @media (min-width: 1920px) {
      .has-padding-top-0-xxl {
        padding-top: 0; } }
  .has-padding-top-1 {
    padding-top: 0.25rem; }
    @media (min-width: 0px) {
      .has-padding-top-1-xs {
        padding-top: 0.25rem; } }
    @media (min-width: 480px) {
      .has-padding-top-1-sm {
        padding-top: 0.25rem; } }
    @media (min-width: 720px) {
      .has-padding-top-1-md {
        padding-top: 0.25rem; } }
    @media (min-width: 1024px) {
      .has-padding-top-1-nav {
        padding-top: 0.25rem; } }
    @media (min-width: 1024px) {
      .has-padding-top-1-lg {
        padding-top: 0.25rem; } }
    @media (min-width: 1280px) {
      .has-padding-top-1-xl {
        padding-top: 0.25rem; } }
    @media (min-width: 1920px) {
      .has-padding-top-1-xxl {
        padding-top: 0.25rem; } }
  .has-padding-top-2 {
    padding-top: 0.5rem; }
    @media (min-width: 0px) {
      .has-padding-top-2-xs {
        padding-top: 0.5rem; } }
    @media (min-width: 480px) {
      .has-padding-top-2-sm {
        padding-top: 0.5rem; } }
    @media (min-width: 720px) {
      .has-padding-top-2-md {
        padding-top: 0.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-top-2-nav {
        padding-top: 0.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-top-2-lg {
        padding-top: 0.5rem; } }
    @media (min-width: 1280px) {
      .has-padding-top-2-xl {
        padding-top: 0.5rem; } }
    @media (min-width: 1920px) {
      .has-padding-top-2-xxl {
        padding-top: 0.5rem; } }
  .has-padding-top-3 {
    padding-top: 1rem; }
    @media (min-width: 0px) {
      .has-padding-top-3-xs {
        padding-top: 1rem; } }
    @media (min-width: 480px) {
      .has-padding-top-3-sm {
        padding-top: 1rem; } }
    @media (min-width: 720px) {
      .has-padding-top-3-md {
        padding-top: 1rem; } }
    @media (min-width: 1024px) {
      .has-padding-top-3-nav {
        padding-top: 1rem; } }
    @media (min-width: 1024px) {
      .has-padding-top-3-lg {
        padding-top: 1rem; } }
    @media (min-width: 1280px) {
      .has-padding-top-3-xl {
        padding-top: 1rem; } }
    @media (min-width: 1920px) {
      .has-padding-top-3-xxl {
        padding-top: 1rem; } }
  .has-padding-top-4 {
    padding-top: 1.5rem; }
    @media (min-width: 0px) {
      .has-padding-top-4-xs {
        padding-top: 1.5rem; } }
    @media (min-width: 480px) {
      .has-padding-top-4-sm {
        padding-top: 1.5rem; } }
    @media (min-width: 720px) {
      .has-padding-top-4-md {
        padding-top: 1.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-top-4-nav {
        padding-top: 1.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-top-4-lg {
        padding-top: 1.5rem; } }
    @media (min-width: 1280px) {
      .has-padding-top-4-xl {
        padding-top: 1.5rem; } }
    @media (min-width: 1920px) {
      .has-padding-top-4-xxl {
        padding-top: 1.5rem; } }
  .has-padding-top-5 {
    padding-top: 2rem; }
    @media (min-width: 0px) {
      .has-padding-top-5-xs {
        padding-top: 2rem; } }
    @media (min-width: 480px) {
      .has-padding-top-5-sm {
        padding-top: 2rem; } }
    @media (min-width: 720px) {
      .has-padding-top-5-md {
        padding-top: 2rem; } }
    @media (min-width: 1024px) {
      .has-padding-top-5-nav {
        padding-top: 2rem; } }
    @media (min-width: 1024px) {
      .has-padding-top-5-lg {
        padding-top: 2rem; } }
    @media (min-width: 1280px) {
      .has-padding-top-5-xl {
        padding-top: 2rem; } }
    @media (min-width: 1920px) {
      .has-padding-top-5-xxl {
        padding-top: 2rem; } }
  .has-padding-top-6 {
    padding-top: 3rem; }
    @media (min-width: 0px) {
      .has-padding-top-6-xs {
        padding-top: 3rem; } }
    @media (min-width: 480px) {
      .has-padding-top-6-sm {
        padding-top: 3rem; } }
    @media (min-width: 720px) {
      .has-padding-top-6-md {
        padding-top: 3rem; } }
    @media (min-width: 1024px) {
      .has-padding-top-6-nav {
        padding-top: 3rem; } }
    @media (min-width: 1024px) {
      .has-padding-top-6-lg {
        padding-top: 3rem; } }
    @media (min-width: 1280px) {
      .has-padding-top-6-xl {
        padding-top: 3rem; } }
    @media (min-width: 1920px) {
      .has-padding-top-6-xxl {
        padding-top: 3rem; } }
  .has-padding-top-7 {
    padding-top: 4rem; }
    @media (min-width: 0px) {
      .has-padding-top-7-xs {
        padding-top: 4rem; } }
    @media (min-width: 480px) {
      .has-padding-top-7-sm {
        padding-top: 4rem; } }
    @media (min-width: 720px) {
      .has-padding-top-7-md {
        padding-top: 4rem; } }
    @media (min-width: 1024px) {
      .has-padding-top-7-nav {
        padding-top: 4rem; } }
    @media (min-width: 1024px) {
      .has-padding-top-7-lg {
        padding-top: 4rem; } }
    @media (min-width: 1280px) {
      .has-padding-top-7-xl {
        padding-top: 4rem; } }
    @media (min-width: 1920px) {
      .has-padding-top-7-xxl {
        padding-top: 4rem; } }
  .has-padding-top-8 {
    padding-top: 5rem; }
    @media (min-width: 0px) {
      .has-padding-top-8-xs {
        padding-top: 5rem; } }
    @media (min-width: 480px) {
      .has-padding-top-8-sm {
        padding-top: 5rem; } }
    @media (min-width: 720px) {
      .has-padding-top-8-md {
        padding-top: 5rem; } }
    @media (min-width: 1024px) {
      .has-padding-top-8-nav {
        padding-top: 5rem; } }
    @media (min-width: 1024px) {
      .has-padding-top-8-lg {
        padding-top: 5rem; } }
    @media (min-width: 1280px) {
      .has-padding-top-8-xl {
        padding-top: 5rem; } }
    @media (min-width: 1920px) {
      .has-padding-top-8-xxl {
        padding-top: 5rem; } }

.has-padding-bottom {
  padding-bottom:  1rem; }
  @media (min-width: 0px) {
    .has-padding-bottom-xs {
      padding-bottom:  1rem; } }
  @media (min-width: 480px) {
    .has-padding-bottom-sm {
      padding-bottom:  1rem; } }
  @media (min-width: 720px) {
    .has-padding-bottom-md {
      padding-bottom:  1rem; } }
  @media (min-width: 1024px) {
    .has-padding-bottom-nav {
      padding-bottom:  1rem; } }
  @media (min-width: 1024px) {
    .has-padding-bottom-lg {
      padding-bottom:  1rem; } }
  @media (min-width: 1280px) {
    .has-padding-bottom-xl {
      padding-bottom:  1rem; } }
  @media (min-width: 1920px) {
    .has-padding-bottom-xxl {
      padding-bottom:  1rem; } }
  .has-padding-bottom-smallest {
    padding-bottom:  0.25rem; }
    @media (min-width: 0px) {
      .has-padding-bottom-smallest-xs {
        padding-bottom:  0.25rem; } }
    @media (min-width: 480px) {
      .has-padding-bottom-smallest-sm {
        padding-bottom:  0.25rem; } }
    @media (min-width: 720px) {
      .has-padding-bottom-smallest-md {
        padding-bottom:  0.25rem; } }
    @media (min-width: 1024px) {
      .has-padding-bottom-smallest-nav {
        padding-bottom:  0.25rem; } }
    @media (min-width: 1024px) {
      .has-padding-bottom-smallest-lg {
        padding-bottom:  0.25rem; } }
    @media (min-width: 1280px) {
      .has-padding-bottom-smallest-xl {
        padding-bottom:  0.25rem; } }
    @media (min-width: 1920px) {
      .has-padding-bottom-smallest-xxl {
        padding-bottom:  0.25rem; } }
  .has-padding-bottom-small {
    padding-bottom:  0.5rem; }
    @media (min-width: 0px) {
      .has-padding-bottom-small-xs {
        padding-bottom:  0.5rem; } }
    @media (min-width: 480px) {
      .has-padding-bottom-small-sm {
        padding-bottom:  0.5rem; } }
    @media (min-width: 720px) {
      .has-padding-bottom-small-md {
        padding-bottom:  0.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-bottom-small-nav {
        padding-bottom:  0.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-bottom-small-lg {
        padding-bottom:  0.5rem; } }
    @media (min-width: 1280px) {
      .has-padding-bottom-small-xl {
        padding-bottom:  0.5rem; } }
    @media (min-width: 1920px) {
      .has-padding-bottom-small-xxl {
        padding-bottom:  0.5rem; } }
  .has-padding-bottom-big {
    padding-bottom:  2rem; }
    @media (min-width: 0px) {
      .has-padding-bottom-big-xs {
        padding-bottom:  2rem; } }
    @media (min-width: 480px) {
      .has-padding-bottom-big-sm {
        padding-bottom:  2rem; } }
    @media (min-width: 720px) {
      .has-padding-bottom-big-md {
        padding-bottom:  2rem; } }
    @media (min-width: 1024px) {
      .has-padding-bottom-big-nav {
        padding-bottom:  2rem; } }
    @media (min-width: 1024px) {
      .has-padding-bottom-big-lg {
        padding-bottom:  2rem; } }
    @media (min-width: 1280px) {
      .has-padding-bottom-big-xl {
        padding-bottom:  2rem; } }
    @media (min-width: 1920px) {
      .has-padding-bottom-big-xxl {
        padding-bottom:  2rem; } }
  .has-padding-bottom-0 {
    padding-bottom: 0; }
    @media (min-width: 0px) {
      .has-padding-bottom-0-xs {
        padding-bottom: 0; } }
    @media (min-width: 480px) {
      .has-padding-bottom-0-sm {
        padding-bottom: 0; } }
    @media (min-width: 720px) {
      .has-padding-bottom-0-md {
        padding-bottom: 0; } }
    @media (min-width: 1024px) {
      .has-padding-bottom-0-nav {
        padding-bottom: 0; } }
    @media (min-width: 1024px) {
      .has-padding-bottom-0-lg {
        padding-bottom: 0; } }
    @media (min-width: 1280px) {
      .has-padding-bottom-0-xl {
        padding-bottom: 0; } }
    @media (min-width: 1920px) {
      .has-padding-bottom-0-xxl {
        padding-bottom: 0; } }
  .has-padding-bottom-1 {
    padding-bottom: 0.25rem; }
    @media (min-width: 0px) {
      .has-padding-bottom-1-xs {
        padding-bottom: 0.25rem; } }
    @media (min-width: 480px) {
      .has-padding-bottom-1-sm {
        padding-bottom: 0.25rem; } }
    @media (min-width: 720px) {
      .has-padding-bottom-1-md {
        padding-bottom: 0.25rem; } }
    @media (min-width: 1024px) {
      .has-padding-bottom-1-nav {
        padding-bottom: 0.25rem; } }
    @media (min-width: 1024px) {
      .has-padding-bottom-1-lg {
        padding-bottom: 0.25rem; } }
    @media (min-width: 1280px) {
      .has-padding-bottom-1-xl {
        padding-bottom: 0.25rem; } }
    @media (min-width: 1920px) {
      .has-padding-bottom-1-xxl {
        padding-bottom: 0.25rem; } }
  .has-padding-bottom-2 {
    padding-bottom: 0.5rem; }
    @media (min-width: 0px) {
      .has-padding-bottom-2-xs {
        padding-bottom: 0.5rem; } }
    @media (min-width: 480px) {
      .has-padding-bottom-2-sm {
        padding-bottom: 0.5rem; } }
    @media (min-width: 720px) {
      .has-padding-bottom-2-md {
        padding-bottom: 0.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-bottom-2-nav {
        padding-bottom: 0.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-bottom-2-lg {
        padding-bottom: 0.5rem; } }
    @media (min-width: 1280px) {
      .has-padding-bottom-2-xl {
        padding-bottom: 0.5rem; } }
    @media (min-width: 1920px) {
      .has-padding-bottom-2-xxl {
        padding-bottom: 0.5rem; } }
  .has-padding-bottom-3 {
    padding-bottom: 1rem; }
    @media (min-width: 0px) {
      .has-padding-bottom-3-xs {
        padding-bottom: 1rem; } }
    @media (min-width: 480px) {
      .has-padding-bottom-3-sm {
        padding-bottom: 1rem; } }
    @media (min-width: 720px) {
      .has-padding-bottom-3-md {
        padding-bottom: 1rem; } }
    @media (min-width: 1024px) {
      .has-padding-bottom-3-nav {
        padding-bottom: 1rem; } }
    @media (min-width: 1024px) {
      .has-padding-bottom-3-lg {
        padding-bottom: 1rem; } }
    @media (min-width: 1280px) {
      .has-padding-bottom-3-xl {
        padding-bottom: 1rem; } }
    @media (min-width: 1920px) {
      .has-padding-bottom-3-xxl {
        padding-bottom: 1rem; } }
  .has-padding-bottom-4 {
    padding-bottom: 1.5rem; }
    @media (min-width: 0px) {
      .has-padding-bottom-4-xs {
        padding-bottom: 1.5rem; } }
    @media (min-width: 480px) {
      .has-padding-bottom-4-sm {
        padding-bottom: 1.5rem; } }
    @media (min-width: 720px) {
      .has-padding-bottom-4-md {
        padding-bottom: 1.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-bottom-4-nav {
        padding-bottom: 1.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-bottom-4-lg {
        padding-bottom: 1.5rem; } }
    @media (min-width: 1280px) {
      .has-padding-bottom-4-xl {
        padding-bottom: 1.5rem; } }
    @media (min-width: 1920px) {
      .has-padding-bottom-4-xxl {
        padding-bottom: 1.5rem; } }
  .has-padding-bottom-5 {
    padding-bottom: 2rem; }
    @media (min-width: 0px) {
      .has-padding-bottom-5-xs {
        padding-bottom: 2rem; } }
    @media (min-width: 480px) {
      .has-padding-bottom-5-sm {
        padding-bottom: 2rem; } }
    @media (min-width: 720px) {
      .has-padding-bottom-5-md {
        padding-bottom: 2rem; } }
    @media (min-width: 1024px) {
      .has-padding-bottom-5-nav {
        padding-bottom: 2rem; } }
    @media (min-width: 1024px) {
      .has-padding-bottom-5-lg {
        padding-bottom: 2rem; } }
    @media (min-width: 1280px) {
      .has-padding-bottom-5-xl {
        padding-bottom: 2rem; } }
    @media (min-width: 1920px) {
      .has-padding-bottom-5-xxl {
        padding-bottom: 2rem; } }
  .has-padding-bottom-6 {
    padding-bottom: 3rem; }
    @media (min-width: 0px) {
      .has-padding-bottom-6-xs {
        padding-bottom: 3rem; } }
    @media (min-width: 480px) {
      .has-padding-bottom-6-sm {
        padding-bottom: 3rem; } }
    @media (min-width: 720px) {
      .has-padding-bottom-6-md {
        padding-bottom: 3rem; } }
    @media (min-width: 1024px) {
      .has-padding-bottom-6-nav {
        padding-bottom: 3rem; } }
    @media (min-width: 1024px) {
      .has-padding-bottom-6-lg {
        padding-bottom: 3rem; } }
    @media (min-width: 1280px) {
      .has-padding-bottom-6-xl {
        padding-bottom: 3rem; } }
    @media (min-width: 1920px) {
      .has-padding-bottom-6-xxl {
        padding-bottom: 3rem; } }
  .has-padding-bottom-7 {
    padding-bottom: 4rem; }
    @media (min-width: 0px) {
      .has-padding-bottom-7-xs {
        padding-bottom: 4rem; } }
    @media (min-width: 480px) {
      .has-padding-bottom-7-sm {
        padding-bottom: 4rem; } }
    @media (min-width: 720px) {
      .has-padding-bottom-7-md {
        padding-bottom: 4rem; } }
    @media (min-width: 1024px) {
      .has-padding-bottom-7-nav {
        padding-bottom: 4rem; } }
    @media (min-width: 1024px) {
      .has-padding-bottom-7-lg {
        padding-bottom: 4rem; } }
    @media (min-width: 1280px) {
      .has-padding-bottom-7-xl {
        padding-bottom: 4rem; } }
    @media (min-width: 1920px) {
      .has-padding-bottom-7-xxl {
        padding-bottom: 4rem; } }
  .has-padding-bottom-8 {
    padding-bottom: 5rem; }
    @media (min-width: 0px) {
      .has-padding-bottom-8-xs {
        padding-bottom: 5rem; } }
    @media (min-width: 480px) {
      .has-padding-bottom-8-sm {
        padding-bottom: 5rem; } }
    @media (min-width: 720px) {
      .has-padding-bottom-8-md {
        padding-bottom: 5rem; } }
    @media (min-width: 1024px) {
      .has-padding-bottom-8-nav {
        padding-bottom: 5rem; } }
    @media (min-width: 1024px) {
      .has-padding-bottom-8-lg {
        padding-bottom: 5rem; } }
    @media (min-width: 1280px) {
      .has-padding-bottom-8-xl {
        padding-bottom: 5rem; } }
    @media (min-width: 1920px) {
      .has-padding-bottom-8-xxl {
        padding-bottom: 5rem; } }

.has-padding-center {
  padding-left:  1rem;
  padding-right:  1rem; }
  @media (min-width: 0px) {
    .has-padding-center-xs {
      padding-left:  1rem;
      padding-right:  1rem; } }
  @media (min-width: 480px) {
    .has-padding-center-sm {
      padding-left:  1rem;
      padding-right:  1rem; } }
  @media (min-width: 720px) {
    .has-padding-center-md {
      padding-left:  1rem;
      padding-right:  1rem; } }
  @media (min-width: 1024px) {
    .has-padding-center-nav {
      padding-left:  1rem;
      padding-right:  1rem; } }
  @media (min-width: 1024px) {
    .has-padding-center-lg {
      padding-left:  1rem;
      padding-right:  1rem; } }
  @media (min-width: 1280px) {
    .has-padding-center-xl {
      padding-left:  1rem;
      padding-right:  1rem; } }
  @media (min-width: 1920px) {
    .has-padding-center-xxl {
      padding-left:  1rem;
      padding-right:  1rem; } }
  .has-padding-center-smallest {
    padding-left:  0.25rem;
    padding-right:  0.25rem; }
    @media (min-width: 0px) {
      .has-padding-center-smallest-xs {
        padding-left:  0.25rem;
        padding-right:  0.25rem; } }
    @media (min-width: 480px) {
      .has-padding-center-smallest-sm {
        padding-left:  0.25rem;
        padding-right:  0.25rem; } }
    @media (min-width: 720px) {
      .has-padding-center-smallest-md {
        padding-left:  0.25rem;
        padding-right:  0.25rem; } }
    @media (min-width: 1024px) {
      .has-padding-center-smallest-nav {
        padding-left:  0.25rem;
        padding-right:  0.25rem; } }
    @media (min-width: 1024px) {
      .has-padding-center-smallest-lg {
        padding-left:  0.25rem;
        padding-right:  0.25rem; } }
    @media (min-width: 1280px) {
      .has-padding-center-smallest-xl {
        padding-left:  0.25rem;
        padding-right:  0.25rem; } }
    @media (min-width: 1920px) {
      .has-padding-center-smallest-xxl {
        padding-left:  0.25rem;
        padding-right:  0.25rem; } }
  .has-padding-center-small {
    padding-left:  0.5rem;
    padding-right:  0.5rem; }
    @media (min-width: 0px) {
      .has-padding-center-small-xs {
        padding-left:  0.5rem;
        padding-right:  0.5rem; } }
    @media (min-width: 480px) {
      .has-padding-center-small-sm {
        padding-left:  0.5rem;
        padding-right:  0.5rem; } }
    @media (min-width: 720px) {
      .has-padding-center-small-md {
        padding-left:  0.5rem;
        padding-right:  0.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-center-small-nav {
        padding-left:  0.5rem;
        padding-right:  0.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-center-small-lg {
        padding-left:  0.5rem;
        padding-right:  0.5rem; } }
    @media (min-width: 1280px) {
      .has-padding-center-small-xl {
        padding-left:  0.5rem;
        padding-right:  0.5rem; } }
    @media (min-width: 1920px) {
      .has-padding-center-small-xxl {
        padding-left:  0.5rem;
        padding-right:  0.5rem; } }
  .has-padding-center-big {
    padding-left:  2rem;
    padding-right:  2rem; }
    @media (min-width: 0px) {
      .has-padding-center-big-xs {
        padding-left:  2rem;
        padding-right:  2rem; } }
    @media (min-width: 480px) {
      .has-padding-center-big-sm {
        padding-left:  2rem;
        padding-right:  2rem; } }
    @media (min-width: 720px) {
      .has-padding-center-big-md {
        padding-left:  2rem;
        padding-right:  2rem; } }
    @media (min-width: 1024px) {
      .has-padding-center-big-nav {
        padding-left:  2rem;
        padding-right:  2rem; } }
    @media (min-width: 1024px) {
      .has-padding-center-big-lg {
        padding-left:  2rem;
        padding-right:  2rem; } }
    @media (min-width: 1280px) {
      .has-padding-center-big-xl {
        padding-left:  2rem;
        padding-right:  2rem; } }
    @media (min-width: 1920px) {
      .has-padding-center-big-xxl {
        padding-left:  2rem;
        padding-right:  2rem; } }
  .has-padding-center-0 {
    padding-left: 0;
    padding-right: 0; }
    @media (min-width: 0px) {
      .has-padding-center-0-xs {
        padding-left: 0;
        padding-right: 0; } }
    @media (min-width: 480px) {
      .has-padding-center-0-sm {
        padding-left: 0;
        padding-right: 0; } }
    @media (min-width: 720px) {
      .has-padding-center-0-md {
        padding-left: 0;
        padding-right: 0; } }
    @media (min-width: 1024px) {
      .has-padding-center-0-nav {
        padding-left: 0;
        padding-right: 0; } }
    @media (min-width: 1024px) {
      .has-padding-center-0-lg {
        padding-left: 0;
        padding-right: 0; } }
    @media (min-width: 1280px) {
      .has-padding-center-0-xl {
        padding-left: 0;
        padding-right: 0; } }
    @media (min-width: 1920px) {
      .has-padding-center-0-xxl {
        padding-left: 0;
        padding-right: 0; } }
  .has-padding-center-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem; }
    @media (min-width: 0px) {
      .has-padding-center-1-xs {
        padding-left: 0.25rem;
        padding-right: 0.25rem; } }
    @media (min-width: 480px) {
      .has-padding-center-1-sm {
        padding-left: 0.25rem;
        padding-right: 0.25rem; } }
    @media (min-width: 720px) {
      .has-padding-center-1-md {
        padding-left: 0.25rem;
        padding-right: 0.25rem; } }
    @media (min-width: 1024px) {
      .has-padding-center-1-nav {
        padding-left: 0.25rem;
        padding-right: 0.25rem; } }
    @media (min-width: 1024px) {
      .has-padding-center-1-lg {
        padding-left: 0.25rem;
        padding-right: 0.25rem; } }
    @media (min-width: 1280px) {
      .has-padding-center-1-xl {
        padding-left: 0.25rem;
        padding-right: 0.25rem; } }
    @media (min-width: 1920px) {
      .has-padding-center-1-xxl {
        padding-left: 0.25rem;
        padding-right: 0.25rem; } }
  .has-padding-center-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
    @media (min-width: 0px) {
      .has-padding-center-2-xs {
        padding-left: 0.5rem;
        padding-right: 0.5rem; } }
    @media (min-width: 480px) {
      .has-padding-center-2-sm {
        padding-left: 0.5rem;
        padding-right: 0.5rem; } }
    @media (min-width: 720px) {
      .has-padding-center-2-md {
        padding-left: 0.5rem;
        padding-right: 0.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-center-2-nav {
        padding-left: 0.5rem;
        padding-right: 0.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-center-2-lg {
        padding-left: 0.5rem;
        padding-right: 0.5rem; } }
    @media (min-width: 1280px) {
      .has-padding-center-2-xl {
        padding-left: 0.5rem;
        padding-right: 0.5rem; } }
    @media (min-width: 1920px) {
      .has-padding-center-2-xxl {
        padding-left: 0.5rem;
        padding-right: 0.5rem; } }
  .has-padding-center-3 {
    padding-left: 1rem;
    padding-right: 1rem; }
    @media (min-width: 0px) {
      .has-padding-center-3-xs {
        padding-left: 1rem;
        padding-right: 1rem; } }
    @media (min-width: 480px) {
      .has-padding-center-3-sm {
        padding-left: 1rem;
        padding-right: 1rem; } }
    @media (min-width: 720px) {
      .has-padding-center-3-md {
        padding-left: 1rem;
        padding-right: 1rem; } }
    @media (min-width: 1024px) {
      .has-padding-center-3-nav {
        padding-left: 1rem;
        padding-right: 1rem; } }
    @media (min-width: 1024px) {
      .has-padding-center-3-lg {
        padding-left: 1rem;
        padding-right: 1rem; } }
    @media (min-width: 1280px) {
      .has-padding-center-3-xl {
        padding-left: 1rem;
        padding-right: 1rem; } }
    @media (min-width: 1920px) {
      .has-padding-center-3-xxl {
        padding-left: 1rem;
        padding-right: 1rem; } }
  .has-padding-center-4 {
    padding-left: 1.5rem;
    padding-right: 1.5rem; }
    @media (min-width: 0px) {
      .has-padding-center-4-xs {
        padding-left: 1.5rem;
        padding-right: 1.5rem; } }
    @media (min-width: 480px) {
      .has-padding-center-4-sm {
        padding-left: 1.5rem;
        padding-right: 1.5rem; } }
    @media (min-width: 720px) {
      .has-padding-center-4-md {
        padding-left: 1.5rem;
        padding-right: 1.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-center-4-nav {
        padding-left: 1.5rem;
        padding-right: 1.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-center-4-lg {
        padding-left: 1.5rem;
        padding-right: 1.5rem; } }
    @media (min-width: 1280px) {
      .has-padding-center-4-xl {
        padding-left: 1.5rem;
        padding-right: 1.5rem; } }
    @media (min-width: 1920px) {
      .has-padding-center-4-xxl {
        padding-left: 1.5rem;
        padding-right: 1.5rem; } }
  .has-padding-center-5 {
    padding-left: 2rem;
    padding-right: 2rem; }
    @media (min-width: 0px) {
      .has-padding-center-5-xs {
        padding-left: 2rem;
        padding-right: 2rem; } }
    @media (min-width: 480px) {
      .has-padding-center-5-sm {
        padding-left: 2rem;
        padding-right: 2rem; } }
    @media (min-width: 720px) {
      .has-padding-center-5-md {
        padding-left: 2rem;
        padding-right: 2rem; } }
    @media (min-width: 1024px) {
      .has-padding-center-5-nav {
        padding-left: 2rem;
        padding-right: 2rem; } }
    @media (min-width: 1024px) {
      .has-padding-center-5-lg {
        padding-left: 2rem;
        padding-right: 2rem; } }
    @media (min-width: 1280px) {
      .has-padding-center-5-xl {
        padding-left: 2rem;
        padding-right: 2rem; } }
    @media (min-width: 1920px) {
      .has-padding-center-5-xxl {
        padding-left: 2rem;
        padding-right: 2rem; } }
  .has-padding-center-6 {
    padding-left: 3rem;
    padding-right: 3rem; }
    @media (min-width: 0px) {
      .has-padding-center-6-xs {
        padding-left: 3rem;
        padding-right: 3rem; } }
    @media (min-width: 480px) {
      .has-padding-center-6-sm {
        padding-left: 3rem;
        padding-right: 3rem; } }
    @media (min-width: 720px) {
      .has-padding-center-6-md {
        padding-left: 3rem;
        padding-right: 3rem; } }
    @media (min-width: 1024px) {
      .has-padding-center-6-nav {
        padding-left: 3rem;
        padding-right: 3rem; } }
    @media (min-width: 1024px) {
      .has-padding-center-6-lg {
        padding-left: 3rem;
        padding-right: 3rem; } }
    @media (min-width: 1280px) {
      .has-padding-center-6-xl {
        padding-left: 3rem;
        padding-right: 3rem; } }
    @media (min-width: 1920px) {
      .has-padding-center-6-xxl {
        padding-left: 3rem;
        padding-right: 3rem; } }
  .has-padding-center-7 {
    padding-left: 4rem;
    padding-right: 4rem; }
    @media (min-width: 0px) {
      .has-padding-center-7-xs {
        padding-left: 4rem;
        padding-right: 4rem; } }
    @media (min-width: 480px) {
      .has-padding-center-7-sm {
        padding-left: 4rem;
        padding-right: 4rem; } }
    @media (min-width: 720px) {
      .has-padding-center-7-md {
        padding-left: 4rem;
        padding-right: 4rem; } }
    @media (min-width: 1024px) {
      .has-padding-center-7-nav {
        padding-left: 4rem;
        padding-right: 4rem; } }
    @media (min-width: 1024px) {
      .has-padding-center-7-lg {
        padding-left: 4rem;
        padding-right: 4rem; } }
    @media (min-width: 1280px) {
      .has-padding-center-7-xl {
        padding-left: 4rem;
        padding-right: 4rem; } }
    @media (min-width: 1920px) {
      .has-padding-center-7-xxl {
        padding-left: 4rem;
        padding-right: 4rem; } }
  .has-padding-center-8 {
    padding-left: 5rem;
    padding-right: 5rem; }
    @media (min-width: 0px) {
      .has-padding-center-8-xs {
        padding-left: 5rem;
        padding-right: 5rem; } }
    @media (min-width: 480px) {
      .has-padding-center-8-sm {
        padding-left: 5rem;
        padding-right: 5rem; } }
    @media (min-width: 720px) {
      .has-padding-center-8-md {
        padding-left: 5rem;
        padding-right: 5rem; } }
    @media (min-width: 1024px) {
      .has-padding-center-8-nav {
        padding-left: 5rem;
        padding-right: 5rem; } }
    @media (min-width: 1024px) {
      .has-padding-center-8-lg {
        padding-left: 5rem;
        padding-right: 5rem; } }
    @media (min-width: 1280px) {
      .has-padding-center-8-xl {
        padding-left: 5rem;
        padding-right: 5rem; } }
    @media (min-width: 1920px) {
      .has-padding-center-8-xxl {
        padding-left: 5rem;
        padding-right: 5rem; } }

.has-padding-left {
  padding-left:  1rem; }
  @media (min-width: 0px) {
    .has-padding-left-xs {
      padding-left:  1rem; } }
  @media (min-width: 480px) {
    .has-padding-left-sm {
      padding-left:  1rem; } }
  @media (min-width: 720px) {
    .has-padding-left-md {
      padding-left:  1rem; } }
  @media (min-width: 1024px) {
    .has-padding-left-nav {
      padding-left:  1rem; } }
  @media (min-width: 1024px) {
    .has-padding-left-lg {
      padding-left:  1rem; } }
  @media (min-width: 1280px) {
    .has-padding-left-xl {
      padding-left:  1rem; } }
  @media (min-width: 1920px) {
    .has-padding-left-xxl {
      padding-left:  1rem; } }
  .has-padding-left-smallest {
    padding-left:  0.25rem; }
    @media (min-width: 0px) {
      .has-padding-left-smallest-xs {
        padding-left:  0.25rem; } }
    @media (min-width: 480px) {
      .has-padding-left-smallest-sm {
        padding-left:  0.25rem; } }
    @media (min-width: 720px) {
      .has-padding-left-smallest-md {
        padding-left:  0.25rem; } }
    @media (min-width: 1024px) {
      .has-padding-left-smallest-nav {
        padding-left:  0.25rem; } }
    @media (min-width: 1024px) {
      .has-padding-left-smallest-lg {
        padding-left:  0.25rem; } }
    @media (min-width: 1280px) {
      .has-padding-left-smallest-xl {
        padding-left:  0.25rem; } }
    @media (min-width: 1920px) {
      .has-padding-left-smallest-xxl {
        padding-left:  0.25rem; } }
  .has-padding-left-small {
    padding-left:  0.5rem; }
    @media (min-width: 0px) {
      .has-padding-left-small-xs {
        padding-left:  0.5rem; } }
    @media (min-width: 480px) {
      .has-padding-left-small-sm {
        padding-left:  0.5rem; } }
    @media (min-width: 720px) {
      .has-padding-left-small-md {
        padding-left:  0.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-left-small-nav {
        padding-left:  0.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-left-small-lg {
        padding-left:  0.5rem; } }
    @media (min-width: 1280px) {
      .has-padding-left-small-xl {
        padding-left:  0.5rem; } }
    @media (min-width: 1920px) {
      .has-padding-left-small-xxl {
        padding-left:  0.5rem; } }
  .has-padding-left-big {
    padding-left:  2rem; }
    @media (min-width: 0px) {
      .has-padding-left-big-xs {
        padding-left:  2rem; } }
    @media (min-width: 480px) {
      .has-padding-left-big-sm {
        padding-left:  2rem; } }
    @media (min-width: 720px) {
      .has-padding-left-big-md {
        padding-left:  2rem; } }
    @media (min-width: 1024px) {
      .has-padding-left-big-nav {
        padding-left:  2rem; } }
    @media (min-width: 1024px) {
      .has-padding-left-big-lg {
        padding-left:  2rem; } }
    @media (min-width: 1280px) {
      .has-padding-left-big-xl {
        padding-left:  2rem; } }
    @media (min-width: 1920px) {
      .has-padding-left-big-xxl {
        padding-left:  2rem; } }
  .has-padding-left-0 {
    padding-left: 0; }
    @media (min-width: 0px) {
      .has-padding-left-0-xs {
        padding-left: 0; } }
    @media (min-width: 480px) {
      .has-padding-left-0-sm {
        padding-left: 0; } }
    @media (min-width: 720px) {
      .has-padding-left-0-md {
        padding-left: 0; } }
    @media (min-width: 1024px) {
      .has-padding-left-0-nav {
        padding-left: 0; } }
    @media (min-width: 1024px) {
      .has-padding-left-0-lg {
        padding-left: 0; } }
    @media (min-width: 1280px) {
      .has-padding-left-0-xl {
        padding-left: 0; } }
    @media (min-width: 1920px) {
      .has-padding-left-0-xxl {
        padding-left: 0; } }
  .has-padding-left-1 {
    padding-left: 0.25rem; }
    @media (min-width: 0px) {
      .has-padding-left-1-xs {
        padding-left: 0.25rem; } }
    @media (min-width: 480px) {
      .has-padding-left-1-sm {
        padding-left: 0.25rem; } }
    @media (min-width: 720px) {
      .has-padding-left-1-md {
        padding-left: 0.25rem; } }
    @media (min-width: 1024px) {
      .has-padding-left-1-nav {
        padding-left: 0.25rem; } }
    @media (min-width: 1024px) {
      .has-padding-left-1-lg {
        padding-left: 0.25rem; } }
    @media (min-width: 1280px) {
      .has-padding-left-1-xl {
        padding-left: 0.25rem; } }
    @media (min-width: 1920px) {
      .has-padding-left-1-xxl {
        padding-left: 0.25rem; } }
  .has-padding-left-2 {
    padding-left: 0.5rem; }
    @media (min-width: 0px) {
      .has-padding-left-2-xs {
        padding-left: 0.5rem; } }
    @media (min-width: 480px) {
      .has-padding-left-2-sm {
        padding-left: 0.5rem; } }
    @media (min-width: 720px) {
      .has-padding-left-2-md {
        padding-left: 0.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-left-2-nav {
        padding-left: 0.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-left-2-lg {
        padding-left: 0.5rem; } }
    @media (min-width: 1280px) {
      .has-padding-left-2-xl {
        padding-left: 0.5rem; } }
    @media (min-width: 1920px) {
      .has-padding-left-2-xxl {
        padding-left: 0.5rem; } }
  .has-padding-left-3 {
    padding-left: 1rem; }
    @media (min-width: 0px) {
      .has-padding-left-3-xs {
        padding-left: 1rem; } }
    @media (min-width: 480px) {
      .has-padding-left-3-sm {
        padding-left: 1rem; } }
    @media (min-width: 720px) {
      .has-padding-left-3-md {
        padding-left: 1rem; } }
    @media (min-width: 1024px) {
      .has-padding-left-3-nav {
        padding-left: 1rem; } }
    @media (min-width: 1024px) {
      .has-padding-left-3-lg {
        padding-left: 1rem; } }
    @media (min-width: 1280px) {
      .has-padding-left-3-xl {
        padding-left: 1rem; } }
    @media (min-width: 1920px) {
      .has-padding-left-3-xxl {
        padding-left: 1rem; } }
  .has-padding-left-4 {
    padding-left: 1.5rem; }
    @media (min-width: 0px) {
      .has-padding-left-4-xs {
        padding-left: 1.5rem; } }
    @media (min-width: 480px) {
      .has-padding-left-4-sm {
        padding-left: 1.5rem; } }
    @media (min-width: 720px) {
      .has-padding-left-4-md {
        padding-left: 1.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-left-4-nav {
        padding-left: 1.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-left-4-lg {
        padding-left: 1.5rem; } }
    @media (min-width: 1280px) {
      .has-padding-left-4-xl {
        padding-left: 1.5rem; } }
    @media (min-width: 1920px) {
      .has-padding-left-4-xxl {
        padding-left: 1.5rem; } }
  .has-padding-left-5 {
    padding-left: 2rem; }
    @media (min-width: 0px) {
      .has-padding-left-5-xs {
        padding-left: 2rem; } }
    @media (min-width: 480px) {
      .has-padding-left-5-sm {
        padding-left: 2rem; } }
    @media (min-width: 720px) {
      .has-padding-left-5-md {
        padding-left: 2rem; } }
    @media (min-width: 1024px) {
      .has-padding-left-5-nav {
        padding-left: 2rem; } }
    @media (min-width: 1024px) {
      .has-padding-left-5-lg {
        padding-left: 2rem; } }
    @media (min-width: 1280px) {
      .has-padding-left-5-xl {
        padding-left: 2rem; } }
    @media (min-width: 1920px) {
      .has-padding-left-5-xxl {
        padding-left: 2rem; } }
  .has-padding-left-6 {
    padding-left: 3rem; }
    @media (min-width: 0px) {
      .has-padding-left-6-xs {
        padding-left: 3rem; } }
    @media (min-width: 480px) {
      .has-padding-left-6-sm {
        padding-left: 3rem; } }
    @media (min-width: 720px) {
      .has-padding-left-6-md {
        padding-left: 3rem; } }
    @media (min-width: 1024px) {
      .has-padding-left-6-nav {
        padding-left: 3rem; } }
    @media (min-width: 1024px) {
      .has-padding-left-6-lg {
        padding-left: 3rem; } }
    @media (min-width: 1280px) {
      .has-padding-left-6-xl {
        padding-left: 3rem; } }
    @media (min-width: 1920px) {
      .has-padding-left-6-xxl {
        padding-left: 3rem; } }
  .has-padding-left-7 {
    padding-left: 4rem; }
    @media (min-width: 0px) {
      .has-padding-left-7-xs {
        padding-left: 4rem; } }
    @media (min-width: 480px) {
      .has-padding-left-7-sm {
        padding-left: 4rem; } }
    @media (min-width: 720px) {
      .has-padding-left-7-md {
        padding-left: 4rem; } }
    @media (min-width: 1024px) {
      .has-padding-left-7-nav {
        padding-left: 4rem; } }
    @media (min-width: 1024px) {
      .has-padding-left-7-lg {
        padding-left: 4rem; } }
    @media (min-width: 1280px) {
      .has-padding-left-7-xl {
        padding-left: 4rem; } }
    @media (min-width: 1920px) {
      .has-padding-left-7-xxl {
        padding-left: 4rem; } }
  .has-padding-left-8 {
    padding-left: 5rem; }
    @media (min-width: 0px) {
      .has-padding-left-8-xs {
        padding-left: 5rem; } }
    @media (min-width: 480px) {
      .has-padding-left-8-sm {
        padding-left: 5rem; } }
    @media (min-width: 720px) {
      .has-padding-left-8-md {
        padding-left: 5rem; } }
    @media (min-width: 1024px) {
      .has-padding-left-8-nav {
        padding-left: 5rem; } }
    @media (min-width: 1024px) {
      .has-padding-left-8-lg {
        padding-left: 5rem; } }
    @media (min-width: 1280px) {
      .has-padding-left-8-xl {
        padding-left: 5rem; } }
    @media (min-width: 1920px) {
      .has-padding-left-8-xxl {
        padding-left: 5rem; } }

.has-padding-right {
  padding-right:  1rem; }
  @media (min-width: 0px) {
    .has-padding-right-xs {
      padding-right:  1rem; } }
  @media (min-width: 480px) {
    .has-padding-right-sm {
      padding-right:  1rem; } }
  @media (min-width: 720px) {
    .has-padding-right-md {
      padding-right:  1rem; } }
  @media (min-width: 1024px) {
    .has-padding-right-nav {
      padding-right:  1rem; } }
  @media (min-width: 1024px) {
    .has-padding-right-lg {
      padding-right:  1rem; } }
  @media (min-width: 1280px) {
    .has-padding-right-xl {
      padding-right:  1rem; } }
  @media (min-width: 1920px) {
    .has-padding-right-xxl {
      padding-right:  1rem; } }
  .has-padding-right-smallest {
    padding-right:  0.25rem; }
    @media (min-width: 0px) {
      .has-padding-right-smallest-xs {
        padding-right:  0.25rem; } }
    @media (min-width: 480px) {
      .has-padding-right-smallest-sm {
        padding-right:  0.25rem; } }
    @media (min-width: 720px) {
      .has-padding-right-smallest-md {
        padding-right:  0.25rem; } }
    @media (min-width: 1024px) {
      .has-padding-right-smallest-nav {
        padding-right:  0.25rem; } }
    @media (min-width: 1024px) {
      .has-padding-right-smallest-lg {
        padding-right:  0.25rem; } }
    @media (min-width: 1280px) {
      .has-padding-right-smallest-xl {
        padding-right:  0.25rem; } }
    @media (min-width: 1920px) {
      .has-padding-right-smallest-xxl {
        padding-right:  0.25rem; } }
  .has-padding-right-small {
    padding-right:  0.5rem; }
    @media (min-width: 0px) {
      .has-padding-right-small-xs {
        padding-right:  0.5rem; } }
    @media (min-width: 480px) {
      .has-padding-right-small-sm {
        padding-right:  0.5rem; } }
    @media (min-width: 720px) {
      .has-padding-right-small-md {
        padding-right:  0.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-right-small-nav {
        padding-right:  0.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-right-small-lg {
        padding-right:  0.5rem; } }
    @media (min-width: 1280px) {
      .has-padding-right-small-xl {
        padding-right:  0.5rem; } }
    @media (min-width: 1920px) {
      .has-padding-right-small-xxl {
        padding-right:  0.5rem; } }
  .has-padding-right-big {
    padding-right:  2rem; }
    @media (min-width: 0px) {
      .has-padding-right-big-xs {
        padding-right:  2rem; } }
    @media (min-width: 480px) {
      .has-padding-right-big-sm {
        padding-right:  2rem; } }
    @media (min-width: 720px) {
      .has-padding-right-big-md {
        padding-right:  2rem; } }
    @media (min-width: 1024px) {
      .has-padding-right-big-nav {
        padding-right:  2rem; } }
    @media (min-width: 1024px) {
      .has-padding-right-big-lg {
        padding-right:  2rem; } }
    @media (min-width: 1280px) {
      .has-padding-right-big-xl {
        padding-right:  2rem; } }
    @media (min-width: 1920px) {
      .has-padding-right-big-xxl {
        padding-right:  2rem; } }
  .has-padding-right-0 {
    padding-right: 0; }
    @media (min-width: 0px) {
      .has-padding-right-0-xs {
        padding-right: 0; } }
    @media (min-width: 480px) {
      .has-padding-right-0-sm {
        padding-right: 0; } }
    @media (min-width: 720px) {
      .has-padding-right-0-md {
        padding-right: 0; } }
    @media (min-width: 1024px) {
      .has-padding-right-0-nav {
        padding-right: 0; } }
    @media (min-width: 1024px) {
      .has-padding-right-0-lg {
        padding-right: 0; } }
    @media (min-width: 1280px) {
      .has-padding-right-0-xl {
        padding-right: 0; } }
    @media (min-width: 1920px) {
      .has-padding-right-0-xxl {
        padding-right: 0; } }
  .has-padding-right-1 {
    padding-right: 0.25rem; }
    @media (min-width: 0px) {
      .has-padding-right-1-xs {
        padding-right: 0.25rem; } }
    @media (min-width: 480px) {
      .has-padding-right-1-sm {
        padding-right: 0.25rem; } }
    @media (min-width: 720px) {
      .has-padding-right-1-md {
        padding-right: 0.25rem; } }
    @media (min-width: 1024px) {
      .has-padding-right-1-nav {
        padding-right: 0.25rem; } }
    @media (min-width: 1024px) {
      .has-padding-right-1-lg {
        padding-right: 0.25rem; } }
    @media (min-width: 1280px) {
      .has-padding-right-1-xl {
        padding-right: 0.25rem; } }
    @media (min-width: 1920px) {
      .has-padding-right-1-xxl {
        padding-right: 0.25rem; } }
  .has-padding-right-2 {
    padding-right: 0.5rem; }
    @media (min-width: 0px) {
      .has-padding-right-2-xs {
        padding-right: 0.5rem; } }
    @media (min-width: 480px) {
      .has-padding-right-2-sm {
        padding-right: 0.5rem; } }
    @media (min-width: 720px) {
      .has-padding-right-2-md {
        padding-right: 0.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-right-2-nav {
        padding-right: 0.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-right-2-lg {
        padding-right: 0.5rem; } }
    @media (min-width: 1280px) {
      .has-padding-right-2-xl {
        padding-right: 0.5rem; } }
    @media (min-width: 1920px) {
      .has-padding-right-2-xxl {
        padding-right: 0.5rem; } }
  .has-padding-right-3 {
    padding-right: 1rem; }
    @media (min-width: 0px) {
      .has-padding-right-3-xs {
        padding-right: 1rem; } }
    @media (min-width: 480px) {
      .has-padding-right-3-sm {
        padding-right: 1rem; } }
    @media (min-width: 720px) {
      .has-padding-right-3-md {
        padding-right: 1rem; } }
    @media (min-width: 1024px) {
      .has-padding-right-3-nav {
        padding-right: 1rem; } }
    @media (min-width: 1024px) {
      .has-padding-right-3-lg {
        padding-right: 1rem; } }
    @media (min-width: 1280px) {
      .has-padding-right-3-xl {
        padding-right: 1rem; } }
    @media (min-width: 1920px) {
      .has-padding-right-3-xxl {
        padding-right: 1rem; } }
  .has-padding-right-4 {
    padding-right: 1.5rem; }
    @media (min-width: 0px) {
      .has-padding-right-4-xs {
        padding-right: 1.5rem; } }
    @media (min-width: 480px) {
      .has-padding-right-4-sm {
        padding-right: 1.5rem; } }
    @media (min-width: 720px) {
      .has-padding-right-4-md {
        padding-right: 1.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-right-4-nav {
        padding-right: 1.5rem; } }
    @media (min-width: 1024px) {
      .has-padding-right-4-lg {
        padding-right: 1.5rem; } }
    @media (min-width: 1280px) {
      .has-padding-right-4-xl {
        padding-right: 1.5rem; } }
    @media (min-width: 1920px) {
      .has-padding-right-4-xxl {
        padding-right: 1.5rem; } }
  .has-padding-right-5 {
    padding-right: 2rem; }
    @media (min-width: 0px) {
      .has-padding-right-5-xs {
        padding-right: 2rem; } }
    @media (min-width: 480px) {
      .has-padding-right-5-sm {
        padding-right: 2rem; } }
    @media (min-width: 720px) {
      .has-padding-right-5-md {
        padding-right: 2rem; } }
    @media (min-width: 1024px) {
      .has-padding-right-5-nav {
        padding-right: 2rem; } }
    @media (min-width: 1024px) {
      .has-padding-right-5-lg {
        padding-right: 2rem; } }
    @media (min-width: 1280px) {
      .has-padding-right-5-xl {
        padding-right: 2rem; } }
    @media (min-width: 1920px) {
      .has-padding-right-5-xxl {
        padding-right: 2rem; } }
  .has-padding-right-6 {
    padding-right: 3rem; }
    @media (min-width: 0px) {
      .has-padding-right-6-xs {
        padding-right: 3rem; } }
    @media (min-width: 480px) {
      .has-padding-right-6-sm {
        padding-right: 3rem; } }
    @media (min-width: 720px) {
      .has-padding-right-6-md {
        padding-right: 3rem; } }
    @media (min-width: 1024px) {
      .has-padding-right-6-nav {
        padding-right: 3rem; } }
    @media (min-width: 1024px) {
      .has-padding-right-6-lg {
        padding-right: 3rem; } }
    @media (min-width: 1280px) {
      .has-padding-right-6-xl {
        padding-right: 3rem; } }
    @media (min-width: 1920px) {
      .has-padding-right-6-xxl {
        padding-right: 3rem; } }
  .has-padding-right-7 {
    padding-right: 4rem; }
    @media (min-width: 0px) {
      .has-padding-right-7-xs {
        padding-right: 4rem; } }
    @media (min-width: 480px) {
      .has-padding-right-7-sm {
        padding-right: 4rem; } }
    @media (min-width: 720px) {
      .has-padding-right-7-md {
        padding-right: 4rem; } }
    @media (min-width: 1024px) {
      .has-padding-right-7-nav {
        padding-right: 4rem; } }
    @media (min-width: 1024px) {
      .has-padding-right-7-lg {
        padding-right: 4rem; } }
    @media (min-width: 1280px) {
      .has-padding-right-7-xl {
        padding-right: 4rem; } }
    @media (min-width: 1920px) {
      .has-padding-right-7-xxl {
        padding-right: 4rem; } }
  .has-padding-right-8 {
    padding-right: 5rem; }
    @media (min-width: 0px) {
      .has-padding-right-8-xs {
        padding-right: 5rem; } }
    @media (min-width: 480px) {
      .has-padding-right-8-sm {
        padding-right: 5rem; } }
    @media (min-width: 720px) {
      .has-padding-right-8-md {
        padding-right: 5rem; } }
    @media (min-width: 1024px) {
      .has-padding-right-8-nav {
        padding-right: 5rem; } }
    @media (min-width: 1024px) {
      .has-padding-right-8-lg {
        padding-right: 5rem; } }
    @media (min-width: 1280px) {
      .has-padding-right-8-xl {
        padding-right: 5rem; } }
    @media (min-width: 1920px) {
      .has-padding-right-8-xxl {
        padding-right: 5rem; } }

.no-padding {
  padding: 0 !important; }
  @media (min-width: 0px) {
    .no-padding-xs {
      padding: 0 !important; } }
  @media (min-width: 480px) {
    .no-padding-sm {
      padding: 0 !important; } }
  @media (min-width: 720px) {
    .no-padding-md {
      padding: 0 !important; } }
  @media (min-width: 1024px) {
    .no-padding-nav {
      padding: 0 !important; } }
  @media (min-width: 1024px) {
    .no-padding-lg {
      padding: 0 !important; } }
  @media (min-width: 1280px) {
    .no-padding-xl {
      padding: 0 !important; } }
  @media (min-width: 1920px) {
    .no-padding-xxl {
      padding: 0 !important; } }

.no-padding-middle {
  padding-bottom: 0 !important;
  padding-top: 0 !important; }
  @media (min-width: 0px) {
    .no-padding-middle-xs {
      padding-bottom: 0 !important;
      padding-top: 0 !important; } }
  @media (min-width: 480px) {
    .no-padding-middle-sm {
      padding-bottom: 0 !important;
      padding-top: 0 !important; } }
  @media (min-width: 720px) {
    .no-padding-middle-md {
      padding-bottom: 0 !important;
      padding-top: 0 !important; } }
  @media (min-width: 1024px) {
    .no-padding-middle-nav {
      padding-bottom: 0 !important;
      padding-top: 0 !important; } }
  @media (min-width: 1024px) {
    .no-padding-middle-lg {
      padding-bottom: 0 !important;
      padding-top: 0 !important; } }
  @media (min-width: 1280px) {
    .no-padding-middle-xl {
      padding-bottom: 0 !important;
      padding-top: 0 !important; } }
  @media (min-width: 1920px) {
    .no-padding-middle-xxl {
      padding-bottom: 0 !important;
      padding-top: 0 !important; } }

.no-padding-top {
  padding-top: 0 !important; }
  @media (min-width: 0px) {
    .no-padding-top-xs {
      padding-top: 0 !important; } }
  @media (min-width: 480px) {
    .no-padding-top-sm {
      padding-top: 0 !important; } }
  @media (min-width: 720px) {
    .no-padding-top-md {
      padding-top: 0 !important; } }
  @media (min-width: 1024px) {
    .no-padding-top-nav {
      padding-top: 0 !important; } }
  @media (min-width: 1024px) {
    .no-padding-top-lg {
      padding-top: 0 !important; } }
  @media (min-width: 1280px) {
    .no-padding-top-xl {
      padding-top: 0 !important; } }
  @media (min-width: 1920px) {
    .no-padding-top-xxl {
      padding-top: 0 !important; } }

.no-padding-bottom {
  padding-bottom: 0 !important; }
  @media (min-width: 0px) {
    .no-padding-bottom-xs {
      padding-bottom: 0 !important; } }
  @media (min-width: 480px) {
    .no-padding-bottom-sm {
      padding-bottom: 0 !important; } }
  @media (min-width: 720px) {
    .no-padding-bottom-md {
      padding-bottom: 0 !important; } }
  @media (min-width: 1024px) {
    .no-padding-bottom-nav {
      padding-bottom: 0 !important; } }
  @media (min-width: 1024px) {
    .no-padding-bottom-lg {
      padding-bottom: 0 !important; } }
  @media (min-width: 1280px) {
    .no-padding-bottom-xl {
      padding-bottom: 0 !important; } }
  @media (min-width: 1920px) {
    .no-padding-bottom-xxl {
      padding-bottom: 0 !important; } }

.no-padding-center {
  padding-left: 0 !important;
  padding-right: 0 !important; }
  @media (min-width: 0px) {
    .no-padding-center-xs {
      padding-left: 0 !important;
      padding-right: 0 !important; } }
  @media (min-width: 480px) {
    .no-padding-center-sm {
      padding-left: 0 !important;
      padding-right: 0 !important; } }
  @media (min-width: 720px) {
    .no-padding-center-md {
      padding-left: 0 !important;
      padding-right: 0 !important; } }
  @media (min-width: 1024px) {
    .no-padding-center-nav {
      padding-left: 0 !important;
      padding-right: 0 !important; } }
  @media (min-width: 1024px) {
    .no-padding-center-lg {
      padding-left: 0 !important;
      padding-right: 0 !important; } }
  @media (min-width: 1280px) {
    .no-padding-center-xl {
      padding-left: 0 !important;
      padding-right: 0 !important; } }
  @media (min-width: 1920px) {
    .no-padding-center-xxl {
      padding-left: 0 !important;
      padding-right: 0 !important; } }

.no-padding-left {
  padding-left: 0 !important; }
  @media (min-width: 0px) {
    .no-padding-left-xs {
      padding-left: 0 !important; } }
  @media (min-width: 480px) {
    .no-padding-left-sm {
      padding-left: 0 !important; } }
  @media (min-width: 720px) {
    .no-padding-left-md {
      padding-left: 0 !important; } }
  @media (min-width: 1024px) {
    .no-padding-left-nav {
      padding-left: 0 !important; } }
  @media (min-width: 1024px) {
    .no-padding-left-lg {
      padding-left: 0 !important; } }
  @media (min-width: 1280px) {
    .no-padding-left-xl {
      padding-left: 0 !important; } }
  @media (min-width: 1920px) {
    .no-padding-left-xxl {
      padding-left: 0 !important; } }

.no-padding-right {
  padding-right: 0 !important; }
  @media (min-width: 0px) {
    .no-padding-right-xs {
      padding-right: 0 !important; } }
  @media (min-width: 480px) {
    .no-padding-right-sm {
      padding-right: 0 !important; } }
  @media (min-width: 720px) {
    .no-padding-right-md {
      padding-right: 0 !important; } }
  @media (min-width: 1024px) {
    .no-padding-right-nav {
      padding-right: 0 !important; } }
  @media (min-width: 1024px) {
    .no-padding-right-lg {
      padding-right: 0 !important; } }
  @media (min-width: 1280px) {
    .no-padding-right-xl {
      padding-right: 0 !important; } }
  @media (min-width: 1920px) {
    .no-padding-right-xxl {
      padding-right: 0 !important; } }

.no-margin {
  margin: 0 !important; }
  @media (min-width: 0px) {
    .no-margin-xs {
      margin: 0 !important; } }
  @media (min-width: 480px) {
    .no-margin-sm {
      margin: 0 !important; } }
  @media (min-width: 720px) {
    .no-margin-md {
      margin: 0 !important; } }
  @media (min-width: 1024px) {
    .no-margin-nav {
      margin: 0 !important; } }
  @media (min-width: 1024px) {
    .no-margin-lg {
      margin: 0 !important; } }
  @media (min-width: 1280px) {
    .no-margin-xl {
      margin: 0 !important; } }
  @media (min-width: 1920px) {
    .no-margin-xxl {
      margin: 0 !important; } }

.no-margin-middle {
  margin-bottom: 0 !important;
  margin-top: 0 !important; }
  @media (min-width: 0px) {
    .no-margin-middle-xs {
      margin-bottom: 0 !important;
      margin-top: 0 !important; } }
  @media (min-width: 480px) {
    .no-margin-middle-sm {
      margin-bottom: 0 !important;
      margin-top: 0 !important; } }
  @media (min-width: 720px) {
    .no-margin-middle-md {
      margin-bottom: 0 !important;
      margin-top: 0 !important; } }
  @media (min-width: 1024px) {
    .no-margin-middle-nav {
      margin-bottom: 0 !important;
      margin-top: 0 !important; } }
  @media (min-width: 1024px) {
    .no-margin-middle-lg {
      margin-bottom: 0 !important;
      margin-top: 0 !important; } }
  @media (min-width: 1280px) {
    .no-margin-middle-xl {
      margin-bottom: 0 !important;
      margin-top: 0 !important; } }
  @media (min-width: 1920px) {
    .no-margin-middle-xxl {
      margin-bottom: 0 !important;
      margin-top: 0 !important; } }

.no-margin-top {
  margin-top: 0 !important; }
  @media (min-width: 0px) {
    .no-margin-top-xs {
      margin-top: 0 !important; } }
  @media (min-width: 480px) {
    .no-margin-top-sm {
      margin-top: 0 !important; } }
  @media (min-width: 720px) {
    .no-margin-top-md {
      margin-top: 0 !important; } }
  @media (min-width: 1024px) {
    .no-margin-top-nav {
      margin-top: 0 !important; } }
  @media (min-width: 1024px) {
    .no-margin-top-lg {
      margin-top: 0 !important; } }
  @media (min-width: 1280px) {
    .no-margin-top-xl {
      margin-top: 0 !important; } }
  @media (min-width: 1920px) {
    .no-margin-top-xxl {
      margin-top: 0 !important; } }

.no-margin-bottom {
  margin-bottom: 0 !important; }
  @media (min-width: 0px) {
    .no-margin-bottom-xs {
      margin-bottom: 0 !important; } }
  @media (min-width: 480px) {
    .no-margin-bottom-sm {
      margin-bottom: 0 !important; } }
  @media (min-width: 720px) {
    .no-margin-bottom-md {
      margin-bottom: 0 !important; } }
  @media (min-width: 1024px) {
    .no-margin-bottom-nav {
      margin-bottom: 0 !important; } }
  @media (min-width: 1024px) {
    .no-margin-bottom-lg {
      margin-bottom: 0 !important; } }
  @media (min-width: 1280px) {
    .no-margin-bottom-xl {
      margin-bottom: 0 !important; } }
  @media (min-width: 1920px) {
    .no-margin-bottom-xxl {
      margin-bottom: 0 !important; } }

.no-margin-center {
  margin-left: 0 !important;
  margin-right: 0 !important; }
  @media (min-width: 0px) {
    .no-margin-center-xs {
      margin-left: 0 !important;
      margin-right: 0 !important; } }
  @media (min-width: 480px) {
    .no-margin-center-sm {
      margin-left: 0 !important;
      margin-right: 0 !important; } }
  @media (min-width: 720px) {
    .no-margin-center-md {
      margin-left: 0 !important;
      margin-right: 0 !important; } }
  @media (min-width: 1024px) {
    .no-margin-center-nav {
      margin-left: 0 !important;
      margin-right: 0 !important; } }
  @media (min-width: 1024px) {
    .no-margin-center-lg {
      margin-left: 0 !important;
      margin-right: 0 !important; } }
  @media (min-width: 1280px) {
    .no-margin-center-xl {
      margin-left: 0 !important;
      margin-right: 0 !important; } }
  @media (min-width: 1920px) {
    .no-margin-center-xxl {
      margin-left: 0 !important;
      margin-right: 0 !important; } }

.no-margin-left {
  margin-left: 0 !important; }
  @media (min-width: 0px) {
    .no-margin-left-xs {
      margin-left: 0 !important; } }
  @media (min-width: 480px) {
    .no-margin-left-sm {
      margin-left: 0 !important; } }
  @media (min-width: 720px) {
    .no-margin-left-md {
      margin-left: 0 !important; } }
  @media (min-width: 1024px) {
    .no-margin-left-nav {
      margin-left: 0 !important; } }
  @media (min-width: 1024px) {
    .no-margin-left-lg {
      margin-left: 0 !important; } }
  @media (min-width: 1280px) {
    .no-margin-left-xl {
      margin-left: 0 !important; } }
  @media (min-width: 1920px) {
    .no-margin-left-xxl {
      margin-left: 0 !important; } }

.no-margin-right {
  margin-right: 0 !important; }
  @media (min-width: 0px) {
    .no-margin-right-xs {
      margin-right: 0 !important; } }
  @media (min-width: 480px) {
    .no-margin-right-sm {
      margin-right: 0 !important; } }
  @media (min-width: 720px) {
    .no-margin-right-md {
      margin-right: 0 !important; } }
  @media (min-width: 1024px) {
    .no-margin-right-nav {
      margin-right: 0 !important; } }
  @media (min-width: 1024px) {
    .no-margin-right-lg {
      margin-right: 0 !important; } }
  @media (min-width: 1280px) {
    .no-margin-right-xl {
      margin-right: 0 !important; } }
  @media (min-width: 1920px) {
    .no-margin-right-xxl {
      margin-right: 0 !important; } }

.has-height-full {
  height: 100%; }
  @media (min-width: 0px) {
    .has-height-full-xs {
      height: 100%; } }
  @media (min-width: 480px) {
    .has-height-full-sm {
      height: 100%; } }
  @media (min-width: 720px) {
    .has-height-full-md {
      height: 100%; } }
  @media (min-width: 1024px) {
    .has-height-full-nav {
      height: 100%; } }
  @media (min-width: 1024px) {
    .has-height-full-lg {
      height: 100%; } }
  @media (min-width: 1280px) {
    .has-height-full-xl {
      height: 100%; } }
  @media (min-width: 1920px) {
    .has-height-full-xxl {
      height: 100%; } }

.has-height-1-4 {
  height: 25%; }
  @media (min-width: 0px) {
    .has-height-1-4-xs {
      height: 25%; } }
  @media (min-width: 480px) {
    .has-height-1-4-sm {
      height: 25%; } }
  @media (min-width: 720px) {
    .has-height-1-4-md {
      height: 25%; } }
  @media (min-width: 1024px) {
    .has-height-1-4-nav {
      height: 25%; } }
  @media (min-width: 1024px) {
    .has-height-1-4-lg {
      height: 25%; } }
  @media (min-width: 1280px) {
    .has-height-1-4-xl {
      height: 25%; } }
  @media (min-width: 1920px) {
    .has-height-1-4-xxl {
      height: 25%; } }

.has-height-half {
  height: 50%; }
  @media (min-width: 0px) {
    .has-height-half-xs {
      height: 50%; } }
  @media (min-width: 480px) {
    .has-height-half-sm {
      height: 50%; } }
  @media (min-width: 720px) {
    .has-height-half-md {
      height: 50%; } }
  @media (min-width: 1024px) {
    .has-height-half-nav {
      height: 50%; } }
  @media (min-width: 1024px) {
    .has-height-half-lg {
      height: 50%; } }
  @media (min-width: 1280px) {
    .has-height-half-xl {
      height: 50%; } }
  @media (min-width: 1920px) {
    .has-height-half-xxl {
      height: 50%; } }

.has-height-3-4 {
  height: 75%; }
  @media (min-width: 0px) {
    .has-height-3-4-xs {
      height: 75%; } }
  @media (min-width: 480px) {
    .has-height-3-4-sm {
      height: 75%; } }
  @media (min-width: 720px) {
    .has-height-3-4-md {
      height: 75%; } }
  @media (min-width: 1024px) {
    .has-height-3-4-nav {
      height: 75%; } }
  @media (min-width: 1024px) {
    .has-height-3-4-lg {
      height: 75%; } }
  @media (min-width: 1280px) {
    .has-height-3-4-xl {
      height: 75%; } }
  @media (min-width: 1920px) {
    .has-height-3-4-xxl {
      height: 75%; } }

.has-height-16-9 {
  height: 56.25%; }
  @media (min-width: 0px) {
    .has-height-16-9-xs {
      height: 56.25%; } }
  @media (min-width: 480px) {
    .has-height-16-9-sm {
      height: 56.25%; } }
  @media (min-width: 720px) {
    .has-height-16-9-md {
      height: 56.25%; } }
  @media (min-width: 1024px) {
    .has-height-16-9-nav {
      height: 56.25%; } }
  @media (min-width: 1024px) {
    .has-height-16-9-lg {
      height: 56.25%; } }
  @media (min-width: 1280px) {
    .has-height-16-9-xl {
      height: 56.25%; } }
  @media (min-width: 1920px) {
    .has-height-16-9-xxl {
      height: 56.25%; } }

.has-height-golden {
  height: 61.803%; }
  @media (min-width: 0px) {
    .has-height-golden-xs {
      height: 61.803%; } }
  @media (min-width: 480px) {
    .has-height-golden-sm {
      height: 61.803%; } }
  @media (min-width: 720px) {
    .has-height-golden-md {
      height: 61.803%; } }
  @media (min-width: 1024px) {
    .has-height-golden-nav {
      height: 61.803%; } }
  @media (min-width: 1024px) {
    .has-height-golden-lg {
      height: 61.803%; } }
  @media (min-width: 1280px) {
    .has-height-golden-xl {
      height: 61.803%; } }
  @media (min-width: 1920px) {
    .has-height-golden-xxl {
      height: 61.803%; } }

.has-height-vh-full {
  height: 100vh; }
  @media (min-width: 0px) {
    .has-height-vh-full-xs {
      height: 100vh; } }
  @media (min-width: 480px) {
    .has-height-vh-full-sm {
      height: 100vh; } }
  @media (min-width: 720px) {
    .has-height-vh-full-md {
      height: 100vh; } }
  @media (min-width: 1024px) {
    .has-height-vh-full-nav {
      height: 100vh; } }
  @media (min-width: 1024px) {
    .has-height-vh-full-lg {
      height: 100vh; } }
  @media (min-width: 1280px) {
    .has-height-vh-full-xl {
      height: 100vh; } }
  @media (min-width: 1920px) {
    .has-height-vh-full-xxl {
      height: 100vh; } }

.has-height-vh-1-4 {
  height: 25vh; }
  @media (min-width: 0px) {
    .has-height-vh-1-4-xs {
      height: 25vh; } }
  @media (min-width: 480px) {
    .has-height-vh-1-4-sm {
      height: 25vh; } }
  @media (min-width: 720px) {
    .has-height-vh-1-4-md {
      height: 25vh; } }
  @media (min-width: 1024px) {
    .has-height-vh-1-4-nav {
      height: 25vh; } }
  @media (min-width: 1024px) {
    .has-height-vh-1-4-lg {
      height: 25vh; } }
  @media (min-width: 1280px) {
    .has-height-vh-1-4-xl {
      height: 25vh; } }
  @media (min-width: 1920px) {
    .has-height-vh-1-4-xxl {
      height: 25vh; } }

.has-height-vh-half {
  height: 50vh; }
  @media (min-width: 0px) {
    .has-height-vh-half-xs {
      height: 50vh; } }
  @media (min-width: 480px) {
    .has-height-vh-half-sm {
      height: 50vh; } }
  @media (min-width: 720px) {
    .has-height-vh-half-md {
      height: 50vh; } }
  @media (min-width: 1024px) {
    .has-height-vh-half-nav {
      height: 50vh; } }
  @media (min-width: 1024px) {
    .has-height-vh-half-lg {
      height: 50vh; } }
  @media (min-width: 1280px) {
    .has-height-vh-half-xl {
      height: 50vh; } }
  @media (min-width: 1920px) {
    .has-height-vh-half-xxl {
      height: 50vh; } }

.has-height-vh-3-4 {
  height: 75vh; }
  @media (min-width: 0px) {
    .has-height-vh-3-4-xs {
      height: 75vh; } }
  @media (min-width: 480px) {
    .has-height-vh-3-4-sm {
      height: 75vh; } }
  @media (min-width: 720px) {
    .has-height-vh-3-4-md {
      height: 75vh; } }
  @media (min-width: 1024px) {
    .has-height-vh-3-4-nav {
      height: 75vh; } }
  @media (min-width: 1024px) {
    .has-height-vh-3-4-lg {
      height: 75vh; } }
  @media (min-width: 1280px) {
    .has-height-vh-3-4-xl {
      height: 75vh; } }
  @media (min-width: 1920px) {
    .has-height-vh-3-4-xxl {
      height: 75vh; } }

.has-height-vh-16-9 {
  height: 56.25vh; }
  @media (min-width: 0px) {
    .has-height-vh-16-9-xs {
      height: 56.25vh; } }
  @media (min-width: 480px) {
    .has-height-vh-16-9-sm {
      height: 56.25vh; } }
  @media (min-width: 720px) {
    .has-height-vh-16-9-md {
      height: 56.25vh; } }
  @media (min-width: 1024px) {
    .has-height-vh-16-9-nav {
      height: 56.25vh; } }
  @media (min-width: 1024px) {
    .has-height-vh-16-9-lg {
      height: 56.25vh; } }
  @media (min-width: 1280px) {
    .has-height-vh-16-9-xl {
      height: 56.25vh; } }
  @media (min-width: 1920px) {
    .has-height-vh-16-9-xxl {
      height: 56.25vh; } }

.has-height-vh-golden {
  height: 61.803vh; }
  @media (min-width: 0px) {
    .has-height-vh-golden-xs {
      height: 61.803vh; } }
  @media (min-width: 480px) {
    .has-height-vh-golden-sm {
      height: 61.803vh; } }
  @media (min-width: 720px) {
    .has-height-vh-golden-md {
      height: 61.803vh; } }
  @media (min-width: 1024px) {
    .has-height-vh-golden-nav {
      height: 61.803vh; } }
  @media (min-width: 1024px) {
    .has-height-vh-golden-lg {
      height: 61.803vh; } }
  @media (min-width: 1280px) {
    .has-height-vh-golden-xl {
      height: 61.803vh; } }
  @media (min-width: 1920px) {
    .has-height-vh-golden-xxl {
      height: 61.803vh; } }

.has-height-vw-full {
  height: 100vw; }
  @media (min-width: 0px) {
    .has-height-vw-full-xs {
      height: 100vw; } }
  @media (min-width: 480px) {
    .has-height-vw-full-sm {
      height: 100vw; } }
  @media (min-width: 720px) {
    .has-height-vw-full-md {
      height: 100vw; } }
  @media (min-width: 1024px) {
    .has-height-vw-full-nav {
      height: 100vw; } }
  @media (min-width: 1024px) {
    .has-height-vw-full-lg {
      height: 100vw; } }
  @media (min-width: 1280px) {
    .has-height-vw-full-xl {
      height: 100vw; } }
  @media (min-width: 1920px) {
    .has-height-vw-full-xxl {
      height: 100vw; } }

.has-height-vw-1-4 {
  height: 25vw; }
  @media (min-width: 0px) {
    .has-height-vw-1-4-xs {
      height: 25vw; } }
  @media (min-width: 480px) {
    .has-height-vw-1-4-sm {
      height: 25vw; } }
  @media (min-width: 720px) {
    .has-height-vw-1-4-md {
      height: 25vw; } }
  @media (min-width: 1024px) {
    .has-height-vw-1-4-nav {
      height: 25vw; } }
  @media (min-width: 1024px) {
    .has-height-vw-1-4-lg {
      height: 25vw; } }
  @media (min-width: 1280px) {
    .has-height-vw-1-4-xl {
      height: 25vw; } }
  @media (min-width: 1920px) {
    .has-height-vw-1-4-xxl {
      height: 25vw; } }

.has-height-vw-half {
  height: 50vw; }
  @media (min-width: 0px) {
    .has-height-vw-half-xs {
      height: 50vw; } }
  @media (min-width: 480px) {
    .has-height-vw-half-sm {
      height: 50vw; } }
  @media (min-width: 720px) {
    .has-height-vw-half-md {
      height: 50vw; } }
  @media (min-width: 1024px) {
    .has-height-vw-half-nav {
      height: 50vw; } }
  @media (min-width: 1024px) {
    .has-height-vw-half-lg {
      height: 50vw; } }
  @media (min-width: 1280px) {
    .has-height-vw-half-xl {
      height: 50vw; } }
  @media (min-width: 1920px) {
    .has-height-vw-half-xxl {
      height: 50vw; } }

.has-height-vw-3-4 {
  height: 75vw; }
  @media (min-width: 0px) {
    .has-height-vw-3-4-xs {
      height: 75vw; } }
  @media (min-width: 480px) {
    .has-height-vw-3-4-sm {
      height: 75vw; } }
  @media (min-width: 720px) {
    .has-height-vw-3-4-md {
      height: 75vw; } }
  @media (min-width: 1024px) {
    .has-height-vw-3-4-nav {
      height: 75vw; } }
  @media (min-width: 1024px) {
    .has-height-vw-3-4-lg {
      height: 75vw; } }
  @media (min-width: 1280px) {
    .has-height-vw-3-4-xl {
      height: 75vw; } }
  @media (min-width: 1920px) {
    .has-height-vw-3-4-xxl {
      height: 75vw; } }

.has-height-vw-16-9 {
  height: 56.25vw; }
  @media (min-width: 0px) {
    .has-height-vw-16-9-xs {
      height: 56.25vw; } }
  @media (min-width: 480px) {
    .has-height-vw-16-9-sm {
      height: 56.25vw; } }
  @media (min-width: 720px) {
    .has-height-vw-16-9-md {
      height: 56.25vw; } }
  @media (min-width: 1024px) {
    .has-height-vw-16-9-nav {
      height: 56.25vw; } }
  @media (min-width: 1024px) {
    .has-height-vw-16-9-lg {
      height: 56.25vw; } }
  @media (min-width: 1280px) {
    .has-height-vw-16-9-xl {
      height: 56.25vw; } }
  @media (min-width: 1920px) {
    .has-height-vw-16-9-xxl {
      height: 56.25vw; } }

.has-height-vw-golden {
  height: 61.803vw; }
  @media (min-width: 0px) {
    .has-height-vw-golden-xs {
      height: 61.803vw; } }
  @media (min-width: 480px) {
    .has-height-vw-golden-sm {
      height: 61.803vw; } }
  @media (min-width: 720px) {
    .has-height-vw-golden-md {
      height: 61.803vw; } }
  @media (min-width: 1024px) {
    .has-height-vw-golden-nav {
      height: 61.803vw; } }
  @media (min-width: 1024px) {
    .has-height-vw-golden-lg {
      height: 61.803vw; } }
  @media (min-width: 1280px) {
    .has-height-vw-golden-xl {
      height: 61.803vw; } }
  @media (min-width: 1920px) {
    .has-height-vw-golden-xxl {
      height: 61.803vw; } }

.has-min-height-full {
  min-height: 100%; }
  @media (min-width: 0px) {
    .has-min-height-full-xs {
      min-height: 100%; } }
  @media (min-width: 480px) {
    .has-min-height-full-sm {
      min-height: 100%; } }
  @media (min-width: 720px) {
    .has-min-height-full-md {
      min-height: 100%; } }
  @media (min-width: 1024px) {
    .has-min-height-full-nav {
      min-height: 100%; } }
  @media (min-width: 1024px) {
    .has-min-height-full-lg {
      min-height: 100%; } }
  @media (min-width: 1280px) {
    .has-min-height-full-xl {
      min-height: 100%; } }
  @media (min-width: 1920px) {
    .has-min-height-full-xxl {
      min-height: 100%; } }

.has-min-height-1-4 {
  min-height: 25%; }
  @media (min-width: 0px) {
    .has-min-height-1-4-xs {
      min-height: 25%; } }
  @media (min-width: 480px) {
    .has-min-height-1-4-sm {
      min-height: 25%; } }
  @media (min-width: 720px) {
    .has-min-height-1-4-md {
      min-height: 25%; } }
  @media (min-width: 1024px) {
    .has-min-height-1-4-nav {
      min-height: 25%; } }
  @media (min-width: 1024px) {
    .has-min-height-1-4-lg {
      min-height: 25%; } }
  @media (min-width: 1280px) {
    .has-min-height-1-4-xl {
      min-height: 25%; } }
  @media (min-width: 1920px) {
    .has-min-height-1-4-xxl {
      min-height: 25%; } }

.has-min-height-half {
  min-height: 50%; }
  @media (min-width: 0px) {
    .has-min-height-half-xs {
      min-height: 50%; } }
  @media (min-width: 480px) {
    .has-min-height-half-sm {
      min-height: 50%; } }
  @media (min-width: 720px) {
    .has-min-height-half-md {
      min-height: 50%; } }
  @media (min-width: 1024px) {
    .has-min-height-half-nav {
      min-height: 50%; } }
  @media (min-width: 1024px) {
    .has-min-height-half-lg {
      min-height: 50%; } }
  @media (min-width: 1280px) {
    .has-min-height-half-xl {
      min-height: 50%; } }
  @media (min-width: 1920px) {
    .has-min-height-half-xxl {
      min-height: 50%; } }

.has-min-height-3-4 {
  min-height: 75%; }
  @media (min-width: 0px) {
    .has-min-height-3-4-xs {
      min-height: 75%; } }
  @media (min-width: 480px) {
    .has-min-height-3-4-sm {
      min-height: 75%; } }
  @media (min-width: 720px) {
    .has-min-height-3-4-md {
      min-height: 75%; } }
  @media (min-width: 1024px) {
    .has-min-height-3-4-nav {
      min-height: 75%; } }
  @media (min-width: 1024px) {
    .has-min-height-3-4-lg {
      min-height: 75%; } }
  @media (min-width: 1280px) {
    .has-min-height-3-4-xl {
      min-height: 75%; } }
  @media (min-width: 1920px) {
    .has-min-height-3-4-xxl {
      min-height: 75%; } }

.has-min-height-16-9 {
  min-height: 56.25%; }
  @media (min-width: 0px) {
    .has-min-height-16-9-xs {
      min-height: 56.25%; } }
  @media (min-width: 480px) {
    .has-min-height-16-9-sm {
      min-height: 56.25%; } }
  @media (min-width: 720px) {
    .has-min-height-16-9-md {
      min-height: 56.25%; } }
  @media (min-width: 1024px) {
    .has-min-height-16-9-nav {
      min-height: 56.25%; } }
  @media (min-width: 1024px) {
    .has-min-height-16-9-lg {
      min-height: 56.25%; } }
  @media (min-width: 1280px) {
    .has-min-height-16-9-xl {
      min-height: 56.25%; } }
  @media (min-width: 1920px) {
    .has-min-height-16-9-xxl {
      min-height: 56.25%; } }

.has-min-height-golden {
  min-height: 61.803%; }
  @media (min-width: 0px) {
    .has-min-height-golden-xs {
      min-height: 61.803%; } }
  @media (min-width: 480px) {
    .has-min-height-golden-sm {
      min-height: 61.803%; } }
  @media (min-width: 720px) {
    .has-min-height-golden-md {
      min-height: 61.803%; } }
  @media (min-width: 1024px) {
    .has-min-height-golden-nav {
      min-height: 61.803%; } }
  @media (min-width: 1024px) {
    .has-min-height-golden-lg {
      min-height: 61.803%; } }
  @media (min-width: 1280px) {
    .has-min-height-golden-xl {
      min-height: 61.803%; } }
  @media (min-width: 1920px) {
    .has-min-height-golden-xxl {
      min-height: 61.803%; } }

.has-min-height-vh-full {
  min-height: 100vh; }
  @media (min-width: 0px) {
    .has-min-height-vh-full-xs {
      min-height: 100vh; } }
  @media (min-width: 480px) {
    .has-min-height-vh-full-sm {
      min-height: 100vh; } }
  @media (min-width: 720px) {
    .has-min-height-vh-full-md {
      min-height: 100vh; } }
  @media (min-width: 1024px) {
    .has-min-height-vh-full-nav {
      min-height: 100vh; } }
  @media (min-width: 1024px) {
    .has-min-height-vh-full-lg {
      min-height: 100vh; } }
  @media (min-width: 1280px) {
    .has-min-height-vh-full-xl {
      min-height: 100vh; } }
  @media (min-width: 1920px) {
    .has-min-height-vh-full-xxl {
      min-height: 100vh; } }

.has-min-height-vh-1-4 {
  min-height: 25vh; }
  @media (min-width: 0px) {
    .has-min-height-vh-1-4-xs {
      min-height: 25vh; } }
  @media (min-width: 480px) {
    .has-min-height-vh-1-4-sm {
      min-height: 25vh; } }
  @media (min-width: 720px) {
    .has-min-height-vh-1-4-md {
      min-height: 25vh; } }
  @media (min-width: 1024px) {
    .has-min-height-vh-1-4-nav {
      min-height: 25vh; } }
  @media (min-width: 1024px) {
    .has-min-height-vh-1-4-lg {
      min-height: 25vh; } }
  @media (min-width: 1280px) {
    .has-min-height-vh-1-4-xl {
      min-height: 25vh; } }
  @media (min-width: 1920px) {
    .has-min-height-vh-1-4-xxl {
      min-height: 25vh; } }

.has-min-height-vh-half {
  min-height: 50vh; }
  @media (min-width: 0px) {
    .has-min-height-vh-half-xs {
      min-height: 50vh; } }
  @media (min-width: 480px) {
    .has-min-height-vh-half-sm {
      min-height: 50vh; } }
  @media (min-width: 720px) {
    .has-min-height-vh-half-md {
      min-height: 50vh; } }
  @media (min-width: 1024px) {
    .has-min-height-vh-half-nav {
      min-height: 50vh; } }
  @media (min-width: 1024px) {
    .has-min-height-vh-half-lg {
      min-height: 50vh; } }
  @media (min-width: 1280px) {
    .has-min-height-vh-half-xl {
      min-height: 50vh; } }
  @media (min-width: 1920px) {
    .has-min-height-vh-half-xxl {
      min-height: 50vh; } }

.has-min-height-vh-3-4 {
  min-height: 75vh; }
  @media (min-width: 0px) {
    .has-min-height-vh-3-4-xs {
      min-height: 75vh; } }
  @media (min-width: 480px) {
    .has-min-height-vh-3-4-sm {
      min-height: 75vh; } }
  @media (min-width: 720px) {
    .has-min-height-vh-3-4-md {
      min-height: 75vh; } }
  @media (min-width: 1024px) {
    .has-min-height-vh-3-4-nav {
      min-height: 75vh; } }
  @media (min-width: 1024px) {
    .has-min-height-vh-3-4-lg {
      min-height: 75vh; } }
  @media (min-width: 1280px) {
    .has-min-height-vh-3-4-xl {
      min-height: 75vh; } }
  @media (min-width: 1920px) {
    .has-min-height-vh-3-4-xxl {
      min-height: 75vh; } }

.has-min-height-vh-16-9 {
  min-height: 56.25vh; }
  @media (min-width: 0px) {
    .has-min-height-vh-16-9-xs {
      min-height: 56.25vh; } }
  @media (min-width: 480px) {
    .has-min-height-vh-16-9-sm {
      min-height: 56.25vh; } }
  @media (min-width: 720px) {
    .has-min-height-vh-16-9-md {
      min-height: 56.25vh; } }
  @media (min-width: 1024px) {
    .has-min-height-vh-16-9-nav {
      min-height: 56.25vh; } }
  @media (min-width: 1024px) {
    .has-min-height-vh-16-9-lg {
      min-height: 56.25vh; } }
  @media (min-width: 1280px) {
    .has-min-height-vh-16-9-xl {
      min-height: 56.25vh; } }
  @media (min-width: 1920px) {
    .has-min-height-vh-16-9-xxl {
      min-height: 56.25vh; } }

.has-min-height-vh-golden {
  min-height: 61.803vh; }
  @media (min-width: 0px) {
    .has-min-height-vh-golden-xs {
      min-height: 61.803vh; } }
  @media (min-width: 480px) {
    .has-min-height-vh-golden-sm {
      min-height: 61.803vh; } }
  @media (min-width: 720px) {
    .has-min-height-vh-golden-md {
      min-height: 61.803vh; } }
  @media (min-width: 1024px) {
    .has-min-height-vh-golden-nav {
      min-height: 61.803vh; } }
  @media (min-width: 1024px) {
    .has-min-height-vh-golden-lg {
      min-height: 61.803vh; } }
  @media (min-width: 1280px) {
    .has-min-height-vh-golden-xl {
      min-height: 61.803vh; } }
  @media (min-width: 1920px) {
    .has-min-height-vh-golden-xxl {
      min-height: 61.803vh; } }

.has-min-height-vw-full {
  min-height: 100vw; }
  @media (min-width: 0px) {
    .has-min-height-vw-full-xs {
      min-height: 100vw; } }
  @media (min-width: 480px) {
    .has-min-height-vw-full-sm {
      min-height: 100vw; } }
  @media (min-width: 720px) {
    .has-min-height-vw-full-md {
      min-height: 100vw; } }
  @media (min-width: 1024px) {
    .has-min-height-vw-full-nav {
      min-height: 100vw; } }
  @media (min-width: 1024px) {
    .has-min-height-vw-full-lg {
      min-height: 100vw; } }
  @media (min-width: 1280px) {
    .has-min-height-vw-full-xl {
      min-height: 100vw; } }
  @media (min-width: 1920px) {
    .has-min-height-vw-full-xxl {
      min-height: 100vw; } }

.has-min-height-vw-1-4 {
  min-height: 25vw; }
  @media (min-width: 0px) {
    .has-min-height-vw-1-4-xs {
      min-height: 25vw; } }
  @media (min-width: 480px) {
    .has-min-height-vw-1-4-sm {
      min-height: 25vw; } }
  @media (min-width: 720px) {
    .has-min-height-vw-1-4-md {
      min-height: 25vw; } }
  @media (min-width: 1024px) {
    .has-min-height-vw-1-4-nav {
      min-height: 25vw; } }
  @media (min-width: 1024px) {
    .has-min-height-vw-1-4-lg {
      min-height: 25vw; } }
  @media (min-width: 1280px) {
    .has-min-height-vw-1-4-xl {
      min-height: 25vw; } }
  @media (min-width: 1920px) {
    .has-min-height-vw-1-4-xxl {
      min-height: 25vw; } }

.has-min-height-vw-half {
  min-height: 50vw; }
  @media (min-width: 0px) {
    .has-min-height-vw-half-xs {
      min-height: 50vw; } }
  @media (min-width: 480px) {
    .has-min-height-vw-half-sm {
      min-height: 50vw; } }
  @media (min-width: 720px) {
    .has-min-height-vw-half-md {
      min-height: 50vw; } }
  @media (min-width: 1024px) {
    .has-min-height-vw-half-nav {
      min-height: 50vw; } }
  @media (min-width: 1024px) {
    .has-min-height-vw-half-lg {
      min-height: 50vw; } }
  @media (min-width: 1280px) {
    .has-min-height-vw-half-xl {
      min-height: 50vw; } }
  @media (min-width: 1920px) {
    .has-min-height-vw-half-xxl {
      min-height: 50vw; } }

.has-min-height-vw-3-4 {
  min-height: 75vw; }
  @media (min-width: 0px) {
    .has-min-height-vw-3-4-xs {
      min-height: 75vw; } }
  @media (min-width: 480px) {
    .has-min-height-vw-3-4-sm {
      min-height: 75vw; } }
  @media (min-width: 720px) {
    .has-min-height-vw-3-4-md {
      min-height: 75vw; } }
  @media (min-width: 1024px) {
    .has-min-height-vw-3-4-nav {
      min-height: 75vw; } }
  @media (min-width: 1024px) {
    .has-min-height-vw-3-4-lg {
      min-height: 75vw; } }
  @media (min-width: 1280px) {
    .has-min-height-vw-3-4-xl {
      min-height: 75vw; } }
  @media (min-width: 1920px) {
    .has-min-height-vw-3-4-xxl {
      min-height: 75vw; } }

.has-min-height-vw-16-9 {
  min-height: 56.25vw; }
  @media (min-width: 0px) {
    .has-min-height-vw-16-9-xs {
      min-height: 56.25vw; } }
  @media (min-width: 480px) {
    .has-min-height-vw-16-9-sm {
      min-height: 56.25vw; } }
  @media (min-width: 720px) {
    .has-min-height-vw-16-9-md {
      min-height: 56.25vw; } }
  @media (min-width: 1024px) {
    .has-min-height-vw-16-9-nav {
      min-height: 56.25vw; } }
  @media (min-width: 1024px) {
    .has-min-height-vw-16-9-lg {
      min-height: 56.25vw; } }
  @media (min-width: 1280px) {
    .has-min-height-vw-16-9-xl {
      min-height: 56.25vw; } }
  @media (min-width: 1920px) {
    .has-min-height-vw-16-9-xxl {
      min-height: 56.25vw; } }

.has-min-height-vw-golden {
  min-height: 61.803vw; }
  @media (min-width: 0px) {
    .has-min-height-vw-golden-xs {
      min-height: 61.803vw; } }
  @media (min-width: 480px) {
    .has-min-height-vw-golden-sm {
      min-height: 61.803vw; } }
  @media (min-width: 720px) {
    .has-min-height-vw-golden-md {
      min-height: 61.803vw; } }
  @media (min-width: 1024px) {
    .has-min-height-vw-golden-nav {
      min-height: 61.803vw; } }
  @media (min-width: 1024px) {
    .has-min-height-vw-golden-lg {
      min-height: 61.803vw; } }
  @media (min-width: 1280px) {
    .has-min-height-vw-golden-xl {
      min-height: 61.803vw; } }
  @media (min-width: 1920px) {
    .has-min-height-vw-golden-xxl {
      min-height: 61.803vw; } }

.has-width-full {
  width: 100%; }
  @media (min-width: 0px) {
    .has-width-full-xs {
      width: 100%; } }
  @media (min-width: 480px) {
    .has-width-full-sm {
      width: 100%; } }
  @media (min-width: 720px) {
    .has-width-full-md {
      width: 100%; } }
  @media (min-width: 1024px) {
    .has-width-full-nav {
      width: 100%; } }
  @media (min-width: 1024px) {
    .has-width-full-lg {
      width: 100%; } }
  @media (min-width: 1280px) {
    .has-width-full-xl {
      width: 100%; } }
  @media (min-width: 1920px) {
    .has-width-full-xxl {
      width: 100%; } }

.has-width-1-4 {
  width: 25%; }
  @media (min-width: 0px) {
    .has-width-1-4-xs {
      width: 25%; } }
  @media (min-width: 480px) {
    .has-width-1-4-sm {
      width: 25%; } }
  @media (min-width: 720px) {
    .has-width-1-4-md {
      width: 25%; } }
  @media (min-width: 1024px) {
    .has-width-1-4-nav {
      width: 25%; } }
  @media (min-width: 1024px) {
    .has-width-1-4-lg {
      width: 25%; } }
  @media (min-width: 1280px) {
    .has-width-1-4-xl {
      width: 25%; } }
  @media (min-width: 1920px) {
    .has-width-1-4-xxl {
      width: 25%; } }

.has-width-half {
  width: 50%; }
  @media (min-width: 0px) {
    .has-width-half-xs {
      width: 50%; } }
  @media (min-width: 480px) {
    .has-width-half-sm {
      width: 50%; } }
  @media (min-width: 720px) {
    .has-width-half-md {
      width: 50%; } }
  @media (min-width: 1024px) {
    .has-width-half-nav {
      width: 50%; } }
  @media (min-width: 1024px) {
    .has-width-half-lg {
      width: 50%; } }
  @media (min-width: 1280px) {
    .has-width-half-xl {
      width: 50%; } }
  @media (min-width: 1920px) {
    .has-width-half-xxl {
      width: 50%; } }

.has-width-3-4 {
  width: 75%; }
  @media (min-width: 0px) {
    .has-width-3-4-xs {
      width: 75%; } }
  @media (min-width: 480px) {
    .has-width-3-4-sm {
      width: 75%; } }
  @media (min-width: 720px) {
    .has-width-3-4-md {
      width: 75%; } }
  @media (min-width: 1024px) {
    .has-width-3-4-nav {
      width: 75%; } }
  @media (min-width: 1024px) {
    .has-width-3-4-lg {
      width: 75%; } }
  @media (min-width: 1280px) {
    .has-width-3-4-xl {
      width: 75%; } }
  @media (min-width: 1920px) {
    .has-width-3-4-xxl {
      width: 75%; } }

.has-width-16-9 {
  width: 56.25%; }
  @media (min-width: 0px) {
    .has-width-16-9-xs {
      width: 56.25%; } }
  @media (min-width: 480px) {
    .has-width-16-9-sm {
      width: 56.25%; } }
  @media (min-width: 720px) {
    .has-width-16-9-md {
      width: 56.25%; } }
  @media (min-width: 1024px) {
    .has-width-16-9-nav {
      width: 56.25%; } }
  @media (min-width: 1024px) {
    .has-width-16-9-lg {
      width: 56.25%; } }
  @media (min-width: 1280px) {
    .has-width-16-9-xl {
      width: 56.25%; } }
  @media (min-width: 1920px) {
    .has-width-16-9-xxl {
      width: 56.25%; } }

.has-width-golden {
  width: 61.803%; }
  @media (min-width: 0px) {
    .has-width-golden-xs {
      width: 61.803%; } }
  @media (min-width: 480px) {
    .has-width-golden-sm {
      width: 61.803%; } }
  @media (min-width: 720px) {
    .has-width-golden-md {
      width: 61.803%; } }
  @media (min-width: 1024px) {
    .has-width-golden-nav {
      width: 61.803%; } }
  @media (min-width: 1024px) {
    .has-width-golden-lg {
      width: 61.803%; } }
  @media (min-width: 1280px) {
    .has-width-golden-xl {
      width: 61.803%; } }
  @media (min-width: 1920px) {
    .has-width-golden-xxl {
      width: 61.803%; } }

.has-width-vh-full {
  width: 100vh; }
  @media (min-width: 0px) {
    .has-width-vh-full-xs {
      width: 100vh; } }
  @media (min-width: 480px) {
    .has-width-vh-full-sm {
      width: 100vh; } }
  @media (min-width: 720px) {
    .has-width-vh-full-md {
      width: 100vh; } }
  @media (min-width: 1024px) {
    .has-width-vh-full-nav {
      width: 100vh; } }
  @media (min-width: 1024px) {
    .has-width-vh-full-lg {
      width: 100vh; } }
  @media (min-width: 1280px) {
    .has-width-vh-full-xl {
      width: 100vh; } }
  @media (min-width: 1920px) {
    .has-width-vh-full-xxl {
      width: 100vh; } }

.has-width-vh-1-4 {
  width: 25vh; }
  @media (min-width: 0px) {
    .has-width-vh-1-4-xs {
      width: 25vh; } }
  @media (min-width: 480px) {
    .has-width-vh-1-4-sm {
      width: 25vh; } }
  @media (min-width: 720px) {
    .has-width-vh-1-4-md {
      width: 25vh; } }
  @media (min-width: 1024px) {
    .has-width-vh-1-4-nav {
      width: 25vh; } }
  @media (min-width: 1024px) {
    .has-width-vh-1-4-lg {
      width: 25vh; } }
  @media (min-width: 1280px) {
    .has-width-vh-1-4-xl {
      width: 25vh; } }
  @media (min-width: 1920px) {
    .has-width-vh-1-4-xxl {
      width: 25vh; } }

.has-width-vh-half {
  width: 50vh; }
  @media (min-width: 0px) {
    .has-width-vh-half-xs {
      width: 50vh; } }
  @media (min-width: 480px) {
    .has-width-vh-half-sm {
      width: 50vh; } }
  @media (min-width: 720px) {
    .has-width-vh-half-md {
      width: 50vh; } }
  @media (min-width: 1024px) {
    .has-width-vh-half-nav {
      width: 50vh; } }
  @media (min-width: 1024px) {
    .has-width-vh-half-lg {
      width: 50vh; } }
  @media (min-width: 1280px) {
    .has-width-vh-half-xl {
      width: 50vh; } }
  @media (min-width: 1920px) {
    .has-width-vh-half-xxl {
      width: 50vh; } }

.has-width-vh-3-4 {
  width: 75vh; }
  @media (min-width: 0px) {
    .has-width-vh-3-4-xs {
      width: 75vh; } }
  @media (min-width: 480px) {
    .has-width-vh-3-4-sm {
      width: 75vh; } }
  @media (min-width: 720px) {
    .has-width-vh-3-4-md {
      width: 75vh; } }
  @media (min-width: 1024px) {
    .has-width-vh-3-4-nav {
      width: 75vh; } }
  @media (min-width: 1024px) {
    .has-width-vh-3-4-lg {
      width: 75vh; } }
  @media (min-width: 1280px) {
    .has-width-vh-3-4-xl {
      width: 75vh; } }
  @media (min-width: 1920px) {
    .has-width-vh-3-4-xxl {
      width: 75vh; } }

.has-width-vh-16-9 {
  width: 56.25vh; }
  @media (min-width: 0px) {
    .has-width-vh-16-9-xs {
      width: 56.25vh; } }
  @media (min-width: 480px) {
    .has-width-vh-16-9-sm {
      width: 56.25vh; } }
  @media (min-width: 720px) {
    .has-width-vh-16-9-md {
      width: 56.25vh; } }
  @media (min-width: 1024px) {
    .has-width-vh-16-9-nav {
      width: 56.25vh; } }
  @media (min-width: 1024px) {
    .has-width-vh-16-9-lg {
      width: 56.25vh; } }
  @media (min-width: 1280px) {
    .has-width-vh-16-9-xl {
      width: 56.25vh; } }
  @media (min-width: 1920px) {
    .has-width-vh-16-9-xxl {
      width: 56.25vh; } }

.has-width-vh-golden {
  width: 61.803vh; }
  @media (min-width: 0px) {
    .has-width-vh-golden-xs {
      width: 61.803vh; } }
  @media (min-width: 480px) {
    .has-width-vh-golden-sm {
      width: 61.803vh; } }
  @media (min-width: 720px) {
    .has-width-vh-golden-md {
      width: 61.803vh; } }
  @media (min-width: 1024px) {
    .has-width-vh-golden-nav {
      width: 61.803vh; } }
  @media (min-width: 1024px) {
    .has-width-vh-golden-lg {
      width: 61.803vh; } }
  @media (min-width: 1280px) {
    .has-width-vh-golden-xl {
      width: 61.803vh; } }
  @media (min-width: 1920px) {
    .has-width-vh-golden-xxl {
      width: 61.803vh; } }

.has-width-vw-full {
  width: 100vw; }
  @media (min-width: 0px) {
    .has-width-vw-full-xs {
      width: 100vw; } }
  @media (min-width: 480px) {
    .has-width-vw-full-sm {
      width: 100vw; } }
  @media (min-width: 720px) {
    .has-width-vw-full-md {
      width: 100vw; } }
  @media (min-width: 1024px) {
    .has-width-vw-full-nav {
      width: 100vw; } }
  @media (min-width: 1024px) {
    .has-width-vw-full-lg {
      width: 100vw; } }
  @media (min-width: 1280px) {
    .has-width-vw-full-xl {
      width: 100vw; } }
  @media (min-width: 1920px) {
    .has-width-vw-full-xxl {
      width: 100vw; } }

.has-width-vw-1-4 {
  width: 25vw; }
  @media (min-width: 0px) {
    .has-width-vw-1-4-xs {
      width: 25vw; } }
  @media (min-width: 480px) {
    .has-width-vw-1-4-sm {
      width: 25vw; } }
  @media (min-width: 720px) {
    .has-width-vw-1-4-md {
      width: 25vw; } }
  @media (min-width: 1024px) {
    .has-width-vw-1-4-nav {
      width: 25vw; } }
  @media (min-width: 1024px) {
    .has-width-vw-1-4-lg {
      width: 25vw; } }
  @media (min-width: 1280px) {
    .has-width-vw-1-4-xl {
      width: 25vw; } }
  @media (min-width: 1920px) {
    .has-width-vw-1-4-xxl {
      width: 25vw; } }

.has-width-vw-half {
  width: 50vw; }
  @media (min-width: 0px) {
    .has-width-vw-half-xs {
      width: 50vw; } }
  @media (min-width: 480px) {
    .has-width-vw-half-sm {
      width: 50vw; } }
  @media (min-width: 720px) {
    .has-width-vw-half-md {
      width: 50vw; } }
  @media (min-width: 1024px) {
    .has-width-vw-half-nav {
      width: 50vw; } }
  @media (min-width: 1024px) {
    .has-width-vw-half-lg {
      width: 50vw; } }
  @media (min-width: 1280px) {
    .has-width-vw-half-xl {
      width: 50vw; } }
  @media (min-width: 1920px) {
    .has-width-vw-half-xxl {
      width: 50vw; } }

.has-width-vw-3-4 {
  width: 75vw; }
  @media (min-width: 0px) {
    .has-width-vw-3-4-xs {
      width: 75vw; } }
  @media (min-width: 480px) {
    .has-width-vw-3-4-sm {
      width: 75vw; } }
  @media (min-width: 720px) {
    .has-width-vw-3-4-md {
      width: 75vw; } }
  @media (min-width: 1024px) {
    .has-width-vw-3-4-nav {
      width: 75vw; } }
  @media (min-width: 1024px) {
    .has-width-vw-3-4-lg {
      width: 75vw; } }
  @media (min-width: 1280px) {
    .has-width-vw-3-4-xl {
      width: 75vw; } }
  @media (min-width: 1920px) {
    .has-width-vw-3-4-xxl {
      width: 75vw; } }

.has-width-vw-16-9 {
  width: 56.25vw; }
  @media (min-width: 0px) {
    .has-width-vw-16-9-xs {
      width: 56.25vw; } }
  @media (min-width: 480px) {
    .has-width-vw-16-9-sm {
      width: 56.25vw; } }
  @media (min-width: 720px) {
    .has-width-vw-16-9-md {
      width: 56.25vw; } }
  @media (min-width: 1024px) {
    .has-width-vw-16-9-nav {
      width: 56.25vw; } }
  @media (min-width: 1024px) {
    .has-width-vw-16-9-lg {
      width: 56.25vw; } }
  @media (min-width: 1280px) {
    .has-width-vw-16-9-xl {
      width: 56.25vw; } }
  @media (min-width: 1920px) {
    .has-width-vw-16-9-xxl {
      width: 56.25vw; } }

.has-width-vw-golden {
  width: 61.803vw; }
  @media (min-width: 0px) {
    .has-width-vw-golden-xs {
      width: 61.803vw; } }
  @media (min-width: 480px) {
    .has-width-vw-golden-sm {
      width: 61.803vw; } }
  @media (min-width: 720px) {
    .has-width-vw-golden-md {
      width: 61.803vw; } }
  @media (min-width: 1024px) {
    .has-width-vw-golden-nav {
      width: 61.803vw; } }
  @media (min-width: 1024px) {
    .has-width-vw-golden-lg {
      width: 61.803vw; } }
  @media (min-width: 1280px) {
    .has-width-vw-golden-xl {
      width: 61.803vw; } }
  @media (min-width: 1920px) {
    .has-width-vw-golden-xxl {
      width: 61.803vw; } }

.has-min-width-full {
  min-width: 100%; }
  @media (min-width: 0px) {
    .has-min-width-full-xs {
      min-width: 100%; } }
  @media (min-width: 480px) {
    .has-min-width-full-sm {
      min-width: 100%; } }
  @media (min-width: 720px) {
    .has-min-width-full-md {
      min-width: 100%; } }
  @media (min-width: 1024px) {
    .has-min-width-full-nav {
      min-width: 100%; } }
  @media (min-width: 1024px) {
    .has-min-width-full-lg {
      min-width: 100%; } }
  @media (min-width: 1280px) {
    .has-min-width-full-xl {
      min-width: 100%; } }
  @media (min-width: 1920px) {
    .has-min-width-full-xxl {
      min-width: 100%; } }

.has-min-width-1-4 {
  min-width: 25%; }
  @media (min-width: 0px) {
    .has-min-width-1-4-xs {
      min-width: 25%; } }
  @media (min-width: 480px) {
    .has-min-width-1-4-sm {
      min-width: 25%; } }
  @media (min-width: 720px) {
    .has-min-width-1-4-md {
      min-width: 25%; } }
  @media (min-width: 1024px) {
    .has-min-width-1-4-nav {
      min-width: 25%; } }
  @media (min-width: 1024px) {
    .has-min-width-1-4-lg {
      min-width: 25%; } }
  @media (min-width: 1280px) {
    .has-min-width-1-4-xl {
      min-width: 25%; } }
  @media (min-width: 1920px) {
    .has-min-width-1-4-xxl {
      min-width: 25%; } }

.has-min-width-half {
  min-width: 50%; }
  @media (min-width: 0px) {
    .has-min-width-half-xs {
      min-width: 50%; } }
  @media (min-width: 480px) {
    .has-min-width-half-sm {
      min-width: 50%; } }
  @media (min-width: 720px) {
    .has-min-width-half-md {
      min-width: 50%; } }
  @media (min-width: 1024px) {
    .has-min-width-half-nav {
      min-width: 50%; } }
  @media (min-width: 1024px) {
    .has-min-width-half-lg {
      min-width: 50%; } }
  @media (min-width: 1280px) {
    .has-min-width-half-xl {
      min-width: 50%; } }
  @media (min-width: 1920px) {
    .has-min-width-half-xxl {
      min-width: 50%; } }

.has-min-width-3-4 {
  min-width: 75%; }
  @media (min-width: 0px) {
    .has-min-width-3-4-xs {
      min-width: 75%; } }
  @media (min-width: 480px) {
    .has-min-width-3-4-sm {
      min-width: 75%; } }
  @media (min-width: 720px) {
    .has-min-width-3-4-md {
      min-width: 75%; } }
  @media (min-width: 1024px) {
    .has-min-width-3-4-nav {
      min-width: 75%; } }
  @media (min-width: 1024px) {
    .has-min-width-3-4-lg {
      min-width: 75%; } }
  @media (min-width: 1280px) {
    .has-min-width-3-4-xl {
      min-width: 75%; } }
  @media (min-width: 1920px) {
    .has-min-width-3-4-xxl {
      min-width: 75%; } }

.has-min-width-16-9 {
  min-width: 56.25%; }
  @media (min-width: 0px) {
    .has-min-width-16-9-xs {
      min-width: 56.25%; } }
  @media (min-width: 480px) {
    .has-min-width-16-9-sm {
      min-width: 56.25%; } }
  @media (min-width: 720px) {
    .has-min-width-16-9-md {
      min-width: 56.25%; } }
  @media (min-width: 1024px) {
    .has-min-width-16-9-nav {
      min-width: 56.25%; } }
  @media (min-width: 1024px) {
    .has-min-width-16-9-lg {
      min-width: 56.25%; } }
  @media (min-width: 1280px) {
    .has-min-width-16-9-xl {
      min-width: 56.25%; } }
  @media (min-width: 1920px) {
    .has-min-width-16-9-xxl {
      min-width: 56.25%; } }

.has-min-width-golden {
  min-width: 61.803%; }
  @media (min-width: 0px) {
    .has-min-width-golden-xs {
      min-width: 61.803%; } }
  @media (min-width: 480px) {
    .has-min-width-golden-sm {
      min-width: 61.803%; } }
  @media (min-width: 720px) {
    .has-min-width-golden-md {
      min-width: 61.803%; } }
  @media (min-width: 1024px) {
    .has-min-width-golden-nav {
      min-width: 61.803%; } }
  @media (min-width: 1024px) {
    .has-min-width-golden-lg {
      min-width: 61.803%; } }
  @media (min-width: 1280px) {
    .has-min-width-golden-xl {
      min-width: 61.803%; } }
  @media (min-width: 1920px) {
    .has-min-width-golden-xxl {
      min-width: 61.803%; } }

.has-min-width-vh-full {
  min-width: 100vh; }
  @media (min-width: 0px) {
    .has-min-width-vh-full-xs {
      min-width: 100vh; } }
  @media (min-width: 480px) {
    .has-min-width-vh-full-sm {
      min-width: 100vh; } }
  @media (min-width: 720px) {
    .has-min-width-vh-full-md {
      min-width: 100vh; } }
  @media (min-width: 1024px) {
    .has-min-width-vh-full-nav {
      min-width: 100vh; } }
  @media (min-width: 1024px) {
    .has-min-width-vh-full-lg {
      min-width: 100vh; } }
  @media (min-width: 1280px) {
    .has-min-width-vh-full-xl {
      min-width: 100vh; } }
  @media (min-width: 1920px) {
    .has-min-width-vh-full-xxl {
      min-width: 100vh; } }

.has-min-width-vh-1-4 {
  min-width: 25vh; }
  @media (min-width: 0px) {
    .has-min-width-vh-1-4-xs {
      min-width: 25vh; } }
  @media (min-width: 480px) {
    .has-min-width-vh-1-4-sm {
      min-width: 25vh; } }
  @media (min-width: 720px) {
    .has-min-width-vh-1-4-md {
      min-width: 25vh; } }
  @media (min-width: 1024px) {
    .has-min-width-vh-1-4-nav {
      min-width: 25vh; } }
  @media (min-width: 1024px) {
    .has-min-width-vh-1-4-lg {
      min-width: 25vh; } }
  @media (min-width: 1280px) {
    .has-min-width-vh-1-4-xl {
      min-width: 25vh; } }
  @media (min-width: 1920px) {
    .has-min-width-vh-1-4-xxl {
      min-width: 25vh; } }

.has-min-width-vh-half {
  min-width: 50vh; }
  @media (min-width: 0px) {
    .has-min-width-vh-half-xs {
      min-width: 50vh; } }
  @media (min-width: 480px) {
    .has-min-width-vh-half-sm {
      min-width: 50vh; } }
  @media (min-width: 720px) {
    .has-min-width-vh-half-md {
      min-width: 50vh; } }
  @media (min-width: 1024px) {
    .has-min-width-vh-half-nav {
      min-width: 50vh; } }
  @media (min-width: 1024px) {
    .has-min-width-vh-half-lg {
      min-width: 50vh; } }
  @media (min-width: 1280px) {
    .has-min-width-vh-half-xl {
      min-width: 50vh; } }
  @media (min-width: 1920px) {
    .has-min-width-vh-half-xxl {
      min-width: 50vh; } }

.has-min-width-vh-3-4 {
  min-width: 75vh; }
  @media (min-width: 0px) {
    .has-min-width-vh-3-4-xs {
      min-width: 75vh; } }
  @media (min-width: 480px) {
    .has-min-width-vh-3-4-sm {
      min-width: 75vh; } }
  @media (min-width: 720px) {
    .has-min-width-vh-3-4-md {
      min-width: 75vh; } }
  @media (min-width: 1024px) {
    .has-min-width-vh-3-4-nav {
      min-width: 75vh; } }
  @media (min-width: 1024px) {
    .has-min-width-vh-3-4-lg {
      min-width: 75vh; } }
  @media (min-width: 1280px) {
    .has-min-width-vh-3-4-xl {
      min-width: 75vh; } }
  @media (min-width: 1920px) {
    .has-min-width-vh-3-4-xxl {
      min-width: 75vh; } }

.has-min-width-vh-16-9 {
  min-width: 56.25vh; }
  @media (min-width: 0px) {
    .has-min-width-vh-16-9-xs {
      min-width: 56.25vh; } }
  @media (min-width: 480px) {
    .has-min-width-vh-16-9-sm {
      min-width: 56.25vh; } }
  @media (min-width: 720px) {
    .has-min-width-vh-16-9-md {
      min-width: 56.25vh; } }
  @media (min-width: 1024px) {
    .has-min-width-vh-16-9-nav {
      min-width: 56.25vh; } }
  @media (min-width: 1024px) {
    .has-min-width-vh-16-9-lg {
      min-width: 56.25vh; } }
  @media (min-width: 1280px) {
    .has-min-width-vh-16-9-xl {
      min-width: 56.25vh; } }
  @media (min-width: 1920px) {
    .has-min-width-vh-16-9-xxl {
      min-width: 56.25vh; } }

.has-min-width-vh-golden {
  min-width: 61.803vh; }
  @media (min-width: 0px) {
    .has-min-width-vh-golden-xs {
      min-width: 61.803vh; } }
  @media (min-width: 480px) {
    .has-min-width-vh-golden-sm {
      min-width: 61.803vh; } }
  @media (min-width: 720px) {
    .has-min-width-vh-golden-md {
      min-width: 61.803vh; } }
  @media (min-width: 1024px) {
    .has-min-width-vh-golden-nav {
      min-width: 61.803vh; } }
  @media (min-width: 1024px) {
    .has-min-width-vh-golden-lg {
      min-width: 61.803vh; } }
  @media (min-width: 1280px) {
    .has-min-width-vh-golden-xl {
      min-width: 61.803vh; } }
  @media (min-width: 1920px) {
    .has-min-width-vh-golden-xxl {
      min-width: 61.803vh; } }

.has-min-width-vw-full {
  min-width: 100vw; }
  @media (min-width: 0px) {
    .has-min-width-vw-full-xs {
      min-width: 100vw; } }
  @media (min-width: 480px) {
    .has-min-width-vw-full-sm {
      min-width: 100vw; } }
  @media (min-width: 720px) {
    .has-min-width-vw-full-md {
      min-width: 100vw; } }
  @media (min-width: 1024px) {
    .has-min-width-vw-full-nav {
      min-width: 100vw; } }
  @media (min-width: 1024px) {
    .has-min-width-vw-full-lg {
      min-width: 100vw; } }
  @media (min-width: 1280px) {
    .has-min-width-vw-full-xl {
      min-width: 100vw; } }
  @media (min-width: 1920px) {
    .has-min-width-vw-full-xxl {
      min-width: 100vw; } }

.has-min-width-vw-1-4 {
  min-width: 25vw; }
  @media (min-width: 0px) {
    .has-min-width-vw-1-4-xs {
      min-width: 25vw; } }
  @media (min-width: 480px) {
    .has-min-width-vw-1-4-sm {
      min-width: 25vw; } }
  @media (min-width: 720px) {
    .has-min-width-vw-1-4-md {
      min-width: 25vw; } }
  @media (min-width: 1024px) {
    .has-min-width-vw-1-4-nav {
      min-width: 25vw; } }
  @media (min-width: 1024px) {
    .has-min-width-vw-1-4-lg {
      min-width: 25vw; } }
  @media (min-width: 1280px) {
    .has-min-width-vw-1-4-xl {
      min-width: 25vw; } }
  @media (min-width: 1920px) {
    .has-min-width-vw-1-4-xxl {
      min-width: 25vw; } }

.has-min-width-vw-half {
  min-width: 50vw; }
  @media (min-width: 0px) {
    .has-min-width-vw-half-xs {
      min-width: 50vw; } }
  @media (min-width: 480px) {
    .has-min-width-vw-half-sm {
      min-width: 50vw; } }
  @media (min-width: 720px) {
    .has-min-width-vw-half-md {
      min-width: 50vw; } }
  @media (min-width: 1024px) {
    .has-min-width-vw-half-nav {
      min-width: 50vw; } }
  @media (min-width: 1024px) {
    .has-min-width-vw-half-lg {
      min-width: 50vw; } }
  @media (min-width: 1280px) {
    .has-min-width-vw-half-xl {
      min-width: 50vw; } }
  @media (min-width: 1920px) {
    .has-min-width-vw-half-xxl {
      min-width: 50vw; } }

.has-min-width-vw-3-4 {
  min-width: 75vw; }
  @media (min-width: 0px) {
    .has-min-width-vw-3-4-xs {
      min-width: 75vw; } }
  @media (min-width: 480px) {
    .has-min-width-vw-3-4-sm {
      min-width: 75vw; } }
  @media (min-width: 720px) {
    .has-min-width-vw-3-4-md {
      min-width: 75vw; } }
  @media (min-width: 1024px) {
    .has-min-width-vw-3-4-nav {
      min-width: 75vw; } }
  @media (min-width: 1024px) {
    .has-min-width-vw-3-4-lg {
      min-width: 75vw; } }
  @media (min-width: 1280px) {
    .has-min-width-vw-3-4-xl {
      min-width: 75vw; } }
  @media (min-width: 1920px) {
    .has-min-width-vw-3-4-xxl {
      min-width: 75vw; } }

.has-min-width-vw-16-9 {
  min-width: 56.25vw; }
  @media (min-width: 0px) {
    .has-min-width-vw-16-9-xs {
      min-width: 56.25vw; } }
  @media (min-width: 480px) {
    .has-min-width-vw-16-9-sm {
      min-width: 56.25vw; } }
  @media (min-width: 720px) {
    .has-min-width-vw-16-9-md {
      min-width: 56.25vw; } }
  @media (min-width: 1024px) {
    .has-min-width-vw-16-9-nav {
      min-width: 56.25vw; } }
  @media (min-width: 1024px) {
    .has-min-width-vw-16-9-lg {
      min-width: 56.25vw; } }
  @media (min-width: 1280px) {
    .has-min-width-vw-16-9-xl {
      min-width: 56.25vw; } }
  @media (min-width: 1920px) {
    .has-min-width-vw-16-9-xxl {
      min-width: 56.25vw; } }

.has-min-width-vw-golden {
  min-width: 61.803vw; }
  @media (min-width: 0px) {
    .has-min-width-vw-golden-xs {
      min-width: 61.803vw; } }
  @media (min-width: 480px) {
    .has-min-width-vw-golden-sm {
      min-width: 61.803vw; } }
  @media (min-width: 720px) {
    .has-min-width-vw-golden-md {
      min-width: 61.803vw; } }
  @media (min-width: 1024px) {
    .has-min-width-vw-golden-nav {
      min-width: 61.803vw; } }
  @media (min-width: 1024px) {
    .has-min-width-vw-golden-lg {
      min-width: 61.803vw; } }
  @media (min-width: 1280px) {
    .has-min-width-vw-golden-xl {
      min-width: 61.803vw; } }
  @media (min-width: 1920px) {
    .has-min-width-vw-golden-xxl {
      min-width: 61.803vw; } }

.pull-right {
  float: right !important; }
  @media (min-width: 0px) {
    .pull-right-xs {
      float: right !important; } }
  @media (min-width: 480px) {
    .pull-right-sm {
      float: right !important; } }
  @media (min-width: 720px) {
    .pull-right-md {
      float: right !important; } }
  @media (min-width: 1024px) {
    .pull-right-nav {
      float: right !important; } }
  @media (min-width: 1024px) {
    .pull-right-lg {
      float: right !important; } }
  @media (min-width: 1280px) {
    .pull-right-xl {
      float: right !important; } }
  @media (min-width: 1920px) {
    .pull-right-xxl {
      float: right !important; } }

.pull-left {
  float: left !important; }
  @media (min-width: 0px) {
    .pull-left-xs {
      float: left !important; } }
  @media (min-width: 480px) {
    .pull-left-sm {
      float: left !important; } }
  @media (min-width: 720px) {
    .pull-left-md {
      float: left !important; } }
  @media (min-width: 1024px) {
    .pull-left-nav {
      float: left !important; } }
  @media (min-width: 1024px) {
    .pull-left-lg {
      float: left !important; } }
  @media (min-width: 1280px) {
    .pull-left-xl {
      float: left !important; } }
  @media (min-width: 1920px) {
    .pull-left-xxl {
      float: left !important; } }

.is-scrollable {
  -webkit-overflow-scrolling: touch;
  overflow: scroll; }
  @media (min-width: 0px) {
    .is-scrollable-xs {
      -webkit-overflow-scrolling: touch;
      overflow: scroll; } }
  @media (min-width: 480px) {
    .is-scrollable-sm {
      -webkit-overflow-scrolling: touch;
      overflow: scroll; } }
  @media (min-width: 720px) {
    .is-scrollable-md {
      -webkit-overflow-scrolling: touch;
      overflow: scroll; } }
  @media (min-width: 1024px) {
    .is-scrollable-nav {
      -webkit-overflow-scrolling: touch;
      overflow: scroll; } }
  @media (min-width: 1024px) {
    .is-scrollable-lg {
      -webkit-overflow-scrolling: touch;
      overflow: scroll; } }
  @media (min-width: 1280px) {
    .is-scrollable-xl {
      -webkit-overflow-scrolling: touch;
      overflow: scroll; } }
  @media (min-width: 1920px) {
    .is-scrollable-xxl {
      -webkit-overflow-scrolling: touch;
      overflow: scroll; } }
  .is-scrollable-x {
    -webkit-overflow-scrolling: touch;
    overflow-x: scroll; }
    @media (min-width: 0px) {
      .is-scrollable-x-xs {
        -webkit-overflow-scrolling: touch;
        overflow-x: scroll; } }
    @media (min-width: 480px) {
      .is-scrollable-x-sm {
        -webkit-overflow-scrolling: touch;
        overflow-x: scroll; } }
    @media (min-width: 720px) {
      .is-scrollable-x-md {
        -webkit-overflow-scrolling: touch;
        overflow-x: scroll; } }
    @media (min-width: 1024px) {
      .is-scrollable-x-nav {
        -webkit-overflow-scrolling: touch;
        overflow-x: scroll; } }
    @media (min-width: 1024px) {
      .is-scrollable-x-lg {
        -webkit-overflow-scrolling: touch;
        overflow-x: scroll; } }
    @media (min-width: 1280px) {
      .is-scrollable-x-xl {
        -webkit-overflow-scrolling: touch;
        overflow-x: scroll; } }
    @media (min-width: 1920px) {
      .is-scrollable-x-xxl {
        -webkit-overflow-scrolling: touch;
        overflow-x: scroll; } }
    .is-scrollable-x-contain {
      -webkit-overflow-scrolling: touch;
      overflow-x: scroll;
      overscroll-behavior-x: contain; }
      @media (min-width: 0px) {
        .is-scrollable-x-contain-xs {
          -webkit-overflow-scrolling: touch;
          overflow-x: scroll;
          overscroll-behavior-x: contain; } }
      @media (min-width: 480px) {
        .is-scrollable-x-contain-sm {
          -webkit-overflow-scrolling: touch;
          overflow-x: scroll;
          overscroll-behavior-x: contain; } }
      @media (min-width: 720px) {
        .is-scrollable-x-contain-md {
          -webkit-overflow-scrolling: touch;
          overflow-x: scroll;
          overscroll-behavior-x: contain; } }
      @media (min-width: 1024px) {
        .is-scrollable-x-contain-nav {
          -webkit-overflow-scrolling: touch;
          overflow-x: scroll;
          overscroll-behavior-x: contain; } }
      @media (min-width: 1024px) {
        .is-scrollable-x-contain-lg {
          -webkit-overflow-scrolling: touch;
          overflow-x: scroll;
          overscroll-behavior-x: contain; } }
      @media (min-width: 1280px) {
        .is-scrollable-x-contain-xl {
          -webkit-overflow-scrolling: touch;
          overflow-x: scroll;
          overscroll-behavior-x: contain; } }
      @media (min-width: 1920px) {
        .is-scrollable-x-contain-xxl {
          -webkit-overflow-scrolling: touch;
          overflow-x: scroll;
          overscroll-behavior-x: contain; } }
  .is-scrollable-y {
    -webkit-overflow-scrolling: touch;
    overflow-y: scroll; }
    @media (min-width: 0px) {
      .is-scrollable-y-xs {
        -webkit-overflow-scrolling: touch;
        overflow-y: scroll; } }
    @media (min-width: 480px) {
      .is-scrollable-y-sm {
        -webkit-overflow-scrolling: touch;
        overflow-y: scroll; } }
    @media (min-width: 720px) {
      .is-scrollable-y-md {
        -webkit-overflow-scrolling: touch;
        overflow-y: scroll; } }
    @media (min-width: 1024px) {
      .is-scrollable-y-nav {
        -webkit-overflow-scrolling: touch;
        overflow-y: scroll; } }
    @media (min-width: 1024px) {
      .is-scrollable-y-lg {
        -webkit-overflow-scrolling: touch;
        overflow-y: scroll; } }
    @media (min-width: 1280px) {
      .is-scrollable-y-xl {
        -webkit-overflow-scrolling: touch;
        overflow-y: scroll; } }
    @media (min-width: 1920px) {
      .is-scrollable-y-xxl {
        -webkit-overflow-scrolling: touch;
        overflow-y: scroll; } }
    .is-scrollable-y-contain {
      -webkit-overflow-scrolling: touch;
      overflow-y: scroll;
      overscroll-behavior-y: contain; }
      @media (min-width: 0px) {
        .is-scrollable-y-contain-xs {
          -webkit-overflow-scrolling: touch;
          overflow-y: scroll;
          overscroll-behavior-y: contain; } }
      @media (min-width: 480px) {
        .is-scrollable-y-contain-sm {
          -webkit-overflow-scrolling: touch;
          overflow-y: scroll;
          overscroll-behavior-y: contain; } }
      @media (min-width: 720px) {
        .is-scrollable-y-contain-md {
          -webkit-overflow-scrolling: touch;
          overflow-y: scroll;
          overscroll-behavior-y: contain; } }
      @media (min-width: 1024px) {
        .is-scrollable-y-contain-nav {
          -webkit-overflow-scrolling: touch;
          overflow-y: scroll;
          overscroll-behavior-y: contain; } }
      @media (min-width: 1024px) {
        .is-scrollable-y-contain-lg {
          -webkit-overflow-scrolling: touch;
          overflow-y: scroll;
          overscroll-behavior-y: contain; } }
      @media (min-width: 1280px) {
        .is-scrollable-y-contain-xl {
          -webkit-overflow-scrolling: touch;
          overflow-y: scroll;
          overscroll-behavior-y: contain; } }
      @media (min-width: 1920px) {
        .is-scrollable-y-contain-xxl {
          -webkit-overflow-scrolling: touch;
          overflow-y: scroll;
          overscroll-behavior-y: contain; } }

.is-scrollable-auto-x {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto; }
  @media (min-width: 0px) {
    .is-scrollable-auto-x-xs {
      -webkit-overflow-scrolling: touch;
      overflow-x: auto; } }
  @media (min-width: 480px) {
    .is-scrollable-auto-x-sm {
      -webkit-overflow-scrolling: touch;
      overflow-x: auto; } }
  @media (min-width: 720px) {
    .is-scrollable-auto-x-md {
      -webkit-overflow-scrolling: touch;
      overflow-x: auto; } }
  @media (min-width: 1024px) {
    .is-scrollable-auto-x-nav {
      -webkit-overflow-scrolling: touch;
      overflow-x: auto; } }
  @media (min-width: 1024px) {
    .is-scrollable-auto-x-lg {
      -webkit-overflow-scrolling: touch;
      overflow-x: auto; } }
  @media (min-width: 1280px) {
    .is-scrollable-auto-x-xl {
      -webkit-overflow-scrolling: touch;
      overflow-x: auto; } }
  @media (min-width: 1920px) {
    .is-scrollable-auto-x-xxl {
      -webkit-overflow-scrolling: touch;
      overflow-x: auto; } }
  .is-scrollable-auto-x-contain {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    overscroll-behavior-x: contain; }
    @media (min-width: 0px) {
      .is-scrollable-auto-x-contain-xs {
        -webkit-overflow-scrolling: touch;
        overflow-x: auto;
        overscroll-behavior-x: contain; } }
    @media (min-width: 480px) {
      .is-scrollable-auto-x-contain-sm {
        -webkit-overflow-scrolling: touch;
        overflow-x: auto;
        overscroll-behavior-x: contain; } }
    @media (min-width: 720px) {
      .is-scrollable-auto-x-contain-md {
        -webkit-overflow-scrolling: touch;
        overflow-x: auto;
        overscroll-behavior-x: contain; } }
    @media (min-width: 1024px) {
      .is-scrollable-auto-x-contain-nav {
        -webkit-overflow-scrolling: touch;
        overflow-x: auto;
        overscroll-behavior-x: contain; } }
    @media (min-width: 1024px) {
      .is-scrollable-auto-x-contain-lg {
        -webkit-overflow-scrolling: touch;
        overflow-x: auto;
        overscroll-behavior-x: contain; } }
    @media (min-width: 1280px) {
      .is-scrollable-auto-x-contain-xl {
        -webkit-overflow-scrolling: touch;
        overflow-x: auto;
        overscroll-behavior-x: contain; } }
    @media (min-width: 1920px) {
      .is-scrollable-auto-x-contain-xxl {
        -webkit-overflow-scrolling: touch;
        overflow-x: auto;
        overscroll-behavior-x: contain; } }

.is-scrollable-auto-y {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto; }
  @media (min-width: 0px) {
    .is-scrollable-auto-y-xs {
      -webkit-overflow-scrolling: touch;
      overflow-y: auto; } }
  @media (min-width: 480px) {
    .is-scrollable-auto-y-sm {
      -webkit-overflow-scrolling: touch;
      overflow-y: auto; } }
  @media (min-width: 720px) {
    .is-scrollable-auto-y-md {
      -webkit-overflow-scrolling: touch;
      overflow-y: auto; } }
  @media (min-width: 1024px) {
    .is-scrollable-auto-y-nav {
      -webkit-overflow-scrolling: touch;
      overflow-y: auto; } }
  @media (min-width: 1024px) {
    .is-scrollable-auto-y-lg {
      -webkit-overflow-scrolling: touch;
      overflow-y: auto; } }
  @media (min-width: 1280px) {
    .is-scrollable-auto-y-xl {
      -webkit-overflow-scrolling: touch;
      overflow-y: auto; } }
  @media (min-width: 1920px) {
    .is-scrollable-auto-y-xxl {
      -webkit-overflow-scrolling: touch;
      overflow-y: auto; } }
  .is-scrollable-auto-y-contain {
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
    overscroll-behavior-y: contain; }
    @media (min-width: 0px) {
      .is-scrollable-auto-y-contain-xs {
        -webkit-overflow-scrolling: touch;
        overflow-y: auto;
        overscroll-behavior-y: contain; } }
    @media (min-width: 480px) {
      .is-scrollable-auto-y-contain-sm {
        -webkit-overflow-scrolling: touch;
        overflow-y: auto;
        overscroll-behavior-y: contain; } }
    @media (min-width: 720px) {
      .is-scrollable-auto-y-contain-md {
        -webkit-overflow-scrolling: touch;
        overflow-y: auto;
        overscroll-behavior-y: contain; } }
    @media (min-width: 1024px) {
      .is-scrollable-auto-y-contain-nav {
        -webkit-overflow-scrolling: touch;
        overflow-y: auto;
        overscroll-behavior-y: contain; } }
    @media (min-width: 1024px) {
      .is-scrollable-auto-y-contain-lg {
        -webkit-overflow-scrolling: touch;
        overflow-y: auto;
        overscroll-behavior-y: contain; } }
    @media (min-width: 1280px) {
      .is-scrollable-auto-y-contain-xl {
        -webkit-overflow-scrolling: touch;
        overflow-y: auto;
        overscroll-behavior-y: contain; } }
    @media (min-width: 1920px) {
      .is-scrollable-auto-y-contain-xxl {
        -webkit-overflow-scrolling: touch;
        overflow-y: auto;
        overscroll-behavior-y: contain; } }

.is-clipped {
  overflow: hidden; }
  @media (min-width: 0px) {
    .is-clipped-xs {
      overflow: hidden; } }
  @media (min-width: 480px) {
    .is-clipped-sm {
      overflow: hidden; } }
  @media (min-width: 720px) {
    .is-clipped-md {
      overflow: hidden; } }
  @media (min-width: 1024px) {
    .is-clipped-nav {
      overflow: hidden; } }
  @media (min-width: 1024px) {
    .is-clipped-lg {
      overflow: hidden; } }
  @media (min-width: 1280px) {
    .is-clipped-xl {
      overflow: hidden; } }
  @media (min-width: 1920px) {
    .is-clipped-xxl {
      overflow: hidden; } }
  .is-clipped-x {
    overflow-x: hidden; }
    @media (min-width: 0px) {
      .is-clipped-x-xs {
        overflow-x: hidden; } }
    @media (min-width: 480px) {
      .is-clipped-x-sm {
        overflow-x: hidden; } }
    @media (min-width: 720px) {
      .is-clipped-x-md {
        overflow-x: hidden; } }
    @media (min-width: 1024px) {
      .is-clipped-x-nav {
        overflow-x: hidden; } }
    @media (min-width: 1024px) {
      .is-clipped-x-lg {
        overflow-x: hidden; } }
    @media (min-width: 1280px) {
      .is-clipped-x-xl {
        overflow-x: hidden; } }
    @media (min-width: 1920px) {
      .is-clipped-x-xxl {
        overflow-x: hidden; } }
  .is-clipped-y {
    overflow-y: hidden; }
    @media (min-width: 0px) {
      .is-clipped-y-xs {
        overflow-y: hidden; } }
    @media (min-width: 480px) {
      .is-clipped-y-sm {
        overflow-y: hidden; } }
    @media (min-width: 720px) {
      .is-clipped-y-md {
        overflow-y: hidden; } }
    @media (min-width: 1024px) {
      .is-clipped-y-nav {
        overflow-y: hidden; } }
    @media (min-width: 1024px) {
      .is-clipped-y-lg {
        overflow-y: hidden; } }
    @media (min-width: 1280px) {
      .is-clipped-y-xl {
        overflow-y: hidden; } }
    @media (min-width: 1920px) {
      .is-clipped-y-xxl {
        overflow-y: hidden; } }

.is-relative {
  position: relative; }
  @media (min-width: 0px) {
    .is-relative-xs {
      position: relative; } }
  @media (min-width: 480px) {
    .is-relative-sm {
      position: relative; } }
  @media (min-width: 720px) {
    .is-relative-md {
      position: relative; } }
  @media (min-width: 1024px) {
    .is-relative-nav {
      position: relative; } }
  @media (min-width: 1024px) {
    .is-relative-lg {
      position: relative; } }
  @media (min-width: 1280px) {
    .is-relative-xl {
      position: relative; } }
  @media (min-width: 1920px) {
    .is-relative-xxl {
      position: relative; } }

.is-absolute {
  position: absolute; }
  @media (min-width: 0px) {
    .is-absolute-xs {
      position: absolute; } }
  @media (min-width: 480px) {
    .is-absolute-sm {
      position: absolute; } }
  @media (min-width: 720px) {
    .is-absolute-md {
      position: absolute; } }
  @media (min-width: 1024px) {
    .is-absolute-nav {
      position: absolute; } }
  @media (min-width: 1024px) {
    .is-absolute-lg {
      position: absolute; } }
  @media (min-width: 1280px) {
    .is-absolute-xl {
      position: absolute; } }
  @media (min-width: 1920px) {
    .is-absolute-xxl {
      position: absolute; } }

.is-fixed {
  position: fixed; }
  @media (min-width: 0px) {
    .is-fixed-xs {
      position: fixed; } }
  @media (min-width: 480px) {
    .is-fixed-sm {
      position: fixed; } }
  @media (min-width: 720px) {
    .is-fixed-md {
      position: fixed; } }
  @media (min-width: 1024px) {
    .is-fixed-nav {
      position: fixed; } }
  @media (min-width: 1024px) {
    .is-fixed-lg {
      position: fixed; } }
  @media (min-width: 1280px) {
    .is-fixed-xl {
      position: fixed; } }
  @media (min-width: 1920px) {
    .is-fixed-xxl {
      position: fixed; } }

.is-sticky {
  position: sticky; }
  @media (min-width: 0px) {
    .is-sticky-xs {
      position: sticky; } }
  @media (min-width: 480px) {
    .is-sticky-sm {
      position: sticky; } }
  @media (min-width: 720px) {
    .is-sticky-md {
      position: sticky; } }
  @media (min-width: 1024px) {
    .is-sticky-nav {
      position: sticky; } }
  @media (min-width: 1024px) {
    .is-sticky-lg {
      position: sticky; } }
  @media (min-width: 1280px) {
    .is-sticky-xl {
      position: sticky; } }
  @media (min-width: 1920px) {
    .is-sticky-xxl {
      position: sticky; } }

.is-static {
  position: static; }
  @media (min-width: 0px) {
    .is-static-xs {
      position: static; } }
  @media (min-width: 480px) {
    .is-static-sm {
      position: static; } }
  @media (min-width: 720px) {
    .is-static-md {
      position: static; } }
  @media (min-width: 1024px) {
    .is-static-nav {
      position: static; } }
  @media (min-width: 1024px) {
    .is-static-lg {
      position: static; } }
  @media (min-width: 1280px) {
    .is-static-xl {
      position: static; } }
  @media (min-width: 1920px) {
    .is-static-xxl {
      position: static; } }

.is-positioned-n {
  left: 50%;
  top: 0;
  transform: translateX(-50%); }
  @media (min-width: 0px) {
    .is-positioned-n-xs {
      left: 50%;
      top: 0;
      transform: translateX(-50%); } }
  @media (min-width: 480px) {
    .is-positioned-n-sm {
      left: 50%;
      top: 0;
      transform: translateX(-50%); } }
  @media (min-width: 720px) {
    .is-positioned-n-md {
      left: 50%;
      top: 0;
      transform: translateX(-50%); } }
  @media (min-width: 1024px) {
    .is-positioned-n-nav {
      left: 50%;
      top: 0;
      transform: translateX(-50%); } }
  @media (min-width: 1024px) {
    .is-positioned-n-lg {
      left: 50%;
      top: 0;
      transform: translateX(-50%); } }
  @media (min-width: 1280px) {
    .is-positioned-n-xl {
      left: 50%;
      top: 0;
      transform: translateX(-50%); } }
  @media (min-width: 1920px) {
    .is-positioned-n-xxl {
      left: 50%;
      top: 0;
      transform: translateX(-50%); } }

.is-positioned-ne {
  right: 0;
  top: 0; }
  @media (min-width: 0px) {
    .is-positioned-ne-xs {
      right: 0;
      top: 0; } }
  @media (min-width: 480px) {
    .is-positioned-ne-sm {
      right: 0;
      top: 0; } }
  @media (min-width: 720px) {
    .is-positioned-ne-md {
      right: 0;
      top: 0; } }
  @media (min-width: 1024px) {
    .is-positioned-ne-nav {
      right: 0;
      top: 0; } }
  @media (min-width: 1024px) {
    .is-positioned-ne-lg {
      right: 0;
      top: 0; } }
  @media (min-width: 1280px) {
    .is-positioned-ne-xl {
      right: 0;
      top: 0; } }
  @media (min-width: 1920px) {
    .is-positioned-ne-xxl {
      right: 0;
      top: 0; } }

.is-positioned-e {
  right: 0;
  top: 50%;
  transform: translateY(-50%); }
  @media (min-width: 0px) {
    .is-positioned-e-xs {
      right: 0;
      top: 50%;
      transform: translateY(-50%); } }
  @media (min-width: 480px) {
    .is-positioned-e-sm {
      right: 0;
      top: 50%;
      transform: translateY(-50%); } }
  @media (min-width: 720px) {
    .is-positioned-e-md {
      right: 0;
      top: 50%;
      transform: translateY(-50%); } }
  @media (min-width: 1024px) {
    .is-positioned-e-nav {
      right: 0;
      top: 50%;
      transform: translateY(-50%); } }
  @media (min-width: 1024px) {
    .is-positioned-e-lg {
      right: 0;
      top: 50%;
      transform: translateY(-50%); } }
  @media (min-width: 1280px) {
    .is-positioned-e-xl {
      right: 0;
      top: 50%;
      transform: translateY(-50%); } }
  @media (min-width: 1920px) {
    .is-positioned-e-xxl {
      right: 0;
      top: 50%;
      transform: translateY(-50%); } }

.is-positioned-se {
  bottom: 0;
  right: 0; }
  @media (min-width: 0px) {
    .is-positioned-se-xs {
      bottom: 0;
      right: 0; } }
  @media (min-width: 480px) {
    .is-positioned-se-sm {
      bottom: 0;
      right: 0; } }
  @media (min-width: 720px) {
    .is-positioned-se-md {
      bottom: 0;
      right: 0; } }
  @media (min-width: 1024px) {
    .is-positioned-se-nav {
      bottom: 0;
      right: 0; } }
  @media (min-width: 1024px) {
    .is-positioned-se-lg {
      bottom: 0;
      right: 0; } }
  @media (min-width: 1280px) {
    .is-positioned-se-xl {
      bottom: 0;
      right: 0; } }
  @media (min-width: 1920px) {
    .is-positioned-se-xxl {
      bottom: 0;
      right: 0; } }

.is-positioned-s {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%); }
  @media (min-width: 0px) {
    .is-positioned-s-xs {
      bottom: 0;
      left: 50%;
      transform: translateX(-50%); } }
  @media (min-width: 480px) {
    .is-positioned-s-sm {
      bottom: 0;
      left: 50%;
      transform: translateX(-50%); } }
  @media (min-width: 720px) {
    .is-positioned-s-md {
      bottom: 0;
      left: 50%;
      transform: translateX(-50%); } }
  @media (min-width: 1024px) {
    .is-positioned-s-nav {
      bottom: 0;
      left: 50%;
      transform: translateX(-50%); } }
  @media (min-width: 1024px) {
    .is-positioned-s-lg {
      bottom: 0;
      left: 50%;
      transform: translateX(-50%); } }
  @media (min-width: 1280px) {
    .is-positioned-s-xl {
      bottom: 0;
      left: 50%;
      transform: translateX(-50%); } }
  @media (min-width: 1920px) {
    .is-positioned-s-xxl {
      bottom: 0;
      left: 50%;
      transform: translateX(-50%); } }

.is-positioned-sw {
  bottom: 0;
  left: 0; }
  @media (min-width: 0px) {
    .is-positioned-sw-xs {
      bottom: 0;
      left: 0; } }
  @media (min-width: 480px) {
    .is-positioned-sw-sm {
      bottom: 0;
      left: 0; } }
  @media (min-width: 720px) {
    .is-positioned-sw-md {
      bottom: 0;
      left: 0; } }
  @media (min-width: 1024px) {
    .is-positioned-sw-nav {
      bottom: 0;
      left: 0; } }
  @media (min-width: 1024px) {
    .is-positioned-sw-lg {
      bottom: 0;
      left: 0; } }
  @media (min-width: 1280px) {
    .is-positioned-sw-xl {
      bottom: 0;
      left: 0; } }
  @media (min-width: 1920px) {
    .is-positioned-sw-xxl {
      bottom: 0;
      left: 0; } }

.is-positioned-w {
  left: 0;
  top: 50%;
  transform: translateY(-50%); }
  @media (min-width: 0px) {
    .is-positioned-w-xs {
      left: 0;
      top: 50%;
      transform: translateY(-50%); } }
  @media (min-width: 480px) {
    .is-positioned-w-sm {
      left: 0;
      top: 50%;
      transform: translateY(-50%); } }
  @media (min-width: 720px) {
    .is-positioned-w-md {
      left: 0;
      top: 50%;
      transform: translateY(-50%); } }
  @media (min-width: 1024px) {
    .is-positioned-w-nav {
      left: 0;
      top: 50%;
      transform: translateY(-50%); } }
  @media (min-width: 1024px) {
    .is-positioned-w-lg {
      left: 0;
      top: 50%;
      transform: translateY(-50%); } }
  @media (min-width: 1280px) {
    .is-positioned-w-xl {
      left: 0;
      top: 50%;
      transform: translateY(-50%); } }
  @media (min-width: 1920px) {
    .is-positioned-w-xxl {
      left: 0;
      top: 50%;
      transform: translateY(-50%); } }

.is-positioned-nw {
  left: 0;
  top: 0; }
  @media (min-width: 0px) {
    .is-positioned-nw-xs {
      left: 0;
      top: 0; } }
  @media (min-width: 480px) {
    .is-positioned-nw-sm {
      left: 0;
      top: 0; } }
  @media (min-width: 720px) {
    .is-positioned-nw-md {
      left: 0;
      top: 0; } }
  @media (min-width: 1024px) {
    .is-positioned-nw-nav {
      left: 0;
      top: 0; } }
  @media (min-width: 1024px) {
    .is-positioned-nw-lg {
      left: 0;
      top: 0; } }
  @media (min-width: 1280px) {
    .is-positioned-nw-xl {
      left: 0;
      top: 0; } }
  @media (min-width: 1920px) {
    .is-positioned-nw-xxl {
      left: 0;
      top: 0; } }

.is-positioned-0 {
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%); }
  @media (min-width: 0px) {
    .is-positioned-0-xs {
      left: 50%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%); } }
  @media (min-width: 480px) {
    .is-positioned-0-sm {
      left: 50%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%); } }
  @media (min-width: 720px) {
    .is-positioned-0-md {
      left: 50%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%); } }
  @media (min-width: 1024px) {
    .is-positioned-0-nav {
      left: 50%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%); } }
  @media (min-width: 1024px) {
    .is-positioned-0-lg {
      left: 50%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%); } }
  @media (min-width: 1280px) {
    .is-positioned-0-xl {
      left: 50%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%); } }
  @media (min-width: 1920px) {
    .is-positioned-0-xxl {
      left: 50%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%); } }

.not-positioned {
  bottom: auto;
  left: auto;
  right: auto;
  top: auto;
  transform: initial; }
  @media (min-width: 0px) {
    .not-positioned-xs {
      bottom: auto;
      left: auto;
      right: auto;
      top: auto;
      transform: initial; } }
  @media (min-width: 480px) {
    .not-positioned-sm {
      bottom: auto;
      left: auto;
      right: auto;
      top: auto;
      transform: initial; } }
  @media (min-width: 720px) {
    .not-positioned-md {
      bottom: auto;
      left: auto;
      right: auto;
      top: auto;
      transform: initial; } }
  @media (min-width: 1024px) {
    .not-positioned-nav {
      bottom: auto;
      left: auto;
      right: auto;
      top: auto;
      transform: initial; } }
  @media (min-width: 1024px) {
    .not-positioned-lg {
      bottom: auto;
      left: auto;
      right: auto;
      top: auto;
      transform: initial; } }
  @media (min-width: 1280px) {
    .not-positioned-xl {
      bottom: auto;
      left: auto;
      right: auto;
      top: auto;
      transform: initial; } }
  @media (min-width: 1920px) {
    .not-positioned-xxl {
      bottom: auto;
      left: auto;
      right: auto;
      top: auto;
      transform: initial; } }

.is-layer-0 {
  z-index: 0; }
  @media (min-width: 0px) {
    .is-layer-0-xs {
      z-index: 0; } }
  @media (min-width: 480px) {
    .is-layer-0-sm {
      z-index: 0; } }
  @media (min-width: 720px) {
    .is-layer-0-md {
      z-index: 0; } }
  @media (min-width: 1024px) {
    .is-layer-0-nav {
      z-index: 0; } }
  @media (min-width: 1024px) {
    .is-layer-0-lg {
      z-index: 0; } }
  @media (min-width: 1280px) {
    .is-layer-0-xl {
      z-index: 0; } }
  @media (min-width: 1920px) {
    .is-layer-0-xxl {
      z-index: 0; } }

.is-layer-10 {
  z-index: 10; }
  @media (min-width: 0px) {
    .is-layer-10-xs {
      z-index: 10; } }
  @media (min-width: 480px) {
    .is-layer-10-sm {
      z-index: 10; } }
  @media (min-width: 720px) {
    .is-layer-10-md {
      z-index: 10; } }
  @media (min-width: 1024px) {
    .is-layer-10-nav {
      z-index: 10; } }
  @media (min-width: 1024px) {
    .is-layer-10-lg {
      z-index: 10; } }
  @media (min-width: 1280px) {
    .is-layer-10-xl {
      z-index: 10; } }
  @media (min-width: 1920px) {
    .is-layer-10-xxl {
      z-index: 10; } }

.is-layer-20 {
  z-index: 20; }
  @media (min-width: 0px) {
    .is-layer-20-xs {
      z-index: 20; } }
  @media (min-width: 480px) {
    .is-layer-20-sm {
      z-index: 20; } }
  @media (min-width: 720px) {
    .is-layer-20-md {
      z-index: 20; } }
  @media (min-width: 1024px) {
    .is-layer-20-nav {
      z-index: 20; } }
  @media (min-width: 1024px) {
    .is-layer-20-lg {
      z-index: 20; } }
  @media (min-width: 1280px) {
    .is-layer-20-xl {
      z-index: 20; } }
  @media (min-width: 1920px) {
    .is-layer-20-xxl {
      z-index: 20; } }

.is-layer-30 {
  z-index: 30; }
  @media (min-width: 0px) {
    .is-layer-30-xs {
      z-index: 30; } }
  @media (min-width: 480px) {
    .is-layer-30-sm {
      z-index: 30; } }
  @media (min-width: 720px) {
    .is-layer-30-md {
      z-index: 30; } }
  @media (min-width: 1024px) {
    .is-layer-30-nav {
      z-index: 30; } }
  @media (min-width: 1024px) {
    .is-layer-30-lg {
      z-index: 30; } }
  @media (min-width: 1280px) {
    .is-layer-30-xl {
      z-index: 30; } }
  @media (min-width: 1920px) {
    .is-layer-30-xxl {
      z-index: 30; } }

.is-layer-40 {
  z-index: 40; }
  @media (min-width: 0px) {
    .is-layer-40-xs {
      z-index: 40; } }
  @media (min-width: 480px) {
    .is-layer-40-sm {
      z-index: 40; } }
  @media (min-width: 720px) {
    .is-layer-40-md {
      z-index: 40; } }
  @media (min-width: 1024px) {
    .is-layer-40-nav {
      z-index: 40; } }
  @media (min-width: 1024px) {
    .is-layer-40-lg {
      z-index: 40; } }
  @media (min-width: 1280px) {
    .is-layer-40-xl {
      z-index: 40; } }
  @media (min-width: 1920px) {
    .is-layer-40-xxl {
      z-index: 40; } }

.is-layer-50 {
  z-index: 50; }
  @media (min-width: 0px) {
    .is-layer-50-xs {
      z-index: 50; } }
  @media (min-width: 480px) {
    .is-layer-50-sm {
      z-index: 50; } }
  @media (min-width: 720px) {
    .is-layer-50-md {
      z-index: 50; } }
  @media (min-width: 1024px) {
    .is-layer-50-nav {
      z-index: 50; } }
  @media (min-width: 1024px) {
    .is-layer-50-lg {
      z-index: 50; } }
  @media (min-width: 1280px) {
    .is-layer-50-xl {
      z-index: 50; } }
  @media (min-width: 1920px) {
    .is-layer-50-xxl {
      z-index: 50; } }

.is-layer-60 {
  z-index: 60; }
  @media (min-width: 0px) {
    .is-layer-60-xs {
      z-index: 60; } }
  @media (min-width: 480px) {
    .is-layer-60-sm {
      z-index: 60; } }
  @media (min-width: 720px) {
    .is-layer-60-md {
      z-index: 60; } }
  @media (min-width: 1024px) {
    .is-layer-60-nav {
      z-index: 60; } }
  @media (min-width: 1024px) {
    .is-layer-60-lg {
      z-index: 60; } }
  @media (min-width: 1280px) {
    .is-layer-60-xl {
      z-index: 60; } }
  @media (min-width: 1920px) {
    .is-layer-60-xxl {
      z-index: 60; } }

.is-layer-70 {
  z-index: 70; }
  @media (min-width: 0px) {
    .is-layer-70-xs {
      z-index: 70; } }
  @media (min-width: 480px) {
    .is-layer-70-sm {
      z-index: 70; } }
  @media (min-width: 720px) {
    .is-layer-70-md {
      z-index: 70; } }
  @media (min-width: 1024px) {
    .is-layer-70-nav {
      z-index: 70; } }
  @media (min-width: 1024px) {
    .is-layer-70-lg {
      z-index: 70; } }
  @media (min-width: 1280px) {
    .is-layer-70-xl {
      z-index: 70; } }
  @media (min-width: 1920px) {
    .is-layer-70-xxl {
      z-index: 70; } }

.is-layer-80 {
  z-index: 80; }
  @media (min-width: 0px) {
    .is-layer-80-xs {
      z-index: 80; } }
  @media (min-width: 480px) {
    .is-layer-80-sm {
      z-index: 80; } }
  @media (min-width: 720px) {
    .is-layer-80-md {
      z-index: 80; } }
  @media (min-width: 1024px) {
    .is-layer-80-nav {
      z-index: 80; } }
  @media (min-width: 1024px) {
    .is-layer-80-lg {
      z-index: 80; } }
  @media (min-width: 1280px) {
    .is-layer-80-xl {
      z-index: 80; } }
  @media (min-width: 1920px) {
    .is-layer-80-xxl {
      z-index: 80; } }

.is-layer-90 {
  z-index: 90; }
  @media (min-width: 0px) {
    .is-layer-90-xs {
      z-index: 90; } }
  @media (min-width: 480px) {
    .is-layer-90-sm {
      z-index: 90; } }
  @media (min-width: 720px) {
    .is-layer-90-md {
      z-index: 90; } }
  @media (min-width: 1024px) {
    .is-layer-90-nav {
      z-index: 90; } }
  @media (min-width: 1024px) {
    .is-layer-90-lg {
      z-index: 90; } }
  @media (min-width: 1280px) {
    .is-layer-90-xl {
      z-index: 90; } }
  @media (min-width: 1920px) {
    .is-layer-90-xxl {
      z-index: 90; } }

.is-layer-100 {
  z-index: 100; }
  @media (min-width: 0px) {
    .is-layer-100-xs {
      z-index: 100; } }
  @media (min-width: 480px) {
    .is-layer-100-sm {
      z-index: 100; } }
  @media (min-width: 720px) {
    .is-layer-100-md {
      z-index: 100; } }
  @media (min-width: 1024px) {
    .is-layer-100-nav {
      z-index: 100; } }
  @media (min-width: 1024px) {
    .is-layer-100-lg {
      z-index: 100; } }
  @media (min-width: 1280px) {
    .is-layer-100-xl {
      z-index: 100; } }
  @media (min-width: 1920px) {
    .is-layer-100-xxl {
      z-index: 100; } }

.is-layer-200 {
  z-index: 200; }
  @media (min-width: 0px) {
    .is-layer-200-xs {
      z-index: 200; } }
  @media (min-width: 480px) {
    .is-layer-200-sm {
      z-index: 200; } }
  @media (min-width: 720px) {
    .is-layer-200-md {
      z-index: 200; } }
  @media (min-width: 1024px) {
    .is-layer-200-nav {
      z-index: 200; } }
  @media (min-width: 1024px) {
    .is-layer-200-lg {
      z-index: 200; } }
  @media (min-width: 1280px) {
    .is-layer-200-xl {
      z-index: 200; } }
  @media (min-width: 1920px) {
    .is-layer-200-xxl {
      z-index: 200; } }

.is-layer-300 {
  z-index: 300; }
  @media (min-width: 0px) {
    .is-layer-300-xs {
      z-index: 300; } }
  @media (min-width: 480px) {
    .is-layer-300-sm {
      z-index: 300; } }
  @media (min-width: 720px) {
    .is-layer-300-md {
      z-index: 300; } }
  @media (min-width: 1024px) {
    .is-layer-300-nav {
      z-index: 300; } }
  @media (min-width: 1024px) {
    .is-layer-300-lg {
      z-index: 300; } }
  @media (min-width: 1280px) {
    .is-layer-300-xl {
      z-index: 300; } }
  @media (min-width: 1920px) {
    .is-layer-300-xxl {
      z-index: 300; } }

.is-layer-400 {
  z-index: 400; }
  @media (min-width: 0px) {
    .is-layer-400-xs {
      z-index: 400; } }
  @media (min-width: 480px) {
    .is-layer-400-sm {
      z-index: 400; } }
  @media (min-width: 720px) {
    .is-layer-400-md {
      z-index: 400; } }
  @media (min-width: 1024px) {
    .is-layer-400-nav {
      z-index: 400; } }
  @media (min-width: 1024px) {
    .is-layer-400-lg {
      z-index: 400; } }
  @media (min-width: 1280px) {
    .is-layer-400-xl {
      z-index: 400; } }
  @media (min-width: 1920px) {
    .is-layer-400-xxl {
      z-index: 400; } }

.is-layer-500 {
  z-index: 500; }
  @media (min-width: 0px) {
    .is-layer-500-xs {
      z-index: 500; } }
  @media (min-width: 480px) {
    .is-layer-500-sm {
      z-index: 500; } }
  @media (min-width: 720px) {
    .is-layer-500-md {
      z-index: 500; } }
  @media (min-width: 1024px) {
    .is-layer-500-nav {
      z-index: 500; } }
  @media (min-width: 1024px) {
    .is-layer-500-lg {
      z-index: 500; } }
  @media (min-width: 1280px) {
    .is-layer-500-xl {
      z-index: 500; } }
  @media (min-width: 1920px) {
    .is-layer-500-xxl {
      z-index: 500; } }

.is-layer-600 {
  z-index: 600; }
  @media (min-width: 0px) {
    .is-layer-600-xs {
      z-index: 600; } }
  @media (min-width: 480px) {
    .is-layer-600-sm {
      z-index: 600; } }
  @media (min-width: 720px) {
    .is-layer-600-md {
      z-index: 600; } }
  @media (min-width: 1024px) {
    .is-layer-600-nav {
      z-index: 600; } }
  @media (min-width: 1024px) {
    .is-layer-600-lg {
      z-index: 600; } }
  @media (min-width: 1280px) {
    .is-layer-600-xl {
      z-index: 600; } }
  @media (min-width: 1920px) {
    .is-layer-600-xxl {
      z-index: 600; } }

.is-layer-700 {
  z-index: 700; }
  @media (min-width: 0px) {
    .is-layer-700-xs {
      z-index: 700; } }
  @media (min-width: 480px) {
    .is-layer-700-sm {
      z-index: 700; } }
  @media (min-width: 720px) {
    .is-layer-700-md {
      z-index: 700; } }
  @media (min-width: 1024px) {
    .is-layer-700-nav {
      z-index: 700; } }
  @media (min-width: 1024px) {
    .is-layer-700-lg {
      z-index: 700; } }
  @media (min-width: 1280px) {
    .is-layer-700-xl {
      z-index: 700; } }
  @media (min-width: 1920px) {
    .is-layer-700-xxl {
      z-index: 700; } }

.is-layer-800 {
  z-index: 800; }
  @media (min-width: 0px) {
    .is-layer-800-xs {
      z-index: 800; } }
  @media (min-width: 480px) {
    .is-layer-800-sm {
      z-index: 800; } }
  @media (min-width: 720px) {
    .is-layer-800-md {
      z-index: 800; } }
  @media (min-width: 1024px) {
    .is-layer-800-nav {
      z-index: 800; } }
  @media (min-width: 1024px) {
    .is-layer-800-lg {
      z-index: 800; } }
  @media (min-width: 1280px) {
    .is-layer-800-xl {
      z-index: 800; } }
  @media (min-width: 1920px) {
    .is-layer-800-xxl {
      z-index: 800; } }

.is-layer-900 {
  z-index: 900; }
  @media (min-width: 0px) {
    .is-layer-900-xs {
      z-index: 900; } }
  @media (min-width: 480px) {
    .is-layer-900-sm {
      z-index: 900; } }
  @media (min-width: 720px) {
    .is-layer-900-md {
      z-index: 900; } }
  @media (min-width: 1024px) {
    .is-layer-900-nav {
      z-index: 900; } }
  @media (min-width: 1024px) {
    .is-layer-900-lg {
      z-index: 900; } }
  @media (min-width: 1280px) {
    .is-layer-900-xl {
      z-index: 900; } }
  @media (min-width: 1920px) {
    .is-layer-900-xxl {
      z-index: 900; } }

.is-layer-1000 {
  z-index: 1000; }
  @media (min-width: 0px) {
    .is-layer-1000-xs {
      z-index: 1000; } }
  @media (min-width: 480px) {
    .is-layer-1000-sm {
      z-index: 1000; } }
  @media (min-width: 720px) {
    .is-layer-1000-md {
      z-index: 1000; } }
  @media (min-width: 1024px) {
    .is-layer-1000-nav {
      z-index: 1000; } }
  @media (min-width: 1024px) {
    .is-layer-1000-lg {
      z-index: 1000; } }
  @media (min-width: 1280px) {
    .is-layer-1000-xl {
      z-index: 1000; } }
  @media (min-width: 1920px) {
    .is-layer-1000-xxl {
      z-index: 1000; } }

.is-layer-behind {
  z-index: -1; }
  @media (min-width: 0px) {
    .is-layer-behind-xs {
      z-index: -1; } }
  @media (min-width: 480px) {
    .is-layer-behind-sm {
      z-index: -1; } }
  @media (min-width: 720px) {
    .is-layer-behind-md {
      z-index: -1; } }
  @media (min-width: 1024px) {
    .is-layer-behind-nav {
      z-index: -1; } }
  @media (min-width: 1024px) {
    .is-layer-behind-lg {
      z-index: -1; } }
  @media (min-width: 1280px) {
    .is-layer-behind-xl {
      z-index: -1; } }
  @media (min-width: 1920px) {
    .is-layer-behind-xxl {
      z-index: -1; } }

.is-layer-max {
  z-index: 99999; }
  @media (min-width: 0px) {
    .is-layer-max-xs {
      z-index: 99999; } }
  @media (min-width: 480px) {
    .is-layer-max-sm {
      z-index: 99999; } }
  @media (min-width: 720px) {
    .is-layer-max-md {
      z-index: 99999; } }
  @media (min-width: 1024px) {
    .is-layer-max-nav {
      z-index: 99999; } }
  @media (min-width: 1024px) {
    .is-layer-max-lg {
      z-index: 99999; } }
  @media (min-width: 1280px) {
    .is-layer-max-xl {
      z-index: 99999; } }
  @media (min-width: 1920px) {
    .is-layer-max-xxl {
      z-index: 99999; } }

.is-visible-from-xs {
  display: none !important; }

.is-visible-xs {
  display: none !important; }

.is-visible-from-sm {
  display: none !important; }

.is-visible-sm {
  display: none !important; }

.is-visible-from-md {
  display: none !important; }

.is-visible-md {
  display: none !important; }

.is-visible-from-nav {
  display: none !important; }

.is-visible-nav {
  display: none !important; }

.is-visible-from-lg {
  display: none !important; }

.is-visible-lg {
  display: none !important; }

.is-visible-from-xl {
  display: none !important; }

.is-visible-xl {
  display: none !important; }

.is-visible-from-xxl {
  display: none !important; }

.is-visible-xxl {
  display: none !important; }

@media (min-width: 0px) and (max-width: 479px) {
  .is-visible-xs {
    display: inherit !important; } }

@media (min-width: 480px) and (max-width: 719px) {
  .is-visible-sm {
    display: inherit !important; } }

@media (min-width: 720px) and (max-width: 1023px) {
  .is-visible-md {
    display: inherit !important; } }

@media (min-width: 1024px) and (max-width: 1023px) {
  .is-visible-nav {
    display: inherit !important; } }

@media (min-width: 1024px) and (max-width: 1279px) {
  .is-visible-lg {
    display: inherit !important; } }

@media (min-width: 1280px) and (max-width: 1919px) {
  .is-visible-xl {
    display: inherit !important; } }

@media (min-width: 1920px) {
  .is-visible-xxl {
    display: inherit !important; } }

@media (min-width: 0px) {
  .is-visible-from-xs {
    display: inherit !important; } }

@media (min-width: 480px) {
  .is-visible-from-sm {
    display: inherit !important; } }

@media (min-width: 720px) {
  .is-visible-from-md {
    display: inherit !important; } }

@media (min-width: 1024px) {
  .is-visible-from-nav {
    display: inherit !important; } }

@media (min-width: 1024px) {
  .is-visible-from-lg {
    display: inherit !important; } }

@media (min-width: 1280px) {
  .is-visible-from-xl {
    display: inherit !important; } }

@media (min-width: 1920px) {
  .is-visible-from-xxl {
    display: inherit !important; } }

.is-hidden {
  display: none !important; }
  @media (min-width: 0px) and (max-width: 479px) {
    .is-hidden-xs {
      display: none !important; } }
  @media (min-width: 480px) and (max-width: 719px) {
    .is-hidden-sm {
      display: none !important; } }
  @media (min-width: 720px) and (max-width: 1023px) {
    .is-hidden-md {
      display: none !important; } }
  @media (min-width: 1024px) and (max-width: 1023px) {
    .is-hidden-nav {
      display: none !important; } }
  @media (min-width: 1024px) and (max-width: 1279px) {
    .is-hidden-lg {
      display: none !important; } }
  @media (min-width: 1280px) and (max-width: 1919px) {
    .is-hidden-xl {
      display: none !important; } }
  @media (min-width: 1920px) {
    .is-hidden-xxl {
      display: none !important; } }
  @media (min-width: 0px) {
    .is-hidden-from-xs {
      display: none !important; } }
  @media (min-width: 480px) {
    .is-hidden-from-sm {
      display: none !important; } }
  @media (min-width: 720px) {
    .is-hidden-from-md {
      display: none !important; } }
  @media (min-width: 1024px) {
    .is-hidden-from-nav {
      display: none !important; } }
  @media (min-width: 1024px) {
    .is-hidden-from-lg {
      display: none !important; } }
  @media (min-width: 1280px) {
    .is-hidden-from-xl {
      display: none !important; } }
  @media (min-width: 1920px) {
    .is-hidden-from-xxl {
      display: none !important; } }

.xui-checkbox [type='checkbox'], .xui-radio [type='radio'], .xui-toggle [type='radio'],
.xui-toggle [type='checkbox'], .xui-tabs [type='radio'], .xui-accordion-item > [type='radio'],
.xui-accordion-item > [type='checkbox'], .is-hidden-input {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.align-center {
  text-align: center; }
  @media (min-width: 0px) {
    .align-center-xs {
      text-align: center; } }
  @media (min-width: 480px) {
    .align-center-sm {
      text-align: center; } }
  @media (min-width: 720px) {
    .align-center-md {
      text-align: center; } }
  @media (min-width: 1024px) {
    .align-center-nav {
      text-align: center; } }
  @media (min-width: 1024px) {
    .align-center-lg {
      text-align: center; } }
  @media (min-width: 1280px) {
    .align-center-xl {
      text-align: center; } }
  @media (min-width: 1920px) {
    .align-center-xxl {
      text-align: center; } }

.align-right {
  text-align: right; }
  @media (min-width: 0px) {
    .align-right-xs {
      text-align: right; } }
  @media (min-width: 480px) {
    .align-right-sm {
      text-align: right; } }
  @media (min-width: 720px) {
    .align-right-md {
      text-align: right; } }
  @media (min-width: 1024px) {
    .align-right-nav {
      text-align: right; } }
  @media (min-width: 1024px) {
    .align-right-lg {
      text-align: right; } }
  @media (min-width: 1280px) {
    .align-right-xl {
      text-align: right; } }
  @media (min-width: 1920px) {
    .align-right-xxl {
      text-align: right; } }

.align-left {
  text-align: left; }
  @media (min-width: 0px) {
    .align-left-xs {
      text-align: left; } }
  @media (min-width: 480px) {
    .align-left-sm {
      text-align: left; } }
  @media (min-width: 720px) {
    .align-left-md {
      text-align: left; } }
  @media (min-width: 1024px) {
    .align-left-nav {
      text-align: left; } }
  @media (min-width: 1024px) {
    .align-left-lg {
      text-align: left; } }
  @media (min-width: 1280px) {
    .align-left-xl {
      text-align: left; } }
  @media (min-width: 1920px) {
    .align-left-xxl {
      text-align: left; } }

.is-uppercase {
  text-transform: uppercase; }

.is-lowercase {
  text-transform: lowercase; }

.is-bold {
  font-weight: bolder; }

.is-medium {
  font-weight: bold; }

.is-normal {
  font-weight: normal; }

.is-light {
  font-weight: lighter; }

.is-nowrap {
  vertical-align: baseline;
  white-space: nowrap; }
  @media (min-width: 0px) {
    .is-nowrap-xs {
      vertical-align: baseline;
      white-space: nowrap; } }
  @media (min-width: 480px) {
    .is-nowrap-sm {
      vertical-align: baseline;
      white-space: nowrap; } }
  @media (min-width: 720px) {
    .is-nowrap-md {
      vertical-align: baseline;
      white-space: nowrap; } }
  @media (min-width: 1024px) {
    .is-nowrap-nav {
      vertical-align: baseline;
      white-space: nowrap; } }
  @media (min-width: 1024px) {
    .is-nowrap-lg {
      vertical-align: baseline;
      white-space: nowrap; } }
  @media (min-width: 1280px) {
    .is-nowrap-xl {
      vertical-align: baseline;
      white-space: nowrap; } }
  @media (min-width: 1920px) {
    .is-nowrap-xxl {
      vertical-align: baseline;
      white-space: nowrap; } }

.is-hyphen {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
  hyphens: auto; }
  @media (min-width: 0px) {
    .is-hyphen-xs {
      overflow-wrap: break-word;
      word-wrap: break-word;
      -ms-word-break: break-all;
      word-break: break-word;
      hyphens: auto; } }
  @media (min-width: 480px) {
    .is-hyphen-sm {
      overflow-wrap: break-word;
      word-wrap: break-word;
      -ms-word-break: break-all;
      word-break: break-word;
      hyphens: auto; } }
  @media (min-width: 720px) {
    .is-hyphen-md {
      overflow-wrap: break-word;
      word-wrap: break-word;
      -ms-word-break: break-all;
      word-break: break-word;
      hyphens: auto; } }
  @media (min-width: 1024px) {
    .is-hyphen-nav {
      overflow-wrap: break-word;
      word-wrap: break-word;
      -ms-word-break: break-all;
      word-break: break-word;
      hyphens: auto; } }
  @media (min-width: 1024px) {
    .is-hyphen-lg {
      overflow-wrap: break-word;
      word-wrap: break-word;
      -ms-word-break: break-all;
      word-break: break-word;
      hyphens: auto; } }
  @media (min-width: 1280px) {
    .is-hyphen-xl {
      overflow-wrap: break-word;
      word-wrap: break-word;
      -ms-word-break: break-all;
      word-break: break-word;
      hyphens: auto; } }
  @media (min-width: 1920px) {
    .is-hyphen-xxl {
      overflow-wrap: break-word;
      word-wrap: break-word;
      -ms-word-break: break-all;
      word-break: break-word;
      hyphens: auto; } }

.is-hyphen-block {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
  hyphens: auto;
  text-align: justify; }
  @media (min-width: 0px) {
    .is-hyphen-block-xs {
      overflow-wrap: break-word;
      word-wrap: break-word;
      -ms-word-break: break-all;
      word-break: break-word;
      hyphens: auto;
      text-align: justify; } }
  @media (min-width: 480px) {
    .is-hyphen-block-sm {
      overflow-wrap: break-word;
      word-wrap: break-word;
      -ms-word-break: break-all;
      word-break: break-word;
      hyphens: auto;
      text-align: justify; } }
  @media (min-width: 720px) {
    .is-hyphen-block-md {
      overflow-wrap: break-word;
      word-wrap: break-word;
      -ms-word-break: break-all;
      word-break: break-word;
      hyphens: auto;
      text-align: justify; } }
  @media (min-width: 1024px) {
    .is-hyphen-block-nav {
      overflow-wrap: break-word;
      word-wrap: break-word;
      -ms-word-break: break-all;
      word-break: break-word;
      hyphens: auto;
      text-align: justify; } }
  @media (min-width: 1024px) {
    .is-hyphen-block-lg {
      overflow-wrap: break-word;
      word-wrap: break-word;
      -ms-word-break: break-all;
      word-break: break-word;
      hyphens: auto;
      text-align: justify; } }
  @media (min-width: 1280px) {
    .is-hyphen-block-xl {
      overflow-wrap: break-word;
      word-wrap: break-word;
      -ms-word-break: break-all;
      word-break: break-word;
      hyphens: auto;
      text-align: justify; } }
  @media (min-width: 1920px) {
    .is-hyphen-block-xxl {
      overflow-wrap: break-word;
      word-wrap: break-word;
      -ms-word-break: break-all;
      word-break: break-word;
      hyphens: auto;
      text-align: justify; } }

.is-ellipsis {
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block; }
  @media (min-width: 0px) {
    .is-ellipsis-xs {
      -ms-text-overflow: ellipsis;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: block; } }
  @media (min-width: 480px) {
    .is-ellipsis-sm {
      -ms-text-overflow: ellipsis;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: block; } }
  @media (min-width: 720px) {
    .is-ellipsis-md {
      -ms-text-overflow: ellipsis;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: block; } }
  @media (min-width: 1024px) {
    .is-ellipsis-nav {
      -ms-text-overflow: ellipsis;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: block; } }
  @media (min-width: 1024px) {
    .is-ellipsis-lg {
      -ms-text-overflow: ellipsis;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: block; } }
  @media (min-width: 1280px) {
    .is-ellipsis-xl {
      -ms-text-overflow: ellipsis;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: block; } }
  @media (min-width: 1920px) {
    .is-ellipsis-xxl {
      -ms-text-overflow: ellipsis;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: block; } }

.is-ink-default {
  color: #2a3d54 !important; }

.is-ink-light {
  color: rgba(42, 61, 84, 0.6) !important; }

.is-ink-lighter {
  color: rgba(42, 61, 84, 0.4) !important; }

.is-ink-lightest {
  color: rgba(42, 61, 84, 0.2) !important; }

.is-ink-minor {
  color: rgba(47, 206, 111, 0.2) !important; }

.is-ink-link {
  color: #2fce6f !important; }

.is-ink-super {
  color: #14a656 !important; }

.is-ink-highlight {
  color: #fcff66 !important; }

.is-ink-invert {
  color: #fff !important; }

.is-ink-info {
  color: #22e7e7 !important; }

.is-ink-disabled {
  color: rgba(42, 61, 84, 0.2) !important; }

.is-ink-error {
  color: #e72222 !important; }

.is-ink-success {
  color: #22e722 !important; }

.is-ink-warning {
  color: #e7c822 !important; }

.is-theme {
  background-color: white; }
  .is-theme_darkest {
    background-color: #e6e6e6; }
  .is-theme_darker {
    background-color: #ebebeb; }
  .is-theme_dark {
    background-color: whitesmoke; }
  .is-theme_light {
    background-color: white; }
  .is-theme_lighter {
    background-color: white; }
  .is-theme_lightest {
    background-color: white; }

.is-theme-minor {
  background-color: #d5f5e2;
  color: #192432; }
  .is-theme-minor a:not(.btn):not(.tag) {
    color: #13512c; }
  .is-theme-minor_darkest {
    background-color: #a9efc3;
    color: #080c10; }
    .is-theme-minor_darkest a:not(.btn) {
      color: #092815; }
  .is-theme-minor_darker {
    background-color: #b2f0ca;
    color: #192432; }
    .is-theme-minor_darker a:not(.btn) {
      color: #092815; }
  .is-theme-minor_dark {
    background-color: #c4f2d6;
    color: #192432; }
    .is-theme-minor_dark a:not(.btn) {
      color: #13512c; }
  .is-theme-minor_light {
    background-color: #e6f9ee;
    color: #2a3d54; }
    .is-theme-minor_light a:not(.btn) {
      color: #13512c; }
  .is-theme-minor_lighter {
    background-color: #f7fdf9;
    color: #2a3d54; }
    .is-theme-minor_lighter a:not(.btn) {
      color: #1c7b42; }
  .is-theme-minor_lightest {
    background-color: white;
    color: #2a3d54; }
    .is-theme-minor_lightest a:not(.btn) {
      color: #1c7b42; }

.is-theme-link {
  background-color: #2fce6f;
  color: white; }
  .is-theme-link a:not(.btn):not(.tag) {
    color: white; }
  .is-theme-link_darkest {
    background-color: #1fab54;
    color: white; }
    .is-theme-link_darkest a:not(.btn) {
      color: white; }
  .is-theme-link_darker {
    background-color: #22b259;
    color: white; }
    .is-theme-link_darker a:not(.btn) {
      color: white; }
  .is-theme-link_dark {
    background-color: #28c064;
    color: white; }
    .is-theme-link_dark a:not(.btn) {
      color: white; }
  .is-theme-link_light {
    background-color: #41d07c;
    color: black; }
    .is-theme-link_light a:not(.btn) {
      color: black; }
  .is-theme-link_lighter {
    background-color: #55d189;
    color: black; }
    .is-theme-link_lighter a:not(.btn) {
      color: black; }
  .is-theme-link_lightest {
    background-color: #67d396;
    color: black; }
    .is-theme-link_lightest a:not(.btn) {
      color: black; }

.is-theme-super {
  background-color: #14a656;
  color: white; }
  .is-theme-super a:not(.btn):not(.tag) {
    color: white; }
  .is-theme-super_darkest {
    background-color: #0a7d3b;
    color: white; }
    .is-theme-super_darkest a:not(.btn) {
      color: #fdfffe; }
  .is-theme-super_darker {
    background-color: #0c8540;
    color: white; }
    .is-theme-super_darker a:not(.btn) {
      color: #fdfffe; }
  .is-theme-super_dark {
    background-color: #10964b;
    color: white; }
    .is-theme-super_dark a:not(.btn) {
      color: #fdfffe; }
  .is-theme-super_light {
    background-color: #19b561;
    color: white; }
    .is-theme-super_light a:not(.btn) {
      color: white; }
  .is-theme-super_lighter {
    background-color: #1ec46d;
    color: white; }
    .is-theme-super_lighter a:not(.btn) {
      color: white; }
  .is-theme-super_lightest {
    background-color: #24d378;
    color: white; }
    .is-theme-super_lightest a:not(.btn) {
      color: white; }

.is-theme-highlight {
  background-color: #fcff66;
  color: #080c10; }
  .is-theme-highlight a:not(.btn):not(.tag) {
    color: #092815; }
  .is-theme-highlight_darkest {
    background-color: #f6ff33;
    color: #080c10; }
    .is-theme-highlight_darkest a:not(.btn) {
      color: black; }
  .is-theme-highlight_darker {
    background-color: #f7ff3d;
    color: #080c10; }
    .is-theme-highlight_darker a:not(.btn) {
      color: black; }
  .is-theme-highlight_dark {
    background-color: #faff52;
    color: #080c10; }
    .is-theme-highlight_dark a:not(.btn) {
      color: #092815; }
  .is-theme-highlight_light {
    background-color: #fcfd7c;
    color: #192432; }
    .is-theme-highlight_light a:not(.btn) {
      color: #092815; }
  .is-theme-highlight_lighter {
    background-color: #fcfc92;
    color: #192432; }
    .is-theme-highlight_lighter a:not(.btn) {
      color: #13512c; }
  .is-theme-highlight_lightest {
    background-color: #fbfaa7;
    color: #192432; }
    .is-theme-highlight_lightest a:not(.btn) {
      color: #13512c; }

.is-theme-invert {
  background-color: #2a3d54;
  color: white; }
  .is-theme-invert a:not(.btn):not(.tag) {
    color: #57d98b; }
  .is-theme-invert_darkest {
    background-color: #172334;
    color: white; }
    .is-theme-invert_darkest a:not(.btn) {
      color: #2fce6f; }
  .is-theme-invert_darker {
    background-color: #1a283b;
    color: white; }
    .is-theme-invert_darker a:not(.btn) {
      color: #2fce6f; }
  .is-theme-invert_dark {
    background-color: #223348;
    color: white; }
    .is-theme-invert_dark a:not(.btn) {
      color: #57d98b; }
  .is-theme-invert_light {
    background-color: #334860;
    color: white; }
    .is-theme-invert_light a:not(.btn) {
      color: #81e2a8; }
  .is-theme-invert_lighter {
    background-color: #3c526b;
    color: white; }
    .is-theme-invert_lighter a:not(.btn) {
      color: #81e2a8; }
  .is-theme-invert_lightest {
    background-color: #465d75;
    color: white; }
    .is-theme-invert_lightest a:not(.btn) {
      color: #aaecc5; }

[data-animate] {
  animation-fill-mode: forwards;
  backface-visibility: hidden;
  opacity: 0;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1); }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

[data-animate~='fadeIn'] {
  animation-name: fadeIn; }

@keyframes popIn {
  0% {
    opacity: 0;
    transform: scale(0.6); }
  80% {
    transform: scale(1.2); }
  100% {
    opacity: 1;
    transform: initial; } }

[data-animate~='popIn'] {
  animation-name: popIn; }

@keyframes flipLeft {
  0% {
    opacity: 0;
    transform: rotateY(180deg); }
  100% {
    opacity: 1;
    transform: rotateY(0); } }

[data-animate~='flipLeft'] {
  animation-name: flipLeft; }

@keyframes flipRight {
  0% {
    opacity: 0;
    transform: rotateY(-180deg); }
  100% {
    opacity: 1;
    transform: rotateY(0); } }

[data-animate~='flipRight'] {
  animation-name: flipRight; }

@keyframes slideRight {
  0% {
    opacity: 0;
    transform: translateX(-100%); }
  100% {
    opacity: 1;
    transform: initial; } }

[data-animate~='slideRight'] {
  animation-name: slideRight; }

@keyframes slideLeft {
  0% {
    opacity: 0;
    transform: translateX(100%); }
  100% {
    opacity: 1;
    transform: initial; } }

[data-animate~='slideLeft'] {
  animation-name: slideLeft; }

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(100%); }
  100% {
    opacity: 1;
    transform: initial; } }

[data-animate~='slideUp'] {
  animation-name: slideUp; }

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-100%); }
  100% {
    opacity: 1;
    transform: initial; } }

[data-animate~='slideDown'] {
  animation-name: slideDown; }

[data-animate~='+1'] {
  animation-delay: 0.1s; }

[data-animate~='+2'] {
  animation-delay: 0.2s; }

[data-animate~='+3'] {
  animation-delay: 0.3s; }

[data-animate~='+4'] {
  animation-delay: 0.4s; }

[data-animate~='+5'] {
  animation-delay: 0.5s; }

[data-animate~="1"] {
  animation-duration: 0.1s; }

[data-animate~="2"] {
  animation-duration: 0.2s; }

[data-animate~="3"] {
  animation-duration: 0.3s; }

[data-animate~="4"] {
  animation-duration: 0.4s; }

[data-animate~="5"] {
  animation-duration: 0.5s; }

.has-transition {
  transition: all .4s; }
